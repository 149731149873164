import React from 'react';
import UploadFileBlock from '../UploadFile/UploadFileBlock';
// import SimpleButton from '../../../../Buttons/SimpleButton';
import {
  Statement,
  Header,
  Choose,
  // Or
} from './Styles';
import { DOCUMENT_TYPE } from '../../../../../models/UploadFileData';
import { CONCRETE_TYPES } from '../../../../../models/Document';
import { STATUS_STATES } from '../../../../../types';

interface ChooseStatementProps {
  type: DOCUMENT_TYPE;
  disableUpload: boolean;
  documentType: CONCRETE_TYPES;
  status: STATUS_STATES;
  setStatus: (value: STATUS_STATES) => void;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
}

function ChooseStatement(props: ChooseStatementProps): JSX.Element {
  const { type, status, uploadFile, setStatus, disableUpload, documentType } = props;

  return (
    <Statement>
      {type === DOCUMENT_TYPE.BANK_STATEMENT && (
        <Header style={{ color: 'var(--bank-color' }}>Reconcilio Format</Header>
      )}
      {type === DOCUMENT_TYPE.LEDGER && (
        <Header style={{ color: 'var(--ledger-color' }}>Reconcilio Format</Header>
      )}
      <Choose>
        <UploadFileBlock
          type={type}
          disableUpload={disableUpload}
          uploadFile={uploadFile}
          concreteType={documentType}
          status={status}
          setStatus={setStatus}
        />
        {/* <Or>{`OR`}</Or>
        <SimpleButton content="Load Previous Uploaded File" /> */}
      </Choose>
    </Statement>
  );
}

export default ChooseStatement;
