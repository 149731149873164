import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import DashboardLayoutComponent from './DashboardLayout';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';
import LocationPath from './LocationPath';

interface DashboardControllerProps {
  children: React.ReactNode;
}

function DashboardLayoutController(props: DashboardControllerProps): JSX.Element {
  const { children } = props;
  const workspaceViewModel = useWorkspacesViewModel();

  const location = useLocation();
  const pageName = LocationPath.getPageName(location.pathname);

  useEffect(() => {
    workspaceViewModel.setIsWorkspacePath(LocationPath.isWorkspacePath(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    console.log(
      '[DashboardLayoutController]',
      workspaceViewModel.isCreateWorkspaceDialogVisible,
      !workspaceViewModel.hasWorkspaces
    );
  }, [workspaceViewModel.hasWorkspaces, workspaceViewModel.isCreateWorkspaceDialogVisible]);

  return (
    <DashboardLayoutComponent
      open={workspaceViewModel.isWorkspacePath}
      setOpen={workspaceViewModel.setIsWorkspacePath}
      pageName={pageName}
      // hasWorkspaces={workspaceViewModel.hasWorkspaces}
      // isCreateWorkspaceDialogVisible={workspaceViewModel.isCreateWorkspaceDialogVisible}
    >
      {children}
    </DashboardLayoutComponent>
  );
}

const DashboardLayout = observer(DashboardLayoutController);

export default DashboardLayout;
