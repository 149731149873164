import Button from '@mui/material/Button';
import unreconcileIcon from '../../../../assets/unreconcile.svg';

interface CancelButtonProps {
  onClick: (reconciliationReportId: string) => void;
  reconciliationReportId: string;
}

function CancelButton(props: CancelButtonProps): JSX.Element {
  const { onClick, reconciliationReportId } = props;

  const handleClick = () => {
    onClick(reconciliationReportId);
  };

  return (
    <Button
      variant="contained"
      style={{
        padding: '15px 20px',
        fontWeight: '500',
        backgroundColor: '#7C7C7A',
      }}
      startIcon={<img src={unreconcileIcon} alt="Unreconciled Icon" />}
      onClick={handleClick}
    >
      Cancel
    </Button>
  );
}

export default CancelButton;
