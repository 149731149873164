import { jwtDecode } from 'jwt-decode';
import {
  GetUserError,
  UpdateUserError,
  IIamRepository,
  IamResponse,
  GetUsersError,
  CreateWorkspaceUserParams,
  WorkspaceUserCreatedError,
  RenewAccessTokenError,
} from '.';
import { UpdateUserDetailsRequestParams, User } from '../../../models/User';
import {
  AsyncStatus,
  DecodedToken,
  RepositoryResponse,
  ResponseCallback,
  State,
  fetchedState,
} from '../../../types';
import { IIamService, ResourceLevel } from '../../interfaces/IIamService';
import { IAM_ACCESS_TOKEN_LOCAL_STORAGE_KEY } from '../../../constants';
import { EventBus, Events, UserDetailsEvent } from '../../../Events';
import { LocalStorageRepository } from '../LocalStorage';

const getLocalStorageValue = (key: string): string | null => {
  const token = localStorage.getItem(key);
  if (token) {
    return token;
  }
  return null;
};

const setLocalStorageValue = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const getAccessToken = (): string | null =>
  getLocalStorageValue(IAM_ACCESS_TOKEN_LOCAL_STORAGE_KEY);

const setAccessToken = (token: string): void => {
  setLocalStorageValue(IAM_ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
};

const clearAccessToken = (): void => {
  localStorage.clear();
  window.localStorage.clear();
};

const isExpired = (token: string): boolean => {
  const decoded = jwtDecode(token) as DecodedToken;
  // Get the current timestamp
  const now = Date.now().valueOf() / 1000; // in seconds
  return decoded.exp < now;
};

const SECONDS_BEFORE_RENEWAL = 60;
const isNearExpiration = (token: string): boolean => {
  const decoded = jwtDecode(token) as DecodedToken;
  // Get the current timestamp
  const now = Date.now().valueOf() / 1000; // in seconds
  return decoded.exp - now < SECONDS_BEFORE_RENEWAL; // 1 minute
};

// Expiration - 1minute - now
const getTimeToRenewMilis = (token: string): number => {
  const decoded = jwtDecode(token) as DecodedToken;
  const now = Date.now().valueOf() / 1000; // in seconds
  return (decoded.exp - SECONDS_BEFORE_RENEWAL - now) * 1000; // ms
};

class IamRepository implements IIamRepository {
  private getUserCallback: ResponseCallback<RepositoryResponse<IamResponse>> | null = null;

  constructor(private iamService: IIamService) {
    EventBus.subscribe(Events.RENEW_ACCESS_TOKEN, this.renewAccessTokenHandler);
  }

  renewAccessTokenHandler = async (user: State<User | null, RenewAccessTokenError>) => {
    if (user.status === AsyncStatus.ERROR) {
      console.error(user.error);
      // return;
    }
    if (user.status === AsyncStatus.SUCCESS) {
      const { data: userInputData } = user;
      let userData = userInputData;
      if (!userInputData) {
        userData = this.getUser();
      }
      const token = userData?.jwt;
      if (!token) {
        console.log('renewAccessTokenHandler:user input data', userInputData);
        console.log('renewAccessTokenHandler:userData', userData);
        console.error('renewAccessTokenHandler:No token found');
        return;
      }

      console.log('renewAccessTokenHandler:isNearExpiration', isNearExpiration(token), Date.now());
      console.log(
        'renewAccessTokenHandler:getTimeToRenewMilis',
        getTimeToRenewMilis(token),
        Date.now()
      );
      if (isNearExpiration(token)) {
        await this.renewAccessToken(token);
        setTimeout(async () => {
          await this.renewAccessToken(token);
          console.log('renewAccessTokenHandler:Renewed access token');
        }, getTimeToRenewMilis(token));
      } else {
        setTimeout(async () => {
          await this.renewAccessToken(token);
          console.log('renewAccessTokenHandler:Renewed access token');
        }, getTimeToRenewMilis(token));
      }
    }
  };

  private renewAccessToken = async (token: string): Promise<void> => {
    const renewedToken = await this.iamService.refreshToken(token);
    setAccessToken(renewedToken);
    EventBus.emit(Events.RENEW_ACCESS_TOKEN, fetchedState(null));
  };

  async loginWithEmailPassword(email: string, password: string): Promise<User> {
    const loginResponse = await this.iamService.loginWithEmailPassword(email, password);
    const token = loginResponse.accessToken;
    setAccessToken(token);
    const decoded = jwtDecode(token) as DecodedToken;
    const user: User = { id: decoded.sub, jwt: token };
    return user;
  }

  async registerWithEmailPassword(email: string, password: string): Promise<void> {
    await this.iamService.registerWithEmailPassword(email, password);
  }

  isAuthenticated(): boolean {
    const token = getAccessToken();
    if (token === null) return false;
    if (isExpired(token)) {
      clearAccessToken();
      return false;
    }
    return true;
  }

  logout(): void {
    clearAccessToken();
  }

  onAuthStateChanged(callback: (data: any) => void) {
    this.getUserCallback = callback;
  }

  getUser(): User | null {
    const token = getAccessToken();
    if (token === null) return null;
    const decoded = jwtDecode(token) as DecodedToken;
    if (isExpired(token)) {
      clearAccessToken();
      return null;
    }
    const user: User = { id: decoded.sub, jwt: token };
    return user;
  }

  getUserDetails(): State<User, GetUserError> {
    this.fetchUserDetails();
    return {
      status: AsyncStatus.PENDING,
      fetchedAt: Date.now(),
      data: null,
      error: null,
    };
  }

  getUsersDetails(ids: string[], event?: UserDetailsEvent): State<User[], GetUsersError> {
    this.fetchUsersDetails(ids, event);
    return {
      status: AsyncStatus.PENDING,
      fetchedAt: Date.now(),
      data: null,
      error: null,
    };
  }

  private fetchUserDetails(): void {
    new Promise<State<User, GetUserError>>(async (resolve, reject) => {
      try {
        const token = getAccessToken();
        if (token === null) throw new Error('No user token found');
        const decoded = jwtDecode(token) as DecodedToken;
        if (isExpired(token)) {
          clearAccessToken();
        }
        const userId = decoded.sub;
        const user = (await this.iamService.getUserById(userId, { idToken: token })) as User;
        const userState: State<User, null> = fetchedState(user);
        resolve(userState);
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
    })
      .then((userResponse: State<User, GetUserError>) => {
        EventBus.emit(Events.USER_FETCH, userResponse);
      })
      .catch((error) => {
        const errorResponse: State<null, GetUserError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error: error.toString(),
        };
        EventBus.emit(Events.USER_FETCH, errorResponse);
      });
  }

  private fetchUsersDetails(ids: string[], event?: UserDetailsEvent): void {
    new Promise<State<User[], GetUserError>>(async (resolve, reject) => {
      try {
        const token = getAccessToken();
        if (token === null) throw new Error('No user token found');
        console.log('ids:', ids);
        const users = (await this.iamService.getUsersByIds(ids, { idToken: token })) as User[];
        const userState: State<User[], null> = fetchedState(users);
        resolve(userState);
      } catch (error) {
        console.log('error', error);
        reject(error);
      }
    })
      .then((usersResponse: State<User[], GetUsersError>) => {
        if (event) EventBus.emit(event, usersResponse);
        else EventBus.emit(Events.USERS_FETCH, usersResponse);
      })
      .catch((error) => {
        const errorResponse: State<null, GetUsersError> = {
          status: AsyncStatus.ERROR,
          fetchedAt: Date.now(),
          data: null,
          error: error.toString(),
        };
        if (event) EventBus.emit(event, errorResponse);
        else EventBus.emit(Events.USERS_FETCH, errorResponse);
      });
  }

  async updateUserDetails(params: UpdateUserDetailsRequestParams): Promise<void> {
    try {
      const token = getAccessToken();
      if (!token) throw new Error('No user token found');
      const decoded = jwtDecode(token) as DecodedToken;
      const userId = decoded.sub;
      await this.iamService.updateUserDetails({ ...params, userId }, { idToken: token });
      EventBus.emit(Events.USER_UPDATE, fetchedState<null>(null));
      // }
    } catch (error: any) {
      const errorResponse: State<null, UpdateUserError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_UPDATE, errorResponse);
    }
  }

  // eslint-disable-next-line consistent-return
  async createWorkspaceUserWithEmailPasswordAndRoleId(
    params: CreateWorkspaceUserParams
  ): Promise<string | void> {
    try {
      const { workspaceId, accessToken } = this.getContextValues();
      if (!workspaceId || !accessToken) throw new Error('No workspaceId or accessToken found');

      const { email, password, roleId } = params;
      const userId = await this.iamService.registerWithEmailPassword(email, password);
      // TODO if this fails delete user should be called
      await this.iamService.assignRoleToUser(
        {
          userId,
          roleId,
          workspaceId,
          resourceLevel: ResourceLevel.WORKSPACE,
        },
        { idToken: accessToken }
      );
      EventBus.emit(Events.WORKSPACE_USER_CREATED, fetchedState<string>(userId));
      return userId;
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceUserCreatedError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.WORKSPACE_USER_CREATED, errorResponse);
    }
  }

  // eslint-disable-next-line consistent-return
  async createEntityUserWithEmailPasswordAndRoleId(
    params: CreateWorkspaceUserParams
  ): Promise<string | void> {
    try {
      const { workspaceId, entityId, accessToken } = this.getContextValues();
      if (!workspaceId || !accessToken || !entityId)
        throw new Error('No workspaceId or entityId accessToken found');
      const { email, password, roleId } = params;
      const userId = await this.iamService.registerWithEmailPassword(email, password);
      // TODO if this fails delete user should be called
      await this.iamService.assignRoleToUser(
        {
          userId,
          roleId,
          workspaceId,
          entityId,
          resourceLevel: ResourceLevel.ENTITY,
        },
        { idToken: accessToken }
      );
      EventBus.emit(Events.ENTITY_USER_CREATED, fetchedState<string>(userId));
      return userId;
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceUserCreatedError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.ENTITY_USER_CREATED, errorResponse);
    }
  }

  // eslint-disable-next-line consistent-return
  async createReconciliationAccountUserWithEmailPasswordAndRoleId(
    params: CreateWorkspaceUserParams
  ): Promise<string | void> {
    try {
      const { workspaceId, entityId, accessToken, reconciliationAccountId } =
        this.getContextValues();
      if (!workspaceId || !accessToken || !entityId || !reconciliationAccountId)
        throw new Error('No workspaceId or entityId accessToken found');
      const { email, password, roleId } = params;
      const token = getAccessToken();
      if (!token) throw new Error('No user token found');
      const userId = await this.iamService.registerWithEmailPassword(email, password);
      // TODO if this fails delete user should be called
      await this.iamService.assignRoleToUser(
        {
          userId,
          roleId,
          workspaceId,
          entityId,
          reconciliationAccountId,
          resourceLevel: ResourceLevel.ENTITY,
        },
        { idToken: token }
      );
      EventBus.emit(Events.RECONCILIATION_ACCOUNT_USER_CREATED, fetchedState<string>(userId));
      return userId;
    } catch (error: any) {
      const errorResponse: State<null, WorkspaceUserCreatedError> = {
        status: AsyncStatus.ERROR,
        fetchedAt: Date.now(),
        data: null,
        error: error.toString(),
      };
      EventBus.emit(Events.RECONCILIATION_ACCOUNT_USER_CREATED, errorResponse);
    }
  }

  private getContextValues(): {
    workspaceId: string | null;
    entityId: string | null;
    reconciliationAccountId: string | null;
    accessToken: string | null;
  } {
    const workspaceId = LocalStorageRepository.getWorkspaceId();
    const entityId = LocalStorageRepository.getEntityId();
    const accountId = LocalStorageRepository.getAccountId();
    const accessToken = LocalStorageRepository.getAccessToken();
    return {
      workspaceId,
      entityId,
      reconciliationAccountId: accountId,
      accessToken,
    };
  }
}

export { IamRepository };
