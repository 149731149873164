import { List } from '@mui/material';
import BankTransactionBlock from '../Transaction/BankTransaction/BankTransaction';
import { Action, BTransactions, TransactionsHeader } from './Styles';
import { ManualTransaction } from '../../../models/ManualReconciliation';
import Categories from './Categories';
import { EXTERNAL_DOCUMENT_TYPES } from '../../../models/Document';
import Search from '../../Search';
import filterIcon from '../../../assets/filter.svg';
import { BankFilterPopUpDialog } from '../../CreateDialog/FilterPopUpDialog';
import SmallSimpleButton from '../../Buttons/SmallSimpleButton';

interface BankTransactionsProps {
  transactions: ManualTransaction[];
  isDateAscending: boolean;
  isCreditAscending: boolean;
  isDebitAscending: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  quickFilter: string;
  changeQuickFilter: (value: string) => void;
  toggleCreditDropdown: () => void;
  toggleDebitDropdown: () => void;
  toggleDateDropdown: () => void;
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  scrollToUnreconcilableText: () => void;
  showFilterPopUpDialog: () => void;
  selectFilteredUnreconciledManualTransactions: () => void;
}

function BankTransactions(props: BankTransactionsProps): JSX.Element {
  const {
    transactions,
    isDateAscending,
    isCreditAscending,
    isDebitAscending,
    externalDocumentType,
    quickFilter,
    changeQuickFilter,
    toggleCreditDropdown,
    toggleDebitDropdown,
    toggleDateDropdown,
    selectOrUnselectBankTransaction,
    scrollToUnreconcilableText,
    showFilterPopUpDialog,
    selectFilteredUnreconciledManualTransactions,
  } = props;
  return (
    <BTransactions>
      <BankFilterPopUpDialog />
      <TransactionsHeader>
        {externalDocumentType} Transactions
        <Action>
          <button
            type="button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Filter"
            onClick={showFilterPopUpDialog}
          >
            <img src={filterIcon} alt="filter" />
          </button>
        </Action>
        <SmallSimpleButton
          content="Select All"
          onClick={selectFilteredUnreconciledManualTransactions}
        />
      </TransactionsHeader>

      <Search
        value={quickFilter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeQuickFilter(e.target.value);
        }}
      />
      <Categories
        isDateAscending={isDateAscending}
        isCreditAscending={isCreditAscending}
        isDebitAscending={isDebitAscending}
        toggleCreditDropdown={toggleCreditDropdown}
        toggleDebitDropdown={toggleDebitDropdown}
        toggleDateDropdown={toggleDateDropdown}
      />
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          minHeight: 300,
          maxHeight: 700,
          '& ul': { padding: '1px' },
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
        subheader={<li />}
      >
        {transactions.map((transaction, index) => (
          <li key={`section-${index}`}>
            <ul>
              <BankTransactionBlock
                key={index}
                transaction={transaction}
                selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
                scrollToUnreconcilableText={scrollToUnreconcilableText}
              />
            </ul>
          </li>
        ))}
      </List>
    </BTransactions>
  );
}
export default BankTransactions;
