import FilledButton from '../../../Buttons/FilledButton';
import { AcceptAllBlock } from './Styles';

interface AcceptAllButtonProps {
  onClick: () => void;
}

function AcceptAllButton(props: AcceptAllButtonProps): JSX.Element {
  const { onClick } = props;
  return (
    <AcceptAllBlock>
      <FilledButton content="Accept all" onClick={onClick} />
    </AcceptAllBlock>
  );
}
export default AcceptAllButton;
