import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import NewEntityDialog from './component';
import { useIamViewModel } from '../../../view-models/IamViewModel';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';
import { WorkspaceUser } from '../../../models/WorkspaceUser';
import { useEntityViewModel } from '../../../view-models/EntityViewModel';


const NewEntityController = () => {
  const iamViewModel = useIamViewModel();
  const workspaceViewModel = useWorkspacesViewModel();
  const entityViewModel = useEntityViewModel();
  const [myUser, setMyUser] = useState<WorkspaceUser | null>(null);
  const [open, setOpen] = useState(entityViewModel.isCreateEntityDialogVisible);

  useEffect(() => {
    if (iamViewModel.user) {
      setMyUser({
        id: iamViewModel.user.id,
        name: iamViewModel.user?.name,
        email: iamViewModel.user?.email,
      });
    }
  }, [iamViewModel.user]);

  useEffect(() => {
    console.log(
      'useEffect isCreateEntityDialogVisible',
      entityViewModel.isCreateEntityDialogVisible
    );
    setOpen(entityViewModel.isCreateEntityDialogVisible);
  }, [entityViewModel.isCreateEntityDialogVisible]);

  return (
    (myUser && open && (
      <NewEntityDialog
        open={open}
        closeDialog={() => {
          console.log('close dialog');
          entityViewModel.hideCreateEntityDialog();
        }}
        submit={entityViewModel.createEntity}
        countries={workspaceViewModel.workspaceCountries}
        languages={workspaceViewModel.workspaceLanguages}
        entityName={entityViewModel.name}
        setEntityName={entityViewModel.setName}
        areRequiredFieldsSet={entityViewModel.areRequiredFieldsSet}
        country={entityViewModel.country}
        setCountry={entityViewModel.setCountry}
        language={entityViewModel.language}
        setLanguage={entityViewModel.setLanguage}
        uniqueIdentifier={entityViewModel.uniqueIdentifier}
        setUniqueIdentifier={entityViewModel.setUniqueIdentifier}
      />
    )) ||
    null
  );
};

export default observer(NewEntityController);
