import { styled } from '@mui/material';
import { CreditBase, DebitBase } from '../../Styles';

export const Headers: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  margin: '0px',
});

export const Header: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

export const HeaderName: any = styled('div')(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: '100%',
}));

export const Categories: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px 10px',
  boxSizing: 'border-box',
  width: 'var(--transaction-max-width)',
  margin: '10px 0px 5px 0px',
  fontSize: 'var(--transaction-header-font-size)',
  color: '#5E6266',
});

export const HeaderDebit: any = styled(DebitBase)({});

export const HeaderCredit: any = styled(CreditBase)({});
