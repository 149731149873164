type AccessType = {
  roles: { _name: string; _id: string }[];
};

type ReconciliationReportAccessType = {
  [key: string]: AccessType;
};

type ReconciliationAccountType = {
  access: AccessType;
  reconciliationReports?: {
    [key: string]: ReconciliationReportAccessType;
  };
};

type EntitiesType = {
  access: AccessType;
  reconciliationAccounts?: {
    [key: string]: ReconciliationAccountType;
  };
};

type WorkspaceType = {
  access: AccessType;
  entities?: {
    [key: string]: EntitiesType;
  };
};

export type UserAccessType = {
  workspaces?: {
    [key: string]: WorkspaceType;
  };
};

export type User = {
  id: string;
  name?: string;
  access?: UserAccessType;
  isBlacklisted?: boolean;
  companyName?: string;
  country?: string;
  email?: string;
  phone?: string;
  industry?: string;
  jwt?: string;
};

export type EditUserParams = {
  name: string;
  companyName: string;
  country: string;
  email: string;
  phone: string;
  industry: string;
};

export type UpdateUserDetailsRequestParams = {
  email?: string;
  oldPassword?: string;
  newPassword?: string;
  name?: string;
  phone?: string;
  companyName?: string;
  industry?: string;
  country?: string;
};

export type UserRoleType = {
  id: string;
  name: string;
  displayName: string;
};

export const AVAILABLE_USER_ROLES: Record<string, Record<string, UserRoleType>> = {
  WORKSPACE: {
    WORKSPACE_MEMBER: {
      id: '0ea3e47d-ebd0-4522-8828-1c03efe5ea21',
      name: 'workspace:member',
      displayName: 'Workspace Member',
    },
    WORKSPACE_ADMIN: {
      id: 'f461ebfe-c45f-41fe-99e3-d6f69e22844e',
      name: 'workspace:admin',
      displayName: 'Workspace Admin',
    },
    WORKSPACE_OWNER: {
      id: '4e59df5e-1b6b-474e-834c-47a62c7bda38',
      name: 'workspace:owner',
      displayName: 'Workspace Owner',
    },
  },
  ENTITY: {
    ENTITY_MEMBER: {
      id: 'bf0a116a-162b-4d9d-91ea-d12f661c7e07',
      name: 'entity:member',
      displayName: 'Entity Member',
    },
    ENTITY_GUEST: {
      id: '75aca13d-f06e-4cfe-bcb1-a982edf0b476',
      name: 'entity:guest',
      displayName: 'Entity Guest',
    },
  },
  ACCOUNT: {
    ACCOUNT_MEMBER: {
      id: 'e9912f5e-75fd-408b-a0da-1d4c09a63093',
      name: 'reconciliationAccount:member',
      displayName: 'Account Member',
    },
    ACCOUNT_GUEST: {
      id: '486c2e8a-aa87-4ecd-9fe9-b9c25ed9042f',
      name: 'reconciliationAccount:guest',
      displayName: 'Account Guest',
    },
  },
};
