import { styled } from '@mui/material/styles';

export const Layout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   padding: '0px',
  boxSizing: 'border-box',
  //   height: 'auto',
  width: '100%',
});

export const Empty: any = styled('div')({
  display: 'flex',
  justifyContent: 'left',
  fontSize: '18px',
  width: 'var(--page-max-width)',
});

export const PercentageInfo: any = styled('div')(({ theme }: any) => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  fontSynthesis: 'none',
  color: 'var(--bank-color)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
