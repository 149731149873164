import { styled } from '@mui/material/styles';

export const UploadFile: any = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  height: 'auto',
  width: 'fit-content',
});

export const Icon: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '6px 2px',
  boxSizing: 'border-box',
  margin: '0px',
  overflow: 'hidden',
  width: '48px',
});

export const Content: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '383px',
  margin: '0px 0px 0px 24px',
});

export const Description: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '252px',
  margin: '0px',
});

export const Header: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '13px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
});

export const Subheader: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 0.4)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '261px',
  margin: '10px 0px 0px 0px',
});
