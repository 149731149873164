import { styled } from '@mui/material/styles';

export const Transactions: any = styled('div')({
  display: 'flex',
  gap: '10px',
  position: 'relative',
  isolation: 'isolate',
  // flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});

export const BankTransactions: any = styled('div')({
  display: 'flex',
  position: 'relative',
  // gap: '20px',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});

export const LedgerTransactions: any = styled('div')({
  display: 'flex',
  position: 'relative',
  // gap: '20px',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
});
