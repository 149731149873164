import React, { useState, JSX } from 'react';
import VisibleText from './VisibleText';
import lockedIcon from '../../../../assets/locked.svg';
import draftIcon from '../../../../assets/draft.svg';
import editIcon from '../../../../assets/edit.svg';
import { Name, SmallScreen, LargeScreen, Action } from './Styles';
import { RECONCILIATION_REPORT_STATUSES } from '../../../../models/CreateReconciliationReport';
import { ReconciliationReportDetails } from '../../../../models/ReconciliationReport';

interface ColumnProps {
  content: string;
  type?: 'header' | 'body';
  reportStatus?: string;
  reportId?: string;
  editReconciliationReportDetails?: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
}

export default function NameColumn(props: ColumnProps): JSX.Element {
  const { type, content, reportStatus, reportId, editReconciliationReportDetails } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(content);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      if (editReconciliationReportDetails) {
        editReconciliationReportDetails(reportId!, { name: editedText });
      }
    }
  };

  return (
    <Name
      sx={{
        width: {
          xs: '100px',
          lg: '180px',
        },
      }}
    >
      {type === 'body' ? (
        <>
          {reportStatus !== RECONCILIATION_REPORT_STATUSES.LOCKED ? (
            <img src={draftIcon} width={16} height={16} alt="Draft" />
          ) : (
            <img src={lockedIcon} width={16} height={16} alt="Locked" />
          )}
          <SmallScreen
            sx={{
              display: {
                xs: 'flex',
                lg: 'none',
              },
            }}
          >
            {!isEditing ? (
              <VisibleText text={editedText} maxCharacters={10} />
            ) : (
              <input
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            )}
          </SmallScreen>
          <LargeScreen
            sx={{
              display: {
                xs: 'none',
                lg: 'flex',
              },
            }}
          >
            {!isEditing ? (
              <VisibleText text={editedText} maxCharacters={18} />
            ) : (
              <input
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            )}
          </LargeScreen>

          {reportStatus !== RECONCILIATION_REPORT_STATUSES.LOCKED ? (
            <Action>
              <button
                type="button" // Add the type attribute with the value "button"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                }}
                aria-label="Edit"
                onClick={() => {
                  if (!isEditing) setIsEditing(true);
                  else setIsEditing(false);
                }}
              >
                <img src={editIcon} alt="edit" />
              </button>
            </Action>
          ) : null}
        </>
      ) : (
        content
      )}
    </Name>
  );
}
