import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { CreateReconciliationReportPage as CreateReconciliationReportPageComponent } from './page';
import { useDocumentsViewModel } from '../../view-models/DocumentsViewModel';
import {
  useReconciliationReportViewModel,
  useReconciliationReportViewModelManager,
} from '../../view-models/ReconciliationReportViewModel';
import { useSuggestedMatchesViewModelManager } from '../../view-models/SuggestedMatchesViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';

export const CreateReconciliationReportController: React.FC = observer(() => {
  const reconciliationReportViewModelManager = useReconciliationReportViewModelManager();
  const suggestedMatchesViewModelManager = useSuggestedMatchesViewModelManager();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  const documentsViewModel = useDocumentsViewModel();
  const reconciliationReportViewModel = useReconciliationReportViewModel();

  const [initialRun, setInitialRun] = React.useState(true);

  useEffect(() => {
    reconciliationReportViewModelManager.resetViewModel();
    suggestedMatchesViewModelManager.clearViewModel();
    documentsViewModel.clearDocuments();
    setInitialRun(false);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (!initialRun && reconciliationReportViewModel && reconciliationReportViewModel.created) {
      navigate(
        `/reconciliation-report/${reconciliationReportViewModel.reconciliationReportId}/suggested-matches`
      );
    }
  }, [initialRun, reconciliationReportViewModel, reconciliationReportViewModel?.created]);

  useEffect(() => {
    if (reconciliationReportViewModel) {
      reconciliationReportViewModel.fetchReconciliationAccountReports();
    }
  }, []);
  // TODO useEffect that will get and empty array

  if (reconciliationReportViewModel)
    return (
      <CreateReconciliationReportPageComponent
        ledgerNotInOurTemplateUploadFileStatus={
          documentsViewModel.ledgerNotInOurTemplateUploadFileStatus
        }
        bankNotInOurTemplateUploadFileStatus={
          documentsViewModel.bankNotInOurTemplateUploadFileStatus
        }
        bankUploadFileStatus={documentsViewModel.bankUploadFileStatus}
        ledgerUploadFileStatus={documentsViewModel.ledgerUploadFileStatus}
        previousReconciliationReports={
          reconciliationReportViewModel.previousReconciliationReportsOrderedByCreatedAt
        }
        status={reconciliationReportViewModel.status}
        progress={reconciliationReportViewModel.progress}
        message={reconciliationReportViewModel.message}
        id={reconciliationReportViewModel.reconciliationReportId || ''}
        bankDocumentId={documentsViewModel.bankDocumentId}
        bankDocumentIdNotInOurTemplate={documentsViewModel.bankDocumentIdNotInOurTemplate}
        ledgerDocumentId={documentsViewModel.ledgerDocumentId}
        ledgerDocumentIdNotInOurTemplate={documentsViewModel.ledgerDocumentIdNotInOurTemplate}
        total={reconciliationReportViewModel.total}
        totalReconciled={reconciliationReportViewModel.totalReconciled}
        reconciliationReportCreated={reconciliationReportViewModel.created}
        reconciliationReportStarted={reconciliationReportViewModel.status !== 'idle'}
        bankDocumentUploaded={documentsViewModel.bankDocumentUploaded}
        bankDocumentNotInOurTemplateUploaded={
          documentsViewModel.bankDocumentNotInOurTemplateUploaded
        }
        reconciliationAccount={
          reconciliationAccountViewModel.reconciliationAccount.data?.name || ''
        }
        createReconciliationReport={reconciliationReportViewModel.createReconciliationReport}
        createReconciliationReportWithCompensatingLedgerTransaction={
          reconciliationReportViewModel.createReconciliationReportWithComplementaryLedgerTransaction
        }
        uploadFile={documentsViewModel.uploadDocument}
        uploadFileNotInOurTemplate={documentsViewModel.uploadDocumentNotInOurTemplate}
        filesUploaded={documentsViewModel.filesUploaded}
        ledgerDocumentUploaded={documentsViewModel.ledgerDocumentUploaded}
        ledgerDocumentNotInOurTemplateUploaded={
          documentsViewModel.ledgerDocumentNotInOurTemplateUploaded
        }
        reconciliationReportPeriodSet={reconciliationReportViewModel.reconciliationReportPeriodSet}
        externalDocumentType={reconciliationAccountViewModel.externalDocumentType}
        ledgerDocumentType={reconciliationAccountViewModel.ledgerDocumentType}
        setLedgerUploadFileStatus={documentsViewModel.setLedgerUploadFileStatus}
        setBankUploadFileStatus={documentsViewModel.setBankUploadFileStatus}
        setBankNotInOurTemplateUploadFileStatus={
          documentsViewModel.setBankNotInOurTemplateUploadFileStatus
        }
        setLedgerNotInOurTemplateUploadFileStatus={
          documentsViewModel.setLedgerNotInOurTemplateUploadFileStatus
        }
      />
    );
  return null;
});

// const observerPage = observer(CreateReconciliationReportController);

export { CreateReconciliationReportController as CreateReconciliationReportPage };
