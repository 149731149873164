import TableBody from './Styles';
import Report from './Report';
import {
  ReconciliationAccountReport,
  ReconciliationReportDetails,
} from '../../../../models/ReconciliationReport';

interface BodyProps {
  reports: ReconciliationAccountReport[];

  navigateToReport: (reportId: string) => void;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
  editReconciliationReportDetails: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
  showDeleteReconciliationReportDialog: (reportId: string) => void;
  isReconciliationAccountReportLocked: (reportId: string) => boolean;
}

function Body(props: BodyProps) {
  const {
    reports,
    navigateToReport,
    downloadAdjustmentsReport,
    downloadReconciliationReport,
    editReconciliationReportDetails,
    showDeleteReconciliationReportDialog,
    isReconciliationAccountReportLocked,
  } = props;

  return (
    <TableBody
      sx={{
        fontSize: {
          xs: '12px',
          lg: '16px',
        },
      }}
    >
      {reports.map((report, index) => (
        <Report
          key={report.id}
          report={report}
          numberOfReports={reports.length}
          index={index}
          navigateToReport={navigateToReport}
          downloadAdjustmentsReport={downloadAdjustmentsReport}
          downloadReconciliationReport={downloadReconciliationReport}
          editReconciliationReportDetails={editReconciliationReportDetails}
          showDeleteReconciliationReportDialog={showDeleteReconciliationReportDialog}
          isReconciliationAccountReportLocked={isReconciliationAccountReportLocked}
        />
      ))}
    </TableBody>
  );
}

export default Body;
