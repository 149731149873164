import { styled } from '@mui/material/styles';

export const Layout: any = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  boxSizing: 'border-box',
});

export const Text404: any = styled('img')({
  height: '277.96px',
  width: '634.58px',
  margin: '0px',
});

export const Text: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontFamily: 'Futura',
  fontWeight: '500',
  fontSize: '32px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '60px 0px 0px 0px',
});

export const Button: any = styled('div')({
  display: 'flex',
  position: 'relative',
  margin: '40px 0px 0px 0px',
});
