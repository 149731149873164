import { observer } from 'mobx-react-lite';
import DateRange from '../../../DateRange/component';
import { useReconciliationReportViewModel } from '../../../../view-models/ReconciliationReportViewModel';

const BankDateController = () => {
  const reconciliationReportViewModel = useReconciliationReportViewModel();
  const content = reconciliationReportViewModel ? (
    <DateRange
      startDate={reconciliationReportViewModel.bankFilterFromDate}
      endDate={reconciliationReportViewModel.bankFilterToDate}
      showErrorState={false}
      editStartDate={reconciliationReportViewModel.setBankFilterFromDate}
      editEndDate={reconciliationReportViewModel.setBankFilterToDate}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
  return content;
};

const BankDate = observer(BankDateController);

export default BankDate;
