import React, { JSX } from 'react';
import UploadStatements from './UploadStatements/UploadStatements';
import FixedBottomButton from '../Buttons/FixedBottomButton';
import ProgressDialog from '../ProgressDialog/ProgressDialog';
import { DOCUMENT_TYPE } from '../../models/UploadFileData';
// import {
//   Workspace,
//   // EntitySlim,
// } from '../../models/Resources';
import CreateLedgerTransactionDialog from './CreateLedgerTransactionDialog';
import PreviousReconciliationReport from './PreviousReconciliationReport';
import { ReconciliationAccountReport } from '../../models/ReconciliationReport';
import ReconciliationPeriod from './ReconciliationPeriod';
import {
  CONCRETE_TYPES,
  EXTERNAL_DOCUMENT_TYPES,
  LEDGER_DOCUMENT_TYPES,
} from '../../models/Document';
import { Period, PreviousReportAndPeriod, Title, TitleAndOptions } from './Styles';
import { STATUS_STATES } from '../../types';
import { LedgerMissingOpeningBalanceDialog } from '../CreateDialog/MissingOpeningBalanceDialog/Ledger';
import { LedgerMissingFormatTypeDialog } from '../CreateDialog/DocumentFormatTypeMissingDialog/Ledger';
import { BankMissingOpeningBalanceDialog } from '../CreateDialog/MissingOpeningBalanceDialog/Bank';
import { BankMissingFormatTypeDialog } from '../CreateDialog/DocumentFormatTypeMissingDialog/Bank';
import { BankDebitsCreditsSwappedDialog } from '../CreateDialog/DebitsCreditsSwappedDialog/Bank';
import { LedgerDebitsCreditsSwappedDialog } from '../CreateDialog/DebitsCreditsSwappedDialog/Ledger';
// import Input from '../Inputs/Input';
interface CreateReconciliationReportProps {
  // workspace?: Workspace | null;
  ledgerNotInOurTemplateUploadFileStatus: STATUS_STATES;
  bankNotInOurTemplateUploadFileStatus: STATUS_STATES;
  ledgerUploadFileStatus: STATUS_STATES;
  bankUploadFileStatus: STATUS_STATES;
  progress: number;
  message: string;
  // id: string;
  total: number;
  totalReconciled: number;
  // reconciliationReportCreated: boolean;
  reconciliationReportStarted: boolean;
  bankDocumentId: string;
  bankDocumentIdNotInOurTemplate: string;
  ledgerDocumentIdNotInOurTemplate: string;
  ledgerDocumentId: string;
  filesUploaded: boolean;
  ledgerDocumentUploaded: boolean;
  ledgerDocumentNotInOurTemplateUploaded: boolean;
  previousReconciliationReportId?: string;
  previousReconciliationReports: ReconciliationAccountReport[];
  reconciliationReportPeriodSet: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  bankDocumentUploaded: boolean;
  bankDocumentNotInOurTemplateUploaded: boolean;
  reconciliationAccount: string;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
  createReconciliationReport: (params: {
    externalDocumentId: string;
    ledgerDocumentId: string;
    externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
    ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
    previousReconciliationReportId?: string;
  }) => void;
  // createReconciliationReportWithCompensatingLedgerTransaction: (params: {
  //   externalDocumentId: string;
  //   ledgerDocumentId: string;
  //   previousReconciliationReportId?: string;
  // }) => void;
  uploadFileNotInOurTemplate: (
    file: File,
    type: DOCUMENT_TYPE,
    concreteType: CONCRETE_TYPES
  ) => Promise<void>;
  setBankUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerUploadFileStatus: (value: STATUS_STATES) => void;
  setBankNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
}

function CreateReconciliationReport(props: CreateReconciliationReportProps): JSX.Element {
  const {
    progress,
    message,
    // id,
    total,
    totalReconciled,
    previousReconciliationReportId,
    bankDocumentUploaded,
    bankDocumentNotInOurTemplateUploaded,
    createReconciliationReport,
    uploadFile,
    bankDocumentId,
    bankDocumentIdNotInOurTemplate,
    ledgerDocumentId,
    ledgerDocumentUploaded,
    ledgerDocumentNotInOurTemplateUploaded,
    ledgerDocumentIdNotInOurTemplate,
    reconciliationReportStarted,
    filesUploaded,
    previousReconciliationReports,
    reconciliationReportPeriodSet,
    externalDocumentType,
    ledgerDocumentType,
    bankUploadFileStatus,
    ledgerUploadFileStatus,
    ledgerNotInOurTemplateUploadFileStatus,
    bankNotInOurTemplateUploadFileStatus,
    setBankNotInOurTemplateUploadFileStatus,
    setLedgerNotInOurTemplateUploadFileStatus,
    setBankUploadFileStatus,
    setLedgerUploadFileStatus,
    uploadFileNotInOurTemplate,
    reconciliationAccount,
  } = props;
  const handleNextStep = async () => {
    let externalDocumentId;
    if (bankDocumentUploaded) {
      externalDocumentId = bankDocumentId;
    } else {
      externalDocumentId = bankDocumentIdNotInOurTemplate;
    }
    let ldgrDocumentId;
    if (ledgerDocumentUploaded) {
      ldgrDocumentId = ledgerDocumentId;
    } else {
      ldgrDocumentId = ledgerDocumentIdNotInOurTemplate;
    }
    await createReconciliationReport({
      ledgerDocumentId: ldgrDocumentId,
      externalDocumentId,
      externalDocumentType,
      ledgerDocumentType,
      previousReconciliationReportId,
    });
  };

  return (
    <>
      <UploadStatements
        ledgerNotInOurTemplateUploadFileStatus={ledgerNotInOurTemplateUploadFileStatus}
        bankNotInOurTemplateUploadFileStatus={bankNotInOurTemplateUploadFileStatus}
        ledgerUploadFileStatus={ledgerUploadFileStatus}
        bankUploadFileStatus={bankUploadFileStatus}
        disableBankDocumentUpload={bankDocumentNotInOurTemplateUploaded}
        disableBankDocumentNotInOurTemplateUpload={bankDocumentUploaded}
        disableLedgerDocumentUpload={ledgerDocumentNotInOurTemplateUploaded}
        disableLedgerDocumentNotInOurTemplateUpload={ledgerDocumentUploaded}
        uploadFile={uploadFile}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        reconciliationAccount={reconciliationAccount}
        uploadFileNotInOurTemplate={uploadFileNotInOurTemplate}
        setBankUploadFileStatus={setBankUploadFileStatus}
        setLedgerUploadFileStatus={setLedgerUploadFileStatus}
        setBankNotInOurTemplateUploadFileStatus={setBankNotInOurTemplateUploadFileStatus}
        setLedgerNotInOurTemplateUploadFileStatus={setLedgerNotInOurTemplateUploadFileStatus}
      />

      <TitleAndOptions>
        <Title>Reconciliation Period</Title>
        <PreviousReportAndPeriod>
          <PreviousReconciliationReport
            previousReconciliationReports={previousReconciliationReports}
          />
          <Period>
            <ReconciliationPeriod />
          </Period>
        </PreviousReportAndPeriod>
      </TitleAndOptions>

      <FixedBottomButton
        disabled={!filesUploaded || !reconciliationReportPeriodSet}
        content="Next"
        onClick={handleNextStep}
        //   disabled={enableNextStep}
      />
      <ProgressDialog
        open={reconciliationReportStarted}
        progress={progress}
        message={message}
        total={total}
        totalReconciled={totalReconciled}
      />
      <CreateLedgerTransactionDialog />
      <LedgerMissingOpeningBalanceDialog />
      <LedgerMissingFormatTypeDialog />
      <LedgerDebitsCreditsSwappedDialog />
      <BankMissingOpeningBalanceDialog />
      <BankMissingFormatTypeDialog />
      <BankDebitsCreditsSwappedDialog />
    </>
  );
}
export default CreateReconciliationReport;
