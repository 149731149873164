import { styled } from '@mui/material/styles';

export const StopListTable = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'rgba(235, 235, 235, 0.1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  position: 'relative',
  isolation: 'isolate',
  height: 'auto',
});

export const TableHeader = styled('div')({
  display: 'flex',
  padding: '15px 30px',
  fontSize: '24px',
});

export const TableBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px 30px',
});

export const Block: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  height: '60px',
  padding: '2px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  overflow: 'visible',
  zIndex: 1,
});

export const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Field: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
});

export const Actions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
});

export const Action = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});
