import { styled } from '@mui/material/styles';

const TableBodyWorkspace = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px 30px',
  backgroundColor: 'rgba(217, 217, 217, 0.1)',
});

export default TableBodyWorkspace;
