import SimpleButton from '../../Buttons/SimpleButton';
import { ButtonsLayout } from './Styles';

interface PaginationActionsProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function PaginationActions(props: PaginationActionsProps) {
  const { count, rowsPerPage, page, onPageChange } = props;
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };
  return (
    <ButtonsLayout>
      <SimpleButton content="Previous Page" onClick={handleBackButtonClick} disabled={page === 0} />
      <SimpleButton
        content="Next Page"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      />
    </ButtonsLayout>
  );
}

export default PaginationActions;
