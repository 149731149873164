import { styled } from '@mui/material/styles';

export const AuthLayout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  fontFamily: 'var(--font-family)',
});

export const LeftSide: any = styled('div')({
  backgroundColor: 'var(--primary-color)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  overflow: 'hidden',
  width: '50%',
  height: '100vh',
});

export const FullLogo: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  overflow: 'visible',
});

export const Vector: any = styled('img')({
  height: '170.34px',
  width: '471.8px',
  margin: '0px',
});

export const Subheader: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '100px 0px 0px 0px',
});

export const Welcome: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '32px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

export const Text: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '20px 0px 0px 0px',
});

export const RightSide: any = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  display: 'flex',
  position: 'absolute',
  isolation: 'isolate',
  right: '0px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px 0px 0px 4px',
  width: '50%',
  height: '100vh',
});
