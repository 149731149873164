import * as XLSX from 'xlsx';

import {
  BanksSupported,
  CUSTOM_OPTIONS,
  LEDGER_ERP_OPTIONS,
} from '../../view-models/ConvertersAndTemplatesViewModel';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../models/Document';
import FollowTemplates from './FollowTemplates/FollowTemplates';
import ConvertBankFormat from './ConvertBankFormat';
import PdfToExcel from './PdfToExcel/PdfToExcel';
import ConvertLedgerFormat from './ConvertLedgerFormat/ConvertLedgerFormat';
import ConvertCustomFormat from './ConvertCustomFormat/ConvertCustomFormat';
import { STATUS_STATES } from '../../types';
import { Converters } from './Styles';
import Line from '../Line';

interface ConvertersAndTemplatesProps {
  pdfUpladFileStatus: STATUS_STATES;
  customUploadFileStatus: STATUS_STATES;
  bankUploadFileStatus: STATUS_STATES;
  ledgerUploadFileStatus: STATUS_STATES;
  customOptions: CUSTOM_OPTIONS[];
  selectedCustom: CUSTOM_OPTIONS;
  erpOptions: LEDGER_ERP_OPTIONS[];
  bankTransactionTypes: BanksSupported[];
  convertBankDocument: (ws: XLSX.WorkSheet, fileName: string, bankType: BanksSupported) => void;
  selectedBank: BanksSupported;
  selectedERP: LEDGER_ERP_OPTIONS;
  setSelectedBank: (value: BanksSupported) => void;
  uploadedBankFile: File | null;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  handleBankFileUpload: (file: File) => void;
  handleLedgerFileUpload: (file: File) => void;
  handlePdfFileUpload: (file: File) => void;
  downloadLedgerTemplate: () => void;
  downloadBankTemplate: () => void;
  convertPdfDocument: () => void;
  convertLedgerDocument: () => void;
  setSelectedERP: (value: LEDGER_ERP_OPTIONS) => void;
  handleCustomFileUpload: (file: File) => void;
  convertCustomDocument: () => void;
  setSelectedCustom: (value: CUSTOM_OPTIONS) => void;
  setLedgerUploadFileStatus: (value: STATUS_STATES) => void;
  setBankUploadFileStatus: (value: STATUS_STATES) => void;
  setCustomUploadFileStatus: (value: STATUS_STATES) => void;
  setPdfUploadFileStatus: (value: STATUS_STATES) => void;
}

function ConvertersAndTemplates(props: ConvertersAndTemplatesProps): JSX.Element {
  const {
    pdfUpladFileStatus,
    customUploadFileStatus,
    bankUploadFileStatus,
    ledgerUploadFileStatus,
    customOptions,
    selectedCustom,
    erpOptions,
    bankTransactionTypes,
    convertBankDocument,
    selectedBank,
    selectedERP,
    setSelectedBank,
    uploadedBankFile,
    externalDocumentType,
    ledgerDocumentType,
    handleLedgerFileUpload,
    handlePdfFileUpload,
    handleBankFileUpload,
    downloadLedgerTemplate,
    downloadBankTemplate,
    convertPdfDocument,
    convertLedgerDocument,
    setSelectedERP,
    handleCustomFileUpload,
    convertCustomDocument,
    setSelectedCustom,
    setLedgerUploadFileStatus,
    setBankUploadFileStatus,
    setCustomUploadFileStatus,
    setPdfUploadFileStatus,
  } = props;

  return (
    <div>
      <FollowTemplates
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        downloadLedgerTemplate={downloadLedgerTemplate}
        downloadBankTemplate={downloadBankTemplate}
      />
      <Converters>
        <PdfToExcel
          uploadFileStatus={pdfUpladFileStatus}
          uploadFile={handlePdfFileUpload}
          convertDocument={convertPdfDocument}
          setUploadFileStatus={setPdfUploadFileStatus}
        />
        <Line />
        <ConvertBankFormat
          uploadFileStatus={bankUploadFileStatus}
          transactionTypes={bankTransactionTypes}
          selectedBank={selectedBank}
          uploadedFile={uploadedBankFile}
          setSelectedBank={setSelectedBank}
          convertDocument={convertBankDocument}
          handleFileUpload={handleBankFileUpload}
          setUploadFileStatus={setBankUploadFileStatus}
        />
        <Line />
        <ConvertLedgerFormat
          uploadFile={handleLedgerFileUpload}
          convertDocument={convertLedgerDocument}
          setSelectedERP={setSelectedERP}
          setUploadFileStatus={setLedgerUploadFileStatus}
          selectedERP={selectedERP}
          erpOptions={erpOptions}
          uploadFileStatus={ledgerUploadFileStatus}
        />
        <Line />
        <ConvertCustomFormat
          uploadFileStatus={customUploadFileStatus}
          uploadFile={handleCustomFileUpload}
          convertDocument={convertCustomDocument}
          setSelectedCustom={setSelectedCustom}
          setUploadFileStatus={setCustomUploadFileStatus}
          selectedCustom={selectedCustom}
          customOptions={customOptions}
        />
      </Converters>
    </div>
  );
}

export default ConvertersAndTemplates;
