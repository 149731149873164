import React from 'react';
import { TransactionProps } from '../../../types';
import { BankTransaction, BankDebit, BankCredit } from './Styles';
import Description from '../DescriptionColumn';
import { Date, Reference } from '../Styles';

function BankTransactionBlock(props: TransactionProps): JSX.Element {
  const { date, description, reference, debit, credit } = props;

  return (
    <BankTransaction>
      <Date>{date}</Date>
      <Description content={description} />
      <Reference>{reference}</Reference>
      <BankDebit>{debit !== undefined && debit !== '' && debit !== '0.00' && debit}</BankDebit>
      <BankCredit>
        {credit !== undefined && credit !== '' && credit !== '0.00' && credit}
      </BankCredit>
    </BankTransaction>
  );
}

export default BankTransactionBlock;
