export enum UPLOADING_STATUS {
  UPLOADING,
  UPLOADED,
  ERROR,
  IDLE,
}

export type DocumentContext = {
  workspaceId: string;
  entityId: string;
  accountId: string;
};

export enum EXTERNAL_DOCUMENT_TYPES {
  BANK = 'Bank',
  SUPPLIER = 'Supplier',
  CUSTOMER = 'Customer',
  DEBIT = 'Debit',
  DATASET_A = 'DatasetA',
}

export enum LEDGER_DOCUMENT_TYPES {
  LEDGER = 'Ledger',
  CREDIT = 'Credit',
  INTERNAL = 'Internal',
}

export enum CONCRETE_TYPES {
  BANK_STATEMENT = 'BankStatement',
  LEDGER = 'Ledger',
  CUSTOMER = 'Customer',
  SUPPLIER = 'Supplier',
  INTENRAL = 'Internal',
  DATASET_A = 'DatasetA',
}
