export function roundToTwoDecimals(num: number): string {
  const roundedNum = num.toFixed(2);
  return roundedNum.endsWith('.00') ? roundedNum.slice(0, -3) : roundedNum;
}

export const fixToTwoDecimals = (num: number): string => num.toFixed(2);

export const fixToThousandSeparator = (num: string): string =>
  num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isPositive = (num: number): boolean => num > 0;

export const isNegative = (num: number): boolean => num < 0;

export const isPositiveOrZero = (num: number): boolean => num >= 0;

export const valueIncreases = (numBefore: number, numAfter: number): boolean =>
  numBefore <= numAfter;

export const getAbsoluteDifference = (num1: number, num2: number): number => Math.abs(num1 - num2);

export const getAbsoluteValue = (num: number): number => Math.abs(num);

export const sortNumbersArray = (data: any[][]): any[][] =>
  data.sort((a, b) => {
    const dateA = a[0];
    const dateB = b[0];

    return dateA - dateB;
  });
