import FullWidthTabs from '../FullWidthTabs';
import ProfileTabContent from './ProfileTabContent/ProfileTabContent';
import SettingsTabContent from './SettingsTabContent/SettingsTabContent';
import UsersTabContent from '../Tabs/UsersTabContent/UsersTabContent';
import { EditWorkspaceParams } from '../../models/Resources';
import { User, UserRoleType } from '../../models/User';

interface WorkspaceProps {
  countries: string[];
  languages: string[];
  users: User[];
  userAvailableRoles: UserRoleType[];
  updateParams: EditWorkspaceParams;
  updateWorkspaceName: (name: string) => void;
  updateCountry: (country: string) => void;
  updateLanguage: (language: string) => void;
  updateWorkspace: (updateParams: Partial<EditWorkspaceParams>) => void;
  deleteWorkspace: () => Promise<void>;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
  fetchUsersDetails: () => void;
}

function Workspace(props: WorkspaceProps): JSX.Element {
  const {
    countries,
    languages,
    users,
    updateCountry,
    updateLanguage,
    updateWorkspaceName,
    updateParams,
    updateWorkspace,
    deleteWorkspace,
    fetchUsersDetails,
    userAvailableRoles,
    createUserWithEmailAndRoleId,
  } = props;

  return (
    <FullWidthTabs
      tabs={[
        {
          label: 'Workspace Profile',
          content: (
            <ProfileTabContent
              countries={countries}
              languages={languages}
              updateParams={updateParams}
              updateCountry={updateCountry}
              updateLanguage={updateLanguage}
              updateWorkspaceName={updateWorkspaceName}
              updateWorkspace={updateWorkspace}
              deleteWorkspace={deleteWorkspace}
            />
          ),
        },
        {
          label: 'Reconciliation Configuration',
          content: <SettingsTabContent />,
        },
        {
          label: 'Users',
          content: (
            <UsersTabContent
              users={users}
              userAvailableRoles={userAvailableRoles}
              createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
            />
          ),
          onTabOpen: () => {
            fetchUsersDetails();
          },
        },
      ]}
    />
  );
}
export default Workspace;
