import React, { JSX } from 'react';
import { BlockContent, Date, Reference, Debit, Title } from './Styles';

import DescriptionColumn from './DescriptionColumn';

interface InnerBlockContentProps {
  date: string;
  description: string;
  reference: string;
  amount: string;
}

function InnerBlockContent(props: InnerBlockContentProps): JSX.Element {
  const { date, description, reference, amount } = props;
  return (
    <BlockContent>
      <Date>
        <Title>{date}</Title>
      </Date>
      <DescriptionColumn content={description} />
      <Reference>
        <Title>{reference}</Title>
      </Reference>
      <Debit>
        <Title>{amount}</Title>
      </Debit>
    </BlockContent>
  );
}

export default InnerBlockContent;
