import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ErrorDialog from './component';
import { useUIViewModel } from '../../view-models/UIViewModel';

const ConfirmationDialogController = () => {
  const uiViewModel = useUIViewModel();
  const [open, setOpen] = useState(uiViewModel.isErrorDialogVisible);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(uiViewModel.isErrorDialogVisible);
  }, [uiViewModel.isErrorDialogVisible]);

  useEffect(() => {
    if (uiViewModel.errorDialogHided) {
      navigate('/dashboard');
    }
  }, [uiViewModel.errorDialogHided]);

  return (
    (open && (
      <ErrorDialog
        errorMessage={uiViewModel.errorDialogMessage}
        errorButtonMessage={uiViewModel.errorDialogButtonMessage}
        errorTitle={uiViewModel.errorDialogTitle}
        open={open}
        closeDialog={() => {
          uiViewModel.hideErrorDialog();
        }}
        submit={() => {
          uiViewModel.hideErrorDialog();
        }}
      />
    )) ||
    null
  );
};

export default observer(ConfirmationDialogController);
