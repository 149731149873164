import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputBlock from '../Input';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import { AsyncStatus } from '../../../types';

interface NewWorkspaceDialogProps {
  open: boolean;
  countries: string[];
  languages: string[];
  name: string;
  country: string;
  language: string;
  workspaceCreationStatus: AsyncStatus;
  submitAction: () => void;
  closeDialog: () => void;
  areRequiredFieldsSet: boolean;
  setName: (value: string) => void;
  setCountry: (value: string) => void;
  setLanguage: (value: string) => void;
}
function NewWorkspaceDialog(props: NewWorkspaceDialogProps) {
  const {
    open,
    name,
    setName,
    country,
    setCountry,
    language,
    setLanguage,
    countries,
    languages,
    closeDialog,
    submitAction,
    areRequiredFieldsSet,
    workspaceCreationStatus,
  } = props;
  return (
    <CreateDialog
      title="Create Workspace"
      open={open}
      closeDialog={closeDialog}
      content={
        <>
          <InputBlock
            name="Name"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            }
            required
          />
          <InputBlock
            name="Country"
            input={
              <Autocomplete
                options={countries}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={country || null}
                onChange={(e, value) => setCountry(value!)}
              />
            }
            required
          />
          <InputBlock
            name="Language"
            input={
              <Autocomplete
                options={languages}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={language || null}
                onChange={(e, value) => setLanguage(value!)}
              />
            }
            required
          />
        </>
      }
      action={
        workspaceCreationStatus === AsyncStatus.PENDING ? (
          <CircularProgress />
        ) : (
          <FilledButton disabled={!areRequiredFieldsSet} content="Create" onClick={submitAction} />
        )
      }
    />
  );
}

export default NewWorkspaceDialog;
