import Button from '@mui/material/Button';
import { buttonStyles } from './Styles';
import { SimpleButtonProps } from '../../types';

function SimpleButton(props: SimpleButtonProps): JSX.Element {
  const { icon, content, onClick, disabled } = props;
  return (
    <Button
      variant="outlined"
      style={buttonStyles}
      startIcon={icon ? <img src={icon} alt="Button Icon" /> : null}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
}
export default SimpleButton;
