import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputBlock from '../Input';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import { AsyncStatus } from '../../../types';
import DatePickerComponent from '../../DatePicker/component';

interface NewLedgerTransactionDialogProps {
  open: boolean;
  transactionTypes: string[];
  transactionType: string;
  date: string;
  description: string;
  reference: string;
  financialTransactionId: string;
  amount: string;
  ledgerTransactionCreationStatus: AsyncStatus;
  submitAction: () => void;
  closeDialog: () => void;
  areRequiredFieldsSet: boolean;
  setDate: (date: string) => void;
  setDescription: (description: string) => void;
  setReference: (reference: string) => void;
  setAmount: (amount: string) => void;
  setFinancialTransactionId: (id: string) => void;
  setTransactionType: (type: string) => void;
}
function NewLedgerTransactionDialog(props: NewLedgerTransactionDialogProps) {
  const {
    open,
    transactionTypes,
    date,
    setDate,
    description,
    setDescription,
    reference,
    setReference,
    financialTransactionId,
    setFinancialTransactionId,
    transactionType,
    setTransactionType,
    amount,
    setAmount,
    closeDialog,
    submitAction,
    areRequiredFieldsSet,
    ledgerTransactionCreationStatus,
  } = props;

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Allow only numbers and dots
    if (event.target.value === '' || /^[0-9.]+$/.test(event.target.value)) {
      console.log('event.target.value', event.target.value);
      setAmount(event.target.value);
    }
  };

  return (
    <CreateDialog
      title="Create Ledger Transaction"
      open={open}
      closeDialog={closeDialog}
      content={
        <>
          <InputBlock
            name="Date"
            input={<DatePickerComponent date={date} setDate={setDate} />}
            required
          />
          <InputBlock
            name="Description"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            }
            required
          />
          <InputBlock
            name="Type"
            input={
              <Autocomplete
                options={transactionTypes}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={transactionType || null}
                onChange={(e, value) => setTransactionType(value!)}
              />
            }
            required
          />
          <InputBlock
            name="Amount"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={amount}
                // onChange={(e) => setAmount(Number(e.target.value))}
                onChange={handleAmountChange}
              />
            }
            required
          />
          <InputBlock
            name="Ledger Id"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={financialTransactionId}
                onChange={(e) => setFinancialTransactionId(e.target.value)}
              />
            }
          />
          <InputBlock
            name="Reference"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
              />
            }
          />
        </>
      }
      action={
        ledgerTransactionCreationStatus === AsyncStatus.PENDING ? (
          <CircularProgress />
        ) : (
          <FilledButton disabled={!areRequiredFieldsSet} content="Create" onClick={submitAction} />
        )
      }
    />
  );
}

export default NewLedgerTransactionDialog;
