// source: reconcilio.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.reconcilio.AcceptAllSuggestionsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptAllSuggestionsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AcceptAllSuggestionsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptAllSuggestionsRequest', null, global);
goog.exportSymbol('proto.reconcilio.AcceptAllSuggestionsResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptAllSuggestionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionRequest', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionsRequest', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionsResponse', null, global);
goog.exportSymbol('proto.reconcilio.AcceptSuggestionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryRequest', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityCategoryResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityStopListItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityStopListItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityStopListItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityStopListItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityStopListItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddEntityStopListItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryRequest', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceCategoryResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceStopListItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceStopListItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceStopListItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceStopListItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceStopListItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.AddWorkspaceStopListItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.Amount', null, global);
goog.exportSymbol('proto.reconcilio.Category', null, global);
goog.exportSymbol('proto.reconcilio.CategoryItem', null, global);
goog.exportSymbol('proto.reconcilio.CreateConverterTrackingErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateConverterTrackingErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateConverterTrackingOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateConverterTrackingRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateConverterTrackingResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateConverterTrackingResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateEntityErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateEntityErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateEntityOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateEntityRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateEntityResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateEntityResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransaction', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionsRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionsResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateLedgerTransactionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationAccountErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationAccountErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationAccountOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationAccountRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationAccountResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationReportErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationReportOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationReportRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationReportResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateReconciliationReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateWorkspaceErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateWorkspaceErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.CreateWorkspaceOKRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateWorkspaceRequest', null, global);
goog.exportSymbol('proto.reconcilio.CreateWorkspaceResponse', null, global);
goog.exportSymbol('proto.reconcilio.CreateWorkspaceResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityCategoryResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityStopListItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityStopListItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityStopListItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityStopListItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityStopListItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteEntityStopListItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteLedgerTransactionErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteLedgerTransactionErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteLedgerTransactionOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteLedgerTransactionRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteLedgerTransactionResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteLedgerTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationAccountErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationAccountErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationAccountOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationAccountRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationAccountResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationReportErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationReportErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationReportOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationReportRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationReportResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteReconciliationReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceCategoryResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceStopListItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceStopListItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceStopListItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.DeleteWorkspaceStopListItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse', null, global);
goog.exportSymbol('proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryNameErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryNameErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryNameOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryNameRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryNameResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityCategoryNameResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityProfileErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityProfileErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityProfileOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityProfileRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityProfileResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityProfileResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityStopListItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityStopListItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityStopListItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityStopListItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityStopListItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditEntityStopListItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditLedgerTransactionErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditLedgerTransactionErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditLedgerTransactionOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditLedgerTransactionRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditLedgerTransactionResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditLedgerTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationAccountProfileErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationAccountProfileErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationAccountProfileOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationAccountProfileRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationAccountProfileResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationAccountProfileResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationReportDetailsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationReportDetailsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationReportDetailsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationReportDetailsRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationReportDetailsResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditReconciliationReportDetailsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryNameErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryNameOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryNameRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryNameResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceCategoryNameResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceProfileErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceProfileErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceProfileOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceProfileRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceProfileResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceProfileResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceStopListItemErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceStopListItemErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceStopListItemOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceStopListItemRequest', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceStopListItemResponse', null, global);
goog.exportSymbol('proto.reconcilio.EditWorkspaceStopListItemResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse', null, global);
goog.exportSymbol('proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.Entity', null, global);
goog.exportSymbol('proto.reconcilio.EntityCompleteSettings', null, global);
goog.exportSymbol('proto.reconcilio.EntityReconciliationAccount', null, global);
goog.exportSymbol('proto.reconcilio.EntitySettings', null, global);
goog.exportSymbol('proto.reconcilio.FinancialDocument', null, global);
goog.exportSymbol('proto.reconcilio.FinancialTransaction', null, global);
goog.exportSymbol('proto.reconcilio.GetAdjustmentsReportErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetAdjustmentsReportErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetAdjustmentsReportOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetAdjustmentsReportRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetAdjustmentsReportResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetAdjustmentsReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetEntityByIdErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetEntityByIdErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetEntityByIdOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetEntityByIdRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetEntityByIdResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetEntityByIdResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetEntitySettingsByIdErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetEntitySettingsByIdErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetEntitySettingsByIdOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetEntitySettingsByIdRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetEntitySettingsByIdResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetEntitySettingsByIdResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountByIdErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountByIdErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountByIdOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountByIdRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountByIdResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountByIdResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountReportsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountReportsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountReportsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountReportsRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountReportsResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationAccountReportsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationReportErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationReportErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationReportOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationReportRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationReportResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetReconciliationReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetSuggestionsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetSuggestionsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetSuggestionsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetSuggestionsRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetSuggestionsResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetSuggestionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceByIdErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceByIdErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceByIdOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceByIdRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceByIdResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceByIdResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceSettingsByIdOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceSettingsByIdRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceSettingsByIdResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspaceSettingsByIdResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspacesErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspacesErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspacesOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspacesRequest', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspacesResponse', null, global);
goog.exportSymbol('proto.reconcilio.GetWorkspacesResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.InheritedSetting', null, global);
goog.exportSymbol('proto.reconcilio.LockReconciliationReportErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.LockReconciliationReportErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.LockReconciliationReportOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.LockReconciliationReportRequest', null, global);
goog.exportSymbol('proto.reconcilio.LockReconciliationReportResponse', null, global);
goog.exportSymbol('proto.reconcilio.LockReconciliationReportResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.ManualReconciliationErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.ManualReconciliationErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.ManualReconciliationOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.ManualReconciliationRequest', null, global);
goog.exportSymbol('proto.reconcilio.ManualReconciliationResponse', null, global);
goog.exportSymbol('proto.reconcilio.ManualReconciliationResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.Match', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportEvent', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportEvent.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportProgressResponse', null, global);
goog.exportSymbol('proto.reconcilio.OnCreateReconciliationReportProgressResponse.ProgressOrMessageCase', null, global);
goog.exportSymbol('proto.reconcilio.ProgressEvent', null, global);
goog.exportSymbol('proto.reconcilio.ReconciliationAccount', null, global);
goog.exportSymbol('proto.reconcilio.ReconciliationAccountReport', null, global);
goog.exportSymbol('proto.reconcilio.ReconciliationReportPeriod', null, global);
goog.exportSymbol('proto.reconcilio.RefreshTokenErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.RefreshTokenErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.RefreshTokenOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.RefreshTokenRequest', null, global);
goog.exportSymbol('proto.reconcilio.RefreshTokenResponse', null, global);
goog.exportSymbol('proto.reconcilio.RefreshTokenResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionRequest', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionResponse', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionsErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionsErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionsOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionsRequest', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionsResponse', null, global);
goog.exportSymbol('proto.reconcilio.RejectSuggestionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.StopListItem', null, global);
goog.exportSymbol('proto.reconcilio.Suggestion', null, global);
goog.exportSymbol('proto.reconcilio.UnreconcileMatchErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.UnreconcileMatchErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.UnreconcileMatchOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.UnreconcileMatchRequest', null, global);
goog.exportSymbol('proto.reconcilio.UnreconcileMatchResponse', null, global);
goog.exportSymbol('proto.reconcilio.UnreconcileMatchResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.UpdatedTransactions', null, global);
goog.exportSymbol('proto.reconcilio.UploadDocumentErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.UploadDocumentErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.UploadDocumentOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.UploadDocumentRequest', null, global);
goog.exportSymbol('proto.reconcilio.UploadDocumentResponse', null, global);
goog.exportSymbol('proto.reconcilio.UploadDocumentResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.User', null, global);
goog.exportSymbol('proto.reconcilio.ValidateDocumentErrorResponse', null, global);
goog.exportSymbol('proto.reconcilio.ValidateDocumentErrorResponse.ErrorCase', null, global);
goog.exportSymbol('proto.reconcilio.ValidateDocumentOKResponse', null, global);
goog.exportSymbol('proto.reconcilio.ValidateDocumentRequest', null, global);
goog.exportSymbol('proto.reconcilio.ValidateDocumentResponse', null, global);
goog.exportSymbol('proto.reconcilio.ValidateDocumentResponse.ResultCase', null, global);
goog.exportSymbol('proto.reconcilio.Workspace', null, global);
goog.exportSymbol('proto.reconcilio.WorkspaceEntity', null, global);
goog.exportSymbol('proto.reconcilio.WorkspaceSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateConverterTrackingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateConverterTrackingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateConverterTrackingRequest.displayName = 'proto.reconcilio.CreateConverterTrackingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateConverterTrackingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateConverterTrackingResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateConverterTrackingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateConverterTrackingResponse.displayName = 'proto.reconcilio.CreateConverterTrackingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateConverterTrackingOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateConverterTrackingOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateConverterTrackingOKResponse.displayName = 'proto.reconcilio.CreateConverterTrackingOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateConverterTrackingErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateConverterTrackingErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateConverterTrackingErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateConverterTrackingErrorResponse.displayName = 'proto.reconcilio.CreateConverterTrackingErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetSuggestionsRequest.displayName = 'proto.reconcilio.GetSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetSuggestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetSuggestionsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetSuggestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetSuggestionsResponse.displayName = 'proto.reconcilio.GetSuggestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetSuggestionsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.GetSuggestionsOKResponse.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.GetSuggestionsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetSuggestionsOKResponse.displayName = 'proto.reconcilio.GetSuggestionsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.Amount.displayName = 'proto.reconcilio.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.Suggestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.Suggestion.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.Suggestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.Suggestion.displayName = 'proto.reconcilio.Suggestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.Match = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.Match.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.Match, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.Match.displayName = 'proto.reconcilio.Match';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetReconciliationReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationReportRequest.displayName = 'proto.reconcilio.GetReconciliationReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetReconciliationReportResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetReconciliationReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationReportResponse.displayName = 'proto.reconcilio.GetReconciliationReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.FinancialTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.FinancialTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.FinancialTransaction.displayName = 'proto.reconcilio.FinancialTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.FinancialDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.FinancialDocument.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.FinancialDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.FinancialDocument.displayName = 'proto.reconcilio.FinancialDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ReconciliationReportPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.ReconciliationReportPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ReconciliationReportPeriod.displayName = 'proto.reconcilio.ReconciliationReportPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationReportOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.GetReconciliationReportOKResponse.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.GetReconciliationReportOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationReportOKResponse.displayName = 'proto.reconcilio.GetReconciliationReportOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationReportErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetReconciliationReportErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetReconciliationReportErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationReportErrorResponse.displayName = 'proto.reconcilio.GetReconciliationReportErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountReportsRequest.displayName = 'proto.reconcilio.GetReconciliationAccountReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetReconciliationAccountReportsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountReportsResponse.displayName = 'proto.reconcilio.GetReconciliationAccountReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.GetReconciliationAccountReportsOKResponse.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountReportsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountReportsOKResponse.displayName = 'proto.reconcilio.GetReconciliationAccountReportsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ReconciliationAccountReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.ReconciliationAccountReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ReconciliationAccountReport.displayName = 'proto.reconcilio.ReconciliationAccountReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetReconciliationAccountReportsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountReportsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountReportsErrorResponse.displayName = 'proto.reconcilio.GetReconciliationAccountReportsErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetSuggestionsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetSuggestionsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetSuggestionsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetSuggestionsErrorResponse.displayName = 'proto.reconcilio.GetSuggestionsErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UploadDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.UploadDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UploadDocumentRequest.displayName = 'proto.reconcilio.UploadDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UploadDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.UploadDocumentResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.UploadDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UploadDocumentResponse.displayName = 'proto.reconcilio.UploadDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UploadDocumentOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.UploadDocumentOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UploadDocumentOKResponse.displayName = 'proto.reconcilio.UploadDocumentOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UploadDocumentErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.UploadDocumentErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UploadDocumentErrorResponse.displayName = 'proto.reconcilio.UploadDocumentErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ValidateDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.ValidateDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ValidateDocumentRequest.displayName = 'proto.reconcilio.ValidateDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ValidateDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.ValidateDocumentResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.ValidateDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ValidateDocumentResponse.displayName = 'proto.reconcilio.ValidateDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ValidateDocumentOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.ValidateDocumentOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ValidateDocumentOKResponse.displayName = 'proto.reconcilio.ValidateDocumentOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ValidateDocumentErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.ValidateDocumentErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ValidateDocumentErrorResponse.displayName = 'proto.reconcilio.ValidateDocumentErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ManualReconciliationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.ManualReconciliationRequest.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.ManualReconciliationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ManualReconciliationRequest.displayName = 'proto.reconcilio.ManualReconciliationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ManualReconciliationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.ManualReconciliationResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.ManualReconciliationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ManualReconciliationResponse.displayName = 'proto.reconcilio.ManualReconciliationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ManualReconciliationOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.ManualReconciliationOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ManualReconciliationOKResponse.displayName = 'proto.reconcilio.ManualReconciliationOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ManualReconciliationErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.ManualReconciliationErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ManualReconciliationErrorResponse.displayName = 'proto.reconcilio.ManualReconciliationErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AcceptSuggestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionRequest.displayName = 'proto.reconcilio.AcceptSuggestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AcceptSuggestionResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AcceptSuggestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionResponse.displayName = 'proto.reconcilio.AcceptSuggestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AcceptSuggestionOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionOKResponse.displayName = 'proto.reconcilio.AcceptSuggestionOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AcceptSuggestionErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionErrorResponse.displayName = 'proto.reconcilio.AcceptSuggestionErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.AcceptSuggestionsRequest.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.AcceptSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionsRequest.displayName = 'proto.reconcilio.AcceptSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AcceptSuggestionsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AcceptSuggestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionsResponse.displayName = 'proto.reconcilio.AcceptSuggestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AcceptSuggestionsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionsOKResponse.displayName = 'proto.reconcilio.AcceptSuggestionsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptSuggestionsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AcceptSuggestionsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AcceptSuggestionsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptSuggestionsErrorResponse.displayName = 'proto.reconcilio.AcceptSuggestionsErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.RejectSuggestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionRequest.displayName = 'proto.reconcilio.RejectSuggestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.RejectSuggestionResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.RejectSuggestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionResponse.displayName = 'proto.reconcilio.RejectSuggestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.RejectSuggestionOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionOKResponse.displayName = 'proto.reconcilio.RejectSuggestionOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.RejectSuggestionErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.RejectSuggestionErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionErrorResponse.displayName = 'proto.reconcilio.RejectSuggestionErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateReconciliationReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationReportRequest.displayName = 'proto.reconcilio.CreateReconciliationReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateReconciliationReportResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateReconciliationReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationReportResponse.displayName = 'proto.reconcilio.CreateReconciliationReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationReportOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateReconciliationReportOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationReportOKResponse.displayName = 'proto.reconcilio.CreateReconciliationReportOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationReportErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateReconciliationReportErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationReportErrorResponse.displayName = 'proto.reconcilio.CreateReconciliationReportErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.LockReconciliationReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.LockReconciliationReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.LockReconciliationReportRequest.displayName = 'proto.reconcilio.LockReconciliationReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.LockReconciliationReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.LockReconciliationReportResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.LockReconciliationReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.LockReconciliationReportResponse.displayName = 'proto.reconcilio.LockReconciliationReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.LockReconciliationReportOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.LockReconciliationReportOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.LockReconciliationReportOKResponse.displayName = 'proto.reconcilio.LockReconciliationReportOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.LockReconciliationReportErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.LockReconciliationReportErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.LockReconciliationReportErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.LockReconciliationReportErrorResponse.displayName = 'proto.reconcilio.LockReconciliationReportErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.OnCreateReconciliationReportEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.OnCreateReconciliationReportEvent.oneofGroups_);
};
goog.inherits(proto.reconcilio.OnCreateReconciliationReportEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.OnCreateReconciliationReportEvent.displayName = 'proto.reconcilio.OnCreateReconciliationReportEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.OnCreateReconciliationReportOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.OnCreateReconciliationReportOKResponse.displayName = 'proto.reconcilio.OnCreateReconciliationReportOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.OnCreateReconciliationReportErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.OnCreateReconciliationReportErrorResponse.displayName = 'proto.reconcilio.OnCreateReconciliationReportErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ProgressEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.ProgressEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ProgressEvent.displayName = 'proto.reconcilio.ProgressEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.OnCreateReconciliationReportProgressResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.OnCreateReconciliationReportProgressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.OnCreateReconciliationReportProgressResponse.displayName = 'proto.reconcilio.OnCreateReconciliationReportProgressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionRequest.displayName = 'proto.reconcilio.CreateLedgerTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateLedgerTransactionResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionResponse.displayName = 'proto.reconcilio.CreateLedgerTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionOKResponse.displayName = 'proto.reconcilio.CreateLedgerTransactionOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionErrorResponse.displayName = 'proto.reconcilio.CreateLedgerTransactionErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditLedgerTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditLedgerTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditLedgerTransactionRequest.displayName = 'proto.reconcilio.EditLedgerTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditLedgerTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditLedgerTransactionResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditLedgerTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditLedgerTransactionResponse.displayName = 'proto.reconcilio.EditLedgerTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditLedgerTransactionOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditLedgerTransactionOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditLedgerTransactionOKResponse.displayName = 'proto.reconcilio.EditLedgerTransactionOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditLedgerTransactionErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditLedgerTransactionErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditLedgerTransactionErrorResponse.displayName = 'proto.reconcilio.EditLedgerTransactionErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteLedgerTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteLedgerTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteLedgerTransactionRequest.displayName = 'proto.reconcilio.DeleteLedgerTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteLedgerTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteLedgerTransactionResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteLedgerTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteLedgerTransactionResponse.displayName = 'proto.reconcilio.DeleteLedgerTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteLedgerTransactionOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteLedgerTransactionOKResponse.displayName = 'proto.reconcilio.DeleteLedgerTransactionOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteLedgerTransactionErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteLedgerTransactionErrorResponse.displayName = 'proto.reconcilio.DeleteLedgerTransactionErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UnreconcileMatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.UnreconcileMatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UnreconcileMatchRequest.displayName = 'proto.reconcilio.UnreconcileMatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UnreconcileMatchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.UnreconcileMatchResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.UnreconcileMatchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UnreconcileMatchResponse.displayName = 'proto.reconcilio.UnreconcileMatchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UnreconcileMatchOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.UnreconcileMatchOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UnreconcileMatchOKResponse.displayName = 'proto.reconcilio.UnreconcileMatchOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UnreconcileMatchErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.UnreconcileMatchErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UnreconcileMatchErrorResponse.displayName = 'proto.reconcilio.UnreconcileMatchErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetAdjustmentsReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetAdjustmentsReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetAdjustmentsReportRequest.displayName = 'proto.reconcilio.GetAdjustmentsReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetAdjustmentsReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetAdjustmentsReportResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetAdjustmentsReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetAdjustmentsReportResponse.displayName = 'proto.reconcilio.GetAdjustmentsReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.UpdatedTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.UpdatedTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.UpdatedTransactions.displayName = 'proto.reconcilio.UpdatedTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetAdjustmentsReportOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.GetAdjustmentsReportOKResponse.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.GetAdjustmentsReportOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetAdjustmentsReportOKResponse.displayName = 'proto.reconcilio.GetAdjustmentsReportOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetAdjustmentsReportErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetAdjustmentsReportErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetAdjustmentsReportErrorResponse.displayName = 'proto.reconcilio.GetAdjustmentsReportErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateWorkspaceRequest.displayName = 'proto.reconcilio.CreateWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateWorkspaceResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateWorkspaceResponse.displayName = 'proto.reconcilio.CreateWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateWorkspaceOKRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateWorkspaceOKRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateWorkspaceOKRequest.displayName = 'proto.reconcilio.CreateWorkspaceOKRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateWorkspaceErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateWorkspaceErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateWorkspaceErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateWorkspaceErrorResponse.displayName = 'proto.reconcilio.CreateWorkspaceErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateEntityRequest.displayName = 'proto.reconcilio.CreateEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateEntityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateEntityResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateEntityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateEntityResponse.displayName = 'proto.reconcilio.CreateEntityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateEntityOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateEntityOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateEntityOKResponse.displayName = 'proto.reconcilio.CreateEntityOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateEntityErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateEntityErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateEntityErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateEntityErrorResponse.displayName = 'proto.reconcilio.CreateEntityErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateReconciliationAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationAccountRequest.displayName = 'proto.reconcilio.CreateReconciliationAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateReconciliationAccountResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateReconciliationAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationAccountResponse.displayName = 'proto.reconcilio.CreateReconciliationAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationAccountOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateReconciliationAccountOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationAccountOKResponse.displayName = 'proto.reconcilio.CreateReconciliationAccountOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateReconciliationAccountErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateReconciliationAccountErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateReconciliationAccountErrorResponse.displayName = 'proto.reconcilio.CreateReconciliationAccountErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspacesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetWorkspacesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspacesRequest.displayName = 'proto.reconcilio.GetWorkspacesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.WorkspaceEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.WorkspaceEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.WorkspaceEntity.displayName = 'proto.reconcilio.WorkspaceEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.User.displayName = 'proto.reconcilio.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.Workspace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.Workspace.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.Workspace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.Workspace.displayName = 'proto.reconcilio.Workspace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspacesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetWorkspacesResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetWorkspacesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspacesResponse.displayName = 'proto.reconcilio.GetWorkspacesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspacesOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.GetWorkspacesOKResponse.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.GetWorkspacesOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspacesOKResponse.displayName = 'proto.reconcilio.GetWorkspacesOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspacesErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetWorkspacesErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetWorkspacesErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspacesErrorResponse.displayName = 'proto.reconcilio.GetWorkspacesErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetWorkspaceByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceByIdRequest.displayName = 'proto.reconcilio.GetWorkspaceByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetWorkspaceByIdResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetWorkspaceByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceByIdResponse.displayName = 'proto.reconcilio.GetWorkspaceByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceByIdOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetWorkspaceByIdOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceByIdOKResponse.displayName = 'proto.reconcilio.GetWorkspaceByIdOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetWorkspaceByIdErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetWorkspaceByIdErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceByIdErrorResponse.displayName = 'proto.reconcilio.GetWorkspaceByIdErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EntityReconciliationAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EntityReconciliationAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EntityReconciliationAccount.displayName = 'proto.reconcilio.EntityReconciliationAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.Entity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.Entity.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.Entity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.Entity.displayName = 'proto.reconcilio.Entity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntityByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetEntityByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntityByIdRequest.displayName = 'proto.reconcilio.GetEntityByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntityByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetEntityByIdResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetEntityByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntityByIdResponse.displayName = 'proto.reconcilio.GetEntityByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntityByIdOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetEntityByIdOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntityByIdOKResponse.displayName = 'proto.reconcilio.GetEntityByIdOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntityByIdErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetEntityByIdErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetEntityByIdErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntityByIdErrorResponse.displayName = 'proto.reconcilio.GetEntityByIdErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.ReconciliationAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.ReconciliationAccount.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.ReconciliationAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.ReconciliationAccount.displayName = 'proto.reconcilio.ReconciliationAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountByIdRequest.displayName = 'proto.reconcilio.GetReconciliationAccountByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetReconciliationAccountByIdResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountByIdResponse.displayName = 'proto.reconcilio.GetReconciliationAccountByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountByIdOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountByIdOKResponse.displayName = 'proto.reconcilio.GetReconciliationAccountByIdOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetReconciliationAccountByIdErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetReconciliationAccountByIdErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetReconciliationAccountByIdErrorResponse.displayName = 'proto.reconcilio.GetReconciliationAccountByIdErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceProfileRequest.displayName = 'proto.reconcilio.EditWorkspaceProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceProfileResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceProfileResponse.displayName = 'proto.reconcilio.EditWorkspaceProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceProfileOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceProfileOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceProfileOKResponse.displayName = 'proto.reconcilio.EditWorkspaceProfileOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceProfileErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceProfileErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceProfileErrorResponse.displayName = 'proto.reconcilio.EditWorkspaceProfileErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityProfileRequest.displayName = 'proto.reconcilio.EditEntityProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityProfileResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityProfileResponse.displayName = 'proto.reconcilio.EditEntityProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityProfileOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityProfileOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityProfileOKResponse.displayName = 'proto.reconcilio.EditEntityProfileOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityProfileErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityProfileErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityProfileErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityProfileErrorResponse.displayName = 'proto.reconcilio.EditEntityProfileErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationAccountProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditReconciliationAccountProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationAccountProfileRequest.displayName = 'proto.reconcilio.EditReconciliationAccountProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationAccountProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditReconciliationAccountProfileResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditReconciliationAccountProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationAccountProfileResponse.displayName = 'proto.reconcilio.EditReconciliationAccountProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditReconciliationAccountProfileOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationAccountProfileOKResponse.displayName = 'proto.reconcilio.EditReconciliationAccountProfileOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditReconciliationAccountProfileErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditReconciliationAccountProfileErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationAccountProfileErrorResponse.displayName = 'proto.reconcilio.EditReconciliationAccountProfileErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceRequest.displayName = 'proto.reconcilio.DeleteWorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceResponse.displayName = 'proto.reconcilio.DeleteWorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceOKResponse.displayName = 'proto.reconcilio.DeleteWorkspaceOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceErrorResponse.displayName = 'proto.reconcilio.DeleteWorkspaceErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityRequest.displayName = 'proto.reconcilio.DeleteEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityResponse.displayName = 'proto.reconcilio.DeleteEntityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityOKResponse.displayName = 'proto.reconcilio.DeleteEntityOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityErrorResponse.displayName = 'proto.reconcilio.DeleteEntityErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteReconciliationAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationAccountRequest.displayName = 'proto.reconcilio.DeleteReconciliationAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteReconciliationAccountResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteReconciliationAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationAccountResponse.displayName = 'proto.reconcilio.DeleteReconciliationAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteReconciliationAccountOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationAccountOKResponse.displayName = 'proto.reconcilio.DeleteReconciliationAccountOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteReconciliationAccountErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteReconciliationAccountErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationAccountErrorResponse.displayName = 'proto.reconcilio.DeleteReconciliationAccountErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceStopListItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddWorkspaceStopListItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceStopListItemRequest.displayName = 'proto.reconcilio.AddWorkspaceStopListItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceStopListItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddWorkspaceStopListItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddWorkspaceStopListItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceStopListItemResponse.displayName = 'proto.reconcilio.AddWorkspaceStopListItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddWorkspaceStopListItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceStopListItemOKResponse.displayName = 'proto.reconcilio.AddWorkspaceStopListItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddWorkspaceStopListItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddWorkspaceStopListItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceStopListItemErrorResponse.displayName = 'proto.reconcilio.AddWorkspaceStopListItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceStopListItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceStopListItemRequest.displayName = 'proto.reconcilio.DeleteWorkspaceStopListItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceStopListItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceStopListItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceStopListItemResponse.displayName = 'proto.reconcilio.DeleteWorkspaceStopListItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceStopListItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.displayName = 'proto.reconcilio.DeleteWorkspaceStopListItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.displayName = 'proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceStopListItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceStopListItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceStopListItemRequest.displayName = 'proto.reconcilio.EditWorkspaceStopListItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceStopListItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceStopListItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceStopListItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceStopListItemResponse.displayName = 'proto.reconcilio.EditWorkspaceStopListItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceStopListItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceStopListItemOKResponse.displayName = 'proto.reconcilio.EditWorkspaceStopListItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceStopListItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceStopListItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceStopListItemErrorResponse.displayName = 'proto.reconcilio.EditWorkspaceStopListItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryRequest.displayName = 'proto.reconcilio.AddWorkspaceCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddWorkspaceCategoryResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryResponse.displayName = 'proto.reconcilio.AddWorkspaceCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryOKResponse.displayName = 'proto.reconcilio.AddWorkspaceCategoryOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddWorkspaceCategoryErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryErrorResponse.displayName = 'proto.reconcilio.AddWorkspaceCategoryErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryRequest.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceCategoryResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryResponse.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryOKResponse.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryItemRequest.displayName = 'proto.reconcilio.AddWorkspaceCategoryItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddWorkspaceCategoryItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryItemResponse.displayName = 'proto.reconcilio.AddWorkspaceCategoryItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryItemOKResponse.displayName = 'proto.reconcilio.AddWorkspaceCategoryItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddWorkspaceCategoryItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.displayName = 'proto.reconcilio.AddWorkspaceCategoryItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryItemRequest.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceCategoryItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryItemResponse.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.displayName = 'proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryNameRequest.displayName = 'proto.reconcilio.EditWorkspaceCategoryNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceCategoryNameResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryNameResponse.displayName = 'proto.reconcilio.EditWorkspaceCategoryNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryNameOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryNameOKResponse.displayName = 'proto.reconcilio.EditWorkspaceCategoryNameOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryNameErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.displayName = 'proto.reconcilio.EditWorkspaceCategoryNameErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryItemRequest.displayName = 'proto.reconcilio.EditWorkspaceCategoryItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceCategoryItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryItemResponse.displayName = 'proto.reconcilio.EditWorkspaceCategoryItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryItemOKResponse.displayName = 'proto.reconcilio.EditWorkspaceCategoryItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditWorkspaceCategoryItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.displayName = 'proto.reconcilio.EditWorkspaceCategoryItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.displayName = 'proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.displayName = 'proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.displayName = 'proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.displayName = 'proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.displayName = 'proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.displayName = 'proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.displayName = 'proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.displayName = 'proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.displayName = 'proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.displayName = 'proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.displayName = 'proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.displayName = 'proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.displayName = 'proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.displayName = 'proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.displayName = 'proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.displayName = 'proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityStopListItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddEntityStopListItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityStopListItemRequest.displayName = 'proto.reconcilio.AddEntityStopListItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityStopListItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddEntityStopListItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddEntityStopListItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityStopListItemResponse.displayName = 'proto.reconcilio.AddEntityStopListItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityStopListItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddEntityStopListItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityStopListItemOKResponse.displayName = 'proto.reconcilio.AddEntityStopListItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityStopListItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddEntityStopListItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityStopListItemErrorResponse.displayName = 'proto.reconcilio.AddEntityStopListItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityStopListItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityStopListItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityStopListItemRequest.displayName = 'proto.reconcilio.DeleteEntityStopListItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityStopListItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityStopListItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityStopListItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityStopListItemResponse.displayName = 'proto.reconcilio.DeleteEntityStopListItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityStopListItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityStopListItemOKResponse.displayName = 'proto.reconcilio.DeleteEntityStopListItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityStopListItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityStopListItemErrorResponse.displayName = 'proto.reconcilio.DeleteEntityStopListItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityStopListItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityStopListItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityStopListItemRequest.displayName = 'proto.reconcilio.EditEntityStopListItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityStopListItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityStopListItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityStopListItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityStopListItemResponse.displayName = 'proto.reconcilio.EditEntityStopListItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityStopListItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityStopListItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityStopListItemOKResponse.displayName = 'proto.reconcilio.EditEntityStopListItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityStopListItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityStopListItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityStopListItemErrorResponse.displayName = 'proto.reconcilio.EditEntityStopListItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddEntityCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryRequest.displayName = 'proto.reconcilio.AddEntityCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddEntityCategoryResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddEntityCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryResponse.displayName = 'proto.reconcilio.AddEntityCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddEntityCategoryOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryOKResponse.displayName = 'proto.reconcilio.AddEntityCategoryOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddEntityCategoryErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryErrorResponse.displayName = 'proto.reconcilio.AddEntityCategoryErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryRequest.displayName = 'proto.reconcilio.DeleteEntityCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityCategoryResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryResponse.displayName = 'proto.reconcilio.DeleteEntityCategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryOKResponse.displayName = 'proto.reconcilio.DeleteEntityCategoryOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryErrorResponse.displayName = 'proto.reconcilio.DeleteEntityCategoryErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddEntityCategoryItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryItemRequest.displayName = 'proto.reconcilio.AddEntityCategoryItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddEntityCategoryItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddEntityCategoryItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryItemResponse.displayName = 'proto.reconcilio.AddEntityCategoryItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AddEntityCategoryItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryItemOKResponse.displayName = 'proto.reconcilio.AddEntityCategoryItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AddEntityCategoryItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AddEntityCategoryItemErrorResponse.displayName = 'proto.reconcilio.AddEntityCategoryItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryItemRequest.displayName = 'proto.reconcilio.DeleteEntityCategoryItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityCategoryItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryItemResponse.displayName = 'proto.reconcilio.DeleteEntityCategoryItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryItemOKResponse.displayName = 'proto.reconcilio.DeleteEntityCategoryItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteEntityCategoryItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteEntityCategoryItemErrorResponse.displayName = 'proto.reconcilio.DeleteEntityCategoryItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityCategoryNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryNameRequest.displayName = 'proto.reconcilio.EditEntityCategoryNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityCategoryNameResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityCategoryNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryNameResponse.displayName = 'proto.reconcilio.EditEntityCategoryNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryNameOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityCategoryNameOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryNameOKResponse.displayName = 'proto.reconcilio.EditEntityCategoryNameOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityCategoryNameErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryNameErrorResponse.displayName = 'proto.reconcilio.EditEntityCategoryNameErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityCategoryItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryItemRequest.displayName = 'proto.reconcilio.EditEntityCategoryItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityCategoryItemResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityCategoryItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryItemResponse.displayName = 'proto.reconcilio.EditEntityCategoryItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryItemOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditEntityCategoryItemOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryItemOKResponse.displayName = 'proto.reconcilio.EditEntityCategoryItemOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditEntityCategoryItemErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditEntityCategoryItemErrorResponse.displayName = 'proto.reconcilio.EditEntityCategoryItemErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetWorkspaceSettingsByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceSettingsByIdRequest.displayName = 'proto.reconcilio.GetWorkspaceSettingsByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetWorkspaceSettingsByIdResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetWorkspaceSettingsByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceSettingsByIdResponse.displayName = 'proto.reconcilio.GetWorkspaceSettingsByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.WorkspaceSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.WorkspaceSettings.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.WorkspaceSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.WorkspaceSettings.displayName = 'proto.reconcilio.WorkspaceSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.StopListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.StopListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.StopListItem.displayName = 'proto.reconcilio.StopListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.Category.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.Category.displayName = 'proto.reconcilio.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CategoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CategoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CategoryItem.displayName = 'proto.reconcilio.CategoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EntitySettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.EntitySettings.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.EntitySettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EntitySettings.displayName = 'proto.reconcilio.EntitySettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.InheritedSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.InheritedSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.InheritedSetting.displayName = 'proto.reconcilio.InheritedSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetWorkspaceSettingsByIdOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.displayName = 'proto.reconcilio.GetWorkspaceSettingsByIdOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.displayName = 'proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntitySettingsByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetEntitySettingsByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntitySettingsByIdRequest.displayName = 'proto.reconcilio.GetEntitySettingsByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntitySettingsByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetEntitySettingsByIdResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetEntitySettingsByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntitySettingsByIdResponse.displayName = 'proto.reconcilio.GetEntitySettingsByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EntityCompleteSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.EntityCompleteSettings.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.EntityCompleteSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EntityCompleteSettings.displayName = 'proto.reconcilio.EntityCompleteSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.GetEntitySettingsByIdOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntitySettingsByIdOKResponse.displayName = 'proto.reconcilio.GetEntitySettingsByIdOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.GetEntitySettingsByIdErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.GetEntitySettingsByIdErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.GetEntitySettingsByIdErrorResponse.displayName = 'proto.reconcilio.GetEntitySettingsByIdErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationReportDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditReconciliationReportDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationReportDetailsRequest.displayName = 'proto.reconcilio.EditReconciliationReportDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationReportDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditReconciliationReportDetailsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditReconciliationReportDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationReportDetailsResponse.displayName = 'proto.reconcilio.EditReconciliationReportDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.EditReconciliationReportDetailsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationReportDetailsOKResponse.displayName = 'proto.reconcilio.EditReconciliationReportDetailsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.EditReconciliationReportDetailsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.EditReconciliationReportDetailsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.EditReconciliationReportDetailsErrorResponse.displayName = 'proto.reconcilio.EditReconciliationReportDetailsErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptAllSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AcceptAllSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptAllSuggestionsRequest.displayName = 'proto.reconcilio.AcceptAllSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptAllSuggestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AcceptAllSuggestionsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AcceptAllSuggestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptAllSuggestionsResponse.displayName = 'proto.reconcilio.AcceptAllSuggestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.AcceptAllSuggestionsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptAllSuggestionsOKResponse.displayName = 'proto.reconcilio.AcceptAllSuggestionsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.AcceptAllSuggestionsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.AcceptAllSuggestionsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.AcceptAllSuggestionsErrorResponse.displayName = 'proto.reconcilio.AcceptAllSuggestionsErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RefreshTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.RefreshTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RefreshTokenRequest.displayName = 'proto.reconcilio.RefreshTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RefreshTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.RefreshTokenResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.RefreshTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RefreshTokenResponse.displayName = 'proto.reconcilio.RefreshTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RefreshTokenOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.RefreshTokenOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RefreshTokenOKResponse.displayName = 'proto.reconcilio.RefreshTokenOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RefreshTokenErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.RefreshTokenErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.RefreshTokenErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RefreshTokenErrorResponse.displayName = 'proto.reconcilio.RefreshTokenErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.CreateLedgerTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransaction.displayName = 'proto.reconcilio.CreateLedgerTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.CreateLedgerTransactionsRequest.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionsRequest.displayName = 'proto.reconcilio.CreateLedgerTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateLedgerTransactionsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionsResponse.displayName = 'proto.reconcilio.CreateLedgerTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.CreateLedgerTransactionsOKResponse.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionsOKResponse.displayName = 'proto.reconcilio.CreateLedgerTransactionsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.CreateLedgerTransactionsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.CreateLedgerTransactionsErrorResponse.displayName = 'proto.reconcilio.CreateLedgerTransactionsErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteReconciliationReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationReportRequest.displayName = 'proto.reconcilio.DeleteReconciliationReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteReconciliationReportResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteReconciliationReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationReportResponse.displayName = 'proto.reconcilio.DeleteReconciliationReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationReportOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.DeleteReconciliationReportOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationReportOKResponse.displayName = 'proto.reconcilio.DeleteReconciliationReportOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.DeleteReconciliationReportErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.DeleteReconciliationReportErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.DeleteReconciliationReportErrorResponse.displayName = 'proto.reconcilio.DeleteReconciliationReportErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconcilio.RejectSuggestionsRequest.repeatedFields_, null);
};
goog.inherits(proto.reconcilio.RejectSuggestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionsRequest.displayName = 'proto.reconcilio.RejectSuggestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.RejectSuggestionsResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.RejectSuggestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionsResponse.displayName = 'proto.reconcilio.RejectSuggestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionsOKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconcilio.RejectSuggestionsOKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionsOKResponse.displayName = 'proto.reconcilio.RejectSuggestionsOKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconcilio.RejectSuggestionsErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.reconcilio.RejectSuggestionsErrorResponse.oneofGroups_);
};
goog.inherits(proto.reconcilio.RejectSuggestionsErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconcilio.RejectSuggestionsErrorResponse.displayName = 'proto.reconcilio.RejectSuggestionsErrorResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateConverterTrackingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateConverterTrackingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    format: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateConverterTrackingRequest}
 */
proto.reconcilio.CreateConverterTrackingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateConverterTrackingRequest;
  return proto.reconcilio.CreateConverterTrackingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateConverterTrackingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateConverterTrackingRequest}
 */
proto.reconcilio.CreateConverterTrackingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateConverterTrackingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateConverterTrackingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateConverterTrackingRequest} returns this
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string format = 2;
 * @return {string}
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateConverterTrackingRequest} returns this
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateConverterTrackingRequest} returns this
 */
proto.reconcilio.CreateConverterTrackingRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateConverterTrackingResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateConverterTrackingResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateConverterTrackingResponse.ResultCase}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateConverterTrackingResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateConverterTrackingResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateConverterTrackingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateConverterTrackingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateConverterTrackingOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateConverterTrackingErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateConverterTrackingResponse}
 */
proto.reconcilio.CreateConverterTrackingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateConverterTrackingResponse;
  return proto.reconcilio.CreateConverterTrackingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateConverterTrackingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateConverterTrackingResponse}
 */
proto.reconcilio.CreateConverterTrackingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateConverterTrackingOKResponse;
      reader.readMessage(value,proto.reconcilio.CreateConverterTrackingOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateConverterTrackingErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateConverterTrackingErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateConverterTrackingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateConverterTrackingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateConverterTrackingOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateConverterTrackingErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateConverterTrackingOKResponse ok = 1;
 * @return {?proto.reconcilio.CreateConverterTrackingOKResponse}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateConverterTrackingOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateConverterTrackingOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.CreateConverterTrackingOKResponse|undefined} value
 * @return {!proto.reconcilio.CreateConverterTrackingResponse} returns this
*/
proto.reconcilio.CreateConverterTrackingResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateConverterTrackingResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateConverterTrackingResponse} returns this
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateConverterTrackingErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateConverterTrackingErrorResponse}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateConverterTrackingErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateConverterTrackingErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateConverterTrackingErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateConverterTrackingResponse} returns this
*/
proto.reconcilio.CreateConverterTrackingResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateConverterTrackingResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateConverterTrackingResponse} returns this
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateConverterTrackingResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateConverterTrackingOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateConverterTrackingOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateConverterTrackingOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateConverterTrackingOKResponse}
 */
proto.reconcilio.CreateConverterTrackingOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateConverterTrackingOKResponse;
  return proto.reconcilio.CreateConverterTrackingOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateConverterTrackingOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateConverterTrackingOKResponse}
 */
proto.reconcilio.CreateConverterTrackingOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateConverterTrackingOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateConverterTrackingOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateConverterTrackingOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNEXPECTED_ERROR: 1
};

/**
 * @return {proto.reconcilio.CreateConverterTrackingErrorResponse.ErrorCase}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.CreateConverterTrackingErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateConverterTrackingErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateConverterTrackingErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateConverterTrackingErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateConverterTrackingErrorResponse}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateConverterTrackingErrorResponse;
  return proto.reconcilio.CreateConverterTrackingErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateConverterTrackingErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateConverterTrackingErrorResponse}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateConverterTrackingErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateConverterTrackingErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unexpected_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateConverterTrackingErrorResponse} returns this
*/
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateConverterTrackingErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateConverterTrackingErrorResponse} returns this
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateConverterTrackingErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetSuggestionsRequest}
 */
proto.reconcilio.GetSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetSuggestionsRequest;
  return proto.reconcilio.GetSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetSuggestionsRequest}
 */
proto.reconcilio.GetSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.GetSuggestionsRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetSuggestionsRequest} returns this
 */
proto.reconcilio.GetSuggestionsRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetSuggestionsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetSuggestionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetSuggestionsResponse.ResultCase}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetSuggestionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetSuggestionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetSuggestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetSuggestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetSuggestionsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetSuggestionsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetSuggestionsResponse}
 */
proto.reconcilio.GetSuggestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetSuggestionsResponse;
  return proto.reconcilio.GetSuggestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetSuggestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetSuggestionsResponse}
 */
proto.reconcilio.GetSuggestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetSuggestionsOKResponse;
      reader.readMessage(value,proto.reconcilio.GetSuggestionsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetSuggestionsErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetSuggestionsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetSuggestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetSuggestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetSuggestionsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetSuggestionsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetSuggestionsOKResponse ok = 1;
 * @return {?proto.reconcilio.GetSuggestionsOKResponse}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetSuggestionsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetSuggestionsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetSuggestionsOKResponse|undefined} value
 * @return {!proto.reconcilio.GetSuggestionsResponse} returns this
*/
proto.reconcilio.GetSuggestionsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetSuggestionsResponse} returns this
 */
proto.reconcilio.GetSuggestionsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetSuggestionsErrorResponse error = 2;
 * @return {?proto.reconcilio.GetSuggestionsErrorResponse}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetSuggestionsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetSuggestionsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetSuggestionsErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetSuggestionsResponse} returns this
*/
proto.reconcilio.GetSuggestionsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetSuggestionsResponse} returns this
 */
proto.reconcilio.GetSuggestionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetSuggestionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.GetSuggestionsOKResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetSuggestionsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetSuggestionsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetSuggestionsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestionsList: jspb.Message.toObjectList(msg.getSuggestionsList(),
    proto.reconcilio.Suggestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetSuggestionsOKResponse}
 */
proto.reconcilio.GetSuggestionsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetSuggestionsOKResponse;
  return proto.reconcilio.GetSuggestionsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetSuggestionsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetSuggestionsOKResponse}
 */
proto.reconcilio.GetSuggestionsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.Suggestion;
      reader.readMessage(value,proto.reconcilio.Suggestion.deserializeBinaryFromReader);
      msg.addSuggestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetSuggestionsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetSuggestionsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetSuggestionsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconcilio.Suggestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Suggestion suggestions = 1;
 * @return {!Array<!proto.reconcilio.Suggestion>}
 */
proto.reconcilio.GetSuggestionsOKResponse.prototype.getSuggestionsList = function() {
  return /** @type{!Array<!proto.reconcilio.Suggestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Suggestion, 1));
};


/**
 * @param {!Array<!proto.reconcilio.Suggestion>} value
 * @return {!proto.reconcilio.GetSuggestionsOKResponse} returns this
*/
proto.reconcilio.GetSuggestionsOKResponse.prototype.setSuggestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconcilio.Suggestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Suggestion}
 */
proto.reconcilio.GetSuggestionsOKResponse.prototype.addSuggestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconcilio.Suggestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetSuggestionsOKResponse} returns this
 */
proto.reconcilio.GetSuggestionsOKResponse.prototype.clearSuggestionsList = function() {
  return this.setSuggestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.Amount}
 */
proto.reconcilio.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.Amount;
  return proto.reconcilio.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.Amount}
 */
proto.reconcilio.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.reconcilio.Amount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.Amount} returns this
 */
proto.reconcilio.Amount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.reconcilio.Amount.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Amount} returns this
 */
proto.reconcilio.Amount.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.Suggestion.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.Suggestion.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.Suggestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.Suggestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Suggestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    suggestionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ledgerTransactionsList: jspb.Message.toObjectList(msg.getLedgerTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance),
    externalTransactionsList: jspb.Message.toObjectList(msg.getExternalTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.Suggestion}
 */
proto.reconcilio.Suggestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.Suggestion;
  return proto.reconcilio.Suggestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.Suggestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.Suggestion}
 */
proto.reconcilio.Suggestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 3:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addLedgerTransactions(value);
      break;
    case 4:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addExternalTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.Suggestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.Suggestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.Suggestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Suggestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuggestionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLedgerTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
  f = message.getExternalTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string suggestion_id = 1;
 * @return {string}
 */
proto.reconcilio.Suggestion.prototype.getSuggestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Suggestion} returns this
 */
proto.reconcilio.Suggestion.prototype.setSuggestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_by = 2;
 * @return {string}
 */
proto.reconcilio.Suggestion.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Suggestion} returns this
 */
proto.reconcilio.Suggestion.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FinancialTransaction ledger_transactions = 3;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.Suggestion.prototype.getLedgerTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 3));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.Suggestion} returns this
*/
proto.reconcilio.Suggestion.prototype.setLedgerTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.Suggestion.prototype.addLedgerTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Suggestion} returns this
 */
proto.reconcilio.Suggestion.prototype.clearLedgerTransactionsList = function() {
  return this.setLedgerTransactionsList([]);
};


/**
 * repeated FinancialTransaction external_transactions = 4;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.Suggestion.prototype.getExternalTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 4));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.Suggestion} returns this
*/
proto.reconcilio.Suggestion.prototype.setExternalTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.Suggestion.prototype.addExternalTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Suggestion} returns this
 */
proto.reconcilio.Suggestion.prototype.clearExternalTransactionsList = function() {
  return this.setExternalTransactionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.Match.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.Match.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.Match.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.Match} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Match.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ledgerTransactionsList: jspb.Message.toObjectList(msg.getLedgerTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance),
    externalTransactionsList: jspb.Message.toObjectList(msg.getExternalTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance),
    isCreatedFromSuggestion: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.Match}
 */
proto.reconcilio.Match.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.Match;
  return proto.reconcilio.Match.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.Match} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.Match}
 */
proto.reconcilio.Match.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 3:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addLedgerTransactions(value);
      break;
    case 4:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addExternalTransactions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCreatedFromSuggestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.Match.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.Match.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.Match} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Match.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLedgerTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
  f = message.getExternalTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
  f = message.getIsCreatedFromSuggestion();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string match_id = 1;
 * @return {string}
 */
proto.reconcilio.Match.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Match} returns this
 */
proto.reconcilio.Match.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_by = 2;
 * @return {string}
 */
proto.reconcilio.Match.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Match} returns this
 */
proto.reconcilio.Match.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FinancialTransaction ledger_transactions = 3;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.Match.prototype.getLedgerTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 3));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.Match} returns this
*/
proto.reconcilio.Match.prototype.setLedgerTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.Match.prototype.addLedgerTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Match} returns this
 */
proto.reconcilio.Match.prototype.clearLedgerTransactionsList = function() {
  return this.setLedgerTransactionsList([]);
};


/**
 * repeated FinancialTransaction external_transactions = 4;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.Match.prototype.getExternalTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 4));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.Match} returns this
*/
proto.reconcilio.Match.prototype.setExternalTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.Match.prototype.addExternalTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Match} returns this
 */
proto.reconcilio.Match.prototype.clearExternalTransactionsList = function() {
  return this.setExternalTransactionsList([]);
};


/**
 * optional bool is_created_from_suggestion = 5;
 * @return {boolean}
 */
proto.reconcilio.Match.prototype.getIsCreatedFromSuggestion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.Match} returns this
 */
proto.reconcilio.Match.prototype.setIsCreatedFromSuggestion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationReportRequest}
 */
proto.reconcilio.GetReconciliationReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationReportRequest;
  return proto.reconcilio.GetReconciliationReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationReportRequest}
 */
proto.reconcilio.GetReconciliationReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportRequest} returns this
 */
proto.reconcilio.GetReconciliationReportRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetReconciliationReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetReconciliationReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetReconciliationReportResponse.ResultCase}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetReconciliationReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetReconciliationReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetReconciliationReportOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetReconciliationReportErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationReportResponse}
 */
proto.reconcilio.GetReconciliationReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationReportResponse;
  return proto.reconcilio.GetReconciliationReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationReportResponse}
 */
proto.reconcilio.GetReconciliationReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetReconciliationReportOKResponse;
      reader.readMessage(value,proto.reconcilio.GetReconciliationReportOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetReconciliationReportErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetReconciliationReportErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetReconciliationReportOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetReconciliationReportErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReconciliationReportOKResponse ok = 1;
 * @return {?proto.reconcilio.GetReconciliationReportOKResponse}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetReconciliationReportOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetReconciliationReportOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetReconciliationReportOKResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportResponse} returns this
*/
proto.reconcilio.GetReconciliationReportResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportResponse} returns this
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetReconciliationReportErrorResponse error = 2;
 * @return {?proto.reconcilio.GetReconciliationReportErrorResponse}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetReconciliationReportErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetReconciliationReportErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetReconciliationReportErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportResponse} returns this
*/
proto.reconcilio.GetReconciliationReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportResponse} returns this
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.FinancialTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.FinancialTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.FinancialTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.FinancialTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: (f = msg.getAmount()) && proto.reconcilio.Amount.toObject(includeInstance, f),
    balance: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rowId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    financialTransactionId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.FinancialTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.FinancialTransaction;
  return proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.FinancialTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 4:
      var value = new proto.reconcilio.Amount;
      reader.readMessage(value,proto.reconcilio.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRowId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancialTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.FinancialTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.FinancialTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.FinancialTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.FinancialTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.reconcilio.Amount.serializeBinaryToWriter
    );
  }
  f = message.getBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRowId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getFinancialTransactionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.reconcilio.FinancialTransaction.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.reconcilio.FinancialTransaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reference = 3;
 * @return {string}
 */
proto.reconcilio.FinancialTransaction.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Amount amount = 4;
 * @return {?proto.reconcilio.Amount}
 */
proto.reconcilio.FinancialTransaction.prototype.getAmount = function() {
  return /** @type{?proto.reconcilio.Amount} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.Amount, 4));
};


/**
 * @param {?proto.reconcilio.Amount|undefined} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
*/
proto.reconcilio.FinancialTransaction.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.FinancialTransaction.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double balance = 5;
 * @return {number}
 */
proto.reconcilio.FinancialTransaction.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.reconcilio.FinancialTransaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.reconcilio.FinancialTransaction.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 row_id = 8;
 * @return {number}
 */
proto.reconcilio.FinancialTransaction.prototype.getRowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setRowId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string financial_transaction_id = 9;
 * @return {string}
 */
proto.reconcilio.FinancialTransaction.prototype.getFinancialTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialTransaction} returns this
 */
proto.reconcilio.FinancialTransaction.prototype.setFinancialTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.FinancialDocument.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.FinancialDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.FinancialDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.FinancialDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.FinancialDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.FinancialDocument}
 */
proto.reconcilio.FinancialDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.FinancialDocument;
  return proto.reconcilio.FinancialDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.FinancialDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.FinancialDocument}
 */
proto.reconcilio.FinancialDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.FinancialDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.FinancialDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.FinancialDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.FinancialDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.FinancialDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialDocument} returns this
 */
proto.reconcilio.FinancialDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.FinancialDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialDocument} returns this
 */
proto.reconcilio.FinancialDocument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.reconcilio.FinancialDocument.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.FinancialDocument} returns this
 */
proto.reconcilio.FinancialDocument.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FinancialTransaction transactions = 4;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.FinancialDocument.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 4));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.FinancialDocument} returns this
*/
proto.reconcilio.FinancialDocument.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.FinancialDocument.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.FinancialDocument} returns this
 */
proto.reconcilio.FinancialDocument.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ReconciliationReportPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ReconciliationReportPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ReconciliationReportPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ReconciliationReportPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    startingDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endingDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ReconciliationReportPeriod}
 */
proto.reconcilio.ReconciliationReportPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ReconciliationReportPeriod;
  return proto.reconcilio.ReconciliationReportPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ReconciliationReportPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ReconciliationReportPeriod}
 */
proto.reconcilio.ReconciliationReportPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartingDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndingDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ReconciliationReportPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ReconciliationReportPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ReconciliationReportPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ReconciliationReportPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartingDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndingDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string starting_date = 1;
 * @return {string}
 */
proto.reconcilio.ReconciliationReportPeriod.prototype.getStartingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationReportPeriod} returns this
 */
proto.reconcilio.ReconciliationReportPeriod.prototype.setStartingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ending_date = 2;
 * @return {string}
 */
proto.reconcilio.ReconciliationReportPeriod.prototype.getEndingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationReportPeriod} returns this
 */
proto.reconcilio.ReconciliationReportPeriod.prototype.setEndingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.GetReconciliationReportOKResponse.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationReportOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationReportOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ledgerDocument: (f = msg.getLedgerDocument()) && proto.reconcilio.FinancialDocument.toObject(includeInstance, f),
    externalDocument: (f = msg.getExternalDocument()) && proto.reconcilio.FinancialDocument.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    suggestionsList: jspb.Message.toObjectList(msg.getSuggestionsList(),
    proto.reconcilio.Suggestion.toObject, includeInstance),
    matchesList: jspb.Message.toObjectList(msg.getMatchesList(),
    proto.reconcilio.Match.toObject, includeInstance),
    ledgerClosingBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    externalClosingBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    reconciliationType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 13, ""),
    year: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    owner: jspb.Message.getFieldWithDefault(msg, 16, ""),
    label: jspb.Message.getFieldWithDefault(msg, 17, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 18, ""),
    period: (f = msg.getPeriod()) && proto.reconcilio.ReconciliationReportPeriod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse}
 */
proto.reconcilio.GetReconciliationReportOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationReportOKResponse;
  return proto.reconcilio.GetReconciliationReportOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationReportOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse}
 */
proto.reconcilio.GetReconciliationReportOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.reconcilio.FinancialDocument;
      reader.readMessage(value,proto.reconcilio.FinancialDocument.deserializeBinaryFromReader);
      msg.setLedgerDocument(value);
      break;
    case 3:
      var value = new proto.reconcilio.FinancialDocument;
      reader.readMessage(value,proto.reconcilio.FinancialDocument.deserializeBinaryFromReader);
      msg.setExternalDocument(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.reconcilio.Suggestion;
      reader.readMessage(value,proto.reconcilio.Suggestion.deserializeBinaryFromReader);
      msg.addSuggestions(value);
      break;
    case 6:
      var value = new proto.reconcilio.Match;
      reader.readMessage(value,proto.reconcilio.Match.deserializeBinaryFromReader);
      msg.addMatches(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLedgerClosingBalance(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExternalClosingBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdatedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 19:
      var value = new proto.reconcilio.ReconciliationReportPeriod;
      reader.readMessage(value,proto.reconcilio.ReconciliationReportPeriod.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationReportOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationReportOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLedgerDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.FinancialDocument.serializeBinaryToWriter
    );
  }
  f = message.getExternalDocument();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.reconcilio.FinancialDocument.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSuggestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.reconcilio.Suggestion.serializeBinaryToWriter
    );
  }
  f = message.getMatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.reconcilio.Match.serializeBinaryToWriter
    );
  }
  f = message.getLedgerClosingBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getExternalClosingBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getReconciliationType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.reconcilio.ReconciliationReportPeriod.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FinancialDocument ledger_document = 2;
 * @return {?proto.reconcilio.FinancialDocument}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getLedgerDocument = function() {
  return /** @type{?proto.reconcilio.FinancialDocument} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.FinancialDocument, 2));
};


/**
 * @param {?proto.reconcilio.FinancialDocument|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
*/
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setLedgerDocument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.clearLedgerDocument = function() {
  return this.setLedgerDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.hasLedgerDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FinancialDocument external_document = 3;
 * @return {?proto.reconcilio.FinancialDocument}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getExternalDocument = function() {
  return /** @type{?proto.reconcilio.FinancialDocument} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.FinancialDocument, 3));
};


/**
 * @param {?proto.reconcilio.FinancialDocument|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
*/
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setExternalDocument = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.clearExternalDocument = function() {
  return this.setExternalDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.hasExternalDocument = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Suggestion suggestions = 5;
 * @return {!Array<!proto.reconcilio.Suggestion>}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getSuggestionsList = function() {
  return /** @type{!Array<!proto.reconcilio.Suggestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Suggestion, 5));
};


/**
 * @param {!Array<!proto.reconcilio.Suggestion>} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
*/
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setSuggestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.reconcilio.Suggestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Suggestion}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.addSuggestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.reconcilio.Suggestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.clearSuggestionsList = function() {
  return this.setSuggestionsList([]);
};


/**
 * repeated Match matches = 6;
 * @return {!Array<!proto.reconcilio.Match>}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getMatchesList = function() {
  return /** @type{!Array<!proto.reconcilio.Match>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Match, 6));
};


/**
 * @param {!Array<!proto.reconcilio.Match>} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
*/
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setMatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.reconcilio.Match=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Match}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.addMatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.reconcilio.Match, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.clearMatchesList = function() {
  return this.setMatchesList([]);
};


/**
 * optional double ledger_closing_balance = 7;
 * @return {number}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getLedgerClosingBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setLedgerClosingBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double external_closing_balance = 8;
 * @return {number}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getExternalClosingBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setExternalClosingBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string reconciliation_type = 10;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getReconciliationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setReconciliationType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string entity_id = 11;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string account_id = 12;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 13;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string year = 14;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional uint64 updated_at = 15;
 * @return {number}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string owner = 16;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string label = 17;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string comment = 18;
 * @return {string}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional ReconciliationReportPeriod period = 19;
 * @return {?proto.reconcilio.ReconciliationReportPeriod}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.getPeriod = function() {
  return /** @type{?proto.reconcilio.ReconciliationReportPeriod} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ReconciliationReportPeriod, 19));
};


/**
 * @param {?proto.reconcilio.ReconciliationReportPeriod|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
*/
proto.reconcilio.GetReconciliationReportOKResponse.prototype.setPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportOKResponse} returns this
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportOKResponse.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetReconciliationReportErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetReconciliationReportErrorResponse.ErrorCase}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetReconciliationReportErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetReconciliationReportErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationReportErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationReportErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationReportErrorResponse}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationReportErrorResponse;
  return proto.reconcilio.GetReconciliationReportErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationReportErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationReportErrorResponse}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationReportErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationReportErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationReportErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationReportErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationAccountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsRequest}
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountReportsRequest;
  return proto.reconcilio.GetReconciliationAccountReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsRequest}
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reconciliation_account_id = 1;
 * @return {string}
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.prototype.getReconciliationAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationAccountReportsRequest} returns this
 */
proto.reconcilio.GetReconciliationAccountReportsRequest.prototype.setReconciliationAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetReconciliationAccountReportsResponse.ResultCase}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetReconciliationAccountReportsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetReconciliationAccountReportsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetReconciliationAccountReportsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetReconciliationAccountReportsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountReportsResponse;
  return proto.reconcilio.GetReconciliationAccountReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetReconciliationAccountReportsOKResponse;
      reader.readMessage(value,proto.reconcilio.GetReconciliationAccountReportsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetReconciliationAccountReportsErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetReconciliationAccountReportsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetReconciliationAccountReportsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetReconciliationAccountReportsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReconciliationAccountReportsOKResponse ok = 1;
 * @return {?proto.reconcilio.GetReconciliationAccountReportsOKResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetReconciliationAccountReportsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetReconciliationAccountReportsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetReconciliationAccountReportsOKResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountReportsResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetReconciliationAccountReportsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetReconciliationAccountReportsErrorResponse error = 2;
 * @return {?proto.reconcilio.GetReconciliationAccountReportsErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetReconciliationAccountReportsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetReconciliationAccountReportsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetReconciliationAccountReportsErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountReportsResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetReconciliationAccountReportsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountReportsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountReportsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountReportsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.reconcilio.ReconciliationAccountReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsOKResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountReportsOKResponse;
  return proto.reconcilio.GetReconciliationAccountReportsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsOKResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.ReconciliationAccountReport;
      reader.readMessage(value,proto.reconcilio.ReconciliationAccountReport.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountReportsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconcilio.ReconciliationAccountReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReconciliationAccountReport reports = 1;
 * @return {!Array<!proto.reconcilio.ReconciliationAccountReport>}
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.reconcilio.ReconciliationAccountReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.ReconciliationAccountReport, 1));
};


/**
 * @param {!Array<!proto.reconcilio.ReconciliationAccountReport>} value
 * @return {!proto.reconcilio.GetReconciliationAccountReportsOKResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountReportsOKResponse.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconcilio.ReconciliationAccountReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.ReconciliationAccountReport}
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconcilio.ReconciliationAccountReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsOKResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountReportsOKResponse.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ReconciliationAccountReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ReconciliationAccountReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ReconciliationAccountReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reconciliationPeriod: (f = msg.getReconciliationPeriod()) && proto.reconcilio.ReconciliationReportPeriod.toObject(includeInstance, f),
    ledgerOpeningBalance: jspb.Message.getFieldWithDefault(msg, 6, ""),
    externalOpeningBalance: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ledgerClosingBalance: jspb.Message.getFieldWithDefault(msg, 8, ""),
    externalClosingBalance: jspb.Message.getFieldWithDefault(msg, 9, ""),
    year: jspb.Message.getFieldWithDefault(msg, 10, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ReconciliationAccountReport}
 */
proto.reconcilio.ReconciliationAccountReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ReconciliationAccountReport;
  return proto.reconcilio.ReconciliationAccountReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ReconciliationAccountReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ReconciliationAccountReport}
 */
proto.reconcilio.ReconciliationAccountReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 5:
      var value = new proto.reconcilio.ReconciliationReportPeriod;
      reader.readMessage(value,proto.reconcilio.ReconciliationReportPeriod.deserializeBinaryFromReader);
      msg.setReconciliationPeriod(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgerOpeningBalance(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalOpeningBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgerClosingBalance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalClosingBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ReconciliationAccountReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ReconciliationAccountReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ReconciliationAccountReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReconciliationPeriod();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.reconcilio.ReconciliationReportPeriod.serializeBinaryToWriter
    );
  }
  f = message.getLedgerOpeningBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExternalOpeningBalance();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLedgerClosingBalance();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExternalClosingBalance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string comment = 4;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ReconciliationReportPeriod reconciliation_period = 5;
 * @return {?proto.reconcilio.ReconciliationReportPeriod}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getReconciliationPeriod = function() {
  return /** @type{?proto.reconcilio.ReconciliationReportPeriod} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ReconciliationReportPeriod, 5));
};


/**
 * @param {?proto.reconcilio.ReconciliationReportPeriod|undefined} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
*/
proto.reconcilio.ReconciliationAccountReport.prototype.setReconciliationPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.clearReconciliationPeriod = function() {
  return this.setReconciliationPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.hasReconciliationPeriod = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string ledger_opening_balance = 6;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getLedgerOpeningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setLedgerOpeningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string external_opening_balance = 7;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getExternalOpeningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setExternalOpeningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ledger_closing_balance = 8;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getLedgerClosingBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setLedgerClosingBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string external_closing_balance = 9;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getExternalClosingBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setExternalClosingBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string year = 10;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string owner = 11;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint64 createdAt = 13;
 * @return {number}
 */
proto.reconcilio.ReconciliationAccountReport.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ReconciliationAccountReport} returns this
 */
proto.reconcilio.ReconciliationAccountReport.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNEXPECTED_ERROR: 1
};

/**
 * @return {proto.reconcilio.GetReconciliationAccountReportsErrorResponse.ErrorCase}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetReconciliationAccountReportsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetReconciliationAccountReportsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountReportsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountReportsErrorResponse;
  return proto.reconcilio.GetReconciliationAccountReportsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountReportsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unexpected_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetReconciliationAccountReportsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountReportsErrorResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountReportsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetSuggestionsErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetSuggestionsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetSuggestionsErrorResponse.ErrorCase}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetSuggestionsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetSuggestionsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetSuggestionsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetSuggestionsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetSuggestionsErrorResponse}
 */
proto.reconcilio.GetSuggestionsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetSuggestionsErrorResponse;
  return proto.reconcilio.GetSuggestionsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetSuggestionsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetSuggestionsErrorResponse}
 */
proto.reconcilio.GetSuggestionsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetSuggestionsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetSuggestionsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetSuggestionsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetSuggestionsErrorResponse} returns this
*/
proto.reconcilio.GetSuggestionsErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetSuggestionsErrorResponse} returns this
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetSuggestionsErrorResponse} returns this
*/
proto.reconcilio.GetSuggestionsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetSuggestionsErrorResponse} returns this
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetSuggestionsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UploadDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UploadDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UploadDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    storageIdentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fileType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    concreteDocumentType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isNotInStandardTemplateFormat: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UploadDocumentRequest}
 */
proto.reconcilio.UploadDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UploadDocumentRequest;
  return proto.reconcilio.UploadDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UploadDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UploadDocumentRequest}
 */
proto.reconcilio.UploadDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStorageIdentifier(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcreteDocumentType(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNotInStandardTemplateFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UploadDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UploadDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UploadDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStorageIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFileType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConcreteDocumentType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_type = 2;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string storage_identifier = 3;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getStorageIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setStorageIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string file_type = 4;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getFileType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setFileType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string workspace_id = 5;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string entity_id = 6;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_id = 7;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string concrete_document_type = 8;
 * @return {string}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getConcreteDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setConcreteDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_not_in_standard_template_format = 9;
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentRequest.prototype.getIsNotInStandardTemplateFormat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.setIsNotInStandardTemplateFormat = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentRequest} returns this
 */
proto.reconcilio.UploadDocumentRequest.prototype.clearIsNotInStandardTemplateFormat = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentRequest.prototype.hasIsNotInStandardTemplateFormat = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.UploadDocumentResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.UploadDocumentResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.UploadDocumentResponse.ResultCase}
 */
proto.reconcilio.UploadDocumentResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.UploadDocumentResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.UploadDocumentResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UploadDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UploadDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UploadDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.UploadDocumentOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.UploadDocumentErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UploadDocumentResponse}
 */
proto.reconcilio.UploadDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UploadDocumentResponse;
  return proto.reconcilio.UploadDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UploadDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UploadDocumentResponse}
 */
proto.reconcilio.UploadDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.UploadDocumentOKResponse;
      reader.readMessage(value,proto.reconcilio.UploadDocumentOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.UploadDocumentErrorResponse;
      reader.readMessage(value,proto.reconcilio.UploadDocumentErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UploadDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UploadDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UploadDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.UploadDocumentOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.UploadDocumentErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional UploadDocumentOKResponse ok = 1;
 * @return {?proto.reconcilio.UploadDocumentOKResponse}
 */
proto.reconcilio.UploadDocumentResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.UploadDocumentOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.UploadDocumentOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.UploadDocumentOKResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentResponse} returns this
*/
proto.reconcilio.UploadDocumentResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.UploadDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentResponse} returns this
 */
proto.reconcilio.UploadDocumentResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UploadDocumentErrorResponse error = 2;
 * @return {?proto.reconcilio.UploadDocumentErrorResponse}
 */
proto.reconcilio.UploadDocumentResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.UploadDocumentErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.UploadDocumentErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.UploadDocumentErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentResponse} returns this
*/
proto.reconcilio.UploadDocumentResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.UploadDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentResponse} returns this
 */
proto.reconcilio.UploadDocumentResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UploadDocumentOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UploadDocumentOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UploadDocumentOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UploadDocumentOKResponse}
 */
proto.reconcilio.UploadDocumentOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UploadDocumentOKResponse;
  return proto.reconcilio.UploadDocumentOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UploadDocumentOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UploadDocumentOKResponse}
 */
proto.reconcilio.UploadDocumentOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UploadDocumentOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UploadDocumentOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UploadDocumentOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.UploadDocumentOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UploadDocumentOKResponse} returns this
 */
proto.reconcilio.UploadDocumentOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.reconcilio.UploadDocumentErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  INVALID_FILE_TYPE_ERROR: 1,
  INVALID_DOCUMENT_LOCATION_ERROR: 2,
  INVALID_DOCUMENT_TYPE_ERROR: 3,
  INVALID_DOCUMENT_STATUS_ERROR: 4,
  UNAUTHORIZED_ERROR: 5,
  UNEXPECTED_ERROR: 6
};

/**
 * @return {proto.reconcilio.UploadDocumentErrorResponse.ErrorCase}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.UploadDocumentErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UploadDocumentErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UploadDocumentErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invalidFileTypeError: (f = msg.getInvalidFileTypeError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidDocumentLocationError: (f = msg.getInvalidDocumentLocationError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidDocumentTypeError: (f = msg.getInvalidDocumentTypeError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidDocumentStatusError: (f = msg.getInvalidDocumentStatusError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UploadDocumentErrorResponse;
  return proto.reconcilio.UploadDocumentErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UploadDocumentErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidFileTypeError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidDocumentLocationError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidDocumentTypeError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidDocumentStatusError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 6:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UploadDocumentErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UploadDocumentErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UploadDocumentErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvalidFileTypeError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidDocumentLocationError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidDocumentTypeError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidDocumentStatusError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse invalid_file_type_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getInvalidFileTypeError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
*/
proto.reconcilio.UploadDocumentErrorResponse.prototype.setInvalidFileTypeError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.clearInvalidFileTypeError = function() {
  return this.setInvalidFileTypeError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.hasInvalidFileTypeError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse invalid_document_location_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getInvalidDocumentLocationError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
*/
proto.reconcilio.UploadDocumentErrorResponse.prototype.setInvalidDocumentLocationError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.clearInvalidDocumentLocationError = function() {
  return this.setInvalidDocumentLocationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.hasInvalidDocumentLocationError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse invalid_document_type_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getInvalidDocumentTypeError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
*/
proto.reconcilio.UploadDocumentErrorResponse.prototype.setInvalidDocumentTypeError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.clearInvalidDocumentTypeError = function() {
  return this.setInvalidDocumentTypeError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.hasInvalidDocumentTypeError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse invalid_document_status_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getInvalidDocumentStatusError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
*/
proto.reconcilio.UploadDocumentErrorResponse.prototype.setInvalidDocumentStatusError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.clearInvalidDocumentStatusError = function() {
  return this.setInvalidDocumentStatusError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.hasInvalidDocumentStatusError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
*/
proto.reconcilio.UploadDocumentErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorResponse unexpected_error = 6;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 6));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
*/
proto.reconcilio.UploadDocumentErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.reconcilio.UploadDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UploadDocumentErrorResponse} returns this
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UploadDocumentErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ValidateDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ValidateDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ValidateDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    concreteDocumentType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ValidateDocumentRequest}
 */
proto.reconcilio.ValidateDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ValidateDocumentRequest;
  return proto.reconcilio.ValidateDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ValidateDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ValidateDocumentRequest}
 */
proto.reconcilio.ValidateDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConcreteDocumentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ValidateDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ValidateDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ValidateDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConcreteDocumentType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.ValidateDocumentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ValidateDocumentRequest} returns this
 */
proto.reconcilio.ValidateDocumentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string concrete_document_type = 2;
 * @return {string}
 */
proto.reconcilio.ValidateDocumentRequest.prototype.getConcreteDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ValidateDocumentRequest} returns this
 */
proto.reconcilio.ValidateDocumentRequest.prototype.setConcreteDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.ValidateDocumentResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.ValidateDocumentResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.ValidateDocumentResponse.ResultCase}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.ValidateDocumentResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.ValidateDocumentResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ValidateDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ValidateDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.ValidateDocumentOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.ValidateDocumentErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ValidateDocumentResponse}
 */
proto.reconcilio.ValidateDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ValidateDocumentResponse;
  return proto.reconcilio.ValidateDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ValidateDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ValidateDocumentResponse}
 */
proto.reconcilio.ValidateDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.ValidateDocumentOKResponse;
      reader.readMessage(value,proto.reconcilio.ValidateDocumentOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.ValidateDocumentErrorResponse;
      reader.readMessage(value,proto.reconcilio.ValidateDocumentErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ValidateDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ValidateDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.ValidateDocumentOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.ValidateDocumentErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ValidateDocumentOKResponse ok = 1;
 * @return {?proto.reconcilio.ValidateDocumentOKResponse}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.ValidateDocumentOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ValidateDocumentOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.ValidateDocumentOKResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentResponse} returns this
*/
proto.reconcilio.ValidateDocumentResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.ValidateDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentResponse} returns this
 */
proto.reconcilio.ValidateDocumentResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ValidateDocumentErrorResponse error = 2;
 * @return {?proto.reconcilio.ValidateDocumentErrorResponse}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.ValidateDocumentErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ValidateDocumentErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ValidateDocumentErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentResponse} returns this
*/
proto.reconcilio.ValidateDocumentResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.ValidateDocumentResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentResponse} returns this
 */
proto.reconcilio.ValidateDocumentResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ValidateDocumentOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ValidateDocumentOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ValidateDocumentOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    openingBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    isOpeningBalanceSet: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ValidateDocumentOKResponse}
 */
proto.reconcilio.ValidateDocumentOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ValidateDocumentOKResponse;
  return proto.reconcilio.ValidateDocumentOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ValidateDocumentOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ValidateDocumentOKResponse}
 */
proto.reconcilio.ValidateDocumentOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpeningBalance(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOpeningBalanceSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ValidateDocumentOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ValidateDocumentOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ValidateDocumentOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpeningBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getIsOpeningBalanceSet();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional double opening_balance = 1;
 * @return {number}
 */
proto.reconcilio.ValidateDocumentOKResponse.prototype.getOpeningBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ValidateDocumentOKResponse} returns this
 */
proto.reconcilio.ValidateDocumentOKResponse.prototype.setOpeningBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bool is_opening_balance_set = 2;
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentOKResponse.prototype.getIsOpeningBalanceSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.ValidateDocumentOKResponse} returns this
 */
proto.reconcilio.ValidateDocumentOKResponse.prototype.setIsOpeningBalanceSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.reconcilio.ValidateDocumentErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  DOCUMENT_NOT_FOUND_ERROR: 1,
  INVALID_DOCUMENT_STATUS_ERROR: 2,
  INVALID_DATE_FORMAT_ERROR: 3,
  MISSING_OPENING_BALANCE_ERROR: 4,
  DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED_ERROR: 5,
  INVALID_BALANCES_ERROR: 6,
  UNAUTHORIZED_ERROR: 7,
  UNEXPECTED_ERROR: 8,
  MIXED_DATES_FORMAT_ERROR: 9,
  DEBIT_CREDIT_SWAPPED_ERROR: 10,
  DOCUMENT_FORMAT_TYPE_MISSING_ERROR: 11
};

/**
 * @return {proto.reconcilio.ValidateDocumentErrorResponse.ErrorCase}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.ValidateDocumentErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ValidateDocumentErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ValidateDocumentErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentNotFoundError: (f = msg.getDocumentNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidDocumentStatusError: (f = msg.getInvalidDocumentStatusError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidDateFormatError: (f = msg.getInvalidDateFormatError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    missingOpeningBalanceError: (f = msg.getMissingOpeningBalanceError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    debitCreditMustBeExclusivelyFilledError: (f = msg.getDebitCreditMustBeExclusivelyFilledError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidBalancesError: (f = msg.getInvalidBalancesError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    mixedDatesFormatError: (f = msg.getMixedDatesFormatError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    debitCreditSwappedError: (f = msg.getDebitCreditSwappedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    documentFormatTypeMissingError: (f = msg.getDocumentFormatTypeMissingError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ValidateDocumentErrorResponse;
  return proto.reconcilio.ValidateDocumentErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ValidateDocumentErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDocumentNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidDocumentStatusError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidDateFormatError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setMissingOpeningBalanceError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDebitCreditMustBeExclusivelyFilledError(value);
      break;
    case 6:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidBalancesError(value);
      break;
    case 7:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 8:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    case 9:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setMixedDatesFormatError(value);
      break;
    case 10:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDebitCreditSwappedError(value);
      break;
    case 11:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDocumentFormatTypeMissingError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ValidateDocumentErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ValidateDocumentErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ValidateDocumentErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidDocumentStatusError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidDateFormatError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getMissingOpeningBalanceError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebitCreditMustBeExclusivelyFilledError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidBalancesError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getMixedDatesFormatError();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebitCreditSwappedError();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getDocumentFormatTypeMissingError();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse document_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getDocumentNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setDocumentNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearDocumentNotFoundError = function() {
  return this.setDocumentNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasDocumentNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse invalid_document_status_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getInvalidDocumentStatusError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setInvalidDocumentStatusError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearInvalidDocumentStatusError = function() {
  return this.setInvalidDocumentStatusError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasInvalidDocumentStatusError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse invalid_date_format_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getInvalidDateFormatError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setInvalidDateFormatError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearInvalidDateFormatError = function() {
  return this.setInvalidDateFormatError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasInvalidDateFormatError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse missing_opening_balance_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getMissingOpeningBalanceError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setMissingOpeningBalanceError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearMissingOpeningBalanceError = function() {
  return this.setMissingOpeningBalanceError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasMissingOpeningBalanceError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse debit_credit_must_be_exclusively_filled_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getDebitCreditMustBeExclusivelyFilledError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setDebitCreditMustBeExclusivelyFilledError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearDebitCreditMustBeExclusivelyFilledError = function() {
  return this.setDebitCreditMustBeExclusivelyFilledError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasDebitCreditMustBeExclusivelyFilledError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorResponse invalid_balances_error = 6;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getInvalidBalancesError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 6));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setInvalidBalancesError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearInvalidBalancesError = function() {
  return this.setInvalidBalancesError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasInvalidBalancesError = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 7;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 7));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ErrorResponse unexpected_error = 8;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 8));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ErrorResponse mixed_dates_format_error = 9;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getMixedDatesFormatError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 9));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setMixedDatesFormatError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearMixedDatesFormatError = function() {
  return this.setMixedDatesFormatError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasMixedDatesFormatError = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ErrorResponse debit_credit_swapped_error = 10;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getDebitCreditSwappedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 10));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setDebitCreditSwappedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearDebitCreditSwappedError = function() {
  return this.setDebitCreditSwappedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasDebitCreditSwappedError = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ErrorResponse document_format_type_missing_error = 11;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.getDocumentFormatTypeMissingError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 11));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
*/
proto.reconcilio.ValidateDocumentErrorResponse.prototype.setDocumentFormatTypeMissingError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.reconcilio.ValidateDocumentErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ValidateDocumentErrorResponse} returns this
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.clearDocumentFormatTypeMissingError = function() {
  return this.setDocumentFormatTypeMissingError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ValidateDocumentErrorResponse.prototype.hasDocumentFormatTypeMissingError = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.ManualReconciliationRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ManualReconciliationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ManualReconciliationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ManualReconciliationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ledgerTransactionRowIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    externalTransactionRowIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ManualReconciliationRequest}
 */
proto.reconcilio.ManualReconciliationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ManualReconciliationRequest;
  return proto.reconcilio.ManualReconciliationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ManualReconciliationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ManualReconciliationRequest}
 */
proto.reconcilio.ManualReconciliationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLedgerTransactionRowIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addExternalTransactionRowIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ManualReconciliationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ManualReconciliationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ManualReconciliationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLedgerTransactionRowIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getExternalTransactionRowIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.ManualReconciliationRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int32 ledger_transaction_row_ids = 2;
 * @return {!Array<number>}
 */
proto.reconcilio.ManualReconciliationRequest.prototype.getLedgerTransactionRowIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.setLedgerTransactionRowIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.addLedgerTransactionRowIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.clearLedgerTransactionRowIdsList = function() {
  return this.setLedgerTransactionRowIdsList([]);
};


/**
 * repeated int32 external_transaction_row_ids = 3;
 * @return {!Array<number>}
 */
proto.reconcilio.ManualReconciliationRequest.prototype.getExternalTransactionRowIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.setExternalTransactionRowIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.addExternalTransactionRowIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.ManualReconciliationRequest} returns this
 */
proto.reconcilio.ManualReconciliationRequest.prototype.clearExternalTransactionRowIdsList = function() {
  return this.setExternalTransactionRowIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.ManualReconciliationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.ManualReconciliationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.ManualReconciliationResponse.ResultCase}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.ManualReconciliationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.ManualReconciliationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ManualReconciliationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ManualReconciliationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.ManualReconciliationOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.ManualReconciliationErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ManualReconciliationResponse}
 */
proto.reconcilio.ManualReconciliationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ManualReconciliationResponse;
  return proto.reconcilio.ManualReconciliationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ManualReconciliationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ManualReconciliationResponse}
 */
proto.reconcilio.ManualReconciliationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.ManualReconciliationOKResponse;
      reader.readMessage(value,proto.reconcilio.ManualReconciliationOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.ManualReconciliationErrorResponse;
      reader.readMessage(value,proto.reconcilio.ManualReconciliationErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ManualReconciliationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ManualReconciliationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.ManualReconciliationOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.ManualReconciliationErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ManualReconciliationOKResponse ok = 1;
 * @return {?proto.reconcilio.ManualReconciliationOKResponse}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.ManualReconciliationOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ManualReconciliationOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.ManualReconciliationOKResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationResponse} returns this
*/
proto.reconcilio.ManualReconciliationResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.ManualReconciliationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationResponse} returns this
 */
proto.reconcilio.ManualReconciliationResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ManualReconciliationErrorResponse error = 2;
 * @return {?proto.reconcilio.ManualReconciliationErrorResponse}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.ManualReconciliationErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ManualReconciliationErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ManualReconciliationErrorResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationResponse} returns this
*/
proto.reconcilio.ManualReconciliationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.ManualReconciliationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationResponse} returns this
 */
proto.reconcilio.ManualReconciliationResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ManualReconciliationOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ManualReconciliationOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ManualReconciliationOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ManualReconciliationOKResponse}
 */
proto.reconcilio.ManualReconciliationOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ManualReconciliationOKResponse;
  return proto.reconcilio.ManualReconciliationOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ManualReconciliationOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ManualReconciliationOKResponse}
 */
proto.reconcilio.ManualReconciliationOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ManualReconciliationOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ManualReconciliationOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ManualReconciliationOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.ManualReconciliationErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  TRANSACTIONS_NOT_SUM_TO_ZERO_ERROR: 1,
  RECONCILIATION_REPORT_STATUS_LOCKED_ERROR: 2,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 3,
  TRANSACATION_IS_NOT_UNRECONCILED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.ManualReconciliationErrorResponse.ErrorCase}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.ManualReconciliationErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ManualReconciliationErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ManualReconciliationErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsNotSumToZeroError: (f = msg.getTransactionsNotSumToZeroError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportStatusLockedError: (f = msg.getReconciliationReportStatusLockedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    transacationIsNotUnreconciledError: (f = msg.getTransacationIsNotUnreconciledError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ManualReconciliationErrorResponse;
  return proto.reconcilio.ManualReconciliationErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ManualReconciliationErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setTransactionsNotSumToZeroError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportStatusLockedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setTransacationIsNotUnreconciledError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ManualReconciliationErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ManualReconciliationErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ManualReconciliationErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsNotSumToZeroError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportStatusLockedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTransacationIsNotUnreconciledError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse transactions_not_sum_to_zero_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.getTransactionsNotSumToZeroError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
*/
proto.reconcilio.ManualReconciliationErrorResponse.prototype.setTransactionsNotSumToZeroError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.clearTransactionsNotSumToZeroError = function() {
  return this.setTransactionsNotSumToZeroError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.hasTransactionsNotSumToZeroError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse reconciliation_report_status_locked_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.getReconciliationReportStatusLockedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
*/
proto.reconcilio.ManualReconciliationErrorResponse.prototype.setReconciliationReportStatusLockedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.clearReconciliationReportStatusLockedError = function() {
  return this.setReconciliationReportStatusLockedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.hasReconciliationReportStatusLockedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
*/
proto.reconcilio.ManualReconciliationErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse transacation_is_not_unreconciled_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.getTransacationIsNotUnreconciledError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
*/
proto.reconcilio.ManualReconciliationErrorResponse.prototype.setTransacationIsNotUnreconciledError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.clearTransacationIsNotUnreconciledError = function() {
  return this.setTransacationIsNotUnreconciledError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.hasTransacationIsNotUnreconciledError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
*/
proto.reconcilio.ManualReconciliationErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.ManualReconciliationErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.ManualReconciliationErrorResponse} returns this
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.ManualReconciliationErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionRequest}
 */
proto.reconcilio.AcceptSuggestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionRequest;
  return proto.reconcilio.AcceptSuggestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionRequest}
 */
proto.reconcilio.AcceptSuggestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.AcceptSuggestionRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AcceptSuggestionRequest} returns this
 */
proto.reconcilio.AcceptSuggestionRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string suggestion_id = 2;
 * @return {string}
 */
proto.reconcilio.AcceptSuggestionRequest.prototype.getSuggestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AcceptSuggestionRequest} returns this
 */
proto.reconcilio.AcceptSuggestionRequest.prototype.setSuggestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AcceptSuggestionResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AcceptSuggestionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AcceptSuggestionResponse.ResultCase}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AcceptSuggestionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AcceptSuggestionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AcceptSuggestionOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AcceptSuggestionErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionResponse}
 */
proto.reconcilio.AcceptSuggestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionResponse;
  return proto.reconcilio.AcceptSuggestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionResponse}
 */
proto.reconcilio.AcceptSuggestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AcceptSuggestionOKResponse;
      reader.readMessage(value,proto.reconcilio.AcceptSuggestionOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AcceptSuggestionErrorResponse;
      reader.readMessage(value,proto.reconcilio.AcceptSuggestionErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AcceptSuggestionOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AcceptSuggestionErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AcceptSuggestionOKResponse ok = 1;
 * @return {?proto.reconcilio.AcceptSuggestionOKResponse}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AcceptSuggestionOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AcceptSuggestionOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AcceptSuggestionOKResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionResponse} returns this
*/
proto.reconcilio.AcceptSuggestionResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AcceptSuggestionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionResponse} returns this
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AcceptSuggestionErrorResponse error = 2;
 * @return {?proto.reconcilio.AcceptSuggestionErrorResponse}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AcceptSuggestionErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AcceptSuggestionErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AcceptSuggestionErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionResponse} returns this
*/
proto.reconcilio.AcceptSuggestionResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AcceptSuggestionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionResponse} returns this
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionOKResponse}
 */
proto.reconcilio.AcceptSuggestionOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionOKResponse;
  return proto.reconcilio.AcceptSuggestionOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionOKResponse}
 */
proto.reconcilio.AcceptSuggestionOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  CREDIT_DEBIT_SUM_ERROR: 1,
  SUGGESTION_DOES_NOT_EXIST_IN_RECONCILIATION_ERROR: 2,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.AcceptSuggestionErrorResponse.ErrorCase}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AcceptSuggestionErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditDebitSumError: (f = msg.getCreditDebitSumError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    suggestionDoesNotExistInReconciliationError: (f = msg.getSuggestionDoesNotExistInReconciliationError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionErrorResponse;
  return proto.reconcilio.AcceptSuggestionErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCreditDebitSumError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setSuggestionDoesNotExistInReconciliationError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditDebitSumError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getSuggestionDoesNotExistInReconciliationError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse credit_debit_sum_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.getCreditDebitSumError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.setCreditDebitSumError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.clearCreditDebitSumError = function() {
  return this.setCreditDebitSumError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.hasCreditDebitSumError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse suggestion_does_not_exist_in_reconciliation_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.getSuggestionDoesNotExistInReconciliationError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.setSuggestionDoesNotExistInReconciliationError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.clearSuggestionDoesNotExistInReconciliationError = function() {
  return this.setSuggestionDoesNotExistInReconciliationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.hasSuggestionDoesNotExistInReconciliationError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.AcceptSuggestionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.AcceptSuggestionsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionsRequest}
 */
proto.reconcilio.AcceptSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionsRequest;
  return proto.reconcilio.AcceptSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionsRequest}
 */
proto.reconcilio.AcceptSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuggestionIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AcceptSuggestionsRequest} returns this
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string suggestion_ids = 2;
 * @return {!Array<string>}
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.getSuggestionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reconcilio.AcceptSuggestionsRequest} returns this
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.setSuggestionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.AcceptSuggestionsRequest} returns this
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.addSuggestionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.AcceptSuggestionsRequest} returns this
 */
proto.reconcilio.AcceptSuggestionsRequest.prototype.clearSuggestionIdsList = function() {
  return this.setSuggestionIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AcceptSuggestionsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AcceptSuggestionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AcceptSuggestionsResponse.ResultCase}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AcceptSuggestionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AcceptSuggestionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AcceptSuggestionsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AcceptSuggestionsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionsResponse}
 */
proto.reconcilio.AcceptSuggestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionsResponse;
  return proto.reconcilio.AcceptSuggestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionsResponse}
 */
proto.reconcilio.AcceptSuggestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AcceptSuggestionsOKResponse;
      reader.readMessage(value,proto.reconcilio.AcceptSuggestionsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AcceptSuggestionsErrorResponse;
      reader.readMessage(value,proto.reconcilio.AcceptSuggestionsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AcceptSuggestionsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AcceptSuggestionsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AcceptSuggestionsOKResponse ok = 1;
 * @return {?proto.reconcilio.AcceptSuggestionsOKResponse}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AcceptSuggestionsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AcceptSuggestionsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AcceptSuggestionsOKResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionsResponse} returns this
*/
proto.reconcilio.AcceptSuggestionsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AcceptSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionsResponse} returns this
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AcceptSuggestionsErrorResponse error = 2;
 * @return {?proto.reconcilio.AcceptSuggestionsErrorResponse}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AcceptSuggestionsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AcceptSuggestionsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AcceptSuggestionsErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionsResponse} returns this
*/
proto.reconcilio.AcceptSuggestionsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AcceptSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionsResponse} returns this
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionsOKResponse}
 */
proto.reconcilio.AcceptSuggestionsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionsOKResponse;
  return proto.reconcilio.AcceptSuggestionsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionsOKResponse}
 */
proto.reconcilio.AcceptSuggestionsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  CREDIT_DEBIT_SUM_ERROR: 1,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.AcceptSuggestionsErrorResponse.ErrorCase}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AcceptSuggestionsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AcceptSuggestionsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptSuggestionsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptSuggestionsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditDebitSumError: (f = msg.getCreditDebitSumError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptSuggestionsErrorResponse;
  return proto.reconcilio.AcceptSuggestionsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptSuggestionsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCreditDebitSumError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptSuggestionsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptSuggestionsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditDebitSumError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse credit_debit_sum_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.getCreditDebitSumError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.setCreditDebitSumError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AcceptSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.clearCreditDebitSumError = function() {
  return this.setCreditDebitSumError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.hasCreditDebitSumError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AcceptSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse} returns this
*/
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AcceptSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptSuggestionsErrorResponse} returns this
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptSuggestionsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionRequest}
 */
proto.reconcilio.RejectSuggestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionRequest;
  return proto.reconcilio.RejectSuggestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionRequest}
 */
proto.reconcilio.RejectSuggestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.RejectSuggestionRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.RejectSuggestionRequest} returns this
 */
proto.reconcilio.RejectSuggestionRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string suggestion_id = 2;
 * @return {string}
 */
proto.reconcilio.RejectSuggestionRequest.prototype.getSuggestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.RejectSuggestionRequest} returns this
 */
proto.reconcilio.RejectSuggestionRequest.prototype.setSuggestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.RejectSuggestionResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.RejectSuggestionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.RejectSuggestionResponse.ResultCase}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.RejectSuggestionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.RejectSuggestionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.RejectSuggestionOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.RejectSuggestionErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionResponse}
 */
proto.reconcilio.RejectSuggestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionResponse;
  return proto.reconcilio.RejectSuggestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionResponse}
 */
proto.reconcilio.RejectSuggestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.RejectSuggestionOKResponse;
      reader.readMessage(value,proto.reconcilio.RejectSuggestionOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.RejectSuggestionErrorResponse;
      reader.readMessage(value,proto.reconcilio.RejectSuggestionErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.RejectSuggestionOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.RejectSuggestionErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional RejectSuggestionOKResponse ok = 1;
 * @return {?proto.reconcilio.RejectSuggestionOKResponse}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.RejectSuggestionOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.RejectSuggestionOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.RejectSuggestionOKResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionResponse} returns this
*/
proto.reconcilio.RejectSuggestionResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.RejectSuggestionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionResponse} returns this
 */
proto.reconcilio.RejectSuggestionResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RejectSuggestionErrorResponse error = 2;
 * @return {?proto.reconcilio.RejectSuggestionErrorResponse}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.RejectSuggestionErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.RejectSuggestionErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.RejectSuggestionErrorResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionResponse} returns this
*/
proto.reconcilio.RejectSuggestionResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.RejectSuggestionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionResponse} returns this
 */
proto.reconcilio.RejectSuggestionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionOKResponse}
 */
proto.reconcilio.RejectSuggestionOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionOKResponse;
  return proto.reconcilio.RejectSuggestionOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionOKResponse}
 */
proto.reconcilio.RejectSuggestionOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.RejectSuggestionErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.RejectSuggestionErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.RejectSuggestionErrorResponse.ErrorCase}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.RejectSuggestionErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.RejectSuggestionErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionErrorResponse}
 */
proto.reconcilio.RejectSuggestionErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionErrorResponse;
  return proto.reconcilio.RejectSuggestionErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionErrorResponse}
 */
proto.reconcilio.RejectSuggestionErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionErrorResponse} returns this
*/
proto.reconcilio.RejectSuggestionErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.RejectSuggestionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionErrorResponse} returns this
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionErrorResponse} returns this
*/
proto.reconcilio.RejectSuggestionErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.RejectSuggestionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionErrorResponse} returns this
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ledgerDocumentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalDocumentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    label: jspb.Message.getFieldWithDefault(msg, 9, ""),
    shouldCreateCompensatingTransaction: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    previousReconciliationId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    ledgerDocumentType: jspb.Message.getFieldWithDefault(msg, 12, ""),
    externalDocumentType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isLedgerOverwritten: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    overwriteLedgerOb: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    isExternalOverwritten: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    overwriteExternalOb: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    externalDebitIncreaseBalance: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    externalDebitDecreaseBalance: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    ledgerDebitIncreaseBalance: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    ledgerDebitDecreaseBalance: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationReportRequest}
 */
proto.reconcilio.CreateReconciliationReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationReportRequest;
  return proto.reconcilio.CreateReconciliationReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationReportRequest}
 */
proto.reconcilio.CreateReconciliationReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgerDocumentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDocumentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldCreateCompensatingTransaction(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousReconciliationId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgerDocumentType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDocumentType(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLedgerOverwritten(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverwriteLedgerOb(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExternalOverwritten(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverwriteExternalOb(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalDebitIncreaseBalance(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalDebitDecreaseBalance(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLedgerDebitIncreaseBalance(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLedgerDebitDecreaseBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLedgerDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getShouldCreateCompensatingTransaction();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLedgerDocumentType();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getExternalDocumentType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsLedgerOverwritten();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getOverwriteLedgerOb();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getIsExternalOverwritten();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getOverwriteExternalOb();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getExternalDebitIncreaseBalance();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getExternalDebitDecreaseBalance();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getLedgerDebitIncreaseBalance();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getLedgerDebitDecreaseBalance();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string ledger_document_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getLedgerDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setLedgerDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_document_id = 2;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getExternalDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setExternalDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string workspace_id = 3;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entity_id = 4;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string start_date = 7;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string end_date = 8;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string label = 9;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool should_create_compensating_transaction = 10;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getShouldCreateCompensatingTransaction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setShouldCreateCompensatingTransaction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string previous_reconciliation_id = 11;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getPreviousReconciliationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setPreviousReconciliationId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.clearPreviousReconciliationId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.hasPreviousReconciliationId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string ledger_document_type = 12;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getLedgerDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setLedgerDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string external_document_type = 13;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getExternalDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setExternalDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_ledger_overwritten = 14;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getIsLedgerOverwritten = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setIsLedgerOverwritten = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional double overwrite_ledger_ob = 15;
 * @return {number}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getOverwriteLedgerOb = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setOverwriteLedgerOb = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional bool is_external_overwritten = 16;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getIsExternalOverwritten = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setIsExternalOverwritten = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional double overwrite_external_ob = 17;
 * @return {number}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getOverwriteExternalOb = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setOverwriteExternalOb = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional bool external_debit_increase_balance = 18;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getExternalDebitIncreaseBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setExternalDebitIncreaseBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool external_debit_decrease_balance = 19;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getExternalDebitDecreaseBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setExternalDebitDecreaseBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool ledger_debit_increase_balance = 20;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getLedgerDebitIncreaseBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setLedgerDebitIncreaseBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool ledger_debit_decrease_balance = 21;
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.getLedgerDebitDecreaseBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.CreateReconciliationReportRequest} returns this
 */
proto.reconcilio.CreateReconciliationReportRequest.prototype.setLedgerDebitDecreaseBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateReconciliationReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateReconciliationReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateReconciliationReportResponse.ResultCase}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateReconciliationReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateReconciliationReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateReconciliationReportOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateReconciliationReportErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationReportResponse}
 */
proto.reconcilio.CreateReconciliationReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationReportResponse;
  return proto.reconcilio.CreateReconciliationReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationReportResponse}
 */
proto.reconcilio.CreateReconciliationReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateReconciliationReportOKResponse;
      reader.readMessage(value,proto.reconcilio.CreateReconciliationReportOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateReconciliationReportErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateReconciliationReportErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateReconciliationReportOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateReconciliationReportErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateReconciliationReportOKResponse ok = 1;
 * @return {?proto.reconcilio.CreateReconciliationReportOKResponse}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateReconciliationReportOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateReconciliationReportOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.CreateReconciliationReportOKResponse|undefined} value
 * @return {!proto.reconcilio.CreateReconciliationReportResponse} returns this
*/
proto.reconcilio.CreateReconciliationReportResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationReportResponse} returns this
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateReconciliationReportErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateReconciliationReportErrorResponse}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateReconciliationReportErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateReconciliationReportErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateReconciliationReportErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateReconciliationReportResponse} returns this
*/
proto.reconcilio.CreateReconciliationReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationReportResponse} returns this
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationReportOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationReportOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationReportOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationReportOKResponse}
 */
proto.reconcilio.CreateReconciliationReportOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationReportOKResponse;
  return proto.reconcilio.CreateReconciliationReportOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationReportOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationReportOKResponse}
 */
proto.reconcilio.CreateReconciliationReportOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationReportOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationReportOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationReportOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportOKResponse.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportOKResponse} returns this
 */
proto.reconcilio.CreateReconciliationReportOKResponse.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationReportErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationReportErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationReportErrorResponse}
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationReportErrorResponse;
  return proto.reconcilio.CreateReconciliationReportErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationReportErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationReportErrorResponse}
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationReportErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationReportErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string error_message = 1;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.CreateReconciliationReportErrorResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.LockReconciliationReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.LockReconciliationReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.LockReconciliationReportRequest}
 */
proto.reconcilio.LockReconciliationReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.LockReconciliationReportRequest;
  return proto.reconcilio.LockReconciliationReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.LockReconciliationReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.LockReconciliationReportRequest}
 */
proto.reconcilio.LockReconciliationReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.LockReconciliationReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.LockReconciliationReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.LockReconciliationReportRequest} returns this
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.LockReconciliationReportRequest} returns this
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.LockReconciliationReportRequest} returns this
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.LockReconciliationReportRequest} returns this
 */
proto.reconcilio.LockReconciliationReportRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.LockReconciliationReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.LockReconciliationReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.LockReconciliationReportResponse.ResultCase}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.LockReconciliationReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.LockReconciliationReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.LockReconciliationReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.LockReconciliationReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.LockReconciliationReportOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.LockReconciliationReportErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.LockReconciliationReportResponse}
 */
proto.reconcilio.LockReconciliationReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.LockReconciliationReportResponse;
  return proto.reconcilio.LockReconciliationReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.LockReconciliationReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.LockReconciliationReportResponse}
 */
proto.reconcilio.LockReconciliationReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.LockReconciliationReportOKResponse;
      reader.readMessage(value,proto.reconcilio.LockReconciliationReportOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.LockReconciliationReportErrorResponse;
      reader.readMessage(value,proto.reconcilio.LockReconciliationReportErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.LockReconciliationReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.LockReconciliationReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.LockReconciliationReportOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.LockReconciliationReportErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional LockReconciliationReportOKResponse ok = 1;
 * @return {?proto.reconcilio.LockReconciliationReportOKResponse}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.LockReconciliationReportOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.LockReconciliationReportOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.LockReconciliationReportOKResponse|undefined} value
 * @return {!proto.reconcilio.LockReconciliationReportResponse} returns this
*/
proto.reconcilio.LockReconciliationReportResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.LockReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.LockReconciliationReportResponse} returns this
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LockReconciliationReportErrorResponse error = 2;
 * @return {?proto.reconcilio.LockReconciliationReportErrorResponse}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.LockReconciliationReportErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.LockReconciliationReportErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.LockReconciliationReportErrorResponse|undefined} value
 * @return {!proto.reconcilio.LockReconciliationReportResponse} returns this
*/
proto.reconcilio.LockReconciliationReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.LockReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.LockReconciliationReportResponse} returns this
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.LockReconciliationReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.LockReconciliationReportOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.LockReconciliationReportOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.LockReconciliationReportOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.LockReconciliationReportOKResponse}
 */
proto.reconcilio.LockReconciliationReportOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.LockReconciliationReportOKResponse;
  return proto.reconcilio.LockReconciliationReportOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.LockReconciliationReportOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.LockReconciliationReportOKResponse}
 */
proto.reconcilio.LockReconciliationReportOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.LockReconciliationReportOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.LockReconciliationReportOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.LockReconciliationReportOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.LockReconciliationReportErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  RECONCILIATION_REPORT_ALREADY_LOCKED_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.LockReconciliationReportErrorResponse.ErrorCase}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.LockReconciliationReportErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.LockReconciliationReportErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.LockReconciliationReportErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.LockReconciliationReportErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportAlreadyLockedError: (f = msg.getReconciliationReportAlreadyLockedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.LockReconciliationReportErrorResponse;
  return proto.reconcilio.LockReconciliationReportErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.LockReconciliationReportErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportAlreadyLockedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.LockReconciliationReportErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.LockReconciliationReportErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.LockReconciliationReportErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportAlreadyLockedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.LockReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse reconciliation_report_already_locked_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.getReconciliationReportAlreadyLockedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.setReconciliationReportAlreadyLockedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.LockReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.clearReconciliationReportAlreadyLockedError = function() {
  return this.setReconciliationReportAlreadyLockedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.hasReconciliationReportAlreadyLockedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.LockReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.LockReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.LockReconciliationReportErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.OnCreateReconciliationReportEvent.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2,
  PROGRESS: 3
};

/**
 * @return {proto.reconcilio.OnCreateReconciliationReportEvent.ResultCase}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.OnCreateReconciliationReportEvent.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.OnCreateReconciliationReportEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.OnCreateReconciliationReportEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.OnCreateReconciliationReportEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.OnCreateReconciliationReportOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.OnCreateReconciliationReportErrorResponse.toObject(includeInstance, f),
    progress: (f = msg.getProgress()) && proto.reconcilio.OnCreateReconciliationReportProgressResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.OnCreateReconciliationReportEvent;
  return proto.reconcilio.OnCreateReconciliationReportEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.OnCreateReconciliationReportEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.OnCreateReconciliationReportOKResponse;
      reader.readMessage(value,proto.reconcilio.OnCreateReconciliationReportOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.OnCreateReconciliationReportErrorResponse;
      reader.readMessage(value,proto.reconcilio.OnCreateReconciliationReportErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.reconcilio.OnCreateReconciliationReportProgressResponse;
      reader.readMessage(value,proto.reconcilio.OnCreateReconciliationReportProgressResponse.deserializeBinaryFromReader);
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.OnCreateReconciliationReportEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.OnCreateReconciliationReportEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.OnCreateReconciliationReportOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.OnCreateReconciliationReportErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getProgress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.reconcilio.OnCreateReconciliationReportProgressResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional OnCreateReconciliationReportOKResponse ok = 1;
 * @return {?proto.reconcilio.OnCreateReconciliationReportOKResponse}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.OnCreateReconciliationReportOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.OnCreateReconciliationReportOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.OnCreateReconciliationReportOKResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent} returns this
*/
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.OnCreateReconciliationReportEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent} returns this
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OnCreateReconciliationReportErrorResponse error = 2;
 * @return {?proto.reconcilio.OnCreateReconciliationReportErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.getError = function() {
  return /** @type{?proto.reconcilio.OnCreateReconciliationReportErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.OnCreateReconciliationReportErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.OnCreateReconciliationReportErrorResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent} returns this
*/
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.OnCreateReconciliationReportEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent} returns this
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OnCreateReconciliationReportProgressResponse progress = 3;
 * @return {?proto.reconcilio.OnCreateReconciliationReportProgressResponse}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.getProgress = function() {
  return /** @type{?proto.reconcilio.OnCreateReconciliationReportProgressResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.OnCreateReconciliationReportProgressResponse, 3));
};


/**
 * @param {?proto.reconcilio.OnCreateReconciliationReportProgressResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent} returns this
*/
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.setProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.OnCreateReconciliationReportEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportEvent} returns this
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.clearProgress = function() {
  return this.setProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportEvent.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.OnCreateReconciliationReportOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.OnCreateReconciliationReportOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.OnCreateReconciliationReportOKResponse}
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.OnCreateReconciliationReportOKResponse;
  return proto.reconcilio.OnCreateReconciliationReportOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.OnCreateReconciliationReportOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.OnCreateReconciliationReportOKResponse}
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.OnCreateReconciliationReportOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.OnCreateReconciliationReportOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportOKResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  DOCUMENT_NOT_FOUND_ERROR: 1,
  DOCUMENT_NOT_FOUND_AT_LOCATION_ERROR: 2,
  DOCUMENT_STATUS_NOT_VALIDATED_ERROR: 3,
  CREDIT_DEBIT_SUM_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.OnCreateReconciliationReportErrorResponse.ErrorCase}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.OnCreateReconciliationReportErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.OnCreateReconciliationReportErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentNotFoundError: (f = msg.getDocumentNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    documentNotFoundAtLocationError: (f = msg.getDocumentNotFoundAtLocationError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    documentStatusNotValidatedError: (f = msg.getDocumentStatusNotValidatedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    creditDebitSumError: (f = msg.getCreditDebitSumError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.OnCreateReconciliationReportErrorResponse;
  return proto.reconcilio.OnCreateReconciliationReportErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDocumentNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDocumentNotFoundAtLocationError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setDocumentStatusNotValidatedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCreditDebitSumError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.OnCreateReconciliationReportErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getDocumentNotFoundAtLocationError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getDocumentStatusNotValidatedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreditDebitSumError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse document_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.getDocumentNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.setDocumentNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.clearDocumentNotFoundError = function() {
  return this.setDocumentNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.hasDocumentNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse document_not_found_at_location_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.getDocumentNotFoundAtLocationError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.setDocumentNotFoundAtLocationError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.clearDocumentNotFoundAtLocationError = function() {
  return this.setDocumentNotFoundAtLocationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.hasDocumentNotFoundAtLocationError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse document_status_not_validated_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.getDocumentStatusNotValidatedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.setDocumentStatusNotValidatedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.clearDocumentStatusNotValidatedError = function() {
  return this.setDocumentStatusNotValidatedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.hasDocumentStatusNotValidatedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse credit_debit_sum_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.getCreditDebitSumError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.setCreditDebitSumError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.clearCreditDebitSumError = function() {
  return this.setCreditDebitSumError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.hasCreditDebitSumError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.OnCreateReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ProgressEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ProgressEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ProgressEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ProgressEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalReconciled: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ProgressEvent}
 */
proto.reconcilio.ProgressEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ProgressEvent;
  return proto.reconcilio.ProgressEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ProgressEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ProgressEvent}
 */
proto.reconcilio.ProgressEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalReconciled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ProgressEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ProgressEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ProgressEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ProgressEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalReconciled();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional double percentage = 1;
 * @return {number}
 */
proto.reconcilio.ProgressEvent.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ProgressEvent} returns this
 */
proto.reconcilio.ProgressEvent.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 total_reconciled = 2;
 * @return {number}
 */
proto.reconcilio.ProgressEvent.prototype.getTotalReconciled = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ProgressEvent} returns this
 */
proto.reconcilio.ProgressEvent.prototype.setTotalReconciled = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.reconcilio.ProgressEvent.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ProgressEvent} returns this
 */
proto.reconcilio.ProgressEvent.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.ProgressOrMessageCase = {
  PROGRESS_OR_MESSAGE_NOT_SET: 0,
  PROGRESS: 1,
  MESSAGE: 2
};

/**
 * @return {proto.reconcilio.OnCreateReconciliationReportProgressResponse.ProgressOrMessageCase}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.getProgressOrMessageCase = function() {
  return /** @type {proto.reconcilio.OnCreateReconciliationReportProgressResponse.ProgressOrMessageCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.OnCreateReconciliationReportProgressResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.OnCreateReconciliationReportProgressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    progress: (f = msg.getProgress()) && proto.reconcilio.ProgressEvent.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.OnCreateReconciliationReportProgressResponse}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.OnCreateReconciliationReportProgressResponse;
  return proto.reconcilio.OnCreateReconciliationReportProgressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.OnCreateReconciliationReportProgressResponse}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.ProgressEvent;
      reader.readMessage(value,proto.reconcilio.ProgressEvent.deserializeBinaryFromReader);
      msg.setProgress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.OnCreateReconciliationReportProgressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.ProgressEvent.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ProgressEvent progress = 1;
 * @return {?proto.reconcilio.ProgressEvent}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.getProgress = function() {
  return /** @type{?proto.reconcilio.ProgressEvent} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ProgressEvent, 1));
};


/**
 * @param {?proto.reconcilio.ProgressEvent|undefined} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} returns this
*/
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.setProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.OnCreateReconciliationReportProgressResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.clearProgress = function() {
  return this.setProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.setMessage = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.reconcilio.OnCreateReconciliationReportProgressResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.OnCreateReconciliationReportProgressResponse} returns this
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.clearMessage = function() {
  return jspb.Message.setOneofField(this, 2, proto.reconcilio.OnCreateReconciliationReportProgressResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.OnCreateReconciliationReportProgressResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    financialTransactionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    transactionType: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest}
 */
proto.reconcilio.CreateLedgerTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionRequest;
  return proto.reconcilio.CreateLedgerTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest}
 */
proto.reconcilio.CreateLedgerTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancialTransactionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinancialTransactionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTransactionType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string financial_transaction_id = 2;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getFinancialTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setFinancialTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string reference = 5;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double amount = 6;
 * @return {number}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string transaction_type = 7;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.getTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionRequest.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateLedgerTransactionResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateLedgerTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateLedgerTransactionResponse.ResultCase}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateLedgerTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateLedgerTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateLedgerTransactionOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateLedgerTransactionErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionResponse}
 */
proto.reconcilio.CreateLedgerTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionResponse;
  return proto.reconcilio.CreateLedgerTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionResponse}
 */
proto.reconcilio.CreateLedgerTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateLedgerTransactionOKResponse;
      reader.readMessage(value,proto.reconcilio.CreateLedgerTransactionOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateLedgerTransactionErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateLedgerTransactionErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateLedgerTransactionOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateLedgerTransactionErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateLedgerTransactionOKResponse ok = 1;
 * @return {?proto.reconcilio.CreateLedgerTransactionOKResponse}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateLedgerTransactionOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateLedgerTransactionOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.CreateLedgerTransactionOKResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateLedgerTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateLedgerTransactionErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateLedgerTransactionErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateLedgerTransactionErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateLedgerTransactionErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateLedgerTransactionErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateLedgerTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionOKResponse}
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionOKResponse;
  return proto.reconcilio.CreateLedgerTransactionOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionOKResponse}
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRowId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 row_id = 1;
 * @return {number}
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.prototype.getRowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.CreateLedgerTransactionOKResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionOKResponse.prototype.setRowId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  INVALID_BALANCES_ERROR: 2,
  CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.CreateLedgerTransactionErrorResponse.ErrorCase}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.CreateLedgerTransactionErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidBalancesError: (f = msg.getInvalidBalancesError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotChangeLockedReconciliationReportError: (f = msg.getCannotChangeLockedReconciliationReportError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionErrorResponse;
  return proto.reconcilio.CreateLedgerTransactionErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidBalancesError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotChangeLockedReconciliationReportError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidBalancesError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotChangeLockedReconciliationReportError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse invalid_balances_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.getInvalidBalancesError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.setInvalidBalancesError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.clearInvalidBalancesError = function() {
  return this.setInvalidBalancesError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.hasInvalidBalancesError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse cannot_change_locked_reconciliation_report_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.getCannotChangeLockedReconciliationReportError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.setCannotChangeLockedReconciliationReportError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.clearCannotChangeLockedReconciliationReportError = function() {
  return this.setCannotChangeLockedReconciliationReportError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.hasCannotChangeLockedReconciliationReportError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.CreateLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditLedgerTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditLedgerTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    transactionType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    financialTransactionId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditLedgerTransactionRequest}
 */
proto.reconcilio.EditLedgerTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditLedgerTransactionRequest;
  return proto.reconcilio.EditLedgerTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditLedgerTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditLedgerTransactionRequest}
 */
proto.reconcilio.EditLedgerTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRowId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancialTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditLedgerTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditLedgerTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTransactionType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFinancialTransactionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 row_id = 2;
 * @return {number}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getRowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setRowId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string reference = 5;
 * @return {string}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double amount = 6;
 * @return {number}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string transaction_type = 7;
 * @return {string}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string financial_transaction_id = 8;
 * @return {string}
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.getFinancialTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditLedgerTransactionRequest} returns this
 */
proto.reconcilio.EditLedgerTransactionRequest.prototype.setFinancialTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditLedgerTransactionResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditLedgerTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditLedgerTransactionResponse.ResultCase}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditLedgerTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditLedgerTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditLedgerTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditLedgerTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditLedgerTransactionOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditLedgerTransactionErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditLedgerTransactionResponse}
 */
proto.reconcilio.EditLedgerTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditLedgerTransactionResponse;
  return proto.reconcilio.EditLedgerTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditLedgerTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditLedgerTransactionResponse}
 */
proto.reconcilio.EditLedgerTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditLedgerTransactionOKResponse;
      reader.readMessage(value,proto.reconcilio.EditLedgerTransactionOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditLedgerTransactionErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditLedgerTransactionErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditLedgerTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditLedgerTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditLedgerTransactionOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditLedgerTransactionErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditLedgerTransactionOKResponse ok = 1;
 * @return {?proto.reconcilio.EditLedgerTransactionOKResponse}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditLedgerTransactionOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditLedgerTransactionOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditLedgerTransactionOKResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditLedgerTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditLedgerTransactionErrorResponse error = 2;
 * @return {?proto.reconcilio.EditLedgerTransactionErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditLedgerTransactionErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditLedgerTransactionErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditLedgerTransactionErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditLedgerTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditLedgerTransactionOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditLedgerTransactionOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditLedgerTransactionOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditLedgerTransactionOKResponse}
 */
proto.reconcilio.EditLedgerTransactionOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditLedgerTransactionOKResponse;
  return proto.reconcilio.EditLedgerTransactionOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditLedgerTransactionOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditLedgerTransactionOKResponse}
 */
proto.reconcilio.EditLedgerTransactionOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRowId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditLedgerTransactionOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditLedgerTransactionOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditLedgerTransactionOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 row_id = 1;
 * @return {number}
 */
proto.reconcilio.EditLedgerTransactionOKResponse.prototype.getRowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.EditLedgerTransactionOKResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionOKResponse.prototype.setRowId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  INVALID_BALANCES_ERROR: 2,
  CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR: 3,
  CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR: 4,
  TRANSACTION_NOT_FOUND_ERROR: 5,
  UNEXPECTED_ERROR: 6
};

/**
 * @return {proto.reconcilio.EditLedgerTransactionErrorResponse.ErrorCase}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditLedgerTransactionErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditLedgerTransactionErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditLedgerTransactionErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidBalancesError: (f = msg.getInvalidBalancesError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotChangeLockedReconciliationReportError: (f = msg.getCannotChangeLockedReconciliationReportError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotModifyReconciledTransactionError: (f = msg.getCannotModifyReconciledTransactionError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    transactionNotFoundError: (f = msg.getTransactionNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditLedgerTransactionErrorResponse;
  return proto.reconcilio.EditLedgerTransactionErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditLedgerTransactionErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidBalancesError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotChangeLockedReconciliationReportError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotModifyReconciledTransactionError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setTransactionNotFoundError(value);
      break;
    case 6:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditLedgerTransactionErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditLedgerTransactionErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidBalancesError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotChangeLockedReconciliationReportError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotModifyReconciledTransactionError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTransactionNotFoundError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse invalid_balances_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getInvalidBalancesError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.setInvalidBalancesError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.clearInvalidBalancesError = function() {
  return this.setInvalidBalancesError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.hasInvalidBalancesError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse cannot_change_locked_reconciliation_report_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getCannotChangeLockedReconciliationReportError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.setCannotChangeLockedReconciliationReportError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.clearCannotChangeLockedReconciliationReportError = function() {
  return this.setCannotChangeLockedReconciliationReportError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.hasCannotChangeLockedReconciliationReportError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse cannot_modify_reconciled_transaction_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getCannotModifyReconciledTransactionError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.setCannotModifyReconciledTransactionError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.clearCannotModifyReconciledTransactionError = function() {
  return this.setCannotModifyReconciledTransactionError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.hasCannotModifyReconciledTransactionError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse transaction_not_found_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getTransactionNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.setTransactionNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.clearTransactionNotFoundError = function() {
  return this.setTransactionNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.hasTransactionNotFoundError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorResponse unexpected_error = 6;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 6));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.reconcilio.EditLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditLedgerTransactionErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteLedgerTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteLedgerTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteLedgerTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteLedgerTransactionRequest}
 */
proto.reconcilio.DeleteLedgerTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteLedgerTransactionRequest;
  return proto.reconcilio.DeleteLedgerTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteLedgerTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteLedgerTransactionRequest}
 */
proto.reconcilio.DeleteLedgerTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRowId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteLedgerTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteLedgerTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteLedgerTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteLedgerTransactionRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionRequest} returns this
 */
proto.reconcilio.DeleteLedgerTransactionRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 row_id = 2;
 * @return {number}
 */
proto.reconcilio.DeleteLedgerTransactionRequest.prototype.getRowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionRequest} returns this
 */
proto.reconcilio.DeleteLedgerTransactionRequest.prototype.setRowId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteLedgerTransactionResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteLedgerTransactionResponse.ResultCase}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteLedgerTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteLedgerTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteLedgerTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteLedgerTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteLedgerTransactionOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteLedgerTransactionErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteLedgerTransactionResponse}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteLedgerTransactionResponse;
  return proto.reconcilio.DeleteLedgerTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteLedgerTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteLedgerTransactionResponse}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteLedgerTransactionOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteLedgerTransactionOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteLedgerTransactionErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteLedgerTransactionErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteLedgerTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteLedgerTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteLedgerTransactionOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteLedgerTransactionErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteLedgerTransactionOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteLedgerTransactionOKResponse}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteLedgerTransactionOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteLedgerTransactionOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteLedgerTransactionOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteLedgerTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteLedgerTransactionErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteLedgerTransactionErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteLedgerTransactionErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteLedgerTransactionErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteLedgerTransactionErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteLedgerTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteLedgerTransactionOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteLedgerTransactionOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteLedgerTransactionOKResponse}
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteLedgerTransactionOKResponse;
  return proto.reconcilio.DeleteLedgerTransactionOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteLedgerTransactionOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteLedgerTransactionOKResponse}
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRowId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteLedgerTransactionOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteLedgerTransactionOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 row_id = 1;
 * @return {number}
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.prototype.getRowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionOKResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionOKResponse.prototype.setRowId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR: 2,
  CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR: 3,
  TRANSACTION_NOT_FOUND_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.DeleteLedgerTransactionErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteLedgerTransactionErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteLedgerTransactionErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotModifyReconciledTransactionError: (f = msg.getCannotModifyReconciledTransactionError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotChangeLockedReconciliationReportError: (f = msg.getCannotChangeLockedReconciliationReportError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    transactionNotFoundError: (f = msg.getTransactionNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteLedgerTransactionErrorResponse;
  return proto.reconcilio.DeleteLedgerTransactionErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotModifyReconciledTransactionError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotChangeLockedReconciliationReportError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setTransactionNotFoundError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteLedgerTransactionErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotModifyReconciledTransactionError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotChangeLockedReconciliationReportError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getTransactionNotFoundError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse cannot_modify_reconciled_transaction_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.getCannotModifyReconciledTransactionError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.setCannotModifyReconciledTransactionError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.clearCannotModifyReconciledTransactionError = function() {
  return this.setCannotModifyReconciledTransactionError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.hasCannotModifyReconciledTransactionError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse cannot_change_locked_reconciliation_report_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.getCannotChangeLockedReconciliationReportError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.setCannotChangeLockedReconciliationReportError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.clearCannotChangeLockedReconciliationReportError = function() {
  return this.setCannotChangeLockedReconciliationReportError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.hasCannotChangeLockedReconciliationReportError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse transaction_not_found_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.getTransactionNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.setTransactionNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.clearTransactionNotFoundError = function() {
  return this.setTransactionNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.hasTransactionNotFoundError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
*/
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.DeleteLedgerTransactionErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteLedgerTransactionErrorResponse} returns this
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteLedgerTransactionErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UnreconcileMatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UnreconcileMatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UnreconcileMatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    matchId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UnreconcileMatchRequest}
 */
proto.reconcilio.UnreconcileMatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UnreconcileMatchRequest;
  return proto.reconcilio.UnreconcileMatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UnreconcileMatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UnreconcileMatchRequest}
 */
proto.reconcilio.UnreconcileMatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UnreconcileMatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UnreconcileMatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UnreconcileMatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMatchId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.UnreconcileMatchRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UnreconcileMatchRequest} returns this
 */
proto.reconcilio.UnreconcileMatchRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string match_id = 2;
 * @return {string}
 */
proto.reconcilio.UnreconcileMatchRequest.prototype.getMatchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.UnreconcileMatchRequest} returns this
 */
proto.reconcilio.UnreconcileMatchRequest.prototype.setMatchId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.UnreconcileMatchResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.UnreconcileMatchResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.UnreconcileMatchResponse.ResultCase}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.UnreconcileMatchResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.UnreconcileMatchResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UnreconcileMatchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UnreconcileMatchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.UnreconcileMatchOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.UnreconcileMatchErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UnreconcileMatchResponse}
 */
proto.reconcilio.UnreconcileMatchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UnreconcileMatchResponse;
  return proto.reconcilio.UnreconcileMatchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UnreconcileMatchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UnreconcileMatchResponse}
 */
proto.reconcilio.UnreconcileMatchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.UnreconcileMatchOKResponse;
      reader.readMessage(value,proto.reconcilio.UnreconcileMatchOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.UnreconcileMatchErrorResponse;
      reader.readMessage(value,proto.reconcilio.UnreconcileMatchErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UnreconcileMatchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UnreconcileMatchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.UnreconcileMatchOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.UnreconcileMatchErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional UnreconcileMatchOKResponse ok = 1;
 * @return {?proto.reconcilio.UnreconcileMatchOKResponse}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.UnreconcileMatchOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.UnreconcileMatchOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.UnreconcileMatchOKResponse|undefined} value
 * @return {!proto.reconcilio.UnreconcileMatchResponse} returns this
*/
proto.reconcilio.UnreconcileMatchResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.UnreconcileMatchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UnreconcileMatchResponse} returns this
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UnreconcileMatchErrorResponse error = 2;
 * @return {?proto.reconcilio.UnreconcileMatchErrorResponse}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.UnreconcileMatchErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.UnreconcileMatchErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.UnreconcileMatchErrorResponse|undefined} value
 * @return {!proto.reconcilio.UnreconcileMatchResponse} returns this
*/
proto.reconcilio.UnreconcileMatchResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.UnreconcileMatchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UnreconcileMatchResponse} returns this
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UnreconcileMatchResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UnreconcileMatchOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UnreconcileMatchOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UnreconcileMatchOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UnreconcileMatchOKResponse}
 */
proto.reconcilio.UnreconcileMatchOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UnreconcileMatchOKResponse;
  return proto.reconcilio.UnreconcileMatchOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UnreconcileMatchOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UnreconcileMatchOKResponse}
 */
proto.reconcilio.UnreconcileMatchOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UnreconcileMatchOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UnreconcileMatchOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UnreconcileMatchOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  RECONCILIATION_REPORT_STATUS_LOCKED_ERROR: 2,
  MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.UnreconcileMatchErrorResponse.ErrorCase}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.UnreconcileMatchErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UnreconcileMatchErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UnreconcileMatchErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportStatusLockedError: (f = msg.getReconciliationReportStatusLockedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    matchDoesNotExistInReconciliationError: (f = msg.getMatchDoesNotExistInReconciliationError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UnreconcileMatchErrorResponse;
  return proto.reconcilio.UnreconcileMatchErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UnreconcileMatchErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportStatusLockedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setMatchDoesNotExistInReconciliationError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UnreconcileMatchErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UnreconcileMatchErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UnreconcileMatchErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportStatusLockedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getMatchDoesNotExistInReconciliationError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
*/
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse reconciliation_report_status_locked_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.getReconciliationReportStatusLockedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
*/
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.setReconciliationReportStatusLockedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.clearReconciliationReportStatusLockedError = function() {
  return this.setReconciliationReportStatusLockedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.hasReconciliationReportStatusLockedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse match_does_not_exist_in_reconciliation_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.getMatchDoesNotExistInReconciliationError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
*/
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.setMatchDoesNotExistInReconciliationError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.clearMatchDoesNotExistInReconciliationError = function() {
  return this.setMatchDoesNotExistInReconciliationError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.hasMatchDoesNotExistInReconciliationError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
*/
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.UnreconcileMatchErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UnreconcileMatchErrorResponse} returns this
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UnreconcileMatchErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetAdjustmentsReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetAdjustmentsReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetAdjustmentsReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetAdjustmentsReportRequest}
 */
proto.reconcilio.GetAdjustmentsReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetAdjustmentsReportRequest;
  return proto.reconcilio.GetAdjustmentsReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetAdjustmentsReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetAdjustmentsReportRequest}
 */
proto.reconcilio.GetAdjustmentsReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetAdjustmentsReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetAdjustmentsReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetAdjustmentsReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.GetAdjustmentsReportRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetAdjustmentsReportRequest} returns this
 */
proto.reconcilio.GetAdjustmentsReportRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetAdjustmentsReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetAdjustmentsReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetAdjustmentsReportResponse.ResultCase}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetAdjustmentsReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetAdjustmentsReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetAdjustmentsReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetAdjustmentsReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetAdjustmentsReportOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetAdjustmentsReportErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetAdjustmentsReportResponse}
 */
proto.reconcilio.GetAdjustmentsReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetAdjustmentsReportResponse;
  return proto.reconcilio.GetAdjustmentsReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetAdjustmentsReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetAdjustmentsReportResponse}
 */
proto.reconcilio.GetAdjustmentsReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetAdjustmentsReportOKResponse;
      reader.readMessage(value,proto.reconcilio.GetAdjustmentsReportOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetAdjustmentsReportErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetAdjustmentsReportErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetAdjustmentsReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetAdjustmentsReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetAdjustmentsReportOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetAdjustmentsReportErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAdjustmentsReportOKResponse ok = 1;
 * @return {?proto.reconcilio.GetAdjustmentsReportOKResponse}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetAdjustmentsReportOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetAdjustmentsReportOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetAdjustmentsReportOKResponse|undefined} value
 * @return {!proto.reconcilio.GetAdjustmentsReportResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetAdjustmentsReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetAdjustmentsReportResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetAdjustmentsReportErrorResponse error = 2;
 * @return {?proto.reconcilio.GetAdjustmentsReportErrorResponse}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetAdjustmentsReportErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetAdjustmentsReportErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetAdjustmentsReportErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetAdjustmentsReportResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetAdjustmentsReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetAdjustmentsReportResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetAdjustmentsReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.UpdatedTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.UpdatedTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.UpdatedTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UpdatedTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {
    originalTransaction: (f = msg.getOriginalTransaction()) && proto.reconcilio.FinancialTransaction.toObject(includeInstance, f),
    updatedTransaction: (f = msg.getUpdatedTransaction()) && proto.reconcilio.FinancialTransaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.UpdatedTransactions}
 */
proto.reconcilio.UpdatedTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.UpdatedTransactions;
  return proto.reconcilio.UpdatedTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.UpdatedTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.UpdatedTransactions}
 */
proto.reconcilio.UpdatedTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.setOriginalTransaction(value);
      break;
    case 2:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.setUpdatedTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.UpdatedTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.UpdatedTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.UpdatedTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.UpdatedTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginalTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTransaction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional FinancialTransaction original_transaction = 1;
 * @return {?proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.UpdatedTransactions.prototype.getOriginalTransaction = function() {
  return /** @type{?proto.reconcilio.FinancialTransaction} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.FinancialTransaction, 1));
};


/**
 * @param {?proto.reconcilio.FinancialTransaction|undefined} value
 * @return {!proto.reconcilio.UpdatedTransactions} returns this
*/
proto.reconcilio.UpdatedTransactions.prototype.setOriginalTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UpdatedTransactions} returns this
 */
proto.reconcilio.UpdatedTransactions.prototype.clearOriginalTransaction = function() {
  return this.setOriginalTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UpdatedTransactions.prototype.hasOriginalTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FinancialTransaction updated_transaction = 2;
 * @return {?proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.UpdatedTransactions.prototype.getUpdatedTransaction = function() {
  return /** @type{?proto.reconcilio.FinancialTransaction} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.FinancialTransaction, 2));
};


/**
 * @param {?proto.reconcilio.FinancialTransaction|undefined} value
 * @return {!proto.reconcilio.UpdatedTransactions} returns this
*/
proto.reconcilio.UpdatedTransactions.prototype.setUpdatedTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.UpdatedTransactions} returns this
 */
proto.reconcilio.UpdatedTransactions.prototype.clearUpdatedTransaction = function() {
  return this.setUpdatedTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.UpdatedTransactions.prototype.hasUpdatedTransaction = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetAdjustmentsReportOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetAdjustmentsReportOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdTransactionsList: jspb.Message.toObjectList(msg.getCreatedTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance),
    deletedTransactionsList: jspb.Message.toObjectList(msg.getDeletedTransactionsList(),
    proto.reconcilio.FinancialTransaction.toObject, includeInstance),
    updatedTransactionsList: jspb.Message.toObjectList(msg.getUpdatedTransactionsList(),
    proto.reconcilio.UpdatedTransactions.toObject, includeInstance),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetAdjustmentsReportOKResponse;
  return proto.reconcilio.GetAdjustmentsReportOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetAdjustmentsReportOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addCreatedTransactions(value);
      break;
    case 2:
      var value = new proto.reconcilio.FinancialTransaction;
      reader.readMessage(value,proto.reconcilio.FinancialTransaction.deserializeBinaryFromReader);
      msg.addDeletedTransactions(value);
      break;
    case 3:
      var value = new proto.reconcilio.UpdatedTransactions;
      reader.readMessage(value,proto.reconcilio.UpdatedTransactions.deserializeBinaryFromReader);
      msg.addUpdatedTransactions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetAdjustmentsReportOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetAdjustmentsReportOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
  f = message.getDeletedTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reconcilio.FinancialTransaction.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.UpdatedTransactions.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * repeated FinancialTransaction created_transactions = 1;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.getCreatedTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 1));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.setCreatedTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.addCreatedTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.clearCreatedTransactionsList = function() {
  return this.setCreatedTransactionsList([]);
};


/**
 * repeated FinancialTransaction deleted_transactions = 2;
 * @return {!Array<!proto.reconcilio.FinancialTransaction>}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.getDeletedTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.FinancialTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.FinancialTransaction, 2));
};


/**
 * @param {!Array<!proto.reconcilio.FinancialTransaction>} value
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.setDeletedTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reconcilio.FinancialTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.FinancialTransaction}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.addDeletedTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reconcilio.FinancialTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.clearDeletedTransactionsList = function() {
  return this.setDeletedTransactionsList([]);
};


/**
 * repeated UpdatedTransactions updated_transactions = 3;
 * @return {!Array<!proto.reconcilio.UpdatedTransactions>}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.getUpdatedTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.UpdatedTransactions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.UpdatedTransactions, 3));
};


/**
 * @param {!Array<!proto.reconcilio.UpdatedTransactions>} value
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.setUpdatedTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.UpdatedTransactions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.UpdatedTransactions}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.addUpdatedTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.UpdatedTransactions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.clearUpdatedTransactionsList = function() {
  return this.setUpdatedTransactionsList([]);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string reconciliation_report_id = 5;
 * @return {string}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 updated_at = 6;
 * @return {number}
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.GetAdjustmentsReportOKResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportOKResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  ADJUSTEMENTS_REPORT_NOT_FOUND_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetAdjustmentsReportErrorResponse.ErrorCase}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetAdjustmentsReportErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetAdjustmentsReportErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetAdjustmentsReportErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetAdjustmentsReportErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adjustementsReportNotFoundError: (f = msg.getAdjustementsReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetAdjustmentsReportErrorResponse}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetAdjustmentsReportErrorResponse;
  return proto.reconcilio.GetAdjustmentsReportErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetAdjustmentsReportErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetAdjustmentsReportErrorResponse}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setAdjustementsReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetAdjustmentsReportErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetAdjustmentsReportErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdjustementsReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse adjustements_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.getAdjustementsReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetAdjustmentsReportErrorResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.setAdjustementsReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetAdjustmentsReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetAdjustmentsReportErrorResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.clearAdjustementsReportNotFoundError = function() {
  return this.setAdjustementsReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.hasAdjustementsReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetAdjustmentsReportErrorResponse} returns this
*/
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetAdjustmentsReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetAdjustmentsReportErrorResponse} returns this
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetAdjustmentsReportErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    language: jspb.Message.getFieldWithDefault(msg, 3, ""),
    responsibleUserId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateWorkspaceRequest}
 */
proto.reconcilio.CreateWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateWorkspaceRequest;
  return proto.reconcilio.CreateWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateWorkspaceRequest}
 */
proto.reconcilio.CreateWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibleUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResponsibleUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateWorkspaceRequest} returns this
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateWorkspaceRequest} returns this
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateWorkspaceRequest} returns this
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string responsible_user_id = 4;
 * @return {string}
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.getResponsibleUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateWorkspaceRequest} returns this
 */
proto.reconcilio.CreateWorkspaceRequest.prototype.setResponsibleUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateWorkspaceResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateWorkspaceResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateWorkspaceResponse.ResultCase}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateWorkspaceResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateWorkspaceResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateWorkspaceOKRequest.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateWorkspaceErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateWorkspaceResponse}
 */
proto.reconcilio.CreateWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateWorkspaceResponse;
  return proto.reconcilio.CreateWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateWorkspaceResponse}
 */
proto.reconcilio.CreateWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateWorkspaceOKRequest;
      reader.readMessage(value,proto.reconcilio.CreateWorkspaceOKRequest.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateWorkspaceErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateWorkspaceErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateWorkspaceOKRequest.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateWorkspaceErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateWorkspaceOKRequest ok = 1;
 * @return {?proto.reconcilio.CreateWorkspaceOKRequest}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateWorkspaceOKRequest} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateWorkspaceOKRequest, 1));
};


/**
 * @param {?proto.reconcilio.CreateWorkspaceOKRequest|undefined} value
 * @return {!proto.reconcilio.CreateWorkspaceResponse} returns this
*/
proto.reconcilio.CreateWorkspaceResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateWorkspaceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateWorkspaceResponse} returns this
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateWorkspaceErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateWorkspaceErrorResponse}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateWorkspaceErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateWorkspaceErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateWorkspaceErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateWorkspaceResponse} returns this
*/
proto.reconcilio.CreateWorkspaceResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateWorkspaceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateWorkspaceResponse} returns this
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateWorkspaceResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateWorkspaceOKRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateWorkspaceOKRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateWorkspaceOKRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceOKRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateWorkspaceOKRequest}
 */
proto.reconcilio.CreateWorkspaceOKRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateWorkspaceOKRequest;
  return proto.reconcilio.CreateWorkspaceOKRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateWorkspaceOKRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateWorkspaceOKRequest}
 */
proto.reconcilio.CreateWorkspaceOKRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateWorkspaceOKRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateWorkspaceOKRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateWorkspaceOKRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceOKRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.CreateWorkspaceOKRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateWorkspaceOKRequest} returns this
 */
proto.reconcilio.CreateWorkspaceOKRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateWorkspaceErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateWorkspaceErrorResponse.ErrorCase}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.CreateWorkspaceErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateWorkspaceErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateWorkspaceErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateWorkspaceErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateWorkspaceErrorResponse}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateWorkspaceErrorResponse;
  return proto.reconcilio.CreateWorkspaceErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateWorkspaceErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateWorkspaceErrorResponse}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateWorkspaceErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateWorkspaceErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateWorkspaceErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateWorkspaceErrorResponse} returns this
*/
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateWorkspaceErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateWorkspaceErrorResponse} returns this
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateWorkspaceErrorResponse} returns this
*/
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateWorkspaceErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateWorkspaceErrorResponse} returns this
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateWorkspaceErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    responsibleUserId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateEntityRequest}
 */
proto.reconcilio.CreateEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateEntityRequest;
  return proto.reconcilio.CreateEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateEntityRequest}
 */
proto.reconcilio.CreateEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibleUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResponsibleUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateEntityRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityRequest} returns this
 */
proto.reconcilio.CreateEntityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.CreateEntityRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityRequest} returns this
 */
proto.reconcilio.CreateEntityRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string unique_identifier = 3;
 * @return {string}
 */
proto.reconcilio.CreateEntityRequest.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityRequest} returns this
 */
proto.reconcilio.CreateEntityRequest.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.reconcilio.CreateEntityRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityRequest} returns this
 */
proto.reconcilio.CreateEntityRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.reconcilio.CreateEntityRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityRequest} returns this
 */
proto.reconcilio.CreateEntityRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string responsible_user_id = 6;
 * @return {string}
 */
proto.reconcilio.CreateEntityRequest.prototype.getResponsibleUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityRequest} returns this
 */
proto.reconcilio.CreateEntityRequest.prototype.setResponsibleUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateEntityResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateEntityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateEntityResponse.ResultCase}
 */
proto.reconcilio.CreateEntityResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateEntityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateEntityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateEntityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateEntityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateEntityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateEntityOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateEntityErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateEntityResponse}
 */
proto.reconcilio.CreateEntityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateEntityResponse;
  return proto.reconcilio.CreateEntityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateEntityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateEntityResponse}
 */
proto.reconcilio.CreateEntityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateEntityOKResponse;
      reader.readMessage(value,proto.reconcilio.CreateEntityOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateEntityErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateEntityErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateEntityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateEntityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateEntityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateEntityOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateEntityErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateEntityOKResponse ok = 1;
 * @return {?proto.reconcilio.CreateEntityOKResponse}
 */
proto.reconcilio.CreateEntityResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateEntityOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateEntityOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.CreateEntityOKResponse|undefined} value
 * @return {!proto.reconcilio.CreateEntityResponse} returns this
*/
proto.reconcilio.CreateEntityResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateEntityResponse} returns this
 */
proto.reconcilio.CreateEntityResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateEntityResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateEntityErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateEntityErrorResponse}
 */
proto.reconcilio.CreateEntityResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateEntityErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateEntityErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateEntityErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateEntityResponse} returns this
*/
proto.reconcilio.CreateEntityResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateEntityResponse} returns this
 */
proto.reconcilio.CreateEntityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateEntityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateEntityOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateEntityOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateEntityOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateEntityOKResponse}
 */
proto.reconcilio.CreateEntityOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateEntityOKResponse;
  return proto.reconcilio.CreateEntityOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateEntityOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateEntityOKResponse}
 */
proto.reconcilio.CreateEntityOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateEntityOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateEntityOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateEntityOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.CreateEntityOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateEntityOKResponse} returns this
 */
proto.reconcilio.CreateEntityOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateEntityErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateEntityErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateEntityErrorResponse.ErrorCase}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.CreateEntityErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateEntityErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateEntityErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateEntityErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateEntityErrorResponse}
 */
proto.reconcilio.CreateEntityErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateEntityErrorResponse;
  return proto.reconcilio.CreateEntityErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateEntityErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateEntityErrorResponse}
 */
proto.reconcilio.CreateEntityErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateEntityErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateEntityErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateEntityErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateEntityErrorResponse} returns this
*/
proto.reconcilio.CreateEntityErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateEntityErrorResponse} returns this
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateEntityErrorResponse} returns this
*/
proto.reconcilio.CreateEntityErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateEntityErrorResponse} returns this
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateEntityErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reconciliationType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    counterpartyContactName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    counterpartyContactEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    responsibleUserId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest}
 */
proto.reconcilio.CreateReconciliationAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationAccountRequest;
  return proto.reconcilio.CreateReconciliationAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest}
 */
proto.reconcilio.CreateReconciliationAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterpartyContactName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterpartyContactEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibleUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReconciliationType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCounterpartyContactName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCounterpartyContactEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResponsibleUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reconciliation_type = 3;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getReconciliationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setReconciliationType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unique_identifier = 5;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string counterparty_contact_name = 6;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getCounterpartyContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setCounterpartyContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string counterparty_contact_email = 7;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getCounterpartyContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setCounterpartyContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string responsible_user_id = 8;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.getResponsibleUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountRequest} returns this
 */
proto.reconcilio.CreateReconciliationAccountRequest.prototype.setResponsibleUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateReconciliationAccountResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateReconciliationAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateReconciliationAccountResponse.ResultCase}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateReconciliationAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateReconciliationAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateReconciliationAccountOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateReconciliationAccountErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationAccountResponse}
 */
proto.reconcilio.CreateReconciliationAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationAccountResponse;
  return proto.reconcilio.CreateReconciliationAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationAccountResponse}
 */
proto.reconcilio.CreateReconciliationAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateReconciliationAccountOKResponse;
      reader.readMessage(value,proto.reconcilio.CreateReconciliationAccountOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateReconciliationAccountErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateReconciliationAccountErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateReconciliationAccountOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateReconciliationAccountErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateReconciliationAccountOKResponse ok = 1;
 * @return {?proto.reconcilio.CreateReconciliationAccountOKResponse}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateReconciliationAccountOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateReconciliationAccountOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.CreateReconciliationAccountOKResponse|undefined} value
 * @return {!proto.reconcilio.CreateReconciliationAccountResponse} returns this
*/
proto.reconcilio.CreateReconciliationAccountResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateReconciliationAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationAccountResponse} returns this
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateReconciliationAccountErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateReconciliationAccountErrorResponse}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateReconciliationAccountErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateReconciliationAccountErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateReconciliationAccountErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateReconciliationAccountResponse} returns this
*/
proto.reconcilio.CreateReconciliationAccountResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateReconciliationAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationAccountResponse} returns this
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationAccountResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationAccountOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationAccountOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationAccountOKResponse}
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationAccountOKResponse;
  return proto.reconcilio.CreateReconciliationAccountOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationAccountOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationAccountOKResponse}
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationAccountOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationAccountOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateReconciliationAccountOKResponse} returns this
 */
proto.reconcilio.CreateReconciliationAccountOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateReconciliationAccountErrorResponse.ErrorCase}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.CreateReconciliationAccountErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateReconciliationAccountErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateReconciliationAccountErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateReconciliationAccountErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateReconciliationAccountErrorResponse}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateReconciliationAccountErrorResponse;
  return proto.reconcilio.CreateReconciliationAccountErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateReconciliationAccountErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateReconciliationAccountErrorResponse}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateReconciliationAccountErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateReconciliationAccountErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateReconciliationAccountErrorResponse} returns this
*/
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateReconciliationAccountErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationAccountErrorResponse} returns this
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateReconciliationAccountErrorResponse} returns this
*/
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateReconciliationAccountErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateReconciliationAccountErrorResponse} returns this
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateReconciliationAccountErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspacesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspacesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspacesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspacesRequest}
 */
proto.reconcilio.GetWorkspacesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspacesRequest;
  return proto.reconcilio.GetWorkspacesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspacesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspacesRequest}
 */
proto.reconcilio.GetWorkspacesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspacesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspacesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspacesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.WorkspaceEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.WorkspaceEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.WorkspaceEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.WorkspaceEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.WorkspaceEntity}
 */
proto.reconcilio.WorkspaceEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.WorkspaceEntity;
  return proto.reconcilio.WorkspaceEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.WorkspaceEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.WorkspaceEntity}
 */
proto.reconcilio.WorkspaceEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.WorkspaceEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.WorkspaceEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.WorkspaceEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.WorkspaceEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.WorkspaceEntity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.WorkspaceEntity} returns this
 */
proto.reconcilio.WorkspaceEntity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.WorkspaceEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.WorkspaceEntity} returns this
 */
proto.reconcilio.WorkspaceEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.User.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roleName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.User}
 */
proto.reconcilio.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.User;
  return proto.reconcilio.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.User}
 */
proto.reconcilio.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.reconcilio.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.User} returns this
 */
proto.reconcilio.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_id = 2;
 * @return {string}
 */
proto.reconcilio.User.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.User} returns this
 */
proto.reconcilio.User.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string role_name = 3;
 * @return {string}
 */
proto.reconcilio.User.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.User} returns this
 */
proto.reconcilio.User.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.Workspace.repeatedFields_ = [5,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.Workspace.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.Workspace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.Workspace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Workspace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    language: jspb.Message.getFieldWithDefault(msg, 4, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.reconcilio.User.toObject, includeInstance),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    entitiesList: jspb.Message.toObjectList(msg.getEntitiesList(),
    proto.reconcilio.WorkspaceEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.Workspace}
 */
proto.reconcilio.Workspace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.Workspace;
  return proto.reconcilio.Workspace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.Workspace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.Workspace}
 */
proto.reconcilio.Workspace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 5:
      var value = new proto.reconcilio.User;
      reader.readMessage(value,proto.reconcilio.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = new proto.reconcilio.WorkspaceEntity;
      reader.readMessage(value,proto.reconcilio.WorkspaceEntity.deserializeBinaryFromReader);
      msg.addEntities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.Workspace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.Workspace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.Workspace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Workspace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.reconcilio.User.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.reconcilio.WorkspaceEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.Workspace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.Workspace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.reconcilio.Workspace.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string language = 4;
 * @return {string}
 */
proto.reconcilio.Workspace.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated User users = 5;
 * @return {!Array<!proto.reconcilio.User>}
 */
proto.reconcilio.Workspace.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.reconcilio.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.User, 5));
};


/**
 * @param {!Array<!proto.reconcilio.User>} value
 * @return {!proto.reconcilio.Workspace} returns this
*/
proto.reconcilio.Workspace.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.reconcilio.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.User}
 */
proto.reconcilio.Workspace.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.reconcilio.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.reconcilio.Workspace.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.reconcilio.Workspace.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated WorkspaceEntity entities = 8;
 * @return {!Array<!proto.reconcilio.WorkspaceEntity>}
 */
proto.reconcilio.Workspace.prototype.getEntitiesList = function() {
  return /** @type{!Array<!proto.reconcilio.WorkspaceEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.WorkspaceEntity, 8));
};


/**
 * @param {!Array<!proto.reconcilio.WorkspaceEntity>} value
 * @return {!proto.reconcilio.Workspace} returns this
*/
proto.reconcilio.Workspace.prototype.setEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.reconcilio.WorkspaceEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.WorkspaceEntity}
 */
proto.reconcilio.Workspace.prototype.addEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.reconcilio.WorkspaceEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Workspace} returns this
 */
proto.reconcilio.Workspace.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetWorkspacesResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetWorkspacesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetWorkspacesResponse.ResultCase}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetWorkspacesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetWorkspacesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspacesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspacesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetWorkspacesOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetWorkspacesErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspacesResponse}
 */
proto.reconcilio.GetWorkspacesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspacesResponse;
  return proto.reconcilio.GetWorkspacesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspacesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspacesResponse}
 */
proto.reconcilio.GetWorkspacesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetWorkspacesOKResponse;
      reader.readMessage(value,proto.reconcilio.GetWorkspacesOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetWorkspacesErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetWorkspacesErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspacesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspacesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetWorkspacesOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetWorkspacesErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetWorkspacesOKResponse ok = 1;
 * @return {?proto.reconcilio.GetWorkspacesOKResponse}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetWorkspacesOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetWorkspacesOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetWorkspacesOKResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspacesResponse} returns this
*/
proto.reconcilio.GetWorkspacesResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetWorkspacesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspacesResponse} returns this
 */
proto.reconcilio.GetWorkspacesResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetWorkspacesErrorResponse error = 2;
 * @return {?proto.reconcilio.GetWorkspacesErrorResponse}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetWorkspacesErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetWorkspacesErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetWorkspacesErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspacesResponse} returns this
*/
proto.reconcilio.GetWorkspacesResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetWorkspacesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspacesResponse} returns this
 */
proto.reconcilio.GetWorkspacesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspacesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.GetWorkspacesOKResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspacesOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspacesOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspacesOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspacesList: jspb.Message.toObjectList(msg.getWorkspacesList(),
    proto.reconcilio.Workspace.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspacesOKResponse}
 */
proto.reconcilio.GetWorkspacesOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspacesOKResponse;
  return proto.reconcilio.GetWorkspacesOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspacesOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspacesOKResponse}
 */
proto.reconcilio.GetWorkspacesOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.Workspace;
      reader.readMessage(value,proto.reconcilio.Workspace.deserializeBinaryFromReader);
      msg.addWorkspaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspacesOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspacesOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspacesOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconcilio.Workspace.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Workspace workspaces = 1;
 * @return {!Array<!proto.reconcilio.Workspace>}
 */
proto.reconcilio.GetWorkspacesOKResponse.prototype.getWorkspacesList = function() {
  return /** @type{!Array<!proto.reconcilio.Workspace>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Workspace, 1));
};


/**
 * @param {!Array<!proto.reconcilio.Workspace>} value
 * @return {!proto.reconcilio.GetWorkspacesOKResponse} returns this
*/
proto.reconcilio.GetWorkspacesOKResponse.prototype.setWorkspacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconcilio.Workspace=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Workspace}
 */
proto.reconcilio.GetWorkspacesOKResponse.prototype.addWorkspaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconcilio.Workspace, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.GetWorkspacesOKResponse} returns this
 */
proto.reconcilio.GetWorkspacesOKResponse.prototype.clearWorkspacesList = function() {
  return this.setWorkspacesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetWorkspacesErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetWorkspacesErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetWorkspacesErrorResponse.ErrorCase}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetWorkspacesErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetWorkspacesErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspacesErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspacesErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspacesErrorResponse}
 */
proto.reconcilio.GetWorkspacesErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspacesErrorResponse;
  return proto.reconcilio.GetWorkspacesErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspacesErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspacesErrorResponse}
 */
proto.reconcilio.GetWorkspacesErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspacesErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspacesErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspacesErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspacesErrorResponse} returns this
*/
proto.reconcilio.GetWorkspacesErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetWorkspacesErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspacesErrorResponse} returns this
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspacesErrorResponse} returns this
*/
proto.reconcilio.GetWorkspacesErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetWorkspacesErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspacesErrorResponse} returns this
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspacesErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceByIdRequest}
 */
proto.reconcilio.GetWorkspaceByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceByIdRequest;
  return proto.reconcilio.GetWorkspaceByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceByIdRequest}
 */
proto.reconcilio.GetWorkspaceByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.GetWorkspaceByIdRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetWorkspaceByIdRequest} returns this
 */
proto.reconcilio.GetWorkspaceByIdRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetWorkspaceByIdResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetWorkspaceByIdResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetWorkspaceByIdResponse.ResultCase}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetWorkspaceByIdResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetWorkspaceByIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetWorkspaceByIdOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetWorkspaceByIdErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceByIdResponse}
 */
proto.reconcilio.GetWorkspaceByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceByIdResponse;
  return proto.reconcilio.GetWorkspaceByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceByIdResponse}
 */
proto.reconcilio.GetWorkspaceByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetWorkspaceByIdOKResponse;
      reader.readMessage(value,proto.reconcilio.GetWorkspaceByIdOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetWorkspaceByIdErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetWorkspaceByIdErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetWorkspaceByIdOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetWorkspaceByIdErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetWorkspaceByIdOKResponse ok = 1;
 * @return {?proto.reconcilio.GetWorkspaceByIdOKResponse}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetWorkspaceByIdOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetWorkspaceByIdOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetWorkspaceByIdOKResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceByIdResponse} returns this
*/
proto.reconcilio.GetWorkspaceByIdResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetWorkspaceByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceByIdResponse} returns this
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetWorkspaceByIdErrorResponse error = 2;
 * @return {?proto.reconcilio.GetWorkspaceByIdErrorResponse}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetWorkspaceByIdErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetWorkspaceByIdErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetWorkspaceByIdErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceByIdResponse} returns this
*/
proto.reconcilio.GetWorkspaceByIdResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetWorkspaceByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceByIdResponse} returns this
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceByIdResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceByIdOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceByIdOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspace: (f = msg.getWorkspace()) && proto.reconcilio.Workspace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceByIdOKResponse}
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceByIdOKResponse;
  return proto.reconcilio.GetWorkspaceByIdOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceByIdOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceByIdOKResponse}
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.Workspace;
      reader.readMessage(value,proto.reconcilio.Workspace.deserializeBinaryFromReader);
      msg.setWorkspace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceByIdOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceByIdOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.Workspace.serializeBinaryToWriter
    );
  }
};


/**
 * optional Workspace workspace = 1;
 * @return {?proto.reconcilio.Workspace}
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.prototype.getWorkspace = function() {
  return /** @type{?proto.reconcilio.Workspace} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.Workspace, 1));
};


/**
 * @param {?proto.reconcilio.Workspace|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceByIdOKResponse} returns this
*/
proto.reconcilio.GetWorkspaceByIdOKResponse.prototype.setWorkspace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceByIdOKResponse} returns this
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.prototype.clearWorkspace = function() {
  return this.setWorkspace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceByIdOKResponse.prototype.hasWorkspace = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetWorkspaceByIdErrorResponse.ErrorCase}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetWorkspaceByIdErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetWorkspaceByIdErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceByIdErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceByIdErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceByIdErrorResponse}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceByIdErrorResponse;
  return proto.reconcilio.GetWorkspaceByIdErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceByIdErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceByIdErrorResponse}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceByIdErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceByIdErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceByIdErrorResponse} returns this
*/
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetWorkspaceByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceByIdErrorResponse} returns this
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceByIdErrorResponse} returns this
*/
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetWorkspaceByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceByIdErrorResponse} returns this
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceByIdErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EntityReconciliationAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EntityReconciliationAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EntityReconciliationAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EntityReconciliationAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reconciliationType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EntityReconciliationAccount}
 */
proto.reconcilio.EntityReconciliationAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EntityReconciliationAccount;
  return proto.reconcilio.EntityReconciliationAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EntityReconciliationAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EntityReconciliationAccount}
 */
proto.reconcilio.EntityReconciliationAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EntityReconciliationAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EntityReconciliationAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EntityReconciliationAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EntityReconciliationAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReconciliationType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.EntityReconciliationAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EntityReconciliationAccount} returns this
 */
proto.reconcilio.EntityReconciliationAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.EntityReconciliationAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EntityReconciliationAccount} returns this
 */
proto.reconcilio.EntityReconciliationAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reconciliation_type = 3;
 * @return {string}
 */
proto.reconcilio.EntityReconciliationAccount.prototype.getReconciliationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EntityReconciliationAccount} returns this
 */
proto.reconcilio.EntityReconciliationAccount.prototype.setReconciliationType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.Entity.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.Entity.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.Entity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.Entity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Entity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    language: jspb.Message.getFieldWithDefault(msg, 5, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.reconcilio.User.toObject, includeInstance),
    reconciliationAccountsList: jspb.Message.toObjectList(msg.getReconciliationAccountsList(),
    proto.reconcilio.EntityReconciliationAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.Entity}
 */
proto.reconcilio.Entity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.Entity;
  return proto.reconcilio.Entity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.Entity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.Entity}
 */
proto.reconcilio.Entity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUpdatedAt(value);
      break;
    case 9:
      var value = new proto.reconcilio.User;
      reader.readMessage(value,proto.reconcilio.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 10:
      var value = new proto.reconcilio.EntityReconciliationAccount;
      reader.readMessage(value,proto.reconcilio.EntityReconciliationAccount.deserializeBinaryFromReader);
      msg.addReconciliationAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.Entity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.Entity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.Entity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Entity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.reconcilio.User.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.reconcilio.EntityReconciliationAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.Entity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.Entity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string unique_identifier = 3;
 * @return {string}
 */
proto.reconcilio.Entity.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.reconcilio.Entity.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string language = 5;
 * @return {string}
 */
proto.reconcilio.Entity.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string owner = 6;
 * @return {string}
 */
proto.reconcilio.Entity.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 created_at = 7;
 * @return {number}
 */
proto.reconcilio.Entity.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 updated_at = 8;
 * @return {number}
 */
proto.reconcilio.Entity.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated User users = 9;
 * @return {!Array<!proto.reconcilio.User>}
 */
proto.reconcilio.Entity.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.reconcilio.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.User, 9));
};


/**
 * @param {!Array<!proto.reconcilio.User>} value
 * @return {!proto.reconcilio.Entity} returns this
*/
proto.reconcilio.Entity.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.reconcilio.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.User}
 */
proto.reconcilio.Entity.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.reconcilio.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated EntityReconciliationAccount reconciliation_accounts = 10;
 * @return {!Array<!proto.reconcilio.EntityReconciliationAccount>}
 */
proto.reconcilio.Entity.prototype.getReconciliationAccountsList = function() {
  return /** @type{!Array<!proto.reconcilio.EntityReconciliationAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.EntityReconciliationAccount, 10));
};


/**
 * @param {!Array<!proto.reconcilio.EntityReconciliationAccount>} value
 * @return {!proto.reconcilio.Entity} returns this
*/
proto.reconcilio.Entity.prototype.setReconciliationAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.reconcilio.EntityReconciliationAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.EntityReconciliationAccount}
 */
proto.reconcilio.Entity.prototype.addReconciliationAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.reconcilio.EntityReconciliationAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Entity} returns this
 */
proto.reconcilio.Entity.prototype.clearReconciliationAccountsList = function() {
  return this.setReconciliationAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntityByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntityByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntityByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntityByIdRequest}
 */
proto.reconcilio.GetEntityByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntityByIdRequest;
  return proto.reconcilio.GetEntityByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntityByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntityByIdRequest}
 */
proto.reconcilio.GetEntityByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntityByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntityByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntityByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.reconcilio.GetEntityByIdRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetEntityByIdRequest} returns this
 */
proto.reconcilio.GetEntityByIdRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.GetEntityByIdRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetEntityByIdRequest} returns this
 */
proto.reconcilio.GetEntityByIdRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetEntityByIdResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetEntityByIdResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetEntityByIdResponse.ResultCase}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetEntityByIdResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetEntityByIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntityByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntityByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetEntityByIdOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetEntityByIdErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntityByIdResponse}
 */
proto.reconcilio.GetEntityByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntityByIdResponse;
  return proto.reconcilio.GetEntityByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntityByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntityByIdResponse}
 */
proto.reconcilio.GetEntityByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetEntityByIdOKResponse;
      reader.readMessage(value,proto.reconcilio.GetEntityByIdOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetEntityByIdErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetEntityByIdErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntityByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntityByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetEntityByIdOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetEntityByIdErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetEntityByIdOKResponse ok = 1;
 * @return {?proto.reconcilio.GetEntityByIdOKResponse}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetEntityByIdOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetEntityByIdOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetEntityByIdOKResponse|undefined} value
 * @return {!proto.reconcilio.GetEntityByIdResponse} returns this
*/
proto.reconcilio.GetEntityByIdResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetEntityByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntityByIdResponse} returns this
 */
proto.reconcilio.GetEntityByIdResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetEntityByIdErrorResponse error = 2;
 * @return {?proto.reconcilio.GetEntityByIdErrorResponse}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetEntityByIdErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetEntityByIdErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetEntityByIdErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntityByIdResponse} returns this
*/
proto.reconcilio.GetEntityByIdResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetEntityByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntityByIdResponse} returns this
 */
proto.reconcilio.GetEntityByIdResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntityByIdResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntityByIdOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntityByIdOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntityByIdOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: (f = msg.getEntity()) && proto.reconcilio.Entity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntityByIdOKResponse}
 */
proto.reconcilio.GetEntityByIdOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntityByIdOKResponse;
  return proto.reconcilio.GetEntityByIdOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntityByIdOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntityByIdOKResponse}
 */
proto.reconcilio.GetEntityByIdOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.Entity;
      reader.readMessage(value,proto.reconcilio.Entity.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntityByIdOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntityByIdOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntityByIdOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.Entity.serializeBinaryToWriter
    );
  }
};


/**
 * optional Entity entity = 1;
 * @return {?proto.reconcilio.Entity}
 */
proto.reconcilio.GetEntityByIdOKResponse.prototype.getEntity = function() {
  return /** @type{?proto.reconcilio.Entity} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.Entity, 1));
};


/**
 * @param {?proto.reconcilio.Entity|undefined} value
 * @return {!proto.reconcilio.GetEntityByIdOKResponse} returns this
*/
proto.reconcilio.GetEntityByIdOKResponse.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntityByIdOKResponse} returns this
 */
proto.reconcilio.GetEntityByIdOKResponse.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntityByIdOKResponse.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetEntityByIdErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetEntityByIdErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetEntityByIdErrorResponse.ErrorCase}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetEntityByIdErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetEntityByIdErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntityByIdErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntityByIdErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntityByIdErrorResponse}
 */
proto.reconcilio.GetEntityByIdErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntityByIdErrorResponse;
  return proto.reconcilio.GetEntityByIdErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntityByIdErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntityByIdErrorResponse}
 */
proto.reconcilio.GetEntityByIdErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntityByIdErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntityByIdErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntityByIdErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntityByIdErrorResponse} returns this
*/
proto.reconcilio.GetEntityByIdErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetEntityByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntityByIdErrorResponse} returns this
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntityByIdErrorResponse} returns this
*/
proto.reconcilio.GetEntityByIdErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetEntityByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntityByIdErrorResponse} returns this
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntityByIdErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.ReconciliationAccount.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.ReconciliationAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.ReconciliationAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.ReconciliationAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ReconciliationAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reconciliationType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    counterpartyContactName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    counterpartyContactEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.reconcilio.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.ReconciliationAccount}
 */
proto.reconcilio.ReconciliationAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.ReconciliationAccount;
  return proto.reconcilio.ReconciliationAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.ReconciliationAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.ReconciliationAccount}
 */
proto.reconcilio.ReconciliationAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterpartyContactName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterpartyContactEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = new proto.reconcilio.User;
      reader.readMessage(value,proto.reconcilio.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.ReconciliationAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.ReconciliationAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.ReconciliationAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.ReconciliationAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReconciliationType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCounterpartyContactName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCounterpartyContactEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.reconcilio.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reconciliation_type = 2;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getReconciliationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setReconciliationType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unique_identifier = 4;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string counterparty_contact_name = 5;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getCounterpartyContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setCounterpartyContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string counterparty_contact_email = 6;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getCounterpartyContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setCounterpartyContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string owner = 7;
 * @return {string}
 */
proto.reconcilio.ReconciliationAccount.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.reconcilio.ReconciliationAccount.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.reconcilio.ReconciliationAccount.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated User users = 10;
 * @return {!Array<!proto.reconcilio.User>}
 */
proto.reconcilio.ReconciliationAccount.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.reconcilio.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.User, 10));
};


/**
 * @param {!Array<!proto.reconcilio.User>} value
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
*/
proto.reconcilio.ReconciliationAccount.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.reconcilio.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.User}
 */
proto.reconcilio.ReconciliationAccount.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.reconcilio.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.ReconciliationAccount} returns this
 */
proto.reconcilio.ReconciliationAccount.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdRequest}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountByIdRequest;
  return proto.reconcilio.GetReconciliationAccountByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdRequest}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string reconciliation_account_id = 1;
 * @return {string}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.getReconciliationAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdRequest} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.setReconciliationAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdRequest} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdRequest} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetReconciliationAccountByIdResponse.ResultCase}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetReconciliationAccountByIdResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetReconciliationAccountByIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetReconciliationAccountByIdOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetReconciliationAccountByIdErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountByIdResponse;
  return proto.reconcilio.GetReconciliationAccountByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetReconciliationAccountByIdOKResponse;
      reader.readMessage(value,proto.reconcilio.GetReconciliationAccountByIdOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetReconciliationAccountByIdErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetReconciliationAccountByIdErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetReconciliationAccountByIdOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetReconciliationAccountByIdErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetReconciliationAccountByIdOKResponse ok = 1;
 * @return {?proto.reconcilio.GetReconciliationAccountByIdOKResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetReconciliationAccountByIdOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetReconciliationAccountByIdOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetReconciliationAccountByIdOKResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetReconciliationAccountByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetReconciliationAccountByIdErrorResponse error = 2;
 * @return {?proto.reconcilio.GetReconciliationAccountByIdErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetReconciliationAccountByIdErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetReconciliationAccountByIdErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetReconciliationAccountByIdErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetReconciliationAccountByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountByIdResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountByIdOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountByIdOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationAccount: (f = msg.getReconciliationAccount()) && proto.reconcilio.ReconciliationAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdOKResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountByIdOKResponse;
  return proto.reconcilio.GetReconciliationAccountByIdOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdOKResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.ReconciliationAccount;
      reader.readMessage(value,proto.reconcilio.ReconciliationAccount.deserializeBinaryFromReader);
      msg.setReconciliationAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountByIdOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.ReconciliationAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReconciliationAccount reconciliation_account = 1;
 * @return {?proto.reconcilio.ReconciliationAccount}
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.prototype.getReconciliationAccount = function() {
  return /** @type{?proto.reconcilio.ReconciliationAccount} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.ReconciliationAccount, 1));
};


/**
 * @param {?proto.reconcilio.ReconciliationAccount|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdOKResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountByIdOKResponse.prototype.setReconciliationAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdOKResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.prototype.clearReconciliationAccount = function() {
  return this.setReconciliationAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountByIdOKResponse.prototype.hasReconciliationAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetReconciliationAccountByIdErrorResponse.ErrorCase}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetReconciliationAccountByIdErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetReconciliationAccountByIdErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetReconciliationAccountByIdErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetReconciliationAccountByIdErrorResponse;
  return proto.reconcilio.GetReconciliationAccountByIdErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetReconciliationAccountByIdErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetReconciliationAccountByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} returns this
*/
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetReconciliationAccountByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetReconciliationAccountByIdErrorResponse} returns this
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetReconciliationAccountByIdErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    language: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceProfileRequest}
 */
proto.reconcilio.EditWorkspaceProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceProfileRequest;
  return proto.reconcilio.EditWorkspaceProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceProfileRequest}
 */
proto.reconcilio.EditWorkspaceProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceProfileRequest} returns this
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceProfileRequest} returns this
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceProfileRequest} returns this
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string language = 4;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceProfileRequest} returns this
 */
proto.reconcilio.EditWorkspaceProfileRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceProfileResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceProfileResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditWorkspaceProfileResponse.ResultCase}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceProfileResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceProfileResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditWorkspaceProfileOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditWorkspaceProfileErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceProfileResponse}
 */
proto.reconcilio.EditWorkspaceProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceProfileResponse;
  return proto.reconcilio.EditWorkspaceProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceProfileResponse}
 */
proto.reconcilio.EditWorkspaceProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditWorkspaceProfileOKResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceProfileOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditWorkspaceProfileErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceProfileErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditWorkspaceProfileOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditWorkspaceProfileErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditWorkspaceProfileOKResponse ok = 1;
 * @return {?proto.reconcilio.EditWorkspaceProfileOKResponse}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceProfileOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceProfileOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceProfileOKResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceProfileResponse} returns this
*/
proto.reconcilio.EditWorkspaceProfileResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceProfileResponse} returns this
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditWorkspaceProfileErrorResponse error = 2;
 * @return {?proto.reconcilio.EditWorkspaceProfileErrorResponse}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceProfileErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceProfileErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceProfileErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceProfileResponse} returns this
*/
proto.reconcilio.EditWorkspaceProfileResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceProfileResponse} returns this
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceProfileResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceProfileOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceProfileOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceProfileOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceProfileOKResponse}
 */
proto.reconcilio.EditWorkspaceProfileOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceProfileOKResponse;
  return proto.reconcilio.EditWorkspaceProfileOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceProfileOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceProfileOKResponse}
 */
proto.reconcilio.EditWorkspaceProfileOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceProfileOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceProfileOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceProfileOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.EditWorkspaceProfileErrorResponse.ErrorCase}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceProfileErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceProfileErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceProfileErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceProfileErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceProfileErrorResponse}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceProfileErrorResponse;
  return proto.reconcilio.EditWorkspaceProfileErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceProfileErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceProfileErrorResponse}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceProfileErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceProfileErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceProfileErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceProfileErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceProfileErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceProfileErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceProfileErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceProfileErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceProfileErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    language: jspb.Message.getFieldWithDefault(msg, 6, ""),
    responsibleUserId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityProfileRequest}
 */
proto.reconcilio.EditEntityProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityProfileRequest;
  return proto.reconcilio.EditEntityProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityProfileRequest}
 */
proto.reconcilio.EditEntityProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibleUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResponsibleUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string unique_identifier = 4;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string language = 6;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string responsible_user_id = 7;
 * @return {string}
 */
proto.reconcilio.EditEntityProfileRequest.prototype.getResponsibleUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityProfileRequest} returns this
 */
proto.reconcilio.EditEntityProfileRequest.prototype.setResponsibleUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityProfileResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityProfileResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditEntityProfileResponse.ResultCase}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditEntityProfileResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityProfileResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditEntityProfileOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditEntityProfileErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityProfileResponse}
 */
proto.reconcilio.EditEntityProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityProfileResponse;
  return proto.reconcilio.EditEntityProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityProfileResponse}
 */
proto.reconcilio.EditEntityProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditEntityProfileOKResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityProfileOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditEntityProfileErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityProfileErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditEntityProfileOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditEntityProfileErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditEntityProfileOKResponse ok = 1;
 * @return {?proto.reconcilio.EditEntityProfileOKResponse}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditEntityProfileOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityProfileOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditEntityProfileOKResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityProfileResponse} returns this
*/
proto.reconcilio.EditEntityProfileResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityProfileResponse} returns this
 */
proto.reconcilio.EditEntityProfileResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditEntityProfileErrorResponse error = 2;
 * @return {?proto.reconcilio.EditEntityProfileErrorResponse}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditEntityProfileErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityProfileErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditEntityProfileErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityProfileResponse} returns this
*/
proto.reconcilio.EditEntityProfileResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityProfileResponse} returns this
 */
proto.reconcilio.EditEntityProfileResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityProfileResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityProfileOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityProfileOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityProfileOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityProfileOKResponse}
 */
proto.reconcilio.EditEntityProfileOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityProfileOKResponse;
  return proto.reconcilio.EditEntityProfileOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityProfileOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityProfileOKResponse}
 */
proto.reconcilio.EditEntityProfileOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityProfileOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityProfileOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityProfileOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityProfileErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityProfileErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.EditEntityProfileErrorResponse.ErrorCase}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditEntityProfileErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityProfileErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityProfileErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityProfileErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityProfileErrorResponse}
 */
proto.reconcilio.EditEntityProfileErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityProfileErrorResponse;
  return proto.reconcilio.EditEntityProfileErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityProfileErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityProfileErrorResponse}
 */
proto.reconcilio.EditEntityProfileErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityProfileErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityProfileErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityProfileErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityProfileErrorResponse} returns this
*/
proto.reconcilio.EditEntityProfileErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityProfileErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityProfileErrorResponse} returns this
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityProfileErrorResponse} returns this
*/
proto.reconcilio.EditEntityProfileErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityProfileErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityProfileErrorResponse} returns this
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityProfileErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationAccountProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationAccountProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    counterpartyContactName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    counterpartyContactEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    responsibleUserId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationAccountProfileRequest;
  return proto.reconcilio.EditReconciliationAccountProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterpartyContactName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounterpartyContactEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibleUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationAccountProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCounterpartyContactName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCounterpartyContactEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResponsibleUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string reconciliation_account_id = 1;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getReconciliationAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setReconciliationAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unique_identifier = 5;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string counterparty_contact_name = 6;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getCounterpartyContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setCounterpartyContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string counterparty_contact_email = 7;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getCounterpartyContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setCounterpartyContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string responsible_user_id = 8;
 * @return {string}
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.getResponsibleUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileRequest} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileRequest.prototype.setResponsibleUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditReconciliationAccountProfileResponse.ResultCase}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditReconciliationAccountProfileResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditReconciliationAccountProfileResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationAccountProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationAccountProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditReconciliationAccountProfileOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditReconciliationAccountProfileErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationAccountProfileResponse;
  return proto.reconcilio.EditReconciliationAccountProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditReconciliationAccountProfileOKResponse;
      reader.readMessage(value,proto.reconcilio.EditReconciliationAccountProfileOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditReconciliationAccountProfileErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditReconciliationAccountProfileErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationAccountProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditReconciliationAccountProfileOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditReconciliationAccountProfileErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditReconciliationAccountProfileOKResponse ok = 1;
 * @return {?proto.reconcilio.EditReconciliationAccountProfileOKResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditReconciliationAccountProfileOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditReconciliationAccountProfileOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditReconciliationAccountProfileOKResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileResponse} returns this
*/
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditReconciliationAccountProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileResponse} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditReconciliationAccountProfileErrorResponse error = 2;
 * @return {?proto.reconcilio.EditReconciliationAccountProfileErrorResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditReconciliationAccountProfileErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditReconciliationAccountProfileErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditReconciliationAccountProfileErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileResponse} returns this
*/
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditReconciliationAccountProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileResponse} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationAccountProfileResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationAccountProfileOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationAccountProfileOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileOKResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationAccountProfileOKResponse;
  return proto.reconcilio.EditReconciliationAccountProfileOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileOKResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationAccountProfileOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.EditReconciliationAccountProfileErrorResponse.ErrorCase}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditReconciliationAccountProfileErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditReconciliationAccountProfileErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationAccountProfileErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationAccountProfileErrorResponse;
  return proto.reconcilio.EditReconciliationAccountProfileErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationAccountProfileErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} returns this
*/
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditReconciliationAccountProfileErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} returns this
*/
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditReconciliationAccountProfileErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationAccountProfileErrorResponse} returns this
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationAccountProfileErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceRequest}
 */
proto.reconcilio.DeleteWorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceRequest;
  return proto.reconcilio.DeleteWorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceRequest}
 */
proto.reconcilio.DeleteWorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceResponse.ResultCase}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteWorkspaceOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteWorkspaceErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceResponse}
 */
proto.reconcilio.DeleteWorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceResponse;
  return proto.reconcilio.DeleteWorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceResponse}
 */
proto.reconcilio.DeleteWorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteWorkspaceOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteWorkspaceErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteWorkspaceOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteWorkspaceErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteWorkspaceOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteWorkspaceOKResponse}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteWorkspaceErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteWorkspaceErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceOKResponse}
 */
proto.reconcilio.DeleteWorkspaceOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceOKResponse;
  return proto.reconcilio.DeleteWorkspaceOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceOKResponse}
 */
proto.reconcilio.DeleteWorkspaceOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceErrorResponse;
  return proto.reconcilio.DeleteWorkspaceErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityRequest}
 */
proto.reconcilio.DeleteEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityRequest;
  return proto.reconcilio.DeleteEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityRequest}
 */
proto.reconcilio.DeleteEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteEntityRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityRequest} returns this
 */
proto.reconcilio.DeleteEntityRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteEntityRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityRequest} returns this
 */
proto.reconcilio.DeleteEntityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteEntityResponse.ResultCase}
 */
proto.reconcilio.DeleteEntityResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteEntityOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteEntityErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityResponse}
 */
proto.reconcilio.DeleteEntityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityResponse;
  return proto.reconcilio.DeleteEntityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityResponse}
 */
proto.reconcilio.DeleteEntityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteEntityOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteEntityErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteEntityOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteEntityErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteEntityOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteEntityOKResponse}
 */
proto.reconcilio.DeleteEntityResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteEntityOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteEntityOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityResponse} returns this
*/
proto.reconcilio.DeleteEntityResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityResponse} returns this
 */
proto.reconcilio.DeleteEntityResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteEntityErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteEntityErrorResponse}
 */
proto.reconcilio.DeleteEntityResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteEntityErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteEntityErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityResponse} returns this
*/
proto.reconcilio.DeleteEntityResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityResponse} returns this
 */
proto.reconcilio.DeleteEntityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityOKResponse}
 */
proto.reconcilio.DeleteEntityOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityOKResponse;
  return proto.reconcilio.DeleteEntityOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityOKResponse}
 */
proto.reconcilio.DeleteEntityOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteEntityErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityErrorResponse}
 */
proto.reconcilio.DeleteEntityErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityErrorResponse;
  return proto.reconcilio.DeleteEntityErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityErrorResponse}
 */
proto.reconcilio.DeleteEntityErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationAccountRequest}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationAccountRequest;
  return proto.reconcilio.DeleteReconciliationAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationAccountRequest}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string reconciliation_account_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.getReconciliationAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountRequest} returns this
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.setReconciliationAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string workspace_id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountRequest} returns this
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountRequest} returns this
 */
proto.reconcilio.DeleteReconciliationAccountRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteReconciliationAccountResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteReconciliationAccountResponse.ResultCase}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteReconciliationAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteReconciliationAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteReconciliationAccountOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteReconciliationAccountErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationAccountResponse}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationAccountResponse;
  return proto.reconcilio.DeleteReconciliationAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationAccountResponse}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteReconciliationAccountOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteReconciliationAccountOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteReconciliationAccountErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteReconciliationAccountErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteReconciliationAccountOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteReconciliationAccountErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteReconciliationAccountOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteReconciliationAccountOKResponse}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteReconciliationAccountOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteReconciliationAccountOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteReconciliationAccountOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountResponse} returns this
*/
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteReconciliationAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationAccountResponse} returns this
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteReconciliationAccountErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteReconciliationAccountErrorResponse}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteReconciliationAccountErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteReconciliationAccountErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteReconciliationAccountErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountResponse} returns this
*/
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteReconciliationAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationAccountResponse} returns this
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationAccountResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationAccountOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationAccountOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationAccountOKResponse}
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationAccountOKResponse;
  return proto.reconcilio.DeleteReconciliationAccountOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationAccountOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationAccountOKResponse}
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationAccountOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationAccountOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNAUTHORIZED_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteReconciliationAccountErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteReconciliationAccountErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteReconciliationAccountErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationAccountErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationAccountErrorResponse}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationAccountErrorResponse;
  return proto.reconcilio.DeleteReconciliationAccountErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationAccountErrorResponse}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationAccountErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unauthorized_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} returns this
*/
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteReconciliationAccountErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} returns this
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} returns this
*/
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteReconciliationAccountErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationAccountErrorResponse} returns this
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationAccountErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceStopListItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceStopListItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemRequest}
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceStopListItemRequest;
  return proto.reconcilio.AddWorkspaceStopListItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemRequest}
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceStopListItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AddWorkspaceStopListItemResponse.ResultCase}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AddWorkspaceStopListItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddWorkspaceStopListItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceStopListItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceStopListItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AddWorkspaceStopListItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AddWorkspaceStopListItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceStopListItemResponse;
  return proto.reconcilio.AddWorkspaceStopListItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AddWorkspaceStopListItemOKResponse;
      reader.readMessage(value,proto.reconcilio.AddWorkspaceStopListItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AddWorkspaceStopListItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.AddWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceStopListItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AddWorkspaceStopListItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AddWorkspaceStopListItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddWorkspaceStopListItemOKResponse ok = 1;
 * @return {?proto.reconcilio.AddWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AddWorkspaceStopListItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddWorkspaceStopListItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AddWorkspaceStopListItemOKResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemResponse} returns this
*/
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddWorkspaceStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemResponse} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddWorkspaceStopListItemErrorResponse error = 2;
 * @return {?proto.reconcilio.AddWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AddWorkspaceStopListItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddWorkspaceStopListItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AddWorkspaceStopListItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemResponse} returns this
*/
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddWorkspaceStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemResponse} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceStopListItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceStopListItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceStopListItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceStopListItemOKResponse;
  return proto.reconcilio.AddWorkspaceStopListItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceStopListItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemOKResponse} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  UNAUTHORIZED_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.AddWorkspaceStopListItemErrorResponse.ErrorCase}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AddWorkspaceStopListItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddWorkspaceStopListItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceStopListItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceStopListItemErrorResponse;
  return proto.reconcilio.AddWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceStopListItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AddWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceStopListItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceStopListItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemRequest}
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceStopListItemRequest;
  return proto.reconcilio.DeleteWorkspaceStopListItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemRequest}
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceStopListItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceStopListItemResponse.ResultCase}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceStopListItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceStopListItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceStopListItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceStopListItemResponse;
  return proto.reconcilio.DeleteWorkspaceStopListItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteWorkspaceStopListItemOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceStopListItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteWorkspaceStopListItemOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceStopListItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceStopListItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceStopListItemOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteWorkspaceStopListItemErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceStopListItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceStopListItemOKResponse;
  return proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  UNAUTHORIZED_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse;
  return proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceStopListItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceStopListItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceStopListItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemRequest}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceStopListItemRequest;
  return proto.reconcilio.EditWorkspaceStopListItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemRequest}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceStopListItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditWorkspaceStopListItemResponse.ResultCase}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceStopListItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceStopListItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceStopListItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceStopListItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditWorkspaceStopListItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditWorkspaceStopListItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceStopListItemResponse;
  return proto.reconcilio.EditWorkspaceStopListItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditWorkspaceStopListItemOKResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceStopListItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditWorkspaceStopListItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceStopListItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditWorkspaceStopListItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditWorkspaceStopListItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditWorkspaceStopListItemOKResponse ok = 1;
 * @return {?proto.reconcilio.EditWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceStopListItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceStopListItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceStopListItemOKResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemResponse} returns this
*/
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemResponse} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditWorkspaceStopListItemErrorResponse error = 2;
 * @return {?proto.reconcilio.EditWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceStopListItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceStopListItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceStopListItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemResponse} returns this
*/
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemResponse} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceStopListItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceStopListItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceStopListItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceStopListItemOKResponse;
  return proto.reconcilio.EditWorkspaceStopListItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemOKResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceStopListItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  UNAUTHORIZED_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.EditWorkspaceStopListItemErrorResponse.ErrorCase}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceStopListItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceStopListItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceStopListItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceStopListItemErrorResponse;
  return proto.reconcilio.EditWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceStopListItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditWorkspaceStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceStopListItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryRequest}
 */
proto.reconcilio.AddWorkspaceCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryRequest;
  return proto.reconcilio.AddWorkspaceCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryRequest}
 */
proto.reconcilio.AddWorkspaceCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryRequest} returns this
 */
proto.reconcilio.AddWorkspaceCategoryRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryRequest} returns this
 */
proto.reconcilio.AddWorkspaceCategoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddWorkspaceCategoryResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AddWorkspaceCategoryResponse.ResultCase}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AddWorkspaceCategoryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddWorkspaceCategoryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AddWorkspaceCategoryOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AddWorkspaceCategoryErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryResponse}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryResponse;
  return proto.reconcilio.AddWorkspaceCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryResponse}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AddWorkspaceCategoryOKResponse;
      reader.readMessage(value,proto.reconcilio.AddWorkspaceCategoryOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AddWorkspaceCategoryErrorResponse;
      reader.readMessage(value,proto.reconcilio.AddWorkspaceCategoryErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AddWorkspaceCategoryOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AddWorkspaceCategoryErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddWorkspaceCategoryOKResponse ok = 1;
 * @return {?proto.reconcilio.AddWorkspaceCategoryOKResponse}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AddWorkspaceCategoryOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddWorkspaceCategoryOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AddWorkspaceCategoryOKResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddWorkspaceCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddWorkspaceCategoryErrorResponse error = 2;
 * @return {?proto.reconcilio.AddWorkspaceCategoryErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AddWorkspaceCategoryErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddWorkspaceCategoryErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AddWorkspaceCategoryErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddWorkspaceCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryOKResponse}
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryOKResponse;
  return proto.reconcilio.AddWorkspaceCategoryOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryOKResponse}
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryOKResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  UNAUTHORIZED_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.AddWorkspaceCategoryErrorResponse.ErrorCase}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AddWorkspaceCategoryErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddWorkspaceCategoryErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryErrorResponse;
  return proto.reconcilio.AddWorkspaceCategoryErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddWorkspaceCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddWorkspaceCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AddWorkspaceCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryRequest}
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryRequest;
  return proto.reconcilio.DeleteWorkspaceCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryRequest}
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceCategoryResponse.ResultCase}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceCategoryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceCategoryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteWorkspaceCategoryOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryResponse;
  return proto.reconcilio.DeleteWorkspaceCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteWorkspaceCategoryOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceCategoryOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteWorkspaceCategoryErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteWorkspaceCategoryOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteWorkspaceCategoryOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteWorkspaceCategoryOKResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceCategoryOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceCategoryOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceCategoryOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteWorkspaceCategoryErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteWorkspaceCategoryErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceCategoryErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceCategoryErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryOKResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryOKResponse;
  return proto.reconcilio.DeleteWorkspaceCategoryOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryOKResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  UNAUTHORIZED_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryErrorResponse;
  return proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemRequest}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryItemRequest;
  return proto.reconcilio.AddWorkspaceCategoryItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemRequest}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AddWorkspaceCategoryItemResponse.ResultCase}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AddWorkspaceCategoryItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddWorkspaceCategoryItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AddWorkspaceCategoryItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryItemResponse;
  return proto.reconcilio.AddWorkspaceCategoryItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AddWorkspaceCategoryItemOKResponse;
      reader.readMessage(value,proto.reconcilio.AddWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AddWorkspaceCategoryItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AddWorkspaceCategoryItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddWorkspaceCategoryItemOKResponse ok = 1;
 * @return {?proto.reconcilio.AddWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AddWorkspaceCategoryItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddWorkspaceCategoryItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AddWorkspaceCategoryItemOKResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddWorkspaceCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddWorkspaceCategoryItemErrorResponse error = 2;
 * @return {?proto.reconcilio.AddWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AddWorkspaceCategoryItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddWorkspaceCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryItemOKResponse;
  return proto.reconcilio.AddWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemOKResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  CATEGORY_NOT_FOUND_ERROR: 2,
  UNAUTHORIZED_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.ErrorCase}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddWorkspaceCategoryItemErrorResponse;
  return proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddWorkspaceCategoryItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryItemRequest;
  return proto.reconcilio.DeleteWorkspaceCategoryItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceCategoryItemResponse.ResultCase}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceCategoryItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceCategoryItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryItemResponse;
  return proto.reconcilio.DeleteWorkspaceCategoryItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteWorkspaceCategoryItemOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteWorkspaceCategoryItemErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse;
  return proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  CATEGORY_NOT_FOUND_ERROR: 2,
  UNAUTHORIZED_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse;
  return proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteWorkspaceCategoryItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameRequest}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryNameRequest;
  return proto.reconcilio.EditWorkspaceCategoryNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameRequest}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditWorkspaceCategoryNameResponse.ResultCase}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceCategoryNameResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceCategoryNameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditWorkspaceCategoryNameOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryNameResponse;
  return proto.reconcilio.EditWorkspaceCategoryNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditWorkspaceCategoryNameOKResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceCategoryNameOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditWorkspaceCategoryNameErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditWorkspaceCategoryNameOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditWorkspaceCategoryNameOKResponse ok = 1;
 * @return {?proto.reconcilio.EditWorkspaceCategoryNameOKResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceCategoryNameOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceCategoryNameOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceCategoryNameOKResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceCategoryNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditWorkspaceCategoryNameErrorResponse error = 2;
 * @return {?proto.reconcilio.EditWorkspaceCategoryNameErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceCategoryNameErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceCategoryNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryNameResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryNameOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameOKResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryNameOKResponse;
  return proto.reconcilio.EditWorkspaceCategoryNameOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameOKResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryNameOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  CATEGORY_NOT_FOUND_ERROR: 2,
  UNAUTHORIZED_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.ErrorCase}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryNameErrorResponse;
  return proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryNameErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemRequest}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryItemRequest;
  return proto.reconcilio.EditWorkspaceCategoryItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemRequest}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemRequest} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditWorkspaceCategoryItemResponse.ResultCase}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceCategoryItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceCategoryItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditWorkspaceCategoryItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryItemResponse;
  return proto.reconcilio.EditWorkspaceCategoryItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditWorkspaceCategoryItemOKResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditWorkspaceCategoryItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditWorkspaceCategoryItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditWorkspaceCategoryItemOKResponse ok = 1;
 * @return {?proto.reconcilio.EditWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceCategoryItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceCategoryItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceCategoryItemOKResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditWorkspaceCategoryItemErrorResponse error = 2;
 * @return {?proto.reconcilio.EditWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditWorkspaceCategoryItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryItemOKResponse;
  return proto.reconcilio.EditWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemOKResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  CATEGORY_NOT_FOUND_ERROR: 2,
  UNAUTHORIZED_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.ErrorCase}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditWorkspaceCategoryItemErrorResponse;
  return proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditWorkspaceCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditWorkspaceCategoryItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest;
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.ResultCase}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse;
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse;
      reader.readMessage(value,proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse;
      reader.readMessage(value,proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnableWorkspaceStopListItemOfEntityOKResponse ok = 1;
 * @return {?proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnableWorkspaceStopListItemOfEntityErrorResponse error = 2;
 * @return {?proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse;
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  STOP_LIST_ITEM_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    stopListItemNotFoundError: (f = msg.getStopListItemNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse;
  return proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setStopListItemNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getStopListItemNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse stop_list_item_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.getStopListItemNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.setStopListItemNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearStopListItemNotFoundError = function() {
  return this.setStopListItemNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasStopListItemNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest;
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.ResultCase}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse;
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse;
      reader.readMessage(value,proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse;
      reader.readMessage(value,proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DisableWorkspaceStopListItemOfEntityOKResponse ok = 1;
 * @return {?proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DisableWorkspaceStopListItemOfEntityErrorResponse error = 2;
 * @return {?proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse;
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  STOP_LIST_ITEM_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    stopListItemNotFoundError: (f = msg.getStopListItemNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse;
  return proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setStopListItemNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getStopListItemNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse stop_list_item_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.getStopListItemNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.setStopListItemNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearStopListItemNotFoundError = function() {
  return this.setStopListItemNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasStopListItemNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceStopListItemOfEntityErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest;
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.ResultCase}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse;
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse;
      reader.readMessage(value,proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse;
      reader.readMessage(value,proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnableWorkspaceCategoryOfEntityOKResponse ok = 1;
 * @return {?proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EnableWorkspaceCategoryOfEntityErrorResponse error = 2;
 * @return {?proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse;
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse;
  return proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EnableWorkspaceCategoryOfEntityErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest;
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_id = 2;
 * @return {string}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.ResultCase}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse;
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse;
      reader.readMessage(value,proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse;
      reader.readMessage(value,proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DisableWorkspaceCategoryOfEntityOKResponse ok = 1;
 * @return {?proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DisableWorkspaceCategoryOfEntityErrorResponse error = 2;
 * @return {?proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse;
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse;
  return proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
*/
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse} returns this
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DisableWorkspaceCategoryOfEntityErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityStopListItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityStopListItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityStopListItemRequest}
 */
proto.reconcilio.AddEntityStopListItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityStopListItemRequest;
  return proto.reconcilio.AddEntityStopListItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityStopListItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityStopListItemRequest}
 */
proto.reconcilio.AddEntityStopListItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityStopListItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityStopListItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityStopListItemRequest} returns this
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityStopListItemRequest} returns this
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityStopListItemRequest} returns this
 */
proto.reconcilio.AddEntityStopListItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddEntityStopListItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AddEntityStopListItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AddEntityStopListItemResponse.ResultCase}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AddEntityStopListItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddEntityStopListItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityStopListItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityStopListItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AddEntityStopListItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AddEntityStopListItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityStopListItemResponse}
 */
proto.reconcilio.AddEntityStopListItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityStopListItemResponse;
  return proto.reconcilio.AddEntityStopListItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityStopListItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityStopListItemResponse}
 */
proto.reconcilio.AddEntityStopListItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AddEntityStopListItemOKResponse;
      reader.readMessage(value,proto.reconcilio.AddEntityStopListItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AddEntityStopListItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.AddEntityStopListItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityStopListItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityStopListItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AddEntityStopListItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AddEntityStopListItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddEntityStopListItemOKResponse ok = 1;
 * @return {?proto.reconcilio.AddEntityStopListItemOKResponse}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AddEntityStopListItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddEntityStopListItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AddEntityStopListItemOKResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityStopListItemResponse} returns this
*/
proto.reconcilio.AddEntityStopListItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddEntityStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityStopListItemResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddEntityStopListItemErrorResponse error = 2;
 * @return {?proto.reconcilio.AddEntityStopListItemErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AddEntityStopListItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddEntityStopListItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AddEntityStopListItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityStopListItemResponse} returns this
*/
proto.reconcilio.AddEntityStopListItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddEntityStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityStopListItemResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityStopListItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityStopListItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityStopListItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityStopListItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityStopListItemOKResponse}
 */
proto.reconcilio.AddEntityStopListItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityStopListItemOKResponse;
  return proto.reconcilio.AddEntityStopListItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityStopListItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityStopListItemOKResponse}
 */
proto.reconcilio.AddEntityStopListItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityStopListItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityStopListItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityStopListItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.AddEntityStopListItemOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityStopListItemOKResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  UNAUTHORIZED_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.AddEntityStopListItemErrorResponse.ErrorCase}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AddEntityStopListItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityStopListItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityStopListItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityStopListItemErrorResponse;
  return proto.reconcilio.AddEntityStopListItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityStopListItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityStopListItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityStopListItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.AddEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityStopListItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityStopListItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityStopListItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stopListItemId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityStopListItemRequest}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityStopListItemRequest;
  return proto.reconcilio.DeleteEntityStopListItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityStopListItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityStopListItemRequest}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStopListItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityStopListItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityStopListItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStopListItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemRequest} returns this
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemRequest} returns this
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string stop_list_item_id = 3;
 * @return {string}
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.getStopListItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemRequest} returns this
 */
proto.reconcilio.DeleteEntityStopListItemRequest.prototype.setStopListItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityStopListItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteEntityStopListItemResponse.ResultCase}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityStopListItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityStopListItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityStopListItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityStopListItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteEntityStopListItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteEntityStopListItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityStopListItemResponse}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityStopListItemResponse;
  return proto.reconcilio.DeleteEntityStopListItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityStopListItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityStopListItemResponse}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteEntityStopListItemOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityStopListItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteEntityStopListItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityStopListItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityStopListItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityStopListItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteEntityStopListItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteEntityStopListItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteEntityStopListItemOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteEntityStopListItemOKResponse}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteEntityStopListItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityStopListItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteEntityStopListItemOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteEntityStopListItemErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteEntityStopListItemErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteEntityStopListItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityStopListItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteEntityStopListItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityStopListItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityStopListItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityStopListItemOKResponse}
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityStopListItemOKResponse;
  return proto.reconcilio.DeleteEntityStopListItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityStopListItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityStopListItemOKResponse}
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityStopListItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityStopListItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  STOP_LIST_ITEM_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.DeleteEntityStopListItemErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityStopListItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityStopListItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    stopListItemNotFoundError: (f = msg.getStopListItemNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityStopListItemErrorResponse;
  return proto.reconcilio.DeleteEntityStopListItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setStopListItemNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityStopListItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getStopListItemNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse stop_list_item_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.getStopListItemNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.setStopListItemNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.clearStopListItemNotFoundError = function() {
  return this.setStopListItemNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.hasStopListItemNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.DeleteEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityStopListItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityStopListItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityStopListItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stopListItemId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityStopListItemRequest}
 */
proto.reconcilio.EditEntityStopListItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityStopListItemRequest;
  return proto.reconcilio.EditEntityStopListItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityStopListItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityStopListItemRequest}
 */
proto.reconcilio.EditEntityStopListItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStopListItemId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityStopListItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityStopListItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStopListItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityStopListItemRequest} returns this
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityStopListItemRequest} returns this
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string stop_list_item_id = 3;
 * @return {string}
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.getStopListItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityStopListItemRequest} returns this
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.setStopListItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityStopListItemRequest} returns this
 */
proto.reconcilio.EditEntityStopListItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityStopListItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityStopListItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditEntityStopListItemResponse.ResultCase}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditEntityStopListItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityStopListItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityStopListItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityStopListItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditEntityStopListItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditEntityStopListItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityStopListItemResponse}
 */
proto.reconcilio.EditEntityStopListItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityStopListItemResponse;
  return proto.reconcilio.EditEntityStopListItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityStopListItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityStopListItemResponse}
 */
proto.reconcilio.EditEntityStopListItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditEntityStopListItemOKResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityStopListItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditEntityStopListItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityStopListItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityStopListItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityStopListItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditEntityStopListItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditEntityStopListItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditEntityStopListItemOKResponse ok = 1;
 * @return {?proto.reconcilio.EditEntityStopListItemOKResponse}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditEntityStopListItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityStopListItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditEntityStopListItemOKResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditEntityStopListItemErrorResponse error = 2;
 * @return {?proto.reconcilio.EditEntityStopListItemErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditEntityStopListItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityStopListItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditEntityStopListItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityStopListItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityStopListItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityStopListItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityStopListItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityStopListItemOKResponse}
 */
proto.reconcilio.EditEntityStopListItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityStopListItemOKResponse;
  return proto.reconcilio.EditEntityStopListItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityStopListItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityStopListItemOKResponse}
 */
proto.reconcilio.EditEntityStopListItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityStopListItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityStopListItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityStopListItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  STOP_LIST_ITEM_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.EditEntityStopListItemErrorResponse.ErrorCase}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditEntityStopListItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityStopListItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityStopListItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    stopListItemNotFoundError: (f = msg.getStopListItemNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityStopListItemErrorResponse;
  return proto.reconcilio.EditEntityStopListItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityStopListItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setStopListItemNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityStopListItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityStopListItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getStopListItemNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse stop_list_item_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.getStopListItemNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.setStopListItemNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.clearStopListItemNotFoundError = function() {
  return this.setStopListItemNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.hasStopListItemNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.EditEntityStopListItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityStopListItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityStopListItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryRequest}
 */
proto.reconcilio.AddEntityCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryRequest;
  return proto.reconcilio.AddEntityCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryRequest}
 */
proto.reconcilio.AddEntityCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryRequest} returns this
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryRequest} returns this
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryRequest} returns this
 */
proto.reconcilio.AddEntityCategoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddEntityCategoryResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AddEntityCategoryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AddEntityCategoryResponse.ResultCase}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AddEntityCategoryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddEntityCategoryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AddEntityCategoryOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AddEntityCategoryErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryResponse}
 */
proto.reconcilio.AddEntityCategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryResponse;
  return proto.reconcilio.AddEntityCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryResponse}
 */
proto.reconcilio.AddEntityCategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AddEntityCategoryOKResponse;
      reader.readMessage(value,proto.reconcilio.AddEntityCategoryOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AddEntityCategoryErrorResponse;
      reader.readMessage(value,proto.reconcilio.AddEntityCategoryErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AddEntityCategoryOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AddEntityCategoryErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddEntityCategoryOKResponse ok = 1;
 * @return {?proto.reconcilio.AddEntityCategoryOKResponse}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AddEntityCategoryOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddEntityCategoryOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AddEntityCategoryOKResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryResponse} returns this
*/
proto.reconcilio.AddEntityCategoryResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddEntityCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryResponse} returns this
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddEntityCategoryErrorResponse error = 2;
 * @return {?proto.reconcilio.AddEntityCategoryErrorResponse}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AddEntityCategoryErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddEntityCategoryErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AddEntityCategoryErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryResponse} returns this
*/
proto.reconcilio.AddEntityCategoryResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddEntityCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryResponse} returns this
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryOKResponse}
 */
proto.reconcilio.AddEntityCategoryOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryOKResponse;
  return proto.reconcilio.AddEntityCategoryOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryOKResponse}
 */
proto.reconcilio.AddEntityCategoryOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryOKResponse} returns this
 */
proto.reconcilio.AddEntityCategoryOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  UNAUTHORIZED_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.AddEntityCategoryErrorResponse.ErrorCase}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AddEntityCategoryErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryErrorResponse;
  return proto.reconcilio.AddEntityCategoryErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.AddEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryRequest}
 */
proto.reconcilio.DeleteEntityCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryRequest;
  return proto.reconcilio.DeleteEntityCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryRequest}
 */
proto.reconcilio.DeleteEntityCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityCategoryResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityCategoryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteEntityCategoryResponse.ResultCase}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityCategoryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityCategoryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteEntityCategoryOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteEntityCategoryErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryResponse}
 */
proto.reconcilio.DeleteEntityCategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryResponse;
  return proto.reconcilio.DeleteEntityCategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryResponse}
 */
proto.reconcilio.DeleteEntityCategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteEntityCategoryOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityCategoryOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteEntityCategoryErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityCategoryErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteEntityCategoryOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteEntityCategoryErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteEntityCategoryOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteEntityCategoryOKResponse}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteEntityCategoryOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityCategoryOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteEntityCategoryOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteEntityCategoryErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteEntityCategoryErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteEntityCategoryErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityCategoryErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteEntityCategoryErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityCategoryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryOKResponse}
 */
proto.reconcilio.DeleteEntityCategoryOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryOKResponse;
  return proto.reconcilio.DeleteEntityCategoryOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryOKResponse}
 */
proto.reconcilio.DeleteEntityCategoryOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.DeleteEntityCategoryErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityCategoryErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryErrorResponse;
  return proto.reconcilio.DeleteEntityCategoryErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.DeleteEntityCategoryErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    value: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryItemRequest}
 */
proto.reconcilio.AddEntityCategoryItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryItemRequest;
  return proto.reconcilio.AddEntityCategoryItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryItemRequest}
 */
proto.reconcilio.AddEntityCategoryItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryItemRequest} returns this
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryItemRequest} returns this
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryItemRequest} returns this
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryItemRequest} returns this
 */
proto.reconcilio.AddEntityCategoryItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddEntityCategoryItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AddEntityCategoryItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AddEntityCategoryItemResponse.ResultCase}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AddEntityCategoryItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddEntityCategoryItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AddEntityCategoryItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AddEntityCategoryItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryItemResponse}
 */
proto.reconcilio.AddEntityCategoryItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryItemResponse;
  return proto.reconcilio.AddEntityCategoryItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryItemResponse}
 */
proto.reconcilio.AddEntityCategoryItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AddEntityCategoryItemOKResponse;
      reader.readMessage(value,proto.reconcilio.AddEntityCategoryItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AddEntityCategoryItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.AddEntityCategoryItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AddEntityCategoryItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AddEntityCategoryItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddEntityCategoryItemOKResponse ok = 1;
 * @return {?proto.reconcilio.AddEntityCategoryItemOKResponse}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AddEntityCategoryItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddEntityCategoryItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AddEntityCategoryItemOKResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddEntityCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddEntityCategoryItemErrorResponse error = 2;
 * @return {?proto.reconcilio.AddEntityCategoryItemErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AddEntityCategoryItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AddEntityCategoryItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AddEntityCategoryItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddEntityCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryItemOKResponse}
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryItemOKResponse;
  return proto.reconcilio.AddEntityCategoryItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryItemOKResponse}
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AddEntityCategoryItemOKResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemOKResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.AddEntityCategoryItemErrorResponse.ErrorCase}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AddEntityCategoryItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AddEntityCategoryItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AddEntityCategoryItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AddEntityCategoryItemErrorResponse;
  return proto.reconcilio.AddEntityCategoryItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AddEntityCategoryItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AddEntityCategoryItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AddEntityCategoryItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.AddEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AddEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AddEntityCategoryItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryItemId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemRequest}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryItemRequest;
  return proto.reconcilio.DeleteEntityCategoryItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemRequest}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryItemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category_item_id = 4;
 * @return {string}
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.getCategoryItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemRequest} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemRequest.prototype.setCategoryItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteEntityCategoryItemResponse.ResultCase}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityCategoryItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityCategoryItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteEntityCategoryItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteEntityCategoryItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryItemResponse;
  return proto.reconcilio.DeleteEntityCategoryItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteEntityCategoryItemOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityCategoryItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteEntityCategoryItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteEntityCategoryItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteEntityCategoryItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteEntityCategoryItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteEntityCategoryItemOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteEntityCategoryItemOKResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteEntityCategoryItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityCategoryItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteEntityCategoryItemOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteEntityCategoryItemErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteEntityCategoryItemErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteEntityCategoryItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteEntityCategoryItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteEntityCategoryItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemOKResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryItemOKResponse;
  return proto.reconcilio.DeleteEntityCategoryItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemOKResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  CATEGORY_ITEM_NOT_FOUND_ERROR: 4,
  UNAUTHORIZED_ERROR: 5,
  UNEXPECTED_ERROR: 6
};

/**
 * @return {proto.reconcilio.DeleteEntityCategoryItemErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteEntityCategoryItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteEntityCategoryItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryItemNotFoundError: (f = msg.getCategoryItemNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteEntityCategoryItemErrorResponse;
  return proto.reconcilio.DeleteEntityCategoryItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryItemNotFoundError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 6:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteEntityCategoryItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryItemNotFoundError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse category_item_not_found_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getCategoryItemNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.setCategoryItemNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.clearCategoryItemNotFoundError = function() {
  return this.setCategoryItemNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.hasCategoryItemNotFoundError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorResponse unexpected_error = 6;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 6));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.reconcilio.DeleteEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteEntityCategoryItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryNameRequest}
 */
proto.reconcilio.EditEntityCategoryNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryNameRequest;
  return proto.reconcilio.EditEntityCategoryNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryNameRequest}
 */
proto.reconcilio.EditEntityCategoryNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryNameRequest} returns this
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryNameRequest} returns this
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryNameRequest} returns this
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryNameRequest} returns this
 */
proto.reconcilio.EditEntityCategoryNameRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityCategoryNameResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityCategoryNameResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditEntityCategoryNameResponse.ResultCase}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditEntityCategoryNameResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityCategoryNameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditEntityCategoryNameOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditEntityCategoryNameErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryNameResponse}
 */
proto.reconcilio.EditEntityCategoryNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryNameResponse;
  return proto.reconcilio.EditEntityCategoryNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryNameResponse}
 */
proto.reconcilio.EditEntityCategoryNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditEntityCategoryNameOKResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityCategoryNameOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditEntityCategoryNameErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityCategoryNameErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditEntityCategoryNameOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditEntityCategoryNameErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditEntityCategoryNameOKResponse ok = 1;
 * @return {?proto.reconcilio.EditEntityCategoryNameOKResponse}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditEntityCategoryNameOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityCategoryNameOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditEntityCategoryNameOKResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityCategoryNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditEntityCategoryNameErrorResponse error = 2;
 * @return {?proto.reconcilio.EditEntityCategoryNameErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditEntityCategoryNameErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityCategoryNameErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditEntityCategoryNameErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityCategoryNameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryNameOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryNameOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryNameOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryNameOKResponse}
 */
proto.reconcilio.EditEntityCategoryNameOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryNameOKResponse;
  return proto.reconcilio.EditEntityCategoryNameOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryNameOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryNameOKResponse}
 */
proto.reconcilio.EditEntityCategoryNameOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryNameOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryNameOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryNameOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  UNAUTHORIZED_ERROR: 4,
  UNEXPECTED_ERROR: 5
};

/**
 * @return {proto.reconcilio.EditEntityCategoryNameErrorResponse.ErrorCase}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditEntityCategoryNameErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryNameErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryNameErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryNameErrorResponse;
  return proto.reconcilio.EditEntityCategoryNameErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryNameErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryNameErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryNameErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unexpected_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.EditEntityCategoryNameErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryNameErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryNameErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryItemId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest}
 */
proto.reconcilio.EditEntityCategoryItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryItemRequest;
  return proto.reconcilio.EditEntityCategoryItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest}
 */
proto.reconcilio.EditEntityCategoryItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryItemId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryItemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest} returns this
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest} returns this
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest} returns this
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category_item_id = 4;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.getCategoryItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest} returns this
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.setCategoryItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string value = 5;
 * @return {string}
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditEntityCategoryItemRequest} returns this
 */
proto.reconcilio.EditEntityCategoryItemRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityCategoryItemResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityCategoryItemResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditEntityCategoryItemResponse.ResultCase}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditEntityCategoryItemResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityCategoryItemResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditEntityCategoryItemOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditEntityCategoryItemErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryItemResponse}
 */
proto.reconcilio.EditEntityCategoryItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryItemResponse;
  return proto.reconcilio.EditEntityCategoryItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryItemResponse}
 */
proto.reconcilio.EditEntityCategoryItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditEntityCategoryItemOKResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityCategoryItemOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditEntityCategoryItemErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditEntityCategoryItemErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditEntityCategoryItemOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditEntityCategoryItemErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditEntityCategoryItemOKResponse ok = 1;
 * @return {?proto.reconcilio.EditEntityCategoryItemOKResponse}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditEntityCategoryItemOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityCategoryItemOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditEntityCategoryItemOKResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditEntityCategoryItemErrorResponse error = 2;
 * @return {?proto.reconcilio.EditEntityCategoryItemErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditEntityCategoryItemErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditEntityCategoryItemErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditEntityCategoryItemErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityCategoryItemResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryItemOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryItemOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryItemOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryItemOKResponse}
 */
proto.reconcilio.EditEntityCategoryItemOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryItemOKResponse;
  return proto.reconcilio.EditEntityCategoryItemOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryItemOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryItemOKResponse}
 */
proto.reconcilio.EditEntityCategoryItemOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryItemOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryItemOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryItemOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  CATEGORY_NOT_FOUND_ERROR: 3,
  CATEGORY_ITEM_NOT_FOUND_ERROR: 4,
  UNAUTHORIZED_ERROR: 5,
  UNEXPECTED_ERROR: 6
};

/**
 * @return {proto.reconcilio.EditEntityCategoryItemErrorResponse.ErrorCase}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditEntityCategoryItemErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditEntityCategoryItemErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditEntityCategoryItemErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryNotFoundError: (f = msg.getCategoryNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    categoryItemNotFoundError: (f = msg.getCategoryItemNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unauthorizedError: (f = msg.getUnauthorizedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditEntityCategoryItemErrorResponse;
  return proto.reconcilio.EditEntityCategoryItemErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditEntityCategoryItemErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryNotFoundError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCategoryItemNotFoundError(value);
      break;
    case 5:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnauthorizedError(value);
      break;
    case 6:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditEntityCategoryItemErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditEntityCategoryItemErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryNotFoundError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryItemNotFoundError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnauthorizedError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse category_not_found_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getCategoryNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.setCategoryNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.clearCategoryNotFoundError = function() {
  return this.setCategoryNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.hasCategoryNotFoundError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse category_item_not_found_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getCategoryItemNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.setCategoryItemNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.clearCategoryItemNotFoundError = function() {
  return this.setCategoryItemNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.hasCategoryItemNotFoundError = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ErrorResponse unauthorized_error = 5;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getUnauthorizedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 5));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.setUnauthorizedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.clearUnauthorizedError = function() {
  return this.setUnauthorizedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.hasUnauthorizedError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ErrorResponse unexpected_error = 6;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 6));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
*/
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.reconcilio.EditEntityCategoryItemErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditEntityCategoryItemErrorResponse} returns this
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditEntityCategoryItemErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceSettingsByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdRequest}
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceSettingsByIdRequest;
  return proto.reconcilio.GetWorkspaceSettingsByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdRequest}
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceSettingsByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdRequest} returns this
 */
proto.reconcilio.GetWorkspaceSettingsByIdRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetWorkspaceSettingsByIdResponse.ResultCase}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetWorkspaceSettingsByIdResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetWorkspaceSettingsByIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceSettingsByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceSettingsByIdResponse;
  return proto.reconcilio.GetWorkspaceSettingsByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetWorkspaceSettingsByIdOKResponse;
      reader.readMessage(value,proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceSettingsByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetWorkspaceSettingsByIdOKResponse ok = 1;
 * @return {?proto.reconcilio.GetWorkspaceSettingsByIdOKResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetWorkspaceSettingsByIdOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetWorkspaceSettingsByIdOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetWorkspaceSettingsByIdOKResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} returns this
*/
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetWorkspaceSettingsByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} returns this
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetWorkspaceSettingsByIdErrorResponse error = 2;
 * @return {?proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} returns this
*/
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetWorkspaceSettingsByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdResponse} returns this
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceSettingsByIdResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.WorkspaceSettings.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.WorkspaceSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.WorkspaceSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.WorkspaceSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.WorkspaceSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stopListList: jspb.Message.toObjectList(msg.getStopListList(),
    proto.reconcilio.StopListItem.toObject, includeInstance),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.reconcilio.Category.toObject, includeInstance),
    entitySettingsList: jspb.Message.toObjectList(msg.getEntitySettingsList(),
    proto.reconcilio.EntitySettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.WorkspaceSettings}
 */
proto.reconcilio.WorkspaceSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.WorkspaceSettings;
  return proto.reconcilio.WorkspaceSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.WorkspaceSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.WorkspaceSettings}
 */
proto.reconcilio.WorkspaceSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.reconcilio.StopListItem;
      reader.readMessage(value,proto.reconcilio.StopListItem.deserializeBinaryFromReader);
      msg.addStopList(value);
      break;
    case 3:
      var value = new proto.reconcilio.Category;
      reader.readMessage(value,proto.reconcilio.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 4:
      var value = new proto.reconcilio.EntitySettings;
      reader.readMessage(value,proto.reconcilio.EntitySettings.deserializeBinaryFromReader);
      msg.addEntitySettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.WorkspaceSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.WorkspaceSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.WorkspaceSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.WorkspaceSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStopListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reconcilio.StopListItem.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.Category.serializeBinaryToWriter
    );
  }
  f = message.getEntitySettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reconcilio.EntitySettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.WorkspaceSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
 */
proto.reconcilio.WorkspaceSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StopListItem stop_list = 2;
 * @return {!Array<!proto.reconcilio.StopListItem>}
 */
proto.reconcilio.WorkspaceSettings.prototype.getStopListList = function() {
  return /** @type{!Array<!proto.reconcilio.StopListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.StopListItem, 2));
};


/**
 * @param {!Array<!proto.reconcilio.StopListItem>} value
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
*/
proto.reconcilio.WorkspaceSettings.prototype.setStopListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reconcilio.StopListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.StopListItem}
 */
proto.reconcilio.WorkspaceSettings.prototype.addStopList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reconcilio.StopListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
 */
proto.reconcilio.WorkspaceSettings.prototype.clearStopListList = function() {
  return this.setStopListList([]);
};


/**
 * repeated Category categories = 3;
 * @return {!Array<!proto.reconcilio.Category>}
 */
proto.reconcilio.WorkspaceSettings.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.reconcilio.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Category, 3));
};


/**
 * @param {!Array<!proto.reconcilio.Category>} value
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
*/
proto.reconcilio.WorkspaceSettings.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Category}
 */
proto.reconcilio.WorkspaceSettings.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
 */
proto.reconcilio.WorkspaceSettings.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated EntitySettings entity_settings = 4;
 * @return {!Array<!proto.reconcilio.EntitySettings>}
 */
proto.reconcilio.WorkspaceSettings.prototype.getEntitySettingsList = function() {
  return /** @type{!Array<!proto.reconcilio.EntitySettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.EntitySettings, 4));
};


/**
 * @param {!Array<!proto.reconcilio.EntitySettings>} value
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
*/
proto.reconcilio.WorkspaceSettings.prototype.setEntitySettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reconcilio.EntitySettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.EntitySettings}
 */
proto.reconcilio.WorkspaceSettings.prototype.addEntitySettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reconcilio.EntitySettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.WorkspaceSettings} returns this
 */
proto.reconcilio.WorkspaceSettings.prototype.clearEntitySettingsList = function() {
  return this.setEntitySettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.StopListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.StopListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.StopListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.StopListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.StopListItem}
 */
proto.reconcilio.StopListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.StopListItem;
  return proto.reconcilio.StopListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.StopListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.StopListItem}
 */
proto.reconcilio.StopListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.StopListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.StopListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.StopListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.StopListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.StopListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.StopListItem} returns this
 */
proto.reconcilio.StopListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.reconcilio.StopListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.StopListItem} returns this
 */
proto.reconcilio.StopListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.Category.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reconcilio.CategoryItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.Category}
 */
proto.reconcilio.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.Category;
  return proto.reconcilio.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.Category}
 */
proto.reconcilio.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.reconcilio.CategoryItem;
      reader.readMessage(value,proto.reconcilio.CategoryItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.CategoryItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.Category.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Category} returns this
 */
proto.reconcilio.Category.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.Category.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.Category} returns this
 */
proto.reconcilio.Category.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CategoryItem items = 3;
 * @return {!Array<!proto.reconcilio.CategoryItem>}
 */
proto.reconcilio.Category.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reconcilio.CategoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.CategoryItem, 3));
};


/**
 * @param {!Array<!proto.reconcilio.CategoryItem>} value
 * @return {!proto.reconcilio.Category} returns this
*/
proto.reconcilio.Category.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.CategoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.CategoryItem}
 */
proto.reconcilio.Category.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.CategoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.Category} returns this
 */
proto.reconcilio.Category.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CategoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CategoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CategoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CategoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CategoryItem}
 */
proto.reconcilio.CategoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CategoryItem;
  return proto.reconcilio.CategoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CategoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CategoryItem}
 */
proto.reconcilio.CategoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CategoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CategoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CategoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CategoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.CategoryItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CategoryItem} returns this
 */
proto.reconcilio.CategoryItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.reconcilio.CategoryItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CategoryItem} returns this
 */
proto.reconcilio.CategoryItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.EntitySettings.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EntitySettings.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EntitySettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EntitySettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EntitySettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stopListList: jspb.Message.toObjectList(msg.getStopListList(),
    proto.reconcilio.StopListItem.toObject, includeInstance),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.reconcilio.Category.toObject, includeInstance),
    workspaceStopListList: jspb.Message.toObjectList(msg.getWorkspaceStopListList(),
    proto.reconcilio.InheritedSetting.toObject, includeInstance),
    workspaceCategoriesList: jspb.Message.toObjectList(msg.getWorkspaceCategoriesList(),
    proto.reconcilio.InheritedSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EntitySettings}
 */
proto.reconcilio.EntitySettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EntitySettings;
  return proto.reconcilio.EntitySettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EntitySettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EntitySettings}
 */
proto.reconcilio.EntitySettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.reconcilio.StopListItem;
      reader.readMessage(value,proto.reconcilio.StopListItem.deserializeBinaryFromReader);
      msg.addStopList(value);
      break;
    case 3:
      var value = new proto.reconcilio.Category;
      reader.readMessage(value,proto.reconcilio.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 4:
      var value = new proto.reconcilio.InheritedSetting;
      reader.readMessage(value,proto.reconcilio.InheritedSetting.deserializeBinaryFromReader);
      msg.addWorkspaceStopList(value);
      break;
    case 5:
      var value = new proto.reconcilio.InheritedSetting;
      reader.readMessage(value,proto.reconcilio.InheritedSetting.deserializeBinaryFromReader);
      msg.addWorkspaceCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EntitySettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EntitySettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EntitySettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EntitySettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStopListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reconcilio.StopListItem.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.Category.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceStopListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reconcilio.InheritedSetting.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.reconcilio.InheritedSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.EntitySettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EntitySettings} returns this
 */
proto.reconcilio.EntitySettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StopListItem stop_list = 2;
 * @return {!Array<!proto.reconcilio.StopListItem>}
 */
proto.reconcilio.EntitySettings.prototype.getStopListList = function() {
  return /** @type{!Array<!proto.reconcilio.StopListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.StopListItem, 2));
};


/**
 * @param {!Array<!proto.reconcilio.StopListItem>} value
 * @return {!proto.reconcilio.EntitySettings} returns this
*/
proto.reconcilio.EntitySettings.prototype.setStopListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reconcilio.StopListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.StopListItem}
 */
proto.reconcilio.EntitySettings.prototype.addStopList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reconcilio.StopListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.EntitySettings} returns this
 */
proto.reconcilio.EntitySettings.prototype.clearStopListList = function() {
  return this.setStopListList([]);
};


/**
 * repeated Category categories = 3;
 * @return {!Array<!proto.reconcilio.Category>}
 */
proto.reconcilio.EntitySettings.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.reconcilio.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Category, 3));
};


/**
 * @param {!Array<!proto.reconcilio.Category>} value
 * @return {!proto.reconcilio.EntitySettings} returns this
*/
proto.reconcilio.EntitySettings.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Category}
 */
proto.reconcilio.EntitySettings.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.EntitySettings} returns this
 */
proto.reconcilio.EntitySettings.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * repeated InheritedSetting workspace_stop_list = 4;
 * @return {!Array<!proto.reconcilio.InheritedSetting>}
 */
proto.reconcilio.EntitySettings.prototype.getWorkspaceStopListList = function() {
  return /** @type{!Array<!proto.reconcilio.InheritedSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.InheritedSetting, 4));
};


/**
 * @param {!Array<!proto.reconcilio.InheritedSetting>} value
 * @return {!proto.reconcilio.EntitySettings} returns this
*/
proto.reconcilio.EntitySettings.prototype.setWorkspaceStopListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reconcilio.InheritedSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.InheritedSetting}
 */
proto.reconcilio.EntitySettings.prototype.addWorkspaceStopList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reconcilio.InheritedSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.EntitySettings} returns this
 */
proto.reconcilio.EntitySettings.prototype.clearWorkspaceStopListList = function() {
  return this.setWorkspaceStopListList([]);
};


/**
 * repeated InheritedSetting workspace_categories = 5;
 * @return {!Array<!proto.reconcilio.InheritedSetting>}
 */
proto.reconcilio.EntitySettings.prototype.getWorkspaceCategoriesList = function() {
  return /** @type{!Array<!proto.reconcilio.InheritedSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.InheritedSetting, 5));
};


/**
 * @param {!Array<!proto.reconcilio.InheritedSetting>} value
 * @return {!proto.reconcilio.EntitySettings} returns this
*/
proto.reconcilio.EntitySettings.prototype.setWorkspaceCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.reconcilio.InheritedSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.InheritedSetting}
 */
proto.reconcilio.EntitySettings.prototype.addWorkspaceCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.reconcilio.InheritedSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.EntitySettings} returns this
 */
proto.reconcilio.EntitySettings.prototype.clearWorkspaceCategoriesList = function() {
  return this.setWorkspaceCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.InheritedSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.InheritedSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.InheritedSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.InheritedSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.InheritedSetting}
 */
proto.reconcilio.InheritedSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.InheritedSetting;
  return proto.reconcilio.InheritedSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.InheritedSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.InheritedSetting}
 */
proto.reconcilio.InheritedSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.InheritedSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.InheritedSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.InheritedSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.InheritedSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reconcilio.InheritedSetting.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.InheritedSetting} returns this
 */
proto.reconcilio.InheritedSetting.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_enabled = 2;
 * @return {boolean}
 */
proto.reconcilio.InheritedSetting.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reconcilio.InheritedSetting} returns this
 */
proto.reconcilio.InheritedSetting.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && proto.reconcilio.WorkspaceSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceSettingsByIdOKResponse;
  return proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.WorkspaceSettings;
      reader.readMessage(value,proto.reconcilio.WorkspaceSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.WorkspaceSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional WorkspaceSettings settings = 1;
 * @return {?proto.reconcilio.WorkspaceSettings}
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.prototype.getSettings = function() {
  return /** @type{?proto.reconcilio.WorkspaceSettings} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.WorkspaceSettings, 1));
};


/**
 * @param {?proto.reconcilio.WorkspaceSettings|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse} returns this
*/
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdOKResponse} returns this
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceSettingsByIdOKResponse.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.ErrorCase}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse;
  return proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} returns this
*/
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} returns this
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} returns this
*/
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse} returns this
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetWorkspaceSettingsByIdErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntitySettingsByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntitySettingsByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntitySettingsByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntitySettingsByIdRequest}
 */
proto.reconcilio.GetEntitySettingsByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntitySettingsByIdRequest;
  return proto.reconcilio.GetEntitySettingsByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntitySettingsByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntitySettingsByIdRequest}
 */
proto.reconcilio.GetEntitySettingsByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntitySettingsByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntitySettingsByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntitySettingsByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string workspace_id = 1;
 * @return {string}
 */
proto.reconcilio.GetEntitySettingsByIdRequest.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdRequest} returns this
 */
proto.reconcilio.GetEntitySettingsByIdRequest.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.reconcilio.GetEntitySettingsByIdRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdRequest} returns this
 */
proto.reconcilio.GetEntitySettingsByIdRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetEntitySettingsByIdResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.GetEntitySettingsByIdResponse.ResultCase}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.GetEntitySettingsByIdResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetEntitySettingsByIdResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntitySettingsByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntitySettingsByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.GetEntitySettingsByIdOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.GetEntitySettingsByIdErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntitySettingsByIdResponse}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntitySettingsByIdResponse;
  return proto.reconcilio.GetEntitySettingsByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntitySettingsByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntitySettingsByIdResponse}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.GetEntitySettingsByIdOKResponse;
      reader.readMessage(value,proto.reconcilio.GetEntitySettingsByIdOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.GetEntitySettingsByIdErrorResponse;
      reader.readMessage(value,proto.reconcilio.GetEntitySettingsByIdErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntitySettingsByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntitySettingsByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.GetEntitySettingsByIdOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.GetEntitySettingsByIdErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetEntitySettingsByIdOKResponse ok = 1;
 * @return {?proto.reconcilio.GetEntitySettingsByIdOKResponse}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.GetEntitySettingsByIdOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetEntitySettingsByIdOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.GetEntitySettingsByIdOKResponse|undefined} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdResponse} returns this
*/
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetEntitySettingsByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntitySettingsByIdResponse} returns this
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetEntitySettingsByIdErrorResponse error = 2;
 * @return {?proto.reconcilio.GetEntitySettingsByIdErrorResponse}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.GetEntitySettingsByIdErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.GetEntitySettingsByIdErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.GetEntitySettingsByIdErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdResponse} returns this
*/
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetEntitySettingsByIdResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntitySettingsByIdResponse} returns this
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntitySettingsByIdResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.EntityCompleteSettings.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EntityCompleteSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EntityCompleteSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EntityCompleteSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EntityCompleteSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    entitySettings: (f = msg.getEntitySettings()) && proto.reconcilio.EntitySettings.toObject(includeInstance, f),
    workspaceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    workspaceStopListList: jspb.Message.toObjectList(msg.getWorkspaceStopListList(),
    proto.reconcilio.StopListItem.toObject, includeInstance),
    workspaceCategoriesList: jspb.Message.toObjectList(msg.getWorkspaceCategoriesList(),
    proto.reconcilio.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EntityCompleteSettings}
 */
proto.reconcilio.EntityCompleteSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EntityCompleteSettings;
  return proto.reconcilio.EntityCompleteSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EntityCompleteSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EntityCompleteSettings}
 */
proto.reconcilio.EntityCompleteSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EntitySettings;
      reader.readMessage(value,proto.reconcilio.EntitySettings.deserializeBinaryFromReader);
      msg.setEntitySettings(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceid(value);
      break;
    case 3:
      var value = new proto.reconcilio.StopListItem;
      reader.readMessage(value,proto.reconcilio.StopListItem.deserializeBinaryFromReader);
      msg.addWorkspaceStopList(value);
      break;
    case 4:
      var value = new proto.reconcilio.Category;
      reader.readMessage(value,proto.reconcilio.Category.deserializeBinaryFromReader);
      msg.addWorkspaceCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EntityCompleteSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EntityCompleteSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EntityCompleteSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EntityCompleteSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntitySettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EntitySettings.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWorkspaceStopListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reconcilio.StopListItem.serializeBinaryToWriter
    );
  }
  f = message.getWorkspaceCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reconcilio.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional EntitySettings entity_settings = 1;
 * @return {?proto.reconcilio.EntitySettings}
 */
proto.reconcilio.EntityCompleteSettings.prototype.getEntitySettings = function() {
  return /** @type{?proto.reconcilio.EntitySettings} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EntitySettings, 1));
};


/**
 * @param {?proto.reconcilio.EntitySettings|undefined} value
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
*/
proto.reconcilio.EntityCompleteSettings.prototype.setEntitySettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
 */
proto.reconcilio.EntityCompleteSettings.prototype.clearEntitySettings = function() {
  return this.setEntitySettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EntityCompleteSettings.prototype.hasEntitySettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string workspaceId = 2;
 * @return {string}
 */
proto.reconcilio.EntityCompleteSettings.prototype.getWorkspaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
 */
proto.reconcilio.EntityCompleteSettings.prototype.setWorkspaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StopListItem workspace_stop_list = 3;
 * @return {!Array<!proto.reconcilio.StopListItem>}
 */
proto.reconcilio.EntityCompleteSettings.prototype.getWorkspaceStopListList = function() {
  return /** @type{!Array<!proto.reconcilio.StopListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.StopListItem, 3));
};


/**
 * @param {!Array<!proto.reconcilio.StopListItem>} value
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
*/
proto.reconcilio.EntityCompleteSettings.prototype.setWorkspaceStopListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reconcilio.StopListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.StopListItem}
 */
proto.reconcilio.EntityCompleteSettings.prototype.addWorkspaceStopList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reconcilio.StopListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
 */
proto.reconcilio.EntityCompleteSettings.prototype.clearWorkspaceStopListList = function() {
  return this.setWorkspaceStopListList([]);
};


/**
 * repeated Category workspace_categories = 4;
 * @return {!Array<!proto.reconcilio.Category>}
 */
proto.reconcilio.EntityCompleteSettings.prototype.getWorkspaceCategoriesList = function() {
  return /** @type{!Array<!proto.reconcilio.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.Category, 4));
};


/**
 * @param {!Array<!proto.reconcilio.Category>} value
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
*/
proto.reconcilio.EntityCompleteSettings.prototype.setWorkspaceCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reconcilio.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.Category}
 */
proto.reconcilio.EntityCompleteSettings.prototype.addWorkspaceCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reconcilio.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.EntityCompleteSettings} returns this
 */
proto.reconcilio.EntityCompleteSettings.prototype.clearWorkspaceCategoriesList = function() {
  return this.setWorkspaceCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntitySettingsByIdOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntitySettingsByIdOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && proto.reconcilio.EntityCompleteSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntitySettingsByIdOKResponse}
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntitySettingsByIdOKResponse;
  return proto.reconcilio.GetEntitySettingsByIdOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntitySettingsByIdOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntitySettingsByIdOKResponse}
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EntityCompleteSettings;
      reader.readMessage(value,proto.reconcilio.EntityCompleteSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntitySettingsByIdOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntitySettingsByIdOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EntityCompleteSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional EntityCompleteSettings settings = 1;
 * @return {?proto.reconcilio.EntityCompleteSettings}
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.prototype.getSettings = function() {
  return /** @type{?proto.reconcilio.EntityCompleteSettings} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EntityCompleteSettings, 1));
};


/**
 * @param {?proto.reconcilio.EntityCompleteSettings|undefined} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdOKResponse} returns this
*/
proto.reconcilio.GetEntitySettingsByIdOKResponse.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntitySettingsByIdOKResponse} returns this
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntitySettingsByIdOKResponse.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  WORKSPACE_NOT_FOUND_ERROR: 1,
  ENTITY_NOT_FOUND_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.GetEntitySettingsByIdErrorResponse.ErrorCase}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.GetEntitySettingsByIdErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.GetEntitySettingsByIdErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.GetEntitySettingsByIdErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceNotFoundError: (f = msg.getWorkspaceNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    entityNotFoundError: (f = msg.getEntityNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.GetEntitySettingsByIdErrorResponse;
  return proto.reconcilio.GetEntitySettingsByIdErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setWorkspaceNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setEntityNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.GetEntitySettingsByIdErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getEntityNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse workspace_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.getWorkspaceNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} returns this
*/
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.setWorkspaceNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.GetEntitySettingsByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} returns this
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.clearWorkspaceNotFoundError = function() {
  return this.setWorkspaceNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.hasWorkspaceNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse entity_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.getEntityNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} returns this
*/
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.setEntityNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.GetEntitySettingsByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} returns this
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.clearEntityNotFoundError = function() {
  return this.setEntityNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.hasEntityNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} returns this
*/
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.GetEntitySettingsByIdErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.GetEntitySettingsByIdErrorResponse} returns this
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.GetEntitySettingsByIdErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationReportDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationReportDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationReportDetailsRequest;
  return proto.reconcilio.EditReconciliationReportDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationReportDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string comment = 4;
 * @return {string}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsRequest} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.clearComment = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.EditReconciliationReportDetailsResponse.ResultCase}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.EditReconciliationReportDetailsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditReconciliationReportDetailsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationReportDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationReportDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.EditReconciliationReportDetailsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.EditReconciliationReportDetailsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationReportDetailsResponse;
  return proto.reconcilio.EditReconciliationReportDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.EditReconciliationReportDetailsOKResponse;
      reader.readMessage(value,proto.reconcilio.EditReconciliationReportDetailsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.EditReconciliationReportDetailsErrorResponse;
      reader.readMessage(value,proto.reconcilio.EditReconciliationReportDetailsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationReportDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.EditReconciliationReportDetailsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.EditReconciliationReportDetailsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional EditReconciliationReportDetailsOKResponse ok = 1;
 * @return {?proto.reconcilio.EditReconciliationReportDetailsOKResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.EditReconciliationReportDetailsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditReconciliationReportDetailsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.EditReconciliationReportDetailsOKResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsResponse} returns this
*/
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditReconciliationReportDetailsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsResponse} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EditReconciliationReportDetailsErrorResponse error = 2;
 * @return {?proto.reconcilio.EditReconciliationReportDetailsErrorResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.EditReconciliationReportDetailsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.EditReconciliationReportDetailsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.EditReconciliationReportDetailsErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsResponse} returns this
*/
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditReconciliationReportDetailsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsResponse} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationReportDetailsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationReportDetailsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsOKResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationReportDetailsOKResponse;
  return proto.reconcilio.EditReconciliationReportDetailsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsOKResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationReportDetailsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.EditReconciliationReportDetailsErrorResponse.ErrorCase}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.EditReconciliationReportDetailsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.EditReconciliationReportDetailsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.EditReconciliationReportDetailsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotChangeLockedReconciliationReportError: (f = msg.getCannotChangeLockedReconciliationReportError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.EditReconciliationReportDetailsErrorResponse;
  return proto.reconcilio.EditReconciliationReportDetailsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotChangeLockedReconciliationReportError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.EditReconciliationReportDetailsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotChangeLockedReconciliationReportError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} returns this
*/
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.EditReconciliationReportDetailsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse cannot_change_locked_reconciliation_report_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.getCannotChangeLockedReconciliationReportError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} returns this
*/
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.setCannotChangeLockedReconciliationReportError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.EditReconciliationReportDetailsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.clearCannotChangeLockedReconciliationReportError = function() {
  return this.setCannotChangeLockedReconciliationReportError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.hasCannotChangeLockedReconciliationReportError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} returns this
*/
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.EditReconciliationReportDetailsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.EditReconciliationReportDetailsErrorResponse} returns this
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.EditReconciliationReportDetailsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptAllSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptAllSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptAllSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptAllSuggestionsRequest}
 */
proto.reconcilio.AcceptAllSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptAllSuggestionsRequest;
  return proto.reconcilio.AcceptAllSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptAllSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptAllSuggestionsRequest}
 */
proto.reconcilio.AcceptAllSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptAllSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptAllSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptAllSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.AcceptAllSuggestionsRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.AcceptAllSuggestionsRequest} returns this
 */
proto.reconcilio.AcceptAllSuggestionsRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AcceptAllSuggestionsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.AcceptAllSuggestionsResponse.ResultCase}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.AcceptAllSuggestionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AcceptAllSuggestionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptAllSuggestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptAllSuggestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.AcceptAllSuggestionsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.AcceptAllSuggestionsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptAllSuggestionsResponse}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptAllSuggestionsResponse;
  return proto.reconcilio.AcceptAllSuggestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptAllSuggestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptAllSuggestionsResponse}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.AcceptAllSuggestionsOKResponse;
      reader.readMessage(value,proto.reconcilio.AcceptAllSuggestionsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.AcceptAllSuggestionsErrorResponse;
      reader.readMessage(value,proto.reconcilio.AcceptAllSuggestionsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptAllSuggestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptAllSuggestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.AcceptAllSuggestionsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.AcceptAllSuggestionsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional AcceptAllSuggestionsOKResponse ok = 1;
 * @return {?proto.reconcilio.AcceptAllSuggestionsOKResponse}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.AcceptAllSuggestionsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AcceptAllSuggestionsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.AcceptAllSuggestionsOKResponse|undefined} value
 * @return {!proto.reconcilio.AcceptAllSuggestionsResponse} returns this
*/
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AcceptAllSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptAllSuggestionsResponse} returns this
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AcceptAllSuggestionsErrorResponse error = 2;
 * @return {?proto.reconcilio.AcceptAllSuggestionsErrorResponse}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.AcceptAllSuggestionsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.AcceptAllSuggestionsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.AcceptAllSuggestionsErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptAllSuggestionsResponse} returns this
*/
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AcceptAllSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptAllSuggestionsResponse} returns this
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptAllSuggestionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptAllSuggestionsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptAllSuggestionsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptAllSuggestionsOKResponse}
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptAllSuggestionsOKResponse;
  return proto.reconcilio.AcceptAllSuggestionsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptAllSuggestionsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptAllSuggestionsOKResponse}
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptAllSuggestionsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptAllSuggestionsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  CREDIT_DEBIT_SUM_ERROR: 1,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.AcceptAllSuggestionsErrorResponse.ErrorCase}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.AcceptAllSuggestionsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.AcceptAllSuggestionsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.AcceptAllSuggestionsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditDebitSumError: (f = msg.getCreditDebitSumError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.AcceptAllSuggestionsErrorResponse;
  return proto.reconcilio.AcceptAllSuggestionsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCreditDebitSumError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.AcceptAllSuggestionsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditDebitSumError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse credit_debit_sum_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.getCreditDebitSumError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} returns this
*/
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.setCreditDebitSumError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.AcceptAllSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} returns this
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.clearCreditDebitSumError = function() {
  return this.setCreditDebitSumError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.hasCreditDebitSumError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} returns this
*/
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.AcceptAllSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} returns this
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} returns this
*/
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.AcceptAllSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.AcceptAllSuggestionsErrorResponse} returns this
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.AcceptAllSuggestionsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RefreshTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RefreshTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RefreshTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RefreshTokenRequest}
 */
proto.reconcilio.RefreshTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RefreshTokenRequest;
  return proto.reconcilio.RefreshTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RefreshTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RefreshTokenRequest}
 */
proto.reconcilio.RefreshTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RefreshTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RefreshTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RefreshTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.RefreshTokenResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.RefreshTokenResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.RefreshTokenResponse.ResultCase}
 */
proto.reconcilio.RefreshTokenResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.RefreshTokenResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.RefreshTokenResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RefreshTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RefreshTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RefreshTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.RefreshTokenOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.RefreshTokenErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RefreshTokenResponse}
 */
proto.reconcilio.RefreshTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RefreshTokenResponse;
  return proto.reconcilio.RefreshTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RefreshTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RefreshTokenResponse}
 */
proto.reconcilio.RefreshTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.RefreshTokenOKResponse;
      reader.readMessage(value,proto.reconcilio.RefreshTokenOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.RefreshTokenErrorResponse;
      reader.readMessage(value,proto.reconcilio.RefreshTokenErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RefreshTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RefreshTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RefreshTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.RefreshTokenOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.RefreshTokenErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional RefreshTokenOKResponse ok = 1;
 * @return {?proto.reconcilio.RefreshTokenOKResponse}
 */
proto.reconcilio.RefreshTokenResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.RefreshTokenOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.RefreshTokenOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.RefreshTokenOKResponse|undefined} value
 * @return {!proto.reconcilio.RefreshTokenResponse} returns this
*/
proto.reconcilio.RefreshTokenResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.RefreshTokenResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RefreshTokenResponse} returns this
 */
proto.reconcilio.RefreshTokenResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RefreshTokenResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RefreshTokenErrorResponse error = 2;
 * @return {?proto.reconcilio.RefreshTokenErrorResponse}
 */
proto.reconcilio.RefreshTokenResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.RefreshTokenErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.RefreshTokenErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.RefreshTokenErrorResponse|undefined} value
 * @return {!proto.reconcilio.RefreshTokenResponse} returns this
*/
proto.reconcilio.RefreshTokenResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.RefreshTokenResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RefreshTokenResponse} returns this
 */
proto.reconcilio.RefreshTokenResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RefreshTokenResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RefreshTokenOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RefreshTokenOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RefreshTokenOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authtoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RefreshTokenOKResponse}
 */
proto.reconcilio.RefreshTokenOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RefreshTokenOKResponse;
  return proto.reconcilio.RefreshTokenOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RefreshTokenOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RefreshTokenOKResponse}
 */
proto.reconcilio.RefreshTokenOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RefreshTokenOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RefreshTokenOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RefreshTokenOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string authToken = 1;
 * @return {string}
 */
proto.reconcilio.RefreshTokenOKResponse.prototype.getAuthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.RefreshTokenOKResponse} returns this
 */
proto.reconcilio.RefreshTokenOKResponse.prototype.setAuthtoken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reconcilio.RefreshTokenOKResponse} returns this
 */
proto.reconcilio.RefreshTokenOKResponse.prototype.clearAuthtoken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RefreshTokenOKResponse.prototype.hasAuthtoken = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.RefreshTokenErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.RefreshTokenErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  UNEXPECTED_ERROR: 1,
  INVALID_TOKEN_ERROR: 2
};

/**
 * @return {proto.reconcilio.RefreshTokenErrorResponse.ErrorCase}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.RefreshTokenErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.RefreshTokenErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RefreshTokenErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RefreshTokenErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidTokenError: (f = msg.getInvalidTokenError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RefreshTokenErrorResponse}
 */
proto.reconcilio.RefreshTokenErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RefreshTokenErrorResponse;
  return proto.reconcilio.RefreshTokenErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RefreshTokenErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RefreshTokenErrorResponse}
 */
proto.reconcilio.RefreshTokenErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidTokenError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RefreshTokenErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RefreshTokenErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RefreshTokenErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidTokenError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse unexpected_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.RefreshTokenErrorResponse} returns this
*/
proto.reconcilio.RefreshTokenErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.RefreshTokenErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RefreshTokenErrorResponse} returns this
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse invalid_token_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.getInvalidTokenError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.RefreshTokenErrorResponse} returns this
*/
proto.reconcilio.RefreshTokenErrorResponse.prototype.setInvalidTokenError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.RefreshTokenErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RefreshTokenErrorResponse} returns this
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.clearInvalidTokenError = function() {
  return this.setInvalidTokenError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RefreshTokenErrorResponse.prototype.hasInvalidTokenError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    financialTransactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    transactionType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransaction}
 */
proto.reconcilio.CreateLedgerTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransaction;
  return proto.reconcilio.CreateLedgerTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransaction}
 */
proto.reconcilio.CreateLedgerTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancialTransactionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinancialTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTransactionType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string financial_transaction_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.getFinancialTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransaction} returns this
 */
proto.reconcilio.CreateLedgerTransaction.prototype.setFinancialTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransaction} returns this
 */
proto.reconcilio.CreateLedgerTransaction.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransaction} returns this
 */
proto.reconcilio.CreateLedgerTransaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reference = 4;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransaction} returns this
 */
proto.reconcilio.CreateLedgerTransaction.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double amount = 5;
 * @return {number}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reconcilio.CreateLedgerTransaction} returns this
 */
proto.reconcilio.CreateLedgerTransaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string transaction_type = 6;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransaction.prototype.getTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransaction} returns this
 */
proto.reconcilio.CreateLedgerTransaction.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.CreateLedgerTransactionsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ledgerTransactionsList: jspb.Message.toObjectList(msg.getLedgerTransactionsList(),
    proto.reconcilio.CreateLedgerTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionsRequest}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionsRequest;
  return proto.reconcilio.CreateLedgerTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionsRequest}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateLedgerTransaction;
      reader.readMessage(value,proto.reconcilio.CreateLedgerTransaction.deserializeBinaryFromReader);
      msg.addLedgerTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLedgerTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reconcilio.CreateLedgerTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CreateLedgerTransaction ledger_transactions = 2;
 * @return {!Array<!proto.reconcilio.CreateLedgerTransaction>}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.getLedgerTransactionsList = function() {
  return /** @type{!Array<!proto.reconcilio.CreateLedgerTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconcilio.CreateLedgerTransaction, 2));
};


/**
 * @param {!Array<!proto.reconcilio.CreateLedgerTransaction>} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsRequest} returns this
*/
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.setLedgerTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reconcilio.CreateLedgerTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.CreateLedgerTransaction}
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.addLedgerTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reconcilio.CreateLedgerTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.CreateLedgerTransactionsRequest} returns this
 */
proto.reconcilio.CreateLedgerTransactionsRequest.prototype.clearLedgerTransactionsList = function() {
  return this.setLedgerTransactionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateLedgerTransactionsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.CreateLedgerTransactionsResponse.ResultCase}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.CreateLedgerTransactionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateLedgerTransactionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.CreateLedgerTransactionsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.CreateLedgerTransactionsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionsResponse}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionsResponse;
  return proto.reconcilio.CreateLedgerTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionsResponse}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.CreateLedgerTransactionsOKResponse;
      reader.readMessage(value,proto.reconcilio.CreateLedgerTransactionsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.CreateLedgerTransactionsErrorResponse;
      reader.readMessage(value,proto.reconcilio.CreateLedgerTransactionsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.CreateLedgerTransactionsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.CreateLedgerTransactionsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CreateLedgerTransactionsOKResponse ok = 1;
 * @return {?proto.reconcilio.CreateLedgerTransactionsOKResponse}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.CreateLedgerTransactionsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateLedgerTransactionsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.CreateLedgerTransactionsOKResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateLedgerTransactionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionsResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateLedgerTransactionsErrorResponse error = 2;
 * @return {?proto.reconcilio.CreateLedgerTransactionsErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.CreateLedgerTransactionsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.CreateLedgerTransactionsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.CreateLedgerTransactionsErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateLedgerTransactionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionsResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionsOKResponse}
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionsOKResponse;
  return proto.reconcilio.CreateLedgerTransactionsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionsOKResponse}
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRowIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 row_ids = 1;
 * @return {!Array<number>}
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.prototype.getRowIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsOKResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.prototype.setRowIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.CreateLedgerTransactionsOKResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.prototype.addRowIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.CreateLedgerTransactionsOKResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsOKResponse.prototype.clearRowIdsList = function() {
  return this.setRowIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  INVALID_BALANCES_ERROR: 2,
  CANNOT_CHANGE_LOCKED_RECONCILIATION_REPORT_ERROR: 3,
  UNEXPECTED_ERROR: 4
};

/**
 * @return {proto.reconcilio.CreateLedgerTransactionsErrorResponse.ErrorCase}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.CreateLedgerTransactionsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.CreateLedgerTransactionsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    invalidBalancesError: (f = msg.getInvalidBalancesError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotChangeLockedReconciliationReportError: (f = msg.getCannotChangeLockedReconciliationReportError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.CreateLedgerTransactionsErrorResponse;
  return proto.reconcilio.CreateLedgerTransactionsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setInvalidBalancesError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotChangeLockedReconciliationReportError(value);
      break;
    case 4:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.CreateLedgerTransactionsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getInvalidBalancesError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotChangeLockedReconciliationReportError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse invalid_balances_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.getInvalidBalancesError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.setInvalidBalancesError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.clearInvalidBalancesError = function() {
  return this.setInvalidBalancesError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.hasInvalidBalancesError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse cannot_change_locked_reconciliation_report_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.getCannotChangeLockedReconciliationReportError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.setCannotChangeLockedReconciliationReportError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.clearCannotChangeLockedReconciliationReportError = function() {
  return this.setCannotChangeLockedReconciliationReportError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.hasCannotChangeLockedReconciliationReportError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorResponse unexpected_error = 4;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 4));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
*/
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.reconcilio.CreateLedgerTransactionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.CreateLedgerTransactionsErrorResponse} returns this
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.CreateLedgerTransactionsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationReportRequest}
 */
proto.reconcilio.DeleteReconciliationReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationReportRequest;
  return proto.reconcilio.DeleteReconciliationReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationReportRequest}
 */
proto.reconcilio.DeleteReconciliationReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.DeleteReconciliationReportRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.DeleteReconciliationReportRequest} returns this
 */
proto.reconcilio.DeleteReconciliationReportRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteReconciliationReportResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteReconciliationReportResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.DeleteReconciliationReportResponse.ResultCase}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.DeleteReconciliationReportResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteReconciliationReportResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.DeleteReconciliationReportOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.DeleteReconciliationReportErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationReportResponse}
 */
proto.reconcilio.DeleteReconciliationReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationReportResponse;
  return proto.reconcilio.DeleteReconciliationReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationReportResponse}
 */
proto.reconcilio.DeleteReconciliationReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.DeleteReconciliationReportOKResponse;
      reader.readMessage(value,proto.reconcilio.DeleteReconciliationReportOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.DeleteReconciliationReportErrorResponse;
      reader.readMessage(value,proto.reconcilio.DeleteReconciliationReportErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.DeleteReconciliationReportOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.DeleteReconciliationReportErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteReconciliationReportOKResponse ok = 1;
 * @return {?proto.reconcilio.DeleteReconciliationReportOKResponse}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.DeleteReconciliationReportOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteReconciliationReportOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.DeleteReconciliationReportOKResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationReportResponse} returns this
*/
proto.reconcilio.DeleteReconciliationReportResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationReportResponse} returns this
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeleteReconciliationReportErrorResponse error = 2;
 * @return {?proto.reconcilio.DeleteReconciliationReportErrorResponse}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.DeleteReconciliationReportErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.DeleteReconciliationReportErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.DeleteReconciliationReportErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationReportResponse} returns this
*/
proto.reconcilio.DeleteReconciliationReportResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteReconciliationReportResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationReportResponse} returns this
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationReportResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationReportOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationReportOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationReportOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationReportOKResponse}
 */
proto.reconcilio.DeleteReconciliationReportOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationReportOKResponse;
  return proto.reconcilio.DeleteReconciliationReportOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationReportOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationReportOKResponse}
 */
proto.reconcilio.DeleteReconciliationReportOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationReportOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationReportOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationReportOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  CANNOT_DELETE_LOCKED_RECONCILIATION_REPORT_ERROR: 2,
  UNEXPECTED_ERROR: 3
};

/**
 * @return {proto.reconcilio.DeleteReconciliationReportErrorResponse.ErrorCase}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.DeleteReconciliationReportErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.DeleteReconciliationReportErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.DeleteReconciliationReportErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.DeleteReconciliationReportErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    cannotDeleteLockedReconciliationReportError: (f = msg.getCannotDeleteLockedReconciliationReportError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.DeleteReconciliationReportErrorResponse;
  return proto.reconcilio.DeleteReconciliationReportErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.DeleteReconciliationReportErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setCannotDeleteLockedReconciliationReportError(value);
      break;
    case 3:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.DeleteReconciliationReportErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.DeleteReconciliationReportErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getCannotDeleteLockedReconciliationReportError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.DeleteReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse cannot_delete_locked_reconciliation_report_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.getCannotDeleteLockedReconciliationReportError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.setCannotDeleteLockedReconciliationReportError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.DeleteReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.clearCannotDeleteLockedReconciliationReportError = function() {
  return this.setCannotDeleteLockedReconciliationReportError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.hasCannotDeleteLockedReconciliationReportError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ErrorResponse unexpected_error = 3;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 3));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse} returns this
*/
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.reconcilio.DeleteReconciliationReportErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.DeleteReconciliationReportErrorResponse} returns this
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.DeleteReconciliationReportErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconcilio.RejectSuggestionsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    suggestionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionsRequest}
 */
proto.reconcilio.RejectSuggestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionsRequest;
  return proto.reconcilio.RejectSuggestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionsRequest}
 */
proto.reconcilio.RejectSuggestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReconciliationReportId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuggestionIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSuggestionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string reconciliation_report_id = 1;
 * @return {string}
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.getReconciliationReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconcilio.RejectSuggestionsRequest} returns this
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.setReconciliationReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string suggestion_ids = 2;
 * @return {!Array<string>}
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.getSuggestionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reconcilio.RejectSuggestionsRequest} returns this
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.setSuggestionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reconcilio.RejectSuggestionsRequest} returns this
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.addSuggestionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconcilio.RejectSuggestionsRequest} returns this
 */
proto.reconcilio.RejectSuggestionsRequest.prototype.clearSuggestionIdsList = function() {
  return this.setSuggestionIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.RejectSuggestionsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.RejectSuggestionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  OK: 1,
  ERROR: 2
};

/**
 * @return {proto.reconcilio.RejectSuggestionsResponse.ResultCase}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.reconcilio.RejectSuggestionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.RejectSuggestionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ok: (f = msg.getOk()) && proto.reconcilio.RejectSuggestionsOKResponse.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.reconcilio.RejectSuggestionsErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionsResponse}
 */
proto.reconcilio.RejectSuggestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionsResponse;
  return proto.reconcilio.RejectSuggestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionsResponse}
 */
proto.reconcilio.RejectSuggestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconcilio.RejectSuggestionsOKResponse;
      reader.readMessage(value,proto.reconcilio.RejectSuggestionsOKResponse.deserializeBinaryFromReader);
      msg.setOk(value);
      break;
    case 2:
      var value = new proto.reconcilio.RejectSuggestionsErrorResponse;
      reader.readMessage(value,proto.reconcilio.RejectSuggestionsErrorResponse.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reconcilio.RejectSuggestionsOKResponse.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reconcilio.RejectSuggestionsErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional RejectSuggestionsOKResponse ok = 1;
 * @return {?proto.reconcilio.RejectSuggestionsOKResponse}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.getOk = function() {
  return /** @type{?proto.reconcilio.RejectSuggestionsOKResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.RejectSuggestionsOKResponse, 1));
};


/**
 * @param {?proto.reconcilio.RejectSuggestionsOKResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionsResponse} returns this
*/
proto.reconcilio.RejectSuggestionsResponse.prototype.setOk = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.RejectSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionsResponse} returns this
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.clearOk = function() {
  return this.setOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.hasOk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RejectSuggestionsErrorResponse error = 2;
 * @return {?proto.reconcilio.RejectSuggestionsErrorResponse}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.getError = function() {
  return /** @type{?proto.reconcilio.RejectSuggestionsErrorResponse} */ (
    jspb.Message.getWrapperField(this, proto.reconcilio.RejectSuggestionsErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.RejectSuggestionsErrorResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionsResponse} returns this
*/
proto.reconcilio.RejectSuggestionsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.RejectSuggestionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionsResponse} returns this
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionsOKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionsOKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionsOKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsOKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionsOKResponse}
 */
proto.reconcilio.RejectSuggestionsOKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionsOKResponse;
  return proto.reconcilio.RejectSuggestionsOKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionsOKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionsOKResponse}
 */
proto.reconcilio.RejectSuggestionsOKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionsOKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionsOKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionsOKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsOKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.reconcilio.RejectSuggestionsErrorResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.ErrorCase = {
  ERROR_NOT_SET: 0,
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: 1,
  UNEXPECTED_ERROR: 2
};

/**
 * @return {proto.reconcilio.RejectSuggestionsErrorResponse.ErrorCase}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.getErrorCase = function() {
  return /** @type {proto.reconcilio.RejectSuggestionsErrorResponse.ErrorCase} */(jspb.Message.computeOneofCase(this, proto.reconcilio.RejectSuggestionsErrorResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconcilio.RejectSuggestionsErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconcilio.RejectSuggestionsErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reconciliationReportNotFoundError: (f = msg.getReconciliationReportNotFoundError()) && common_pb.ErrorResponse.toObject(includeInstance, f),
    unexpectedError: (f = msg.getUnexpectedError()) && common_pb.ErrorResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconcilio.RejectSuggestionsErrorResponse}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconcilio.RejectSuggestionsErrorResponse;
  return proto.reconcilio.RejectSuggestionsErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconcilio.RejectSuggestionsErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconcilio.RejectSuggestionsErrorResponse}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setReconciliationReportNotFoundError(value);
      break;
    case 2:
      var value = new common_pb.ErrorResponse;
      reader.readMessage(value,common_pb.ErrorResponse.deserializeBinaryFromReader);
      msg.setUnexpectedError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconcilio.RejectSuggestionsErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconcilio.RejectSuggestionsErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconcilio.RejectSuggestionsErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReconciliationReportNotFoundError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
  f = message.getUnexpectedError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.ErrorResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrorResponse reconciliation_report_not_found_error = 1;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.getReconciliationReportNotFoundError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 1));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionsErrorResponse} returns this
*/
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.setReconciliationReportNotFoundError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.reconcilio.RejectSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionsErrorResponse} returns this
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.clearReconciliationReportNotFoundError = function() {
  return this.setReconciliationReportNotFoundError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.hasReconciliationReportNotFoundError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorResponse unexpected_error = 2;
 * @return {?proto.reconcilio.ErrorResponse}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.getUnexpectedError = function() {
  return /** @type{?proto.reconcilio.ErrorResponse} */ (
    jspb.Message.getWrapperField(this, common_pb.ErrorResponse, 2));
};


/**
 * @param {?proto.reconcilio.ErrorResponse|undefined} value
 * @return {!proto.reconcilio.RejectSuggestionsErrorResponse} returns this
*/
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.setUnexpectedError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.reconcilio.RejectSuggestionsErrorResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconcilio.RejectSuggestionsErrorResponse} returns this
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.clearUnexpectedError = function() {
  return this.setUnexpectedError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconcilio.RejectSuggestionsErrorResponse.prototype.hasUnexpectedError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.reconcilio);
