import { SubHeaders } from './Styles';
import BCategories from './BTHeader/BCategories';
import LCategories from './LTHeader/LCategories';

function SubHeadersBlock(): JSX.Element {
  return (
    <SubHeaders
      sx={{
        display: {
          xs: 'none',
          lg: 'flex',
        },
      }}
    >
      <BCategories />
      <LCategories />
    </SubHeaders>
  );
}
export default SubHeadersBlock;
