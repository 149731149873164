import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import { useWorkspacesViewModel } from '../../../../view-models/WorkspacesViewModel';
import StopListView from './component';

const StopListController = () => {
  const workspaceViewModel = useWorkspacesViewModel();

  return workspaceViewModel.workspaceSettings.data ? (
    <StopListView
      stopList={workspaceViewModel.workspaceSettings.data.stopList}
      deleteStopListItem={workspaceViewModel.deleteStopListItem}
      updateStopListItem={workspaceViewModel.updateStopListItem}
      showAddStopListItemDialog={workspaceViewModel.showAddStopListItemDialog}
      setStopListItemName={workspaceViewModel.setStopListItemName}
    />
  ) : (
    <CircularProgress />
  );
};

const StopList = observer(StopListController);

export default StopList;
