import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from './Input';
import { Title, Amounts, DateRange, Dash, LeftSpace, BottomSpace } from './Styles';
import ClearButton from '../../Buttons/ClearButton';

interface FilterPopUpDialogProps {
  open: boolean;
  areRequiredFieldsSet: boolean;
  searchFilter: string;
  date: JSX.Element;
  fromAmountFilter: number | null;
  toAmountFilter: number | null;
  setFromAmountFilter: (value: string) => void;
  setToAmountFilter: (value: string) => void;
  setSearchFilter: (value: string) => void;
  closeDialog: () => void;
  submit: () => void;
  clear: () => void;
}
function FilterPopUpDialog(props: FilterPopUpDialogProps) {
  const {
    open,
    areRequiredFieldsSet,
    searchFilter,
    date,
    fromAmountFilter,
    toAmountFilter,
    submit,
    closeDialog,
    setSearchFilter,
    setFromAmountFilter,
    setToAmountFilter,
    clear,
  } = props;

  return (
    <CreateDialog
      title="Filter"
      open={open}
      closeDialog={closeDialog}
      content={
        <>
          <Title>Date</Title>
          <DateRange>{date}</DateRange>
          <Title>Search</Title>
          <LeftSpace>
            <InputBlock
              input={
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={searchFilter}
                  onChange={(event) => setSearchFilter(event.target.value)}
                  sx={{ width: '100%' }}
                />
              }
            />
          </LeftSpace>

          <Title>Amount</Title>
          <Amounts>
            <LeftSpace>
              <InputBlock
                input={
                  <TextField
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    value={fromAmountFilter}
                    onChange={(event) => setFromAmountFilter(event.target.value)}
                    sx={{ width: '100%' }}
                  />
                }
              />
            </LeftSpace>
            <Dash>-</Dash>
            <InputBlock
              input={
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  value={toAmountFilter}
                  onChange={(event) => setToAmountFilter(event.target.value)}
                  sx={{ width: '100%' }}
                />
              }
            />
          </Amounts>
          <BottomSpace />
        </>
      }
      action={
        <>
          <ClearButton onClick={clear} />
          <FilledButton disabled={!areRequiredFieldsSet} content="Apply" onClick={submit} />
        </>
      }
    />
  );
}

export default FilterPopUpDialog;
