import React, { useState, JSX } from 'react';
import { Button } from '@mui/material';
import { Block, Content, Actions, Action, Category, Input, Items } from '../Styles';
import editIcon from '../../../../../assets/edit.svg';
import undoIcon from '../../../../../assets/undo.svg';
import saveIcon from '../../../../../assets/save.svg';
import deleteIcon from '../../../../../assets/delete.svg';
import ChunkedItems from './ChunkedItems/index';
import { CategoryItem } from '../../../../../models/Resources';

interface CategoryBlockProps {
  name: string;
  items: CategoryItem[];
  nameInput: JSX.Element;
  updateField: () => void;
  deleteField: () => void;
  showAddCategoryItemDialog: () => void;
  showEditCategoryItemDialog: (itemId: string) => void;
}

function chunk(array: CategoryItem[], size: number): CategoryItem[][] {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

const CategoryBlock = (props: CategoryBlockProps) => {
  const {
    name,
    items,
    nameInput,
    updateField,
    deleteField,
    showAddCategoryItemDialog,
    showEditCategoryItemDialog,
  } = props;
  const [edit, setEdit] = useState(false);
  const handleClick = () => {
    setEdit(!edit);
  };

  const smallScreenChunkedItems = chunk(items, 2);
  const mediumScreenChunkedItems = chunk(items, 4);

  return (
    <Block>
      <Content>
        <Category>{edit ? <Input>{nameInput}</Input> : name}</Category>
        <Items
          sx={{
            width: 200,
            display: {
              xs: 'block',
              lg: 'none',
            },
          }}
        >
          <ChunkedItems
            items={smallScreenChunkedItems}
            showEditCategoryItemDialog={showEditCategoryItemDialog}
          />
        </Items>
        <Items
          sx={{
            width: 400,
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          <ChunkedItems
            items={mediumScreenChunkedItems}
            showEditCategoryItemDialog={showEditCategoryItemDialog}
          />
        </Items>
      </Content>
      <Actions>
        {edit && (
          <Action>
            <button
              type="button" // Add the type attribute with the value "button"
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              aria-label="Save"
              onClick={() => {
                updateField();
                setEdit(false);
              }}
            >
              <img src={saveIcon} alt="save" />
            </button>
          </Action>
        )}
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={handleClick}
          >
            <img src={edit ? undoIcon : editIcon} alt="edit" />
          </button>
        </Action>
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={deleteField}
          >
            <img src={deleteIcon} alt="delete" />
          </button>
        </Action>

        <Button variant="text" onClick={showAddCategoryItemDialog}>
          Add Item
        </Button>
      </Actions>
    </Block>
  );
};

export default CategoryBlock;
