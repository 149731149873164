import React from 'react';
import { observer } from 'mobx-react-lite';
import NewWorkspaceDialog from './component';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';

function NewWorkspaceController() {
  const workspaceViewModel = useWorkspacesViewModel();

  return (
    <NewWorkspaceDialog
      open={workspaceViewModel.isCreateWorkspaceDialogVisible}
      workspaceCreationStatus={workspaceViewModel.workspaceCreationStatus}
      closeDialog={workspaceViewModel.hideCreateWorkspaceDialog}
      countries={workspaceViewModel.workspaceCountries}
      languages={workspaceViewModel.workspaceLanguages}
      submitAction={workspaceViewModel.createWorkspace}
      name={workspaceViewModel.name}
      setName={workspaceViewModel.setName}
      country={workspaceViewModel.country}
      setCountry={workspaceViewModel.setCountry}
      language={workspaceViewModel.language}
      setLanguage={workspaceViewModel.setLanguage}
      areRequiredFieldsSet={workspaceViewModel.areRequiredFieldsSet}
    />
  );
}

const NewWorkspace = observer(NewWorkspaceController);

export default NewWorkspace;
