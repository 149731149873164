/* eslint-disable no-restricted-syntax */
import {
  AmountFilter,
  DateFilter,
  FilterOptions,
  ManualTransaction,
} from '../models/ManualReconciliation';
import DateUtils from '../utils/dates';
import { getAbsoluteValue } from '../utils/number';
import ManualTransactionHelper from './ManualTransaction';

type TFilter = {
  filter: string;
  operator: string | null;
};

enum STANDARD_OPERATORS {
  EQUAL = '=',
  NOT_EQUAL = '<>',
  LIKE = '%',
  AND = '&',
}
const DEFAULT_OPERATOR = STANDARD_OPERATORS.LIKE;
const INVALID_DATE = 'Invalid Date';

export default class ManualTransactionFilter {
  public static runQuery(query: string, transactions: ManualTransaction[]): ManualTransaction[] {
    const filters = this.parseQuery(query);

    const filtered: ManualTransaction[] = [];
    for (const transaction of transactions) {
      const satisfiesCondition = this.applyOperators(transaction, filters);
      if (satisfiesCondition) {
        filtered.push(transaction);
      }
    }
    return filtered;
  }

  public static applyFilterOptions(
    filterOptions: FilterOptions,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    const transactionsDateFiltered = ManualTransactionFilter.applyDateFilter(
      filterOptions.date,
      transactions
    );
    const transactionsDateAmountFiltered = ManualTransactionFilter.applyAmountFilter(
      filterOptions.amount,
      transactionsDateFiltered
    );
    const transactionsFinalFiltered = ManualTransactionFilter.applySearchFilter(
      filterOptions.search,
      transactionsDateAmountFiltered
    );
    return transactionsFinalFiltered;
  }

  private static applySearchFilter(
    search: string,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    const filtered: ManualTransaction[] = [];
    for (const transaction of transactions) {
      const satisfiesCondition = this.satisfiesSearchFilter(transaction, search);
      if (satisfiesCondition) {
        filtered.push(transaction);
      }
    }
    return filtered;
  }

  private static areBothDatesSet(date: DateFilter): boolean {
    return date.from !== INVALID_DATE && date.to !== INVALID_DATE;
  }

  private static getTransactionsWithDatesBetween(
    date: DateFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    const dateFrom = DateUtils.ParseCustomDate(date.from);
    const dateTo = DateUtils.ParseCustomDate(date.to);
    // get transactions with dates between from and to
    const filteredTransactions = transactions.filter((transaction) => {
      const formattedTransactionDate = DateUtils.FormatYearToYYYY(transaction.date);
      const transactionDate = DateUtils.ParseCustomDate(formattedTransactionDate);
      return (
        transactionDate.getTime() >= dateFrom.getTime() &&
        transactionDate.getTime() <= dateTo.getTime()
      );
    });
    return filteredTransactions;
  }

  private static isFromDateSet(date: DateFilter): boolean {
    return date.from !== INVALID_DATE;
  }

  private static getTransactionsWithDatesGreaterThanFrom(
    date: DateFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    const dateFrom = DateUtils.ParseCustomDate(date.from);
    // get transactions with dates greater than from
    const filteredTransactions = transactions.filter((transaction) => {
      const formattedTransactionDate = DateUtils.FormatYearToYYYY(transaction.date);
      const transactionDate = DateUtils.ParseCustomDate(formattedTransactionDate);
      return transactionDate.getTime() >= dateFrom.getTime();
    });
    return filteredTransactions;
  }

  private static isToDateSet(date: DateFilter): boolean {
    return date.to !== INVALID_DATE;
  }

  private static getTransactionsWithDatesLessThanTo(
    date: DateFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    const dateTo = DateUtils.ParseCustomDate(date.to);
    // get transactions with dates less than to
    const filteredTransactions = transactions.filter((transaction) => {
      const formattedTransactionDate = DateUtils.FormatYearToYYYY(transaction.date);
      const transactionDate = DateUtils.ParseCustomDate(formattedTransactionDate);
      return transactionDate.getTime() <= dateTo.getTime();
    });
    return filteredTransactions;
  }

  private static applyDateFilter(
    date: DateFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    if (this.areBothDatesSet(date)) {
      return this.getTransactionsWithDatesBetween(date, transactions);
    }
    if (this.isFromDateSet(date)) {
      return this.getTransactionsWithDatesGreaterThanFrom(date, transactions);
    }
    if (this.isToDateSet(date)) {
      return this.getTransactionsWithDatesLessThanTo(date, transactions);
    }
    return transactions;
  }

  private static areBothAmountsSet(amount: AmountFilter): boolean {
    return amount.from !== null && amount.to !== null;
  }

  private static getTransactionsWithAmountsBetween(
    amount: AmountFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    if (amount.from === amount.to) {
      // get transactions with amounts equal to absolute amount
      const absoluteAmount = getAbsoluteValue(amount.from!);
      const filteredTransactions = transactions.filter((transaction) => {
        if (ManualTransactionHelper.isCredit(transaction)) {
          const absoluteCredit = getAbsoluteValue(transaction.creditNumber);
          return absoluteCredit === absoluteAmount;
        }
        const absoluteDebit = getAbsoluteValue(transaction.debitNumber);
        return absoluteDebit === absoluteAmount;
      });
      return filteredTransactions;
    }
    // get transactions with amounts between from and to
    const filteredTransactions = transactions.filter((transaction) => {
      if (ManualTransactionHelper.isCredit(transaction)) {
        return transaction.creditNumber >= amount.from! && transaction.creditNumber <= amount.to!;
      }
      return transaction.debitNumber >= amount.from! && transaction.debitNumber <= amount.to!;
    });
    return filteredTransactions;
  }

  private static isFromAmountSet(amount: AmountFilter): boolean {
    return amount.from !== null;
  }

  private static getTransactionsWithAmountsGreaterThanFrom(
    amount: AmountFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    // get transactions with amounts greater than from
    const filteredTransactions = transactions.filter((transaction) => {
      if (ManualTransactionHelper.isCredit(transaction)) {
        return transaction.creditNumber >= amount.from!;
      }
      return transaction.debitNumber >= amount.from!;
    });
    return filteredTransactions;
  }

  private static isToAmountSet(amount: AmountFilter): boolean {
    return amount.to !== null;
  }

  private static getTransactionsWithAmountsLessThanTo(
    amount: AmountFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    // get transactions with dates less than to
    const filteredTransactions = transactions.filter((transaction) => {
      if (ManualTransactionHelper.isCredit(transaction)) {
        return transaction.creditNumber <= amount.to!;
      }
      return transaction.debitNumber <= amount.to!;
    });
    return filteredTransactions;
  }

  private static applyAmountFilter(
    amount: AmountFilter,
    transactions: ManualTransaction[]
  ): ManualTransaction[] {
    if (this.areBothAmountsSet(amount)) {
      return this.getTransactionsWithAmountsBetween(amount, transactions);
    }
    if (this.isFromAmountSet(amount)) {
      return this.getTransactionsWithAmountsGreaterThanFrom(amount, transactions);
    }
    if (this.isToAmountSet(amount)) {
      return this.getTransactionsWithAmountsLessThanTo(amount, transactions);
    }
    return transactions;
  }

  private static satisfiesSearchFilter(transaction: ManualTransaction, filter: string): boolean {
    const words = this.splitWordsOfStringFields(transaction);
    for (const word of words) {
      if (word.includes(filter)) {
        return true;
      }
    }
    return false;
  }

  private static applyOperators(transaction: ManualTransaction, filters: TFilter[]): boolean {
    for (const filter of filters) {
      const satisfiesCondition = this.applyOperator(transaction, filter);
      if (!satisfiesCondition) {
        return false;
      }
    }
    return true;
  }

  private static applyOperator(transaction: ManualTransaction, filter: TFilter): boolean {
    switch (filter.operator) {
      case STANDARD_OPERATORS.EQUAL:
        return this.applyEqualOperator(transaction, filter.filter);
      case STANDARD_OPERATORS.NOT_EQUAL:
        return this.applyNotEqualOperator(transaction, filter.filter);
      case STANDARD_OPERATORS.LIKE:
        return this.applyLikeOperator(transaction, filter.filter);
      default:
        return this.applyLikeOperator(transaction, filter.filter);
    }
  }

  private static splitBySpace(str: string): string[] {
    return str.split(' ');
  }

  private static splitWordsIntoOneArray(transaction: ManualTransaction): string[] {
    const words: string[] = [];
    const trimmedDescription = this.trim(transaction.description);
    const trimmedReference = this.trim(transaction.reference);
    const trimmedFinancialTransactionId = this.trim(transaction.financialTransactionId);
    const trimmedCredit = this.trim(transaction.credit);
    const trimmedDebit = this.trim(transaction.debit);
    words.push(...this.splitBySpace(trimmedDescription));
    words.push(...this.splitBySpace(trimmedReference));
    words.push(trimmedFinancialTransactionId);
    words.push(trimmedCredit);
    words.push(trimmedDebit);
    return words;
  }

  private static splitWordsOfStringFields(transaction: ManualTransaction): string[] {
    const words: string[] = [];
    const trimmedDescription = this.trim(transaction.description);
    const trimmedReference = this.trim(transaction.reference);
    const trimmedFinancialTransactionId = this.trim(transaction.financialTransactionId);
    words.push(...this.splitBySpace(trimmedDescription));
    words.push(...this.splitBySpace(trimmedReference));
    words.push(trimmedFinancialTransactionId);
    return words;
  }

  private static indicatesDate(filter: string): boolean {
    return filter.includes('/');
  }

  private static applyEqualOperator(transaction: ManualTransaction, filter: string): boolean {
    if (this.indicatesDate(filter)) {
      return transaction.date === filter;
    }
    const words = this.splitWordsIntoOneArray(transaction);
    return words.includes(filter);
  }

  private static applyNotEqualOperator(transaction: ManualTransaction, filter: string): boolean {
    if (this.indicatesDate(filter)) {
      return transaction.date !== filter;
    }
    const words = this.splitWordsIntoOneArray(transaction);
    return !words.includes(filter);
  }

  private static applyLikeOperator(transaction: ManualTransaction, filter: string): boolean {
    if (this.indicatesDate(filter)) {
      return transaction.date.includes(filter);
    }
    const words = this.splitWordsIntoOneArray(transaction);
    for (const word of words) {
      if (word.includes(filter)) {
        return true;
      }
    }
    return false;
  }

  private static getFilters(query: string): string[] {
    if (query.includes(STANDARD_OPERATORS.AND)) {
      const filters = query.split(STANDARD_OPERATORS.AND);
      const lastIndex = filters.length - 1;
      if (filters[lastIndex].length === 0) {
        filters.pop();
      }
      return filters;
    }

    return [query];
  }

  private static removeCommas(query: string): string {
    return query.replace(/,/g, '');
  }

  private static trim(query: string): string {
    const trimmed = query.trim();
    const trimmedAndCommaRemoved = this.removeCommas(trimmed);
    const final = trimmedAndCommaRemoved.toLowerCase();
    return final;
  }

  private static parseQuery(query: string): TFilter[] {
    const filters = this.getFilters(query);
    const parsedFilters: TFilter[] = [];
    for (const filter of filters) {
      let operator = DEFAULT_OPERATOR;
      let parsedFilter = this.trim(filter);
      if (filter.includes(STANDARD_OPERATORS.LIKE)) {
        operator = STANDARD_OPERATORS.LIKE;
        parsedFilter = filter.replace(STANDARD_OPERATORS.LIKE, '');
      } else if (filter.includes(STANDARD_OPERATORS.NOT_EQUAL)) {
        operator = STANDARD_OPERATORS.NOT_EQUAL;
        parsedFilter = filter.replace(STANDARD_OPERATORS.NOT_EQUAL, '');
      } else if (filter.includes(STANDARD_OPERATORS.EQUAL)) {
        operator = STANDARD_OPERATORS.EQUAL;
        parsedFilter = filter.replace(STANDARD_OPERATORS.EQUAL, '');
      }
      parsedFilters.push({ filter: parsedFilter, operator });
    }
    return parsedFilters;
  }
}
