export type SuggestedMatchTransactionPrimitives = {
  date: string;
  description: string;
  reference: string;
  debit: number;
  credit: number;
};

export type SuggestedMatchPrimitives = {
  id: string;
  bankStatementTransactions: SuggestedMatchTransactionPrimitives[];
  ledgerTransactions: SuggestedMatchTransactionPrimitives[];
};

export type SuggestedMatchTransaction = {
  date: string;
  description: string;
  reference: string;
  debit: string;
  credit: string;
};

export type SuggestedMatch = {
  id: string;
  bankStatementTransactions: SuggestedMatchTransaction[];
  ledgerTransactions: SuggestedMatchTransaction[];
};

export type TSuggestedMatchesStatus = 'idle' | 'pending' | 'success' | 'cached' | 'error';

export const SuggestedMatchesStatuses = {
  IDLE: 'idle' as TSuggestedMatchesStatus,
  LOADING: 'pending' as TSuggestedMatchesStatus,
  SUCCESS: 'success' as TSuggestedMatchesStatus,
  CACHED: 'cached' as TSuggestedMatchesStatus,
  ERROR: 'error' as TSuggestedMatchesStatus,
};
