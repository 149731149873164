import React, { ReactNode, createContext, useContext } from 'react';
// import ResourcesRepo from "../infra/repos/ResourcesRepository";
import GRPCReconciliationReportService from '../infra/services/GRPCReconciliationReportService';
import FileStorageService from '../infra/services/FileStorageService';
import IamService from '../infra/services/IamService';
import { IamRepository, IIamRepository } from '../infra/repositories/iam';
// import InMemoryReconciliationReportService from "../infra/services/InMemoryReconciliationReportService";
import GRPCResourcesService from '../infra/services/GRPCResourcesService';
import { IResourcesRepository, ResourcesRepository } from '../infra/repositories/resources';
import ReconciliationReportRepository, {
  IReconciliationReportRepository,
} from '../infra/repositories/reconciliation-report';

export interface AppContext {
  reconciliationReportRepository: IReconciliationReportRepository;
  resourcesRepository: IResourcesRepository;
  iamRepository: IIamRepository;
}

const fileStorageService = new FileStorageService();
const iamService = new IamService();
const grpcResourcesService = new GRPCResourcesService();
const reconciliationReportService = new GRPCReconciliationReportService();
const iamRepository = new IamRepository(iamService);
const resourcesRepository = new ResourcesRepository(grpcResourcesService);
const reconciliationReportRepository = new ReconciliationReportRepository(
  reconciliationReportService,
  fileStorageService
);

export const initialContext: AppContext = {
  reconciliationReportRepository,
  resourcesRepository,
  iamRepository,
};

interface DIProviderProps {
  children: ReactNode;
}
export const DIContext = createContext<AppContext>(initialContext);

const DIProvider: React.FC<DIProviderProps> = ({ children }) => (
  <DIContext.Provider value={initialContext}>{children}</DIContext.Provider>
);

function useDIContext() {
  const context = useContext(DIContext);
  if (!context) {
    throw new Error('useDIContext must be used within an DIProvider');
  }
  return context;
}

function useIamRepository() {
  return useDIContext().iamRepository;
}

function useResourcesRepository() {
  return useDIContext().resourcesRepository;
}

function useReconciliationReportRepository() {
  return useDIContext().reconciliationReportRepository;
}

export {
  useIamRepository,
  useResourcesRepository,
  useReconciliationReportRepository,
  useDIContext,
  DIProvider,
};
export default DIContext;
