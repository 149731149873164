import React, { createContext, useContext, useMemo } from 'react';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { useReconciliationReportRepository } from '../context/DI';
import { DOCUMENT_TYPE, FILE_TYPE, UploadFileParams } from '../models/UploadFileData';
import { CONCRETE_TYPES, UPLOADING_STATUS } from '../models/Document';
import {
  BaseError,
  IReconciliationReportRepository,
} from '../infra/repositories/reconciliation-report';
import { EventBus, Events } from '../Events';
import { BitloopsError, ErrorCodes } from '../Errors';
import { fetchedState, initState, State, STATUS_STATES } from '../types';
import { RECONCILIATION_ACCOUNT_TYPES, ReconciliationAccount } from '../models/Resources';
import { ReconciliationAccountFetchError } from '../infra/repositories/resources';

export enum FILE_MIME_TYPE {
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

interface IDocumentsViewModel {
  bankDocumentId: string;
  ledgerDocumentId: string;
  uploadingStatus: UPLOADING_STATUS;
  filesUploaded: boolean;
  uploadDocument: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
}

class DocumentsViewModel implements IDocumentsViewModel {
  private readonly CALLER = 'DocumentsViewModel';

  bankDocumentId: string = '';

  bankDocumentIdNotInOurTemplate: string = '';

  ledgerDocumentIdNotInOurTemplate: string = '';

  ledgerDocumentId: string = '';

  uploadingStatus: UPLOADING_STATUS = UPLOADING_STATUS.IDLE;

  @observable
  filesUploaded: boolean = false;

  bankUploadFileStatus: STATUS_STATES = 'idle';

  ledgerUploadFileStatus: STATUS_STATES = 'idle';

  ledgerNotInOurTemplateUploadFileStatus: STATUS_STATES = 'idle';

  bankNotInOurTemplateUploadFileStatus: STATUS_STATES = 'idle';

  isBankMissingOB: boolean = false;

  isLedgerMissingOB: boolean = false;

  isLedgerDocumentFormatTypeMissing: boolean = false;

  isLedgerOBApplied: boolean = false;

  isBankOBApplied: boolean = false;

  isBankDocumentFormatTypeMissing: boolean = false;

  isLedgerDebitsCreditsSwappedError: boolean = false;

  isBankDebitsCreditsSwappedError: boolean = false;

  private _reconciliationAccount: State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  > = initState(null);

  constructor(private reconciliationReportRepository: IReconciliationReportRepository) {
    makeAutoObservable(this);
    EventBus.subscribe(Events.LEDGER_DEBIT_INCREASED, this.ledgerDebitIncreasedDecreasedHandler);
    EventBus.subscribe(Events.LEDGER_DEBIT_DECREASED, this.ledgerDebitIncreasedDecreasedHandler);
    EventBus.subscribe(Events.BANK_DEBIT_INCREASED, this.bankDebitIncreasedDecreasedHandler);
    EventBus.subscribe(Events.BANK_DEBIT_DECREASED, this.bankDebitIncreasedDecreasedHandler);
    EventBus.subscribe(Events.RECONCILIATION_ACCOUNT_FETCH, this.reconciliationAccountFetchHandler);
  }

  get isLedgerDocumentFormatTypeVisible(): boolean {
    return (
      this.isLedgerDocumentFormatTypeMissing ||
      this.isLedgerFormatTypeMissingBecauseOfSupplierOBMissing
    );
  }

  get isBankDocumentFormatTypeVisible(): boolean {
    return (
      this.isBankDocumentFormatTypeMissing || this.isBankFormatTypeMissingBecauseOfCustomerOBMissing
    );
  }

  private get isLedgerFormatTypeMissingBecauseOfSupplierOBMissing(): boolean {
    return this.isLedgerOBApplied && this.isSupplierAccount;
  }

  private get isBankFormatTypeMissingBecauseOfCustomerOBMissing(): boolean {
    return this.isBankOBApplied && this.isCustomerAccount;
  }

  private get isSupplierAccount(): boolean {
    if (this._reconciliationAccount.data) {
      return (
        this._reconciliationAccount.data.reconciliationType ===
        RECONCILIATION_ACCOUNT_TYPES.SUPPLIER
      );
    }
    return false;
  }

  private get isCustomerAccount(): boolean {
    if (this._reconciliationAccount.data) {
      return (
        this._reconciliationAccount.data.reconciliationType ===
        RECONCILIATION_ACCOUNT_TYPES.CUSTOMER
      );
    }
    return false;
  }

  @action
  uploadDocument = async (
    file: File,
    type: DOCUMENT_TYPE,
    concreteType: CONCRETE_TYPES
  ): Promise<void> => {
    let documentId = '';
    try {
      const fileType = this.fileTypeToEnum(file.type);
      const uploadInput: UploadFileParams = {
        file,
        fileName: file.name,
        fileType,
        documentType: type,
        concreteType,
        workspaceId: '',
        entityId: '',
        accountId: '',
      };
      this.uploadingStatus = UPLOADING_STATUS.UPLOADING;
      documentId = await this.reconciliationReportRepository.uploadFile(uploadInput);
      const { openingBalance, isOpeningBalanceSet } =
        await this.reconciliationReportRepository.validateDocument(documentId, concreteType);
      this.uploadingStatus = UPLOADING_STATUS.UPLOADED;
      console.log(`Document ID: ${documentId}`);
      if (type === DOCUMENT_TYPE.BANK_STATEMENT) this.updateBankDocumentId(documentId);
      else if (type === DOCUMENT_TYPE.LEDGER) this.updateLedgerDocumentId(documentId);
      // Validate document
      this.updateFilesUploaded();
      console.log('openingBalance set', isOpeningBalanceSet);
      console.log('openingBalance', openingBalance);
      EventBus.emit(
        Events.DOCUMENT_UPLOADED,
        fetchedState({ openingBalance, isOpeningBalanceSet, type })
      );
      console.log(`Document validated: ${documentId}`);
    } catch (error) {
      console.error('documents view model error', error);
      const err: BitloopsError = error as BitloopsError;
      console.log('BitloopsError error', err);
      this.handleError(err, type, documentId);
      this.uploadingStatus = UPLOADING_STATUS.ERROR;
    }
  };

  private handleError = (error: BitloopsError, type: DOCUMENT_TYPE, documentId: string): void => {
    switch (error.code) {
      case ErrorCodes.MISSING_OPENING_BALANCE: {
        if (type === DOCUMENT_TYPE.BANK_STATEMENT) {
          this.updateBankDocumentId(documentId);
          this.updateMissingBankOB();
        } else if (type === DOCUMENT_TYPE.LEDGER) {
          this.updateLedgerDocumentId(documentId);
          this.updateMissingLedgerOB();
        }
        break;
      }
      case ErrorCodes.MISSING_DOCUMENT_FORMAT_TYPE: {
        if (type === DOCUMENT_TYPE.BANK_STATEMENT) {
          this.updateBankDocumentId(documentId);
          this.isBankDocumentFormatTypeMissing = true;
        } else if (type === DOCUMENT_TYPE.LEDGER) {
          this.updateLedgerDocumentId(documentId);
          this.isLedgerDocumentFormatTypeMissing = true;
        }
        break;
      }
      case ErrorCodes.DEBIT_CREDIT_SWAPPED_ERROR: {
        if (type === DOCUMENT_TYPE.BANK_STATEMENT) {
          this.isBankDebitsCreditsSwappedError = true;
        } else if (type === DOCUMENT_TYPE.LEDGER) {
          this.isLedgerDebitsCreditsSwappedError = true;
        }
        break;
      }
      default:
        EventBus.emit(Events.ERROR, error);
    }
  };

  @computed
  get bankDocumentUploaded(): boolean {
    return this.bankDocumentId !== '';
  }

  @computed
  get ledgerDocumentUploaded(): boolean {
    return this.ledgerDocumentId !== '';
  }

  @computed
  get bankDocumentNotInOurTemplateUploaded(): boolean {
    return this.bankDocumentIdNotInOurTemplate !== '';
  }

  @computed
  get ledgerDocumentNotInOurTemplateUploaded(): boolean {
    return this.ledgerDocumentIdNotInOurTemplate !== '';
  }

  @action
  setBankUploadFileStatus = (status: STATUS_STATES): void => {
    this.bankUploadFileStatus = status;
  };

  @action
  setLedgerUploadFileStatus = (status: STATUS_STATES): void => {
    this.ledgerUploadFileStatus = status;
  };

  @action
  setBankNotInOurTemplateUploadFileStatus = (status: STATUS_STATES): void => {
    this.bankNotInOurTemplateUploadFileStatus = status;
  };

  @action
  setLedgerNotInOurTemplateUploadFileStatus = (status: STATUS_STATES): void => {
    this.ledgerNotInOurTemplateUploadFileStatus = status;
  };

  @action
  reconciliationAccountFetchHandler = (
    repoResponse: State<ReconciliationAccount | null, ReconciliationAccountFetchError>
  ) => {
    if (repoResponse.data) {
      this._reconciliationAccount = repoResponse;
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  uploadDocumentNotInOurTemplate = async (
    file: File,
    type: DOCUMENT_TYPE,
    concreteType: CONCRETE_TYPES
  ): Promise<void> => {
    try {
      const fileType = this.fileTypeToEnumForDifferentTemplate(file.type);
      const uploadInput: UploadFileParams = {
        file,
        fileName: file.name,
        fileType,
        documentType: type,
        concreteType,
        workspaceId: '',
        entityId: '',
        accountId: '',
        isNotInStandardTemplateFormat: true,
      };
      this.uploadingStatus = UPLOADING_STATUS.UPLOADING;
      // const documentId =
      //   await this.reconciliationReportRepository.uploadFileNotInOurTemplate(uploadInput);
      const documentId = await this.reconciliationReportRepository.uploadFile(uploadInput);
      this.uploadingStatus = UPLOADING_STATUS.UPLOADED;
      console.log(`Document ID: ${documentId}`);
      if (type === DOCUMENT_TYPE.BANK_STATEMENT)
        this.updateBankDocumentIdNotInOurTemplate(documentId);
      else if (type === DOCUMENT_TYPE.LEDGER)
        this.updateLedgerDocumentIdNotInOurTemplate(documentId);

      this.updateFilesUploaded();
    } catch (error) {
      console.error(error);
      const err: BitloopsError = error as BitloopsError;
      EventBus.emit(Events.ERROR, err);
      this.uploadingStatus = UPLOADING_STATUS.ERROR;
    }
  };

  @action
  private updateBankDocumentIdNotInOurTemplate = (bankDocumentId: string): void => {
    this.bankDocumentIdNotInOurTemplate = bankDocumentId;
  };

  @action
  private updateLedgerDocumentIdNotInOurTemplate = (ledgerDocumentId: string): void => {
    this.ledgerDocumentIdNotInOurTemplate = ledgerDocumentId;
  };

  @action
  private updateBankDocumentId = (bankDocumentId: string): void => {
    this.bankDocumentId = bankDocumentId;
  };

  @action
  private updateLedgerDocumentId = (ledgerDocumentId: string): void => {
    this.ledgerDocumentId = ledgerDocumentId;
  };

  @action
  private ledgerDebitIncreasedDecreasedHandler = (_stateResponse: State<null, BaseError>) => {
    if (this.isLedgerDocumentFormatTypeMissing) this.isLedgerDocumentFormatTypeMissing = false;
    if (this.isLedgerFormatTypeMissingBecauseOfSupplierOBMissing) this.isLedgerOBApplied = false;
    this.uploadingStatus = UPLOADING_STATUS.UPLOADED;
    this.updateFilesUploaded();
  };

  @action
  private bankDebitIncreasedDecreasedHandler = (_stateResponse: State<null, BaseError>) => {
    if (this.isBankDocumentFormatTypeMissing) this.isBankDocumentFormatTypeMissing = false;
    if (this.isBankFormatTypeMissingBecauseOfCustomerOBMissing) this.isBankOBApplied = false;
    this.uploadingStatus = UPLOADING_STATUS.UPLOADED;
    this.updateFilesUploaded();
  };

  @action
  applyLedgerOpeningBalance = (): void => {
    this.isLedgerMissingOB = false;
    this.isLedgerOBApplied = true;
    if (!this.isLedgerFormatTypeMissingBecauseOfSupplierOBMissing) {
      this.uploadingStatus = UPLOADING_STATUS.UPLOADED;
    }
    this.updateFilesUploaded();
    EventBus.emit(Events.LEDGER_OB_UPLOAD_FINISHED, fetchedState(null));
  };

  @action
  applyBankOpeningBalance = (): void => {
    this.isBankMissingOB = false;
    this.isBankOBApplied = true;
    if (!this.isBankFormatTypeMissingBecauseOfCustomerOBMissing) {
      this.uploadingStatus = UPLOADING_STATUS.UPLOADED;
    }
    this.updateFilesUploaded();
    EventBus.emit(Events.BANK_OB_UPLOAD_FINISHED, fetchedState(null));
  };

  @action
  applyLedgerDebitsCreditsSwapped = (): void => {
    this.isLedgerDebitsCreditsSwappedError = false;
  };

  @action
  applyBankDebitsCreditsSwapped = (): void => {
    this.isBankDebitsCreditsSwappedError = false;
  };

  @action
  hideLedgerDebitsCreditsSwappedDialog = (): void => {
    this.isLedgerDebitsCreditsSwappedError = false;
  };

  @action
  hideBankDebitsCreditsSwappedDialog = (): void => {
    this.isBankDebitsCreditsSwappedError = false;
  };

  @action
  hideBankMissingOBDialog = (): void => {
    this.isBankMissingOB = false;
    this.bankDocumentId = '';
    this.updateFilesUploaded();
    EventBus.emit(Events.BANK_OB_UPLOAD_FINISHED, fetchedState(null));
  };

  @action
  hideLedgerMissingOBDialog = (): void => {
    this.isLedgerMissingOB = false;
    this.ledgerDocumentId = '';
    this.updateFilesUploaded();
    EventBus.emit(Events.LEDGER_OB_UPLOAD_FINISHED, fetchedState(null));
  };

  @action
  hideLedgerDocumentFormatTypeMissingDialog = (): void => {
    if (this.isLedgerDocumentFormatTypeMissing) this.isLedgerDocumentFormatTypeMissing = false;
    if (this.isLedgerFormatTypeMissingBecauseOfSupplierOBMissing) this.isLedgerOBApplied = false;
    this.ledgerDocumentId = '';
    this.updateFilesUploaded();
  };

  @action
  hideBankDocumentFormatTypeMissingDialog = (): void => {
    if (this.isBankDocumentFormatTypeMissing) this.isBankDocumentFormatTypeMissing = false;
    if (this.isBankFormatTypeMissingBecauseOfCustomerOBMissing) this.isBankOBApplied = false;
    this.bankDocumentId = '';
    this.updateFilesUploaded();
  };

  @action
  private updateMissingBankOB = (): void => {
    this.isBankMissingOB = true;
  };

  @action
  private updateMissingLedgerOB = (): void => {
    this.isLedgerMissingOB = true;
  };

  @action
  private updateFilesUploaded = (): void => {
    const bankDocumentUploaded =
      this.bankDocumentId !== '' || this.bankDocumentIdNotInOurTemplate !== '';
    const ledgerDocumentUploaded =
      this.ledgerDocumentId !== '' || this.ledgerDocumentIdNotInOurTemplate !== '';
    if (
      bankDocumentUploaded &&
      ledgerDocumentUploaded &&
      this.uploadingStatus === UPLOADING_STATUS.UPLOADED
    ) {
      this.filesUploaded = true;
    }
  };

  @action
  public clearDocuments = (): void => {
    this.bankDocumentId = '';
    this.bankDocumentIdNotInOurTemplate = '';
    this.ledgerDocumentId = '';
    this.ledgerDocumentIdNotInOurTemplate = '';
    this.resetState();
  };

  @action
  private resetState = (): void => {
    this.bankUploadFileStatus = 'idle';
    this.ledgerUploadFileStatus = 'idle';
    this.ledgerNotInOurTemplateUploadFileStatus = 'idle';
    this.bankNotInOurTemplateUploadFileStatus = 'idle';
    this.isBankMissingOB = false;
    this.isLedgerMissingOB = false;
    this.isLedgerDocumentFormatTypeMissing = false;
    this.isLedgerOBApplied = false;
    this.uploadingStatus = UPLOADING_STATUS.IDLE;
    this.filesUploaded = false;
    this.isBankOBApplied = false;
    this.isBankDocumentFormatTypeMissing = false;
  };

  private fileTypeToEnum = (fileType: string): FILE_TYPE => {
    switch (fileType) {
      //   case 'application/pdf':
      //     return FILE_TYPE.PDF;
      //   case 'text/csv':
      //     return FILE_TYPE.CSV;
      case FILE_MIME_TYPE.XLSX:
      case FILE_MIME_TYPE.XLS:
        return FILE_TYPE.EXCEL;
      default:
        throw new Error(`File type ${fileType} not supported.`);
    }
  };

  private fileTypeToEnumForDifferentTemplate = (fileType: string): FILE_TYPE => {
    switch (fileType) {
      case FILE_MIME_TYPE.PDF:
        return FILE_TYPE.PDF;
      //   case 'text/csv':
      //     return FILE_TYPE.CSV;
      case FILE_MIME_TYPE.XLSX:
      case FILE_MIME_TYPE.XLS:
        return FILE_TYPE.EXCEL;
      case FILE_MIME_TYPE.DOC:
      case FILE_MIME_TYPE.DOCX:
        return FILE_TYPE.WORD;
      case FILE_MIME_TYPE.JPEG:
      case FILE_MIME_TYPE.PNG:
        return FILE_TYPE.IMAGE;
      default:
        throw new Error(`File type ${fileType} not supported.`);
    }
  };
}

const DocumentsViewModelContext = createContext<DocumentsViewModel | null>(null);

interface DocumentsViewModelProviderProps {
  children: React.ReactNode;
}

const DocumentsViewModelProvider: React.FC<DocumentsViewModelProviderProps> = ({ children }) => {
  const reconciliationReportRepository = useReconciliationReportRepository();
  const documentsViewModel = useMemo(
    () => new DocumentsViewModel(reconciliationReportRepository),
    [reconciliationReportRepository]
  );

  return (
    <DocumentsViewModelContext.Provider value={documentsViewModel}>
      {children}
    </DocumentsViewModelContext.Provider>
  );
};

const useDocumentsViewModel = () => {
  const viewModel = useContext(DocumentsViewModelContext);
  if (!viewModel) throw new Error('No DocumentsViewModel provided');
  return viewModel;
};

export {
  DocumentsViewModel,
  DocumentsViewModelProvider,
  DocumentsViewModelContext,
  useDocumentsViewModel,
};
