import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Categories,
  SpanDate,
  SpanDescription,
  SpanDebit,
  SpanCredit,
  Date,
  Description,
  Debit,
  Credit,
} from './Styles';

interface CategoriesProps {
  isDateAscending: boolean;
  isCreditAscending: boolean;
  isDebitAscending: boolean;
  toggleCreditDropdown: () => void;
  toggleDebitDropdown: () => void;
  toggleDateDropdown: () => void;
}

function Header(props: CategoriesProps): JSX.Element {
  const {
    isDateAscending,
    toggleDateDropdown,
    isCreditAscending,
    isDebitAscending,
    toggleCreditDropdown,
    toggleDebitDropdown,
  } = props;

  return (
    <Categories>
      <SpanDate onClick={toggleDateDropdown}>
        <Date>
          {isDateAscending ? (
            <KeyboardArrowDownIcon style={{ verticalAlign: 'middle' }} />
          ) : (
            <KeyboardArrowUpIcon style={{ verticalAlign: 'middle' }} />
          )}
          Date
        </Date>
      </SpanDate>
      <SpanDescription>
        <Description>Description / Reference</Description>
      </SpanDescription>
      {/* <SpanFinancialTransactionId>
        <FinancialTransactionId>Id</FinancialTransactionId>
      </SpanFinancialTransactionId>
      <SpanReference>
        <Reference>Reference</Reference>
      </SpanReference> */}
      <SpanDebit onClick={toggleDebitDropdown}>
        <Debit>
          {isDebitAscending ? (
            <KeyboardArrowDownIcon style={{ verticalAlign: 'middle' }} />
          ) : (
            <KeyboardArrowUpIcon style={{ verticalAlign: 'middle' }} />
          )}
          Debit
        </Debit>
      </SpanDebit>
      <SpanCredit onClick={toggleCreditDropdown}>
        <Credit>
          {isCreditAscending ? (
            <KeyboardArrowDownIcon style={{ verticalAlign: 'middle' }} />
          ) : (
            <KeyboardArrowUpIcon style={{ verticalAlign: 'middle' }} />
          )}
          Credit
        </Credit>
      </SpanCredit>
    </Categories>
  );
}
export default Header;
