import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface PreviousReconciliationReportProps {
  id: string;
  previousReconciliationReportNames: { id: string; name: string }[];
  setPreviousReconciliationReport: (id: string | null) => void;
}

function PreviousReconciliationReportDialog(props: PreviousReconciliationReportProps) {
  const { previousReconciliationReportNames, setPreviousReconciliationReport } = props;

  return (
    <Autocomplete
      disablePortal
      options={previousReconciliationReportNames}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ width: 325 }}
      onChange={(e, value) => setPreviousReconciliationReport(value!.id)} // Add the onChange event handler
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose Previous Reconciliation Report"
          InputLabelProps={{
          style: { color: 'var(--ledger-color' }, // Set the style here to make the label blue
          }}
        />
      )}
    />
  );
}

export default PreviousReconciliationReportDialog;
