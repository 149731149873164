import { observer } from 'mobx-react-lite';
import BankMissingOpeningBalanceDialog from '../component';
import { useDocumentsViewModel } from '../../../../view-models/DocumentsViewModel';
import { useReconciliationReportViewModel } from '../../../../view-models/ReconciliationReportViewModel';

function BankMissingOpeningBalanceController() {
  const documentsViewModel = useDocumentsViewModel()!;
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <BankMissingOpeningBalanceDialog
      open={documentsViewModel.isBankMissingOB}
      openingBalance={reconciliationReportViewModel.bankOpeningBalance}
      submitAction={documentsViewModel.applyBankOpeningBalance}
      areRequiredFieldsSet={
        reconciliationReportViewModel.areBankMissingOpeningBalanceRequiredFieldsSet
      }
      closeDialog={documentsViewModel.hideBankMissingOBDialog}
      editOpeningBalance={reconciliationReportViewModel.editBankOpeningBalance}
    />
  );
}

const BankMissingOB = observer(BankMissingOpeningBalanceController);

export default BankMissingOB;
