import { styled } from '@mui/material/styles';

export const Action = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});

export const Section = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const Header = styled('div')({
  width: '100%',
  textAlign: 'center',
  fontSize: '32px',
});

export const TransactionsHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  fontSize: '24px',
  width: '100%',
  textAlign: 'left',
  marginBottom: '10px',
  alignItems: 'center',
});

export const Transactions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const BTransactions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'var(--bank-color)',
});

export const LTransactions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'var(--ledger-color)',
});

export const Categories: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  // padding: '0px 10px',
  boxSizing: 'border-box',
  width: 'var(--transaction-max-width)',
  margin: '23px 0px 0px 0px',
});

export const SpanDate: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  marginRight: '1rem',
  width: '5rem',
});

export const Date: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--date-width-sm)',
});

export const SpanDescription: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  marginRight: '4rem',
  width: '9.4rem',
});

export const Description: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--description-width-sm)',
});

export const SpanDebit: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  marginLeft: '4rem',
});

export const Debit: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'calc(var(--amount-width-sm) + 2rem)',
});

export const SpanCredit: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  marginRight: '20rem',
});

export const Credit: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'calc(var(--amount-width-sm) + 0.7rem)',
});
