import * as React from 'react';
import { List, ListItem } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { ListItemStyles } from './Styles';

function BottomListItems(props: {
  drawerOpen: boolean;
  navigateToWorkspace: () => void;
  navigateToConvertersAndTemplates: () => void;
}) {
  const { drawerOpen, navigateToWorkspace, navigateToConvertersAndTemplates } = props;

  return (
    <List>
      <ListItem
        sx={{
          padding: '0px',
          '& .MuiListItemButton-root:hover': {
            backgroundColor: (theme) => theme.palette.grey[900],
            '&, & .MuiListItemIcon-root': {
              color: '#fff',
            },
          },
        }}
      >
        <ListItemButton
          onClick={navigateToWorkspace}
          sx={{ justifyContent: 'center', ...ListItemStyles }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              marginRight: drawerOpen ? 2 : 0,
            }}
          >
            <SettingsIcon />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Workspace Settings" />}
        </ListItemButton>
      </ListItem>
      <ListItem
        sx={{
          padding: '0px',
          marginTop: '8px',
        }}
      >
        <ListItemButton
          onClick={navigateToConvertersAndTemplates}
          sx={{ justifyContent: 'center', ...ListItemStyles }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              marginRight: drawerOpen ? 2 : 0,
            }}
          >
            <ArticleIcon />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Converters & Templates" />}
        </ListItemButton>
      </ListItem>
    </List>
  );
}

export default BottomListItems;
