import { CustomTextField, SearchContainer } from './Styles';

const Search = ({ onChange, value }: { onChange: React.ChangeEventHandler; value: string }) => {
  //   <input className={search} type="text" onChange={onChange} placeholder="Search..." />
  const val = value.length > 0 ? value : '';
  return (
    <SearchContainer>
      <CustomTextField
        id="outlined-search"
        className="blackClear"
        placeholder="Search..."
        type="search"
        variant="outlined"
        onChange={onChange}
        value={val}
      />
    </SearchContainer>
  );
};
export default Search;
