import Button from '@mui/material/Button';
import unreconcileIcon from '../../assets/unreconcile.svg';
import { buttonStyles } from './Styles';
import { RejectButtonProps } from '../../types';

const greyColor = {
  backgroundColor: '#7C7C7A',
};

function RejectButton(props: RejectButtonProps): JSX.Element {
  const { onClick, disabled, reconciliationReportId, suggestedMatchId } = props;

  const handleClick = () => {
    onClick(reconciliationReportId, suggestedMatchId);
  };

  return (
    <Button
      variant="contained"
      style={{ ...buttonStyles, ...greyColor }}
      startIcon={<img src={unreconcileIcon} alt="Unreconciled Icon" />}
      disabled={disabled}
      onClick={handleClick}
    >
      Reject
    </Button>
  );
}

export default RejectButton;
