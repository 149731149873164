import TextField from '@mui/material/TextField';
import TableBodyWorkspace from './Styles';
import WorkspaceListItem from './WorkspaceListItem';
import { InheritedStopListItem } from '../../../../../models/Resources';

interface WorkspaceListItemsProps {
  workspaceStopList: InheritedStopListItem[];
  enableWorkspaceStopListItem: (id: string) => Promise<void>;
  disableWorkspaceStopListItem: (id: string) => Promise<void>;
}

function WorkspaceListItems(props: WorkspaceListItemsProps): JSX.Element {
  const { workspaceStopList, enableWorkspaceStopListItem, disableWorkspaceStopListItem } = props;

  return (
    <TableBodyWorkspace>
      {/* <WorkspaceTab>{workspaceName}</WorkspaceTab> */}
      {workspaceStopList.map((item) => (
        <WorkspaceListItem
          key={item.id}
          item={item}
          nameInput={<TextField id="outlined-basic" variant="outlined" defaultValue={item.value} />}
          enableWorkspaceStopListItem={enableWorkspaceStopListItem}
          disableWorkspaceStopListItem={disableWorkspaceStopListItem}
        />
      ))}
      {/* <Button variant="text" onClick={addWorkspaceStopListItem}>
        Add Item
      </Button> */}
    </TableBodyWorkspace>
  );
}
export default WorkspaceListItems;
