import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { ListItemStyles } from '../Styles';

function AddEntityItem(props: { drawerOpen: boolean; showCreateEntityDialog: () => void }) {
  const { drawerOpen, showCreateEntityDialog } = props;

  return (
    <ListItemButton
      onClick={showCreateEntityDialog}
      sx={{
        justifyContent: 'center',
        ...ListItemStyles,
        marginBottom: 4,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          marginRight: drawerOpen ? 2 : 0,
        }}
      >
        <AddIcon />
      </ListItemIcon>
      {drawerOpen && <ListItemText primary="Create Entity" />}
    </ListItemButton>
  );
}

export default AddEntityItem;
