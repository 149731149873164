import CircularProgress from '@mui/material/CircularProgress';
import { LoaderBlock } from './Styles';

function Loader(): JSX.Element {
  return (
    <LoaderBlock>
      <CircularProgress />
    </LoaderBlock>
  );
}
export default Loader;
