import React from 'react';
import Categories from './Categories';
import { Header, HeaderName } from './Styles';

interface HeaderBlockProps {
  name: string;
  type: 'bank' | 'ledger';
  action?: React.ReactNode;
}
function HeaderBlock(props: HeaderBlockProps): JSX.Element {
  const { name, type, action } = props;
  return (
    <Header>
      <HeaderName
        style={{
          color: type === 'bank' ? 'var(--bank-color)' : 'var(--ledger-color)',
        }}
      >
        {name}
        {action && action}
      </HeaderName>
      <Categories type={type} />
    </Header>
  );
}
export default HeaderBlock;
