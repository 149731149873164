import * as React from 'react';
import { List } from './Styles';
import {
  CurrentEntityStatus,
  Entity,
  EntitySlim,
  ReconciliationAccount,
  ReconciliationAccountSlim,
} from '../../../models/Resources';
import TopListItems from './TopListItems';

export default function ListItems(props: {
  entities: EntitySlim[];
  drawerOpen: boolean;
  reconciliationAccountsList: ReconciliationAccountSlim[];
  goHome: () => void;
  openDrawer: () => void;
  currentEntity: Entity | null;
  selectEntity: (entityId: string) => void;
  currentEntityStatus: CurrentEntityStatus;
  currentReconciliationAccount: ReconciliationAccount | null;
  selectReconciliationAccount: (reconciliationAccountId: string) => void;
  navigateToEntity: (entityId: string) => void;
  navigateToWorkspace: () => void;
  navigateToReconciliationAccount: (reconciliationAccountId: string) => void;
  showCreateEntityDialog: () => void;
  currentReconciliationAccountsType: string;
  selectReconciliationAccountsType: (reconciliationAccountTypes: string) => void;
  navigateToConvertersAndTemplates: () => void;
}) {
  const {
    entities,
    drawerOpen,
    openDrawer,
    goHome,
    currentEntity,
    selectEntity,
    currentEntityStatus,
    currentReconciliationAccount,
    selectReconciliationAccount,
    navigateToEntity,
    navigateToWorkspace,
    navigateToReconciliationAccount,
    showCreateEntityDialog,
    reconciliationAccountsList,
    currentReconciliationAccountsType,
    selectReconciliationAccountsType,
    navigateToConvertersAndTemplates,
  } = props;

  return (
    <List>
      <TopListItems
        entities={entities}
        drawerOpen={drawerOpen}
        openDrawer={openDrawer}
        goHome={goHome}
        currentEntity={currentEntity}
        currentEntityStatus={currentEntityStatus}
        selectEntity={selectEntity}
        currentReconciliationAccount={currentReconciliationAccount}
        selectReconciliationAccount={selectReconciliationAccount}
        navigateToEntity={navigateToEntity}
        navigateToReconciliationAccount={navigateToReconciliationAccount}
        reconciliationAccountsList={reconciliationAccountsList}
        showCreateEntityDialog={showCreateEntityDialog}
        navigateToWorkspace={navigateToWorkspace}
        currentReconciliationAccountsType={currentReconciliationAccountsType}
        selectReconciliationAccountsType={selectReconciliationAccountsType}
        navigateToConvertersAndTemplates={navigateToConvertersAndTemplates}
      />
    </List>
  );
}
