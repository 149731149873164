import FilledButton from '../../../Buttons/FilledButton';
import { RejectAllBlock } from './Styles';

interface RejectAllButtonProps {
  onClick: () => void;
}

function RejectAllButton(props: RejectAllButtonProps): JSX.Element {
  const { onClick } = props;
  return (
    <RejectAllBlock>
      <FilledButton content="Reject all" onClick={onClick} />
    </RejectAllBlock>
  );
}
export default RejectAllButton;
