import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from '../Input';

interface NewCategoryItemDialogProps {
  open: boolean;
  categoryItemName: string;
  setCategoryItemName: (value: string) => void;
  areRequiredFieldsSet: boolean;
  closeDialog: () => void;
  submit: () => void;
}
function NewCategoryItemDialog(props: NewCategoryItemDialogProps) {
  const {
    open,
    categoryItemName: categoryName,
    setCategoryItemName: setCategoryName,
    areRequiredFieldsSet,
    submit,
    closeDialog,
  } = props;

  return (
    <CreateDialog
      title="Add Category Item"
      open={open}
      closeDialog={closeDialog}
      content={
        <InputBlock
          name="Category Item Name"
          input={
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={categoryName}
              onChange={(event) => setCategoryName(event.target.value)}
            />
          }
          required
        />
      }
      action={<FilledButton disabled={!areRequiredFieldsSet} content="Add" onClick={submit} />}
    />
  );
}

export default NewCategoryItemDialog;
