import Button from '@mui/material/Button';
import { buttonStyles } from './Styles';
import { FilledButtonProps } from '../../types';

const bankColor = {
  backgroundColor: 'var(--bank-color)',
};
function FilledButton(props: FilledButtonProps): JSX.Element {
  const { content, icon, disabled, bank, onClick } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      style={bank ? { ...buttonStyles, ...bankColor } : buttonStyles}
      startIcon={icon && <img src={icon} alt="Button Icon" />}
      disabled={disabled}
      onClick={() => onClick?.()}
    >
      {content}
    </Button>
  );
}

export default FilledButton;
