import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIamViewModel } from '../view-models/IamViewModel';

interface ProtectedRouteProps {
  element: React.FunctionComponentElement<any>;
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const { element } = props;
  const iamViewModel = useIamViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    const { isAuthenticated } = iamViewModel;
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [iamViewModel.isAuthenticated]);

  return iamViewModel.isAuthenticated ? element : null;
}

export default ProtectedRoute;
