import { styled } from '@mui/material/styles';

export const TransactionsBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
});

export const BTransactions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  color: 'var(--bank-color)',
});

export const LTransactions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  color: 'var(--ledger-color)',
});

export const LTransaction = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
export const LTAction = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});
