import { TextField, Autocomplete } from '@mui/material';
import React from 'react';
// import InputBlock from '../../CreateDialog/Input';
import FieldBlock from './Field';
import SimpleButton from '../../Buttons/SimpleButton';
import { TabActions } from './Styles';
import deleteIcon from '../../../assets/delete.svg';
import { EditEntityParams } from '../../../models/Resources';

const ENTITY_DELETION_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this entity?';
const ENTITY_DELETION_CONFIRMATION_BUTTON_MESSAGE = 'Delete';

interface ProfileTabContentProps {
  countries: string[];
  languages: string[];
  updateParams: EditEntityParams;
  updateEntityName: (name: string) => void;
  updateCountry: (country: string) => void;
  updateLanguage: (language: string) => void;
  updateEntity: (updateParams: Partial<EditEntityParams>) => void;
  deleteEntity: () => Promise<void>;
  showConfirmationDialog: (
    message: string,
    buttonMessage: string,
    confirmationAction: () => void
  ) => void;
}

function ProfileTabContent(props: ProfileTabContentProps): JSX.Element {
  const {
    updateParams,
    countries,
    languages,
    updateCountry,
    updateLanguage,
    updateEntityName,
    updateEntity,
    deleteEntity,
    showConfirmationDialog,
  } = props;
  return (
    <>
      <FieldBlock
        name="Name"
        field={updateParams.name}
        fieldInput={
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => updateEntityName(e.target.value)}
            defaultValue={updateParams.name}
          />
        }
        updateParam={{ name: updateParams.name }}
        updateField={updateEntity}
      />
      <FieldBlock
        name="Country"
        field={updateParams.country}
        fieldInput={
          <Autocomplete
            options={countries}
            sx={{ width: 400 }}
            value={updateParams.country || null}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e, value) => {
              if (value) updateCountry(value);
            }}
          />
        }
        updateParam={{ country: updateParams.country }}
        updateField={updateEntity}
      />
      <FieldBlock
        name="Language"
        field={updateParams.language}
        fieldInput={
          <Autocomplete
            options={languages}
            sx={{ width: 300 }}
            value={updateParams.language || null}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e, value) => {
              if (value) updateLanguage(value);
            }}
          />
        }
        updateParam={{ language: updateParams.language }}
        updateField={updateEntity}
      />
      <TabActions>
        {/* <SimpleButton icon={deleteIcon} onClick={deleteEntity} content="Delete Entity" /> */}

        <SimpleButton
          icon={deleteIcon}
          onClick={() => {
            showConfirmationDialog(
              ENTITY_DELETION_CONFIRMATION_MESSAGE,
              ENTITY_DELETION_CONFIRMATION_BUTTON_MESSAGE,
              deleteEntity
            );
          }}
          content="Delete Entity"
        />
      </TabActions>
    </>
  );
}
export default ProfileTabContent;
