import { TextField } from '@mui/material';

import FieldBlock from './Field';
import { currentReconciliationAccount } from '../../../mockData/resources';
import { TabActions } from './Styles';
import SimpleButton from '../../Buttons/SimpleButton';
import deleteIcon from '../../../assets/delete.svg';
import { EditReconciliationAccountParams } from '../../../models/Resources';

interface ProfileTabContentProps {
  workspaceName: string;
  entityName: string;
  updateParams: EditReconciliationAccountParams;
  updateReconciliationAccountName: (name: string) => void;
  updateCounterpartyContactName: (counterpartyName: string) => void;
  updateCounterpartyContactEmail: (counterpartyEmail: string) => void;
  updateReconciliationAccount: (updateParams: Partial<EditReconciliationAccountParams>) => void;
  deleteReconciliationAccount: () => Promise<void>;
}

function ProfileTabContent(props: ProfileTabContentProps): JSX.Element {
  const {
    workspaceName,
    entityName,
    updateParams,
    updateReconciliationAccountName,
    updateCounterpartyContactName,
    updateCounterpartyContactEmail,
    updateReconciliationAccount,
    deleteReconciliationAccount,
  } = props;
  return (
    <>
      <FieldBlock name="Workspace" field={workspaceName} uneditable />
      <FieldBlock name="Entity" field={entityName} uneditable />
      <FieldBlock
        name="Reconciliation Type"
        field={currentReconciliationAccount.reconciliationType}
        uneditable
      />
      <FieldBlock
        name="Name"
        field={updateParams.name}
        fieldInput={
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => updateReconciliationAccountName(e.target.value)}
            defaultValue={updateParams.name}
          />
        }
        updateParam={{ name: updateParams.name }}
        updateField={updateReconciliationAccount}
      />
      <FieldBlock
        name="Counterparty Contact Name"
        field={updateParams.counterpartyContactName}
        fieldInput={
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => updateCounterpartyContactName(e.target.value)}
            defaultValue={updateParams.counterpartyContactName}
          />
        }
        updateParam={{ counterpartyContactName: updateParams.counterpartyContactName }}
        updateField={updateReconciliationAccount}
      />
      <FieldBlock
        name="Counterparty Contact Email"
        field={updateParams.counterpartyContactEmail}
        fieldInput={
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => updateCounterpartyContactEmail(e.target.value)}
            defaultValue={updateParams.counterpartyContactEmail}
          />
        }
        updateParam={{ counterpartyContactEmail: updateParams.counterpartyContactEmail }}
        updateField={updateReconciliationAccount}
      />
      <TabActions>
        <SimpleButton
          icon={deleteIcon}
          onClick={deleteReconciliationAccount}
          content="Delete Reconciliation Account"
        />
      </TabActions>
    </>
  );
}
export default ProfileTabContent;
