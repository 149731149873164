import { styled } from '@mui/material';

export const AcceptAllBlock: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  padding: '0px',
  boxSizing: 'border-box',
  // margin: '0px 0px 0px 40px',
  widht: '100%',
  alignSelf: 'flex-end',
});
