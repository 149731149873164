import React, { useState, useEffect, JSX } from 'react';
import { Block, Title, Field, Actions, Action, Content } from './Styles';
import editIcon from '../../../assets/edit.svg';
import undoIcon from '../../../assets/undo.svg';
import saveIcon from '../../../assets/save.svg';
import { EditReconciliationAccountParams } from '../../../models/Resources';

// import { Content } from '../../Buttons/FixedBottomButton/Styles';
interface FieldBlockProps {
  name: string;
  field: string;
  fieldInput?: JSX.Element;
  updateField?: (value: Partial<EditReconciliationAccountParams>) => void;
  updateParam?: Partial<EditReconciliationAccountParams>;
  uneditable?: boolean;
}
function FieldBlock(props: FieldBlockProps) {
  const { name, field, fieldInput, updateField, uneditable, updateParam } = props;
  const [edit, setEdit] = useState(false);
  const [fieldValue, setFieldValue] = useState<JSX.Element | undefined | string>(field);
  const handleClick = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    if (uneditable) {
      setFieldValue(field);
    } else if (edit) {
      setFieldValue(fieldInput);
    } else {
      setFieldValue(field);
    }
  }, [uneditable, edit, field, fieldInput]);

  return (
    <Block
      sx={{
        width: {
          xs: 'var(--tab-content-min-width)',
          lg: 'var(--tab-content-max-width)',
        },
      }}
    >
      <Content>
        <Title>{name}:</Title>
        <Field>{fieldValue}</Field>
      </Content>
      {!uneditable && updateParam && updateField && (
        <Actions>
          {edit && (
            <Action>
              <button
                type="button" // Add the type attribute with the value "button"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                }}
                aria-label="Save"
                onClick={() => {
                  updateField(updateParam);
                  setEdit(false);
                }}
              >
                <img src={saveIcon} alt="save" />
              </button>
            </Action>
          )}
          <Action>
            <button
              type="button" // Add the type attribute with the value "button"
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              aria-label="Edit"
              onClick={handleClick}
            >
              <img src={edit ? undoIcon : editIcon} alt="edit" />
            </button>
          </Action>
        </Actions>
      )}
    </Block>
  );
}

export default FieldBlock;
