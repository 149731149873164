import Button from '@mui/material/Button';
import reconcileIcon from '../../assets/reconcile.svg';
import { buttonStyles } from './Styles';
import { AcceptButtonProps } from '../../types';

function AcceptButton(props: AcceptButtonProps): JSX.Element {
  const { onClick, disabled, reconciliationReportId, suggestedMatchId } = props;

  const handleClick = () => {
    onClick(reconciliationReportId, suggestedMatchId);
  };

  return (
    <Button
      variant="contained"
      color="success"
      style={buttonStyles}
      startIcon={<img src={reconcileIcon} alt="Reconcile Icon" />}
      disabled={disabled}
      onClick={handleClick}
    >
      Accept
    </Button>
  );
}

export default AcceptButton;
