import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { ListItemStyles } from '../Styles';

export function HomeItem(props: { drawerOpen: boolean; goHome: () => void }) {
  const { drawerOpen, goHome } = props;
  return (
    <ListItemButton onClick={goHome} sx={{ justifyContent: 'center',   ...ListItemStyles, }}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          marginRight: drawerOpen ? 2 : 0,
        }}
      >
        <HomeIcon />
      </ListItemIcon>
      {drawerOpen && <ListItemText primary="Dashboard" />}
    </ListItemButton>
  );
}
