import AmountSubheader from '../AmountSubheader/AmountSubheader';
import Subheader from '../Subheader/Subheader';
import { UnreconciledTransactionsBlocksProps } from '../../../types';
import { UnreconciledTransactionsAccordion } from './Accordion/Accordion';
import { Empty, Layout, PercentageInfo } from './Styles';

function UnreconciledTransactions(props: UnreconciledTransactionsBlocksProps): JSX.Element {
  const {
    unreconciledTransactionsBlocks,
    ledgerEndBalance,
    bankClosingBalance,
    reconciliationDifference,
    ledgerEndOfPeriodBalanceName,
    externalClosingBalanceName,
    externalStatementTransactionsPercentage,
  } = props;

  return (
    <>
      {unreconciledTransactionsBlocks === null && (
        <Empty>Unreconciled transactions will be loaded soon.</Empty>
      )}
      {unreconciledTransactionsBlocks && unreconciledTransactionsBlocks.length === 0 && (
        <Empty>No unreconciled transactions found.</Empty>
      )}
      {unreconciledTransactionsBlocks && unreconciledTransactionsBlocks.length > 0 && (
        <Layout>
          <PercentageInfo>
            {externalStatementTransactionsPercentage !== null
              ? `(Automatic Reconciliation: ${externalStatementTransactionsPercentage.toFixed(2)}%)`
              : ''}
          </PercentageInfo>
          <AmountSubheader title={ledgerEndOfPeriodBalanceName} amount={ledgerEndBalance} />
          {/* <Subheader title="Unreconciled Transactions" /> */}
          <UnreconciledTransactionsAccordion
            unreconciledTransactionsBlocks={unreconciledTransactionsBlocks}
          />
          <AmountSubheader title={externalClosingBalanceName} amount={bankClosingBalance} />
          <AmountSubheader title="Reconciliation Difference" amount={reconciliationDifference} />
        </Layout>
      )}
    </>
  );
}
export default UnreconciledTransactions;
