import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { Users, Actions } from './Styles';
import SimpleButton from '../../Buttons/SimpleButton';
import UserBlock from './User/User';
import InviteUsersDialog from './Dialog';
import { User, UserRoleType } from '../../../models/User';

interface UsersTabContentProps {
  users: User[];
  userAvailableRoles: UserRoleType[];
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
}

function UsersTabContent(props: UsersTabContentProps): JSX.Element {
  const { users, userAvailableRoles, createUserWithEmailAndRoleId } = props;
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  return (
    <Users>
      {users.map((user, index) => (
        <UserBlock
          key={index}
          name={user.name ? user.name : ''}
          email={user.email ? user.email : ''}
          avatar=""
          nameInput={<TextField id="outlined-basic" variant="outlined" defaultValue={user.name} />}
          updateUser={() => {}}
          deleteUser={() => {}}
        />
      ))}
      <Actions>
        <SimpleButton
          content="Invite Users"
          onClick={() => {
            setOpen(true);
          }}
        />
      </Actions>

      <InviteUsersDialog
        createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
        open={open}
        title="Invite User"
        email={email}
        setEmail={setEmail}
        roles={userAvailableRoles}
        role={userAvailableRoles[0]}
        setRole={() => {}}
        submitAction={() => {}}
        closeDialog={() => {
          setOpen(false);
        }}
      />
    </Users>
  );
}
export default UsersTabContent;
