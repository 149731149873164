import { styled } from '@mui/material/styles';

export const Actions = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '10px',
});

export const LeftButtons = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '10px',
});

export const RightButtons = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '10px',
});
