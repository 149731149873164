import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import EntityPage from './page';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';
import { useUIViewModel } from '../../view-models/UIViewModel';

export const EntityController: React.FC = () => {
  const workspaceViewModel = useWorkspacesViewModel();
  const entityViewModel = useEntityViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();
  const uiViewModel = useUIViewModel();
  const navigate = useNavigate();
  useEffect(() => {
    if (!entityViewModel.hasEntities) {
      navigate('/dashboard');
    }
  }, [entityViewModel.entities]);

  const params = useParams<{ workspaceId: string; entityId: string }>();
  const { workspaceId, entityId } = params;
  // TODO Vasilis maybe move this to view model
  if (!workspaceViewModel.selectedWorkspace && workspaceId)
    workspaceViewModel.selectWorkspace(workspaceId);
  if (!entityViewModel.entity && !entityViewModel.clearedEntity && entityId) {
    console.log('[EntityController] [Entity] selectEntity', entityId);
    entityViewModel.selectEntity(entityId);
  }
  return (
    <EntityPage
      // TODO: this will break if someone visits directly this url
      currentEntity={entityViewModel.entity.data}
      countries={workspaceViewModel.workspaceCountries}
      languages={workspaceViewModel.workspaceLanguages}
      createUserWithEmailAndRoleId={entityViewModel.createUserWithEmailAndRoleId}
      users={entityViewModel.users}
      userAvailableRoles={entityViewModel.availableUserRoles}
      updateEntityName={entityViewModel.updateEntityName}
      updateCountry={entityViewModel.updateCountry}
      updateLanguage={entityViewModel.updateLanguage}
      updateEntity={entityViewModel.updateEntity}
      deleteEntity={entityViewModel.deleteEntity}
      updateParams={entityViewModel.updateParams}
      status="success"
      showCreateReconciliationAccountDialog={
        reconciliationAccountViewModel.showCreateReconciliationAccountDialog
      }
      fetchUsersDetails={entityViewModel.fetchUsersDetails}
      showConfirmationDialog={uiViewModel.showConfirmationDialog}
    />
  );
};

const EntityControllerPage = observer(EntityController);

export { EntityControllerPage };
