import { styled } from '@mui/material';

export const Title: any = styled('div')({
  fontSize: '18px',
  color: 'rgba(182, 182, 182, 1)',
  marginLeft: '10px',
  marginTop: '10px',
});

export const Dash: any = styled('div')({
  fontSize: '18px',
  marginLeft: '3.5px',
  marginRight: '3.5px',
});

export const LeftSpace: any = styled('div')({
  marginLeft: '10px',
});

export const BottomSpace: any = styled('div')({
  marginBottom: '10px',
});

export const Amounts: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
});

export const DateRange: any = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginLeft: '10px',
});

export const Block: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  overflow: 'visible',
  height: 'auto',
  zIndex: 1,
  width: '252px',
});

export const InputTitle: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(182, 182, 182, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

export const Input: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  width: '100%',
  marginTop: '5px',
});
