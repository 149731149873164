import { observer } from 'mobx-react-lite';
import LedgerMissingOpeningBalanceDialog from '../component';
import { useDocumentsViewModel } from '../../../../view-models/DocumentsViewModel';
import { useReconciliationReportViewModel } from '../../../../view-models/ReconciliationReportViewModel';

function LedgerMissingOpeningBalanceController() {
  const documentsViewModel = useDocumentsViewModel()!;
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <LedgerMissingOpeningBalanceDialog
      open={documentsViewModel.isLedgerMissingOB}
      openingBalance={reconciliationReportViewModel.ledgerOpeningBalance}
      submitAction={documentsViewModel.applyLedgerOpeningBalance}
      areRequiredFieldsSet={
        reconciliationReportViewModel.areLedgerMissingOpeningBalanceRequiredFieldsSet
      }
      closeDialog={documentsViewModel.hideLedgerMissingOBDialog}
      editOpeningBalance={reconciliationReportViewModel.editLedgerOpeningBalance}
    />
  );
}

const ledgerMissingOB = observer(LedgerMissingOpeningBalanceController);

export default ledgerMissingOB;
