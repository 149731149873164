import { IAMServiceErrors } from '../../Errors';
import { ReconcilioServiceClient } from '../../bitloops/proto/ReconcilioServiceClientPb';
import { RefreshTokenErrorResponse, RefreshTokenRequest } from '../../bitloops/proto/reconcilio_pb';
import { HTTP_PROXY_URL, PROXY_URL } from '../../config';
import {
  AssignRoleToUserParams,
  AuthOptions,
  GetAvailableRolesResponse,
  GetUserResponse,
  GetUsersByIdsResponse,
  IIamService,
  LoginResponse,
  ResourceLevel,
  UpdateUserDetailsRequest,
} from '../interfaces/IIamService';

export default class IamService implements IIamService {
  private reconciliationService: ReconcilioServiceClient;

  constructor(private readonly apiUrl = HTTP_PROXY_URL) {
    this.reconciliationService = new ReconcilioServiceClient(PROXY_URL);
  }

  async refreshToken(accessToken: string): Promise<string> {
    const request = new RefreshTokenRequest();

    const response = await this.reconciliationService.refreshToken(request, {
      Authorization: `Bearer ${accessToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: RefreshTokenErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case RefreshTokenErrorResponse.ErrorCase.INVALID_TOKEN_ERROR:
          throw IAMServiceErrors.INVALID_TOKEN_ERROR;
        case RefreshTokenErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw IAMServiceErrors.UNEXPECTED_ERROR;
        default:
          throw IAMServiceErrors.UNEXPECTED_ERROR;
      }
    }

    const authToken = response.getOk()?.getAuthtoken();
    if (!authToken) {
      throw IAMServiceErrors.INVALID_TOKEN_ERROR;
    }
    return authToken;
  }

  async loginWithEmailPassword(email: string, password: string): Promise<LoginResponse> {
    const apiUrl = `${this.apiUrl}/users/signin`;
    const requestBody = JSON.stringify({
      email,
      password,
    });
    // Send the file to your API
    const response = await fetch(apiUrl, {
      method: 'POST',
      body: requestBody,
      headers: { 'Content-Type': 'application/json' },
    });
    // Check if the upload was successful
    if (!response.ok) {
      throw new Error(
        response.statusText.includes('Unauthorized')
          ? 'Check your credentials'
          : response.statusText
      );
    }
    // Process the response here (if needed)
    const data = await response.json();
    return { accessToken: data.token };
  }

  async registerWithEmailPassword(email: string, password: string): Promise<string> {
    const apiUrl = `${this.apiUrl}/users/signup`;
    const requestBody = JSON.stringify({
      email,
      password,
    });
    // Send the file to your API
    const response = await fetch(apiUrl, {
      method: 'POST',
      body: requestBody,
      headers: { 'Content-Type': 'application/json' },
    });
    // Check if the upload was successful
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        response.statusText.includes('Conflict') ? 'Account already exists!' : errorResponse
      );
    }
    const data = await response.json();
    return data.id;
  }

  async getUsersByIds(ids: string[], authOptions: AuthOptions): Promise<GetUsersByIdsResponse> {
    const apiUrl = `${this.apiUrl}/users/getByIds`;
    const requestBody = JSON.stringify({ ids });

    const response = await fetch(apiUrl, {
      method: 'POST',
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authOptions.idToken}`,
      },
    });
    // Check if the upload was successful
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        response.statusText.includes('Conflict') ? 'Account already exists!' : errorResponse
      );
    }
    const data = await response.json();
    return data.users;
  }

  async getUserById(id: string, authOptions: AuthOptions): Promise<GetUserResponse> {
    const apiUrl = `${this.apiUrl}/users/${id}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authOptions.idToken}`,
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }
    const data = await response.json();
    return data;
  }

  async getUserByEmail(email: string, authOptions: AuthOptions): Promise<GetUserResponse> {
    const apiUrl = `${this.apiUrl}/users?email=${email}`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authOptions.idToken}`,
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }
    const data = await response.json();
    return data;
  }

  async getAvailableRoles(authOptions: AuthOptions): Promise<GetAvailableRolesResponse> {
    const apiUrl = `${this.apiUrl}/users/roles`;
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authOptions.idToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const data = await response.json();
    return data.roles;
  }

  async assignRoleToUser(params: AssignRoleToUserParams, authOptions: AuthOptions): Promise<void> {
    const apiUrl = `${this.apiUrl}/users/assignRoleToUser`;
    const {
      userId,
      roleId,
      workspaceId,
      entityId,
      reconciliationAccountId,
      reconciliationReportId,
      resourceLevel,
    } = params;
    const requestBody: AssignRoleToUserParams = {
      userId,
      roleId,
      workspaceId,
      resourceLevel,
    };
    if (entityId) requestBody.entityId = entityId;
    if (reconciliationAccountId) requestBody.reconciliationAccountId = reconciliationAccountId;
    if (reconciliationReportId) requestBody.reconciliationReportId = reconciliationReportId;
    const requestBodyJSON = JSON.stringify(requestBody);

    const response = await fetch(apiUrl, {
      method: 'POST',
      body: requestBodyJSON,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authOptions.idToken}`,
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }
    const data = await response.json();
    return data;
  }

  async updateUserDetails(
    params: UpdateUserDetailsRequest,
    authOptions: AuthOptions
  ): Promise<void> {
    const { userId, email, oldPassword, newPassword, name, phone, companyName, industry, country } =
      params;
    if (!userId) throw new Error('userId is required');
    const requestBody: any = {};
    if (email) requestBody.email = email;
    if (oldPassword) requestBody.oldPassword = oldPassword;
    if (newPassword) requestBody.newPassword = newPassword;
    if (name) requestBody.name = name;
    if (phone) requestBody.phone = phone;
    if (companyName) requestBody.companyName = companyName;
    if (industry) requestBody.industry = industry;
    if (country) requestBody.country = country;
    const apiUrl = `${this.apiUrl}/users/${userId}`;

    const response = await fetch(apiUrl, {
      method: 'PATCH',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authOptions.idToken}`,
      },
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }
  }
}

const main = async () => {
  try {
    const iamService = new IamService();
    const { accessToken: token } = await iamService.loginWithEmailPassword('ant@ant.gr', '12345');
    console.log('token:', token);
    // const users =  await iamService.getUsersByIds(['d17d0d0d-98cf-4b52-9ce6-0bacb4795794'], {idToken: token});
    // console.log('users:', users)
    // const roles = await iamService.getAvailableRoles({idToken: token});
    // await iamService.assignRoleToUser({
    //   userId: 'd17d0d0d-98cf-4b52-9ce6-0bacb4795794',
    //   roleId: '0ea3e47d-ebd0-4522-8828-1c03efe5ea21' ,
    //   workspaceId: '34618b9b-065a-4845-a45c-79e268cb2f9d',
    //   entityId: '35123b95-6ad2-4f71-9f4a-4fd28cf518ac',
    //   resourceLevel:ResourceLevel.ENTITY,
    // },
    // {idToken: token})
    // await iamService.updateUserDetails({
    //   userId: 'd17d0d0d-98cf-4b52-9ce6-0bacb4795794',
    //   email:'ant@ant.gr',
    //   name: 'Paparidis',
    //   phone: 'paparidophone',
    //   companyName: 'paparidicompany',
    //   industry: 'paparidiindustry',
    //   country: 'paparidicountry',
    //   oldPassword: '12345',
    //   newPassword: '12345',

    // }, {idToken: token})

    // const user = await iamService.getUserById('d17d0d0d-98cf-4b52-9ce6-0bacb4795794', {idToken: token});
    // console.log('user:', user);
    // const user = await iamService.getUserByEmail('ant@ant.gr', {idToken: token});
    // console.log('user:', user);
  } catch (error) {
    console.error(error);
  }
};

// main();
