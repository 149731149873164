import Layout from './Layout';
import ReconciledTransaction from './ReconciledTransaction/ReconciledTransaction';
import Subheader from '../Subheader/Subheader';
import { ReconciledTransactionBlocksProps } from '../../../types';
import { Empty } from './Styles';
// import SimpleButton from '../../Buttons/SimpleButton';
// import filterIcon from '../../../assets/filter.svg';

function ReconciledTransactions(props: ReconciledTransactionBlocksProps): JSX.Element {
  const {
    reconciledTransactionsBlocks,
    externalDocumentType,
    ledgerDocumentType,
    unreconcileMatch,
    bankStatementTransactionsPercentage,
  } = props;
  return (
    <>
      {/* <Subheader title="Reconciled Transactions" /> */}
      {reconciledTransactionsBlocks === null && (
        <Empty>Reconciled transactions will be loaded soon.</Empty>
      )}
      {reconciledTransactionsBlocks && reconciledTransactionsBlocks.length === 0 && (
        <Empty>No reconciled transactions found.</Empty>
      )}
      {reconciledTransactionsBlocks && reconciledTransactionsBlocks.length > 0 && (
        <Layout
          externalDocumentType={externalDocumentType}
          externalStatementTransactionsPercentage={bankStatementTransactionsPercentage}
          ledgerDocumentType={ledgerDocumentType}
        >
          {reconciledTransactionsBlocks.map((reconciledTransaction, index) => (
            <ReconciledTransaction
              id={reconciledTransaction.id}
              unreconcileMatch={unreconcileMatch}
              bankStatementTransactions={reconciledTransaction.bankStatementTransactions}
              ledgerTransactions={reconciledTransaction.ledgerTransactions}
              type={reconciledTransaction.type}
              key={index}
            />
          ))}
        </Layout>
      )}
    </>
  );
}

export default ReconciledTransactions;
