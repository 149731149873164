import BankTransactionBlock from '../../Transaction/BankTransaction/BankTransaction';
import { BTransactions } from './Styles';
import { ManualTransaction } from '../../../../models/ManualReconciliation';

interface BankTransactionsProps {
  transactions: ManualTransaction[];
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
}

function BankTransactions(props: BankTransactionsProps): JSX.Element {
  const { transactions, selectOrUnselectBankTransaction } = props;

  return (
    <BTransactions>
      {transactions.map((transaction, index) => (
        <BankTransactionBlock
          key={index}
          transaction={transaction}
          selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
        />
      ))}
    </BTransactions>
  );
}
export default BankTransactions;
