import DatePickerComponent from '../DatePicker/component';
import { Dates, Date } from './styles';

interface ReconciliationPeriodProps {
  startDate: string;
  endDate: string;
  showErrorState?: boolean;
  editStartDate: (date: string) => void;
  editEndDate: (date: string) => void;
  shouldDisableStartDateFromDatePicker?: () => boolean;
}

function DateRange(props: ReconciliationPeriodProps) {
  const {
    startDate,
    endDate,
    showErrorState,
    editStartDate,
    editEndDate,
    shouldDisableStartDateFromDatePicker,
  } = props;
  return (
    <Dates>
      <Date>
        <DatePickerComponent
          label="start"
          date={startDate}
          setDate={editStartDate}
          shouldDisableDateFromDatePicker={shouldDisableStartDateFromDatePicker}
          showErrorState={showErrorState}
        />
        <DatePickerComponent
          label="end"
          date={endDate}
          setDate={editEndDate}
          showErrorState={showErrorState}
        />
      </Date>
    </Dates>
  );
}

export default DateRange;
