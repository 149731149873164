import { useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import infoIcon from '../../../assets/info.svg';
import { Block, Content, Info, Text, Templates } from './Styles';
import FilledButton from '../../Buttons/FilledButton';
import templateIcon from '../../../assets/template.svg';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../models/Document';
import { buttonStyles } from '../../Buttons/Styles';

interface FollowTemplatesProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  downloadLedgerTemplate: () => void;
  downloadBankTemplate: () => void;
}

const infoData = `
The first column should be the transaction date. Ensure the content are actual dates.

Ensure the first transaction (or last if chronologically descending) has a running
balance to calculate the statement&apos;s opening balance.

A transaction has both a credit and a debit amount (a 0 is considered a value, so those
values should be empty).

Ensure the Credit, Debit and Balance fields are numbers.

Avoid strange characters/emojis in the Description, Reference or Bank/Ledger ID fields.

Dates in the documents cannot be outside the defined reconciliation period.

Dates in statements are consistently ascending or descending, they cannot be mixed up.

Columns are not aligned with our template (we need a Date, Description, Reference, Bank
or Ledger ID, Debit, Credit, Balance column).
`;

function FollowTemplates({
  externalDocumentType,
  ledgerDocumentType,
  downloadLedgerTemplate,
  downloadBankTemplate,
}: FollowTemplatesProps): JSX.Element {
  const [showFullText, setShowFullText] = useState(false);

  const handleHover = () => {
    setShowFullText(true);
  };

  const handleMouseLeave = () => {
    setShowFullText(false);
  };

  return (
    <Block>
      <Content>
        <Info>
          <Tooltip open={showFullText} title={infoData} placement="top">
            <img
              src={infoIcon}
              loading="lazy"
              alt="Info Icon"
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
            />
          </Tooltip>
          <Text>Please follow these templates</Text>
        </Info>
        <Templates>
          <FilledButton
            content={`${externalDocumentType} Template`}
            icon={templateIcon}
            bank
            onClick={downloadBankTemplate}
          />
          <FilledButton
            content={`${ledgerDocumentType} Template`}
            icon={templateIcon}
            onClick={downloadLedgerTemplate}
          />
        </Templates>
      </Content>
    </Block>
  );
}

export default FollowTemplates;
