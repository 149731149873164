import { styled } from '@mui/material/styles';

export const Period: any = styled('div')({
  marginTop: '10px',
});

export const Title: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  width: '200px',
  color: 'rgba(182, 182, 182, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginRight: '10px',
  marginBottom: '15px',
});

export const TitleAndOptions: any = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  // width: '100%',
  marginTop: '10px',
});

export const PreviousReportAndPeriod: any = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});
