import { styled } from '@mui/material/styles';

export const Button = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  padding: '0px 20px 0px 10px',
  margin: '20px 0px',
  font: 'inherit',
  outline: 'inherit',
  '&:hover': {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--white-color)',
    borderRadius: 'var(--border-radius)',
  },
});

export const Icon = styled('div')({
  backgroundColor: 'var(--primary-color)',
  borderRadius: '50%',
  width: '90px',
  height: '90px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Content = styled('div')({
  textAlign: 'left',
  fontSize: '24px',
  minWidth: '350px',
});
