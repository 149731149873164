import { styled } from '@mui/material/styles';

export const Item = styled('div')({
  display: 'flex',
  width: '180px',
  paddingLeft: '5px',
  marginLeft: '15px',
  color: '#B5B5C3',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});

export const SimpleAction = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
