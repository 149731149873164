import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import {
  CurrentEntityStatus,
  Entity,
  EntitySlim,
  ReconciliationAccount,
  ReconciliationAccountSlim,
} from '../../models/Resources';
import ListItems from './ListItems';
import logo from '../../assets/reconcilio-full-black.svg';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface IDrawer {
  entities: EntitySlim[];
  currentEntity: Entity | null;
  selectEntity: (entityId: string) => void;
  selectReconciliationAccount: (reconciliationAccountId: string) => void;
  currentEntityStatus: CurrentEntityStatus;
  reconciliationAccountsList: ReconciliationAccountSlim[];
  currentReconciliationAccount: ReconciliationAccount | null;
  open: boolean;
  openDrawer: () => void;
  toggleDrawer: () => void;
  navigateToEntity: (entityId: string) => void;
  navigateToWorkspace: () => void;
  navigateToConvertersAndTemplates: () => void;
  goHome: () => void;
  navigateToReconciliationAccount: (reconciliationAccountId: string) => void;
  showCreateEntityDialog: () => void;
  currentReconciliationAccountsType: string;
  selectReconciliationAccountsType: (reconciliationAccountsType: string) => void;
}

const DrawerComponent: React.FC<IDrawer> = (props: IDrawer) => {
  const {
    entities,
    open,
    openDrawer,
    toggleDrawer,
    selectEntity,
    currentEntity,
    currentEntityStatus,
    reconciliationAccountsList,
    selectReconciliationAccount,
    goHome,
    navigateToEntity,
    navigateToWorkspace,
    navigateToConvertersAndTemplates,
    currentReconciliationAccount,
    navigateToReconciliationAccount,
    showCreateEntityDialog,
    currentReconciliationAccountsType,
    selectReconciliationAccountsType,
  } = props;
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [0],
          margin: '0px 16px',
        }}
      >
        <img src={logo} alt="Logo" width={120} height={43} />
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItems
          entities={entities}
          drawerOpen={open}
          openDrawer={openDrawer}
          goHome={goHome}
          currentEntity={currentEntity}
          currentEntityStatus={currentEntityStatus}
          selectEntity={selectEntity}
          reconciliationAccountsList={reconciliationAccountsList}
          currentReconciliationAccount={currentReconciliationAccount}
          selectReconciliationAccount={selectReconciliationAccount}
          navigateToEntity={navigateToEntity}
          navigateToReconciliationAccount={navigateToReconciliationAccount}
          navigateToWorkspace={navigateToWorkspace}
          showCreateEntityDialog={showCreateEntityDialog}
          currentReconciliationAccountsType={currentReconciliationAccountsType}
          selectReconciliationAccountsType={selectReconciliationAccountsType}
          navigateToConvertersAndTemplates={navigateToConvertersAndTemplates}
        />
      </List>
    </Drawer>
  );
};

export default DrawerComponent;
