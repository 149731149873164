import { List } from '@mui/material';
import FilledButton from '../../Buttons/FilledButton';
import LedgerTransactionBlock from '../Transaction/LedgerTransaction/LedgerTransaction';
import { Action, LTransactions, TransactionsHeader } from './Styles';
import { ManualTransaction } from '../../../models/ManualReconciliation';
import Categories from './Categories';
import { LEDGER_DOCUMENT_TYPES } from '../../../models/Document';
import Search from '../../Search';
import filterIcon from '../../../assets/filter.svg';
import { LedgerFilterPopUpDialog } from '../../CreateDialog/FilterPopUpDialog';
import SmallSimpleButton from '../../Buttons/SmallSimpleButton';

interface LedgerTransactionsProps {
  transactions: ManualTransaction[];
  isDateAscending: boolean;
  isCreditAscending: boolean;
  isDebitAscending: boolean;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  quickFilter: string;
  toggleCreditDropdown: () => void;
  toggleDebitDropdown: () => void;
  toggleDateDropdown: () => void;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  showNewLedgerTransactionDialog: () => void;
  scrollToUnreconcilableText: () => void;
  changeQuickFilter: (value: string) => void;
  showFilterPopUpDialog: () => void;
  selectFilteredUnreconciledManualTransactions: () => void;
}

function LedgerTransactions(props: LedgerTransactionsProps): JSX.Element {
  const {
    transactions,
    isDateAscending,
    isCreditAscending,
    isDebitAscending,
    ledgerDocumentType,
    quickFilter,
    toggleCreditDropdown,
    toggleDebitDropdown,
    toggleDateDropdown,
    selectOrUnselectLedgerTransaction,
    showNewLedgerTransactionDialog,
    scrollToUnreconcilableText,
    changeQuickFilter,
    showFilterPopUpDialog,
    selectFilteredUnreconciledManualTransactions,
  } = props;
  return (
    <LTransactions>
      <LedgerFilterPopUpDialog />
      <TransactionsHeader>
        {ledgerDocumentType} Transactions
        <Action>
          <button
            type="button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Filter"
            onClick={showFilterPopUpDialog}
          >
            <img src={filterIcon} alt="filter" />
          </button>
        </Action>
        <SmallSimpleButton
          content="Select All"
          onClick={selectFilteredUnreconciledManualTransactions}
        />
      </TransactionsHeader>

      <Search
        value={quickFilter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeQuickFilter(e.target.value);
        }}
      />
      <Categories
        isDateAscending={isDateAscending}
        isCreditAscending={isCreditAscending}
        isDebitAscending={isDebitAscending}
        toggleCreditDropdown={toggleCreditDropdown}
        toggleDebitDropdown={toggleDebitDropdown}
        toggleDateDropdown={toggleDateDropdown}
      />
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          minHeight: 300,
          maxHeight: 700,
          '& ul': { padding: '1px' },
          marginBottom: '20px',
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
        subheader={<li />}
      >
        {transactions.map((transaction, index) => (
          <li key={`section-${index}`}>
            <ul>
              <LedgerTransactionBlock
                key={index}
                transaction={transaction}
                selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
                scrollToUnreconcilableText={scrollToUnreconcilableText}
              />
            </ul>
          </li>
        ))}
      </List>
      {/* <FilledButton content="New Ledger Transaction" onClick={showNewLedgerTransactionDialog} /> */}
    </LTransactions>
  );
}
export default LedgerTransactions;
