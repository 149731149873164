import { MatchType } from '../../../../models/ReconciliationReport';
import { FlagShape, FlagText } from './Styles';

interface FlagProps {
  type: MatchType;
}

function Flag(props: FlagProps): JSX.Element {
  const { type } = props;
  return (
    <FlagShape
      style={{
        backgroundColor: type === 'A' ? 'var(--primary-color)' : 'var(--white-color)',
        color: type === 'A' ? 'var(--white-color)' : 'var(--primary-color)',
      }}
    >
      <FlagText>{type}</FlagText>
    </FlagShape>
  );
}

export default Flag;
