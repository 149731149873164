import React, { JSX } from 'react';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import 'react-datepicker/dist/react-datepicker.css';
import CreateReconciliationReport from '../../components/CreateReconciliationReport/CreateReconciliationReport';
import { CreateReconciliationReportPageProps } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export function CreateReconciliationReportPage(
  props: CreateReconciliationReportPageProps
): JSX.Element {
  const {
    status,
    errorMessage,
    progress,
    message,
    total,
    totalReconciled,
    reconciliationReportStarted,
    externalDocumentType,
    ledgerDocumentType,
    bankDocumentUploaded,
    bankDocumentNotInOurTemplateUploaded,
    createReconciliationReport,
    // createReconciliationReportWithCompensatingLedgerTransaction,
    uploadFile,
    bankDocumentId,
    bankDocumentIdNotInOurTemplate,
    ledgerDocumentId,
    ledgerDocumentIdNotInOurTemplate,
    ledgerDocumentUploaded,
    ledgerDocumentNotInOurTemplateUploaded,
    previousReconciliationReports,
    reconciliationAccount,
    filesUploaded,
    reconciliationReportPeriodSet,
    bankUploadFileStatus,
    ledgerUploadFileStatus,
    ledgerNotInOurTemplateUploadFileStatus,
    bankNotInOurTemplateUploadFileStatus,
    setBankNotInOurTemplateUploadFileStatus,
    setLedgerNotInOurTemplateUploadFileStatus,
    setBankUploadFileStatus,
    setLedgerUploadFileStatus,
    uploadFileNotInOurTemplate,
  } = props;

  return (
    <>
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      <CreateReconciliationReport
        ledgerNotInOurTemplateUploadFileStatus={ledgerNotInOurTemplateUploadFileStatus}
        bankNotInOurTemplateUploadFileStatus={bankNotInOurTemplateUploadFileStatus}
        ledgerUploadFileStatus={ledgerUploadFileStatus}
        bankUploadFileStatus={bankUploadFileStatus}
        reconciliationReportStarted={reconciliationReportStarted}
        progress={progress}
        message={message}
        total={total}
        totalReconciled={totalReconciled}
        bankDocumentUploaded={bankDocumentUploaded}
        bankDocumentNotInOurTemplateUploaded={bankDocumentNotInOurTemplateUploaded}
        createReconciliationReport={createReconciliationReport}
        uploadFile={uploadFile}
        uploadFileNotInOurTemplate={uploadFileNotInOurTemplate}
        bankDocumentId={bankDocumentId}
        bankDocumentIdNotInOurTemplate={bankDocumentIdNotInOurTemplate}
        ledgerDocumentId={ledgerDocumentId}
        ledgerDocumentIdNotInOurTemplate={ledgerDocumentIdNotInOurTemplate}
        ledgerDocumentUploaded={ledgerDocumentUploaded}
        ledgerDocumentNotInOurTemplateUploaded={ledgerDocumentNotInOurTemplateUploaded}
        previousReconciliationReports={previousReconciliationReports}
        reconciliationAccount={reconciliationAccount}
        filesUploaded={filesUploaded}
        reconciliationReportPeriodSet={reconciliationReportPeriodSet}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        setBankUploadFileStatus={setBankUploadFileStatus}
        setLedgerUploadFileStatus={setLedgerUploadFileStatus}
        setBankNotInOurTemplateUploadFileStatus={setBankNotInOurTemplateUploadFileStatus}
        setLedgerNotInOurTemplateUploadFileStatus={setLedgerNotInOurTemplateUploadFileStatus}
      />
    </>
  );
}
