import TextField from '@mui/material/TextField';
import { Button, Divider } from '@mui/material';
import { StopListTable, TableHeader, TableBody } from './Styles';
import ItemBlock from './Item';
import { StopListItem } from '../../../../models/Resources';
import { NewWorkspaceStopListItem } from '../../../CreateDialog/NewStopListItemDialog';

interface StopListProps {
  stopList: StopListItem[];
  setStopListItemName: (value: string) => void;
  updateStopListItem: (id: string) => void;
  deleteStopListItem: (id: string) => void;

  showAddStopListItemDialog: () => void;
}

function StopListView(props: StopListProps): JSX.Element {
  const {
    stopList,
    setStopListItemName,
    updateStopListItem,
    deleteStopListItem,
    showAddStopListItemDialog,
  } = props;

  return (
    <StopListTable
      sx={{
        width: {
          xs: 'var(--tab-content-min-width)',
          lg: 'var(--tab-content-max-width)',
        },
      }}
    >
      <TableHeader>Items</TableHeader>
      <Divider />
      <TableBody>
        {stopList.map((item) => (
          <ItemBlock
            key={item.id}
            name={item.value}
            nameInput={
              <TextField
                id="outlined-basic"
                variant="outlined"
                defaultValue={item.value}
                onChange={(event) => setStopListItemName(event.target.value)}
              />
            }
            updateField={() => {
              updateStopListItem(item.id);
            }}
            // updateField={updateStopListItem}
            deleteField={() => deleteStopListItem(item.id)}
          />
        ))}
        <Button variant="text" onClick={showAddStopListItemDialog}>
          Add Item
        </Button>
      </TableBody>
      <NewWorkspaceStopListItem />
    </StopListTable>
  );
}
export default StopListView;
