import { observer } from 'mobx-react-lite';
import FilterPopUpDialog from './component';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import LedgerDate from './LedgerDate';

const LedgerFilterPopUpController = () => {
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <FilterPopUpDialog
      open={reconciliationReportViewModel.isLedgerFilterPopUpDialogVisible}
      searchFilter={reconciliationReportViewModel.searchLedgerFilterOptions}
      fromAmountFilter={reconciliationReportViewModel.fromAmountLedgerFilterOptions}
      toAmountFilter={reconciliationReportViewModel.toAmountLedgerFilterOptions}
      setFromAmountFilter={reconciliationReportViewModel.setFromAmountLedgerFilterOptions}
      setToAmountFilter={reconciliationReportViewModel.setToAmountLedgerFilterOptions}
      closeDialog={reconciliationReportViewModel.hideLedgerFilterPopUpDialog}
      setSearchFilter={reconciliationReportViewModel.setSearchLedgerFilterOptions}
      areRequiredFieldsSet={reconciliationReportViewModel.areLedgerFilterOptionsSet}
      submit={reconciliationReportViewModel.applyLedgerFilterOptions}
      clear={reconciliationReportViewModel.clearLedgerFilterOptions}
      date={<LedgerDate />}
    />
  );
};

export default observer(LedgerFilterPopUpController);
