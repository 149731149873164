import { styled } from '@mui/material/styles';

export const Layout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: 'auto',
  width: '100%',
});

export const ButtonsLayout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: 'auto',
  width: '100%',
  gap: '20px',
});

export const SuggestedMatches: any = styled('div')({
  display: 'flex',
  position: 'relative',
  gap: '20px',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '20px 0px 0px 0px',
  width: '100%',
});

export const AcceptAllBlock: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 40px',
  widht: '100%',
});
