import React, { useEffect } from 'react';
import { Checkbox } from '@mui/material';
import { BankTransaction, BankDebit, BankCredit } from './Styles';
import Description from '../Description/Description';
import { Date, Reference, BankID } from '../Styles';
import { ManualTransaction } from '../../../../models/ManualReconciliation';

interface TransactionProps {
  transaction: ManualTransaction;
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  scrollToUnreconcilableText?: () => void;
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function BankTransactionBlock(props: TransactionProps): JSX.Element {
  const { transaction, selectOrUnselectBankTransaction, scrollToUnreconcilableText } = props;

  return (
    <BankTransaction
      onClick={() => {
        selectOrUnselectBankTransaction(transaction);
        if (scrollToUnreconcilableText) scrollToUnreconcilableText();
      }}
    >
      {/* <Checkbox
        checked={transaction.selected}
        {...label}
        defaultChecked
        sx={{
          '&:hover': { bgcolor: 'transparent' },
          padding: '0 5px 0 0',
        }}
        onClick={() => {
          selectOrUnselectBankTransaction(transaction);
        }}
      /> */}
      <Date>{transaction.date}</Date>
      <Description transaction={transaction} type="Bank" maxCharacters={96} />
      {/* <BankID>{transaction.financialTransactionId}</BankID>
      <Reference>{transaction.reference}</Reference> */}
      <BankDebit>{transaction.debit !== '0.00' && transaction.debit}</BankDebit>
      <BankCredit>{transaction.credit !== '0.00' && transaction.credit}</BankCredit>
    </BankTransaction>
  );
}

export default BankTransactionBlock;
