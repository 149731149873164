import { styled } from '@mui/material/styles';

export const Blocks: any = styled('div')({
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  // gap: '10px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto',
  width: '100%',
});
