import { UnreconciledTransactionsBlock } from '../../../models/ReconciliationReport';

function UnreconciledTxBlockTableRows(props: UnreconciledTransactionsBlock & { index: number }) {
  const {
    title,
    total,
    adjustedBalance,
    blocks,
    dateHeader,
    descriptionHeader,
    referenceHeader,
    amountHeader,
    index,
  } = props;
  // amountHeader is Either Debit or Credit
  return (
    <>
      {/* <!-- empty row added next... --> */}
      <tr>
        <td aria-hidden>&nbsp;</td>
      </tr>
      <tr>
        <th>
          {index + 1}. {title}
        </th>
      </tr>
      {/* <!-- empty row added next... --> */}
      <tr>
        <td aria-hidden>&nbsp;</td>
      </tr>
      <tr>
        <th>{dateHeader || 'Date'}</th>
        <th>{descriptionHeader || 'Description'}</th>
        <th>{referenceHeader || 'Reference'}</th>
        <th>{amountHeader}</th>
      </tr>
      {blocks.map((tx, blockIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={blockIndex}>
          <td>{tx.date}</td>
          <td>{tx.description}</td>
          <td>{tx.reference}</td>
          <td>{tx.amount}</td>
        </tr>
      ))}
      {/* <!-- empty row added next... --> */}
      <tr>
        <td aria-hidden>&nbsp;</td>
      </tr>
      <tr>
        <th>Total</th>
        <th aria-hidden> </th>
        <th aria-hidden> </th>
        <th>{total}</th>
      </tr>
      <tr>
        <th>Adjusted Balance</th>
        <th aria-hidden> </th>
        <th aria-hidden> </th>
        <th aria-hidden> </th>
        <th>{adjustedBalance}</th>
      </tr>
    </>
  );
}

export default UnreconciledTxBlockTableRows;
