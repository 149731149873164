import CreateButton from './CreateButton';
import entityIcon from '../../../assets/create-buttons/entity.svg';

interface NewEntityProps {
  onClick: () => void;
}

function NewEntityButton(props: NewEntityProps): JSX.Element {
  const { onClick } = props;
  return <CreateButton content="Create Entity" icon={entityIcon} onClick={onClick} />;
}
export default NewEntityButton;
