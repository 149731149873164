import Button from '@mui/material/Button';
import { buttonStyles } from './Styles';

export interface ClearButtonProps {
  disabled?: boolean;
  icon?: string;
  onClick?: () => void;
}

const greyColor = {
  backgroundColor: '#7C7C7A',
};

function ClearButton(props: ClearButtonProps): JSX.Element {
  const { icon, disabled, onClick } = props;

  return (
    <Button
      variant="contained"
      style={{ ...buttonStyles, ...greyColor }}
      startIcon={icon && <img src={icon} alt="Clear Icon" />}
      disabled={disabled}
      onClick={() => onClick?.()}
    >
      Clear
    </Button>
  );
}

export default ClearButton;
