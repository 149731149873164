import { styled } from '@mui/material/styles';

export const Dates: any = styled('div')({
  display: 'flex',
  gap: '20px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: 'auto',
  // width: 'var(--page-max-width)',
  marginTop: '10px',
});

export const Date: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center', // Center the children horizontally.
  alignItems: 'center',
  gap: '10px',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  // width: '100%',
});
