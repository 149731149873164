import React from 'react';
import { Categories, HeaderDebit, HeaderCredit } from './Styles';
import { Checkbox, Date, Description, Reference, LedgerID, BankID } from '../../Styles';

interface CategoriesProps {
  type: 'bank' | 'ledger';
}

function Header(props: CategoriesProps): JSX.Element {
  const { type } = props;
  if (type === 'bank') {
    return (
      <Categories>
        {/* <Checkbox /> */}
        <Date>Date</Date>
        <Description>Description</Description>
        {/* <BankID>BankID</BankID>
        <Reference>Reference</Reference> */}
        <HeaderDebit>Debit</HeaderDebit>
        <HeaderCredit>Credit</HeaderCredit>
      </Categories>
    );
  }
  return (
    <Categories>
      {/* <Checkbox /> */}
      <Date>Date</Date>
      <Description>Description</Description>
      {/* <LedgerID>LedgerID</LedgerID>
      <Reference>Reference</Reference> */}
      <HeaderDebit>Debit</HeaderDebit>
      <HeaderCredit>Credit</HeaderCredit>
    </Categories>
  );
}
export default Header;
