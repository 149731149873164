import { styled } from '@mui/material/styles';

export const Details: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  height: 'auto',
  width: 'fit-content',
});

export const MainContent: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
});

export const Headers: any = styled('div')({
  display: 'flex',
  gap: '5px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

export const Main: any = styled('div')(({ theme }: any) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

export const Sub: any = styled('div')(({ theme }: any) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

export const Divider: any = styled('div')({
  border: '0.5px solid rgba(94, 98, 102, 1)',
  height: '0px',
  width: 'var(--page-max-width)',
  margin: '15px 0px 10px 0px',
});
