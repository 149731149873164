import { LEDGER_DOCUMENT_TYPES, EXTERNAL_DOCUMENT_TYPES } from './Document';

export type CreateReconciliationReportData = {
  progressPercentage?: number;
  message?: string;
  id?: string;
  total?: number;
  totalReconciled?: number;
};

export type ReportContext = {
  workspaceId: string;
  entityId: string;
  accountId: string;
};
export type TReconciliationStatus = 'loading' | 'success' | 'error' | 'idle';

export const ReconciliationStatuses = {
  IDLE: 'idle' as TReconciliationStatus,
  LOADING: 'loading' as TReconciliationStatus,
  SUCCESS: 'success' as TReconciliationStatus,
  ERROR: 'error' as TReconciliationStatus,
};

export const RECONCILIATION_REPORT_STATUSES = {
  LOCKED: 'LOCKED',
  FINALIZED: 'FINALIZED',
  CREATED: 'CREATED',
};

export type ReconciliationReportPeriod = {
  startDate: string;
  endDate: string;
};

export type CreateReconciliationReportParams = {
  externalDocumentId: string;
  ledgerDocumentId: string;
  period: ReconciliationReportPeriod;
  shouldCreateCompensatingTransaction: boolean;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  previousReconciliationReportId?: string;
  ledgerOB?: number;
  externalOB?: number;
  ledgerDebitIncrease?: boolean;
  bankDebitIncrease?: boolean;
};
