import { Divider } from '@mui/material';
import { StopListTable, TableHeader } from './Styles';
import EntityListItems from './EntityListItems/index';
import WorkspaceListItems from './WorkspaceListItems';
import { InheritedStopListItem, StopListItem } from '../../../../models/Resources';

interface StopListProps {
  stopList: StopListItem[];
  workspaceStopList: InheritedStopListItem[];
  updateEntityStopListItem: (id: string) => Promise<void>;
  showAddStopListItemDialog: () => void;
  deleteEntityStopListItem: (id: string) => Promise<void>;
  setStopListItemName: (name: string) => void;

  enableWorkspaceStopListItem: (id: string) => Promise<void>;
  disableWorkspaceStopListItem: (id: string) => Promise<void>;
}

function StopList(props: StopListProps): JSX.Element {
  const {
    stopList,
    workspaceStopList,
    updateEntityStopListItem,
    deleteEntityStopListItem,
    showAddStopListItemDialog,
    enableWorkspaceStopListItem,
    disableWorkspaceStopListItem,
    setStopListItemName,
  } = props;

  return (
    <StopListTable
      sx={{
        width: {
          xs: 'var(--tab-content-min-width)',
          lg: 'var(--tab-content-max-width)',
        },
      }}
    >
      <TableHeader>Items</TableHeader>
      <Divider />
      <WorkspaceListItems
        workspaceStopList={workspaceStopList}
        enableWorkspaceStopListItem={enableWorkspaceStopListItem}
        disableWorkspaceStopListItem={disableWorkspaceStopListItem}
      />
      <EntityListItems
        entityStopList={stopList}
        updateEntityStopListItem={updateEntityStopListItem}
        deleteEntityStopListItem={deleteEntityStopListItem}
        showAddStopListItemDialog={showAddStopListItemDialog}
        setStopListItemName={setStopListItemName}
      />
    </StopListTable>
  );
}
export default StopList;
