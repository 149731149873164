import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Profile } from './Styles';
import FieldBlock from './Field';
import { EditUserParams } from '../../models/User';

export interface UserProfileProps {
  name: string;
  email: string;
  telephone: string;
  companyName: string;
  industry: string;
  countries: string[];
  country: string;
  updateParams: EditUserParams;
  // password: string;
  message: { type: 'error' | 'success'; message: string } | null;
  updateName: (name: string) => void;
  updateTelephone: (telephone: string) => void;
  updateCompanyName: (companyName: string) => void;
  updateIndustry: (industry: string) => void;
  updateEmail: (email: string) => void;
  // updatePassword: (password: string) => void;
  updateUser: (updateParams: Partial<EditUserParams>) => void;
  updateCountry: (value: string) => void;
}

function UserProfile(props: UserProfileProps): JSX.Element {
  const {
    countries,
    country,
    updateEmail,
    updateName,
    updateTelephone,
    updateCompanyName,
    updateIndustry,
    updateUser,
    updateParams,
    updateCountry,
  } = props;

  return (
    <Profile>
      <FieldBlock
        name="Name"
        field={updateParams.name}
        fieldInput={
          <TextField
            label="Name"
            type="text"
            // value={name}
            onChange={(e) => updateName(e.target.value)}
            defaultValue={updateParams.name}
          />
        }
        updateParam={{ name: updateParams.name }}
        updateField={updateUser}
      />
      <FieldBlock
        name="Email"
        field={updateParams.email}
        fieldInput={
          <TextField
            label="Email"
            type="email"
            // value={email}
            onChange={(e) => updateEmail(e.target.value)}
          />
        }
        updateParam={{ email: updateParams.email }}
        updateField={updateUser}
      />
      <FieldBlock
        name="Telephone"
        field={updateParams.phone}
        fieldInput={
          <TextField
            label="Telephone"
            type="text"
            // value={telephone}
            onChange={(e) => updateTelephone(e.target.value)}
          />
        }
        updateParam={{ phone: updateParams.phone }}
        updateField={updateUser}
      />
      <FieldBlock
        name="Company Name"
        field={updateParams.companyName}
        fieldInput={
          <TextField
            label="Company Name"
            type="text"
            // value={companyName}
            onChange={(e) => updateCompanyName(e.target.value)}
          />
        }
        updateParam={{ companyName: updateParams.companyName }}
        updateField={updateUser}
      />
      <FieldBlock
        name="Industry"
        field={updateParams.industry}
        fieldInput={
          <TextField
            label="Industry"
            type="text"
            // value={industry}
            onChange={(e) => updateIndustry(e.target.value)}
          />
        }
        updateParam={{ industry: updateParams.industry }}
        updateField={updateUser}
      />
      <FieldBlock
        name="Country"
        field={updateParams.country}
        fieldInput={
          <Autocomplete
            options={countries}
            value={updateParams.country || null}
            sx={{ width: 400 }}
            defaultValue={country}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e, value) => {
              if (value) updateCountry(value);
            }}
          />
        }
        updateParam={{ country: updateParams.country }}
        updateField={updateUser}
      />
    </Profile>
  );
}
export { UserProfile };
