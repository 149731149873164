import { styled } from '@mui/material/styles';

export const Header: any = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '100%',
  gap: '10px',
});

export const Title: any = styled('div')({});

export const Amount: any = styled('div')({});
