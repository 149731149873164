import { observer } from 'mobx-react-lite';
import FixedBottomButtonComponent from './FixedBottomButton';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';

type FixedBottomButtonControllerProps = {
  content: string;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

function FixedBottomButtonController(props: FixedBottomButtonControllerProps): JSX.Element {
  const { content, icon, disabled, onClick } = props;
  const workspaceViewModel = useWorkspacesViewModel();
  const { isWorkspacePath } = workspaceViewModel;

  return (
    <FixedBottomButtonComponent
      content={content}
      icon={icon}
      disabled={disabled}
      onClick={onClick}
      drawerOpen={isWorkspacePath}
    />
  );
}

const FixedBottomButton = observer(FixedBottomButtonController);

export default FixedBottomButton;
