import { Section, Header, Transactions } from './Styles';
import LedgerTransactions from './LedgerTransactions';
import BankTransactions from './BankTransactions';
import { ManualTransaction } from '../../../models/ManualReconciliation';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../models/Document';
import { UnreconcilableText } from '../MatchBlock/Styles';

interface UnreconciledTransactionsProps {
  // content: string;\
  status: 'idle' | 'reconcilable' | 'unreconcilable';
  ammountDifference?: string;
  ammountDifferenceLabel: string;
  bankUnreconciledTransactions: ManualTransaction[];
  ledgerUnreconciledTransactions: ManualTransaction[];
  isBankDateAscending: boolean;
  isBankCreditAscending: boolean;
  isBankDebitAscending: boolean;
  isLedgerDateAscending: boolean;
  isLedgerCreditAscending: boolean;
  isLedgerDebitAscending: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  bankQuickFilter: string;
  ledgerQuickFilter: string;
  toggleLedgerDateDropdown: () => void;
  toggleLedgerCreditDropdown: () => void;
  toggleLedgerDebitDropdown: () => void;
  toggleBankDateDropdown: () => void;
  toggleBankCreditDropdown: () => void;
  toggleBankDebitDropdown: () => void;
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  showNewLedgerTransactionDialog: () => void;
  scrollToUnreconcilableText: () => void;
  changeBankQuickFilter: (value: string) => void;
  changeLedgerQuickFilter: (value: string) => void;
  showBankFilterPopUpDialog: () => void;
  showLedgerFilterPopUpDialog: () => void;
  selectFilteredBankUnreconciledManualTransactions: () => void;
  selectFilteredLedgerUnreconciledManualTransactions: () => void;
}

function UnreconciledTransactions(props: UnreconciledTransactionsProps): JSX.Element {
  const {
    status,
    ammountDifference,
    ammountDifferenceLabel,
    bankUnreconciledTransactions,
    ledgerUnreconciledTransactions,
    isBankDateAscending,
    isBankCreditAscending,
    isBankDebitAscending,
    isLedgerDateAscending,
    isLedgerCreditAscending,
    isLedgerDebitAscending,
    externalDocumentType,
    ledgerDocumentType,
    bankQuickFilter,
    ledgerQuickFilter,
    toggleLedgerDateDropdown,
    toggleLedgerCreditDropdown,
    toggleLedgerDebitDropdown,
    toggleBankDateDropdown,
    toggleBankCreditDropdown,
    toggleBankDebitDropdown,
    selectOrUnselectBankTransaction,
    selectOrUnselectLedgerTransaction,
    showNewLedgerTransactionDialog,
    scrollToUnreconcilableText,
    changeBankQuickFilter,
    changeLedgerQuickFilter,
    showBankFilterPopUpDialog,
    showLedgerFilterPopUpDialog,
    selectFilteredBankUnreconciledManualTransactions,
    selectFilteredLedgerUnreconciledManualTransactions,
  } = props;
  return (
    <Section>
      <Header>Unreconciled Transactions</Header>
      <Transactions
        sx={{
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          gap: {
            xs: '20px',
            lg: '0px',
          },
        }}
      >
        <BankTransactions
          transactions={bankUnreconciledTransactions}
          isDateAscending={isBankDateAscending}
          externalDocumentType={externalDocumentType}
          toggleDateDropdown={toggleBankDateDropdown}
          isDebitAscending={isBankDebitAscending}
          toggleDebitDropdown={toggleBankDebitDropdown}
          isCreditAscending={isBankCreditAscending}
          toggleCreditDropdown={toggleBankCreditDropdown}
          selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
          scrollToUnreconcilableText={scrollToUnreconcilableText}
          changeQuickFilter={changeBankQuickFilter}
          showFilterPopUpDialog={showBankFilterPopUpDialog}
          quickFilter={bankQuickFilter}
          selectFilteredUnreconciledManualTransactions={
            selectFilteredBankUnreconciledManualTransactions
          }
        />
        <LedgerTransactions
          transactions={ledgerUnreconciledTransactions}
          isDateAscending={isLedgerDateAscending}
          ledgerDocumentType={ledgerDocumentType}
          toggleDateDropdown={toggleLedgerDateDropdown}
          isDebitAscending={isLedgerDebitAscending}
          toggleDebitDropdown={toggleLedgerDebitDropdown}
          isCreditAscending={isLedgerCreditAscending}
          toggleCreditDropdown={toggleLedgerCreditDropdown}
          selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
          showNewLedgerTransactionDialog={showNewLedgerTransactionDialog}
          scrollToUnreconcilableText={scrollToUnreconcilableText}
          changeQuickFilter={changeLedgerQuickFilter}
          showFilterPopUpDialog={showLedgerFilterPopUpDialog}
          quickFilter={ledgerQuickFilter}
          selectFilteredUnreconciledManualTransactions={
            selectFilteredLedgerUnreconciledManualTransactions
          }
        />
      </Transactions>
      {status === 'unreconcilable' && (
        <UnreconcilableText>{ammountDifferenceLabel}</UnreconcilableText>
      )}
    </Section>
  );
}
export default UnreconciledTransactions;
