import { styled } from '@mui/material/styles';

export const Text: any = styled('div')({
  textAlign: 'left',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  height: 'auto',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: '11px',
});

export const TextSpan: any = styled('span')({
  whiteSpace: 'pre-wrap',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '11px',
});
