/* eslint-disable react/no-unused-prop-types */
import React, { JSX } from 'react';
import { Header, Text, TextSpan1, TextSpan2 } from './Styles';
// import { SignUpForm } from '../../components/SignUpForm';
import { EmailPassForm } from '../../components/EmailPassForm';

interface RegisterPageProps {
  name: string;
  telephone: string;
  companyName: string;
  industry: string;
  countries: string[];
  country: string;
  email: string;
  password: string;
  message: { type: 'error' | 'success'; message: string } | null;
  updateEmail: (email: string) => void;
  updatePassword: (password: string) => void;
  updateName: (name: string) => void;
  updateTelephone: (telephone: string) => void;
  updateCompanyName: (companyName: string) => void;
  updateIndustry: (industry: string) => void;
  setCountryInputValue: (value: string) => void;
  submit: () => void;
  visitLoginPage: () => void;
}

function RegisterPage(props: RegisterPageProps): JSX.Element {
  const {
    // name,
    // telephone,
    // companyName,
    // industry,
    // countries,
    // country,
    email,
    password,
    message,
    submit,
    updateEmail,
    updatePassword,
    // updateName,
    // updateTelephone,
    // updateCompanyName,
    // updateIndustry,
    // setCountryInputValue,
    visitLoginPage,
  } = props;

  return (
    <>
      <Header>Sign Up</Header>
      {/* <SignUpForm
        email={email}
        password={password}
        message={message}
        submit={submit}
        view={'Sign Up'}
        name={name}
        telephone={telephone}
        companyName={companyName}
        industry={industry}
        countries={countries}
        country={country}
        updateEmail={updateEmail}
        updatePassword={updatePassword}
        updateName={updateName}
        updateTelephone={updateTelephone}
        updateCompanyName={updateCompanyName}
        updateIndustry={updateIndustry}
        setCountryInputValue={setCountryInputValue}
      /> */}
      <EmailPassForm
        email={email}
        password={password}
        view="Sign Up"
        message={message}
        submit={submit}
        updateEmail={updateEmail}
        updatePassword={updatePassword}
      />
      <Text>
        <TextSpan1>{'Already a user? '}</TextSpan1>
        <TextSpan2 onClick={visitLoginPage}>LOGIN</TextSpan2>
      </Text>
    </>
  );
}
export default RegisterPage;
