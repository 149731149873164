import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';

interface DebitsCreditsSwappedDialogProps {
  open: boolean;
  submitAction: () => void;
  closeDialog: () => void;
}
function DebitsCreditsSwappedDialog(props: DebitsCreditsSwappedDialogProps) {
  const { open, closeDialog, submitAction } = props;
  return (
    <CreateDialog
      title="Error"
      open={open}
      closeDialog={closeDialog}
      content={
        <p>
          The upload statement appears to have the credits and debits swapped. Could you confirm and
          ensure its a valid statement for this Reconciliation Account.
        </p>
      }
      action={<FilledButton content="OK" onClick={submitAction} />}
    />
  );
}

export default DebitsCreditsSwappedDialog;
