import { observer } from 'mobx-react-lite';
import BankDocumentFormatTypeMissingDialog from '../component';
import { useDocumentsViewModel } from '../../../../view-models/DocumentsViewModel';
import { useReconciliationReportViewModel } from '../../../../view-models/ReconciliationReportViewModel';

function BankDocumentFormatTypeMissingController() {
  const documentsViewModel = useDocumentsViewModel()!;
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <BankDocumentFormatTypeMissingDialog
      open={documentsViewModel.isBankDocumentFormatTypeVisible}
      submitDebitsIncrease={reconciliationReportViewModel.applyBankDebitIncrease}
      submitDebitsDecrease={reconciliationReportViewModel.applyBankDebitDecrease}
      closeDialog={documentsViewModel.hideBankDocumentFormatTypeMissingDialog}
    />
  );
}

const formatTypeMissingOB = observer(BankDocumentFormatTypeMissingController);

export default formatTypeMissingOB;
