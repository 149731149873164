import React, { JSX } from 'react';
import { Divider } from '@mui/material';
import { Report } from './Styles';
import {
  ReconciliationAccountReport,
  ReconciliationReportDetails,
} from '../../../../../models/ReconciliationReport';
import MainRow from './MainRow';

interface ReportBlockProps {
  report: ReconciliationAccountReport;
  numberOfReports: number;
  index: number;
  navigateToReport: (id: string) => void;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
  editReconciliationReportDetails: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
  showDeleteReconciliationReportDialog: (reportId: string) => void;
  isReconciliationAccountReportLocked: (reportId: string) => boolean;
}

function ReportBlock(props: ReportBlockProps): JSX.Element {
  const {
    report,
    numberOfReports,
    index,
    navigateToReport,
    downloadAdjustmentsReport,
    downloadReconciliationReport,
    editReconciliationReportDetails,
    showDeleteReconciliationReportDialog,
    isReconciliationAccountReportLocked,
  } = props;

  return (
    <Report>
      <MainRow
        report={report}
        navigateToReport={navigateToReport}
        downloadReconciliationReport={downloadReconciliationReport}
        downloadAdjustmentsReport={downloadAdjustmentsReport}
        editReconciliationReportDetails={editReconciliationReportDetails}
        showDeleteReconciliationReportDialog={showDeleteReconciliationReportDialog}
        isReconciliationAccountReportLocked={isReconciliationAccountReportLocked}
      />
      {/* <SecondaryRow report={report} downloadAdjustmentsReport={downloadAdjustmentsReport} /> */}
      {numberOfReports - 1 === index ? null : <Divider />}
    </Report>
  );
}

export default ReportBlock;
