import { observer } from 'mobx-react-lite';
import NewCategoryItemDialog from './component';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';

const NewCategoryItemController = () => {
  const workspaceViewModel = useWorkspacesViewModel();

  return (
    <NewCategoryItemDialog
      open={workspaceViewModel.isAddCategoryItemDialogVisible}
      closeDialog={workspaceViewModel.hideAddCategoryItemDialog}
      submit={() => workspaceViewModel.addNewCategoryItem()}
      categoryItemName={workspaceViewModel.categoryItemName}
      setCategoryItemName={workspaceViewModel.setCategoryItemName}
      areRequiredFieldsSet={workspaceViewModel.categoryItemName.length > 0}
    />
  );
};

const NewCategoryItem = observer(NewCategoryItemController);

export default NewCategoryItem;
