import { styled } from '@mui/material';

export const LtHeader: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  marginLeft: '24rem',
});

export const LtHeaderName: any = styled('div')(({ theme }: any) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'var(--ledger-color)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

export const LtCategories: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px 10px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '23px 0px 0px 0px',
});

export const LtDate: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

export const Date: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--date-width-sm)',
});

export const LtDescription: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  padding: '0px',
  boxSizing: 'border-box',
});

export const Description: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--description-width-sm)',
  marginLeft: '1rem',
});

export const LtReference: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  padding: '0px',
  boxSizing: 'border-box',
});

export const Reference: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--reference-width-sm)',
  marginLeft: '1rem',
  '@media (max-width: 1470px)': {
    marginLeft: '-1rem',
  },
});

export const LtDebit: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  padding: '0px',
  boxSizing: 'border-box',
});

export const Debit: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--amount-width-sm)',
  marginLeft: '2rem',
});

export const LtCredit: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  padding: '0px',
  boxSizing: 'border-box',
});

export const Credit: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--amount-width-sm)',
  marginLeft: '1rem',
});

export const LCategory: any = styled('div')({
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '35px',
  width: '100%',
  margin: '0px',
});

export const LDate: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginRight: '2rem',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

export const LReference: any = styled('div')({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginLeft: '0.5rem',
  width: 'var(--reference-width-sm)',
});

export const LDebit: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginLeft: '1.5rem',
  width: 'var(--amount-width-sm)',
});

export const LCredit: any = styled('div')({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginRight: '4rem',
  width: 'var(--amount-width-sm)',
});

export const LDescription: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(94, 98, 102, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-header-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: 'var(--description-width-sm)',
  height: 'auto',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});
