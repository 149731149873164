import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import NewStopListItemDialog from './component';
import { useIamViewModel } from '../../../view-models/IamViewModel';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';
import { WorkspaceUser } from '../../../models/WorkspaceUser';

const NewWorkspaceStopListItemController = () => {
  const iamViewModel = useIamViewModel();
  const workspaceViewModel = useWorkspacesViewModel();
  const [myUser, setMyUser] = useState<WorkspaceUser | null>(null);

  useEffect(() => {
    if (iamViewModel.user) {
      setMyUser({
        id: iamViewModel.user.id,
        name: iamViewModel.user?.name,
        email: iamViewModel.user?.email,
      });
    }
  }, [iamViewModel.user]);

  return myUser ? (
    <NewStopListItemDialog
      open={workspaceViewModel.isAddStopListItemDialogVisible}
      closeDialog={workspaceViewModel.hideAddStopListItemDialog}
      submit={workspaceViewModel.addStopListItem}
      stopListItemName={workspaceViewModel.stopListItemName}
      setStopListItemName={workspaceViewModel.setStopListItemName}
      areRequiredFieldsSet={workspaceViewModel.stopListItemName.length > 0}
    />
  ) : null;
};

const NewWorkspaceStopListItem = observer(NewWorkspaceStopListItemController);

export default NewWorkspaceStopListItem;
