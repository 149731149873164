export type LoginResponse = {
  accessToken: string;
};

export type AuthOptions = {
  idToken: string | null;
};

type AccessType = {
  roles: { _name: string; _id: string }[];
};

type ReconciliationReportAccessType = {
  [key: string]: AccessType;
};

type ReconciliationAccountType = {
  access: AccessType;
  reconciliationReports?: {
    [key: string]: ReconciliationReportAccessType;
  };
};

type EntitiesType = {
  access: AccessType;
  reconciliationAccounts?: {
    [key: string]: ReconciliationAccountType;
  };
};

type WorkspaceType = {
  access: AccessType;
  entities?: {
    [key: string]: EntitiesType;
  };
};

export type UserAccessType = {
  workspaces?: {
    [key: string]: WorkspaceType;
  };
};

export type GetUserResponse = {
  id: string;
  access: UserAccessType;
  isBlacklisted: boolean;
  companyName?: string;
  country?: string;
  email?: string;
  name?: string;
  phone?: string;
  industry?: string;
};

export type GetUsersByIdsResponse = GetUserResponse[];

export type GetAvailableRolesResponse = {
  id: string;
  name: string;
}[];

export enum ResourceLevel {
  WORKSPACE = 'WORKSPACE',
  ENTITY = 'ENTITY',
  RECONCILIATION_ACCOUNT = 'RECONCILIATION_ACCOUNT',
  RECONCILIATION_REPORT = 'RECONCILIATION_REPORT',
}

export type AssignRoleToUserParams = {
  userId: string;
  roleId: string;
  workspaceId: string;
  resourceLevel: ResourceLevel;
  entityId?: string;
  reconciliationAccountId?: string;
  reconciliationReportId?: string;
};

export type UpdateUserDetailsRequest = {
  userId: string;
  email?: string;
  oldPassword?: string;
  newPassword?: string;
  name?: string;
  phone?: string;
  companyName?: string;
  industry?: string;
  country?: string;
};

export interface IIamService {
  loginWithEmailPassword(email: string, password: string): Promise<LoginResponse>;
  registerWithEmailPassword(email: string, password: string): Promise<string>;
  getUsersByIds(ids: string[], authOptions: AuthOptions): Promise<GetUsersByIdsResponse>;
  getUserById(id: string, authOptions: AuthOptions): Promise<GetUserResponse>;
  getUserByEmail(email: string, authOptions: AuthOptions): Promise<GetUserResponse>;
  getAvailableRoles(authOptions: AuthOptions): Promise<GetAvailableRolesResponse>;
  assignRoleToUser(params: AssignRoleToUserParams, authOptions: AuthOptions): Promise<void>;
  updateUserDetails(params: UpdateUserDetailsRequest, authOptions: AuthOptions): Promise<void>;
  refreshToken(accessToken: string): Promise<string>;
}
