import React, { useContext, useEffect } from 'react';
import './styles/globals.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { ViewModelProviders } from './context/ViewModelProviders';
import ErrorContext from './context/Error';
import Routes from './routes';
import ScrollToTop from './ScrollToTop';

function App() {
  const { error, setError } = useContext(ErrorContext)!; // Use the ErrorContext to get error and setError

  useEffect(() => {
    if (error) {
      alert(error);
      setError(null);
    }
  }, [error, setError]);
  return (
    <ViewModelProviders>
      <Router>
        <ScrollToTop />
        <Routes />
      </Router>
    </ViewModelProviders>
  );
}

export default App;
