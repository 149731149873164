import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from '../Input';

interface NewEntityDialogProps {
  open: boolean;
  stopListItemName: string;
  setStopListItemName: (value: string) => void;
  areRequiredFieldsSet: boolean;
  closeDialog: () => void;
  submit: () => void;
}
function NewStopListItemDialog(props: NewEntityDialogProps) {
  const { open, stopListItemName, setStopListItemName, areRequiredFieldsSet, submit, closeDialog } =
    props;

  return (
    <CreateDialog
      title="Create Stop List Item"
      open={open}
      closeDialog={closeDialog}
      content={
        <InputBlock
          name="Item Name"
          input={
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={stopListItemName}
              onChange={(event) => setStopListItemName(event.target.value)}
            />
          }
          required
        />
      }
      action={<FilledButton disabled={!areRequiredFieldsSet} content="Add" onClick={submit} />}
    />
  );
}

export default NewStopListItemDialog;
