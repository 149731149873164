import FilledButton from '../Buttons/FilledButton';
import CreateDialog from '../CreateDialog/CreateDialog';

interface ConfirmationDialogProps {
  open: boolean;
  closeDialog: () => void;
  submit: () => void;
  confirmationMessage: string;
  confirmationButtonMessage: string;
}
function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { open, submit, closeDialog, confirmationMessage, confirmationButtonMessage } = props;

  return (
    <CreateDialog
      title="Confirmation"
      open={open}
      closeDialog={closeDialog}
      content={<div>{confirmationMessage}</div>}
      action={
        <FilledButton
          content={confirmationButtonMessage} // Confirmation button message
          onClick={submit}
        />
      }
    />
  );
}

export default ConfirmationDialog;
