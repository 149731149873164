import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../../CreateDialog/CreateDialog';

interface LockReconciliationReportDialogProps {
  open: boolean;
  submitAction: () => void;
  closeDialog: () => void;
}
function LockReconciliationReportDialog(props: LockReconciliationReportDialogProps) {
  const { open, closeDialog, submitAction } = props;
  return (
    <CreateDialog
      title="Are you sure you want to lock this reconciliation report?"
      open={open}
      closeDialog={closeDialog}
      action={<FilledButton content="Continue" onClick={submitAction} />}
      content={<> </>}
    />
  );
}

export default LockReconciliationReportDialog;
