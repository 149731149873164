import React from 'react';
import LTCategories from './LTCategories';
import { LtHeader, LtHeaderName } from './Styles';
import { LEDGER_DOCUMENT_TYPES } from '../../../../../models/Document';

interface LTHeaderProps {
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
}

function LTHeader(props: LTHeaderProps): JSX.Element {
  const { ledgerDocumentType } = props;
  return (
    <LtHeader>
      <LtHeaderName>{ledgerDocumentType} Transactions</LtHeaderName>
    </LtHeader>
  );
}
export default LTHeader;
