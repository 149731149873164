import { Block } from './Styles';
import { SimpleBlockProps } from '../../../types';

function SimpleBlock(props: SimpleBlockProps): JSX.Element {
  const { children } = props;
  return (
    <Block
    // sx={{
    //   width: {
    //     xs: '600px',
    //     lg: 'var(--page-max-width)',
    //   },
    // }}
    >
      {children}
    </Block>
  );
}
export default SimpleBlock;
