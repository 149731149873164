import { ReconciledTransaction, Layout, MainContent, Button, Icon } from './Styles';
import Match from '../../../Match/Match';
import Flag from './Flag';
import unreconcileIcon from '../../../../assets/unreconcile-red.svg';
import { ReconciledTransactionBlockProps } from '../../../../types';

function ReconciledTransactionBlock(props: ReconciledTransactionBlockProps): JSX.Element {
  const { bankStatementTransactions, ledgerTransactions, type, id, unreconcileMatch } = props;
  // console.log('[ReconciledTransactionBlock] props:', props);
  return (
    <Layout>
      <MainContent>
        <Flag type={type} />
        <ReconciledTransaction
        // width={{
        //   xs: '100%',
        //   lg: 'var(--page-max-width)',
        // }}
        >
          <Match
            bankTransactions={bankStatementTransactions}
            ledgerTransactions={ledgerTransactions}
          />
        </ReconciledTransaction>
      </MainContent>
      <Button onClick={() => unreconcileMatch(id)}>
        <Icon src={unreconcileIcon} loading="lazy" alt="Icon" />
      </Button>
    </Layout>
  );
}

export default ReconciledTransactionBlock;
