import { styled } from '@mui/material/styles';

export const BankHeader: any = styled('div')({
  gridColumnStart: 1,
  gridColumnEnd: 7,
  gridRow: 1,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '22px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginTop: '17px',
});

export const LedgerHeader: any = styled('div')({
  gridColumnStart: 1,
  gridColumnEnd: 7,
  gridRow: 3,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '22px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  marginTop: '17px',
});

export const Statements: any = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  // gap: '10px',
  gridAutoRows: 'minmax(50px, auto)',
});

export const LedgerStatement: any = styled('div')({
  gridColumnStart: 1,
  gridColumnEnd: 4,
  gridRow: 4,
});

export const LedgerStatementInDifferentTemplate: any = styled('div')({
  gridColumnStart: 4,
  gridColumnEnd: 7,
  gridRow: 4,
  marginLeft: '10px',
});

export const BankStatement: any = styled('div')({
  gridColumnStart: 1,
  gridColumnEnd: 4,
  gridRow: 2,
});

export const BankStatementInDifferentTemplate: any = styled('div')({
  gridColumnStart: 4,
  gridColumnEnd: 7,
  gridRow: 2,
  marginBottom: '2rem',
  marginLeft: '10px',
});

// export const InformationBlock: any = styled('div')({
//   gridColumnStart: 4,
//   gridColumnEnd: 6,
//   gridRowStart: 1,
//   gridRowEnd: 5,
//   backgroundColor: 'rgba(255, 255, 255, 1)',
//   boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
//   border: '1px solid rgba(235, 235, 235, 1)',
//   boxSizing: 'border-box',
//   borderRadius: 'var(--border-radius)',
//   padding: '10px 20px',
//   overflow: 'hidden',
//   height: 'auto',
//   width: '100%',
// });
