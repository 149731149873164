import { observer } from 'mobx-react-lite';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import LockReconciliationReportDialog from './component';

function LockReconciliationReportDialogController() {
  const reportViewModel = useReconciliationReportViewModel();

  if (!reportViewModel) {
    return null;
  }
  return (
    <LockReconciliationReportDialog
      open={reportViewModel.isLockReconciliationReportDialogVisible}
      closeDialog={reportViewModel.hideLockReconciliationReportDialog}
      submitAction={reportViewModel.lockReconciliationReport}
    />
  );
}

const LockReconciliationReportDialogPage = observer(LockReconciliationReportDialogController);

// eslint-disable-next-line import/prefer-default-export
export { LockReconciliationReportDialogPage as LockReconciliationReportDialog };
