import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../../CreateDialog/CreateDialog';

interface DeleteReconciliationReportDialogProps {
  open: boolean;
  submitAction: () => void;
  closeDialog: () => void;
}
function DeleteReconciliationReportDialog(props: DeleteReconciliationReportDialogProps) {
  const { open, closeDialog, submitAction } = props;
  return (
    <CreateDialog
      title="Are you sure you want to delete this reconciliation report?"
      open={open}
      closeDialog={closeDialog}
      action={<FilledButton content="Continue" onClick={submitAction} />}
      content={<> </>}
    />
  );
}

export default DeleteReconciliationReportDialog;
