import FilledButton from '../Buttons/FilledButton';
import CreateDialog from '../CreateDialog/CreateDialog';

interface ErrorDialogProps {
  open: boolean;
  closeDialog: () => void;
  submit: () => void;
  errorMessage: string;
  errorButtonMessage: string;
  errorTitle: string;
}
function ErrorDialog(props: ErrorDialogProps) {
  const { open, submit, closeDialog, errorMessage, errorButtonMessage, errorTitle } = props;

  return (
    <CreateDialog
      title={errorTitle}
      isError
      open={open}
      closeDialog={closeDialog}
      content={<div>{errorMessage}</div>}
      action={
        <FilledButton
          content={errorButtonMessage} // Confirmation button message
          onClick={submit}
        />
      }
    />
  );
}

export default ErrorDialog;