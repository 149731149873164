import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from '../Input';

interface NewCategoryDialogProps {
  open: boolean;
  categoryName: string;
  setCategoryName: (value: string) => void;
  areRequiredFieldsSet: boolean;
  closeDialog: () => void;
  submit: () => void;
}
function NewCategoryDialog(props: NewCategoryDialogProps) {
  const { open, categoryName, setCategoryName, areRequiredFieldsSet, submit, closeDialog } = props;

  return (
    <CreateDialog
      title="Create Category"
      open={open}
      closeDialog={closeDialog}
      content={
        <InputBlock
          name="Category Name"
          input={
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={categoryName}
              onChange={(event) => setCategoryName(event.target.value)}
            />
          }
          required
        />
      }
      action={<FilledButton disabled={!areRequiredFieldsSet} content="Add" onClick={submit} />}
    />
  );
}

export default NewCategoryDialog;
