import { styled } from '@mui/material/styles';

const TableHeaders = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-between',
  padding: '15px 30px',
});

export default TableHeaders;
