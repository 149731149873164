import React, { JSX } from 'react';
import { LTransactions, LTransaction, LTAction } from './Styles';
import LedgerTransactionBlock from '../../Transaction/LedgerTransaction/LedgerTransaction';
import editIcon from '../../../../assets/edit.svg';
import { ManualTransaction } from '../../../../models/ManualReconciliation';

interface LedgerTransactionsProps {
  transactions: ManualTransaction[];
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  showEditLedgerTransactionDialog: (ledgerTransaction: ManualTransaction) => void;
}

function LedgerTransactions(props: LedgerTransactionsProps): JSX.Element {
  const { transactions, selectOrUnselectLedgerTransaction, showEditLedgerTransactionDialog } =
    props;
  return (
    <LTransactions>
      {transactions.map((transaction, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <LTransaction key={index}>
          <LedgerTransactionBlock
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            transaction={transaction}
            selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
          >
            {/* <LTAction>
              <button
                type="button" // Add the type attribute with the value "button"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                }}
                aria-label="Edit"
                onClick={() => showEditLedgerTransactionDialog(transaction)}
              >
                <img src={editIcon} alt="edit" />
              </button>
            </LTAction> */}
          </LedgerTransactionBlock>
        </LTransaction>
      ))}
      {/* <FilledButton content="New Ledger Transaction" /> */}
    </LTransactions>
  );
}
export default LedgerTransactions;
