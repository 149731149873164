import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SuggestedMatchesPage } from './page';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';
import {
  useSuggestedMatchesViewModel,
  useSuggestedMatchesViewModelManager,
} from '../../view-models/SuggestedMatchesViewModel';

export const SuggestedMatchesController: React.FC = () => {
  const suggestedMatchesViewModelManager = useSuggestedMatchesViewModelManager();
  const suggestedMatchesViewModel = useSuggestedMatchesViewModel();
  const entityViewModel = useEntityViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  useEffect(() => {
    if (suggestedMatchesViewModel === null) suggestedMatchesViewModelManager.setViewModel();
  }, []);

  const params = useParams();
  const navigate = useNavigate();
  const reconciliationReportId = params.id!;

  useEffect(() => {
    if (suggestedMatchesViewModel)
      suggestedMatchesViewModel.setReconciliationReportId(reconciliationReportId);
  }, [reconciliationReportId, suggestedMatchesViewModel]);

  useEffect(() => {
    // SOS DO NOT REMOVE THESE LOGS
    console.log(
      '[SuggestedMatchesController] [SuggestedMatchesViewModel] suggestedMatchesViewModel.suggestedMatches',
      suggestedMatchesViewModel?.suggestedMatches
    );
    console.log(
      '[SuggestedMatchesController] [SuggestedMatchesViewModel] suggestedMatchesViewModel.suggestedMatchesLoaded',
      suggestedMatchesViewModel?.suggestedMatchesLoaded
    );
    if (
      suggestedMatchesViewModel &&
      suggestedMatchesViewModel.suggestedMatchesLoaded &&
      suggestedMatchesViewModel.suggestedMatches.length === 0
    ) {
      navigate(`/reconciliation-report/${reconciliationReportId}`);
    }
  }, [suggestedMatchesViewModel, suggestedMatchesViewModel?.suggestedMatches]);

  if (suggestedMatchesViewModel)
    return (
      <SuggestedMatchesPage
        suggestedMatches={suggestedMatchesViewModel.suggestedMatches}
        status="success"
        currentEntity={entityViewModel.entity.data}
        currentReconciliationAccount={reconciliationAccountViewModel.reconciliationAccount.data}
        currentReconciliationReport={reconciliationReportId}
        acceptSuggestedMatch={suggestedMatchesViewModel.acceptSuggestedMatch}
        rejectSuggestedMatch={suggestedMatchesViewModel.rejectSuggestedMatch}
        acceptSuggestions={suggestedMatchesViewModel.acceptSuggestedMatches}
        rejectSuggestions={suggestedMatchesViewModel.rejectSuggestedMatches}
        externalDocumentType={reconciliationAccountViewModel.externalDocumentType}
        ledgerDocumentType={reconciliationAccountViewModel.ledgerDocumentType}
      />
    );
  return null;
};

const observerPage = observer(SuggestedMatchesController);

export { observerPage as SuggestedMatchesControllerPage };
