/* eslint-disable class-methods-use-this */
import { createContext, useContext, useMemo } from 'react';
import { action, autorun, computed, makeAutoObservable, observable } from 'mobx';
import { useIamRepository, useResourcesRepository } from '../context/DI';
import * as Resources from '../models/Resources';
import {
  EntityFetchError,
  IResourcesRepository,
  UpdateEntityError,
  UpdateWorkspaceError,
  WorkspaceFetchError,
  WorkspaceSettingsFetchError,
} from '../infra/repositories/resources';
import { AsyncStatus, State, initState, cachedState, fetchedState } from '../types';
import { CategoryItemDeletedPayload, CategoryItemPayload, EventBus, Events } from '../Events';
import { Category, Entity, StopListItem, Workspace, WorkspaceSettings } from '../models/Resources';
import {
  GetUsersError,
  IIamRepository,
  WorkspaceUserCreatedError,
} from '../infra/repositories/iam';
import { AVAILABLE_USER_ROLES, User, UserRoleType } from '../models/User';
import { autogeneratePassword } from '../common';

interface IWorkspacesViewModel {
  name: string;
  country: string;
  language: string;
  areRequiredFieldsSet: boolean;
  workspaces: State<Workspace[], WorkspaceFetchError>;
  selectedWorkspace: State<Workspace | null, WorkspaceFetchError>;
  hasWorkspaces: boolean;
  isCreateWorkspaceDialogVisible: boolean;
  isAddStopListItemDialogVisible: boolean;
  workspaceCreationStatus: AsyncStatus;
  workspaceSettings: State<WorkspaceSettings | null, WorkspaceSettingsFetchError>;
  availableUserRoles: UserRoleType[];
  createWorkspace: () => Promise<void>;
  showCreateWorkspaceDialog: () => void;
  hideCreateWorkspaceDialog: () => void;
  setName: (value: string) => void;
  setCountry: (value: string) => void;
  setLanguage: (value: string) => void;
  deleteWorkspace: () => Promise<void>;

  stopListItemName: string;
  categoryName: string;
  categoryItemName: string;
  setStopListItemName: (value: string) => void;
  setCategoryName: (value: string) => void;
  setCategoryItemName: (value: string) => void;
  showAddStopListItemDialog: () => void;
  hideAddStopListItemDialog: () => void;
  showAddCategoryDialog: () => void;
  hideAddCategoryDialog: () => void;
  showAddCategoryItemDialog: (categoryId: string) => void;
  hideAddCategoryItemDialog: () => void;
  showEditCategoryItemDialog: (categoryId: string, categoryItem: string) => void;
  hideEditCategoryItemDialog: () => void;

  updateStopListItem: (id: string) => Promise<void>;
  deleteStopListItem: (id: string) => Promise<void>;
  addStopListItem: () => Promise<void>;
  addNewCategory: () => Promise<void>;
  updateCategoryName: (categoryId: string) => Promise<void>;
  deleteCategory: (categoryId: string) => Promise<void>;
  addNewCategoryItem: () => Promise<void>;
  updateCategoryItem: () => Promise<void>;
  deleteCategoryItem: () => Promise<void>;
}

class WorkspacesViewModel implements IWorkspacesViewModel {
  private readonly CALLER = 'WorkspacesViewModel';

  private _workspaces: State<Workspace[], WorkspaceFetchError> = initState<
    Workspace[],
    WorkspaceFetchError
  >([]);

  private _selectedWorkspace: State<Workspace | null, WorkspaceFetchError> = initState(null);

  private _workspaceSettings: State<WorkspaceSettings | null, WorkspaceSettingsFetchError> =
    initState<WorkspaceSettings | null, WorkspaceSettingsFetchError>(null);

  private _showCreateWorkspaceDialog = false;

  private _isAddStopListItemDialogVisible = false;

  private _isAddCategoryDialogVisible = false;

  private _isAddCategoryItemDialogVisible = false;

  private _isEditCategoryItemDialogVisible = false;

  private _categoryIdDialog: string | null = null;

  private _categoryItemIdDialog: string | null = null;

  private _draftWorkspace: Workspace = {
    id: '',
    name: '',
    country: '',
    language: '',
    createdAt: 0,
    updatedAt: 0,
    entities: [],
  };

  private _updateParams: Resources.EditWorkspaceParams = {
    name: '',
    country: '',
    language: '',
  };

  private _draftStopListItemName = '';

  private _draftCategoryName = '';

  private _draftCategoryItemName = '';

  private _usersDetails: User[] = [];

  private _isWorkspacePath: boolean = false;

  @observable
  workspaceCreationStatus: AsyncStatus = AsyncStatus.IDLE;

  constructor(
    private resourcesRepository: IResourcesRepository,
    private iamRepository: IIamRepository
  ) {
    makeAutoObservable(this, {
      workspaces: computed,
      users: computed,
      hasWorkspaces: computed,
      isCreateWorkspaceDialogVisible: computed,
      isAddStopListItemDialogVisible: computed,
      isAddCategoryDialogVisible: computed,
      isAddCategoryItemDialogVisible: computed,
      isEditCategoryItemDialogVisible: computed,
      selectedWorkspace: computed,
      workspaceSettings: computed,
      initializeUpdateParams: action,
      workspaceUpdatedHandler: action,
      workspaceDeleteHandler: action,
    });

    autorun(() => {
      if (this._selectedWorkspace) {
        this.initializeUpdateParams();
      }
    });
    EventBus.subscribe(Events.WORKSPACES_FETCH_SELECTED, this.selectedWorkspaceFetchHandler);
    EventBus.subscribe(Events.WORKSPACES_FETCH, this.workspacesFetchHandler);
    EventBus.subscribe(Events.WORKSPACE_CREATE, this.workspaceCreateHandler);
    EventBus.subscribe(Events.ENTITY_CREATE, this.entityCreated);
    EventBus.subscribe(Events.ENTITY_UPDATE, this.entityUpdated);
    EventBus.subscribe(Events.WORKSPACE_UPDATE, this.workspaceUpdatedHandler);
    EventBus.subscribe(Events.WORKSPACE_DELETE, this.workspaceDeleteHandler);
    EventBus.subscribe(Events.ENTITY_DELETE, this.entityDeleteHandler);

    EventBus.subscribe(Events.WORKSPACE_SELECT, this.fetchWorkspaceSettings);
    EventBus.subscribe(Events.WORKSPACE_SETTINGS_FETCH, this.workspaceSettingsFetchHandler);
    EventBus.subscribe(Events.WORKSPACE_STOP_LIST_ITEM_CREATE, this.stopListItemCreatedHandler);
    EventBus.subscribe(Events.WORKSPACE_STOP_LIST_ITEM_DELETE, this.stopListItemDeletedHandler);
    EventBus.subscribe(Events.WORKSPACE_STOP_LIST_ITEM_UPDATE, this.stopListItemUpdatedHandler);
    EventBus.subscribe(Events.WORKSPACE_CATEGORY_CREATE, this.categoryCreatedHandler);
    EventBus.subscribe(Events.WORKSPACE_CATEGORY_DELETE, this.categoryDeletedHandler);
    EventBus.subscribe(Events.WORKSPACE_CATEGORY_UPDATE, this.categoryUpdatedHandler);
    EventBus.subscribe(Events.WORKSPACE_CATEGORY_ITEM_CREATE, this.categoryItemCreatedHandler);
    EventBus.subscribe(Events.WORKSPACE_CATEGORY_ITEM_DELETE, this.categoryItemDeletedHandler);
    EventBus.subscribe(Events.WORKSPACE_CATEGORY_ITEM_UPDATE, this.categoryItemUpdatedHandler);
    EventBus.subscribe(Events.WORKSPACE_USER_CREATED, this.workspaceUserCreatedHandler);
    EventBus.subscribe(Events.WORKSPACE_USERS_FETCH, this.usersFetchedHandler);
  }

  get isWorkspacePath() {
    return this._isWorkspacePath;
  }

  get workspaces() {
    return this._workspaces;
  }

  get hasWorkspaces() {
    console.log('hasWorkspaces', this._workspaces.data);
    return (this._workspaces.data && this._workspaces.data.length > 0) || false;
  }

  get selectedWorkspace(): State<Workspace | null, WorkspaceFetchError> {
    if (
      this._selectedWorkspace.data === null &&
      this._workspaces.data &&
      this._workspaces.data.length > 0 &&
      (this._workspaces.status === AsyncStatus.SUCCESS ||
        this._workspaces.status === AsyncStatus.CACHED)
    ) {
      console.log('[WorkspaceViewModel] [Workspace] selectWorkspace', this._workspaces.data[0].id);
      this.selectWorkspace(this._workspaces.data[0].id);
      return {
        data: this._workspaces.data[0],
        status: this._workspaces.status,
        fetchedAt: this._workspaces.fetchedAt,
        error: null,
      };
    }
    return this._selectedWorkspace;
  }

  get isCreateWorkspaceDialogVisible() {
    return this._showCreateWorkspaceDialog;
  }

  get isAddStopListItemDialogVisible() {
    return this._isAddStopListItemDialogVisible;
  }

  get isAddCategoryDialogVisible() {
    return this._isAddCategoryDialogVisible;
  }

  get isAddCategoryItemDialogVisible() {
    return this._isAddCategoryItemDialogVisible;
  }

  get isEditCategoryItemDialogVisible() {
    return this._isEditCategoryItemDialogVisible;
  }

  get areRequiredFieldsSet() {
    return (
      this._draftWorkspace.name !== '' &&
      this._draftWorkspace.country !== '' &&
      this._draftWorkspace.language !== ''
    );
  }

  get workspaceCountries() {
    return ['Cyprus', 'Greece', 'USA', 'UK'];
  }

  get workspaceLanguages() {
    return ['el-gr', 'en-gr', 'el-cy', 'en-cy', 'en-us', 'en-gb'];
  }

  get users() {
    return this._usersDetails;
  }

  get updateParams(): Resources.EditWorkspaceParams {
    return this._updateParams;
  }

  get name(): string {
    return this._draftWorkspace.name;
  }

  get country(): string {
    return this._draftWorkspace.country;
  }

  get language(): string {
    return this._draftWorkspace.language;
  }

  get workspaceSettings() {
    return this._workspaceSettings;
  }

  get stopListItemName(): string {
    return this._draftStopListItemName;
  }

  get categoryName(): string {
    return this._draftCategoryName;
  }

  get categoryItemName(): string {
    return this._draftCategoryItemName;
  }

  // this could be dynamic depending on the role of the current user
  get availableUserRoles(): UserRoleType[] {
    return [
      AVAILABLE_USER_ROLES.WORKSPACE.WORKSPACE_MEMBER,
      AVAILABLE_USER_ROLES.WORKSPACE.WORKSPACE_ADMIN,
    ];
  }

  setIsWorkspacePath = (isWorkspacePath: boolean): void => {
    this._isWorkspacePath = isWorkspacePath;
  };

  loadWorkspaces = (): void => {
    this.resourcesRepository.fetchSelectedWorkspace();
    this._workspaces = { ...this._workspaces, status: AsyncStatus.PENDING };
    this.fetchWorkspaces();
  };

  @action
  initializeUpdateParams() {
    this._updateParams = {
      name: this._selectedWorkspace.data?.name || '',
      country: this._selectedWorkspace.data?.country || '',
      language: this._selectedWorkspace.data?.language || '',
    };
  }

  @action
  setName = (name: string): void => {
    this._draftWorkspace.name = name;
  };

  @action
  setCountry = (country: string): void => {
    this._draftWorkspace.country = country;
  };

  @action
  setLanguage = (language: string): void => {
    this._draftWorkspace.language = language;
  };

  @action
  fetchWorkspaces = (): void => {
    try {
      const repoResponse = this.resourcesRepository.getWorkspaces();
      const { status, data } = repoResponse;
      if (status === AsyncStatus.SUCCESS) this._workspaces = fetchedState(data);
      else if (status === AsyncStatus.CACHED && data) {
        this._workspaces = cachedState(data, Date.now());
      } // TODO get date from repoResponse
      this._showCreateWorkspaceDialog = this._workspaces.data?.length === 0;
    } catch (error) {
      console.error(error);
    }
  };

  fetchUsersDetails = (): void => {
    try {
      const userIds: string[] = [];
      this.selectedWorkspace.data?.usersList?.forEach((user) => {
        userIds.push(user.userId);
      });
      const event = Events.WORKSPACE_USERS_FETCH;
      this.iamRepository.getUsersDetails(userIds, event);
    } catch (error) {
      console.error(error);
    }
  };

  usersFetchedHandler = (users: State<User[], GetUsersError>) => {
    if (users.status === AsyncStatus.ERROR) {
      console.error(users.error);
      return;
    }

    if (users.data !== null && users.status === AsyncStatus.SUCCESS) {
      this._usersDetails = users.data;
    }
  };

  createUserWithEmailAndRoleId = async (email: string, roleId: string) => {
    try {
      const password = autogeneratePassword();
      const userId = await this.iamRepository.createWorkspaceUserWithEmailPasswordAndRoleId({
        email,
        password,
        roleId,
      });

      if (userId)
        this.selectedWorkspace.data?.usersList?.push({
          userId,
          roleId,
        });
      console.log('[createUserWithEmailPasswordAndRoleId] userId', userId);
    } catch (error) {
      console.error('[createUserWithEmailPasswordAndRoleId] error', error);
    }
  };

  workspaceUserCreatedHandler = (data: State<string, WorkspaceUserCreatedError>) => {
    console.log('inside workspaceUserCreatedHandler');
    if (data.status === AsyncStatus.ERROR) {
      console.error(data.error);
      return;
    }

    if (data.data !== null && data.status === AsyncStatus.SUCCESS) {
      this.fetchUsersDetails();
    }
  };

  @action
  createWorkspace = async (): Promise<void> => {
    const params: Resources.CreateWorkspaceParams = {
      name: this._draftWorkspace.name,
      country: this._draftWorkspace.country,
      language: this._draftWorkspace.language,
    };
    if (!this.areRequiredFieldsSet) {
      throw new Error('Required fields are not set');
    }
    this.workspaceCreationStatus = AsyncStatus.PENDING;
    await this.resourcesRepository.createWorkspace(params);
  };

  deleteWorkspace = async (): Promise<void> => {
    try {
      await this.resourcesRepository.deleteWorkspace();
    } catch (error: any) {
      console.error('Delete workspace error:', error);
    }
  };

  @action
  showCreateWorkspaceDialog = (): void => {
    this._showCreateWorkspaceDialog = true;
  };

  @action
  hideCreateWorkspaceDialog = (): void => {
    if (this._workspaces && this._workspaces.data && this._workspaces.data.length > 0) {
      this._showCreateWorkspaceDialog = false;
    }
  };

  @action
  selectWorkspace = (workspaceInfo: string | Workspace): void => {
    const workspaceId = typeof workspaceInfo === 'string' ? workspaceInfo : workspaceInfo.id;
    const workspace =
      typeof workspaceInfo === 'object'
        ? workspaceInfo
        : this._workspaces.data?.find(
            (workspaceCandidate) => workspaceCandidate.id === workspaceId
          );
    if (workspace) {
      const selectedWorkspaceState: State<Workspace, WorkspaceFetchError> = {
        data: workspace,
        status: this._workspaces.status as AsyncStatus.CACHED | AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
      this._selectedWorkspace = selectedWorkspaceState;
      EventBus.emit(Events.WORKSPACE_SELECT, selectedWorkspaceState);
    }
  };

  @action
  updateWorkspace = async (
    workspaceParams: Partial<Resources.EditWorkspaceParams>
  ): Promise<void> => {
    try {
      await this.resourcesRepository.updateWorkspace({
        ...workspaceParams,
        workspaceId: '',
      });
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  updateWorkspaceName = (name: string) => {
    this._updateParams = {
      ...this._updateParams,
      name,
    };
  };

  @action
  updateWorkspaceCountry = (country: string) => {
    this._updateParams = {
      ...this._updateParams,
      country,
    };
  };

  @action
  updateWorkspaceLanguage = (language: string) => {
    this._updateParams = {
      ...this._updateParams,
      language,
    };
  };

  @action
  fetchWorkspaceSettings = (): void => {
    try {
      const repoResponse = this.resourcesRepository.getWorkspaceSettings();
      const { status, data } = repoResponse;
      if (status === AsyncStatus.SUCCESS) this._workspaceSettings = fetchedState(data);
      else if (status === AsyncStatus.CACHED && data) {
        this._workspaceSettings = cachedState(data, Date.now());
      }
    } catch (error) {
      console.error(error);
    }
  };

  @action
  private selectedWorkspaceFetchHandler = (
    workspace: State<Workspace | null, WorkspaceFetchError>
  ) => {
    console.log('[WorkspacesViewModel] [Workspace] selectedWorkspaceFetchHandler', workspace);
    this._selectedWorkspace = workspace;
    if (workspace.data) {
      const selectedWorkspaceState: State<Workspace, WorkspaceFetchError> = {
        data: workspace.data,
        status: workspace.status as AsyncStatus.CACHED | AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
      EventBus.emit(Events.WORKSPACE_SELECT, selectedWorkspaceState);
    }
  };

  @action
  private entityCreated = (entity: State<Entity | null, EntityFetchError>) => {
    if (this._selectedWorkspace && this._selectedWorkspace.data && entity && entity.data) {
      const slimEntity: Resources.EntitySlim = {
        id: entity.data.id,
        name: entity.data.name,
      };
      this._selectedWorkspace.data.entities = [
        ...this._selectedWorkspace.data.entities,
        slimEntity,
      ];
    }
  };

  @action
  private entityUpdated = (entity: State<Entity, UpdateEntityError>) => {
    if (this._selectedWorkspace && this._selectedWorkspace.data && entity && entity.data) {
      const entityData = entity.data;
      const entitiesData = this._selectedWorkspace.data.entities.map((e) => {
        if (e.id !== entityData.id) return e;
        return {
          id: entityData.id,
          name: entityData.name,
        };
      });
      this._selectedWorkspace.data.entities = entitiesData;
    }
  };

  @action
  workspacesFetchHandler = (repoResponse: State<Workspace[], WorkspaceFetchError>) => {
    if (repoResponse.data) {
      this._workspaces = repoResponse;
      this._showCreateWorkspaceDialog = !(
        (this._workspaces.data && this._workspaces.data.length > 0) ||
        false
      );
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  entityDeleteHandler = (entity: State<string | null, EntityFetchError>) => {
    if (this._selectedWorkspace && this._selectedWorkspace.data && entity && entity.data) {
      const entityId = entity.data;
      const entities = this._selectedWorkspace?.data.entities;
      const entitiesNew = entities.filter((e) => e.id !== entityId);
      this._selectedWorkspace.data.entities = entitiesNew;
    }
  };

  // @action
  workspaceUpdatedHandler = (workspace: State<Workspace, UpdateWorkspaceError>) => {
    if (workspace.status === AsyncStatus.ERROR) {
      console.error(workspace.error);
      return;
    }
    if (workspace.data !== null && workspace.status === AsyncStatus.SUCCESS) {
      this._selectedWorkspace = {
        data: workspace.data,
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
      if (this._workspaces.data !== null) {
        const workspacesData = this._workspaces.data.map((w) =>
          w.id !== workspace.data.id ? w : workspace.data
        );

        this._workspaces = {
          ...this._workspaces,
          data: workspacesData,
        };
      }
    }
  };

  workspaceDeleteHandler = (workspace: State<string, WorkspaceFetchError>) => {
    if (workspace.status === AsyncStatus.ERROR) {
      console.error(workspace.error);
      return;
    }
    if (workspace.data !== null && workspace.status === AsyncStatus.SUCCESS) {
      this._selectedWorkspace = fetchedState(null);
      if (this._workspaces.data !== null) {
        const workspacesNew = this._workspaces.data?.filter((ws) => ws.id !== workspace.data);
        this._workspaces = {
          ...this._workspaces,
          data: workspacesNew,
        };
      }
    }
    if (this._workspaces.data !== null && this._workspaces.data.length === 0) {
      EventBus.emit(Events.WORKSPACE_DELETE_LAST, null);
    }
  };

  @action
  private workspaceCreateHandler = (workspace: State<Workspace, WorkspaceFetchError>) => {
    if (workspace.status === AsyncStatus.ERROR) {
      console.error(workspace.error);
      return;
    }

    if (workspace.status === AsyncStatus.SUCCESS) {
      this.workspaceCreationStatus = AsyncStatus.IDLE;
      this.hideCreateWorkspaceDialog();
    }

    this._selectedWorkspace = {
      data: workspace.data,
      status: AsyncStatus.SUCCESS,
      fetchedAt: Date.now(),
      error: null,
    };

    if (this._workspaces.data !== null && workspace.data !== null) {
      this._workspaces = {
        ...this._workspaces,
        data: [...this._workspaces.data, workspace.data],
      };
    }
    this._showCreateWorkspaceDialog = !(
      (this._workspaces.data && this._workspaces.data.length > 0) ||
      false
    );
    this.selectWorkspace(workspace.data!.id);
  };

  @action
  private workspaceSettingsFetchHandler = (
    repoResponse: State<WorkspaceSettings, WorkspaceFetchError>
  ) => {
    if (repoResponse.data) {
      this._workspaceSettings = repoResponse;
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  updateStopListItem = async (id: string): Promise<void> => {
    try {
      const newValue = this._draftStopListItemName;
      await this.resourcesRepository.editWorkspaceStopListItem(id, newValue);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setStopListItemName('');
    }
  };

  @action
  deleteStopListItem = async (id: string) => {
    try {
      await this.resourcesRepository.deleteWorkspaceStopListItem(id);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  addStopListItem = async () => {
    try {
      const value = this._draftStopListItemName;
      await this.resourcesRepository.addWorkspaceStopListItem(value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setStopListItemName('');
      this.hideAddStopListItemDialog();
    }
  };

  @action
  addNewCategory = async () => {
    try {
      const value = this._draftCategoryName;
      await this.resourcesRepository.addWorkspaceCategory(value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryName('');
      this.hideAddCategoryDialog();
    }
  };

  @action
  updateCategoryName = async (categoryId: string) => {
    try {
      const value = this._draftCategoryName;
      await this.resourcesRepository.editWorkspaceCategoryName(categoryId, value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryName('');
    }
  };

  @action
  deleteCategory = async (categoryId: string) => {
    try {
      await this.resourcesRepository.deleteWorkspaceCategory(categoryId);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  addNewCategoryItem = async () => {
    try {
      const value = this._draftCategoryItemName;
      const categoryId = this._categoryIdDialog;
      if (!categoryId) {
        throw new Error('Category ID is not set');
      }
      await this.resourcesRepository.addWorkspaceCategoryItem(categoryId, value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryItemName('');
      this.hideAddCategoryItemDialog();
    }
  };

  @action
  updateCategoryItem = async () => {
    try {
      const value = this._draftCategoryItemName;
      const categoryId = this._categoryIdDialog;
      const itemId = this._categoryItemIdDialog;
      if (!categoryId || !itemId) {
        throw new Error('Category ID or Item ID is not set');
      }
      await this.resourcesRepository.editWorkspaceCategoryItem(categoryId, itemId, value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryItemName('');
      this.hideEditCategoryItemDialog();
    }
  };

  @action
  deleteCategoryItem = async () => {
    try {
      const categoryId = this._categoryIdDialog;
      const itemId = this._categoryItemIdDialog;
      if (!categoryId || !itemId) {
        throw new Error('Category ID or Item ID is not set');
      }
      await this.resourcesRepository.deleteWorkspaceCategoryItem(categoryId, itemId);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.hideEditCategoryItemDialog();
    }
  };

  @action
  showAddStopListItemDialog = (): void => {
    this._isAddStopListItemDialogVisible = true;
  };

  @action
  hideAddStopListItemDialog = (): void => {
    this._isAddStopListItemDialogVisible = false;
  };

  @action
  showAddCategoryDialog = (): void => {
    this._isAddCategoryDialogVisible = true;
  };

  @action
  hideAddCategoryDialog = (): void => {
    this._isAddCategoryDialogVisible = false;
  };

  @action
  showAddCategoryItemDialog = (categoryId: string): void => {
    this._isAddCategoryItemDialogVisible = true;

    this._categoryIdDialog = categoryId;
  };

  @action
  hideAddCategoryItemDialog = (): void => {
    this._isAddCategoryItemDialogVisible = false;
    this._categoryIdDialog = null;
  };

  @action
  showEditCategoryItemDialog = (categoryId: string, categoryItemId: string): void => {
    this._isEditCategoryItemDialogVisible = true;

    this._categoryIdDialog = categoryId;
    this._categoryItemIdDialog = categoryItemId;
    const item = this._workspaceSettings.data?.categories
      .find((c) => c.id === categoryId)
      ?.items.find((i) => i.id === categoryItemId);
    if (!item) {
      throw new Error('Category item not found');
    }
    this.setCategoryItemName(item.value);
  };

  @action
  hideEditCategoryItemDialog = (): void => {
    this._isEditCategoryItemDialogVisible = false;
    this.setCategoryItemName('');
    this._categoryIdDialog = null;
    this._categoryItemIdDialog = null;
  };

  @action
  setStopListItemName = (name: string): void => {
    this._draftStopListItemName = name;
  };

  @action
  setCategoryName = (value: string): void => {
    this._draftCategoryName = value;
  };

  @action
  setCategoryItemName = (value: string): void => {
    this._draftCategoryItemName = value;
  };

  @action
  private stopListItemCreatedHandler = (
    stopListItem: State<StopListItem, WorkspaceSettingsFetchError>
  ) => {
    if (stopListItem.status === AsyncStatus.ERROR) {
      console.error(stopListItem.error);
      return;
    }
    if (stopListItem.data !== null && stopListItem.status === AsyncStatus.SUCCESS) {
      const { data } = stopListItem;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories ?? [],
          stopList: [...(this._workspaceSettings.data?.stopList ?? []), data],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  stopListItemDeletedHandler = (stopListItem: State<string, WorkspaceSettingsFetchError>) => {
    if (stopListItem.status === AsyncStatus.ERROR) {
      console.error(stopListItem.error);
      return;
    }
    if (stopListItem.data !== null && stopListItem.status === AsyncStatus.SUCCESS) {
      const { data } = stopListItem;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories ?? [],
          stopList: this._workspaceSettings.data?.stopList
            ? this._workspaceSettings.data.stopList.filter((item) => item.id !== data)
            : [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  stopListItemUpdatedHandler = (stopListItem: State<StopListItem, WorkspaceSettingsFetchError>) => {
    if (stopListItem.status === AsyncStatus.ERROR) {
      console.error(stopListItem.error);
      return;
    }
    if (stopListItem.data !== null && stopListItem.status === AsyncStatus.SUCCESS) {
      const { data } = stopListItem;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories ?? [],
          stopList: this._workspaceSettings.data?.stopList
            ? this._workspaceSettings.data.stopList.map((item) =>
                item.id === data.id ? data : item
              )
            : [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryCreatedHandler = (category: State<Category, WorkspaceSettingsFetchError>) => {
    if (category.status === AsyncStatus.ERROR) {
      console.error(category.error);
      return;
    }
    if (category.data !== null && category.status === AsyncStatus.SUCCESS) {
      const { data } = category;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: [...(this._workspaceSettings.data?.categories ?? []), data],
          stopList: this._workspaceSettings.data?.stopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryDeletedHandler = (category: State<string, WorkspaceSettingsFetchError>) => {
    if (category.status === AsyncStatus.ERROR) {
      console.error(category.error);
      return;
    }
    if (category.data !== null && category.status === AsyncStatus.SUCCESS) {
      const { data } = category;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories
            ? this._workspaceSettings.data.categories.filter((item) => item.id !== data)
            : [],
          stopList: this._workspaceSettings.data?.stopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryUpdatedHandler = (message: State<Category, WorkspaceSettingsFetchError>) => {
    if (message.status === AsyncStatus.ERROR) {
      console.error(message.error);
      return;
    }
    if (message.data !== null && message.status === AsyncStatus.SUCCESS) {
      const { data } = message;
      // Only the name of the category is updated
      const { name } = data;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories
            ? this._workspaceSettings.data.categories.map((category) =>
                category.id !== data.id ? category : { ...category, name }
              )
            : [],
          stopList: this._workspaceSettings.data?.stopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryItemCreatedHandler = (
    categoryItem: State<CategoryItemPayload, WorkspaceSettingsFetchError>
  ) => {
    if (categoryItem.status === AsyncStatus.ERROR) {
      console.error(categoryItem.error);
      return;
    }
    if (categoryItem.data !== null && categoryItem.status === AsyncStatus.SUCCESS) {
      const { data } = categoryItem;
      const { categoryId } = data;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories
            ? this._workspaceSettings.data.categories.map((category) =>
                category.id === categoryId
                  ? {
                      ...category,
                      items: [...(category.items ?? []), data],
                    }
                  : category
              )
            : [],
          stopList: this._workspaceSettings.data?.stopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryItemDeletedHandler = (
    categoryItem: State<CategoryItemDeletedPayload, WorkspaceSettingsFetchError>
  ) => {
    if (categoryItem.status === AsyncStatus.ERROR) {
      console.error(categoryItem.error);
      return;
    }
    if (categoryItem.data !== null && categoryItem.status === AsyncStatus.SUCCESS) {
      const { data } = categoryItem;
      const { itemId, categoryId } = data;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories
            ? this._workspaceSettings.data.categories.map((category) =>
                category.id === categoryId
                  ? {
                      ...category,
                      items: category.items.filter((item) => item.id !== itemId),
                    }
                  : category
              )
            : [],
          stopList: this._workspaceSettings.data?.stopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryItemUpdatedHandler = (
    categoryItem: State<CategoryItemPayload, WorkspaceSettingsFetchError>
  ) => {
    if (categoryItem.status === AsyncStatus.ERROR) {
      console.error(categoryItem.error);
      return;
    }
    if (categoryItem.data !== null && categoryItem.status === AsyncStatus.SUCCESS) {
      const { data } = categoryItem;
      const { id, categoryId } = data;
      this._workspaceSettings = {
        ...this._workspaceSettings,
        data: {
          categories: this._workspaceSettings.data?.categories
            ? this._workspaceSettings.data.categories.map((category) =>
                category.id === categoryId
                  ? {
                      ...category,
                      items: category.items.map((item) => (item.id === id ? data : item)),
                    }
                  : category
              )
            : [],
          stopList: this._workspaceSettings.data?.stopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };
}

const WorkspacesViewModelContext = createContext<WorkspacesViewModel | null>(null);

interface WorkspacesViewModelProviderProps {
  children: React.ReactNode;
}

function WorkspacesViewModelProvider(props: WorkspacesViewModelProviderProps): JSX.Element {
  const { children } = props;
  const resourcesRepository = useResourcesRepository();
  const iamRepository = useIamRepository();
  const workspacesViewModel = useMemo(
    () => new WorkspacesViewModel(resourcesRepository, iamRepository),
    [resourcesRepository]
  );

  return (
    <WorkspacesViewModelContext.Provider value={workspacesViewModel}>
      {children}
    </WorkspacesViewModelContext.Provider>
  );
}

const useWorkspacesViewModel = () => {
  const viewModel = useContext(WorkspacesViewModelContext);
  if (!viewModel) throw new Error('No WorkspacesViewModel provided');
  return viewModel;
};

export {
  WorkspacesViewModel,
  WorkspacesViewModelProvider,
  WorkspacesViewModelContext,
  useWorkspacesViewModel,
};
