import { styled } from '@mui/material/styles';

export const DescriptionSpan: any = styled('span')({
  whiteSpace: 'pre-wrap',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-content-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Description: any = styled('div')({
  display: '-webkit-box',
  WebkitLineClamp: 2, // Number of lines you want to display
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  textAlign: 'left',
  // whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 'var(--transaction-content-font-size)',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: 'var(--description-width-sm)',
  height: 'auto',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

export const SmallScreen: any = styled('div')({});
export const LargeScreen: any = styled('div')({});
