import { styled } from '@mui/material/styles';

export const AmountSubheader: any = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '100%',
  fontSize: '24px',
  marginTop: '20px',
});

export const Title: any = styled('div')({
  display: 'flex',
  width: '350px',
});

export const Amount: any = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '160px',
});
