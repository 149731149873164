import Header from './Header';
import { Headers } from './Styles';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../../../models/Document';

interface HeadersBlockProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
}

function HeadersBlock(props: HeadersBlockProps): JSX.Element {
  const { externalDocumentType, ledgerDocumentType } = props;
  return (
    <Headers
      sx={{
        display: {
          xs: 'none',
          lg: 'flex',
        },
      }}
    >
      <Header name={`${externalDocumentType} Transactions`} type="bank" />
      <Header name={`${ledgerDocumentType} Transactions`} type="ledger" />
    </Headers>
  );
}
export default HeadersBlock;
