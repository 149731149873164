import * as XLSX from 'xlsx';
import { utils, writeFileXLSX } from 'xlsx';
import { createContext, useContext, useMemo } from 'react';
import { action, makeAutoObservable } from 'mobx';
import { ReconciliationReport } from '../models/ReconciliationReport';
import { IReconciliationReportRepository } from '../infra/repositories/reconciliation-report';
import { useReconciliationReportRepository } from '../context/DI';
import { FILE_TYPE, FileData } from '../models/UploadFileData';
import ReconciliationReportExcelFile from '../utils/report-to-excel';
import { sortNumbersArray } from '../utils/number';
import { STATUS_STATES } from '../types';

export enum CONVERTER_FORMATS {
  BANK_FORMAT = 'BANK_FORMAT',
  LEDGER_FORMAT = 'LEDGER_FORMAT',
}

export enum BanksSupported {
  BANK_OF_CYPRUS = 'BANK_OF_CYPRUS',
  HELLENIC_BANK = 'HELLENIC_BANK',
  EUROBANK = 'EUROBANK',
  NONE = 'Select Bank',
}

export enum LEDGER_ERP_OPTIONS {
  NONE = 'Select ERP',
  SOFIA_ERP = 'SOFIA_ERP',
}

export enum CUSTOM_OPTIONS {
  NONE = 'Select Custom',
}

enum FILE_SUFFIXES {
  PDF = '.pdf',
}

enum SHIFT_ROW_DIRECTION {
  UP = 'up',
  DOWN = 'down',
}

class ConvertersAndTemplatesViewModel {
  constructor(private reconciliationReportRepository: IReconciliationReportRepository) {
    makeAutoObservable(this);
  }

  selectedBank: BanksSupported = BanksSupported.NONE;

  selectedERP: LEDGER_ERP_OPTIONS = LEDGER_ERP_OPTIONS.NONE;

  selectedCustom: CUSTOM_OPTIONS = CUSTOM_OPTIONS.NONE;

  uploadedBankFile: File | null = null;

  uploadedPdfFile: File | null = null;

  uploadedLedgerFile: File | null = null;

  uploadedCustomFile: File | null = null;

  ledgerUploadFileStatus: STATUS_STATES = 'idle';

  bankUploadFileStatus: STATUS_STATES = 'idle';

  customUploadFileStatus: STATUS_STATES = 'idle';

  pdfUploadFileStatus: STATUS_STATES = 'idle';

  get ledgerERPOptions(): LEDGER_ERP_OPTIONS[] {
    return [LEDGER_ERP_OPTIONS.SOFIA_ERP];
  }

  get bankTransactionTypes(): BanksSupported[] {
    return [BanksSupported.BANK_OF_CYPRUS, BanksSupported.HELLENIC_BANK, BanksSupported.EUROBANK];
  }

  get customOptions(): CUSTOM_OPTIONS[] {
    return [];
  }

  downloadFile = (sheet: XLSX.WorkSheet, fileName: string) => {
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Reconciliation Report');

    const downloadedFileName = `${fileName.substring(
      0,
      fileName.length - 5
    )}_ReconcilioTemplate.xlsx`;
    writeFileXLSX(workbook, downloadedFileName);
  };

  clearOptionsAndFiles = (): void => {
    this.clearSelectedOptions();
    this.clearUploadedFiles();
  };

  private clearSelectedOptions = (): void => {
    this.selectedBank = BanksSupported.NONE;
    this.selectedERP = LEDGER_ERP_OPTIONS.NONE;
    this.selectedCustom = CUSTOM_OPTIONS.NONE;
  };

  private clearUploadedFiles = (): void => {
    this.uploadedBankFile = null;
    this.uploadedPdfFile = null;
    this.uploadedLedgerFile = null;
    this.uploadedCustomFile = null;
    this.ledgerUploadFileStatus = 'idle';
    this.bankUploadFileStatus = 'idle';
    this.customUploadFileStatus = 'idle';
    this.pdfUploadFileStatus = 'idle';
  };

  setLedgerUploadFileStatus = (status: STATUS_STATES): void => {
    this.ledgerUploadFileStatus = status;
  };

  setBankUploadFileStatus = (status: STATUS_STATES): void => {
    this.bankUploadFileStatus = status;
  };

  setCustomUploadFileStatus = (status: STATUS_STATES): void => {
    this.customUploadFileStatus = status;
  };

  setPdfUploadFileStatus = (status: STATUS_STATES): void => {
    this.pdfUploadFileStatus = status;
  };

  @action
  convertLedgerStatement = async (): Promise<void> => {
    if (!this.uploadedLedgerFile || this.selectedERP === LEDGER_ERP_OPTIONS.NONE) {
      return;
    }

    let finalSheet: XLSX.WorkSheet | null = null;
    switch (this.selectedERP) {
      case LEDGER_ERP_OPTIONS.SOFIA_ERP:
        finalSheet = await this.convertSofiaERPStatement(this.uploadedLedgerFile);
        break;
      default:
        throw new Error(`ERP ${this.selectedERP} not supported`);
    }

    if (finalSheet) {
      this.downloadFile(finalSheet, this.uploadedLedgerFile!.name);
      await this.reconciliationReportRepository.createConverterTracking({
        format: CONVERTER_FORMATS.LEDGER_FORMAT,
        type: this.selectedERP,
      });
      this.clearOptionsAndFiles();
    }
  };

  @action
  convertCustomDocument = async (): Promise<void> => {};

  @action
  convertBankStatement = async (
    initialSheet: XLSX.WorkSheet,
    fileName: string,
    bankType: string
  ): Promise<void> => {
    let finalSheet: XLSX.WorkSheet;
    switch (bankType) {
      case BanksSupported.BANK_OF_CYPRUS:
        finalSheet = this.convertBankOfCyprusStatement(initialSheet);
        break;
      case BanksSupported.HELLENIC_BANK:
        finalSheet = this.convertHellenicBankStatement(initialSheet);
        break;
      case BanksSupported.EUROBANK:
        finalSheet = this.convertEurobankStatement(initialSheet);
        break;
      default:
        throw new Error(`Bank ${bankType} not supported`);
    }

    if (finalSheet) {
      this.downloadFile(finalSheet, fileName);
      await this.reconciliationReportRepository.createConverterTracking({
        format: CONVERTER_FORMATS.BANK_FORMAT,
        type: bankType,
      });
      this.clearOptionsAndFiles();
    }
  };

  private handleDownloadPdfToExcel = (fileData: FileData[]) => {
    const workbook = ReconciliationReportExcelFile.pdfToExcelData(fileData);

    const fileName = `${this.getPdfFileName(this.uploadedPdfFile!)}.xlsx`;
    writeFileXLSX(workbook, fileName);
  };

  private getPdfFileName = (file: File): string => {
    const fileNameWithSuffix = file.name;
    const fileName = fileNameWithSuffix.split(FILE_SUFFIXES.PDF)[0];
    return fileName;
  };

  @action
  convertPdfDocument = async (): Promise<void> => {
    if (this.uploadedPdfFile) {
      const fileData = await this.reconciliationReportRepository.convertFile({
        file: this.uploadedPdfFile,
        fileType: FILE_TYPE.PDF,
      });
      this.handleDownloadPdfToExcel(fileData);
      this.clearOptionsAndFiles();
    }
  };

  @action
  handleCustomOptionChange = (selectedCustom: CUSTOM_OPTIONS): void => {
    this.selectedCustom = selectedCustom;
  };

  @action
  handleERPOptionChange = (selectedERP: LEDGER_ERP_OPTIONS): void => {
    this.selectedERP = selectedERP;
  };

  @action
  handleBankTypeChange = (selectedBank: BanksSupported): void => {
    this.selectedBank = selectedBank;
  };

  @action
  handleBankFileUpload = (file: File): void => {
    this.uploadedBankFile = file;
  };

  @action
  handlePdfFileUpload = (file: File): void => {
    this.uploadedPdfFile = file;
  };

  @action
  handleLedgerFileUpload = (file: File): void => {
    this.uploadedLedgerFile = file;
  };

  @action
  handleCustomFileUpload = (file: File): void => {
    this.uploadedCustomFile = file;
  };

  private async convertFileToSheet(file: File): Promise<XLSX.WorkSheet | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let worksheet: XLSX.WorkSheet | null = null;
      reader.onload = (e) => {
        const data = e.target?.result;

        // Check if the file is empty
        if (!data || (data instanceof ArrayBuffer && data.byteLength === 0)) {
          console.error('The file is empty.');
          return resolve(null);
        }

        // Convert data to binary
        if (!(data instanceof ArrayBuffer)) throw new Error('Expected ArrayBuffer');
        const binaryData = new Uint8Array(data);

        // Parse the binary data as an Excel file
        const workbook = XLSX.read(binaryData, {
          type: 'array',
          raw: true,
        });

        // Check if the workbook has sheets
        if (workbook.SheetNames.length === 0) {
          console.error('The workbook does not contain any sheets.');
          return resolve(null);
        }

        // Retrieve the name of a tab by its index.
        const sheetName = workbook.SheetNames[0];
        worksheet = workbook.Sheets[sheetName];
        return resolve(worksheet);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private async convertSofiaERPStatement(file: File): Promise<XLSX.WorkSheet | null> {
    const sheet = await this.convertFileToSheet(file);
    if (!sheet) {
      return null;
    }
    let res = this.deleteRows(sheet, [1, 2, 3]);
    res = this.moveColumn(res, 'N', 'B');
    res = this.deleteColumns(res, ['E', 'F', 'G', 'J', 'K', 'L', 'M', 'N']);
    res = this.deleteLastRows(res, 3);
    res = this.shiftRows(res, 1, SHIFT_ROW_DIRECTION.DOWN);
    res = this.insertDataToRow(
      res,
      [1, 2, 3, 4, 5, 6, 7],
      ['Date', 'Description', 'Reference', 'TransactionID', 'Debit', 'Credit', 'Balance'],
      1
    );
    res = this.sortRowsByDate(res);
    res = this.setDateFormat(res);
    return res;
  }

  private convertBankOfCyprusStatement(sheet: XLSX.WorkSheet): XLSX.WorkSheet {
    let res: XLSX.WorkSheet = sheet;
    res = this.deleteRows(res, [1, 2, 3, 4, 5, 6, 7]);
    res = this.deleteColumns(res, ['C', 'D', 'E', 'F', 'K', 'M']);

    res = this.moveColumn(res, 'G', 'D');

    return res;
  }

  private convertHellenicBankStatement(sheet: XLSX.WorkSheet): XLSX.WorkSheet {
    let res = this.deleteColumns(sheet, ['A', 'B', 'C', 'H']);

    res = this.insertColumnsWithHeaders(res, [3, 4], ['Reference', 'BankID']);
    res = this.renameColumnWithLetter(res, 'G', 'Balance');
    return res;
  }

  private convertEurobankStatement(sheet: XLSX.WorkSheet): XLSX.WorkSheet {
    let res = this.deleteRows(sheet, [1, 2, 3, 4, 5, 6, 7, 8, 10, 11]);
    res = this.moveColumn(res, 'A', 'D');
    res = this.insertColumnsWithHeaders(res, [5], ['Debit']);
    res = this.renameColumnWithLetter(res, 'F', 'Credit');
    res = this.moveNegativeValues(res, 'F', 'E');
    return res;
  }

  private insertColumnsWithHeaders(
    sheet: XLSX.WorkSheet,
    columnNumbers: number[],
    columnHeaders: string[]
  ): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      // Handle the error or return early if appropriate
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // Validate input arrays
    if (columnNumbers.length !== columnHeaders.length) {
      throw new Error('Number of columns and headers must be equal');
    }

    for (let i = 0; i < columnNumbers.length; i += 1) {
      const columnIndex = columnNumbers[i];

      // Validate column index
      if (columnIndex < 1 || columnIndex > sheetArray[0].length + 1) {
        throw new Error(`Invalid column index: ${columnIndex}`);
      }

      const newColumnHeader = columnHeaders[i];

      // Insert the new column header at the specified position
      sheetArray[0].splice(columnIndex - 1, 0, newColumnHeader);

      // Insert an empty value for the new column in each row
      for (let rowNum = 1; rowNum < sheetArray.length; rowNum += 1) {
        sheetArray[rowNum].splice(columnIndex - 1, 0, '');
      }
    }

    // Convert the updated array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    // Adjust the range after inserting columns
    const newRange = XLSX.utils.decode_range(sheetToUpdate['!ref']!);
    newRange.e.c += columnNumbers.length;
    sheetToUpdate['!ref'] = XLSX.utils.encode_range(newRange);

    return updatedSheet;
  }

  public renameColumnWithLetter(
    sheet: XLSX.WorkSheet,
    colLetter: string,
    newColumnName: string
  ): XLSX.WorkSheet {
    if (!sheet) {
      throw new Error('Sheet is undefined');
    }

    const colIndex = XLSX.utils.decode_col(colLetter);

    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    if (sheetArray.length === 0) {
      throw new Error('Sheet is empty');
    }

    if (colIndex < sheetArray[0].length) {
      sheetArray[0][colIndex] = newColumnName;
    } else {
      throw new Error(`Column index ${colIndex} is out of range`);
    }

    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);
    return updatedSheet;
  }

  private deleteColumns(sheet: XLSX.WorkSheet, columnIndexes: string[]): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    if (!sheetToUpdate) {
      throw new Error('Sheet is undefined');
    }

    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // Convert column letters to column numbers and sort in descending order
    const colNumbers = columnIndexes
      .map((colLetter) => XLSX.utils.decode_col(colLetter))
      .sort((a, b) => b - a);

    // eslint-disable-next-line no-restricted-syntax
    for (const colNum of colNumbers) {
      for (let rowNum = 0; rowNum < sheetArray.length; rowNum += 1) {
        if (sheetArray[rowNum].length > colNum) {
          sheetArray[rowNum].splice(colNum, 1);
        }
      }
    }

    // Rebuild the sheet from the modified array
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    // Adjust the range of the worksheet
    this.adjustSheetRange(updatedSheet);

    return updatedSheet;
  }

  private moveNegativeValues(
    sheet: XLSX.WorkSheet,
    fromColumn: string,
    toColumn: string
  ): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    if (!sheetToUpdate) {
      throw new Error('Sheet is undefined');
    }

    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    const fromColumnIndex = XLSX.utils.decode_col(fromColumn);
    const toColumnIndex = XLSX.utils.decode_col(toColumn);

    for (let rowNum = 0; rowNum < sheetArray.length; rowNum += 1) {
      if (
        sheetArray[rowNum].length > fromColumnIndex &&
        sheetArray[rowNum].length > toColumnIndex
      ) {
        const fromColumnValue = sheetArray[rowNum][fromColumnIndex];
        if (fromColumnValue) {
          const fromColumnValueNumber = Number(fromColumnValue);
          if (fromColumnValueNumber < 0) {
            sheetArray[rowNum][toColumnIndex] = Math.abs(fromColumnValueNumber).toString();
            sheetArray[rowNum][fromColumnIndex] = '';
          }
        }
      }
    }

    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    // Adjust the range of the worksheet
    this.adjustSheetRange(updatedSheet);

    return updatedSheet;
  }

  private adjustSheetRange(sheet: XLSX.WorkSheet): XLSX.WorkSheet {
    // Detect the maximum row and column index after modification
    let maxCol = 0;
    const maxRow = sheet['!ref'] ? XLSX.utils.decode_range(sheet['!ref']).e.r : 0;
    // eslint-disable-next-line no-plusplus
    for (let R = 0; R <= maxRow; ++R) {
      // eslint-disable-next-line no-plusplus
      for (let C = 0; sheet[XLSX.utils.encode_cell({ r: R, c: C })]; ++C) {
        if (C > maxCol) maxCol = C;
      }
    }

    // Set the new range
    // eslint-disable-next-line no-param-reassign
    sheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: maxRow, c: maxCol } });
    return sheet;
  }

  private deleteRows(sheet: XLSX.WorkSheet, rowIndexes: number[]): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      // Handle the error or return early if appropriate
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // Remove specified rows in reverse order
    for (let i = rowIndexes.length - 1; i >= 0; i -= 1) {
      const rowNum = rowIndexes[i] - 1;

      // Adjust the range after deleting a row
      const newRange = XLSX.utils.decode_range(sheetToUpdate['!ref']!);
      newRange.e.r -= 1;
      sheetToUpdate['!ref'] = XLSX.utils.encode_range(newRange);

      // Splice the entire row, considering Excel's internal representation
      sheetArray.splice(rowNum, 1);
    }

    // Convert the updated array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    // Set the date format for the entire first column
    for (
      let rowNum = 0;
      rowNum <= XLSX.utils.decode_range(sheetToUpdate['!ref']!).e.r;
      rowNum += 1
    ) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
      if (updatedSheet[cellAddress]) {
        updatedSheet[cellAddress].z = 'dd/mm/yyyy';
      }
    }

    return updatedSheet;
  }

  private deleteLastRows(sheet: XLSX.WorkSheet, numberOfRows: number): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows to count the total rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });
    const totalRows = sheetArray.length;

    // Calculate the row indexes for the last 'numberOfRows' rows
    const rowIndexes = [];
    for (let i = totalRows; i > totalRows - numberOfRows; i -= 1) {
      rowIndexes.push(i);
    }
    const reversedRowIndexes = rowIndexes.reverse();

    // Call the existing deleteRows function with the calculated indexes
    return this.deleteRows(sheetToUpdate, reversedRowIndexes);
  }

  private shiftRows(
    sheet: XLSX.WorkSheet,
    startRowIndex: number,
    direction: SHIFT_ROW_DIRECTION
  ): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });
    const totalRows = sheetArray.length;

    // Ensure the startRowIndex is within bounds
    if (startRowIndex < 1 || startRowIndex > totalRows) {
      throw new Error('Start row index is out of bounds');
    }

    if (direction === SHIFT_ROW_DIRECTION.DOWN) {
      // Shift rows down
      for (let i = totalRows - 1; i >= startRowIndex - 1; i -= 1) {
        sheetArray[i + 1] = sheetArray[i];
      }
      // Insert an empty row at the startRowIndex
      sheetArray[startRowIndex - 1] = new Array(sheetArray[0].length).fill('');
    } else if (direction === SHIFT_ROW_DIRECTION.UP) {
      // Shift rows up
      for (let i = startRowIndex - 1; i < totalRows - 1; i += 1) {
        sheetArray[i] = sheetArray[i + 1];
      }
      // Insert an empty row at the last position
      sheetArray[totalRows - 1] = new Array(sheetArray[0].length).fill('');
    } else {
      throw new Error(
        `Direction must be either ${SHIFT_ROW_DIRECTION.UP} or ${SHIFT_ROW_DIRECTION.DOWN}`
      );
    }

    // Convert the updated array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    return updatedSheet;
  }

  private moveColumn(
    sheet: XLSX.WorkSheet,
    fromColumnName: string,
    toColumnName: string
  ): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    const fromColumn = XLSX.utils.decode_col(fromColumnName);
    const toColumn = XLSX.utils.decode_col(toColumnName);

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      // Handle the error or return early if appropriate
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // Validate column indices
    if (
      fromColumn < 0 ||
      toColumn < 0 ||
      fromColumn > sheetArray[0].length ||
      toColumn > sheetArray[0].length
    ) {
      throw new Error('Invalid column indices');
    }

    // Move the column in each row
    for (let rowNum = 0; rowNum < sheetArray.length; rowNum += 1) {
      // Remove the column from its original position
      const removedColumn = sheetArray[rowNum].splice(fromColumn, 1)[0];

      // Insert the column at the new position
      sheetArray[rowNum].splice(toColumn, 0, removedColumn);
    }

    // Convert the updated array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    // Adjust the range after moving the column
    const newRange = XLSX.utils.decode_range(sheetToUpdate['!ref']!);
    newRange.e.c += toColumn - fromColumn;
    sheetToUpdate['!ref'] = XLSX.utils.encode_range(newRange);

    return updatedSheet;
  }

  private insertDataToRow(
    sheet: XLSX.WorkSheet,
    columnIndexes: number[],
    data: string[],
    rowIndex: number
  ): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // Ensure rowIndex is within bounds
    if (rowIndex < 1 || rowIndex > sheetArray.length + 1) {
      throw new Error('Row index is out of bounds');
    }

    // Ensure the length of columnIndexes and data match
    if (columnIndexes.length !== data.length) {
      throw new Error('Column indexes and data arrays must have the same length');
    }

    // Adjust rowIndex for 0-based index
    const targetRowIndex = rowIndex - 1;

    // Ensure the target row exists, or create a new row if it doesn't
    if (!sheetArray[targetRowIndex]) {
      sheetArray[targetRowIndex] = [];
    }

    // Insert headers at specified columns
    for (let i = 0; i < columnIndexes.length; i += 1) {
      const colIndex = columnIndexes[i] - 1; // Convert to 0-based index
      sheetArray[targetRowIndex][colIndex] = data[i];
    }

    // Convert the updated array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    return updatedSheet;
  }

  private sortRowsByDate(sheet: XLSX.WorkSheet): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // The first row contains headers, so start sorting from the second row
    const headers = sheetArray[0];
    const dataRows = sheetArray.slice(1);

    // Sort the data rows by the date in the first column
    const sortedDataRows = sortNumbersArray(dataRows);

    // Combine the headers and the sorted data rows back into one array
    const sortedSheetArray = [headers, ...sortedDataRows];

    // Convert the sorted array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sortedSheetArray);

    return updatedSheet;
  }

  private setDateFormat(sheet: XLSX.WorkSheet): XLSX.WorkSheet {
    const sheetToUpdate = sheet;

    // Check if sheetToUpdate is undefined
    if (!sheetToUpdate) {
      // Handle the error or return early if appropriate
      throw new Error('Sheet is undefined');
    }

    // Convert the sheet to an array of rows
    const sheetArray: string[][] = XLSX.utils.sheet_to_json(sheetToUpdate, { header: 1 });

    // Convert the updated array back to a sheet
    const updatedSheet = XLSX.utils.aoa_to_sheet(sheetArray);

    // Set the date format for the entire first column
    for (
      let rowNum = 0;
      rowNum <= XLSX.utils.decode_range(sheetToUpdate['!ref']!).e.r;
      rowNum += 1
    ) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
      if (updatedSheet[cellAddress]) {
        updatedSheet[cellAddress].z = 'dd/mm/yyyy';
      }
    }

    return updatedSheet;
  }

  handleDownloadLedgerTemplateButtonClick = () => {
    const ledgerData = [
      ['Date', 'Description', 'Reference', 'Ledger ID', 'Debit', 'Credit', 'Balance'],
    ];

    const ws = utils.json_to_sheet<ReconciliationReport>([]);

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, ws, 'Reconciliation Report');
    const fileName = 'Reconcilio - Ledger Upload Template.xlsx';

    // Add the data to the workbook
    utils.sheet_add_aoa(ws, ledgerData, {
      origin: 'A1',
    }); // start from first cell

    writeFileXLSX(workbook, fileName);
  };

  handleDownloadBankTemplateButtonClick = () => {
    const ledgerData = [
      ['Date', 'Description', 'Reference', 'Bank ID', 'Debit', 'Credit', 'Balance'],
    ];

    const ws = utils.json_to_sheet<ReconciliationReport>([]);

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, ws, 'Reconciliation Report');
    const fileName = 'Reconcilio - Bank Upload Template.xlsx';

    // Add the data to the workbook
    utils.sheet_add_aoa(ws, ledgerData, {
      origin: 'A1',
    }); // start from first cell

    writeFileXLSX(workbook, fileName);
  };
}

const ConvertersAndTemplatesViewModelContext =
  createContext<ConvertersAndTemplatesViewModel | null>(null);

interface ConvertersAndTemplatesViewModelProviderProps {
  children: React.ReactNode;
}

const ConvertersAndTemplatesViewModelProvider: React.FC<
  ConvertersAndTemplatesViewModelProviderProps
> = ({ children }: ConvertersAndTemplatesViewModelProviderProps) => {
  const reconciliationReportRepository = useReconciliationReportRepository();
  const convertersAndTemplatesViewModel = useMemo(
    () => new ConvertersAndTemplatesViewModel(reconciliationReportRepository),
    [reconciliationReportRepository]
  );

  return (
    <ConvertersAndTemplatesViewModelContext.Provider value={convertersAndTemplatesViewModel}>
      {children}
    </ConvertersAndTemplatesViewModelContext.Provider>
  );
};

const useConvertersAndTemplatesViewModel = () => {
  const viewModel = useContext(ConvertersAndTemplatesViewModelContext);
  if (!viewModel) throw new Error('No EntityViewModel provided');
  return viewModel;
};

export {
  ConvertersAndTemplatesViewModel,
  ConvertersAndTemplatesViewModelProvider,
  useConvertersAndTemplatesViewModel,
};
