import { Button, Icon, Content } from './Styles';

interface CreateButtonProps {
  content: string;
  icon: string;
  onClick: () => void;
}

function CreateButton(props: CreateButtonProps): JSX.Element {
  const { content, icon, onClick } = props;
  return (
    <Button onClick={onClick}>
      <Icon>
        <img src={icon} alt="Create Icon" />
      </Icon>
      <Content>{content}</Content>
    </Button>
  );
}
export default CreateButton;
