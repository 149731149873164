import React from 'react';
import { Row, Rows } from '../../Styles';
import ChunkedItem from './ChunkedItem';
import { CategoryItem } from '../../../../../../models/Resources';

interface ChunkedItemsProps {
  items: CategoryItem[][];
  showEditCategoryItemDialog: (itemId: string) => void;
}
const ChunkedItems = (props: ChunkedItemsProps) => {
  const { items, showEditCategoryItemDialog } = props;

  return (
    <Rows>
      {items.map((chunk, index) => (
        <Row key={index}>
          <ChunkedItem items={chunk} showEditCategoryItemDialog={showEditCategoryItemDialog} />
        </Row>
      ))}
    </Rows>
  );
};

export default ChunkedItems;
