import { styled } from '@mui/material/styles';

export const Header: any = styled('div')({
  display: 'flex',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px 0px 20px 0px',
  width: '100%',
});

interface MainHeaderProps {
  title: string;
  color?: string;
}

function MainHeader(props: MainHeaderProps): JSX.Element {
  const { title, color } = props;
  return (
    <Header
      style={{
        color: color || 'rgba(0, 0, 0, 1)',
      }}
    >
      {title}
    </Header>
  );
}
export default MainHeader;
