import { styled } from '@mui/material/styles';

export const Form: any = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '20px',
  width: '380px',
  margin: '40px 0 0 0',
});
