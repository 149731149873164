import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '600px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  isError?: boolean;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { isError, children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => (isError ? theme.palette.error.main : theme.palette.grey[900]),
        color: 'white',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface CreateDialogProps {
  title: string;
  open: boolean;
  content: JSX.Element;
  action: JSX.Element;
  closeDialog: () => void;
  isError?: boolean;
}
export default function CreateDialog(props: CreateDialogProps) {
  const { title, open, content, action, closeDialog, isError } = props;

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog();
        }
      }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={closeDialog} isError={isError}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>{action}</DialogActions>
    </BootstrapDialog>
  );
}
