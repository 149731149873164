import ReconciliationReportRepository from './ReconciliationReportRepository';
import {
  CreateReconciliationReportData,
  CreateReconciliationReportParams,
} from '../../../models/CreateReconciliationReport';
import {
  AdjustmentsReport,
  ReconciliationAccountReport,
  ReconciliationReport,
  ReconciliationReportDetails,
} from '../../../models/ReconciliationReport';
import { SuggestedMatchPrimitives, TSuggestedMatchesStatus } from '../../../models/SuggestedMatch';
import { ConvertFileParams, FileData, UploadFileParams } from '../../../models/UploadFileData';
import { RepositoryResponse, State } from '../../../types';
import {
  CreateLedgerTransactionParams,
  CreateLedgerTransactionsParams,
  DeleteLedgerTransactionParams,
  EditLedgerTransactionParams,
} from '../../../models/ManualReconciliation';

export type CreateConverterTrackingParams = {
  format: string;
  type: string;
};

export type SuggestedMatchesResponse = {
  status: TSuggestedMatchesStatus;
  data: SuggestedMatchPrimitives[];
};

// export type RegisterCallbackInput<T> = {
//   caller:string;
//   callback: (input: T) => void;
// }

// export enum ResourcesRepositoryEvents {
//     ENTITY_CREATED = 'ENTITY_CREATED',
//     ENTITY_FETCHED = 'ENTITY_FETCHED',
//     RECONCILIATION_ACCOUNT_CREATED = 'RECONCILIATION_ACCOUNT_CREATED',
//     RECONCILIATION_ACCOUNT_FETCHED = 'RECONCILIATION_ACCOUNT_FETCHED',
// }

export type ValidateDocumentResponse = {
  openingBalance: number | null;
  isOpeningBalanceSet: boolean;
};

export type BaseError = string | null;
export type ReconciliationReportFetchError = string | null;
export type ManualReconciliationError = string | null;
export type LockReconciliationError = string | null;
export type UnReconcileMatchError = string | null;
export type CreateLedgerTransactionError = string | null;
export type EditLedgerTransactionError = string | null;
export type DeleteLedgerTransactionError = string | null;
export type EditReconciliationReportDetailsError = string | null;
export type CreateLedgerTransactionsError = string | null;
export type DeleteReconciliationReportError = string | null;

interface IReconciliationReportRepository {
  getSuggestedMatches(
    reconciliationReportId: string,
    getSuggestedMatchesCallback: (
      suggestedMatchesResponse: RepositoryResponse<SuggestedMatchesResponse>
    ) => void
  ): SuggestedMatchesResponse;
  acceptSuggestedMatch(reconciliationReportId: string, suggestedMatchId: string): Promise<void>;
  rejectSuggestedMatch(reconciliationReportId: string, suggestedMatchId: string): Promise<void>;
  acceptAllSuggestedMatches(reconciliationReportId: string): Promise<void>;
  acceptSuggestedMatches(reconciliationReportId: string, suggestionIds: string[]): Promise<void>;
  rejectSuggestedMatches(reconciliationReportId: string, suggestionIds: string[]): Promise<void>;
  createReconciliationReport(
    params: CreateReconciliationReportParams,
    onData: (data: CreateReconciliationReportData) => void,
    onCompleted: () => void,
    onError: (error: any) => void
  ): Promise<void>;
  getReconciliationReport(
    reconciliationReportId: string
  ): State<ReconciliationReport | null, ReconciliationReportFetchError>;
  getReconciliationReportSync(reconciliationReportId: string): Promise<ReconciliationReport>;

  getReconciliationReportsByAccountId(
    accountId: string
  ): State<ReconciliationAccountReport[], ReconciliationReportFetchError>;
  getReconciliationReportsFromCurrentAccountId(): State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  >;
  /**
   *
   * @param file - a File object (perhaps from an <input type="file"> element)
   * @param fileName
   * @param fileType
   * @returns the document id
   */
  uploadFile(params: UploadFileParams): Promise<string>;
  convertFile(params: ConvertFileParams): Promise<FileData[]>;
  validateDocument(id: string, concreteType?: string): Promise<ValidateDocumentResponse>;
  manualReconciliation(
    reconciliationReportId: string,
    ledgerTransactionRowIds: number[],
    externalTransactionRowIds: number[]
  ): Promise<void>;
  lockReconciliationReport(reconciliationReportId: string): Promise<void>;
  getAdjustmentsReport(reportId: string): Promise<AdjustmentsReport>;
  unReconcileMatch(reconciliationReportId: string, matchId: string): Promise<void>;
  createLedgerTransaction(
    createLedgerTransactionParams: CreateLedgerTransactionParams
  ): Promise<void>;
  createLedgerTransactions(
    createLedgerTransactionParams: CreateLedgerTransactionsParams
  ): Promise<void>;
  editLedgerTransaction(editLedgerTransactionParams: EditLedgerTransactionParams): Promise<void>;
  deleteLedgerTransaction(
    deleteLedgerTransactionParams: DeleteLedgerTransactionParams
  ): Promise<void>;
  editReconciliationReportDetails(
    reconciliationReportId: string,
    reconciliationReportDetails: ReconciliationReportDetails
  ): Promise<void>;
  deleteReconciliationReport(reportId: string): Promise<void>;
  createConverterTracking(params: CreateConverterTrackingParams): Promise<void>;
}

export default ReconciliationReportRepository;

export type { IReconciliationReportRepository };
