import React, { JSX } from 'react';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import PageDetails from '../../components/PageDetails/Details';
import Workspace from '../../components/Workspace/Workspace';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { EditWorkspaceParams } from '../../models/Resources';
import { User, UserRoleType } from '../../models/User';

interface WorkspacePageProps {
  updateParams: EditWorkspaceParams;
  countries: string[];
  languages: string[];
  users: User[];
  updateCountry: (country: string) => void;
  updateLanguage: (language: string) => void;
  updateWorkspaceName: (name: string) => void;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  updateWorkspace: (updateParams: Partial<EditWorkspaceParams>) => void;
  showCreateEntityDialog: () => void;
  deleteWorkspace: () => Promise<void>;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
  userAvailableRoles: UserRoleType[];
  fetchUsersDetails: () => void;
}

function WorkspacePage(props: WorkspacePageProps): JSX.Element {
  const {
    countries,
    languages,
    users,
    updateCountry,
    updateLanguage,
    updateWorkspaceName,
    status,
    errorMessage,
    updateParams,
    updateWorkspace,
    showCreateEntityDialog,
    deleteWorkspace,
    fetchUsersDetails,
    userAvailableRoles,
    createUserWithEmailAndRoleId,
  } = props;

  return (
    <>
      <PageDetails
        header={updateParams.name || ''}
        action={
          status === 'success' ? (
            <SimpleButton content="Create Entity" onClick={showCreateEntityDialog} />
          ) : undefined
        }
      />
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && (
        <Workspace
          countries={countries}
          languages={languages}
          users={users}
          updateCountry={updateCountry}
          updateLanguage={updateLanguage}
          updateWorkspaceName={updateWorkspaceName}
          updateParams={updateParams}
          updateWorkspace={updateWorkspace}
          deleteWorkspace={deleteWorkspace}
          fetchUsersDetails={fetchUsersDetails}
          userAvailableRoles={userAvailableRoles}
          createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
        />
      )}
    </>
  );
}

WorkspacePage.defaultProps = {
  errorMessage: '',
} as WorkspacePageProps;

export default WorkspacePage;
