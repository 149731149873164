import { ReconciliationPeriod } from './Styles';

interface ColumnProps {
  content: JSX.Element | string;
  fontSize?: string;
}

export default function ReconciliationPeriodColumn(props: ColumnProps): JSX.Element {
  const { content, fontSize } = props;
  return (
    <ReconciliationPeriod
      sx={{
        width: {
          xs: '80px',
          lg: '130px',
        },
        fontSize: fontSize || '18px',
      }}
    >
      {content}
    </ReconciliationPeriod>
  );
}
