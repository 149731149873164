import { BDate, BReference, BDebit, BCredit, BDescription, BCategory } from './Styles';

function BCategories(): JSX.Element {
  return (
    <BCategory>
      <BDate>Date</BDate>
      <BDescription>Description</BDescription>
      <BReference>Reference</BReference>
      <BDebit>Debit</BDebit>
      <BCredit>Credit</BCredit>
    </BCategory>
  );
}

export default BCategories;
