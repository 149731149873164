import { ReconciledTransactionsBlockRows } from './reconciled-transaction-block-rows';
import UnreconciledTxBlockTableRows from './unreconciled-transaction-block-rows';
import FilledButton from '../../../components/Buttons/FilledButton';
import downloadIcon from '../../../assets/template.svg';
import {
  ReconciledTransactionsBlock,
  UnreconciledTransactionsBlock,
} from '../../../models/ReconciliationReport';

export interface DownloadReportPageProps {
  unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] | null;
  reconciledTransactionsBlocks: ReconciledTransactionsBlock[] | null;
  ledgerEndBalance: string;
  bankClosingBalance: string;
  reconciliationDifference: string;
  handleDownloadXLSXButtonClick: () => void | Promise<void>;
}

export function DownloadReport(props: DownloadReportPageProps) {
  const {
    unreconciledTransactionsBlocks,
    reconciledTransactionsBlocks,
    ledgerEndBalance,
    bankClosingBalance,
    reconciliationDifference,
    handleDownloadXLSXButtonClick,
  } = props;

  const edfOfPeriodBookBalance = ledgerEndBalance;

  // These are missing/not passed from the props
  // const reconciledCreditSum = 'TODO-OR-REMOVE';
  // const reconciledDebitSum = 'TODO-OR-REMOVE';
  // const reconciledBalance = 'TODO-OR-REMOVE';

  // const adjustedBookBalance = 'TODO-OR-REMOVE';

  return (
    <>
      <table id="reconciliation-report" style={{ display: 'none' }}>
        <style>{`
    table, th, td{
     border:1px solid black;
    }
  `}</style>
        <thead>
          <tr>
            <th>Reconciliation Report</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>End of Period Book Balance</td>
            <td aria-hidden />
            <td aria-hidden />
            <td>{edfOfPeriodBookBalance}</td>
          </tr>
          {unreconciledTransactionsBlocks &&
            unreconciledTransactionsBlocks.map((block, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <UnreconciledTxBlockTableRows key={index} {...block} index={index} />
            ))}

          {/* <!-- empty row added next... --> */}
          <tr>
            <td aria-hidden>&nbsp;</td>
          </tr>

          <tr>
            <td>Bank closing balance</td>
            <td aria-hidden />
            <td aria-hidden />
            <td aria-hidden />
            <td>{bankClosingBalance}</td>
          </tr>
          <tr>
            <td>Difference</td>
            <td aria-hidden />
            <td aria-hidden />
            <td aria-hidden />
            <td>{reconciliationDifference}</td>
          </tr>

          {/* <!-- empty row added next... --> */}
          <tr>
            <td aria-hidden>&nbsp;</td>
          </tr>

          <tr>
            <th>Reconciled Transactions</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Reference</th>
            <th>Debit</th>
            <th>Credit</th>
          </tr>

          {reconciledTransactionsBlocks &&
            reconciledTransactionsBlocks.map((block, index) => (
              <ReconciledTransactionsBlockRows
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                bankTransactions={block.bankStatementTransactions}
                ledgerTransactions={block.ledgerTransactions}
              />
            ))}

          {/* <!-- empty row added next... --> */}
          <tr>
            <td aria-hidden>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <FilledButton
        icon={downloadIcon}
        content="Download Report"
        onClick={handleDownloadXLSXButtonClick}
      />
    </>
  );
}
