import { styled } from '@mui/material/styles';

export const Users = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});
export const Actions = styled('div')({
  width: 'var(--page-max-width)',
  display: 'flex',
  justifyContent: 'center',
});
