import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../../models/Document';
import BTHeader from './BTHeader/BTHeader';
import LTHeader from './LTHeader/LTHeader';
import { Headers } from './Styles';
import SubHeaders from './Subheaders';

interface HeadersProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  externalStatementTransactionsPercentage: number | null;
}

function HeadersBlock(props: HeadersProps): JSX.Element {
  const { externalDocumentType, ledgerDocumentType, externalStatementTransactionsPercentage } =
    props;

  return (
    <>
      <Headers
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
        }}
      >
        <BTHeader
          externalDocumentType={externalDocumentType}
          externalStatementTransactionsPercentage={externalStatementTransactionsPercentage}
        />
        <LTHeader ledgerDocumentType={ledgerDocumentType} />
      </Headers>
      <SubHeaders />
    </>
  );
}
export default HeadersBlock;
