import { styled } from '@mui/material/styles';

export const Button = styled('button')({
  display: 'flex',
  width: '100%',
  backgroundColor: 'var(--primary-color)',
  position: 'fixed',
  bottom: '0px',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '20px 0px',
  fontFamily: 'var(--font-family)',
  border: 'none',
  outline: 'none',
  boxShadow: 'none',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: 'rgb(0, 0, 89)',
    cursor: 'pointer',
  },
  '&[disabled]': {
    backgroundColor: 'rgba(0, 0, 0, 0.12);',
    color: 'rgba(0, 0, 0, 0.26',
  },
});

export const Content = styled('div')({
  color: '#fff',
  width: '100%',
  fontSize: '24px',
});

export const Icon = styled('div')({});
