import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import HomeComponent from './page';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';

const HomeController: React.FC = () => {
  const workspaceViewModel = useWorkspacesViewModel();
  const entitiesViewModel = useEntityViewModel();
  const entityViewModel = useEntityViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  const navigate = useNavigate();

  const onStartNewReconciliationReport = () => {
    navigate('/create-reconciliation-report');
  };

  useEffect(() => {
    workspaceViewModel.loadWorkspaces();
  }, []);

  return (
    <HomeComponent
      hasSelectedEntity={entityViewModel.entity.data !== null}
      hasSelectedReconciliationAccount={
        reconciliationAccountViewModel.reconciliationAccount.data !== null
      }
      hasWorkspaces={workspaceViewModel.hasWorkspaces}
      hasEntities={entitiesViewModel.hasEntities}
      hasReconciliationAccounts={reconciliationAccountViewModel.hasReconciliationAccounts}
      showCreateWorkspaceDialog={workspaceViewModel.showCreateWorkspaceDialog}
      showCreateEntityDialog={entityViewModel.showCreateEntityDialog}
      showCreateReconciliationAccountDialog={
        reconciliationAccountViewModel.showCreateReconciliationAccountDialog
      }
      createNewReconciliationReport={onStartNewReconciliationReport}
    />
  );
};

const HomePage = observer(HomeController);

export default HomePage;
