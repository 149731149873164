import { Entity, ReconciliationAccount, Workspace } from '../infra/interfaces/IResourcesService';

export const workspaces: Workspace[] = [
  {
    id: '1',
    name: 'WTS',
    country: 'India',
    language: 'English',
    createdAt: 0,
    updatedAt: 0,
    entities: [
      {
        id: '1',
        name: 'Default',
        // country: "India",
        // language: "English",
        // uniqueIdentifier: "Default",
        // owner: "Default",
        // createdAt: 0,
        // updatedAt: 0,
        // reconciliationAccountsList: [
        //   {
        //     id: "1",
        //     name: "Default",
        //     reconciliationType: "Default",
        //     uniqueIdentifier: "Default",
        //     owner: "Default",
        //     createdAt: 0,
        //     updatedAt: 0,
        //   },
        // ],
      },
    ],
  },
  // { id: '2', name: 'Workspace 2', description: 'Workspace 2 Description' },
  // { id: '3', name: 'Workspace 3', description: 'Workspace 3 Description' },
];

export const entities: Entity[] = [
  {
    id: '1',
    name: 'Default',
    country: 'India',
    language: 'English',
    uniqueIdentifier: 'Default',
    owner: 'Default',
    createdAt: 0,
    updatedAt: 0,
    usersList: [],
    reconciliationAccountsList: [
      {
        id: '1',
        name: 'Default',
        reconciliationType: 'Default',
        // uniqueIdentifier: "Default",
        // owner: "Default",
        // createdAt: 0,
        // updatedAt: 0,
      },
    ],
    // accounts: [
    //   {
    //     id: '1',
    //     name: 'Default',
    //     description: 'Default Description',
    //     // reports: [{ id: '1', name: 'Default' }],
    //   },
    // ],
  },
];

export const reconciliationAccounts: ReconciliationAccount[] = [
  {
    id: '1',
    name: 'Default',
    reconciliationType: 'Default',
    uniqueIdentifier: 'Default',
    owner: 'Default',
    createdAt: 0,
    updatedAt: 0,
    usersList: [],
    // reports: [{ id: '1', name: 'Default' }],
  },
];

export const currentWorkspace = {
  ...workspaces[0],
  entities,
};

export const currentEntity = {
  ...entities[0],
  reconciliationAccounts,
};

export const currentReconciliationAccount = {
  ...reconciliationAccounts[0],
  reconciliationReports: [{ id: '1', name: 'Default', description: 'Default Description' }],
};
