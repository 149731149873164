import {
  IAM_ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  SELECTED_ACCOUNT_LOCAL_STORAGE_KEY,
  SELECTED_ENTITY_LOCAL_STORAGE_KEY,
  SELECTED_WORKSPACE_LOCAL_STORAGE_KEY,
} from '../../constants';
import { ReconciliationAccount } from '../../models/Resources';
import { AsyncStatus, CacheErrors, State } from '../../types';
import { Workspace } from '../interfaces/IResourcesService';

export class LocalStorageRepository {
  static getLocalStorageStateObject<T>(key: string): State<T, null> {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value) as State<T, null>;
      } catch (e) {
        // TODO maybe use a type here and return instead of null
        return {
          data: null,
          status: AsyncStatus.CACHED,
          fetchedAt: 0,
          error: CacheErrors.EMPTY_CACHE,
        };
      }
    }
    return {
      data: null,
      status: AsyncStatus.CACHED,
      fetchedAt: 0,
      error: CacheErrors.EMPTY_CACHE,
    };
  }

  static getLocalStorageValue(key: string): string | null {
    const token = localStorage.getItem(key);
    if (token) {
      return token;
    }
    return null;
  }

  static setLocalStorageValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  static getAccessToken(): string | null {
    return LocalStorageRepository.getLocalStorageValue(IAM_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  }

  static getWorkspaceId(): string | null {
    const workspace = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
      SELECTED_WORKSPACE_LOCAL_STORAGE_KEY
    );
    if (workspace.data) {
      return workspace.data.id;
    }
    return null;
  }

  static getEntityId(): string | null {
    const entity = LocalStorageRepository.getLocalStorageStateObject<Workspace>(
      SELECTED_ENTITY_LOCAL_STORAGE_KEY
    );
    if (entity.data) {
      return entity.data.id;
    }
    return null;
  }

  static getAccountId(): string | null {
    const account = LocalStorageRepository.getLocalStorageStateObject<ReconciliationAccount>(
      SELECTED_ACCOUNT_LOCAL_STORAGE_KEY
    );
    if (account.data) {
      return account.data.id;
    }
    return null;
  }

  static setLocalStorageStateObject<T>(key: string, data: T): void {
    const stateObject = {
      status: AsyncStatus.CACHED,
      fetchedAt: Date.now(),
      data,
      error: null,
    };
    localStorage.setItem(key, JSON.stringify(stateObject));
  }

  static setLocalStorageObjectAsState<T>(key: string, state: T): void {
    const stateObject = {
      ...state,
      status: AsyncStatus.CACHED,
    };
    localStorage.setItem(key, JSON.stringify(stateObject));
  }
}
