import { Block, InputTitle, Input } from './Styles';

interface InputBlockProps {
  name?: string;
  input: JSX.Element;
  required?: boolean;
}
function InputBlock(props: InputBlockProps) {
  const { name, input, required } = props;
  return (
    <Block>
      {name && (
        <InputTitle>
          {name}
          {required && <span style={{ color: 'var(--primary-color)' }}>*</span>}
        </InputTitle>
      )}
      <Input>{input}</Input>
    </Block>
  );
}

export default InputBlock;
