import { observer } from 'mobx-react-lite';
import CategoryItemControlsDialog from './component';
import { useEntityViewModel } from '../../../view-models/EntityViewModel';

const EditEntityCategoryItemController = () => {
  const entityViewModel = useEntityViewModel();

  return (
    <CategoryItemControlsDialog
      open={entityViewModel.isEditCategoryItemDialogVisible}
      closeDialog={entityViewModel.hideEditCategoryItemDialog}
      submit={() => entityViewModel.updateCategoryItem()}
      categoryItemValue={entityViewModel.categoryItemName}
      setCategoryItemName={entityViewModel.setCategoryItemName}
      areRequiredFieldsSet={entityViewModel.categoryItemName.length > 0}
      deleteCategoryItem={() => entityViewModel.deleteCategoryItem()}
    />
  );
};

const EditEntityCategoryItem = observer(EditEntityCategoryItemController);

export default EditEntityCategoryItem;
