import TableHeaders from './Styles';
import NameColumn from '../Columns/ΝameColumn';
import ReconciliationPeriodColumn from '../Columns/ReconciliationPeriodColumn';
import BalanceColumn from '../Columns/BalanceColumn';
import YearColumn from '../Columns/YearColumn';
import ActionsColumn from '../Columns/ActionsColumn';
import AdjustmentsColumn from '../Columns/AdjustmentsColumn';

function Headers(): JSX.Element {
  return (
    <TableHeaders
      sx={{
        fontSize: {
          xs: '14px',
          lg: '18px',
        },
      }}
    >
      <NameColumn content="Name" />
      {/* <LabelColumn content="Label" /> */}
      <ReconciliationPeriodColumn content="Reconciliation Period" />
      <BalanceColumn content="Ledger OB" helperText="Ledger Opening Balance" type="header" ledger />
      <BalanceColumn content="Bank OB" helperText="Bank Opening Balance" type="header" bank />
      <BalanceColumn content="Ledger CB" helperText="Ledger Closing Balance" type="header" ledger />
      <BalanceColumn content="Bank CB" helperText="Bank Closing Balance" type="header" bank />
      <YearColumn content="Year" />
      <AdjustmentsColumn content="Adjustments Report" />
      <ActionsColumn content="Report" />
    </TableHeaders>
  );
}

export default Headers;
