import React, { useState, JSX } from 'react';
import { Block, Title, Field, Actions, Action, Content } from './Styles';
import editIcon from '../../../assets/edit.svg';
import undoIcon from '../../../assets/undo.svg';
import saveIcon from '../../../assets/save.svg';
import { EditEntityParams } from '../../../models/Resources';

// import { Content } from '../../Buttons/FixedBottomButton/Styles';
interface FieldBlockProps {
  name: string;
  field: string;
  fieldInput: JSX.Element;
  updateParam: Partial<EditEntityParams>;
  updateField: (value: Partial<EditEntityParams>) => void;
}
const FieldBlock = (props: FieldBlockProps) => {
  const { name, field, fieldInput, updateField, updateParam } = props;
  const [edit, setEdit] = useState(false);
  const handleClick = () => {
    setEdit(!edit);
  };
  return (
    <Block
      sx={{
        width: {
          xs: 'var(--tab-content-min-width)',
          lg: 'var(--tab-content-max-width)',
        },
      }}
    >
      <Content>
        <Title>{name}:</Title>
        {edit ? <Field>{fieldInput}</Field> : <Field>{field}</Field>}
      </Content>
      <Actions>
        {edit && (
          <Action>
            <button
              type="button" // Add the type attribute with the value "button"
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              aria-label="Save"
              onClick={() => {
                updateField(updateParam);
                setEdit(false);
              }}
            >
              <img src={saveIcon} alt="save" />
            </button>
          </Action>
        )}
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={handleClick}
          >
            <img src={edit ? undoIcon : editIcon} alt="edit" />
          </button>
        </Action>
      </Actions>
    </Block>
  );
};

export default FieldBlock;
