import {
  Dialog,
  DialogTitle,
  LinearProgress,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import progressGif from '../../assets/loader-progress.gif';
import { Gif } from './Styles';

interface ProgressDialogProps {
  open: boolean;
  progress: number;
  message: string;
  total: number;
  totalReconciled: number;
  // handleClose: () => void;
}
function ProgressDialog(props: ProgressDialogProps) {
  const { open, progress, total, totalReconciled, message } = props;

  return (
    <Dialog
      open={open}
      //   onClose={handleClose}
    >
      <DialogTitle>Processing...</DialogTitle>
      <DialogContent>
        <Gif>
          <img src={progressGif} alt="Loading..." />
        </Gif>
        <DialogContentText>{message}</DialogContentText>
        <DialogContentText>{`${totalReconciled} of ${total} transactions processed`}</DialogContentText>
      </DialogContent>
      <LinearProgress variant="determinate" value={progress} />
    </Dialog>
  );
}

export default ProgressDialog;
