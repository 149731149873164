import MuiDrawer from '@mui/material/Drawer';
import { List } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { UnreconciledTransactionsBlock as UnreconciledTransactionsBlockModel } from '../../../../models/ReconciliationReport';
import { ExpandableAccordionItem } from './ExpandableAccordionItem';
import UnreconciledTransactionsBlock from '../Block/Block';
import { Amount } from './Styles';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(() => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
  },
}));

export interface UnreconciledTransactionsAccordionProps {
  unreconciledTransactionsBlocks: UnreconciledTransactionsBlockModel[];
}
export function UnreconciledTransactionsAccordion(props: UnreconciledTransactionsAccordionProps) {
  const { unreconciledTransactionsBlocks } = props;
  return (
    <Drawer variant="permanent">
      <List component="nav">
        {unreconciledTransactionsBlocks.map((block, index) => (
          <ExpandableAccordionItem
            mainListItem={<ListItemText key={index} primary={block.title} />}
            secondaryListItem={<Amount>{block.total}</Amount>}
            subListItems={
              <UnreconciledTransactionsBlock
                key={index}
                title={block.title}
                blocks={block.blocks}
                type={block.type}
                total={block.total}
                adjustedBalance={block.adjustedBalance}
                dateHeader={block.dateHeader}
                descriptionHeader={block.descriptionHeader}
                referenceHeader={block.referenceHeader}
                amountHeader={block.amountHeader}
              />
            }
          />
        ))}
      </List>
    </Drawer>
  );
}
