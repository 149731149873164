import * as React from 'react';
import { List } from '@mui/material';
import {
  CurrentEntityStatus,
  Entity,
  EntitySlim,
  ReconciliationAccount,
  ReconciliationAccountSlim,
} from '../../../../models/Resources';
import { HomeItem } from './HomeItem';
import { EntityItems } from './EntityItems';
import AddEntityItem from './AddEntityItem';
import BottomListItems from '../BottomListItems';

function TopListItems(props: {
  entities: EntitySlim[];
  drawerOpen: boolean;
  reconciliationAccountsList: ReconciliationAccountSlim[];
  goHome: () => void;
  openDrawer: () => void;
  currentEntity: Entity | null;
  selectEntity: (entityId: string) => void;
  currentEntityStatus: CurrentEntityStatus;
  currentReconciliationAccount: ReconciliationAccount | null;
  selectReconciliationAccount: (reconciliationAccountId: string) => void;
  navigateToEntity: (entityId: string) => void;
  navigateToReconciliationAccount: (reconciliationAccountId: string) => void;
  showCreateEntityDialog: () => void;
  navigateToWorkspace: () => void;
  currentReconciliationAccountsType: string;
  selectReconciliationAccountsType: (reconciliationAccountsType: string) => void;
  navigateToConvertersAndTemplates: () => void;
}) {
  const {
    entities,
    drawerOpen,
    openDrawer,
    goHome,
    currentEntity,
    selectEntity,
    currentEntityStatus,
    currentReconciliationAccount,
    selectReconciliationAccount,
    navigateToEntity,
    navigateToReconciliationAccount,
    showCreateEntityDialog,
    reconciliationAccountsList,
    navigateToWorkspace,
    currentReconciliationAccountsType,
    selectReconciliationAccountsType,
    navigateToConvertersAndTemplates,
  } = props;

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        '& .MuiListItemButton-root:hover': {
          backgroundColor: (theme) => theme.palette.grey[900],
          '&, & .MuiListItemIcon-root': {
            color: '#fff',
          },
        },
      }}
      component="nav"
      onClick={openDrawer}
    >
      <HomeItem drawerOpen={drawerOpen} goHome={goHome} />
      <BottomListItems
        drawerOpen={drawerOpen}
        navigateToWorkspace={navigateToWorkspace}
        navigateToConvertersAndTemplates={navigateToConvertersAndTemplates}
      />
      <AddEntityItem drawerOpen={drawerOpen} showCreateEntityDialog={showCreateEntityDialog} />
      <EntityItems
        entities={entities}
        drawerOpen={drawerOpen}
        currentEntity={currentEntity}
        selectEntity={selectEntity}
        currentReconciliationAccount={currentReconciliationAccount}
        selectReconciliationAccount={selectReconciliationAccount}
        navigateToEntity={navigateToEntity}
        navigateToReconciliationAccount={navigateToReconciliationAccount}
        reconciliationAccountsList={reconciliationAccountsList}
      />
    </List>
  );
}

export default TopListItems;
