import { styled } from '@mui/material/styles';

export const MatchBlock = styled('div')({
  padding: '20px 10px',
  borderRadius: 'var(--border-radius)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
});

export const Transactions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const BTransactions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  color: 'var(--bank-color)',
});

export const LTransactions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  color: 'var(--ledger-color)',
});

export const LTransaction = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const Header = styled('div')({
  width: '100%',
  textAlign: 'center',
  fontSize: '32px',
  marginBottom: '10px',
});

export const LTAction = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});

export const IdleText = styled('div')({
  width: '100%',
  textAlign: 'center',
});

export const UnreconcilableText = styled('div')({
  paddingTop: '10px',
  width: '100%',
  textAlign: 'right',
  color: 'var(--error-color)',
  fontSize: '18px',
});
