import React from 'react';
import { Header, Text, TextSpan1, TextSpan2 } from './Styles';
import { EmailPassForm } from '../../components/EmailPassForm';

interface LogInPageProps {
  email: string;
  password: string;
  message: { type: 'error' | 'success'; message: string } | null;
  updateEmail: (email: string) => void;
  updatePassword: (password: string) => void;
  submit: () => void;
  visitSignUpPage: () => void;
}

const dontHaveAnAccount = 'Don’t have an account?';

function LoginPage(props: LogInPageProps): JSX.Element {
  const { email, password, submit, message, updateEmail, updatePassword, visitSignUpPage } = props;

  return (
    <>
      <Header>Login</Header>
      <EmailPassForm
        email={email}
        password={password}
        message={message}
        view="Login"
        submit={submit}
        updateEmail={updateEmail}
        updatePassword={updatePassword}
      />
      <Text>
        <TextSpan1>{dontHaveAnAccount}</TextSpan1>
        <TextSpan2> </TextSpan2>
        <TextSpan2 onClick={visitSignUpPage}>SIGN UP</TextSpan2>
      </Text>
    </>
  );
}
export default LoginPage;
