import { TableRow, Action } from './Styles';
// import { Actions } from '../Styles';

import NameColumn from '../../Columns/ΝameColumn';
import LabelColumn from '../../Columns/LabelColumn';
import ReconciliationPeriodColumn from '../../Columns/ReconciliationPeriodColumn';
import BalanceColumn from '../../Columns/BalanceColumn';
import YearColumn from '../../Columns/YearColumn';
import ActionsColumn from '../../Columns/ActionsColumn';
import viewIcon from '../../../../../assets/view.svg';
import downloadIcon from '../../../../../assets/download.svg';
import deleteIcon from '../../../../../assets/delete.svg';
import {
  ReconciliationAccountReport,
  ReconciliationReportDetails,
} from '../../../../../models/ReconciliationReport';
import AdjustmentsColumn from '../../Columns/AdjustmentsColumn';
import DeleteReconciliationReportDialog from '../../../DeleteReconciliationReportDialog';

interface ReportBlockProps {
  report: ReconciliationAccountReport;
  navigateToReport: (id: string) => void;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  editReconciliationReportDetails: (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ) => Promise<void>;
  showDeleteReconciliationReportDialog: (reportId: string) => void;
  isReconciliationAccountReportLocked: (reportId: string) => boolean;
}

function MainRow(props: ReportBlockProps): JSX.Element {
  const {
    report,
    navigateToReport,
    downloadReconciliationReport,
    downloadAdjustmentsReport,
    editReconciliationReportDetails,
    showDeleteReconciliationReportDialog,
    isReconciliationAccountReportLocked,
  } = props;
  return (
    <TableRow>
      <NameColumn
        content={report.name}
        reportStatus={report.status}
        reportId={report.id}
        editReconciliationReportDetails={editReconciliationReportDetails}
        type="body"
      />
      {/* <LabelColumn
        content={
          <>{report.label && <Chip label={report.label} color="primary" variant="outlined" />}</>
        }
      /> */}
      <ReconciliationPeriodColumn
        content={
          <>
            {report.reconciliationPeriod.startingDate} {' - '}
            {report.reconciliationPeriod.endingDate}{' '}
          </>
        }
        fontSize="14px"
      />
      <BalanceColumn content={report.ledgerOpeningBalance} fontSize="14px" />
      <BalanceColumn content={report.bankOpeningBalance} fontSize="14px" />
      <BalanceColumn content={report.ledgerClosingBalance} fontSize="14px" />
      <BalanceColumn content={report.bankClosingBalance} fontSize="14px" />
      <YearColumn content={report.year} fontSize="14px" />
      <AdjustmentsColumn
        content={
          <Action onClick={() => downloadAdjustmentsReport(report.id)}>
            <img src={downloadIcon} alt="Download" />
          </Action>
        }
      />
      <ActionsColumn
        content={
          <>
            <Action onClick={() => navigateToReport(report.id)}>
              <img src={viewIcon} alt="View" />
            </Action>
            <Action onClick={() => downloadReconciliationReport(report.id)}>
              <img src={downloadIcon} alt="Download" />
            </Action>
            {!isReconciliationAccountReportLocked(report.id) && (
              <Action
                onClick={() => {
                  showDeleteReconciliationReportDialog(report.id);
                }}
              >
                <img src={deleteIcon} alt="Delete" />
              </Action>
            )}
          </>
        }
      />
      <DeleteReconciliationReportDialog />
    </TableRow>
  );
}

export default MainRow;
