import React, { JSX } from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from './TabPanel';

interface FlexibleWidthTabsProps {
  tabs: {
    label: string;
    content: JSX.Element;
  }[];
  width?: string;
}

function a11yProps(index: number) {
  return {
    id: `flexible-width-tab-${index}`,
    'aria-controls': `flexible-width-tabpanel-${index}`,
  };
}

export default function FlexibleWidthTabs(props: FlexibleWidthTabsProps) {
  const { tabs, width } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          width: width || '600px',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="flexible width tabs example"
          sx={{
            borderRadius: 'var(--border-radius)',
          }}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {tabs.map((tab, index) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{
                backgroundColor: index === value ? 'var(--primary-color)' : 'rgba(255,255,255,0.4)',
                color: index === value ? 'var(--white-color)' : 'var(--primary-color)',
                border: '1px solid var(--primary-color)',
                opacity: 1,
              }}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TabPanel key={index} value={value} index={index} dir={theme.direction}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
}
