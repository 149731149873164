import { observer } from 'mobx-react-lite';
import NewStopListItemDialog from './component';
import { useEntityViewModel } from '../../../view-models/EntityViewModel';

const NewEntityStopListItemController = () => {
  const entityViewModel = useEntityViewModel();

  return (
    <NewStopListItemDialog
      open={entityViewModel.isAddStopListItemDialogVisible}
      closeDialog={entityViewModel.hideAddStopListItemDialog}
      submit={entityViewModel.addStopListItem}
      stopListItemName={entityViewModel.stopListItemName}
      setStopListItemName={entityViewModel.setStopListItemName}
      areRequiredFieldsSet={entityViewModel.stopListItemName.length > 0}
    />
  );
};

const NewEntityStopListItem = observer(NewEntityStopListItemController);

export default NewEntityStopListItem;
