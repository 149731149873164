import Settings from './Styles';
import Categories from './Categories';
import FlexibleWidthTabs from '../../FlexibleWidthTabs';
import StopList from './StopList';

function SettingsTabContent(): JSX.Element {
  return (
    <Settings>
      <FlexibleWidthTabs
        tabs={[
          {
            label: 'StopList',
            content: <StopList />,
          },
          {
            label: 'Categories',
            content: <Categories />,
          },
        ]}
      />
    </Settings>
  );
}
export default SettingsTabContent;
