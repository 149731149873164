import { styled } from '@mui/material/styles';

export const Gif = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  '& img': {
    width: '100%',
    maxWidth: '15em',
    height: 'auto',
  },
});
