import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';

interface DocumentFormatTypeMissingDialogProps {
  open: boolean;
  submitDebitsIncrease: () => void;
  submitDebitsDecrease: () => void;
  closeDialog: () => void;
}
function DocumentFormatTypeMissingDialog(props: DocumentFormatTypeMissingDialogProps) {
  const { open, closeDialog, submitDebitsIncrease, submitDebitsDecrease } = props;
  return (
    <CreateDialog
      title="What is the format type for this Statement?"
      open={open}
      closeDialog={closeDialog}
      content={
        <p>
          If the statement swaps Debits with Credits (Debits decrease the Balance) and presents the
          Balance in absolute values, please specify so by clicking on the relevant button.
        </p>
      }
      action={
        <>
          <FilledButton content="Debits Increase" onClick={submitDebitsIncrease} />
          <FilledButton content="Debits Decrease" onClick={submitDebitsDecrease} />
        </>
      }
    />
  );
}

export default DocumentFormatTypeMissingDialog;
