import { Checkbox } from '@mui/material';
import React from 'react';
import { LedgerTransaction, LedgerDebit, LedgerCredit } from './Styles';
import { Date, Reference, LedgerID } from '../Styles';
import Description from '../Description/Description';
import { ManualTransaction } from '../../../../models/ManualReconciliation';

interface TransactionProps {
  children?: React.ReactNode;
  transaction: ManualTransaction;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  scrollToUnreconcilableText?: () => void;
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function LedgerTransactionBlock(props: TransactionProps): JSX.Element {
  const { children, transaction, selectOrUnselectLedgerTransaction, scrollToUnreconcilableText } =
    props;
  return (
    <LedgerTransaction
      onClick={() => {
        selectOrUnselectLedgerTransaction(transaction);
        if (scrollToUnreconcilableText) scrollToUnreconcilableText();
      }}
    >
      {/* <Checkbox
        checked={transaction.selected}
        {...label}
        defaultChecked
        sx={{
          '&:hover': { bgcolor: 'transparent' },
          padding: '0 5px 0 0',
        }}
        onClick={() => {
          selectOrUnselectLedgerTransaction(transaction);
        }}
      /> */}
      <Date>{transaction.date}</Date>&nbsp;
      <Description transaction={transaction} type="Ledger" maxCharacters={96} />
      {/* <LedgerID>{transaction.financialTransactionId}</LedgerID>
      <Reference>{transaction.reference}</Reference> */}
      <LedgerDebit>{transaction.debit !== '0.00' && transaction.debit}</LedgerDebit>
      <LedgerCredit>{transaction.credit !== '0.00' && transaction.credit}</LedgerCredit>
      {/* We don't want when we click on the children(e.g. edit icon) to trigger the parent's onClick event. */}
      {React.Children.map(children, (child: any) =>
        React.cloneElement(child, { onClick: (event: any) => event.stopPropagation() })
      )}
    </LedgerTransaction>
  );
}

export default LedgerTransactionBlock;
