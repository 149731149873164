import { observer } from 'mobx-react-lite';
import DeleteReconciliationReportDialog from './component';
import { useReconciliationAccountViewModel } from '../../../view-models/ReconciliationAccountViewModel';

function DeleteReconciliationReportDialogController() {
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  if (!reconciliationAccountViewModel) {
    return null;
  }
  return (
    <DeleteReconciliationReportDialog
      open={reconciliationAccountViewModel.isDeleteReconciliationReportDialogVisible}
      closeDialog={reconciliationAccountViewModel.hideDeleteReconciliationReportDialog}
      submitAction={reconciliationAccountViewModel.deleteReconciliationReport}
    />
  );
}

const DeleteReconciliationReportDialogPage = observer(DeleteReconciliationReportDialogController);

// eslint-disable-next-line import/prefer-default-export
export { DeleteReconciliationReportDialogPage as DeleteReconciliationReportDialog };
