import { AmountSubheader, Title, Amount } from './Styles';

interface SubheaderProps {
  title: string;
  amount?: string;
}

function Subheader(props: SubheaderProps): JSX.Element {
  const { title, amount } = props;
  return (
    <AmountSubheader>
      <Title>{title}</Title>
      <Amount>{amount && amount}</Amount>
    </AmountSubheader>
  );
}
export default Subheader;
