import { styled } from '@mui/material/styles';

export const FooterBlock: any = styled('div')({
  // display: `flex`,
  // position: `absolute`,
  // bottom: `10px`,
  // color: 'rgba(0, 0, 0, 0.12)',
  flexShrink: 0,
  color: 'rgba(0, 0, 0, 0.12)',
});
