import React from 'react';
import Copyright from './Copyright';
import { FooterBlock } from './Styles';

const Footer: React.FC = () => (
  <FooterBlock>
    <Copyright />
  </FooterBlock>
);

export default Footer;
