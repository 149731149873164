import { styled } from '@mui/material/styles';

export const CategoriesTable = styled('div')({
  width: 'var(--page-max-width)',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'rgba(235, 235, 235, 0.1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  position: 'relative',
  isolation: 'isolate',
  height: 'auto',
});

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
export const TableHeader = styled('div')({
  display: 'flex',
  padding: '15px 30px',
  fontSize: '24px',
});

export const TableBodyWorkspace = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px 30px',
  backgroundColor: 'rgba(217, 217, 217, 0.1)',
});

export const TableBodyEntity = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px 30px',
});

export const Block: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  height: 'auto',
  padding: '2px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  overflow: 'visible',
  zIndex: 1,
});

export const Category = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '200px',
});

export const Input = styled('div')({
  width: '180px',
});
export const Items = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const Item = styled('div')({
  display: 'flex',
  width: '180px',
  paddingLeft: '5px',
  marginLeft: '15px',
  color: '#B5B5C3',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

export const Rows = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});
export const Row = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
});

export const Actions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
});

export const Action = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});

export const WorkspaceTab = styled('div')({
  transform: 'rotate(-90deg)',
  left: '-30px',
  backgroundColor: 'var(--primary-color)',
  color: 'var(--white-color)',
  width: 'fit-content',
  position: 'absolute',
  borderTopLeftRadius: 'var(--border-radius)',
  borderTopRightRadius: 'var(--border-radius)',
  padding: '5px 10px',
});

export const EntityTab = styled('div')({
  transform: 'rotate(-90deg)',
  left: '-30px',
  backgroundColor: 'var(--white-color)',
  border: '1px solid var(--primary-color)',
  color: 'var(--primary-color)',
  width: 'fit-content',
  position: 'absolute',
  borderTopLeftRadius: 'var(--border-radius)',
  borderTopRightRadius: 'var(--border-radius)',
  padding: '5px 10px',
});
