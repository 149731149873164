import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CONCRETE_TYPES } from '../../../models/Document';
import { DOCUMENT_TYPE } from '../../../models/UploadFileData';
import { Choose, Header, Statement } from '../Styles';
import { LEDGER_ERP_OPTIONS } from '../../../view-models/ConvertersAndTemplatesViewModel';
import { STATUS_STATES } from '../../../types';
import SimpleButton from '../../Buttons/SimpleButton';
import UploadFileBlockButton from '../../CreateReconciliationReport/UploadStatements/ChooseStatements/UploadFile/UploadFileBlockButton';

interface ConvertLedgerFormatProps {
  uploadFileStatus: STATUS_STATES;
  erpOptions: LEDGER_ERP_OPTIONS[];
  selectedERP: LEDGER_ERP_OPTIONS;
  uploadFile: (file: File) => void;
  convertDocument: () => void;
  setSelectedERP: (value: LEDGER_ERP_OPTIONS) => void;
  setUploadFileStatus: (value: STATUS_STATES) => void;
}

function ConvertLedgerFormat(props: ConvertLedgerFormatProps): JSX.Element {
  const {
    erpOptions,
    selectedERP,
    uploadFileStatus,
    uploadFile,
    convertDocument,
    setSelectedERP,
    setUploadFileStatus,
  } = props;

  return (
    <Statement>
      <Header>Ledger Formats</Header>

      <Autocomplete
        options={erpOptions}
        sx={{ width: 200 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} />}
        value={selectedERP}
        onChange={(e, value) => {
          setSelectedERP(value || LEDGER_ERP_OPTIONS.NONE);
        }}
      />

      <Choose>
        <UploadFileBlockButton
          disableUpload={false}
          uploadFile={uploadFile}
          status={uploadFileStatus}
          type={DOCUMENT_TYPE.LEDGER}
          concreteType={CONCRETE_TYPES.LEDGER}
          setStatus={setUploadFileStatus}
        />
      </Choose>
      <SimpleButton content="Convert" onClick={convertDocument} />
    </Statement>
  );
}

export default ConvertLedgerFormat;
