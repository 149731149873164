import React from 'react';
import BTCategories from './BTCategories';
import { BtHeader, BtHeaderName, BtPercentageInfo } from './Styles';
import { EXTERNAL_DOCUMENT_TYPES } from '../../../../../models/Document';

interface BTHeaderProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  externalStatementTransactionsPercentage: number | null;
}

function BTHeader(props: BTHeaderProps): JSX.Element {
  const { externalDocumentType, externalStatementTransactionsPercentage } = props;

  return (
    <BtHeader>
      <BtHeaderName>
        {externalDocumentType} Transactions {}
        <BtPercentageInfo>
          {externalStatementTransactionsPercentage !== null
            ? `(Automatic Reconciliation: ${externalStatementTransactionsPercentage.toFixed(2)}%)`
            : ''}
        </BtPercentageInfo>
      </BtHeaderName>
    </BtHeader>
  );
}
export default BTHeader;
