import * as React from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  isReconciliationReport?: boolean;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, isReconciliationReport, ...other } = props;

  const style = isReconciliationReport ? 'w-full' : '';

  return (
    <div
      role="tabpanel"
      className={style}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
