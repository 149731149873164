import VisibleText from '../../VisibleText';
import { Description } from './Styles';

interface DescriptionColumnProps {
  content: string;
}

function DescriptionColumn(props: DescriptionColumnProps): JSX.Element {
  const { content } = props;
  return (
    <Description>
      <VisibleText text={content} maxCharacters={96} />
    </Description>
  );
}
export default DescriptionColumn;
