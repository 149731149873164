import React from 'react';
import { Layout, ReconciledTransactions } from './Styles';
import Headers from '../../Headers/TransactionsHeaders';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../models/Document';
// import ReconciledTransaction2 from './ReconciledTransaction2/ReconciledTransaction';

interface RTLayoutProps {
  children: React.ReactNode;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  externalStatementTransactionsPercentage: number | null;
}

function RTLayout(props: RTLayoutProps): JSX.Element {
  const {
    children,
    externalDocumentType,
    ledgerDocumentType,
    externalStatementTransactionsPercentage,
  } = props;
  return (
    <Layout>
      <Headers
        externalDocumentType={externalDocumentType}
        externalStatementTransactionsPercentage={externalStatementTransactionsPercentage}
        ledgerDocumentType={ledgerDocumentType}
      />
      <ReconciledTransactions>{children}</ReconciledTransactions>
    </Layout>
  );
}

export default RTLayout;
