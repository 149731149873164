import Block from '../../../Blocks/SimpleBlock/SimpleBlock';
import MainHeader from './InnerBlocks/InnerBlockHeaders/MainHeader';
import InnerBlocks from './InnerBlocks/InnerBlocks';
import Subheader from './Subheader/Subheader';
import { UnreconciledTransactionsProps } from '../../../../types';

function UnreconciledTransactions(props: UnreconciledTransactionsProps): JSX.Element {
  const {
    title,
    type,
    total,
    adjustedBalance,
    blocks,
    dateHeader,
    descriptionHeader,
    referenceHeader,
    amountHeader,
  } = props;
  return (
    <Block>
      <MainHeader
        title={title}
        color={type === 'ledger' ? 'var(--ledger-color)' : 'var(--bank-color)'}
      />
      <InnerBlocks
        blocks={blocks}
        dateHeader={dateHeader}
        descriptionHeader={descriptionHeader}
        referenceHeader={referenceHeader}
        amountHeader={amountHeader}
      />
      <Subheader amount={total} small />
      <Subheader title="Adjusted Balance" amount={adjustedBalance} />
    </Block>
  );
}
export default UnreconciledTransactions;
