import * as React from 'react';
import { Outlet } from 'react-router-dom';
import reconcilioLogo from '../../assets/reconcilio-full-white.svg';
import {
  AuthLayout,
  LeftSide,
  FullLogo,
  Vector,
  Subheader,
  Welcome,
  Text,
  RightSide,
} from './Styles';

const Layout: React.FC = () => (
  <AuthLayout>
    <LeftSide>
      <FullLogo>
        <Vector src={reconcilioLogo} loading="lazy" alt="Reconcilio Logo" />
      </FullLogo>
      <Subheader>
        <Welcome>Welcome</Welcome>
        <Text>Login to continue</Text>
      </Subheader>
    </LeftSide>
    <RightSide>
      <Outlet />
    </RightSide>
  </AuthLayout>
);

export default Layout;
