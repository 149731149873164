import { styled } from '@mui/material/styles';

export const Report = styled('div')({
  paddingBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
});

export const Comment = styled('div')({
  display: 'flex',
  padding: '10px',
  minHeight: '55px',
  alignItems: 'center',
  backgroundColor: 'var(--white-color)',
  borderRadius: 'var(--border-radius)',
  // border: '1px solid rgba(0, 0, 0, 0.12)',
  justifyContent: 'space-between',
});

export const TableRow: any = styled('div')({
  display: 'flex',
  paddingBottom: '10px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Center = styled('div')({
  width: '100%',
  display: 'flex',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Action = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});
