import { observer } from 'mobx-react-lite';
import NewCategoryDialog from './component';
import { useEntityViewModel } from '../../../view-models/EntityViewModel';

const NewEntityCategoryController = () => {
  const entityViewModel = useEntityViewModel();

  return (
    <NewCategoryDialog
      open={entityViewModel.isAddCategoryDialogVisible}
      closeDialog={entityViewModel.hideAddCategoryDialog}
      submit={entityViewModel.addNewCategory}
      categoryName={entityViewModel.categoryName}
      setCategoryName={entityViewModel.setCategoryName}
      areRequiredFieldsSet={entityViewModel.categoryName.length > 0}
    />
  );
};

const NewEntityCategory = observer(NewEntityCategoryController);

export default NewEntityCategory;
