import React from 'react';
import BTCategories from './BTCategories';
import { BtHeader, BtHeaderName } from './Styles';
import { EXTERNAL_DOCUMENT_TYPES } from '../../../../../models/Document';

interface BTHeaderProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
}

function BTHeader(props: BTHeaderProps): JSX.Element {
  const { externalDocumentType } = props;

  return (
    <BtHeader>
      <BtHeaderName>{externalDocumentType} Transactions</BtHeaderName>
    </BtHeader>
  );
}
export default BTHeader;
