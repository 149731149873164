import { styled } from '@mui/material/styles';
import { CreditBase, DebitBase } from '../Styles';

export const BankTransaction: any = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1.8px solid var(--bank-color)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 10px',
  height: '55px',
  width: 'var(--transaction-max-width)',
  margin: '0px',
  fontSize: '11px',
  color: 'rgba(0, 0, 0, 1)',
});

export const BankDebit: any = styled(DebitBase)({
  color: '#AA0000',
});

export const BankCredit: any = styled(CreditBase)({
  color: '#006A4E',
});
