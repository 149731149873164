import { Header } from './Styles';

interface PageHeaderProps {
  title: string;
}

function PageHeader(props: PageHeaderProps): JSX.Element {
  const { title } = props;
  return <Header>{title}</Header>;
}
export default PageHeader;
