import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import CategoriesView from './component';
import { useEntityViewModel } from '../../../../view-models/EntityViewModel';

const CategoriesController = () => {
  const entityViewModel = useEntityViewModel();

  return entityViewModel.entitySettings.data ? (
    <CategoriesView
      categories={entityViewModel.entitySettings.data.categories}
      workspaceCategories={entityViewModel.entitySettings.data.workspaceCategories}
      setCategoryName={entityViewModel.setCategoryName}
      updateCategoryName={entityViewModel.updateCategoryName}
      deleteCategory={entityViewModel.deleteCategory}
      showAddCategoryDialog={entityViewModel.showAddCategoryDialog}
      showAddCategoryItemDialog={entityViewModel.showAddCategoryItemDialog}
      showEditCategoryItemDialog={entityViewModel.showEditCategoryItemDialog}
      enableWorkspaceCategory={entityViewModel.enableWorkspaceCategory}
      disableWorkspaceCategory={entityViewModel.disableWorkspaceCategory}
    />
  ) : (
    <CircularProgress />
  );
};

const Categories = observer(CategoriesController);

export default Categories;
