import React from 'react';
import { LDate, LReference, LDebit, LCredit, LDescription, LCategory } from './Styles';

function LCategories(): JSX.Element {
  return (
    <LCategory>
      <LDate>Date</LDate>
      <LDescription>Description</LDescription>
      <LReference>Reference</LReference>
      <LDebit>Debit</LDebit>
      <LCredit>Credit</LCredit>
    </LCategory>
  );
}

export default LCategories;
