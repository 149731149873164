import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputBlock from '../Input';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';

interface NewReconciliationAccountDialogProps {
  open: boolean;
  types: string[];
  submit: () => void;
  closeDialog: () => void;

  reconciliationAccountName: string;
  setReconciliationAccountName: (value: string) => void;
  areRequiredFieldsSet: boolean;
  counterPartyContactName: string;
  setCounterPartyContactName: (value: string) => void;
  counterPartyContactEmail: string;
  setCounterPartyContactEmail: (value: string) => void;
  reconciliationType: string;
  setReconciliationType: (value: string) => void;
  uniqueIdentifier: string;
  setUniqueIdentifier: (value: string) => void;
  isUniqueIdentifierSet: boolean;
  showUniqueIdentifierErrorMessage: () => string;
}

function NewReconciliationAccountDialog(props: NewReconciliationAccountDialogProps) {
  const {
    open,
    types,
    submit,
    closeDialog,
    reconciliationAccountName,
    setReconciliationAccountName,
    areRequiredFieldsSet,
    counterPartyContactName,
    setCounterPartyContactName,
    counterPartyContactEmail,
    setCounterPartyContactEmail,
    reconciliationType,
    setReconciliationType,
    uniqueIdentifier,
    setUniqueIdentifier,
    isUniqueIdentifierSet,
    showUniqueIdentifierErrorMessage,
  } = props;

  return (
    <CreateDialog
      title="Create Reconciliation Account"
      open={open}
      closeDialog={closeDialog}
      content={
        <>
          <InputBlock
            name="Reconciliation Type"
            input={
              <Autocomplete
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={reconciliationType}
                options={types}
                onChange={(e, value) => setReconciliationType(value || '')}
              />
            }
            required
          />
          <InputBlock
            name="Name"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={reconciliationAccountName}
                onChange={(event) => {
                  setReconciliationAccountName(event.target.value);
                }}
              />
            }
            required
          />
          <InputBlock
            name="ERP Account Code"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={uniqueIdentifier}
                onChange={(event) => {
                  setUniqueIdentifier(event.target.value);
                }}
                error={!isUniqueIdentifierSet}
                helperText={showUniqueIdentifierErrorMessage()}
              />
            }
            required
          />
          <InputBlock
            name="Counterparty Contact Name"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={counterPartyContactName}
                onChange={(event) => {
                  setCounterPartyContactName(event.target.value);
                }}
              />
            }
          />
          <InputBlock
            name="Counterparty Contact Email"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={counterPartyContactEmail}
                onChange={(event) => {
                  setCounterPartyContactEmail(event.target.value);
                }}
              />
            }
          />
        </>
      }
      action={<FilledButton disabled={!areRequiredFieldsSet} content="Create" onClick={submit} />}
    />
  );
}

export default NewReconciliationAccountDialog;
