import { styled } from '@mui/material/styles';

export const Layout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: 'auto',
  width: '100%',
});

export const ReconciledTransactions: any = styled('div')({
  display: 'flex',
  position: 'relative',
  // gap: '20px',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '20px 0px 0px 0px',
  width: '100%',
});

export const Empty: any = styled('div')({
  display: 'flex',
  justifyContent: 'left',
  fontSize: '18px',
  width: 'var(--page-max-width)',
});
