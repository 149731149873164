import { useState } from 'react';
import AcceptButton from '../../Buttons/AcceptButton';
import RejectButton from '../../Buttons/RejectButton';
import { SuggestedMatch, Buttons } from './Styles';
import Match from '../../Match/Match';
import { SuggestedMatchProps } from '../../../types';

function SuggestedMatchBlock(props: SuggestedMatchProps): JSX.Element {
  const {
    id,
    reconciliationReportId,
    bankTransactions,
    ledgerTransactions,
    timeout,
    acceptSuggestedMatch,
    rejectSuggestedMatch,
  } = props;
  // const [actionsDisabled, setActionsDisabled] = useState(false);

  // const handleClick = () => {
  //   setActionsDisabled(true);

  //   setTimeout(() => {
  //     setActionsDisabled(false);
  //   }, timeout || 2000);
  // };

  return (
    <SuggestedMatch
    // sx={{
    //   width: {
    //     xs: 'auto',
    //     lg: 'var(--page-max-width)',
    //   },
    // }}
    >
      <Match bankTransactions={bankTransactions} ledgerTransactions={ledgerTransactions} />
      <Buttons>
        <AcceptButton
          // disabled={actionsDisabled}
          onClick={acceptSuggestedMatch}
          suggestedMatchId={id}
          reconciliationReportId={reconciliationReportId}
        />
        <RejectButton
          // disabled={actionsDisabled}
          onClick={rejectSuggestedMatch}
          suggestedMatchId={id}
          reconciliationReportId={reconciliationReportId}
        />
      </Buttons>
    </SuggestedMatch>
  );
}

export default SuggestedMatchBlock;
