import { Adjustments } from './Styles';

interface ColumnProps {
  content: JSX.Element | string;
}

export default function AdjustmentsColumn(props: ColumnProps): JSX.Element {
  const { content } = props;
  return (
    <Adjustments
      sx={{
        width: {
          xs: '60px',
          lg: '100px',
        },
      }}
    >
      {content}
    </Adjustments>
  );
}
