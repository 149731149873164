import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

type DatePickerProps = {
  date: string;
  label?: string;
  showErrorState?: boolean;
  setDate: (date: string) => void;
  shouldDisableDateFromDatePicker?: () => boolean;
};

const getSlotProps = (showErrorState?: boolean) => {
  let errorState = true;
  if (showErrorState !== undefined) {
    errorState = showErrorState;
  }
  let slotProps = {};
  if (!errorState) {
    slotProps = {
      textField: {
        error: false,
      },
    };
  }
  return slotProps;
};

const DatePickerComponent: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const { date, showErrorState, setDate, label, shouldDisableDateFromDatePicker } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        slotProps={getSlotProps(showErrorState)}
        label={label}
        value={dayjs(date, 'DD/MM/YYYY')}
        onChange={(newValue) => {
          if (newValue === null) return;
          const newDate = newValue.format('DD/MM/YYYY');
          setDate(newDate);
        }}
        format="DD/MM/YYYY"
        shouldDisableDate={
          shouldDisableDateFromDatePicker ? () => shouldDisableDateFromDatePicker() : () => false
        }
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
