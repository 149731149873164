import SimpleButton from '../../components/Buttons/SimpleButton';
import Image404 from '../../assets/404.svg';
import { Layout, Text404, Text, Button } from './Styles';
// import returnIcon from '../../assets/return.svg';

function NotFoundPage(): JSX.Element {
  return (
    <Layout>
      <Text404 src={Image404} loading="lazy" alt="404" />
      <Text>Oops! Page Not Found</Text>
      <Button>
        <SimpleButton
          content="Back to Home"
          onClick={() => {
            window.location.href = '/';
          }}
        />
      </Button>
    </Layout>
  );
}
export default NotFoundPage;
