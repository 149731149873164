import { observer } from 'mobx-react-lite';
import NewCategoryItemDialog from './component';
import { useEntityViewModel } from '../../../view-models/EntityViewModel';

const NewEntityCategoryItemController = () => {
  const entityViewModel = useEntityViewModel();

  return (
    <NewCategoryItemDialog
      open={entityViewModel.isAddCategoryItemDialogVisible}
      closeDialog={entityViewModel.hideAddCategoryItemDialog}
      submit={() => entityViewModel.addNewCategoryItem()}
      categoryItemName={entityViewModel.categoryItemName}
      setCategoryItemName={entityViewModel.setCategoryItemName}
      areRequiredFieldsSet={entityViewModel.categoryItemName.length > 0}
    />
  );
};

const NewEntityCategoryItem = observer(NewEntityCategoryItemController);

export default NewEntityCategoryItem;
