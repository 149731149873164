import { observer } from 'mobx-react-lite';
import BankDebitsCreditsSwappedDialog from '../component';
import { useDocumentsViewModel } from '../../../../view-models/DocumentsViewModel';

function BankDebitsCreditsSwappedController() {
  const documentsViewModel = useDocumentsViewModel()!;

  return (
    <BankDebitsCreditsSwappedDialog
      open={documentsViewModel.isBankDebitsCreditsSwappedError}
      submitAction={documentsViewModel.applyBankDebitsCreditsSwapped}
      closeDialog={documentsViewModel.hideBankDebitsCreditsSwappedDialog}
    />
  );
}

const debitsCreditsSwapped = observer(BankDebitsCreditsSwappedController);

export default debitsCreditsSwapped;
