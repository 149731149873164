/* eslint-disable no-param-reassign */
import React, { createContext, useContext, useMemo } from 'react';
import { action, autorun, computed, makeAutoObservable, observable } from 'mobx';
import { utils, writeFileXLSX } from 'xlsx';
import {
  CreateReconciliationAccountParams,
  EditReconciliationAccountParams,
  Entity,
  RECONCILIATION_ACCOUNT_TYPES,
  ReconciliationAccount,
  ReconciliationAccountSlim,
} from '../models/Resources';
import {
  EntityFetchError,
  IResourcesRepository,
  ReconciliationAccountFetchError,
  UpdateReconciliationAccountError,
} from '../infra/repositories/resources';
import {
  useIamRepository,
  useReconciliationReportRepository,
  useResourcesRepository,
} from '../context/DI';
import { AsyncStatus, State, cachedState, fetchedState, initState } from '../types';
import { Events, EventBus, EditReconciliationReportDetailsPayload } from '../Events';
import {
  AdjustmentsReport,
  RECONCILIATION_MATCH_TYPES,
  ReconciliationAccountReport,
  ReconciliationReport,
  ReconciliationReportDetails,
  TransactionType,
} from '../models/ReconciliationReport';
import {
  DeleteReconciliationReportError,
  EditReconciliationReportDetailsError,
  IReconciliationReportRepository,
  ReconciliationReportFetchError,
} from '../infra/repositories/reconciliation-report';
import { AVAILABLE_USER_ROLES, User, UserRoleType } from '../models/User';
import {
  GetUsersError,
  IIamRepository,
  ReconciliationAccountUserCreatedError,
} from '../infra/repositories/iam';

import ReconciliationReportExcelFile from '../utils/report-to-excel';
import { autogeneratePassword } from '../common';
import {
  fixToThousandSeparator,
  getAbsoluteDifference,
  isPositiveOrZero,
  valueIncreases,
} from '../utils/number';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../models/Document';
import { RECONCILIATION_REPORT_STATUSES } from '../models/CreateReconciliationReport';

interface IReconciliationAccountViewModel {
  reconciliationAccountReports: State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  >;
  reconciliationAccountsList: State<ReconciliationAccountSlim[], ReconciliationAccountFetchError>;
  reconciliationAccount: State<ReconciliationAccount | null, ReconciliationReportFetchError>;
  isCreateReconciliationAccountDialogVisible: boolean;
  name: string;
  reconciliationAccountTypes: string[];
  // owner: string;
  counterpartyContactEmail: string;
  counterpartyContactName: string;
  uniqueIdentifier: string;
  areRequiredFieldsSet: boolean;
  hasReconciliationAccounts: boolean;
  availableUserRoles: UserRoleType[];
  setName: (value: string) => void;
  // setOwner: (value: string) => void;
  setCounterPartyContactName: (value: string) => void;
  setCounterPartyContactEmail: (value: string) => void;
  setUniqueIdentifier: (value: string) => void;
  createReconciliationAccount: () => void;
  showCreateReconciliationAccountDialog: () => void;
  hideCreateReconciliationAccountDialog: () => void;
  downloadAdjustmentsReport: (reportId: string) => Promise<void>;
  downloadReconciliationReport: (reportId: string) => Promise<void>;
}

export type FlattenTransaction = {
  amount: number;
  amountType: string;
  balance: number;
  date: string;
  description: string;
  id: string;
  rowId: string;
  status: string;
  financialTransactionId: string;
  reference: string;
};

class ReconciliationAccountViewModel implements IReconciliationAccountViewModel {
  private readonly CALLER = 'ReconciliationAccountViewModel';

  private _reconciliationReportId: string | null = null;

  private _showDeleteReconciliationReportDialog: boolean = false;

  _draftReconciliationAccount: ReconciliationAccount = {
    id: '',
    name: '',
    reconciliationType: '',
    counterpartyContactEmail: '',
    counterpartyContactName: '',
    uniqueIdentifier: '',
    owner: '',
    createdAt: 0,
    updatedAt: 0,
    usersList: [],
  };

  @observable
  isCreateReconciliationAccountDialogVisible: boolean = false;

  @observable
  clearedReconciliationAccount: boolean = false;

  private _reconciliationAccountTypes: string[] = [
    RECONCILIATION_ACCOUNT_TYPES.BANK,
    RECONCILIATION_ACCOUNT_TYPES.SUPPLIER,
    RECONCILIATION_ACCOUNT_TYPES.CUSTOMER,
    RECONCILIATION_ACCOUNT_TYPES.SINGLE_ACCOUNT,
    RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING,
  ];

  private _reconciliationAccountType: string = '';

  private _reconciliationAccountsList: State<
    ReconciliationAccountSlim[],
    ReconciliationAccountFetchError
  > = initState<ReconciliationAccountSlim[], ReconciliationAccountFetchError>([]);

  private _reconciliationAccount: State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  > = initState(null);

  private _reconciliationAccountReports: State<
    ReconciliationAccountReport[],
    ReconciliationReportFetchError
  > = initState([]);

  private _updateParams: EditReconciliationAccountParams = {
    name: '',
    counterpartyContactEmail: '',
    counterpartyContactName: '',
  };

  private _usersDetails: User[] = [];

  constructor(
    private resourcesRepository: IResourcesRepository,
    private reconciliationReportRepository: IReconciliationReportRepository,
    private iamRepository: IIamRepository
  ) {
    console.log('ReconciliationAccountViewModel constructor');
    this._reconciliationAccountReports = {
      ...this._reconciliationAccountReports,
      status: AsyncStatus.PENDING,
    };
    makeAutoObservable(this, {
      users: computed,
      selectReconciliationAccount: action,
    });
    autorun(() => {
      if (this._reconciliationAccount) {
        this.initializeUpdateParams();
      }
    });
    EventBus.subscribe(Events.WORKSPACE_SELECT, this.workspaceSelectedHandler);
    EventBus.subscribe(Events.ENTITY_SELECT, this.entitySelected);
    EventBus.subscribe(Events.ENTITY_CREATE, this.entityCreated);
    EventBus.subscribe(Events.RECONCILIATION_ACCOUNT_FETCH, this.reconciliationAccountFetchHandler);
    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_CREATE,
      this.reconciliationAccountCreateHandler
    );
    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_REPORTS_FETCH,
      this.reconciliationAccountReportsFetchHandler
    );
    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_UPDATE,
      this.reconciliationAccountUpdatedHandler
    );
    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_DELETE,
      this.reconciliationAccountDeleteHandler
    );

    EventBus.subscribe(Events.RECONCILIATION_ACCOUNT_USERS_FETCH, this.usersFetchedHandler);

    EventBus.subscribe(
      Events.RECONCILIATION_ACCOUNT_USER_CREATED,
      this.reconciliationAccountUserCreatedHandler
    );
    EventBus.subscribe(
      Events.EDIT_RECONCILIATION_REPORT_DETAILS,
      this.editReconciliationReportDetailsHandler
    );
    EventBus.subscribe(
      Events.DELETE_RECONCILIATION_REPORT,
      this.reconciliationReportDeletedHandler
    );
  }

  get updateParams(): EditReconciliationAccountParams {
    return this._updateParams;
  }

  get reconciliationAccountTypes(): string[] {
    return this._reconciliationAccountTypes;
  }

  get reconciliationAccountReports() {
    return this._reconciliationAccountReports;
  }

  get reconciliationAccountReportsOrderedByCreatedAt(): ReconciliationAccountReport[] {
    if (!this._reconciliationAccountReports.data) return [];
    const reports = this._reconciliationAccountReports.data.slice();
    return reports.sort((a, b) => b.createdAt - a.createdAt);
  }

  get reconciliationAccountsList(): State<
    ReconciliationAccountSlim[],
    ReconciliationAccountFetchError
  > {
    return this._reconciliationAccountsList;
  }

  get reconciliationAccountsListOrderedByName(): ReconciliationAccountSlim[] {
    if (!this._reconciliationAccountsList.data) return [];
    const reconciliationAccounts = this._reconciliationAccountsList.data.slice();
    return reconciliationAccounts.sort((a, b) => a.name.localeCompare(b.name));
  }

  get reconciliationAccount(): State<
    ReconciliationAccount | null,
    ReconciliationAccountFetchError
  > {
    return this._reconciliationAccount;
  }

  get reconciliationAccountsType(): string {
    return this._reconciliationAccountType;
  }

  get name(): string {
    return this._draftReconciliationAccount.name;
  }

  get counterpartyContactName(): string {
    return this._draftReconciliationAccount.counterpartyContactName || '';
  }

  get counterpartyContactEmail(): string {
    return this._draftReconciliationAccount.counterpartyContactEmail || '';
  }

  get reconciliationType(): string {
    return this._draftReconciliationAccount.reconciliationType || '';
  }

  get uniqueIdentifier(): string {
    return this._draftReconciliationAccount.uniqueIdentifier;
  }

  get isUniqueIdentifierSet(): boolean {
    return this._draftReconciliationAccount.uniqueIdentifier.length >= 3;
  }

  showUniqueIdentifierErrorMessage = (): string =>
    !this.isUniqueIdentifierSet ? '*minimum 3 characters required' : '';

  private get isReconciliationTypeSet(): boolean {
    return this._draftReconciliationAccount.reconciliationType.length > 0;
  }

  private get isNameSet(): boolean {
    return this._draftReconciliationAccount.name.length > 0;
  }

  get areRequiredFieldsSet(): boolean {
    return this.isNameSet && this.isReconciliationTypeSet && this.isUniqueIdentifierSet;
  }

  get hasReconciliationAccounts(): boolean {
    return this._reconciliationAccountsList.data
      ? this._reconciliationAccountsList.data.length > 0
      : false;
  }

  get availableUserRoles(): UserRoleType[] {
    return [
      AVAILABLE_USER_ROLES.ACCOUNT.ACCOUNT_MEMBER,
      AVAILABLE_USER_ROLES.ACCOUNT.ACCOUNT_GUEST,
    ];
  }

  get users(): User[] {
    return this._usersDetails;
  }

  get externalDocumentType(): EXTERNAL_DOCUMENT_TYPES {
    if (this._reconciliationAccount.data) {
      console.log('externalDocumentType', this._reconciliationAccount.data.reconciliationType);
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return EXTERNAL_DOCUMENT_TYPES.BANK;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return EXTERNAL_DOCUMENT_TYPES.SUPPLIER;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return EXTERNAL_DOCUMENT_TYPES.CUSTOMER;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return EXTERNAL_DOCUMENT_TYPES.DATASET_A;
      }

      throw new Error('Unknown reconciliation account type');
    }
    return EXTERNAL_DOCUMENT_TYPES.BANK;
  }

  get ledgerDocumentType(): LEDGER_DOCUMENT_TYPES {
    if (this._reconciliationAccount.data) {
      const reconciliationAccountType = this._reconciliationAccount.data.reconciliationType;
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.BANK) {
        return LEDGER_DOCUMENT_TYPES.LEDGER;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.SUPPLIER) {
        return LEDGER_DOCUMENT_TYPES.LEDGER;
      }
      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.CUSTOMER) {
        return LEDGER_DOCUMENT_TYPES.LEDGER;
      }

      if (reconciliationAccountType === RECONCILIATION_ACCOUNT_TYPES.TRANSACTION_MATCHING) {
        return LEDGER_DOCUMENT_TYPES.INTERNAL;
      }
      throw new Error('Unknown reconciliation account type');
    }
    return LEDGER_DOCUMENT_TYPES.LEDGER;
  }

  isReconciliationAccountReportLocked = (reportId: string): boolean => {
    let isLocked = false;
    if (!this._reconciliationAccountReports.data) {
      return isLocked;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const accountReport of this._reconciliationAccountReports.data) {
      if (accountReport.id === reportId) {
        isLocked = accountReport.status === RECONCILIATION_REPORT_STATUSES.LOCKED;
        return isLocked;
      }
    }
    return isLocked;
  };

  @action
  initializeUpdateParams() {
    this._updateParams = {
      name: this._reconciliationAccount.data?.name || '',
      counterpartyContactName: this._reconciliationAccount.data?.counterpartyContactName || '',
      counterpartyContactEmail: this._reconciliationAccount.data?.counterpartyContactEmail || '',
    };
  }

  private fixToThousandSeparator = (amount: string): string => {
    const fixedThousandAmount = fixToThousandSeparator(amount);
    return fixedThousandAmount;
  };

  @action
  fetchReconciliationAccountReports = (): void => {
    try {
      if (this._reconciliationAccount.data) {
        const repoResponse =
          this.reconciliationReportRepository.getReconciliationReportsByAccountId(
            this._reconciliationAccount.data.id
          );
        const { status, data } = repoResponse;
        if (status === AsyncStatus.SUCCESS) this._reconciliationAccountReports = fetchedState(data);
        else if (status === AsyncStatus.CACHED && data)
          this._reconciliationAccountReports = cachedState(data, Date.now());
      }
    } catch (error) {
      console.error(error);
    }
  };

  @action
  reconciliationAccountReportsFetchHandler = (
    repoResponse: State<ReconciliationAccountReport[], ReconciliationAccountFetchError>
  ) => {
    if (repoResponse.data) {
      this._reconciliationAccountReports = repoResponse;
      this._reconciliationAccountReports.data?.forEach((report) => {
        report.bankOpeningBalance = this.fixToThousandSeparator(report.bankOpeningBalance);
        report.bankClosingBalance = this.fixToThousandSeparator(report.bankClosingBalance);
        report.ledgerOpeningBalance = this.fixToThousandSeparator(report.ledgerOpeningBalance);
        report.ledgerClosingBalance = this.fixToThousandSeparator(report.ledgerClosingBalance);
      });
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  selectReconciliationAccount = (reconciliationAccountId: string): void => {
    console.log('selectReconciliationAccount', reconciliationAccountId);
    this.clearReconciliationAccountReports();
    this.clearReconciliationAccount();
    this.fetchReconciliationAccount(reconciliationAccountId);
  };

  @action
  private fetchReconciliationAccount = (reconciliationAccountId: string): void => {
    try {
      if (this._reconciliationAccount.status === AsyncStatus.IDLE) {
        this._reconciliationAccount = {
          ...this._reconciliationAccount,
          status: AsyncStatus.PENDING,
        };
      }
      this.resourcesRepository.getReconciliationAccountById(reconciliationAccountId);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  reconciliationAccountFetchHandler = (
    repoResponse: State<ReconciliationAccount | null, ReconciliationAccountFetchError>
  ) => {
    if (repoResponse.data) {
      this._reconciliationAccount = repoResponse;
      this.fetchReconciliationAccountReports();
      EventBus.emit(Events.RECONCILIATION_ACCOUNT_SELECT, this._reconciliationAccount);
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  reconciliationAccountDeleteHandler = (
    reconciliationAccount: State<string | null, ReconciliationAccountFetchError>
  ) => {
    if (reconciliationAccount.status === AsyncStatus.ERROR) {
      console.error(reconciliationAccount.error);
    }
    if (
      reconciliationAccount.data !== null &&
      reconciliationAccount.status === AsyncStatus.SUCCESS
    ) {
      this.clearReconciliationAccount();
      if (this._reconciliationAccountsList.data !== null) {
        const reconciliationAccountsNew = this._reconciliationAccountsList.data?.filter(
          (acc) => acc.id !== reconciliationAccount.data
        );
        this._reconciliationAccountsList = {
          ...this._reconciliationAccountsList,
          data: reconciliationAccountsNew,
        };

        if (
          this._reconciliationAccountsList.data &&
          this._reconciliationAccountsList.data.length > 0
        ) {
          this.selectReconciliationAccount(this._reconciliationAccountsList.data[0].id);
        }
      }
    }
  };

  @action
  private reconciliationAccountCreateHandler = (
    reconciliationAccount: State<ReconciliationAccount | null, ReconciliationAccountFetchError>
  ): void => {
    this._reconciliationAccount = reconciliationAccount;
    if (this._reconciliationAccountsList && reconciliationAccount.data) {
      const reconciliationAccountSlim: ReconciliationAccountSlim = {
        id: reconciliationAccount.data.id,
        name: reconciliationAccount.data.name,
        reconciliationType: reconciliationAccount.data.reconciliationType,
      };
      console.log(
        '[EntityViewModel] entityCreateHandler',
        reconciliationAccount,
        this._reconciliationAccountsList
      );
      if (this._reconciliationAccountsList.status === AsyncStatus.SUCCESS) {
        this._reconciliationAccountsList = {
          ...this._reconciliationAccountsList,
          data: [...this._reconciliationAccountsList.data, reconciliationAccountSlim],
        };
      } else if (
        this._reconciliationAccountsList.status === AsyncStatus.CACHED ||
        this._reconciliationAccountsList.status === AsyncStatus.IDLE
      ) {
        if (this._reconciliationAccountsList.data)
          this._reconciliationAccountsList = {
            ...this._reconciliationAccountsList,
            data: [...this._reconciliationAccountsList.data, reconciliationAccountSlim],
            error: null,
          };
        else
          this._reconciliationAccountsList = {
            fetchedAt: Date.now(),
            data: [reconciliationAccountSlim],
            status: AsyncStatus.CACHED,
            error: null,
          };
      }
    }
  };

  @action
  private workspaceSelectedHandler = () => {
    this.clearReconciliationAccount();
    this.clearReconciliationAccountReports();
    this._reconciliationAccountsList = initState([]);
  };

  @action
  setName = (name: string): void => {
    this._draftReconciliationAccount.name = name;
  };

  @action
  setCounterPartyContactName = (counterPartyContactName: string): void => {
    this._draftReconciliationAccount.counterpartyContactName = counterPartyContactName;
  };

  @action
  setCounterPartyContactEmail = (counterPartyContactEmail: string): void => {
    this._draftReconciliationAccount.counterpartyContactEmail = counterPartyContactEmail;
  };

  @action
  setReconciliationType = (reconciliationType: string): void => {
    this._draftReconciliationAccount.reconciliationType = reconciliationType;
  };

  @action
  setUniqueIdentifier = (uniqueIdentifier: string): void => {
    this._draftReconciliationAccount.uniqueIdentifier = uniqueIdentifier;
  };

  @action
  showCreateReconciliationAccountDialog = (): void => {
    this.isCreateReconciliationAccountDialogVisible = true;
  };

  @action
  selectReconciliationAccountsType = (reconciliationAccountType: string): void => {
    this._reconciliationAccountType = reconciliationAccountType;
  };

  @action
  hideCreateReconciliationAccountDialog = (): void => {
    this.isCreateReconciliationAccountDialogVisible = false;
  };

  @action
  createReconciliationAccount = (): void => {
    const params: CreateReconciliationAccountParams = {
      name: this._draftReconciliationAccount.name,
      reconciliationType: this._draftReconciliationAccount.reconciliationType,
      counterpartyContactName: this._draftReconciliationAccount.counterpartyContactName,
      counterpartyContactEmail: this._draftReconciliationAccount.counterpartyContactEmail,
      uniqueIdentifier: this._draftReconciliationAccount.uniqueIdentifier,
      workspaceId: '',
      entityId: '',
      owner: this._draftReconciliationAccount.owner,
    };
    this.resourcesRepository.createReconciliationAccount(params);
    this.hideCreateReconciliationAccountDialog();
  };

  createUserWithEmailAndRoleId = async (email: string, roleId: string) => {
    try {
      const password = autogeneratePassword();
      const userId =
        await this.iamRepository.createReconciliationAccountUserWithEmailPasswordAndRoleId({
          email,
          password,
          roleId,
        });

      if (userId)
        this.reconciliationAccount.data?.usersList.push({
          userId,
          roleId,
        });
    } catch (error) {
      console.error('[createUserWithEmailPasswordAndRoleId] error', error);
    }
  };

  reconciliationAccountUserCreatedHandler = (
    data: State<string, ReconciliationAccountUserCreatedError>
  ) => {
    if (data.status === AsyncStatus.ERROR) {
      console.error(data.error);
      return;
    }

    if (data.data !== null && data.status === AsyncStatus.SUCCESS) {
      this.fetchUsersDetails();
    }
  };

  deleteReconciliationAccount = async (): Promise<void> => {
    try {
      await this.resourcesRepository.deleteReconciliationAccount();
    } catch (error: any) {
      console.error('Delete entity error:', error);
    }
  };

  @action
  private entitySelected = (entity: State<Entity | null, EntityFetchError>) => {
    console.log('[ReconciliationAccountViewModel] ENTITY_SELECTED', entity);
    this.clearReconciliationAccount();
    this.clearReconciliationAccountReports();
    if (entity.data) {
      this._reconciliationAccountsList = {
        ...entity,
        status: AsyncStatus.CACHED,
        data: entity.data.reconciliationAccountsList,
        error: null,
      };
      if (entity.data.reconciliationAccountsList.length > 0) {
        const reconciliationAccountId = entity.data.reconciliationAccountsList[0].id;
        this.selectReconciliationAccount(reconciliationAccountId);
      }
    }
  };

  @action
  private entityCreated = () => {
    this.clearReconciliationAccount();
    this.clearReconciliationAccountReports();
    this.clearReconciliationAccounts();
  };

  @action
  private clearReconciliationAccount = (): void => {
    this._reconciliationAccount = initState(null);
    this.clearedReconciliationAccount = true;
  };

  @action
  private clearReconciliationAccountReports = (): void => {
    this._reconciliationAccountReports = initState([]);
  };

  @action
  private clearReconciliationAccounts = (): void => {
    this._reconciliationAccountsList = initState([]);
  };

  @action
  updateReconciliationAccount = async (
    reconciliationAccountParams: Partial<EditReconciliationAccountParams>
  ): Promise<void> => {
    try {
      await this.resourcesRepository.updateReconciliationAccount(reconciliationAccountParams);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  updateReconciliationAccountName = (name: string) => {
    this._updateParams = {
      ...this._updateParams,
      name,
    };
  };

  @action
  updateCounterpartyContactName = (counterpartyContactName: string) => {
    this._updateParams = {
      ...this._updateParams,
      counterpartyContactName,
    };
  };

  @action
  updateCounterpartyContactEmail = (counterpartyContactEmail: string) => {
    this._updateParams = {
      ...this._updateParams,
      counterpartyContactEmail,
    };
  };

  reconciliationAccountUpdatedHandler = (
    account: State<ReconciliationAccount, UpdateReconciliationAccountError>
  ) => {
    if (account.status === AsyncStatus.ERROR) {
      console.error(account.error);
      return;
    }
    if (account.data !== null && account.status === AsyncStatus.SUCCESS) {
      this._reconciliationAccount = fetchedState(account.data);
      console.log(
        '[ReconciliationAccountViewModel] reconciliationAccountUpdatedHandler',
        this._reconciliationAccount
      );
      if (this._reconciliationAccountsList.data !== null) {
        const accountsData = this._reconciliationAccountsList.data.map((r) => {
          if (r.id !== account.data.id) return r;
          return {
            id: account.data.id,
            name: account.data.name,
            reconciliationType: account.data.reconciliationType,
          };
        });
        this._reconciliationAccountsList = {
          ...this._reconciliationAccountsList,
          data: accountsData,
        };
      }
    }
  };

  editReconciliationReportDetails = async (
    reportId: string,
    reportDetails: ReconciliationReportDetails
  ): Promise<void> => {
    try {
      await this.reconciliationReportRepository.editReconciliationReportDetails(
        reportId,
        reportDetails
      );
    } catch (error) {
      console.error(error);
    }
  };

  editReconciliationReportDetailsHandler = (
    stateResponse: State<
      EditReconciliationReportDetailsPayload,
      EditReconciliationReportDetailsError
    >
  ) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      const { reconciliationReportId, reconciliationReportDetails } = stateResponse.data;
      const { name, label, comment } = reconciliationReportDetails;
      this._reconciliationAccountReports.data?.forEach((r) => {
        if (r.id === reconciliationReportId) {
          if (name !== undefined && name != null) r.name = name;
          if (label !== undefined && label != null) r.label = label;
          if (comment !== undefined && comment != null) r.comment = comment;
        }
      });
    }
  };

  @action
  showDeleteReconciliationReportDialog = (reportId: string) => {
    this._reconciliationReportId = reportId;
    this._showDeleteReconciliationReportDialog = true;
  };

  @action
  hideDeleteReconciliationReportDialog = (): void => {
    if (this._showDeleteReconciliationReportDialog) {
      this._showDeleteReconciliationReportDialog = false;
    }
  };

  get isDeleteReconciliationReportDialogVisible(): boolean {
    return this._showDeleteReconciliationReportDialog;
  }

  deleteReconciliationReport = async (): Promise<void> => {
    if (this._reconciliationReportId === null) return;
    try {
      await this.reconciliationReportRepository.deleteReconciliationReport(
        this._reconciliationReportId
      );
    } catch (error) {
      console.error(error);
    }
  };

  reconciliationReportDeletedHandler = (
    stateResponse: State<string, DeleteReconciliationReportError>
  ) => {
    if (stateResponse.status === AsyncStatus.ERROR) {
      console.error(stateResponse.error);
      return;
    }
    if (stateResponse.status === AsyncStatus.SUCCESS) {
      const reconciliationReportId = stateResponse.data;
      if (this._reconciliationAccountReports.data) {
        this._reconciliationAccountReports.data = this._reconciliationAccountReports.data.filter(
          (r) => r.id !== reconciliationReportId
        );
      }
      this.hideDeleteReconciliationReportDialog();
    }
  };

  downloadAdjustmentsReport = async (reportId: string): Promise<void> => {
    try {
      const report = await this.reconciliationReportRepository.getAdjustmentsReport(reportId);
      console.log(`Fetched report ${reportId}`, report);

      const ws = utils.json_to_sheet<AdjustmentsReport>([]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, ws, 'Adjustments Report');
      const fileName = `AdjustmentsReport-${reportId}.xlsx`;

      const data = this.createAdjustmentsReportData(report);
      // Add the data to the workbook
      utils.sheet_add_aoa(ws, data, {
        origin: 'A1',
      });

      // Package and Release Data (`writeFile` tries to write and save an XLSX file)
      writeFileXLSX(workbook, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  private getReconciliationAccountReportFromAdjustmentsReport = (
    report: AdjustmentsReport
  ): ReconciliationAccountReport | null => {
    if (!this._reconciliationAccountReports.data) return null;
    for (let r = 0; r < this._reconciliationAccountReports.data.length; r += 1) {
      const accountReport = this._reconciliationAccountReports.data[r];
      if (accountReport.id === report.reconciliationReportId) {
        return accountReport;
      }
    }
    return null;
  };

  private isDebitFlattenTransaction = (transaction: FlattenTransaction): boolean =>
    transaction.amountType === 'debit';

  private isCreditFlattenTransaction = (transaction: FlattenTransaction): boolean =>
    transaction.amountType === 'credit';

  private getCreatedTransactionAmount = (
    transaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    const { amount } = transaction;
    return {
      debit: this.isDebitFlattenTransaction(transaction) ? amount : null,
      credit: this.isCreditFlattenTransaction(transaction) ? amount : null,
    };
  };

  private getEditedTransactionAmount = (
    originalTransaction: FlattenTransaction,
    updatedTransaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    if (this.isDebitFlattenTransaction(originalTransaction)) {
      return this.getDebitCaseEditTransactionAmount(originalTransaction, updatedTransaction);
    }
    return this.getCreditCaseEditTransactionAmount(originalTransaction, updatedTransaction);
  };

  private getDebitCaseEditTransactionAmount = (
    originalTransaction: FlattenTransaction,
    updatedTransaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    const absoluteDifference = getAbsoluteDifference(
      originalTransaction.amount,
      updatedTransaction.amount
    );
    if (isPositiveOrZero(originalTransaction.amount)) {
      if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
        return {
          debit: absoluteDifference,
          credit: null,
        };
      }
      return {
        debit: null,
        credit: absoluteDifference,
      };
    }
    if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
      return {
        debit: absoluteDifference,
        credit: null,
      };
    }
    return {
      debit: -absoluteDifference,
      credit: null,
    };
  };

  private getCreditCaseEditTransactionAmount = (
    originalTransaction: FlattenTransaction,
    updatedTransaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    const absoluteDifference = getAbsoluteDifference(
      originalTransaction.amount,
      updatedTransaction.amount
    );
    if (isPositiveOrZero(originalTransaction.amount)) {
      if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
        return {
          debit: null,
          credit: absoluteDifference,
        };
      }
      return {
        debit: absoluteDifference,
        credit: null,
      };
    }
    if (valueIncreases(originalTransaction.amount, updatedTransaction.amount)) {
      return {
        debit: null,
        credit: absoluteDifference,
      };
    }
    return {
      debit: null,
      credit: -absoluteDifference,
    };
  };

  private getEditedDescriptionName = (description: string): string =>
    `Rounding ADJ: ${description}`;

  private getDeletedTransactionAmount = (
    transaction: FlattenTransaction
  ): { debit: number | null; credit: number | null } => {
    const updatedTransaction = { ...transaction, amount: 0 };
    return this.getEditedTransactionAmount(transaction, updatedTransaction);
  };

  private createAdjustmentsReportData = (report: AdjustmentsReport) => {
    const { createdTransactions, deletedTransactions, updatedTransactions } = report;

    const COUNTER_ACCOUNT_NAME = 'COUNTER ACCOUNT';
    const createdTransactionsData = createdTransactions.map((t) => this.flattenTransaction(t));
    const deletedTransactionsData = deletedTransactions.map((t) => this.flattenTransaction(t));

    const updatedTransactionsData = updatedTransactions.map((txs) => ({
      original: this.flattenTransaction(txs.originalTransaction),
      updated: this.flattenTransaction(txs.updatedTransaction),
    }));
    const reconciliationAccount = this._reconciliationAccount.data;
    const accountName = reconciliationAccount ? reconciliationAccount.name : '';
    const accountCode = reconciliationAccount ? reconciliationAccount.uniqueIdentifier : '';
    const accountReport = this.getReconciliationAccountReportFromAdjustmentsReport(report)!;
    const data = [
      [`Reconciliation Report for ${accountName}`],
      [
        `For period: ${accountReport.reconciliationPeriod.startingDate} - ${accountReport.reconciliationPeriod.endingDate}`,
      ],
      ['Manual Adjustments & Journal Entries'],
      [],
      ['Date', 'Description', 'Reference', 'Transaction ID', 'Account Code', 'Debit', 'Credit'],
      ...createdTransactionsData.flatMap((tx) => [
        [
          tx.date,
          tx.description,
          tx.reference,
          tx.financialTransactionId,
          accountCode,
          this.getCreatedTransactionAmount(tx).debit,
          this.getCreatedTransactionAmount(tx).credit,
        ],
        [
          tx.date,
          tx.description,
          tx.reference,
          tx.financialTransactionId,
          COUNTER_ACCOUNT_NAME,
          this.getCreatedTransactionAmount(tx).credit,
          this.getCreatedTransactionAmount(tx).debit,
        ],
        [],
      ]),
      ...updatedTransactionsData.flatMap((tx) => [
        [
          tx.updated.date,
          this.getEditedDescriptionName(tx.updated.description),
          tx.updated.reference,
          tx.updated.financialTransactionId,
          accountCode,
          this.getEditedTransactionAmount(tx.original, tx.updated).debit,
          this.getEditedTransactionAmount(tx.original, tx.updated).credit,
        ],
        [
          tx.updated.date,
          this.getEditedDescriptionName(tx.updated.description),
          tx.updated.reference,
          tx.updated.financialTransactionId,
          COUNTER_ACCOUNT_NAME,
          this.getEditedTransactionAmount(tx.original, tx.updated).credit,
          this.getEditedTransactionAmount(tx.original, tx.updated).debit,
        ],
        [],
      ]),
      ...deletedTransactionsData.flatMap((tx) => [
        [
          tx.date,
          this.getEditedDescriptionName(tx.description),
          tx.reference,
          tx.financialTransactionId,
          accountCode,
          this.getDeletedTransactionAmount(tx).debit,
          this.getDeletedTransactionAmount(tx).credit,
        ],
        [
          tx.date,
          this.getEditedDescriptionName(tx.description),
          tx.reference,
          tx.financialTransactionId,
          COUNTER_ACCOUNT_NAME,
          this.getDeletedTransactionAmount(tx).credit,
          this.getDeletedTransactionAmount(tx).debit,
        ],
        [],
      ]),
    ];
    return data;
  };

  //     const ws = utils.json_to_sheet<AdjustmentsReport>([report]);
  //     const workbook = utils.book_new();
  //     utils.book_append_sheet(workbook, ws, 'Adjustments Report');
  //     const fileName = `AdjustmentsReport-${reportId}.xlsx`;

  //     utils.sheet_add_aoa(ws, [[`Created ${new Date().toISOString()}`]], {
  //       origin: -1,
  //     });

  //     // Package and Release Data (`writeFile` tries to write and save an XLSB file)
  //     writeFileXLSX(workbook, fileName);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  private flattenTransaction(transaction: TransactionType): FlattenTransaction {
    const { amount } = transaction;
    return {
      ...transaction,
      amount: amount.amount,
      amountType: amount.type,
    };
  }

  getAutomaticReconciliationRate(report: ReconciliationReport): number {
    let automaticReconciledOrSuggestedBankTransactions = 0;
    report.matches.forEach((match) => {
      if (match.type !== RECONCILIATION_MATCH_TYPES.MANUAL) {
        match.bankStatementTransactions.forEach((matchBankTransaction) => {
          automaticReconciledOrSuggestedBankTransactions += 1;
        });
      }
    });
    const totalBankTransactions = report.externalTransactions.length;
    const percentage =
      (automaticReconciledOrSuggestedBankTransactions / totalBankTransactions) * 100;

    return percentage;
  }

  downloadReconciliationReport = async (reportId: string): Promise<void> => {
    try {
      const report =
        await this.reconciliationReportRepository.getReconciliationReportSync(reportId);
      console.log(`Fetched report ${reportId}`, report);
      const automaticReconciliationPercentage = this.getAutomaticReconciliationRate(report);

      const workbook = ReconciliationReportExcelFile.reportToExcelData(
        report,
        this._reconciliationAccount.data,
        automaticReconciliationPercentage
      );
      const fileName = `Report-${reportId}.xlsx`;
      writeFileXLSX(workbook, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  fetchUsersDetails = (): void => {
    try {
      const userIds = this.reconciliationAccount.data?.usersList.map((user) => user.userId) || [];

      console.log('account_users_fetch', userIds);
      const event = Events.RECONCILIATION_ACCOUNT_USERS_FETCH;
      this.iamRepository.getUsersDetails(userIds, event);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  usersFetchedHandler = (users: State<User[], GetUsersError>) => {
    if (users.status === AsyncStatus.ERROR) {
      console.error(users.error);
      return;
    }
    console.log('fetched users', users.data);

    if (users.data !== null && users.status === AsyncStatus.SUCCESS) {
      this._usersDetails = users.data;
    }
  };
}

const ReconciliationAccountViewModelContext = createContext<ReconciliationAccountViewModel | null>(
  null
);

interface ReconciliationAccountViewModelProviderProps {
  children: React.ReactNode;
}

const ReconciliationAccountViewModelProvider: React.FC<
  ReconciliationAccountViewModelProviderProps
> = ({ children }: ReconciliationAccountViewModelProviderProps) => {
  const resourcesRepository = useResourcesRepository();
  const reconciliationReportRepository = useReconciliationReportRepository();
  const iamRepository = useIamRepository();

  const reconciliationAccountViewModel = useMemo(
    () =>
      new ReconciliationAccountViewModel(
        resourcesRepository,
        reconciliationReportRepository,
        iamRepository
      ),
    []
  );

  return (
    <ReconciliationAccountViewModelContext.Provider value={reconciliationAccountViewModel}>
      {children}
    </ReconciliationAccountViewModelContext.Provider>
  );
};

const useReconciliationAccountViewModel = () => {
  const viewModel = useContext(ReconciliationAccountViewModelContext);
  if (!viewModel) throw new Error('No ReconciliationAccountViewModel provided');
  return viewModel;
};

export {
  ReconciliationAccountViewModel,
  ReconciliationAccountViewModelProvider,
  ReconciliationAccountViewModelContext,
  useReconciliationAccountViewModel,
};
