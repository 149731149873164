import React, { JSX } from 'react';
import { Block, Title, Input } from './Styles';

interface InputBlockProps {
  name: string;
  input: JSX.Element;
  required?: boolean;
}
const InputBlock = (props: InputBlockProps) => {
  const { name, input, required } = props;
  return (
    <Block>
      <Title>
        {name}
        {required && <span style={{ color: 'var(--primary-color)' }}>*</span>}
      </Title>
      <Input>{input}</Input>
    </Block>
  );
};

export default InputBlock;
