import { styled } from '@mui/material/styles';

export const Header: any = styled('div')({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

export const Form: any = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '20px',
  width: '380px',
  margin: '40px 0 0 0',
});

export const GoogleButton: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '380px',
  margin: '27px 0px 0px 0px',
  overflow: 'hidden',
});

export const TextSpan1: any = styled('span')(({ theme }: any) => ({
  whiteSpace: 'pre-wrap',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

export const TextSpan2: any = styled('span')(({ theme }: any) => ({
  whiteSpace: 'pre-wrap',
  color: 'var(--primary-color)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  cursor: 'pointer',
}));

export const Text: any = styled('div')(({ theme }: any) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '27px 0px 0px 0px',
}));
