import { Details, MainContent, Headers, Main, Sub, Divider } from './Styles';
import { IPageDetailsProps } from '../../types';

function PageDetails(props: IPageDetailsProps): JSX.Element {
  const { header, subheader, action } = props;
  return (
    <Details
      sx={{
        width: {
          xs: '100%',
          lg: 'var(--page-max-width)',
        },
        paddingLeft: {
          xs: '20px',
          lg: '0px',
        },
      }}
    >
      <MainContent>
        <Headers>
          <Main>{header}</Main>
          <Sub>{subheader}</Sub>
        </Headers>
        {action}
      </MainContent>
      <Divider
        sx={{
          width: {
            xs: '100%',
            lg: 'var(--page-max-width)',
          },
        }}
      />
    </Details>
  );
}

export default PageDetails;
