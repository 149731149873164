import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from '../Input';

interface NewEntityDialogProps {
  open: boolean;
  countries: string[];
  languages: string[];
  entityName: string;
  setEntityName: (value: string) => void;
  areRequiredFieldsSet: boolean;
  closeDialog: () => void;
  submit: () => void;
  country: string;
  setCountry: (value: string) => void;
  language: string;
  setLanguage: (value: string) => void;
  uniqueIdentifier: string;
  setUniqueIdentifier: (value: string) => void;
}
function NewEntityDialog(props: NewEntityDialogProps) {
  const {
    open,
    countries,
    languages,
    entityName,
    setEntityName,
    areRequiredFieldsSet,
    submit,
    closeDialog,
    country,
    setCountry,
    language,
    setLanguage,
    uniqueIdentifier,
    setUniqueIdentifier,
  } = props;

  return (
    <CreateDialog
      title="Create Entity"
      open={open}
      closeDialog={closeDialog}
      content={
        <>
          <InputBlock
            name="Entity Name"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={entityName}
                onChange={(event) => setEntityName(event.target.value)}
              />
            }
            required
          />
          <InputBlock
            name="Unique Identifier"
            input={
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={uniqueIdentifier}
                onChange={(event) => setUniqueIdentifier(event.target.value)}
              />
            }
          />
          <InputBlock
            name="Country"
            input={
              <Autocomplete
                options={countries}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={country}
                onChange={(e, value) => setCountry(value!)}
              />
            }
          />
          <InputBlock
            name="Language"
            input={
              <Autocomplete
                options={languages}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                value={language}
                onChange={(e, value) => setLanguage(value!)}
              />
            }
          />
        </>
      }
      action={<FilledButton disabled={!areRequiredFieldsSet} content="Create" onClick={submit} />}
    />
  );
}

export default NewEntityDialog;
