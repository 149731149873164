import React, { useRef } from 'react';
import MatchingBlock from './MatchBlock';
import UnreconciledTransactions from './UnreconciledTransactions/UnreconciledTransactions';
import { Section } from './Styles';
import { ManualTransaction, SelectedTransactions } from '../../models/ManualReconciliation';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../models/Document';
import LedgerTransactionButtons from './LedgerTransactionButtons';

interface ManualReconciliationProps {
  amountDifferenceLabel: string;
  reconciliationReportId: string;
  bankTransactions: ManualTransaction[];
  ledgerTransactions: ManualTransaction[];
  selectedTransactions: SelectedTransactions;
  matchTransactionsStatus: 'idle' | 'reconcilable' | 'unreconcilable';
  amountDifference?: string;
  isBankDateAscending: boolean;
  isBankCreditAscending: boolean;
  isBankDebitAscending: boolean;
  isLedgerDateAscending: boolean;
  isLedgerCreditAscending: boolean;
  isLedgerDebitAscending: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  bankQuickFilter: string;
  ledgerQuickFilter: string;
  toggleLedgerDateDropdown: () => void;
  toggleLedgerCreditDropdown: () => void;
  toggleLedgerDebitDropdown: () => void;
  toggleBankDateDropdown: () => void;
  toggleBankCreditDropdown: () => void;
  toggleBankDebitDropdown: () => void;
  reconcile: () => void;
  cancelManualReconciliation: () => void;
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  showNewLedgerTransactionDialog: () => void;
  showEditLedgerTransactionDialog: (ledgerTransaction: ManualTransaction) => void;
  generateCorrespondingTransactions: () => void;
  changeBankQuickFilter: (value: string) => void;
  changeLedgerQuickFilter: (value: string) => void;
  showBankFilterPopUpDialog: () => void;
  showLedgerFilterPopUpDialog: () => void;
  selectFilteredBankUnreconciledManualTransactions: () => void;
  selectFilteredLedgerUnreconciledManualTransactions: () => void;
  removeSelectedBankUnreconciledManualTransactions: () => void;
  removeSelectedLedgerUnreconciledManualTransactions: () => void;
}

function ManualReconciliation(props: ManualReconciliationProps): JSX.Element {
  const unreconcilableTextRef = useRef<HTMLDivElement>(null);

  const scrollToUnreconcilableText = () => {
    // TODO remove this if not needed
    console.log('scrollToUnreconcilableText', unreconcilableTextRef);
    // if (unreconcilableTextRef.current) {
    //   unreconcilableTextRef.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',
    //   });
    // const elementPosition =
    //   unreconcilableTextRef.current.getBoundingClientRect().top + window.scrollY;
    // window.scrollTo({
    //   top: elementPosition,
    //   behavior: 'smooth',
    // });
    // }
  };

  const {
    amountDifferenceLabel,
    bankTransactions,
    ledgerTransactions,
    reconciliationReportId,
    selectedTransactions,
    matchTransactionsStatus,
    amountDifference,
    isBankDateAscending,
    isBankCreditAscending,
    isBankDebitAscending,
    isLedgerDateAscending,
    isLedgerCreditAscending,
    isLedgerDebitAscending,
    externalDocumentType,
    ledgerDocumentType,
    bankQuickFilter,
    ledgerQuickFilter,
    toggleLedgerDateDropdown,
    toggleLedgerCreditDropdown,
    toggleLedgerDebitDropdown,
    toggleBankDateDropdown,
    toggleBankCreditDropdown,
    toggleBankDebitDropdown,
    selectOrUnselectBankTransaction,
    selectOrUnselectLedgerTransaction,
    reconcile,
    cancelManualReconciliation,
    showNewLedgerTransactionDialog,
    showEditLedgerTransactionDialog,
    generateCorrespondingTransactions,
    changeBankQuickFilter,
    changeLedgerQuickFilter,
    showBankFilterPopUpDialog,
    showLedgerFilterPopUpDialog,
    selectFilteredBankUnreconciledManualTransactions,
    selectFilteredLedgerUnreconciledManualTransactions,
    removeSelectedBankUnreconciledManualTransactions,
    removeSelectedLedgerUnreconciledManualTransactions,
  } = props;

  return (
    <Section>
      <LedgerTransactionButtons
        showNewLedgerTransactionDialog={showNewLedgerTransactionDialog}
        generateCorrespondingTransactions={generateCorrespondingTransactions}
      />
      <MatchingBlock
        ref={unreconcilableTextRef}
        bankTransactions={selectedTransactions.bankTransactions}
        ledgerTransactions={selectedTransactions.ledgerTransactions}
        status={matchTransactionsStatus}
        reconciliationReportId={reconciliationReportId}
        amountDifference={amountDifference}
        amountDifferenceLabel={amountDifferenceLabel}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
        selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
        reconcile={reconcile}
        cancelManualReconciliation={cancelManualReconciliation}
        showEditLedgerTransactionDialog={showEditLedgerTransactionDialog}
        removeSelectedBankUnreconciledManualTransactions={
          removeSelectedBankUnreconciledManualTransactions
        }
        removeSelectedLedgerUnreconciledManualTransactions={
          removeSelectedLedgerUnreconciledManualTransactions
        }
      />
      <UnreconciledTransactions
        ammountDifference={amountDifference}
        ammountDifferenceLabel={amountDifferenceLabel}
        status={matchTransactionsStatus}
        bankUnreconciledTransactions={bankTransactions}
        ledgerUnreconciledTransactions={ledgerTransactions}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        toggleBankDateDropdown={toggleBankDateDropdown}
        isBankDateAscending={isBankDateAscending}
        toggleBankCreditDropdown={toggleBankCreditDropdown}
        isBankCreditAscending={isBankCreditAscending}
        toggleBankDebitDropdown={toggleBankDebitDropdown}
        isBankDebitAscending={isBankDebitAscending}
        toggleLedgerDateDropdown={toggleLedgerDateDropdown}
        isLedgerDateAscending={isLedgerDateAscending}
        toggleLedgerCreditDropdown={toggleLedgerCreditDropdown}
        isLedgerCreditAscending={isLedgerCreditAscending}
        toggleLedgerDebitDropdown={toggleLedgerDebitDropdown}
        isLedgerDebitAscending={isLedgerDebitAscending}
        selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
        selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
        showNewLedgerTransactionDialog={showNewLedgerTransactionDialog}
        scrollToUnreconcilableText={scrollToUnreconcilableText}
        changeBankQuickFilter={changeBankQuickFilter}
        changeLedgerQuickFilter={changeLedgerQuickFilter}
        showBankFilterPopUpDialog={showBankFilterPopUpDialog}
        showLedgerFilterPopUpDialog={showLedgerFilterPopUpDialog}
        bankQuickFilter={bankQuickFilter}
        ledgerQuickFilter={ledgerQuickFilter}
        selectFilteredBankUnreconciledManualTransactions={
          selectFilteredBankUnreconciledManualTransactions
        }
        selectFilteredLedgerUnreconciledManualTransactions={
          selectFilteredLedgerUnreconciledManualTransactions
        }
      />
    </Section>
  );
}
export default ManualReconciliation;
