import { observer } from 'mobx-react-lite';
import LedgerDebitsCreditsSwappedDialog from '../component';
import { useDocumentsViewModel } from '../../../../view-models/DocumentsViewModel';

function LedgerDebitsCreditsSwappedController() {
  const documentsViewModel = useDocumentsViewModel()!;

  return (
    <LedgerDebitsCreditsSwappedDialog
      open={documentsViewModel.isLedgerDebitsCreditsSwappedError}
      submitAction={documentsViewModel.applyLedgerDebitsCreditsSwapped}
      closeDialog={documentsViewModel.hideLedgerDebitsCreditsSwappedDialog}
    />
  );
}

const debitsCreditsSwapped = observer(LedgerDebitsCreditsSwappedController);

export default debitsCreditsSwapped;
