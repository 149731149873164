import { useEffect } from 'react';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from './TabPanel';

interface FullWidthTabsProps {
  tabs: {
    isReconciliationReport?: boolean;
    label: string;
    content: JSX.Element;
    onClick?: () => void;
    onTabOpen?: () => void;
  }[];
  selectedTab?: number;
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(props: FullWidthTabsProps) {
  const { tabs, selectedTab } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(selectedTab || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (tabs && tabs[value].onTabOpen) {
      const tab = tabs[value];
      if (tab.onTabOpen) {
        tab.onTabOpen();
      }
    }
  }, [value]);

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          width: {
            xs: '100%',
            lg: 'var(--page-max-width)',
          },
          paddingLeft: {
            xs: '20px',
            lg: '0px',
          },
          backgroundColor: '#fafafa',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            borderRadius: 'var(--border-radius)',
          }}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                backgroundColor: index === value ? 'var(--primary-color)' : 'rgba(255,255,255,0.4)',
                color: index === value ? 'var(--white-color)' : 'var(--primary-color)',
                border: '1px solid var(--primary-color)',
                opacity: 1,
              }}
              label={tab.label}
              onClick={() => {
                if (tab.onClick) tab.onClick();
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          dir={theme.direction}
          isReconciliationReport={tab.isReconciliationReport}
        >
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
}
