import { SuggestedMatchPrimitives } from './SuggestedMatch';

export const ReconciliationTransactionStatuses = {
  Reconciled: 'Reconciled',
  Unreconciled: 'Unreconciled',
  Suggestion: 'Suggestion',
  Reversed: 'Reversed',
} as const;
export const ReconciliationTransactionStatusesArray = [
  ReconciliationTransactionStatuses.Reconciled,
  ReconciliationTransactionStatuses.Unreconciled,
  ReconciliationTransactionStatuses.Suggestion,
  ReconciliationTransactionStatuses.Reversed,
] as const;
export type TReconciliationTransactionStatus =
  (typeof ReconciliationTransactionStatusesArray)[number];

export type Transaction = {
  date: string;
  description: string;
  reference: string;
  debit: number;
  credit: number;
  status: TReconciliationTransactionStatus;
  rowId: number;
  financialTransactionId: string;
};

export type MatchType = 'M' | 'A' | 'S';

export type Match = {
  id: string;
  bankStatementTransactions: Transaction[];
  ledgerTransactions: Transaction[];
  type: MatchType;
};

type ReconciliationPeriod = {
  startingDate: string;
  endingDate: string;
};

export type ReconciliationReport = {
  id: string;
  ledgerEndBalance: number;
  bankClosingBalance: number;
  ledgerTransactions: Transaction[];
  externalTransactions: Transaction[];
  matches: Match[];
  suggestions: SuggestedMatchPrimitives[];
  status: string;
  name: string;
  reconciliationPeriod: ReconciliationPeriod;
};

export type ReconciliationAccountReport = {
  id: string;
  name: string;
  label: string;
  comment: string;
  reconciliationPeriod: { startingDate: string; endingDate: string };
  ledgerOpeningBalance: string;
  bankOpeningBalance: string;
  ledgerClosingBalance: string;
  bankClosingBalance: string;
  year: string;
  owner: string;
  status: string;
  createdAt: number;
};

export type UnreconciledTransactionsBlock = {
  title: string;
  blocks: {
    date: string;
    description: string;
    reference: string;
    amount: string;
    financialTransactionId: string;
  }[];
  type: 'ledger' | 'bank' | undefined;
  total: string;
  adjustedBalance: string;
  dateHeader?: string;
  descriptionHeader?: string;
  referenceHeader?: string;
  amountHeader: string;
};

export type ReconciledTransaction = {
  date: string;
  description: string;
  reference: string;
  debit: string;
  credit: string;
  financialTransactionId: string;
};

export type ReconciledTransactionsBlock = {
  id: string;
  bankStatementTransactions: ReconciledTransaction[];
  ledgerTransactions: ReconciledTransaction[];
  type: MatchType;
};

type AmountType = {
  amount: number;
  type: string;
};

export type TransactionType = {
  amount: AmountType;
  balance: number;
  date: string;
  description: string;
  id: string;
  rowId: string;
  status: string;
  financialTransactionId: string;
  reference: string;
};

export type UpdatedTransactionsType = {
  originalTransaction: TransactionType;
  updatedTransaction: TransactionType;
};

export type AdjustmentsReport = {
  reconciliationAdjustmentsReportId: string;
  reconciliationReportId: string;
  updatedAt: number;
  createdTransactions: TransactionType[];
  deletedTransactions: TransactionType[];
  updatedTransactions: UpdatedTransactionsType[];
};

export enum AMOUNT_TYPE {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum AMOUNT_TYPE_HEADER {
  CREDIT = 'Credit',
  DEBIT = 'Debit',
}

export type ReconciliationReportDetails = {
  name?: string;
  label?: string;
  comment?: string;
};

export const RECONCILIATION_MATCH_TYPES: Record<string, MatchType> = {
  AUTOMATIC: 'A',
  SUGGESTION: 'S',
  MANUAL: 'M',
};
