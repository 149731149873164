import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import UserPage from './page';
import { useIamViewModel } from '../../view-models/IamViewModel';

const UserController: React.FC = (props) => {
  const iamViewModel = useIamViewModel();

  useEffect(() => {
    iamViewModel.fetchUser();
  }, []);

  return (
    <UserPage
      email={iamViewModel._user.data?.email || ''}
      message={iamViewModel.authMessage}
      updateEmail={iamViewModel.updateUserEmail}
      name={iamViewModel._user.data?.name || ''}
      updateName={iamViewModel.updateUserName}
      telephone={iamViewModel._user.data?.phone || ''}
      updateTelephone={iamViewModel.updateUserPhone}
      companyName={iamViewModel._user.data?.companyName || ''}
      updateCompanyName={iamViewModel.updateUserCompanyName}
      industry={iamViewModel._user.data?.industry || ''}
      updateIndustry={iamViewModel.updateUserIndustry}
      updateUser={iamViewModel.updateUser}
      updateParams={iamViewModel.updateParams}
      countries={iamViewModel.userCountries}
      updateCountry={iamViewModel.updateUserCountry}
      country=""
      status="success"
    />
  );
};

const User = observer(UserController);

export { User };
