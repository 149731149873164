import { TransactionsBlock } from './Styles';
import BankTransactions from './BankTransactions';
import LedgerTransactions from './LedgerTransactions';
import { ManualTransaction } from '../../../../models/ManualReconciliation';

interface TransactionsProps {
  bankTransactions: ManualTransaction[];
  ledgerTransactions: ManualTransaction[];
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  showEditLedgerTransactionDialog: (ledgerTransaction: ManualTransaction) => void;
}

function Transactions(props: TransactionsProps): JSX.Element {
  const {
    bankTransactions,
    ledgerTransactions,
    selectOrUnselectBankTransaction,
    selectOrUnselectLedgerTransaction,
    showEditLedgerTransactionDialog,
  } = props;

  return (
    <TransactionsBlock
      sx={{
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
      }}
    >
      <BankTransactions
        transactions={bankTransactions}
        selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
      />
      <LedgerTransactions
        transactions={ledgerTransactions}
        selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
        showEditLedgerTransactionDialog={showEditLedgerTransactionDialog}
      />
    </TransactionsBlock>
  );
}
export default Transactions;
