import {
  Statement,
  Header,
  Choose,
  // Or
} from './Styles';
import { DOCUMENT_TYPE } from '../../../../../models/UploadFileData';
import { CONCRETE_TYPES } from '../../../../../models/Document';
import UploadFileInDifferentTemplateBlock, {
  UploadFileInDifferentTemplateProps,
} from '../UploadFile/UploadFileInDifferentTemplateBlock';
import { STATUS_STATES } from '../../../../../types';

interface ChooseStatementProps {
  uploadFileStatus: STATUS_STATES;
  type: DOCUMENT_TYPE;
  disableUpload: boolean;
  documentType: CONCRETE_TYPES;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
  setUploadFileStatus: (value: STATUS_STATES) => void;
}

function ChooseStatement(props: ChooseStatementProps): JSX.Element {
  const { uploadFileStatus, type, uploadFile, disableUpload, documentType, setUploadFileStatus } =
    props;

  return (
    <Statement>
      {type === DOCUMENT_TYPE.BANK_STATEMENT && (
        <Header style={{ color: 'var(--bank-color' }}>Other Format</Header>
      )}
      {type === DOCUMENT_TYPE.LEDGER && (
        <Header style={{ color: 'var(--ledger-color' }}>Other Format</Header>
      )}
      <Choose>
        <UploadFileInDifferentTemplateBlock
          type={type}
          disableUpload={disableUpload}
          uploadFile={uploadFile as UploadFileInDifferentTemplateProps['uploadFile']}
          concreteType={documentType}
          status={uploadFileStatus}
          setStatus={setUploadFileStatus}
        />
      </Choose>
    </Statement>
  );
}

export default ChooseStatement;
