import { styled } from '@mui/material/styles';

const TableBody = styled('div')({
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  padding: '15px 30px',
  color: '#B5B5C3',
});

export default TableBody;
