import React, { useState, JSX } from 'react';
import Avatar from '@mui/material/Avatar';
import { Content, Block, Field, Actions, Action } from './Styles';
import editIcon from '../../../../assets/edit.svg';
import undoIcon from '../../../../assets/undo.svg';
import saveIcon from '../../../../assets/save.svg';
import deleteIcon from '../../../../assets/delete.svg';

// import { Content } from '../../Buttons/FixedBottomButton/Styles';
interface UserBlockProps {
  name: string;
  avatar: string | undefined;
  email: string;
  nameInput: JSX.Element;
  updateUser: () => void;
  deleteUser: () => void;
}
function UserBlock(props: UserBlockProps) {
  const { name, nameInput, updateUser, deleteUser, avatar, email } = props;
  const [editUser, setEditUser] = useState(false);
  const handleClick = () => {
    setEditUser(!editUser);
  };
  return (
    <Block
      sx={{
        width: {
          xs: 'var(--tab-content-min-width)',
          lg: 'var(--tab-content-max-width)',
        },
      }}
    >
      <Content>
        <Avatar alt={name} src={avatar} sx={{ width: 24, height: 24 }} />
        <Field>{email}</Field>
        {editUser ? <Field>{nameInput}</Field> : <Field>{name}</Field>}
      </Content>
      <Actions>
        {editUser && (
          <Action>
            <button
              type="button" // Add the type attribute with the value "button"
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              aria-label="Save"
              onClick={updateUser}
            >
              <img src={saveIcon} alt="save" />
            </button>
          </Action>
        )}
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={handleClick}
          >
            <img src={editUser ? undoIcon : editIcon} alt="edit" />
          </button>
        </Action>
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={deleteUser}
          >
            <img src={deleteIcon} alt="delete" />
          </button>
        </Action>
      </Actions>
    </Block>
  );
}

export default UserBlock;
