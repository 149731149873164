import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import EntityListItem from './EntityListItem';
import TableBodyEntity from './Styles';
import { StopListItem } from '../../../../../models/Resources';
import { NewEntityStopListItem } from '../../../../CreateDialog/NewStopListItemDialog';

interface StopListProps {
  entityStopList: StopListItem[];
  updateEntityStopListItem: (id: string) => Promise<void>;
  deleteEntityStopListItem: (id: string) => Promise<void>;
  showAddStopListItemDialog: () => void;
  setStopListItemName: (name: string) => void;
}

function EntityListItems(props: StopListProps): JSX.Element {
  const {
    entityStopList,
    updateEntityStopListItem,
    deleteEntityStopListItem,
    showAddStopListItemDialog,
    setStopListItemName,
  } = props;

  return (
    <TableBodyEntity>
      {entityStopList.map((item) => (
        <EntityListItem
          key={item.id}
          name={item.value}
          nameInput={
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={item.value}
              onChange={(event) => setStopListItemName(event.target.value)}
            />
          }
          updateField={() => updateEntityStopListItem(item.id)}
          deleteField={() => deleteEntityStopListItem(item.id)}
        />
      ))}
      <Button variant="text" onClick={showAddStopListItemDialog}>
        Add Item
      </Button>
      <NewEntityStopListItem />
    </TableBodyEntity>
  );
}
export default EntityListItems;
