import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, DialogActions, TextField } from '@mui/material';
import InputBlock from './Input';
import FilledButton from '../../../Buttons/FilledButton';
import { UserRoleType } from '../../../../models/User';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '600px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: (theme) => theme.palette.grey[900],
        color: 'white',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface InviteUsersDialogProps {
  title: string;
  open: boolean;
  closeDialog: () => void;
  submitAction: () => void;
  email: string;
  setEmail: (email: string) => void;
  roles: UserRoleType[];
  role: UserRoleType;
  setRole: (role: UserRoleType) => void;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
}
export default function InviteUsersDialog(props: InviteUsersDialogProps) {
  const {
    title,
    open,
    submitAction,
    closeDialog,
    email,
    setEmail,
    roles,
    role,
    setRole,
    createUserWithEmailAndRoleId,
  } = props;

  return (
    <BootstrapDialog onClose={closeDialog} aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={closeDialog}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <InputBlock
          name="Email"
          input={
            <TextField
              id="outlined-basic"
              variant="outlined"
              // value={email}
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          }
          required
        />
        <InputBlock
          name="Role"
          input={
            <Autocomplete
              options={roles}
              getOptionLabel={(option) => option.displayName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
              onChange={(e, value) => setRole(value!)}
            />
          }
          required
        />
      </DialogContent>
      <DialogActions>
        <FilledButton
          content="Invite"
          onClick={() => createUserWithEmailAndRoleId(email, role.id)}
        />
      </DialogActions>
    </BootstrapDialog>
  );
}
