import React, { useState, JSX } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Text, TextSpan } from './Styles';

interface VisibleTextProps {
  text: string;
  maxCharacters: number;
}

function VisibleText(props: VisibleTextProps): JSX.Element {
  const { text, maxCharacters } = props;

  const [showFullText, setShowFullText] = useState(false);

  const handleHover = () => {
    // if (text.length > maxCharacters) {
    setShowFullText(true);
    // }
  };

  const handleMouseLeave = () => {
    setShowFullText(false);
  };

  return (
    <Text>
      <Tooltip open={showFullText} title={text} placement="top">
        <TextSpan onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
          {text.length > maxCharacters ? `${text.substring(0, maxCharacters)}...` : text}
        </TextSpan>
      </Tooltip>
    </Text>
  );
}

export default VisibleText;
