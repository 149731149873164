import { styled } from '@mui/material/styles';

export const Block: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  height: '60px',
  padding: '2px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  overflow: 'visible',
  zIndex: 1,
});

export const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
});

export const Field: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  width: '100%',
});

export const Actions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
});

export const Action = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});
