import React, { JSX } from 'react';
// import NewReconciliationReportButton from '../../components/Buttons/CreateButtons/NewReconciliationReportButton';
// import NewReconciliationAccountButton from '../../components/Buttons/CreateButtons/NewReconciliationAccountButton';
import NewEntityButton from '../../components/Buttons/CreateButtons/NewEntityButton';
import NewWorkspaceButton from '../../components/Buttons/CreateButtons/NewWorkspaceButton';

type HomePageProps = {
  createNewReconciliationReport: () => void;

  showCreateWorkspaceDialog: () => void;
  showCreateEntityDialog: () => void;
  showCreateReconciliationAccountDialog: () => void;

  hasWorkspaces: boolean;
  hasEntities: boolean;
  hasReconciliationAccounts: boolean;
  hasSelectedEntity: boolean;
  hasSelectedReconciliationAccount: boolean;
};

function HomePage(props: HomePageProps): JSX.Element {
  const {
    createNewReconciliationReport,
    showCreateReconciliationAccountDialog,
    showCreateWorkspaceDialog,
    hasSelectedEntity,
    hasSelectedReconciliationAccount,
    hasReconciliationAccounts,
    hasEntities,
    showCreateEntityDialog,
    hasWorkspaces,
  } = props;
  return (
    <main>
      {hasWorkspaces && <NewEntityButton onClick={showCreateEntityDialog} />}
      {!hasWorkspaces && <NewWorkspaceButton onClick={showCreateWorkspaceDialog} />}
    </main>
  );
}
export default HomePage;
