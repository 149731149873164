import React, { createContext, useContext, useMemo } from 'react';
import { action, autorun, computed, makeAutoObservable, observable } from 'mobx';
import * as Resources from '../models/Resources';
import {
  EntityFetchError,
  EntitySettingsFetchError,
  IResourcesRepository,
  UpdateEntityError,
  UpdateReconciliationAccountError,
  WorkspaceFetchError,
} from '../infra/repositories/resources';
import { useIamRepository, useResourcesRepository } from '../context/DI';
import { AsyncStatus, State, cachedState, fetchedState, initState } from '../types';
import { Workspace } from '../infra/interfaces/IResourcesService';
import { EntitySlim, Entity, EntitySettings, StopListItem, Category } from '../models/Resources';
import {
  Events,
  EventBus,
  CategoryItemPayload,
  CategoryItemDeletedPayload,
  StopListItemId,
  CategoryId,
} from '../Events';
import { AVAILABLE_USER_ROLES, User, UserRoleType } from '../models/User';
import { EntityUserCreatedError, GetUsersError, IIamRepository } from '../infra/repositories/iam';
import { autogeneratePassword } from '../common';

interface IEntityViewModel {
  entities: State<EntitySlim[], null>;
  entity: State<Entity | null, EntityFetchError>;
  isCreateEntityDialogVisible: boolean;
  name: string;
  country: string;
  language: string;
  uniqueIdentifier: string;
  areRequiredFieldsSet: boolean;
  hasEntities: boolean;
  selectEntity: (entityId: string) => void;
  setName: (value: string) => void;
  setCountry: (value: string) => void;
  setLanguage: (value: string) => void;
  setUniqueIdentifier: (value: string) => void;
  createEntity: () => void;
  deleteEntity: () => Promise<void>;
  showCreateEntityDialog: () => void;
  hideCreateEntityDialog: () => void;

  entitySettings: State<Resources.EntitySettings | null, EntitySettingsFetchError>;
  stopListItemName: string;
  categoryName: string;
  categoryItemName: string;
  availableUserRoles: UserRoleType[];
  setStopListItemName: (value: string) => void;
  setCategoryName: (value: string) => void;
  setCategoryItemName: (value: string) => void;
  showAddStopListItemDialog: () => void;
  hideAddStopListItemDialog: () => void;
  showAddCategoryDialog: () => void;
  hideAddCategoryDialog: () => void;
  showAddCategoryItemDialog: (categoryId: string) => void;
  hideAddCategoryItemDialog: () => void;
  showEditCategoryItemDialog: (categoryId: string, categoryItem: string) => void;
  hideEditCategoryItemDialog: () => void;

  updateStopListItem: (id: string) => Promise<void>;
  deleteStopListItem: (id: string) => Promise<void>;
  addStopListItem: () => Promise<void>;
  addNewCategory: () => Promise<void>;
  updateCategoryName: (categoryId: string) => Promise<void>;
  deleteCategory: (categoryId: string) => Promise<void>;
  addNewCategoryItem: () => Promise<void>;
  updateCategoryItem: () => Promise<void>;
  deleteCategoryItem: () => Promise<void>;
  // TODO Add enable/disable workspace settings
  enableWorkspaceStopListItem: (id: string) => Promise<void>;
  disableWorkspaceStopListItem: (id: string) => Promise<void>;
  enableWorkspaceCategory: (id: string) => Promise<void>;
  disableWorkspaceCategory: (id: string) => Promise<void>;
}

class EntityViewModel implements IEntityViewModel {
  private readonly CALLER = 'EntityViewModel';

  _draftEntity: Entity = {
    id: '',
    name: '',
    country: '',
    language: '',
    uniqueIdentifier: '',
    owner: '',
    reconciliationAccountsList: [],
    createdAt: Date.now(),
    updatedAt: Date.now(),
    usersList: [],
  };

  // private _selectedEntityId: string | null = null;

  @observable
  isCreateEntityDialogVisible: boolean = false;

  @observable
  clearedEntity: boolean = false;

  private _entities: State<EntitySlim[], null> = initState([]);

  private _entity: {
    currentEntity: State<Entity | null, EntityFetchError>;
    previousEntity: Entity | null;
  } = {
    currentEntity: initState(null),
    previousEntity: null,
  };

  private _updateParams: Resources.EditEntityParams = {
    name: '',
    country: '',
    language: '',
  };

  private _entitySettings: State<EntitySettings | null, EntitySettingsFetchError> = initState<
    EntitySettings | null,
    EntitySettingsFetchError
  >(null);

  private _draftStopListItemName = '';

  private _draftCategoryName = '';

  private _draftCategoryItemName = '';

  private _isAddStopListItemDialogVisible = false;

  private _isAddCategoryDialogVisible = false;

  private _isAddCategoryItemDialogVisible = false;

  private _isEditCategoryItemDialogVisible = false;

  private _categoryIdDialog: string | null = null;

  private _categoryItemIdDialog: string | null = null;

  private _usersDetails: User[] = [];

  constructor(
    private resourcesRepository: IResourcesRepository,
    private iamRepository: IIamRepository
  ) {
    makeAutoObservable(this, {
      entity: computed,
      users: computed,
      entitySettings: computed,
      isAddStopListItemDialogVisible: computed,
      isAddCategoryDialogVisible: computed,
      isAddCategoryItemDialogVisible: computed,
      isEditCategoryItemDialogVisible: computed,
    });
    autorun(() => {
      if (this._entity) {
        this.initializeUpdateParams();
      }
    });
    // this.resourcesRepository.registerCallback<RepoEntity>(ResourcesRepositoryEvents.ENTITY_CREATED, {caller: this.CALLER, callback: this.addEntity});
    // this.resourcesRepository.registerCallback<FullWorkspace>(ResourcesRepositoryEvents.CHANGED_SELECTED_WORKSPACE, {caller: this.CALLER, callback: this.changeSelectedWorkspaceCallback});
    EventBus.subscribe(Events.WORKSPACE_SELECT, this.workspaceSelectedHandler);
    EventBus.subscribe(Events.WORKSPACE_CREATE, this.workspaceCreatedHandler);
    EventBus.subscribe(Events.WORKSPACE_DELETE_LAST, this.workspaceDeletedHandler);
    EventBus.subscribe(Events.ENTITY_FETCH, this.entityFetchHandler);
    EventBus.subscribe(Events.ENTITY_CREATE, this.entityCreateHandler);
    EventBus.subscribe(Events.ENTITY_UPDATE, this.entityUpdatedHandler);
    EventBus.subscribe(Events.RECONCILIATION_ACCOUNT_UPDATE, this.reconciliationAccountUpdated);
    EventBus.subscribe(Events.ENTITY_DELETE, this.entityDeleteHandler);

    EventBus.subscribe(Events.ENTITY_SELECT, this.fetchEntitySettings);

    EventBus.subscribe(Events.ENTITY_SETTINGS_FETCH, this.entitySettingsFetchHandler);
    EventBus.subscribe(Events.ENTITY_STOP_LIST_ITEM_CREATE, this.stopListItemCreatedHandler);
    EventBus.subscribe(Events.ENTITY_STOP_LIST_ITEM_DELETE, this.stopListItemDeletedHandler);
    EventBus.subscribe(Events.ENTITY_STOP_LIST_ITEM_UPDATE, this.stopListItemUpdatedHandler);
    EventBus.subscribe(Events.ENTITY_CATEGORY_CREATE, this.categoryCreatedHandler);
    EventBus.subscribe(Events.ENTITY_CATEGORY_DELETE, this.categoryDeletedHandler);
    EventBus.subscribe(Events.ENTITY_CATEGORY_UPDATE, this.categoryUpdatedHandler);
    EventBus.subscribe(Events.ENTITY_CATEGORY_ITEM_CREATE, this.categoryItemCreatedHandler);
    EventBus.subscribe(Events.ENTITY_CATEGORY_ITEM_DELETE, this.categoryItemDeletedHandler);
    EventBus.subscribe(Events.ENTITY_CATEGORY_ITEM_UPDATE, this.categoryItemUpdatedHandler);
    // TODO Add enable/disable workspace settings
    EventBus.subscribe(
      Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_ENABLE,
      this.stopListItemEnabledHandler
    );
    EventBus.subscribe(
      Events.ENTITY_WORKSPACE_STOP_LIST_ITEM_DISABLE,
      this.stopListItemDisabledHandler
    );
    EventBus.subscribe(Events.ENTITY_WORKSPACE_CATEGORY_ENABLE, this.categoryEnabledHandler);
    EventBus.subscribe(Events.ENTITY_WORKSPACE_CATEGORY_DISABLE, this.categoryDisabledHandler);
    EventBus.subscribe(Events.ENTITY_USERS_FETCH, this.usersFetchedHandler);

    EventBus.subscribe(Events.ENTITY_USER_CREATED, this.entityUserCreatedHandler);
  }

  get updateParams(): Resources.EditEntityParams {
    return this._updateParams;
  }

  get entities(): State<EntitySlim[], null> {
    return this._entities;
  }

  get entity(): State<Entity | null, EntityFetchError> {
    return this._entity.currentEntity;
  }

  get name(): string {
    return this._draftEntity.name;
  }

  get owner(): string {
    return this._draftEntity.owner;
  }

  get country(): string {
    return this._draftEntity.country;
  }

  get language(): string {
    return this._draftEntity.language;
  }

  get uniqueIdentifier(): string {
    return this._draftEntity.uniqueIdentifier;
  }

  get areRequiredFieldsSet(): boolean {
    return this._draftEntity.name.length > 0;
  }

  get hasEntities(): boolean {
    return this._entities?.data !== null && this._entities?.data.length > 0;
  }

  get entitySettings() {
    return this._entitySettings;
  }

  get stopListItemName(): string {
    return this._draftStopListItemName;
  }

  get categoryName(): string {
    return this._draftCategoryName;
  }

  get categoryItemName(): string {
    return this._draftCategoryItemName;
  }

  get isAddStopListItemDialogVisible() {
    return this._isAddStopListItemDialogVisible;
  }

  get isAddCategoryDialogVisible() {
    return this._isAddCategoryDialogVisible;
  }

  get isAddCategoryItemDialogVisible() {
    return this._isAddCategoryItemDialogVisible;
  }

  get isEditCategoryItemDialogVisible() {
    return this._isEditCategoryItemDialogVisible;
  }

  get availableUserRoles(): UserRoleType[] {
    return [AVAILABLE_USER_ROLES.ENTITY.ENTITY_GUEST, AVAILABLE_USER_ROLES.ENTITY.ENTITY_MEMBER];
  }

  get users(): User[] {
    return this._usersDetails;
  }

  selectEntity = (entityId: string): void => {
    this._entity = {
      currentEntity: {
        ...initState<Entity | null, EntityFetchError>(null),
        status: AsyncStatus.PENDING,
      },
      previousEntity: this._entity.currentEntity.data,
    };
    this.fetchEntity(entityId);
  };

  @action
  private fetchEntity = (entityId: string): void => {
    try {
      this.resourcesRepository.getEntityById(entityId);
    } catch (error) {
      console.error(error);
    }
  };

  entityFetchHandler = (repoResponse: State<Entity | null, EntityFetchError>) => {
    if (repoResponse.data) {
      this._entity.currentEntity = repoResponse;
      EventBus.emit(Events.ENTITY_SELECT, this._entity.currentEntity);
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  initializeUpdateParams() {
    this._updateParams = {
      name: this._entity.currentEntity.data?.name || '',
      country: this._entity.currentEntity.data?.country || '',
      language: this._entity.currentEntity.data?.language || '',
    };
  }

  @action
  setName = (name: string): void => {
    this._draftEntity.name = name;
  };

  @action
  setOwner = (owner: string): void => {
    this._draftEntity.owner = owner;
  };

  @action
  setCountry = (country: string): void => {
    this._draftEntity.country = country;
  };

  @action
  setLanguage = (language: string): void => {
    this._draftEntity.language = language;
  };

  @action
  setUniqueIdentifier = (uniqueIdentifier: string): void => {
    this._draftEntity.uniqueIdentifier = uniqueIdentifier;
  };

  @action
  setStopListItemName = (name: string): void => {
    this._draftStopListItemName = name;
  };

  @action
  setCategoryName = (value: string): void => {
    this._draftCategoryName = value;
  };

  @action
  setCategoryItemName = (value: string): void => {
    this._draftCategoryItemName = value;
  };

  @action
  showCreateEntityDialog = (): void => {
    this.isCreateEntityDialogVisible = true;
  };

  @action
  hideCreateEntityDialog = (): void => {
    this.isCreateEntityDialogVisible = false;
  };

  @action
  showAddStopListItemDialog = (): void => {
    this._isAddStopListItemDialogVisible = true;
  };

  @action
  hideAddStopListItemDialog = (): void => {
    this._isAddStopListItemDialogVisible = false;
  };

  @action
  showAddCategoryDialog = (): void => {
    this._isAddCategoryDialogVisible = true;
  };

  @action
  hideAddCategoryDialog = (): void => {
    this._isAddCategoryDialogVisible = false;
  };

  @action
  showAddCategoryItemDialog = (categoryId: string): void => {
    this._isAddCategoryItemDialogVisible = true;

    this._categoryIdDialog = categoryId;
  };

  @action
  hideAddCategoryItemDialog = (): void => {
    this._isAddCategoryItemDialogVisible = false;
    this._categoryIdDialog = null;
  };

  @action
  showEditCategoryItemDialog = (categoryId: string, categoryItemId: string): void => {
    this._isEditCategoryItemDialogVisible = true;

    this._categoryIdDialog = categoryId;
    this._categoryItemIdDialog = categoryItemId;
    const item = this._entitySettings.data?.categories
      .find((c) => c.id === categoryId)
      ?.items.find((i) => i.id === categoryItemId);
    if (!item) {
      throw new Error('Category item not found');
    }
    this.setCategoryItemName(item.value);
  };

  @action
  hideEditCategoryItemDialog = (): void => {
    this._isEditCategoryItemDialogVisible = false;
    this.setCategoryItemName('');
    this._categoryIdDialog = null;
    this._categoryItemIdDialog = null;
  };

  createUserWithEmailAndRoleId = async (email: string, roleId: string) => {
    try {
      const password = autogeneratePassword();
      const userId = await this.iamRepository.createEntityUserWithEmailPasswordAndRoleId({
        email,
        password,
        roleId,
      });

      if (userId)
        this.entity.data?.usersList.push({
          userId,
          roleId,
        });
    } catch (error) {
      console.error('[createUserWithEmailPasswordAndRoleId] error', error);
    }
  };

  entityUserCreatedHandler = (data: State<string, EntityUserCreatedError>) => {
    if (data.status === AsyncStatus.ERROR) {
      console.error(data.error);
      return;
    }

    if (data.data !== null && data.status === AsyncStatus.SUCCESS) {
      this.fetchUsersDetails();
    }
  };

  @action
  createEntity = (): void => {
    const params: Resources.CreateEntityParams = {
      name: this._draftEntity.name,
      country: this._draftEntity.country,
      language: this._draftEntity.language,
      uniqueIdentifier: this._draftEntity.uniqueIdentifier,
      owner: this._draftEntity.owner,
      workspaceId: '',
    };
    this.resourcesRepository.createEntity(params);
    this.hideCreateEntityDialog();
  };

  @action
  deleteEntity = async (): Promise<void> => {
    try {
      await this.resourcesRepository.deleteEntity();
    } catch (error: unknown) {
      console.error('Delete entity error:', error);
    }
  };

  @action
  private entityCreateHandler = (entity: State<Entity | null, EntityFetchError>): void => {
    this._entity.currentEntity = entity;
    if (this._entities && entity.data) {
      const entitySlim: EntitySlim = {
        id: entity.data.id,
        name: entity.data.name,
      };
      if (this._entities.status === AsyncStatus.SUCCESS) {
        this._entities = { ...this._entities, data: [...this._entities.data, entitySlim] };
      } else if (
        this._entities.status === AsyncStatus.IDLE ||
        this._entities.status === AsyncStatus.CACHED
      ) {
        if (this._entities.data)
          this._entities = {
            error: null,
            status: AsyncStatus.CACHED,
            fetchedAt: Date.now(),
            data: [...this._entities.data, entitySlim],
          };
        else
          this._entities = {
            fetchedAt: Date.now(),
            data: [entitySlim],
            status: AsyncStatus.CACHED,
            error: null,
          };
      }
    }
  };

  @action
  private entityDeleteHandler = (entity: State<string | null, EntityFetchError>) => {
    if (entity.status === AsyncStatus.ERROR) {
      console.error(entity.error);
      return;
    }
    if (entity.data !== null && entity.status === AsyncStatus.SUCCESS) {
      this.clearEntity();
      if (this._entities.data !== null) {
        const entitiesNew = this._entities.data?.filter((ent) => ent.id !== entity.data);
        this._entities = {
          ...this._entities,
          data: entitiesNew,
        };

        if (this._entities.data && this._entities.data.length > 0) {
          this.selectEntity(this._entities.data[0].id);
        }
      }
    }
  };

  @action
  private workspaceSelectedHandler = (workspaceState: State<Workspace, WorkspaceFetchError>) => {
    this.clearEntity();
    this._entities = initState([]);
    if (workspaceState.data && workspaceState.data.entities.length > 0) {
      const entitiesState = {
        data: workspaceState.data?.entities,
        status: workspaceState.status as AsyncStatus.CACHED | AsyncStatus.SUCCESS,
        fetchedAt: workspaceState.fetchedAt,
        error: null,
      };
      this._entities = entitiesState;
      if (entitiesState.data.length > 0) {
        this.selectEntity(entitiesState.data[0].id);
      }
    }
  };

  fetchUsersDetails = (): void => {
    try {
      const userIds = this.entity.data?.usersList.map((user) => user.userId) || [];
      const event = Events.ENTITY_USERS_FETCH;
      this.iamRepository.getUsersDetails(userIds, event);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  private workspaceCreatedHandler = () => {
    this.clearEntity();
    this._entities = initState([]);
  };

  @action
  private workspaceDeletedHandler = () => {
    this.clearEntity();
    this._entities = initState([]);
  };

  @action
  private clearEntity = (): void => {
    this._entity.currentEntity = initState(null);
    this._entity.previousEntity = null;
    this.clearedEntity = true;
  };

  @action
  updateEntity = async (entityParams: Partial<Resources.EditEntityParams>): Promise<void> => {
    try {
      await this.resourcesRepository.updateEntity(entityParams);
    } catch (error) {
      console.error(error);
    }
  };

  @action
  updateEntityName = (name: string) => {
    this._updateParams = {
      ...this._updateParams,
      name,
    };
  };

  @action
  updateCountry = (country: string) => {
    this._updateParams = {
      ...this._updateParams,
      country,
    };
  };

  @action
  updateLanguage = (language: string) => {
    this._updateParams = {
      ...this._updateParams,
      language,
    };
  };

  entityUpdatedHandler = (entity: State<Entity, UpdateEntityError>) => {
    if (entity.status === AsyncStatus.ERROR) {
      console.error(entity.error);
      return;
    }
    if (entity.data !== null && entity.status === AsyncStatus.SUCCESS) {
      this._entity.currentEntity = fetchedState(entity.data);
      if (this._entities.data !== null) {
        const entitiesData = this._entities.data.map((e) => {
          if (e.id !== entity.data.id) return e;
          return {
            id: entity.data.id,
            name: entity.data.name,
          };
        });
        this._entities = {
          ...this._entities,
          data: entitiesData,
        };
      }
    }
  };

  @action
  private reconciliationAccountUpdated = (
    account: State<Resources.ReconciliationAccount, UpdateReconciliationAccountError>
  ) => {
    if (this._entity && this._entity.currentEntity.data && account && account.data) {
      const accountData = account.data;
      const accountsData = this._entity.currentEntity.data.reconciliationAccountsList.map((r) => {
        if (r.id !== accountData.id) return r;
        return {
          id: accountData.id,
          name: accountData.name,
          reconciliationType: accountData.reconciliationType,
        };
      });
      this._entity.currentEntity.data.reconciliationAccountsList = accountsData;
    }
  };

  @action
  fetchEntitySettings = (): void => {
    try {
      const repoResponse = this.resourcesRepository.getEntitySettings();
      const { status, data } = repoResponse;
      if (status === AsyncStatus.SUCCESS) this._entitySettings = fetchedState(data);
      else if (status === AsyncStatus.CACHED && data) {
        this._entitySettings = cachedState(data, Date.now());
      }
    } catch (error) {
      console.error(error);
    }
  };

  @action
  private entitySettingsFetchHandler = (repoResponse: State<EntitySettings, EntityFetchError>) => {
    if (repoResponse.data) {
      this._entitySettings = repoResponse;
    } else if (repoResponse.error) {
      console.error(repoResponse.error);
    }
  };

  @action
  updateStopListItem = async (id: string): Promise<void> => {
    try {
      const newValue = this._draftStopListItemName;
      await this.resourcesRepository.editEntityStopListItem(id, newValue);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setStopListItemName('');
    }
  };

  @action
  deleteStopListItem = async (id: string) => {
    try {
      await this.resourcesRepository.deleteEntityStopListItem(id);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  addStopListItem = async () => {
    try {
      const value = this._draftStopListItemName;
      await this.resourcesRepository.addEntityStopListItem(value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setStopListItemName('');
      this.hideAddStopListItemDialog();
    }
  };

  @action
  addNewCategory = async () => {
    try {
      const value = this._draftCategoryName;
      await this.resourcesRepository.addEntityCategory(value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryName('');
      this.hideAddCategoryDialog();
    }
  };

  @action
  updateCategoryName = async (categoryId: string) => {
    try {
      const value = this._draftCategoryName;
      await this.resourcesRepository.editEntityCategoryName(categoryId, value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryName('');
    }
  };

  @action
  deleteCategory = async (categoryId: string) => {
    try {
      await this.resourcesRepository.deleteEntityCategory(categoryId);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  addNewCategoryItem = async () => {
    try {
      const value = this._draftCategoryItemName;
      const categoryId = this._categoryIdDialog;
      if (!categoryId) {
        throw new Error('Category ID is not set');
      }
      await this.resourcesRepository.addEntityCategoryItem(categoryId, value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryItemName('');
      this.hideAddCategoryItemDialog();
    }
  };

  @action
  updateCategoryItem = async () => {
    try {
      const value = this._draftCategoryItemName;
      const categoryId = this._categoryIdDialog;
      const itemId = this._categoryItemIdDialog;
      if (!categoryId || !itemId) {
        throw new Error('Category ID or Item ID is not set');
      }
      await this.resourcesRepository.editEntityCategoryItem(categoryId, itemId, value);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.setCategoryItemName('');
      this.hideEditCategoryItemDialog();
    }
  };

  @action
  deleteCategoryItem = async () => {
    try {
      const categoryId = this._categoryIdDialog;
      const itemId = this._categoryItemIdDialog;
      if (!categoryId || !itemId) {
        throw new Error('Category ID or Item ID is not set');
      }
      await this.resourcesRepository.deleteEntityCategoryItem(categoryId, itemId);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.hideEditCategoryItemDialog();
    }
  };

  @action
  enableWorkspaceStopListItem = async (id: string): Promise<void> => {
    try {
      await this.resourcesRepository.enableWorkspaceStopListItemOfEntity(id);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  disableWorkspaceStopListItem = async (id: string): Promise<void> => {
    try {
      await this.resourcesRepository.disableWorkspaceStopListItemOfEntity(id);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  enableWorkspaceCategory = async (id: string): Promise<void> => {
    try {
      await this.resourcesRepository.enableWorkspaceCategoryOfEntity(id);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  disableWorkspaceCategory = async (id: string): Promise<void> => {
    try {
      await this.resourcesRepository.disableWorkspaceCategoryOfEntity(id);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  @action
  private stopListItemCreatedHandler = (
    stopListItem: State<StopListItem, EntitySettingsFetchError>
  ) => {
    if (stopListItem.status === AsyncStatus.ERROR) {
      console.error(stopListItem.error);
      return;
    }
    if (stopListItem.data !== null && stopListItem.status === AsyncStatus.SUCCESS) {
      const { data } = stopListItem;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          stopList: [...(this._entitySettings.data?.stopList ?? []), data],
          categories: this._entitySettings.data?.categories ?? [],
          workspaceCategories: this._entitySettings.data?.workspaceCategories ?? [],
          workspaceStopList: this._entitySettings.data?.workspaceStopList ?? [],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  stopListItemDeletedHandler = (stopListItem: State<string, EntitySettingsFetchError>) => {
    if (stopListItem.status === AsyncStatus.ERROR) {
      console.error(stopListItem.error);
      return;
    }
    if (stopListItem.data !== null && stopListItem.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before deleting stop list item');
        return;
      }
      const { data } = stopListItem;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          stopList: this._entitySettings.data.stopList.filter((item) => item.id !== data),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  stopListItemUpdatedHandler = (stopListItem: State<StopListItem, EntitySettingsFetchError>) => {
    if (stopListItem.status === AsyncStatus.ERROR) {
      console.error(stopListItem.error);
      return;
    }
    if (stopListItem.data !== null && stopListItem.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before updating stop list item');
        return;
      }
      const { data } = stopListItem;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          stopList: this._entitySettings.data.stopList.map((item) =>
            item.id === data.id ? data : item
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryCreatedHandler = (category: State<Category, EntitySettingsFetchError>) => {
    if (category.status === AsyncStatus.ERROR) {
      console.error(category.error);
      return;
    }
    if (category.data !== null && category.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before creating category');
        return;
      }
      const { data } = category;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          categories: [...this._entitySettings.data.categories, data],
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryDeletedHandler = (category: State<string, EntitySettingsFetchError>) => {
    if (category.status === AsyncStatus.ERROR) {
      console.error(category.error);
      return;
    }
    if (category.data !== null && category.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before deleting category');
        return;
      }
      const { data } = category;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          categories: this._entitySettings.data.categories.filter((item) => item.id !== data),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryUpdatedHandler = (message: State<Omit<Category, 'items'>, EntitySettingsFetchError>) => {
    if (message.status === AsyncStatus.ERROR) {
      console.error(message.error);
      return;
    }
    if (message.data !== null && message.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before updating category');
        return;
      }
      const { data } = message;
      // Only the name of the category is updated
      const { name } = data;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          categories: this._entitySettings.data.categories.map((category) =>
            category.id !== data.id ? category : { ...category, name }
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryItemCreatedHandler = (
    categoryItem: State<CategoryItemPayload, EntitySettingsFetchError>
  ) => {
    if (categoryItem.status === AsyncStatus.ERROR) {
      console.error(categoryItem.error);
      return;
    }
    if (categoryItem.data !== null && categoryItem.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before creating category item');
        return;
      }
      const { data } = categoryItem;
      const { categoryId } = data;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          categories: this._entitySettings.data.categories.map((category) =>
            category.id === categoryId
              ? {
                  ...category,
                  items: [...category.items, data],
                }
              : category
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryItemDeletedHandler = (
    categoryItem: State<CategoryItemDeletedPayload, EntitySettingsFetchError>
  ) => {
    if (categoryItem.status === AsyncStatus.ERROR) {
      console.error(categoryItem.error);
      return;
    }
    if (categoryItem.data !== null && categoryItem.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before deleting category item');
        return;
      }
      const { data } = categoryItem;
      const { itemId, categoryId } = data;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          categories: this._entitySettings.data.categories.map((category) =>
            category.id === categoryId
              ? {
                  ...category,
                  items: category.items.filter((item) => item.id !== itemId),
                }
              : category
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryItemUpdatedHandler = (
    categoryItem: State<CategoryItemPayload, EntitySettingsFetchError>
  ) => {
    if (categoryItem.status === AsyncStatus.ERROR) {
      console.error(categoryItem.error);
      return;
    }
    if (categoryItem.data !== null && categoryItem.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before updating category item');
        return;
      }
      const { data } = categoryItem;
      const { id, categoryId } = data;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          categories: this._entitySettings.data.categories.map((category) =>
            category.id === categoryId
              ? {
                  ...category,
                  items: category.items.map((item) => (item.id === id ? data : item)),
                }
              : category
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  stopListItemEnabledHandler = (message: State<StopListItemId, EntitySettingsFetchError>) => {
    if (message.status === AsyncStatus.ERROR) {
      console.error(message.error);
      return;
    }
    if (message.data !== null && message.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before enabling stop list item');
        return;
      }
      const { data: id } = message;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          workspaceStopList: this._entitySettings.data.workspaceStopList.map((item) =>
            item.id !== id ? item : { ...item, isEnabled: true }
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  stopListItemDisabledHandler = (message: State<StopListItemId, EntitySettingsFetchError>) => {
    if (message.status === AsyncStatus.ERROR) {
      console.error(message.error);
      return;
    }
    if (message.data !== null && message.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before disabling stop list item');
        return;
      }
      const { data: id } = message;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          workspaceStopList: this._entitySettings.data.workspaceStopList.map((item) =>
            item.id !== id ? item : { ...item, isEnabled: false }
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryEnabledHandler = (message: State<CategoryId, EntitySettingsFetchError>) => {
    if (message.status === AsyncStatus.ERROR) {
      console.error(message.error);
      return;
    }
    if (message.data !== null && message.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before enabling category');
        return;
      }
      const { data: id } = message;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          workspaceCategories: this._entitySettings.data.workspaceCategories.map((category) =>
            category.id !== id ? category : { ...category, isEnabled: true }
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  @action
  categoryDisabledHandler = (message: State<CategoryId, EntitySettingsFetchError>) => {
    if (message.status === AsyncStatus.ERROR) {
      console.error(message.error);
      return;
    }
    if (message.data !== null && message.status === AsyncStatus.SUCCESS) {
      if (!this._entitySettings.data) {
        console.error('Entity settings should be loaded before disabling category');
        return;
      }
      const { data: id } = message;
      this._entitySettings = {
        ...this._entitySettings,
        data: {
          ...this._entitySettings.data,
          workspaceCategories: this._entitySettings.data.workspaceCategories.map((category) =>
            category.id !== id ? category : { ...category, isEnabled: false }
          ),
        },
        status: AsyncStatus.SUCCESS,
        fetchedAt: Date.now(),
        error: null,
      };
    }
  };

  usersFetchedHandler = (users: State<User[], GetUsersError>) => {
    if (users.status === AsyncStatus.ERROR) {
      console.error(users.error);
      return;
    }

    if (users.data !== null && users.status === AsyncStatus.SUCCESS) {
      this._usersDetails = users.data;
    }
  };
}

const EntityViewModelContext = createContext<EntityViewModel | null>(null);

interface EntityViewModelProviderProps {
  children: React.ReactNode;
}

const EntityViewModelProvider: React.FC<EntityViewModelProviderProps> = ({
  children,
}: EntityViewModelProviderProps) => {
  const resourcesRepository = useResourcesRepository();
  const iamRepository = useIamRepository();
  const entityViewModel = useMemo(
    () => new EntityViewModel(resourcesRepository, iamRepository),
    [resourcesRepository]
  );

  return (
    <EntityViewModelContext.Provider value={entityViewModel}>
      {children}
    </EntityViewModelContext.Provider>
  );
};

const useEntityViewModel = () => {
  const viewModel = useContext(EntityViewModelContext);
  if (!viewModel) throw new Error('No EntityViewModel provided');
  return viewModel;
};

export { EntityViewModel, EntityViewModelProvider, EntityViewModelContext, useEntityViewModel };
