import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Description, DescriptionSpan } from './Styles';
import { ManualTransaction } from '../../../../models/ManualReconciliation';

interface DescriptionBlockProps {
  type: 'Ledger' | 'Bank';
  transaction: ManualTransaction;
  maxCharacters: number;
}

function DescriptionBlock(props: DescriptionBlockProps): JSX.Element {
  const { transaction, maxCharacters, type } = props;
  const { description, financialTransactionId, reference } = transaction;

  const [showFullText, setShowFullText] = useState(false);

  const handleHover = () => {
    // if (description.length > maxCharacters) {
    setShowFullText(true);
    // }
  };

  const handleMouseLeave = () => {
    setShowFullText(false);
  };

  const descriptionAndReference =
    reference && reference.length > 0 ? `${description}, ${reference}` : description;

  return (
    <Description>
      <Tooltip
        open={showFullText}
        title={`${type}ID: ${financialTransactionId}, Reference:${reference}, Description: ${description}`}
        placement="top"
      >
        <DescriptionSpan onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
          {descriptionAndReference.length > maxCharacters
            ? `${descriptionAndReference.substring(0, maxCharacters)}...`
            : descriptionAndReference}
          {/* {description} */}
        </DescriptionSpan>
      </Tooltip>
    </Description>
  );
}

export default DescriptionBlock;
