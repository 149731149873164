import { useCallback, useState, JSX } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadFileIcon from '../../../../../assets/upload-file.svg';
import uploadedFileIcon from '../../../../../assets/uploaded-file.svg';
import { UploadFile, Icon, Content, Description, Header, Subheader } from './Styles';
import SimpleButton from '../../../../Buttons/SimpleButton';
import { DOCUMENT_TYPE } from '../../../../../models/UploadFileData';
import { CONCRETE_TYPES } from '../../../../../models/Document';
import { STATUS_STATES } from '../../../../../types';

export interface UploadFileInDifferentTemplateProps {
  type?: DOCUMENT_TYPE;
  status: STATUS_STATES;
  disableUpload: boolean;
  uploadFile: (file: File, type?: DOCUMENT_TYPE, concreteType?: CONCRETE_TYPES) => void;
  concreteType?: CONCRETE_TYPES;
  setStatus: (value: STATUS_STATES) => void;
}

const idleBorderStyle =
  // eslint-disable-next-line quotes, @typescript-eslint/quotes
  "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='6' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")";

function UploadFileInDifferentTemplateBlock(
  props: UploadFileInDifferentTemplateProps
): JSX.Element {
  const { type, status, uploadFile, disableUpload, concreteType, setStatus } = props;
  const [filename, setFilename] = useState<string>('');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        // We want to handle a single file
        const file = acceptedFiles[0];
        // Here we could set an uploading indicator=true to inform the user that the file is being uploaded
        // Or Show the name of the uploaded file so the user knows which file they selected.
        setFilename(file.name);
        setStatus('uploading');
        await uploadFile(file, type, concreteType);
        setStatus('uploaded');
      }
    },
    [type, uploadFile]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'],
      // 'image/png': ['.png'],
      // 'image/jpeg': ['.jpeg'],
      // 'application/msword': ['.doc'],
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
  });

  return (
    <UploadFile
      {...getRootProps()}
      style={{
        border: status === 'idle' ? 'none' : '1px solid var(--bank-color)',
        backgroundImage: status === 'idle' ? idleBorderStyle : 'none',
      }}
    >
      <input {...getInputProps()} />
      {status === 'uploaded' ? (
        <>
          <Icon>
            <img src={uploadedFileIcon} alt="Uploaded File Icon" />
          </Icon>
          <Content>
            <Description>{filename}</Description>
            <SimpleButton disabled={disableUpload} content="Replace File" />
          </Content>
        </>
      ) : (
        <>
          <Icon>
            <img src={uploadFileIcon} alt="Upload File Icon" />
          </Icon>
          <Content>
            <Description>
              <Header>Select a file or drag and drop here</Header>
              <Subheader>PDF file</Subheader>
            </Description>
            <SimpleButton disabled={disableUpload} content="Select File" />
          </Content>
        </>
      )}
    </UploadFile>
  );
}

export default UploadFileInDifferentTemplateBlock;
