import React, { JSX } from 'react';
import { Blocks } from './Styles';
import InnerBlock from '../../../../Blocks/InnerBlock/InnerBlock';
import InnerBlockContent from './InnerBlockContent/InnerBlockContent';
import { InnerBlocksProps } from '../../../../../types';
import InnerBlockHeader from './InnerBlockHeaders/InnerBlockHeader';

function InnerBlocks(props: InnerBlocksProps): JSX.Element {
  const { blocks, dateHeader, descriptionHeader, referenceHeader, amountHeader } = props;
  return (
    <>
      {blocks.length > 0 && (
        <InnerBlockHeader
          dateHeader={dateHeader}
          descriptionHeader={descriptionHeader}
          referenceHeader={referenceHeader}
          amountHeader={amountHeader}
        />
      )}
      <Blocks>
        {blocks.map((block, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <InnerBlock key={index}>
            <InnerBlockContent
              date={block.date}
              description={block.description}
              reference={block.reference}
              amount={block.amount}
            />
          </InnerBlock>
        ))}
      </Blocks>
    </>
  );
}
export default InnerBlocks;
