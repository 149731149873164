import { styled } from '@mui/material/styles';

export const Table = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'rgba(235, 235, 235, 0.1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  position: 'relative',
  isolation: 'isolate',
  height: 'auto',
});
