import { Button, Content, Icon } from './Styles';
import { FixedBottomButtonProps } from '../../../types';

function FixedBottomButton(props: FixedBottomButtonProps): JSX.Element {
  const { content, icon, disabled, onClick, drawerOpen } = props;
  return (
    <Button disabled={disabled} onClick={onClick}>
      {icon && <Icon>{icon}</Icon>}
      <Content> {content}</Content>
    </Button>
  );
}

export default FixedBottomButton;
