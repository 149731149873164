import * as XLSX from 'xlsx';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { Choose } from '../CreateReconciliationReport/UploadStatements/ChooseStatements/ChooseStatement/Styles';
import { DOCUMENT_TYPE } from '../../models/UploadFileData';
import { BanksSupported } from '../../view-models/ConvertersAndTemplatesViewModel';
import { CONCRETE_TYPES } from '../../models/Document';
import { Header, Statement } from './Styles';
import { STATUS_STATES } from '../../types';
import SimpleButton from '../Buttons/SimpleButton';
import UploadFileBlockButton from '../CreateReconciliationReport/UploadStatements/ChooseStatements/UploadFile/UploadFileBlockButton';

interface ConvertBankFormatProps {
  uploadFileStatus: STATUS_STATES;
  transactionTypes: BanksSupported[];
  selectedBank: BanksSupported;
  uploadedFile: File | null;
  setSelectedBank: (value: BanksSupported) => void;
  convertDocument: (ws: XLSX.WorkSheet, fileName: string, bankType: BanksSupported) => void;
  handleFileUpload: (file: File) => void;
  setUploadFileStatus: (value: STATUS_STATES) => void;
}

function ConvertBankFormat(props: ConvertBankFormatProps): JSX.Element {
  const {
    uploadFileStatus,
    transactionTypes,
    selectedBank,
    uploadedFile,
    handleFileUpload,
    convertDocument,
    setSelectedBank,
    setUploadFileStatus,
  } = props;

  const handleConvert = () => {
    if (!uploadedFile) return;

    const bankType = selectedBank as BanksSupported;
    const reader = new FileReader();

    // Read the contents of the file
    reader.onload = (e) => {
      const data = e.target?.result;

      // Check if the file is empty
      if (!data || (data instanceof ArrayBuffer && data.byteLength === 0)) {
        console.error('The file is empty.');
        return;
      }
      // Convert data to binary
      if (!(data instanceof ArrayBuffer)) throw new Error('Expected ArrayBuffer');
      const binaryData = new Uint8Array(data);

      // Parse the binary data as an Excel file
      const workbook = XLSX.read(binaryData, {
        type: 'array',
        raw: true,
      });

      // Check if the workbook has sheets
      if (workbook.SheetNames.length === 0) {
        console.error('The workbook does not contain any sheets.');
        return;
      }

      // Retrieve the name of a tab by its index.
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      convertDocument(worksheet, uploadedFile.name, bankType);
    };

    // Read the file as binary data
    reader.readAsArrayBuffer(uploadedFile);
  };

  const selectBankType = (value: BanksSupported) => {
    setSelectedBank(value);
  };

  return (
    <Statement>
      <Header>Bank Formats</Header>

      <Autocomplete
        options={transactionTypes}
        sx={{ width: 200 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} />}
        value={selectedBank}
        onChange={(e, value) => selectBankType(value || BanksSupported.NONE)}
      />
      <Choose>
        <UploadFileBlockButton
          type={DOCUMENT_TYPE.BANK_STATEMENT}
          disableUpload={false}
          uploadFile={handleFileUpload}
          concreteType={CONCRETE_TYPES.BANK_STATEMENT}
          status={uploadFileStatus}
          setStatus={setUploadFileStatus}
        />
      </Choose>
      <SimpleButton content="Convert" onClick={handleConvert} />
    </Statement>
  );
}

export default ConvertBankFormat;
