import React, { createContext, useContext, useState } from 'react';
import { action, computed, makeAutoObservable } from 'mobx';
import {
  SuggestedMatch,
  TSuggestedMatchesStatus,
  SuggestedMatchesStatuses,
  SuggestedMatchPrimitives,
  SuggestedMatchTransactionPrimitives,
  SuggestedMatchTransaction,
} from '../models/SuggestedMatch';
import { useReconciliationReportRepository } from '../context/DI';
import {
  IReconciliationReportRepository,
  SuggestedMatchesResponse,
} from '../infra/repositories/reconciliation-report';
import { RepositoryResponse } from '../types';
import { fixToThousandSeparator, fixToTwoDecimals } from '../utils/number';

interface ISuggestedMatchesViewModel {
  suggestedMatches: SuggestedMatch[];
  suggestedMatchesLoaded: boolean;
  setReconciliationReportId: (reconciliationReportId: string) => void;
  acceptSuggestedMatch: (
    reconciliationReportId: string,
    suggestedMatchId: string,
    accessToken: string | null
  ) => Promise<void | Error>;
  rejectSuggestedMatch: (
    reconciliationReportId: string,
    suggestedMatchId: string,
    accessToken: string | null
  ) => Promise<void | Error>;
  clear: () => void;
}

class SuggestedMatchesViewModel implements ISuggestedMatchesViewModel {
  private _suggestedMatches: SuggestedMatch[] = [];

  suggestedMatchesLoaded: boolean = false;

  suggestedMatchesStatus: TSuggestedMatchesStatus = SuggestedMatchesStatuses.IDLE;

  private _reconciliationReportId: string = '';

  constructor(private reconciliationReportRepository: IReconciliationReportRepository) {
    console.log('[SuggestedMatchesViewModel] constructor');
    makeAutoObservable(this);
  }

  @computed
  get suggestedMatches() {
    if (
      this.suggestedMatchesStatus === 'idle' &&
      this._reconciliationReportId &&
      this._reconciliationReportId !== ''
    ) {
      this.suggestedMatchesStatus = 'pending';
      this.fetchSuggestedMatches();
    }
    return this._suggestedMatches;
  }

  @action
  setReconciliationReportId = (reconciliationReportId: string) => {
    this._reconciliationReportId = reconciliationReportId;
  };

  @action
  private async fetchSuggestedMatches(): Promise<void | Error> {
    try {
      const { status, data } = await this.reconciliationReportRepository.getSuggestedMatches(
        this._reconciliationReportId,
        this.getSuggestedMatchesCallback
      );
      console.log('*** fetchSuggestedMatches ***', data);
      this.suggestedMatchesStatus = status;
      this._suggestedMatches = this.fixSuggestedMatchesAmounts(data);
    } catch (error) {
      this.suggestedMatchesStatus = SuggestedMatchesStatuses.ERROR;
      console.error(error);
    }
  }

  private fixToThousandSeparator = (amount: number): string => {
    const fixedTwoDecimalsAmount = fixToTwoDecimals(amount);
    const fixedThousandAmount = fixToThousandSeparator(fixedTwoDecimalsAmount);
    return fixedThousandAmount;
  };

  private fixSuggestionTransactionAmounts = (
    transactions: SuggestedMatchTransactionPrimitives[]
  ): SuggestedMatchTransaction[] => {
    const fixedTransactions: SuggestedMatchTransaction[] = [];
    transactions.forEach((transaction) => {
      const fixedTransaction = {
        date: transaction.date,
        description: transaction.description,
        reference: transaction.reference,
        debit: this.fixToThousandSeparator(transaction.debit),
        credit: this.fixToThousandSeparator(transaction.credit),
      };
      fixedTransactions.push(fixedTransaction);
    });
    return fixedTransactions;
  };

  private fixSuggestedMatchesAmounts = (matches: SuggestedMatchPrimitives[]): SuggestedMatch[] => {
    const fixedMatches: SuggestedMatch[] = [];
    matches.forEach((match) => {
      fixedMatches.push({
        id: match.id,
        bankStatementTransactions: this.fixSuggestionTransactionAmounts(
          match.bankStatementTransactions
        ),
        ledgerTransactions: this.fixSuggestionTransactionAmounts(match.ledgerTransactions),
      });
    });
    return fixedMatches;
  };

  @action
  private getSuggestedMatchesCallback = (
    repoResponse: RepositoryResponse<SuggestedMatchesResponse>
  ) => {
    if (repoResponse.ok) {
      const { status, data } = repoResponse.ok;
      console.log('[SuggestedMatchesViewModel] getSuggestedMatchesCallback', data);
      this._suggestedMatches = this.fixSuggestedMatchesAmounts(data);
      this.suggestedMatchesStatus = status;
      console.log(
        '[SuggestedMatchesViewModel] this.suggestedMatchesLoaded',
        this.suggestedMatchesLoaded
      );
      if (!this.suggestedMatchesLoaded) {
        console.log(
          '[SuggestedMatchesViewModel] setting this.suggestedMatchesLoaded',
          this.suggestedMatchesLoaded
        );
        this.suggestedMatchesLoaded = true;
      }
    } else if (repoResponse.error) {
      this.suggestedMatchesStatus = SuggestedMatchesStatuses.ERROR;
      console.error(repoResponse.error.message, repoResponse.error.code);
    }
  };

  @action
  acceptSuggestedMatch = async (
    reconciliationReportId: string,
    suggestedMatchId: string
  ): Promise<void | Error> => {
    try {
      await this.reconciliationReportRepository.acceptSuggestedMatch(
        reconciliationReportId,
        suggestedMatchId
      );
      this._suggestedMatches = this.suggestedMatches.filter(
        (suggestedMatch) => suggestedMatch.id !== suggestedMatchId
      );
    } catch (error) {
      console.error(error);
      return error as Error;
    }
    return undefined;
  };

  @action
  rejectSuggestedMatch = async (
    reconciliationReportId: string,
    suggestedMatchId: string
  ): Promise<void | Error> => {
    try {
      await this.reconciliationReportRepository.rejectSuggestedMatch(
        reconciliationReportId,
        suggestedMatchId
      );
      this._suggestedMatches = this.suggestedMatches.filter(
        (suggestedMatch) => suggestedMatch.id !== suggestedMatchId
      );
    } catch (error) {
      return error as Error;
    }
    return undefined;
  };

  @action
  acceptAllSuggestedMatches = async (): Promise<void | Error> => {
    try {
      console.log('acceptAllSuggestedMatches begin');
      await this.reconciliationReportRepository.acceptAllSuggestedMatches(
        this._reconciliationReportId
      );
      this._suggestedMatches = [];
    } catch (error) {
      console.error(error);
      return error as Error;
    }
    return undefined;
  };

  @action
  acceptSuggestedMatches = async (from: number, to: number): Promise<void | Error> => {
    try {
      console.log('acceptSuggestedMatches begin', from, to);
      const idsToBeAccepted: string[] = [];
      this._suggestedMatches
        .filter((match, index) => index >= from && index <= to)
        .map((match, index) => idsToBeAccepted.push(match.id));
      await this.reconciliationReportRepository.acceptSuggestedMatches(
        this._reconciliationReportId,
        idsToBeAccepted
      );

      this._suggestedMatches = this._suggestedMatches.filter(
        (suggestedMatch) => !idsToBeAccepted.includes(suggestedMatch.id)
      );
    } catch (error) {
      console.error(error);
      return error as Error;
    }
    return undefined;
  };

  @action
  rejectSuggestedMatches = async (from: number, to: number): Promise<void | Error> => {
    try {
      console.log('rejectSuggestedMatches begin', from, to);
      const idsToBeRejected: string[] = [];
      this._suggestedMatches
        .filter((match, index) => index >= from && index <= to)
        .map((match, index) => idsToBeRejected.push(match.id));
      await this.reconciliationReportRepository.rejectSuggestedMatches(
        this._reconciliationReportId,
        idsToBeRejected
      );

      this._suggestedMatches = this._suggestedMatches.filter(
        (suggestedMatch) => !idsToBeRejected.includes(suggestedMatch.id)
      );
    } catch (error) {
      console.error(error);
      return error as Error;
    }
    return undefined;
  };

  clear = () => {
    // Termination actions
  };
}

const SuggestedMatchesViewModelContext = createContext<{
  viewModel: SuggestedMatchesViewModel | null;
  manager: {
    setViewModel: () => void;
    clearViewModel: () => void;
    resetViewModel: () => void;
  };
} | null>(null);

interface SuggestedMatchesViewModelProviderProps {
  children: React.ReactNode;
}

const SuggestedMatchesViewModelProvider: React.FC<SuggestedMatchesViewModelProviderProps> = (
  props: SuggestedMatchesViewModelProviderProps
) => {
  const { children } = props;
  const reconciliationReportRepository = useReconciliationReportRepository();

  const [suggestedMatchesViewModel, setSuggestedMatchesViewModel] =
    useState<SuggestedMatchesViewModel | null>(
      new SuggestedMatchesViewModel(reconciliationReportRepository)
    );

  const setViewModel = () => {
    setSuggestedMatchesViewModel(new SuggestedMatchesViewModel(reconciliationReportRepository));
  };

  const clearViewModel = () => {
    suggestedMatchesViewModel?.clear();
    setSuggestedMatchesViewModel(null);
  };

  const resetViewModel = () => {
    clearViewModel();
    setViewModel();
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const suggestedMatchesViewModelBundle = {
    viewModel: suggestedMatchesViewModel,
    manager: {
      setViewModel,
      clearViewModel,
      resetViewModel,
    },
  };

  return (
    <SuggestedMatchesViewModelContext.Provider value={suggestedMatchesViewModelBundle}>
      {children}
    </SuggestedMatchesViewModelContext.Provider>
  );
};

const useSuggestedMatchesViewModel = () => {
  const viewModelBundle = useContext(SuggestedMatchesViewModelContext);
  if (!viewModelBundle) throw new Error('No SuggestedMatchesViewModel provided');
  // if (!viewModelBundle.viewModel) {
  //   console.log('Setting SuggestedMatchesViewModel');
  //   viewModelBundle.manager.setViewModel();
  // }
  // if (!viewModelBundle.viewModel)
  //   throw new Error('SuggestedMatchesViewModel not set!'); // impossible to reach
  return viewModelBundle.viewModel;
};

const useSuggestedMatchesViewModelManager = () => {
  const viewModelBundle = useContext(SuggestedMatchesViewModelContext);
  if (!viewModelBundle) throw new Error('No SuggestedMatchesViewModel provided');
  return viewModelBundle.manager;
};

export {
  SuggestedMatchesViewModel,
  SuggestedMatchesViewModelProvider,
  SuggestedMatchesViewModelContext,
  useSuggestedMatchesViewModel,
  useSuggestedMatchesViewModelManager,
};
