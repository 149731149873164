import { observer } from 'mobx-react-lite';
import DateRange from '../../../DateRange/component';
import { useReconciliationReportViewModel } from '../../../../view-models/ReconciliationReportViewModel';

const LedgerDateController = () => {
  const reconciliationReportViewModel = useReconciliationReportViewModel();
  const content = reconciliationReportViewModel ? (
    <DateRange
      startDate={reconciliationReportViewModel.ledgerFilterFromDate}
      endDate={reconciliationReportViewModel.ledgerFilterToDate}
      showErrorState={false}
      editStartDate={reconciliationReportViewModel.setLedgerFilterFromDate}
      editEndDate={reconciliationReportViewModel.setLedgerFilterToDate}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
  return content;
};

const LedgerDate = observer(LedgerDateController);

export default LedgerDate;
