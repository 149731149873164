import React from 'react';
import {
  Categories,
  SpanDate,
  SpanDescription,
  SpanReference,
  SpanDebit,
  SpanCredit,
  Date,
  Description,
  Reference,
  Debit,
  Credit,
  SpanFinancialTransactionId,
  FinancialTransactionId,
} from './Styles';

function Header(): JSX.Element {
  return (
    <Categories>
      <SpanDate>
        <Date>Date</Date>
      </SpanDate>
      <SpanDescription>
        <Description>Description</Description>
      </SpanDescription>
      {/* <SpanFinancialTransactionId>
        <FinancialTransactionId>Id</FinancialTransactionId>
      </SpanFinancialTransactionId>
      <SpanReference>
        <Reference>Reference</Reference>
      </SpanReference> */}
      <SpanDebit>
        <Debit>Debit</Debit>
      </SpanDebit>
      <SpanCredit>
        <Credit>Credit</Credit>
      </SpanCredit>
    </Categories>
  );
}
export default Header;
