import { styled } from '@mui/material/styles';

export const Name = styled('div')({
  color: 'var(--black-color)',
  display: 'flex',
  gap: '5px',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const Label = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ReconciliationPeriod = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
});

export const Balance = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
});

export const Year = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const Owner = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const Adjustments = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const Bank = styled('div')({
  color: 'var(--bank-color)',
});

export const Ledger = styled('div')({
  color: 'var(--ledger-color)',
});

export const SmallScreen = styled('div')({});
export const LargeScreen = styled('div')({});

export const Action = styled('div')({
  cursor: 'pointer',
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 'var(--border-radius)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 128, 0.04)',
  },
});

export const Text: any = styled('div')({
  textAlign: 'left',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  height: 'auto',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: '14px',
});

export const TextSpan: any = styled('span')({
  whiteSpace: 'pre-wrap',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
});
