import ChooseStatements from './ChooseStatements/ChooseStatements';
import { Section } from './Styles';
import Header from '../../Headers/SectionHeader/SectionHeader';
import { DOCUMENT_TYPE } from '../../../models/UploadFileData';
import {
  EXTERNAL_DOCUMENT_TYPES,
  LEDGER_DOCUMENT_TYPES,
  CONCRETE_TYPES,
} from '../../../models/Document';
import { STATUS_STATES } from '../../../types';

interface UploadStatementsProps {
  ledgerNotInOurTemplateUploadFileStatus: STATUS_STATES;
  bankNotInOurTemplateUploadFileStatus: STATUS_STATES;
  ledgerUploadFileStatus: STATUS_STATES;
  bankUploadFileStatus: STATUS_STATES;
  disableBankDocumentUpload: boolean;
  disableBankDocumentNotInOurTemplateUpload: boolean;
  disableLedgerDocumentUpload: boolean;
  disableLedgerDocumentNotInOurTemplateUpload: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  reconciliationAccount: string;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
  uploadFileNotInOurTemplate: (
    file: File,
    type: DOCUMENT_TYPE,
    concreteType: CONCRETE_TYPES
  ) => Promise<void>;
  setBankUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerUploadFileStatus: (value: STATUS_STATES) => void;
  setBankNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
}

function UploadStatements({
  uploadFile,
  disableBankDocumentUpload,
  disableBankDocumentNotInOurTemplateUpload,
  disableLedgerDocumentUpload,
  disableLedgerDocumentNotInOurTemplateUpload,
  externalDocumentType,
  ledgerDocumentType,
  reconciliationAccount,
  bankUploadFileStatus,
  ledgerUploadFileStatus,
  ledgerNotInOurTemplateUploadFileStatus,
  bankNotInOurTemplateUploadFileStatus,
  setBankNotInOurTemplateUploadFileStatus,
  setLedgerNotInOurTemplateUploadFileStatus,
  setBankUploadFileStatus,
  setLedgerUploadFileStatus,
  uploadFileNotInOurTemplate,
}: UploadStatementsProps): JSX.Element {
  return (
    <Section
      sx={{
        width: {
          xs: '100%',
          lg: 'var(--page-max-width)',
        },
        marginLeft: {
          xs: '25px',
          lg: '0px',
        },
      }}
    >
      <Header title="Upload Statements" />
      <ChooseStatements
        ledgerNotInOurTemplateUploadFileStatus={ledgerNotInOurTemplateUploadFileStatus}
        bankNotInOurTemplateUploadFileStatus={bankNotInOurTemplateUploadFileStatus}
        ledgerUploadFileStatus={ledgerUploadFileStatus}
        bankUploadFileStatus={bankUploadFileStatus}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        reconciliationAccount={reconciliationAccount}
        disableBankDocumentUpload={disableBankDocumentUpload}
        disableBankDocumentNotInOurTemplateUpload={disableBankDocumentNotInOurTemplateUpload}
        disableLedgerDocumentUpload={disableLedgerDocumentUpload}
        disableLedgerDocumentNotInOurTemplateUpload={disableLedgerDocumentNotInOurTemplateUpload}
        uploadFile={uploadFile}
        uploadFileNotInOurTemplate={uploadFileNotInOurTemplate}
        setBankUploadFileStatus={setBankUploadFileStatus}
        setLedgerUploadFileStatus={setLedgerUploadFileStatus}
        setBankNotInOurTemplateUploadFileStatus={setBankNotInOurTemplateUploadFileStatus}
        setLedgerNotInOurTemplateUploadFileStatus={setLedgerNotInOurTemplateUploadFileStatus}
      />
    </Section>
  );
}

export default UploadStatements;
