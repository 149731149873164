import React, { JSX } from 'react';
import {
  CONCRETE_TYPES,
  EXTERNAL_DOCUMENT_TYPES,
  LEDGER_DOCUMENT_TYPES,
} from '../../../../models/Document';
import { DOCUMENT_TYPE } from '../../../../models/UploadFileData';
import ChooseStatement from './ChooseStatement/ChooseStatement';
import {
  BankHeader,
  LedgerHeader,
  LedgerStatement,
  BankStatement,
  Statements,
  BankStatementInDifferentTemplate,
  LedgerStatementInDifferentTemplate,
} from './Styles';
import ChooseNotOurTemplateStatement from './ChooseStatement/ChooseNotOurTemplateStatement';
import { STATUS_STATES } from '../../../../types';

interface ChooseStatementsProps {
  ledgerNotInOurTemplateUploadFileStatus: STATUS_STATES;
  bankNotInOurTemplateUploadFileStatus: STATUS_STATES;
  ledgerUploadFileStatus: STATUS_STATES;
  bankUploadFileStatus: STATUS_STATES;
  disableBankDocumentUpload: boolean;
  disableBankDocumentNotInOurTemplateUpload: boolean;
  disableLedgerDocumentUpload: boolean;
  disableLedgerDocumentNotInOurTemplateUpload: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  reconciliationAccount: string;
  uploadFile: (file: File, type: DOCUMENT_TYPE, concreteType: CONCRETE_TYPES) => Promise<void>;
  uploadFileNotInOurTemplate: (
    file: File,
    type: DOCUMENT_TYPE,
    concreteType: CONCRETE_TYPES
  ) => Promise<void>;
  setBankUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerUploadFileStatus: (value: STATUS_STATES) => void;
  setBankNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
  setLedgerNotInOurTemplateUploadFileStatus: (value: STATUS_STATES) => void;
}

const getLedgerDocumentLabel = (ledgerDocumentType: LEDGER_DOCUMENT_TYPES): string => {
  if (ledgerDocumentType === LEDGER_DOCUMENT_TYPES.INTERNAL) {
    return 'Internal Data / Dataset B';
  }
  return `${ledgerDocumentType} Statement`;
};

const getExternalDocumentLabel = (
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES,
  reconciliationAccountName: string
): string => {
  if (externalDocumentType === EXTERNAL_DOCUMENT_TYPES.DATASET_A) {
    return `${reconciliationAccountName} / Dataset A`;
  }
  return `${externalDocumentType} Statement`;
};

// TODO: Get the state from Document viewmodel
function ChooseStatements(props: ChooseStatementsProps): JSX.Element {
  const {
    uploadFile,
    disableBankDocumentUpload,
    disableBankDocumentNotInOurTemplateUpload,
    disableLedgerDocumentUpload,
    disableLedgerDocumentNotInOurTemplateUpload,
    externalDocumentType,
    ledgerDocumentType,
    reconciliationAccount,
    bankUploadFileStatus,
    ledgerUploadFileStatus,
    ledgerNotInOurTemplateUploadFileStatus,
    bankNotInOurTemplateUploadFileStatus,
    setBankUploadFileStatus,
    setLedgerUploadFileStatus,
    uploadFileNotInOurTemplate,
    setBankNotInOurTemplateUploadFileStatus,
    setLedgerNotInOurTemplateUploadFileStatus,
  } = props;

  const ledgerDocumentLabel = getLedgerDocumentLabel(ledgerDocumentType);
  const externalDocumentLabel = getExternalDocumentLabel(
    externalDocumentType,
    reconciliationAccount
  );

  return (
    <Statements>
      <BankHeader style={{ color: 'var(--bank-color' }}>{externalDocumentLabel}</BankHeader>

      <BankStatement>
        <ChooseStatement
          status={bankUploadFileStatus}
          type={DOCUMENT_TYPE.BANK_STATEMENT}
          documentType={externalDocumentType as unknown as CONCRETE_TYPES}
          disableUpload={disableBankDocumentUpload}
          uploadFile={uploadFile}
          setStatus={setBankUploadFileStatus}
        />
      </BankStatement>

      <BankStatementInDifferentTemplate>
        <ChooseNotOurTemplateStatement
          uploadFileStatus={bankNotInOurTemplateUploadFileStatus}
          type={DOCUMENT_TYPE.BANK_STATEMENT}
          documentType={externalDocumentType as unknown as CONCRETE_TYPES}
          disableUpload={disableBankDocumentNotInOurTemplateUpload}
          uploadFile={uploadFileNotInOurTemplate}
          setUploadFileStatus={setBankNotInOurTemplateUploadFileStatus}
        />
      </BankStatementInDifferentTemplate>

      <LedgerHeader style={{ color: 'var(--ledger-color' }}>{ledgerDocumentLabel}</LedgerHeader>

      <LedgerStatement>
        <ChooseStatement
          status={ledgerUploadFileStatus}
          disableUpload={disableLedgerDocumentUpload}
          type={DOCUMENT_TYPE.LEDGER}
          documentType={ledgerDocumentType as unknown as CONCRETE_TYPES}
          uploadFile={uploadFile}
          setStatus={setLedgerUploadFileStatus}
        />
      </LedgerStatement>

      <LedgerStatementInDifferentTemplate>
        <ChooseNotOurTemplateStatement
          uploadFileStatus={ledgerNotInOurTemplateUploadFileStatus}
          disableUpload={disableLedgerDocumentNotInOurTemplateUpload}
          type={DOCUMENT_TYPE.LEDGER}
          documentType={ledgerDocumentType as unknown as CONCRETE_TYPES}
          uploadFile={uploadFileNotInOurTemplate}
          setUploadFileStatus={setLedgerNotInOurTemplateUploadFileStatus}
        />
      </LedgerStatementInDifferentTemplate>
    </Statements>
  );
}

export default ChooseStatements;
