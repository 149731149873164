import React, { createContext, useContext, useMemo } from 'react';
import { action, autorun, makeAutoObservable, observable } from 'mobx';
import { EventBus, Events } from '../Events';
import { BitloopsError } from '../Errors';

export interface IUIViewModel {
  showConfirmationDialog: (
    message: string,
    buttonMessage: string,
    confirmationAction: () => void
  ) => void;
  hideConfirmationDialog: () => void;
  showErrorDialog: (
    title: string,
    message: string,
    buttonMessage: string
    // confirmationAction: () => void
  ) => void;
  hideErrorDialog: () => void;
}

const CLOSE_ERROR_BUTTON_MESSAGE = 'close';
const ERROR_DIALOG_TITLE = 'Error';

class UIViewModel implements IUIViewModel {
  @observable
  isConfirmationDialogVisible: boolean = false;

  @observable
  isErrorDialogVisible: boolean = false;

  @observable
  errorDialogHided: boolean = false;

  @observable
  confirmationDialogAction: () => void = () => {};

  @observable
  confirmationDialogMessage: string = '';

  @observable
  errorDialogMessage: string = '';

  @observable
  errorDialogTitle: string = '';

  @observable
  confirmationDialogButtonMessage: string = '';

  @observable
  errorDialogButtonMessage: string = '';

  constructor() {
    makeAutoObservable(this, {});
    EventBus.subscribe(Events.ERROR, this.handleErrorEvent);
  }

  @action
  hideConfirmationDialog = (): void => {
    this.isConfirmationDialogVisible = false;
  };

  @action
  hideErrorDialog = (): void => {
    this.isErrorDialogVisible = false;
    this.errorDialogHided = true;
  };

  @action
  showConfirmationDialog = (
    message: string,
    buttonMessage: string,
    confirmationAction: () => void
  ): void => {
    this.isConfirmationDialogVisible = true;
    this.confirmationDialogMessage = message;
    this.confirmationDialogButtonMessage = buttonMessage;
    this.confirmationDialogAction = confirmationAction;
  };

  @action
  showErrorDialog = (title: string, message: string, buttonMessage: string) => {
    this.isErrorDialogVisible = true;
    this.errorDialogHided = false;
    this.errorDialogMessage = message;
    this.errorDialogButtonMessage = buttonMessage;
    this.errorDialogTitle = title;
  };

  @action
  private handleErrorEvent = (bitloopsError: BitloopsError) => {
    this.showErrorDialog(ERROR_DIALOG_TITLE, bitloopsError.message, CLOSE_ERROR_BUTTON_MESSAGE);
  };
}

const UIViewModelContext = createContext<UIViewModel | null>(null);

interface UIViewModelProviderProps {
  children: React.ReactNode;
}

const UIViewModelProvider: React.FC<UIViewModelProviderProps> = ({
  children,
}: UIViewModelProviderProps) => {
  const uiViewModel = useMemo(() => new UIViewModel(), []);

  return <UIViewModelContext.Provider value={uiViewModel}>{children}</UIViewModelContext.Provider>;
};

const useUIViewModel = () => {
  const viewModel = useContext(UIViewModelContext);
  if (!viewModel) throw new Error('No EntityViewModel provided');
  return viewModel;
};

export { UIViewModel, UIViewModelContext, useUIViewModel, UIViewModelProvider };
