import FullWidthTabs from '../FullWidthTabs';
import ProfileTabContent from './ProfileTabContent/ProfileTabContent';
import SettingsTabContent from './SettingsTabContent/SettingsTabContent';
import UsersTabContent from '../Tabs/UsersTabContent/UsersTabContent';
import { EditEntityParams } from '../../models/Resources';
import { User, UserRoleType } from '../../models/User';

interface EntityProps {
  countries: string[];
  languages: string[];
  users: User[];
  updateParams: EditEntityParams;
  userAvailableRoles: UserRoleType[];
  updateEntityName: (name: string) => void;
  updateCountry: (country: string) => void;
  updateLanguage: (language: string) => void;
  updateEntity: (updateParams: Partial<EditEntityParams>) => void;
  deleteEntity: () => Promise<void>;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
  fetchUsersDetails: () => void;
  showConfirmationDialog: (
    message: string,
    buttonMessage: string,
    confirmationAction: () => void
  ) => void;
}

function EntityView(props: EntityProps): JSX.Element {
  const {
    updateParams,
    countries,
    languages,
    users,
    updateCountry,
    updateLanguage,
    updateEntityName,
    updateEntity,
    deleteEntity,
    userAvailableRoles,
    createUserWithEmailAndRoleId,
    fetchUsersDetails,
    showConfirmationDialog,
  } = props;
  return (
    <FullWidthTabs
      tabs={[
        {
          label: 'Entity Profile',
          content: (
            <ProfileTabContent
              countries={countries}
              languages={languages}
              updateParams={updateParams}
              updateEntity={updateEntity}
              updateCountry={updateCountry}
              updateLanguage={updateLanguage}
              updateEntityName={updateEntityName}
              deleteEntity={deleteEntity}
              showConfirmationDialog={showConfirmationDialog}
            />
          ),
        },
        {
          label: 'Reconciliation Configuration',
          content: <SettingsTabContent />,
        },
        {
          label: 'Users',
          content: (
            <UsersTabContent
              users={users}
              userAvailableRoles={userAvailableRoles}
              createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
            />
          ),
          onTabOpen: () => {
            fetchUsersDetails();
          },
        },
      ]}
    />
  );
}
export default EntityView;
