import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import { useWorkspacesViewModel } from '../../../../view-models/WorkspacesViewModel';
import CategoriesView from './component';

const CategoriesController = () => {
  const workspaceViewModel = useWorkspacesViewModel();

  return workspaceViewModel.workspaceSettings.data ? (
    <CategoriesView
      categories={workspaceViewModel.workspaceSettings.data.categories}
      setCategoryName={workspaceViewModel.setCategoryName}
      updateCategoryName={workspaceViewModel.updateCategoryName}
      deleteCategory={workspaceViewModel.deleteCategory}
      showAddCategoryDialog={workspaceViewModel.showAddCategoryDialog}
      showAddCategoryItemDialog={workspaceViewModel.showAddCategoryItemDialog}
      showEditCategoryItemDialog={workspaceViewModel.showEditCategoryItemDialog}
    />
  ) : (
    <CircularProgress />
  );
};

const Categories = observer(CategoriesController);

export default Categories;
