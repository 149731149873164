import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000080',
    },
    secondary: {
      main: '#DC0529',
    },
    error: {
      main: '#920000',
    },
    success: {
      main: '#006729',
    },
  },
  typography: {
    fontFamily: ['Futura'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1440,
      xl: 1600,
    },
  },
});

export default theme;
