import React, { useState, useEffect, JSX, forwardRef } from 'react';
import { MatchBlock, IdleText, UnreconcilableText } from './Styles';
import Transactions from './Transactions';
import Actions from './Actions';
import Headers from '../Transaction/Headers/index';
import { ManualTransaction } from '../../../models/ManualReconciliation';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../models/Document';

interface MatchingProps {
  amountDifferenceLabel: string;
  reconciliationReportId: string;
  bankTransactions: ManualTransaction[];
  ledgerTransactions: ManualTransaction[];
  status: 'idle' | 'reconcilable' | 'unreconcilable';
  amountDifference?: string;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  reconcile: () => void;
  cancelManualReconciliation: () => void;
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  showEditLedgerTransactionDialog: (ledgerTransaction: ManualTransaction) => void;
  removeSelectedBankUnreconciledManualTransactions: () => void;
  removeSelectedLedgerUnreconciledManualTransactions: () => void;
}

const Matching = forwardRef<HTMLDivElement, MatchingProps>((props, ref): JSX.Element => {
  const {
    amountDifferenceLabel,
    bankTransactions,
    ledgerTransactions,
    status,
    reconciliationReportId,
    amountDifference,
    externalDocumentType,
    ledgerDocumentType,
    selectOrUnselectBankTransaction,
    selectOrUnselectLedgerTransaction,
    reconcile,
    cancelManualReconciliation,
    showEditLedgerTransactionDialog,
    removeSelectedBankUnreconciledManualTransactions,
    removeSelectedLedgerUnreconciledManualTransactions,
  } = props;
  const [backgroundColor, setBackgroundColor] = useState('#EBEBEB');

  useEffect(() => {
    switch (status) {
      case 'reconcilable':
        setBackgroundColor('rgba(0,103,41,10%)');
        break;
      case 'unreconcilable':
        setBackgroundColor('rgba(146,0,0,10%)');
        break;
      default:
    }
  }, [status]);

  return (
    <MatchBlock
      style={{
        backgroundColor,
      }}
    >
      <Headers
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
      />
      <Transactions
        bankTransactions={bankTransactions}
        ledgerTransactions={ledgerTransactions}
        selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
        selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
        showEditLedgerTransactionDialog={showEditLedgerTransactionDialog}
      />
      {status === 'unreconcilable' && (
        <UnreconcilableText ref={ref}> {amountDifferenceLabel}</UnreconcilableText>
      )}
      {status === 'idle' && (
        <IdleText>
          There are no transactions selected. Please select transactions to reconcile.
        </IdleText>
      )}
      <Actions
        reconciliationReportId={reconciliationReportId}
        status={status}
        reconcile={reconcile}
        cancel={cancelManualReconciliation}
        removeSelectedBankUnreconciledManualTransactions={
          removeSelectedBankUnreconciledManualTransactions
        }
        removeSelectedLedgerUnreconciledManualTransactions={
          removeSelectedLedgerUnreconciledManualTransactions
        }
      />
    </MatchBlock>
  );
});
export default Matching;
