import Checkbox from '@mui/material/Checkbox';
import { Block, Field, Actions, Action, Content } from '../Styles';
import { InheritedStopListItem } from '../../../../../models/Resources';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface WorkspaceListItemProps {
  item: InheritedStopListItem;
  nameInput: JSX.Element;
  enableWorkspaceStopListItem: (id: string) => Promise<void>;
  disableWorkspaceStopListItem: (id: string) => Promise<void>;
}
const WorkspaceListItem = (props: WorkspaceListItemProps) => {
  const { item, enableWorkspaceStopListItem, disableWorkspaceStopListItem } = props;
  return (
    <Block>
      <Content>
        <Field>{item.value}</Field>
      </Content>
      <Actions>
        <Action>
          <Checkbox
            checked={item.isEnabled}
            {...label}
            sx={{
              '&:hover': { bgcolor: 'transparent' },
              padding: 0,
            }}
            onClick={() => {
              if (item.isEnabled) {
                disableWorkspaceStopListItem(item.id);
              } else {
                enableWorkspaceStopListItem(item.id);
              }
            }}
          />
        </Action>
      </Actions>
    </Block>
  );
};

export default WorkspaceListItem;
