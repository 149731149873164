import { useState } from 'react';
import { List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

export interface ExpandableListItemProps {
  mainListItem: JSX.Element;
  secondaryListItem: JSX.Element;
  subListItems?: JSX.Element;
}
export function ExpandableAccordionItem(props: ExpandableListItemProps) {
  const { mainListItem, subListItems, secondaryListItem } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    const newOpenState = !open;
    setOpen(newOpenState);
  };
  const expandIcon = open ? <ExpandLess /> : <ExpandMore />;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          justifyContent: 'center',
        }}
      >
        {mainListItem}
        {secondaryListItem}
        {expandIcon}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subListItems}
        </List>
      </Collapse>
    </>
  );
}
