import TextField from '@mui/material/TextField';
import { Button, Divider } from '@mui/material';
import {
  CategoriesTable,
  TableHeader,
  TableBodyWorkspace,
  TableBodyEntity,
  Category as CategoryDiv,
  Item,
  // EntityTab,
  // WorkspaceTab,
} from './Styles';
import EntityCategoryBlock from './EntityItem';
import WorkspaceCategoryBlock from './WorkspaceItem/WorkspaceItem';
import { Category, InheritedCategory } from '../../../../models/Resources';
import { NewEntityCategory } from '../../../CreateDialog/NewCategoryDialog';
import { NewEntityCategoryItem } from '../../../CreateDialog/NewCategoryItemDialog';
import { EditEntityCategoryItem } from '../../../CreateDialog/CategoryItemControlsDialog';

interface CategoriesProps {
  categories: Category[];
  workspaceCategories: InheritedCategory[];

  updateCategoryName: (categoryId: string) => Promise<void>;
  deleteCategory: (categoryId: string) => Promise<void>;
  showAddCategoryDialog: () => void;
  setCategoryName: (value: string) => void;
  showAddCategoryItemDialog: (categoryId: string) => void;
  showEditCategoryItemDialog: (categoryId: string, itemId: string) => void;

  enableWorkspaceCategory: (categoryId: string) => Promise<void>;
  disableWorkspaceCategory: (categoryId: string) => Promise<void>;
}

function Categories(props: CategoriesProps): JSX.Element {
  const {
    // workspaceName,
    // entityName,
    workspaceCategories,
    categories,
    updateCategoryName,
    deleteCategory,
    showAddCategoryDialog,
    setCategoryName,
    showAddCategoryItemDialog,
    showEditCategoryItemDialog,
    enableWorkspaceCategory,
    disableWorkspaceCategory,
  } = props;

  return (
    <>
      {/* <WorkspaceTab>{workspaceName}</WorkspaceTab>
      <EntityTab>{entityName}</EntityTab> */}
      <CategoriesTable
        sx={{
          width: {
            xs: 'var(--tab-content-min-width)',
            lg: 'var(--tab-content-max-width)',
          },
        }}
      >
        <TableHeader>
          <CategoryDiv>Category</CategoryDiv>
          <Item>Items</Item>
        </TableHeader>
        <Divider />

        <TableBodyWorkspace>
          {workspaceCategories.map((item) => (
            <WorkspaceCategoryBlock
              key={item.id}
              name={item.name}
              items={item.items}
              checked={item.isEnabled}
              enableWorkspaceCategory={() => enableWorkspaceCategory(item.id)}
              disableWorkspaceCategory={() => disableWorkspaceCategory(item.id)}
            />
          ))}
        </TableBodyWorkspace>
        <TableBodyEntity>
          {categories.map((category) => (
            <EntityCategoryBlock
              key={category.id}
              name={category.name}
              nameInput={
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={category.name}
                  onChange={(event) => setCategoryName(event.target.value)}
                />
              }
              updateField={() => updateCategoryName(category.id)}
              deleteField={() => deleteCategory(category.id)}
              items={category.items}
              showAddCategoryItemDialog={() => showAddCategoryItemDialog(category.id)}
              showEditCategoryItemDialog={(itemId: string) =>
                showEditCategoryItemDialog(category.id, itemId)
              }
            />
          ))}
          <Button variant="text" onClick={showAddCategoryDialog}>
            Add Category
          </Button>
          <NewEntityCategory />
          <NewEntityCategoryItem />
          <EditEntityCategoryItem />
        </TableBodyEntity>
      </CategoriesTable>
    </>
  );
}
export default Categories;
