import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../../models/Document';
import LargeScreenHeaders from './LargeScreenHeaders';
import SmallScreenHeaders from './SmallScreenHeaders/index';

interface HeadersBlockProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
}

function HeadersBlock(props: HeadersBlockProps): JSX.Element {
  const { externalDocumentType, ledgerDocumentType } = props;
  return (
    <>
      <LargeScreenHeaders
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
      />
      <SmallScreenHeaders
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
      />
    </>
  );
}
export default HeadersBlock;
