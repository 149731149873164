import { TReconciliationTransactionStatus } from './ReconciliationReport';

export type ManualTransaction = {
  date: string;
  description: string;
  reference: string;
  debit: string;
  credit: string;
  debitNumber: number;
  creditNumber: number;
  status: TReconciliationTransactionStatus;
  rowId: number;
  financialTransactionId: string;
  selected: boolean;
};

export type SelectedTransactions = {
  bankTransactions: ManualTransaction[];
  ledgerTransactions: ManualTransaction[];
};

export type ManualReconcilableStatus = 'idle' | 'reconcilable' | 'unreconcilable';

export enum MANUAL_RECONCILABLE_STATUSES {
  IDLE = 'idle',
  RECONCILABLE = 'reconcilable',
  UNRECONCILABLE = 'unreconcilable',
}

export type CreateLedgerTransactionParams = {
  reconciliationReportId: string;
  financialTransactionId?: string;
  date: string;
  description: string;
  reference?: string;
  amount: number;
  transactionType: string;
};

export type CreateLedgerTransaction = {
  financialTransactionId?: string;
  date: string;
  description: string;
  reference?: string;
  amount: number;
  transactionType: string;
};
export type CreateLedgerTransactionsParams = {
  reconciliationReportId: string;
  ledgerTransactions: CreateLedgerTransaction[];
};

export type ManualLedgerTransactionParams = {
  financialTransactionId?: string;
  date: string;
  description: string;
  reference?: string;
  amount: string;
  transactionType: string;
  rowId: number;
};

export type EditLedgerTransactionParams = ManualLedgerTransactionParams & {
  reconciliationReportId: string;
  rowId: number;
};

export type DeleteLedgerTransactionParams = {
  reconciliationReportId: string;
  rowId: number;
};

export type DateFilter = {
  from: string;
  to: string;
};
export type AmountFilter = {
  from: number | null;
  to: number | null;
};
export type FilterOptions = {
  search: string;
  date: DateFilter;
  amount: AmountFilter;
};
