import CreateButton from './CreateButton';
import workspaceIcon from '../../../assets/create-buttons/workspace.svg';

interface NewWorkspaceProps {
  onClick: () => void;
}

function NewWorkspaceButton(props: NewWorkspaceProps): JSX.Element {
  const { onClick } = props;
  return <CreateButton content="Create Workspace" icon={workspaceIcon} onClick={onClick} />;
}
export default NewWorkspaceButton;
