import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
// import { List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
// import CircularProgress from '@mui/material/CircularProgress';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import Tooltip from '@mui/material/Tooltip';
import { ListItemStyles } from '../Drawer/ListItems/Styles';
import { ReconciliationAccount, ReconciliationAccountSlim } from '../../models/Resources';

function CategoryTitle({ title }: { title: string }) {
  return <div className="text-xs mt-1 font-light underline">{title}</div>;
}

function Account({
  name,
  id,
  selectReconciliationAccountHandler,
  isSelected,
}: {
  name: string;
  id: string;
  selectReconciliationAccountHandler: (id: string) => void;
  isSelected?: boolean;
}) {
  const style = isSelected
    ? 'mt-1 pl-4 py-1 rounded text-white bg-[var(--primary-color)] hover:bg-[#000A95]'
    : 'mt-1 pl-4 py-1 hover:rounded hover:bg-[var(--primary-color)] hover:text-white';
  return (
    <Tooltip title={name} placement="top" enterDelay={500} leaveDelay={200}>
      <div
        id={id}
        role="button"
        tabIndex={0}
        className={style}
        onClick={() => {
          selectReconciliationAccountHandler(id);
        }}
        onKeyDown={() => {
          selectReconciliationAccountHandler(id);
        }}
      >
        <Typography
          sx={{
            whiteSpace: 'normal',
          }}
        >
          {name}
        </Typography>
      </div>
    </Tooltip>
  );
}

function Accounts({
  accounts,
  selectedAccount,
  selectReconciliationAccountHandler,
  title,
}: {
  accounts: ReconciliationAccountSlim[];
  selectedAccount: ReconciliationAccount | null;
  selectReconciliationAccountHandler: (id: string) => void;
  title: string;
}) {
  return (
    <div>
      <CategoryTitle title={title} />
      {accounts.map((account) => (
        <Account
          key={account.id}
          name={account.name}
          id={account.id}
          selectReconciliationAccountHandler={selectReconciliationAccountHandler}
          isSelected={selectedAccount !== null && selectedAccount.id === account.id}
        />
      ))}
    </div>
  );
}

export interface ExpandableEntityListProps {
  accounts: ReconciliationAccountSlim[];
  drawerOpen: boolean;
  mainListItem: React.ReactNode;
  handleExpandCollapse: (isExpanding: boolean) => void;
  selectReconciliationAccountHandler: (id: string) => void;
  selectedAccount: ReconciliationAccount | null;
  isSelected?: boolean;
}
export function ExpandableEntityList(props: ExpandableEntityListProps) {
  const {
    accounts,
    mainListItem,
    drawerOpen,
    isSelected,
    selectReconciliationAccountHandler,
    selectedAccount,
    handleExpandCollapse,
  } = props;

  const [open, setOpen] = useState<null | boolean>(null);

  useEffect(() => {
    if (open === null) {
      setOpen(true);
    }
  }, []);

  const handleClick = () => {
    if (drawerOpen) {
      const newOpenState = !open;
      handleExpandCollapse(newOpenState);
      setOpen(newOpenState);
    }
  };
  const expandIcon = open ? <ExpandLess /> : <ExpandMore />;
  const bankAccounts = accounts.filter((account) => account.reconciliationType === 'BANK');
  const customerAccounts = accounts.filter((account) => account.reconciliationType === 'CUSTOMER');
  const supplierAccounts = accounts.filter((account) => account.reconciliationType === 'SUPPLIER');
  const singleAccount = accounts.filter(
    (account) => account.reconciliationType === 'SINGLE_ACCOUNT'
  );
  const transactionMatchingAccount = accounts.filter(
    (account) => account.reconciliationType === 'TRANSACTION_MATCHING'
  );

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          justifyContent: 'center',
          background: isSelected ? 'var(--primary-color)' : undefined,
          color: isSelected ? '#fff' : undefined,
          ...ListItemStyles,
          borderStyle: 'ridge',
        }}
      >
        {mainListItem}
        {drawerOpen && expandIcon}
      </ListItemButton>
      {drawerOpen && (
        <Collapse
          in={open || false}
          timeout="auto"
          unmountOnExit
          className="rounded bg-slate-50 mx-1 p-1"
        >
          {accounts.length === 0 && <div className="ml-5 text-xl font-thin">No accounts found</div>}
          {bankAccounts.length > 0 && (
            <Accounts
              accounts={bankAccounts}
              selectReconciliationAccountHandler={selectReconciliationAccountHandler}
              selectedAccount={selectedAccount}
              title="BANKS"
            />
          )}
          {customerAccounts.length > 0 && (
            <Accounts
              accounts={customerAccounts}
              selectReconciliationAccountHandler={selectReconciliationAccountHandler}
              selectedAccount={selectedAccount}
              title="CUSTOMERS"
            />
          )}
          {supplierAccounts.length > 0 && (
            <Accounts
              accounts={supplierAccounts}
              selectReconciliationAccountHandler={selectReconciliationAccountHandler}
              selectedAccount={selectedAccount}
              title="SUPPLIERS"
            />
          )}
          {singleAccount.length > 0 && (
            <Accounts
              accounts={singleAccount}
              selectReconciliationAccountHandler={selectReconciliationAccountHandler}
              selectedAccount={selectedAccount}
              title="SINGLE ACCOUNTS"
            />
          )}
          {transactionMatchingAccount.length > 0 && (
            <Accounts
              accounts={transactionMatchingAccount}
              selectReconciliationAccountHandler={selectReconciliationAccountHandler}
              selectedAccount={selectedAccount}
              title="TRANSACTION MATCHING"
            />
          )}
        </Collapse>
      )}
    </>
  );
}
