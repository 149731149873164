import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import reconcilioLogoSmallWhite from '../../assets/reconcilio-small-white.svg';
import muiTheme from '../../styles/MuiTheme';
import { userInfo } from '../../mockData/user';
import { Workspace } from '../../models/Resources';

import './AppBar.css';
import { State } from '../../types';
import { WorkspaceFetchError } from '../../infra/repositories/resources';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
  className?: string;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface IReconcilioDrawer {
  className?: string;
  open: boolean;
  pageName?: string;
  toggleDrawer: () => void;
  selectedWorkspace: State<Workspace | null, WorkspaceFetchError>;
  workspaces: State<Workspace[], WorkspaceFetchError>;
  selectWorkspace: (workspaceId: string) => void;
  showCreateWorkspaceDialog: () => void;
  logout: () => void;
  onUserProfileClick: () => void;
}

const AppBarComponent: React.FC<IReconcilioDrawer> = (props: IReconcilioDrawer) => {
  const {
    open,
    pageName,
    toggleDrawer,
    selectedWorkspace,
    workspaces,
    selectWorkspace,
    showCreateWorkspaceDialog,
    logout,
    onUserProfileClick,
    className,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openUserMenu = Boolean(anchorEl);

  const handleUserAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            paddingLeft: '2px',
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <img src={reconcilioLogoSmallWhite} alt="Logo" width={40} height={39} />
          {/* <MenuIcon /> */}
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          // color="primary"
          noWrap
          sx={{ flexGrow: 1, color: 'white' }}
        >
          {pageName}
        </Typography>

        {selectedWorkspace && (
          <FormControl
            sx={{
              backgroundColor: muiTheme.palette.grey[100],
              borderRadius: '4px',
            }}
          >
            <InputLabel id="workspace-select--input-label" />
            {selectedWorkspace.data && (
              <Select
                labelId="workspace-select-label"
                id="workspace-simple-select"
                sx={{
                  width: 200,
                }}
                displayEmpty={false}
                value={selectedWorkspace.data.id}
                label=""
                onChange={(e) => {
                  const workspaceId = e.target.value;
                  if (workspaceId === 'create-workspace') {
                    console.log('creating new workspace');
                    showCreateWorkspaceDialog();
                  } else {
                    console.log('selecting new workspace', workspaceId);
                    selectWorkspace(workspaceId);
                  }
                }}
              >
                {workspaces.data &&
                  workspaces.data.map((workspace) => (
                    <MenuItem
                      key={workspace.id}
                      value={workspace.id}
                      sx={{
                        backgroundColor: muiTheme.palette.grey[100],
                        borderRadius: '4px',
                      }}
                    >
                      {workspace.name}
                    </MenuItem>
                  ))}
                <MenuItem
                  key="create-workspace"
                  value="create-workspace"
                  sx={{
                    backgroundColor: muiTheme.palette.grey[100],
                    borderRadius: '4px',
                  }}
                >
                  + Create Workspace
                </MenuItem>
              </Select>
            )}
          </FormControl>
        )}
        <IconButton color="inherit" onClick={handleUserAvatarClick}>
          <div className={className}>
            <Avatar alt={userInfo.name} src={userInfo.avatar} />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openUserMenu}
              onClose={handleUserMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleUserMenuClose();
                  onUserProfileClick();
                }}
              >
                Profile
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleUserMenuClose();
                  logout();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
