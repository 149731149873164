import { Year } from './Styles';

interface ColumnProps {
  content: string;
  fontSize?: string;
}

export default function YearColumn(props: ColumnProps): JSX.Element {
  const { content, fontSize } = props;
  return (
    <Year
      sx={{
        width: {
          xs: '30px',
          lg: '60px',
        },
        fontSize: fontSize || '18px',
      }}
    >
      {content}
    </Year>
  );
}
