import { observer } from 'mobx-react-lite';
import FilterPopUpDialog from './component';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import BankDate from './BankDate';

const BankFilterPopUpController = () => {
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <FilterPopUpDialog
      open={reconciliationReportViewModel.isBankFilterPopUpDialogVisible}
      searchFilter={reconciliationReportViewModel.searchBankFilterOptions}
      fromAmountFilter={reconciliationReportViewModel.fromAmountBankFilterOptions}
      toAmountFilter={reconciliationReportViewModel.toAmountBankFilterOptions}
      setFromAmountFilter={reconciliationReportViewModel.setFromAmountBankFilterOptions}
      setToAmountFilter={reconciliationReportViewModel.setToAmountBankFilterOptions}
      closeDialog={reconciliationReportViewModel.hideBankFilterPopUpDialog}
      setSearchFilter={reconciliationReportViewModel.setSearchBankFilterOptions}
      areRequiredFieldsSet={reconciliationReportViewModel.areBankFilterOptionsSet}
      submit={reconciliationReportViewModel.applyBankFilterOptions}
      clear={reconciliationReportViewModel.clearBankFilterOptions}
      date={<BankDate />}
    />
  );
};

export default observer(BankFilterPopUpController);
