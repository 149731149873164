import { Choose, Header } from '../Styles';
import { STATUS_STATES } from '../../../types';
import SimpleButton from '../../Buttons/SimpleButton';
import UploadFileInDifferentTemplateBlockButton from '../../CreateReconciliationReport/UploadStatements/ChooseStatements/UploadFile/UploadFileInDifferentTemplateBlockButton';
import { UploadAndButton, Statement } from './Styles';

interface PdfToExcelProps {
  uploadFileStatus: STATUS_STATES;
  uploadFile: (file: File) => void;
  convertDocument: () => void;
  setUploadFileStatus: (value: STATUS_STATES) => void;
}

function PdfToExcel(props: PdfToExcelProps): JSX.Element {
  const { uploadFileStatus, uploadFile, convertDocument, setUploadFileStatus } = props;

  return (
    <Statement>
      <Header>PDF to Excel</Header>
      <UploadAndButton>
        <Choose>
          <UploadFileInDifferentTemplateBlockButton
            status={uploadFileStatus}
            disableUpload={false}
            uploadFile={uploadFile}
            setStatus={setUploadFileStatus}
          />
        </Choose>
        <SimpleButton content="Convert" onClick={convertDocument} />
      </UploadAndButton>
    </Statement>
  );
}

export default PdfToExcel;
