import React from 'react';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import VisibleSuggestedMatches from './VisibleSuggestedMatches/VisibleSuggestedMatches';
import PaginationButtons from './PaginationButtons/PaginationButtons';
import { SuggestedMatchesProps } from '../../types';

export default function SuggestedMatches(props: SuggestedMatchesProps) {
  const {
    reconciliationReportId,
    suggestedMatches,
    timeout,
    externalDocumentType,
    ledgerDocumentType,
    acceptSuggestedMatch,
    rejectSuggestedMatch,
    acceptSuggestions,
    rejectSuggestions,
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          lg: 'var(--page-max-width)',
        },
        paddingLeft: {
          xs: '20px',
          lg: '0px',
        },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={suggestedMatches.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // ActionsComponent={TablePaginationActions}
      />
      <VisibleSuggestedMatches
        reconciliationReportId={reconciliationReportId}
        rowsPerPage={rowsPerPage}
        page={page}
        suggestedMatches={suggestedMatches}
        timeout={timeout}
        externalDocumentType={externalDocumentType}
        ledgerDocumentType={ledgerDocumentType}
        acceptSuggestedMatch={acceptSuggestedMatch}
        rejectSuggestedMatch={rejectSuggestedMatch}
        acceptSuggestions={acceptSuggestions}
        rejectSuggestions={rejectSuggestions}
      />
      <PaginationButtons
        page={page}
        onPageChange={handleChangePage}
        count={suggestedMatches.length}
        rowsPerPage={rowsPerPage}
      />
    </Box>
  );
}

// interface TablePaginationActionsProps {
//   count: number;
//   page: number;
//   rowsPerPage: number;
//   onPageChange: (
//     event: React.MouseEvent<HTMLButtonElement>,
//     newPage: number
//   ) => void;
// }

// function TablePaginationActions(props: TablePaginationActionsProps) {
//   return <Box sx={{ flexShrink: 0, ml: 2.5 }}></Box>;
// }
