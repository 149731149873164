import React, { JSX } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

import Footer from '../../components/Footer';
import AppBar from '../../components/AppBar';
import Drawer from '../../components/Drawer';
import NewWorkspaceDialog from '../../components/CreateDialog/NewWorkspaceDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import NewEntityDialog from '../../components/CreateDialog/NewEntityDialog';
import NewReconciliationAccountDialog from '../../components/CreateDialog/NewReconciliationAccountDialog';
import ErrorDialog from '../../components/ErrorDialog';

interface IDashboardLayout {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  pageName: string | undefined;
  // hasWorkspaces: boolean;
  // isCreateWorkspaceDialogVisible: boolean;
  children: React.ReactNode;
}

function DashboardLayout(props: IDashboardLayout): JSX.Element {
  const { open, setOpen, pageName, children } = props;
  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const openDrawer = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ConfirmationDialog />
      <ErrorDialog />
      <NewEntityDialog />
      <NewReconciliationAccountDialog />
      <AppBar open={open} toggleDrawer={toggleDrawer} pageName={pageName} />
      <Drawer open={open} openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          // backgroundColor:
          //   // (theme) =>
          //   theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          marginBottom: '70px',
        }}
      >
        <Toolbar />
        <Container
          maxWidth="lg"
          sx={{
            mt: 4,
            mb: 4,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <>
              <NewWorkspaceDialog />
              {children}
            </>
          </Grid>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
