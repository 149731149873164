import React from 'react';
import Error from '../../components/Error/Error';
import { UserProfile } from '../../components/UserProfile';
import Loader from '../../components/Loader/Loader';
import { EditUserParams } from '../../models/User';

interface UserPageProps {
  name: string;
  telephone: string;
  companyName: string;
  industry: string;
  countries: string[];
  country: string;
  email: string;
  message: { type: 'error' | 'success'; message: string } | null;
  updateParams: EditUserParams;
  updateEmail: (email: string) => void;
  updateName: (name: string) => void;
  updateTelephone: (telephone: string) => void;
  updateCompanyName: (companyName: string) => void;
  updateIndustry: (industry: string) => void;
  updateCountry: (value: string) => void;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  updateUser: (updateParams: Partial<EditUserParams>) => void;
}

function UserPage(props: UserPageProps): JSX.Element {
  const {
    name,
    telephone,
    companyName,
    industry,
    countries,
    country,
    email,
    message,
    updateEmail,
    updateName,
    updateTelephone,
    updateCompanyName,
    updateIndustry,
    updateCountry,
    status,
    errorMessage,
    updateUser,
    updateParams,
  } = props;

  return (
    <>
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && (
        <UserProfile
          name={name}
          telephone={telephone}
          companyName={companyName}
          industry={industry}
          countries={countries}
          country={country}
          email={email}
          message={message}
          updateEmail={updateEmail}
          updateParams={updateParams}
          updateName={updateName}
          updateTelephone={updateTelephone}
          updateCompanyName={updateCompanyName}
          updateIndustry={updateIndustry}
          updateCountry={updateCountry}
          updateUser={updateUser}
        />
      )}
    </>
  );
}
export default UserPage;
