import { styled } from '@mui/material/styles';

export const List = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%',
});

export const ListItemStyles = {
  borderStyle: 'solid',
  borderWidth: 'thin',
  borderRadius: '10px',
  margin: '3px',
};
