import React, { JSX } from 'react';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import PageDetails from '../../components/PageDetails/Details';
import EntityView from '../../components/Entity/Entity';
import SimpleButton from '../../components/Buttons/SimpleButton';
import NewReconciliationAccountDialog from '../../components/CreateDialog/NewReconciliationAccountDialog';
import { EditEntityParams, Entity } from '../../models/Resources';
import { User, UserRoleType } from '../../models/User';
import ConfirmationDialog from '../../components/ConfirmationDialog';

interface EntityPageProps {
  currentEntity: Entity | null;
  countries: string[];
  languages: string[];
  users: User[];
  userAvailableRoles: UserRoleType[];
  updateParams: EditEntityParams;
  updateEntityName: (name: string) => void;
  updateCountry: (country: string) => void;
  updateLanguage: (language: string) => void;
  updateEntity: (updateParams: Partial<EditEntityParams>) => void;
  deleteEntity: () => Promise<void>;
  showCreateReconciliationAccountDialog: () => void;
  createUserWithEmailAndRoleId: (email: string, roleId: string) => Promise<void>;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  fetchUsersDetails: () => void;
  showConfirmationDialog: (
    message: string,
    buttonMessage: string,
    confirmationAction: () => void
  ) => void;
}

export default function EntityPage(props: EntityPageProps): JSX.Element {
  const {
    currentEntity,
    countries,
    languages,
    users,
    updateParams,
    updateEntity,
    updateCountry,
    updateLanguage,
    updateEntityName,
    showCreateReconciliationAccountDialog,
    status,
    errorMessage,
    deleteEntity,
    userAvailableRoles,
    createUserWithEmailAndRoleId,
    fetchUsersDetails,
    // hideConfirmationDialog,
    showConfirmationDialog,
  } = props;
  return (
    <>
      {/* <ConfirmationDialog
        action={deleteEntity}
        hideConfirmationDialog={hideConfirmationDialog}
        confirmationMessage="Are you sure you want to delete this entity?"
        confirmationButtonMessage="Delete"
      /> */}
      <PageDetails
        header={currentEntity?.name ?? 'Entity'}
        action={
          status === 'success' ? (
            <SimpleButton
              content="Create Reconciliation Account"
              onClick={showCreateReconciliationAccountDialog}
            />
          ) : undefined
        }
        // <SimpleButton icon={deleteIcon} content="Delete Entity" /> : undefined}
      />
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && currentEntity && (
        <EntityView
          countries={countries}
          languages={languages}
          users={users}
          updateCountry={updateCountry}
          updateLanguage={updateLanguage}
          updateEntityName={updateEntityName}
          updateParams={updateParams}
          updateEntity={updateEntity}
          deleteEntity={deleteEntity}
          userAvailableRoles={userAvailableRoles}
          createUserWithEmailAndRoleId={createUserWithEmailAndRoleId}
          fetchUsersDetails={fetchUsersDetails}
          showConfirmationDialog={showConfirmationDialog}
        />
      )}
      {status === 'success' && !currentEntity && <Loader />}
    </>
  );
}
