import { Actions } from './Styles';

interface ColumnProps {
  content: JSX.Element | string;
}

export default function ActionsColumn(props: ColumnProps): JSX.Element {
  const { content } = props;
  return <Actions sx={{ width: '82px' }}>{content}</Actions>;
}
