import BankTransactionBlock from '../Transaction/BankTransaction/BankTransaction';
import LedgerTransactionBlock from '../Transaction/LedgerTransaction/LedgerTransaction';
import { Transactions, BankTransactions, LedgerTransactions } from './Styles';
import { MatchProps } from '../../types';

function Match(props: MatchProps): JSX.Element {
  const { bankTransactions, ledgerTransactions } = props;
  return (
    <Transactions
      sx={{
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
      }}
    >
      <BankTransactions>
        {bankTransactions.map((transaction, index) => (
          <BankTransactionBlock
            date={transaction.date}
            description={transaction.description}
            reference={transaction.reference}
            debit={transaction.debit}
            credit={transaction.credit}
            key={index}
          />
        ))}
      </BankTransactions>
      <LedgerTransactions>
        {ledgerTransactions.map((transaction, index) => (
          <LedgerTransactionBlock
            date={transaction.date}
            description={transaction.description}
            reference={transaction.reference}
            debit={transaction.debit}
            credit={transaction.credit}
            key={index}
          />
        ))}
      </LedgerTransactions>
    </Transactions>
  );
}

export default Match;
