import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import DrawerComponent from './component';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';
import { useEntityViewModel } from '../../view-models/EntityViewModel';
import { useReconciliationAccountViewModel } from '../../view-models/ReconciliationAccountViewModel';
import { ROUTE_PATHS } from '../../routes';

interface IDrawerController {
  open: boolean;
  openDrawer: () => void;
  toggleDrawer: () => void;
}

export const DrawerController: React.FC<IDrawerController> = (props: IDrawerController) => {
  const workspaceViewModel = useWorkspacesViewModel();
  const entityViewModel = useEntityViewModel();
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  const { open, openDrawer, toggleDrawer } = props;

  const navigate = useNavigate();

  const navigateToWorkspace = () => {
    const workspaceId = workspaceViewModel.selectedWorkspace!.data!.id;
    navigate(`/workspace/${workspaceId}`);
  };

  const navigateToEntity = (entityId: string) => {
    const workspaceId = workspaceViewModel.selectedWorkspace!.data!.id;

    navigate(`/workspace/${workspaceId}/entity/${entityId}`);
  };

  const navigateToConvertersAndTemplates = () => {
    navigate(ROUTE_PATHS.CONVERTERS_AND_TEMPLATES);
  };

  const navigateToReconciliationAccount = (accountId: string) => {
    const workspaceId = workspaceViewModel.selectedWorkspace!.data!.id;
    const entityId = entityViewModel.entity!.data!.id;

    navigate(`/workspace/${workspaceId}/entity/${entityId}/reconciliation-account/${accountId}`);
  };

  const goHome = () => {
    navigate('/');
  };

  return workspaceViewModel.selectedWorkspace ? (
    <DrawerComponent
      goHome={goHome}
      entities={entityViewModel.entities.data || []}
      currentEntity={entityViewModel.entity.data}
      currentEntityStatus={entityViewModel.entity!.status}
      currentReconciliationAccount={reconciliationAccountViewModel.reconciliationAccount.data}
      currentReconciliationAccountsType={reconciliationAccountViewModel.reconciliationAccountsType}
      selectReconciliationAccountsType={
        reconciliationAccountViewModel.selectReconciliationAccountsType
      }
      selectReconciliationAccount={reconciliationAccountViewModel.selectReconciliationAccount}
      selectEntity={entityViewModel.selectEntity}
      open={open}
      openDrawer={openDrawer}
      toggleDrawer={toggleDrawer}
      reconciliationAccountsList={
        reconciliationAccountViewModel.reconciliationAccountsListOrderedByName
      }
      navigateToEntity={navigateToEntity}
      navigateToWorkspace={navigateToWorkspace}
      navigateToReconciliationAccount={navigateToReconciliationAccount}
      showCreateEntityDialog={entityViewModel.showCreateEntityDialog}
      navigateToConvertersAndTemplates={navigateToConvertersAndTemplates}
    />
  ) : null;
};

const Drawer = observer(DrawerController);

export default Drawer;
