import { Header, Title, Amount } from './Styles';

interface SubheaderProps {
  title?: string;
  amount: string;
  color?: string;
  small?: boolean;
}

function Subheader(props: SubheaderProps): JSX.Element {
  const { title, amount, color, small } = props;
  return (
    <Header
      style={{
        color: color || 'var(--black-color)',
        fontSize: small ? '20px' : '24px',
        marginTop: small ? '20px' : '15px',
      }}
    >
      {title && <Title>{title}</Title>}
      <Amount>{amount}</Amount>
    </Header>
  );
}
export default Subheader;
