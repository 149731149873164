import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CONCRETE_TYPES } from '../../../models/Document';
import { DOCUMENT_TYPE } from '../../../models/UploadFileData';
import { Choose, Header, Statement } from '../Styles';
import { CUSTOM_OPTIONS } from '../../../view-models/ConvertersAndTemplatesViewModel';
import { STATUS_STATES } from '../../../types';
import SimpleButton from '../../Buttons/SimpleButton';
import UploadFileBlockButton from '../../CreateReconciliationReport/UploadStatements/ChooseStatements/UploadFile/UploadFileBlockButton';

interface ConvertCustomFormatProps {
  uploadFileStatus: STATUS_STATES;
  customOptions: CUSTOM_OPTIONS[];
  selectedCustom: CUSTOM_OPTIONS;
  uploadFile: (file: File) => void;
  convertDocument: () => void;
  setSelectedCustom: (value: CUSTOM_OPTIONS) => void;
  setUploadFileStatus: (value: STATUS_STATES) => void;
}

function ConvertCustomFormat(props: ConvertCustomFormatProps): JSX.Element {
  const {
    customOptions,
    selectedCustom,
    uploadFileStatus,
    uploadFile,
    convertDocument,
    setSelectedCustom,
    setUploadFileStatus,
  } = props;

  return (
    <Statement>
      <Header>Custom Format</Header>

      <Autocomplete
        options={customOptions}
        sx={{ width: 200 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} />}
        value={selectedCustom}
        onChange={(e, value) => {
          setSelectedCustom(value || CUSTOM_OPTIONS.NONE);
        }}
      />

      <Choose>
        <UploadFileBlockButton
          disableUpload={false}
          uploadFile={uploadFile}
          type={DOCUMENT_TYPE.LEDGER}
          concreteType={CONCRETE_TYPES.LEDGER}
          status={uploadFileStatus}
          setStatus={setUploadFileStatus}
        />
      </Choose>
      <SimpleButton content="Convert" onClick={convertDocument} />
    </Statement>
  );
}

export default ConvertCustomFormat;
