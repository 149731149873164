import SuggestedMatches from '../../components/SuggestedMatches/SuggestedMatches';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import PageDetails from '../../components/PageDetails/Details';
import { SuggestedMatchesPageProps } from '../../types';

export function SuggestedMatchesPage(props: SuggestedMatchesPageProps): JSX.Element {
  const {
    currentEntity,
    currentReconciliationAccount,
    currentReconciliationReport,
    suggestedMatches,
    status,
    errorMessage,
    timeout,
    externalDocumentType,
    ledgerDocumentType,
    acceptSuggestedMatch,
    rejectSuggestedMatch,
    acceptSuggestions,
    rejectSuggestions,
  } = props;

  return (
    <>
      <PageDetails
        header={`${currentEntity?.name} / ${currentReconciliationAccount?.name}`}
        subheader={currentReconciliationReport}
      />
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && (
        <SuggestedMatches
          reconciliationReportId={currentReconciliationReport}
          suggestedMatches={suggestedMatches}
          timeout={timeout}
          externalDocumentType={externalDocumentType}
          ledgerDocumentType={ledgerDocumentType}
          acceptSuggestedMatch={acceptSuggestedMatch}
          rejectSuggestedMatch={rejectSuggestedMatch}
          acceptSuggestions={acceptSuggestions}
          rejectSuggestions={rejectSuggestions}
        />
      )}
    </>
  );
}
