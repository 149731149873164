import React from 'react';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import PageDetails from '../../components/PageDetails/Details';
import { Entity, ReconciliationAccount } from '../../models/Resources';
import { ManualTransaction, SelectedTransactions } from '../../models/ManualReconciliation';
import FixedBottomButton from '../../components/Buttons/FixedBottomButton';
import ManualReconciliation from '../../components/ManualReconciliation/ManualReconciliation';
import NewLedgerTransaction from '../../components/CreateDialog/NewLedgerTransactionDialog';
import EditLedgerTransaction from '../../components/CreateDialog/EditLedgerTransactionDialog';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../models/Document';
import SimpleButton from '../../components/Buttons/SimpleButton';

interface ManualReconciliationPageProps {
  amountDifferenceLabel: string;
  currentEntity: Entity | null;
  currentReconciliationAccount: ReconciliationAccount | null;
  currentReconciliationReportName: string;
  bankUnreconciledTransactions: ManualTransaction[];
  ledgerUnreconciledTransactions: ManualTransaction[];
  selectedTransactions: SelectedTransactions;
  matchTransactionsStatus: 'idle' | 'reconcilable' | 'unreconcilable';
  amountDifference?: string;
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
  isBankDateAscending: boolean;
  isBankCreditAscending: boolean;
  isBankDebitAscending: boolean;
  isLedgerDateAscending: boolean;
  isLedgerCreditAscending: boolean;
  isLedgerDebitAscending: boolean;
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
  bankQuickFilter: string;
  ledgerQuickFilter: string;
  reconcile: () => void;
  cancelManualReconciliation: () => void;
  selectOrUnselectBankTransaction: (bankTransaction: ManualTransaction) => void;
  selectOrUnselectLedgerTransaction: (ledgerTransaction: ManualTransaction) => void;
  navigateToViewlReconciliationReport: () => void;
  showNewLedgerTransactionDialog: () => void;
  showEditLedgerTransactionDialog: (ledgerTransaction: ManualTransaction) => void;
  toggleBankDateDropdown: () => void;
  toggleBankCreditDropdown: () => void;
  toggleBankDebitDropdown: () => void;
  toggleLedgerDateDropdown: () => void;
  toggleLedgerCreditDropdown: () => void;
  toggleLedgerDebitDropdown: () => void;
  generateCorrespondingTransactions: () => void;
  changeBankQuickFilter: (value: string) => void;
  changeLedgerQuickFilter: (value: string) => void;
  showBankFilterPopUpDialog: () => void;
  showLedgerFilterPopUpDialog: () => void;
  selectFilteredBankUnreconciledManualTransactions: () => void;
  selectFilteredLedgerUnreconciledManualTransactions: () => void;
  removeSelectedBankUnreconciledManualTransactions: () => void;
  removeSelectedLedgerUnreconciledManualTransactions: () => void;
}

export default function ManualReconciliationPage(
  props: ManualReconciliationPageProps
): JSX.Element {
  const {
    amountDifferenceLabel,
    currentEntity,
    currentReconciliationAccount,
    currentReconciliationReportName,
    bankUnreconciledTransactions,
    ledgerUnreconciledTransactions,
    selectedTransactions,
    status,
    matchTransactionsStatus,
    amountDifference,
    errorMessage,
    isBankDateAscending,
    isBankCreditAscending,
    isBankDebitAscending,
    isLedgerDateAscending,
    isLedgerCreditAscending,
    isLedgerDebitAscending,
    externalDocumentType,
    ledgerDocumentType,
    bankQuickFilter,
    ledgerQuickFilter,
    toggleLedgerDateDropdown,
    toggleLedgerCreditDropdown,
    toggleLedgerDebitDropdown,
    toggleBankDateDropdown,
    toggleBankCreditDropdown,
    toggleBankDebitDropdown,
    selectOrUnselectBankTransaction,
    selectOrUnselectLedgerTransaction,
    reconcile,
    cancelManualReconciliation,
    navigateToViewlReconciliationReport,
    showNewLedgerTransactionDialog,
    showEditLedgerTransactionDialog,
    generateCorrespondingTransactions,
    changeBankQuickFilter,
    changeLedgerQuickFilter,
    showBankFilterPopUpDialog,
    showLedgerFilterPopUpDialog,
    selectFilteredBankUnreconciledManualTransactions,
    selectFilteredLedgerUnreconciledManualTransactions,
    removeSelectedBankUnreconciledManualTransactions,
    removeSelectedLedgerUnreconciledManualTransactions,
  } = props;

  return (
    <>
      <PageDetails
        header={`${currentEntity?.name} / ${currentReconciliationAccount?.name}`}
        subheader={currentReconciliationReportName}
        action={
          status === 'success' ? (
            <SimpleButton
              content="View Reconciliation Report"
              onClick={navigateToViewlReconciliationReport}
            />
          ) : undefined
        }
      />
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && (
        <>
          <NewLedgerTransaction />
          {/* <EditLedgerTransaction /> */}
          <ManualReconciliation
            amountDifferenceLabel={amountDifferenceLabel}
            selectedTransactions={selectedTransactions}
            bankTransactions={bankUnreconciledTransactions}
            ledgerTransactions={ledgerUnreconciledTransactions}
            reconciliationReportId={currentReconciliationReportName}
            matchTransactionsStatus={matchTransactionsStatus}
            amountDifference={amountDifference}
            externalDocumentType={externalDocumentType}
            ledgerDocumentType={ledgerDocumentType}
            toggleBankDateDropdown={toggleBankDateDropdown}
            isBankDateAscending={isBankDateAscending}
            toggleBankCreditDropdown={toggleBankCreditDropdown}
            isBankCreditAscending={isBankCreditAscending}
            toggleBankDebitDropdown={toggleBankDebitDropdown}
            isBankDebitAscending={isBankDebitAscending}
            toggleLedgerDateDropdown={toggleLedgerDateDropdown}
            isLedgerDateAscending={isLedgerDateAscending}
            toggleLedgerCreditDropdown={toggleLedgerCreditDropdown}
            isLedgerCreditAscending={isLedgerCreditAscending}
            toggleLedgerDebitDropdown={toggleLedgerDebitDropdown}
            isLedgerDebitAscending={isLedgerDebitAscending}
            selectOrUnselectBankTransaction={selectOrUnselectBankTransaction}
            selectOrUnselectLedgerTransaction={selectOrUnselectLedgerTransaction}
            reconcile={reconcile}
            cancelManualReconciliation={cancelManualReconciliation}
            showNewLedgerTransactionDialog={showNewLedgerTransactionDialog}
            showEditLedgerTransactionDialog={showEditLedgerTransactionDialog}
            generateCorrespondingTransactions={generateCorrespondingTransactions}
            changeBankQuickFilter={changeBankQuickFilter}
            changeLedgerQuickFilter={changeLedgerQuickFilter}
            showBankFilterPopUpDialog={showBankFilterPopUpDialog}
            showLedgerFilterPopUpDialog={showLedgerFilterPopUpDialog}
            bankQuickFilter={bankQuickFilter}
            ledgerQuickFilter={ledgerQuickFilter}
            selectFilteredBankUnreconciledManualTransactions={
              selectFilteredBankUnreconciledManualTransactions
            }
            selectFilteredLedgerUnreconciledManualTransactions={
              selectFilteredLedgerUnreconciledManualTransactions
            }
            removeSelectedBankUnreconciledManualTransactions={
              removeSelectedBankUnreconciledManualTransactions
            }
            removeSelectedLedgerUnreconciledManualTransactions={
              removeSelectedLedgerUnreconciledManualTransactions
            }
          />

          {/* <Workspace
            workspaceName={workspaceName}
            countries={countries}
            languages={languages}
            selectedCountry={selectedCountry}
            selectedLanguage={selectedLanguage}
            users={users}
            updateCountry={updateCountry}
            updateLanguage={updateLanguage}
            updateWorkspaceName={updateWorkspaceName}
          /> */}
        </>
      )}
    </>
  );
}
