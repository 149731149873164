import { styled } from '@mui/material/styles';

export const Block: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  boxSizing: 'border-box',
  overflow: 'visible',
  height: 'auto',
  zIndex: 1,
});

export const Title: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(182, 182, 182, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});

export const Input: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  width: '215px',
});
