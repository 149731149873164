import React, { useEffect } from 'react';
import { Outlet, useNavigate, useRoutes } from 'react-router-dom';

import AuthLayout from '../layouts/Auth/Auth';
import DashboardLayout from '../layouts/dashboard';
import LoginPage from '../pages/Login';
import RegisterPage from '../pages/Register';
import HomePage from '../pages/Home';
import CreateReconciliationReport from '../pages/CreateReconciliationReport';
import ReconciliationReport from '../pages/ReconciliationReport';
import SuggestedMatches from '../pages/SuggestedMatches';
import Workspace from '../pages/Workspace';
import Entity from '../pages/Entity';
import User from '../pages/User';
import ReconciliationAccount from '../pages/ReconciliationAccount';
import ManualReconciliation from '../pages/ManualReconciliation';
import NotFoundPage from '../pages/NotFound';
import ProtectedRoute from './protected-route';
import { ConvertersAndTemplatesController } from '../pages/ConvertersAndTemplates/controller';

export enum ROUTE_PATHS {
  CONVERTERS_AND_TEMPLATES = '/converters-and-templates',
}

const Routes: React.FC = () => {
  const isAuthenticated = true; // Todo Fetch this from your auth service
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const element = useRoutes([
    {
      path: 'login',
      element: <AuthLayout />,
      children: [{ path: '/login', element: <LoginPage /> }],
    },
    {
      path: 'register',
      element: <AuthLayout />,
      children: [{ path: '/register', element: <RegisterPage /> }],
    },
    {
      path: '/',
      element: isAuthenticated ? (
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      ) : null,
      children: [
        {
          path: '/',
          element: <ProtectedRoute element={<HomePage />} />,
        },
        {
          path: '/dashboard',
          element: <ProtectedRoute element={<HomePage />} />,
        },
        {
          path: '/create-reconciliation-report',
          element: <ProtectedRoute element={<CreateReconciliationReport />} />,
        },
        {
          path: '/reconciliation-report/:id',
          element: <ProtectedRoute element={<ReconciliationReport />} />,
        },
        {
          path: '/reconciliation-report/:id/manual-reconciliation',
          element: <ProtectedRoute element={<ManualReconciliation />} />,
        },
        {
          path: '/reconciliation-report/:id/suggested-matches',
          element: <ProtectedRoute element={<SuggestedMatches />} />,
        },
        {
          path: '/workspace/:id',
          element: <ProtectedRoute element={<Workspace />} />,
        },
        {
          path: '/user/:id',
          element: <ProtectedRoute element={<User />} />,
        },
        {
          path: '/workspace/:workspaceId/entity/:entityId',
          element: <ProtectedRoute element={<Entity />} />,
        },
        {
          path: '/workspace/:workspaceId/entity/:entityId/reconciliation-account/:reconciliationAccountId',
          element: <ProtectedRoute element={<ReconciliationAccount />} />,
        },
        {
          path: ROUTE_PATHS.CONVERTERS_AND_TEMPLATES,
          element: <ProtectedRoute element={<ConvertersAndTemplatesController />} />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  return element;
};

// {
/* <Routes>
  <Route
    path="/"
    element={
      <Dashboard>
        <HomePage />
      </Dashboard>
    }
  />
  <Route
    path="suggested-matches"
    element={
      <Dashboard>
        <SuggestedMatchesPage suggestedMatches={suggestedMatches} />
      </Dashboard>
    }
  />
  {/* <Route
    path="reconciliation-report"
    element={
      <ReconciliationReportPage
        unreconciledTransactionsBlocks={unreconciledTransactionsBlocks}
      />
    }
  /> */
// }
// <Route
//   path="create-reconciliation-report"
//   element={
//     <Dashboard>
//       <CreateReconciliationReportPage
//         entities={entities}
//         currentWorkspace={currentWorkspace}
//         reconciliationAccounts={reconciliationAccounts}
//         reconciliationReports={reconciliationReports}
//       />
//     </Dashboard>
//   }
// />
// </Routes>

export default Routes;
