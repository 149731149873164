import React, { JSX } from 'react';
import { Tooltip } from '@mui/material';
import { Balance, Bank, Ledger } from './Styles';

interface ColumnProps {
  content: string;
  type?: 'header' | 'body';
  bank?: boolean;
  ledger?: boolean;
  helperText?: string;
  fontSize?: string;
}

export default function BalanceColumn(props: ColumnProps): JSX.Element {
  const { type, content, bank, helperText, fontSize, ledger } = props;
  return (
    <Balance
      sx={{
        width: {
          xs: '80px',
          lg: '100px',
        },
        fontSize: fontSize || '18px',
      }}
    >
      {type === 'header' ? (
        <Tooltip title={helperText}>
          {bank ? <Bank>{content}</Bank> : <Ledger>{content}</Ledger>}
        </Tooltip>
      ) : (
        content
      )}
    </Balance>
  );
}
