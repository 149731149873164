import { styled } from '@mui/material/styles';

export const ButtonsLayout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '20px 0px 0px 0px',
});
