import { ManualTransaction } from '../models/ManualReconciliation';

export default class ManualTransactionHelper {
  public static isCredit(transaction: ManualTransaction): boolean {
    return transaction.creditNumber !== 0;
  }

  public static isDebit(transaction: ManualTransaction): boolean {
    return transaction.debitNumber !== 0;
  }
}
