import { observer } from 'mobx-react-lite';
import NewLedgerTransactionDialog from './component';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';

function NewLedgerTransactionController() {
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <NewLedgerTransactionDialog
      open={reconciliationReportViewModel.isNewLedgerTransactionDialogVisible}
      ledgerTransactionCreationStatus={
        reconciliationReportViewModel.ledgerTransactionCreationStatus
      }
      closeDialog={reconciliationReportViewModel.hideNewLedgerTransactionDialog}
      submitAction={reconciliationReportViewModel.createLedgerTransaction}
      areRequiredFieldsSet={reconciliationReportViewModel.areLedgerTransactionRequiredFieldsSet}
      transactionTypes={reconciliationReportViewModel.ledgerTransactionTypes}
      transactionType={reconciliationReportViewModel.ledgerTransactionType}
      setTransactionType={reconciliationReportViewModel.setLedgerTransactionType}
      date={reconciliationReportViewModel.ledgerTransactionDate}
      setDate={reconciliationReportViewModel.setLedgerTransactionDate}
      description={reconciliationReportViewModel.ledgerTransactionDescription}
      setDescription={reconciliationReportViewModel.setLedgerTransactionDescription}
      reference={reconciliationReportViewModel.ledgerTransactionReference}
      setReference={reconciliationReportViewModel.setLedgerTransactionReference}
      financialTransactionId={reconciliationReportViewModel.ledgerTransactionFinancialTransactionId}
      setFinancialTransactionId={
        reconciliationReportViewModel.setLedgerTransactionFinancialTransactionId
      }
      amount={reconciliationReportViewModel.ledgerTransactionAmount}
      setAmount={reconciliationReportViewModel.setLedgerTransactionAmount}
    />
  );
}

const NewLedgerTransaction = observer(NewLedgerTransactionController);

export default NewLedgerTransaction;
