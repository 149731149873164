import React from 'react';
import ReconcileButton from './ReconcileButton';
import CancelButton from './CancelButton';
import { Actions, LeftButtons, RightButtons } from './Styles';
import SimpleButton from '../../../Buttons/SimpleButton';

interface ActionsBlockProps {
  reconciliationReportId: string;
  status: 'idle' | 'reconcilable' | 'unreconcilable';
  reconcile: () => void;
  cancel: () => void;
  removeSelectedBankUnreconciledManualTransactions: () => void;
  removeSelectedLedgerUnreconciledManualTransactions: () => void;
}

function ActionsBlock(props: ActionsBlockProps) {
  const {
    status,
    reconciliationReportId,
    reconcile,
    cancel,
    removeSelectedBankUnreconciledManualTransactions,
    removeSelectedLedgerUnreconciledManualTransactions,
  } = props;

  return status !== 'idle' ? (
    <Actions>
      <LeftButtons>
        <ReconcileButton
          onClick={() => reconcile()}
          disabled={status !== 'reconcilable'}
          reconciliationReportId={reconciliationReportId}
        />
        <SimpleButton
          content="Remove All"
          onClick={removeSelectedBankUnreconciledManualTransactions}
        />
      </LeftButtons>
      <RightButtons>
        <SimpleButton
          content="Remove All"
          onClick={removeSelectedLedgerUnreconciledManualTransactions}
        />
        <CancelButton onClick={() => cancel()} reconciliationReportId={reconciliationReportId} />
      </RightButtons>
    </Actions>
  ) : null;
}
export default ActionsBlock;
