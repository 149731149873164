import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import AppBarComponent from './component';
import { useIamViewModel } from '../../view-models/IamViewModel';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';

interface IAppBarController {
  open: boolean;
  pageName: string | undefined;
  toggleDrawer: () => void;
}

const AppBarController: React.FC<IAppBarController> = (props: IAppBarController) => {
  const { open, pageName, toggleDrawer } = props;
  const iamViewModel = useIamViewModel();
  const workspaceViewModel = useWorkspacesViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (!iamViewModel.isAuthenticated) {
      navigate('/login');
    }
  }, [iamViewModel.isAuthenticated]);

  const onUserProfileClick = () => {
    navigate('/user/:id');
  };

  // useEffect(() => {
  //   if (workspaceViewModel.selectedWorkspace) {
  //     navigate('/dashboard');
  //   }
  // }, [workspaceViewModel.selectedWorkspace?.data?.id]);

  return (
    <AppBarComponent
      open={open}
      pageName={pageName}
      toggleDrawer={toggleDrawer}
      workspaces={workspaceViewModel.workspaces}
      selectedWorkspace={workspaceViewModel.selectedWorkspace}
      selectWorkspace={workspaceViewModel.selectWorkspace}
      showCreateWorkspaceDialog={workspaceViewModel.showCreateWorkspaceDialog}
      logout={iamViewModel.logout}
      onUserProfileClick={onUserProfileClick}
    />
  );
};

const AppBar = observer(AppBarController);

export default AppBar;
