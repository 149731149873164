/* eslint-disable import/prefer-default-export */
import { observer } from 'mobx-react-lite';
import PreviousReconciliationReportDialog from './component';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import { ReconciliationAccountReport } from '../../../models/ReconciliationReport';

const PreviousReconciliationReportController = (props: {
  previousReconciliationReports: ReconciliationAccountReport[];
}) => {
  const reconciliationReportViewModel = useReconciliationReportViewModel();
  const reconciliationReportNames = props.previousReconciliationReports.map((report) => ({
    id: report.id,
    name: report.name,
  }));
  console.log('reconciliationReportNames', props.previousReconciliationReports);
  const content = reconciliationReportViewModel ? (
    <PreviousReconciliationReportDialog
      id={reconciliationReportViewModel.reconciliationReportId || ''}
      previousReconciliationReportNames={reconciliationReportNames}
      setPreviousReconciliationReport={
        reconciliationReportViewModel.setPreviousReconciliationReport
      }
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
  return content;
};

const PreviousReconciliationReport = observer(PreviousReconciliationReportController);

export { PreviousReconciliationReport };
