import { styled } from '@mui/material';

export const Headers: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  // justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px 10px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px',
});

export const SubHeaders: any = styled('div')({
  display: 'flex',
  gap: '10px',
  position: 'relative',
  isolation: 'isolate',
  // flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  marginTop: '10px',
  width: '100%',
});
