import { observer } from 'mobx-react-lite';
import NewReconciliationAccountDialogComponent from './component';
import { useReconciliationAccountViewModel } from '../../../view-models/ReconciliationAccountViewModel';

const NewReconciliationAccountDialogController = () => {
  const reconciliationAccountViewModel = useReconciliationAccountViewModel();

  return (
    <NewReconciliationAccountDialogComponent
      open={reconciliationAccountViewModel.isCreateReconciliationAccountDialogVisible}
      closeDialog={reconciliationAccountViewModel.hideCreateReconciliationAccountDialog}
      submit={reconciliationAccountViewModel.createReconciliationAccount}
      reconciliationAccountName={reconciliationAccountViewModel.name}
      setReconciliationAccountName={reconciliationAccountViewModel.setName}
      areRequiredFieldsSet={reconciliationAccountViewModel.areRequiredFieldsSet}
      counterPartyContactName={reconciliationAccountViewModel.counterpartyContactName}
      setCounterPartyContactName={reconciliationAccountViewModel.setCounterPartyContactName}
      counterPartyContactEmail={reconciliationAccountViewModel.counterpartyContactEmail}
      setCounterPartyContactEmail={reconciliationAccountViewModel.setCounterPartyContactEmail}
      reconciliationType={reconciliationAccountViewModel.reconciliationType}
      setReconciliationType={reconciliationAccountViewModel.setReconciliationType}
      uniqueIdentifier={reconciliationAccountViewModel.uniqueIdentifier}
      setUniqueIdentifier={reconciliationAccountViewModel.setUniqueIdentifier}
      types={reconciliationAccountViewModel.reconciliationAccountTypes}
      isUniqueIdentifierSet={reconciliationAccountViewModel.isUniqueIdentifierSet}
      showUniqueIdentifierErrorMessage={
        reconciliationAccountViewModel.showUniqueIdentifierErrorMessage
      }
    />
  );
};

const NewReconciliationAccountDialog = observer(NewReconciliationAccountDialogController);

export default NewReconciliationAccountDialog;
