import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoginPage from './page';
import { useIamViewModel } from '../../view-models/IamViewModel';

const LoginController: React.FC = () => {
  const iamViewModel = useIamViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (iamViewModel.isAuthenticated) {
      navigate('/');
    }
  }, [iamViewModel.isAuthenticated]);

  const visitSignUpPage = () => {
    // navigate('/register');
    window.location.replace('https://reconcilio.com/#contact-us');
  };

  return (
    <LoginPage
      email={iamViewModel.loginEmail}
      password={iamViewModel.loginPassword}
      visitSignUpPage={visitSignUpPage}
      message={iamViewModel.authMessage}
      submit={iamViewModel.loginWithEmailPassword}
      updateEmail={iamViewModel.updateLoginEmail}
      updatePassword={iamViewModel.updateLoginPassword}
    />
  );
};

const Login = observer(LoginController);

export default Login;
