import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../../CreateDialog/CreateDialog';

interface CreateLedgerTransactionDialogProps {
  open: boolean;
  submitAction: () => void;
  closeDialog: () => void;
}
function CreateLedgerTransactionDialog(props: CreateLedgerTransactionDialogProps) {
  const { open, closeDialog, submitAction } = props;
  return (
    <CreateDialog
      title="Create Compensating Ledger Transaction"
      open={open}
      closeDialog={closeDialog}
      action={<FilledButton content="Create" onClick={submitAction} />}
      content={<> </>}
    />
  );
}

export default CreateLedgerTransactionDialog;
