import PageDetails from '../../components/PageDetails/Details';
// import Header from '../../components/Headers/PageHeader/PageHeader';
import Loader from '../../components/Loader/Loader';
import Error from '../../components/Error/Error';
import UnreconciledTransactions from '../../components/ReconciliationReport/UnreconciledTransactions/UnreconciledTransactions';
import ReconciledTransactions from '../../components/ReconciliationReport/ReconciledTransactions/ReconciledTransactions';
// import SimpleButton from '../../components/Buttons/SimpleButton';
// import editIcon from '../../assets/edit.svg';
import { ReconciliationReportPageProps } from '../../types';
import ReconciliationReportButtons from './reconciliation-report-download/reconciliation-report-buttons';
import FullWidthTabs from '../../components/FullWidthTabs';

export default function ReconciliationReportPage(
  props: ReconciliationReportPageProps
): JSX.Element {
  const {
    currentEntity,
    currentReconciliationAccount,
    currentReconciliationReportName,
    unreconciledTransactionsBlocks,
    reconciledTransactionsBlocks,
    status,
    errorMessage,
    ledgerEndBalance,
    bankClosingBalance,
    reconciliationDifference,
    isReconciliationReportLocked,
    ledgerEndOfPeriodBalanceName,
    externalClosingBalanceName,
    externalDocumentType,
    ledgerDocumentType,
    navigateToManualReconciliation,
    unreconcileMatch,
    handleDownloadXLSXButtonClick,
    showLockReconciliationReportDialog,
    bankStatementTransactionsPercentage,
  } = props;

  return (
    <>
      <PageDetails
        header={`${currentEntity?.name} / ${currentReconciliationAccount?.name}`}
        subheader={currentReconciliationReportName}
      />

      {/* <Header title="Reconciliation Report" /> */}
      {status === 'loading' && <Loader />}
      {status === 'error' &&
        (errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <Error message="Something went wrong." />
        ))}
      {status === 'success' && (
        <>
          <ReconciliationReportButtons
            reconciledTransactionsBlocks={reconciledTransactionsBlocks}
            ledgerEndBalance={ledgerEndBalance}
            bankClosingBalance={bankClosingBalance}
            unreconciledTransactionsBlocks={unreconciledTransactionsBlocks}
            reconciliationDifference={reconciliationDifference}
            isReconciliationReportLocked={isReconciliationReportLocked}
            handleDownloadXLSXButtonClick={handleDownloadXLSXButtonClick}
            showLockReconciliationReportDialog={showLockReconciliationReportDialog}
            navigateToManualReconciliation={navigateToManualReconciliation}
          />
          <FullWidthTabs
            selectedTab={1}
            tabs={[
              {
                label: 'Reconciled Transactions',
                isReconciliationReport: true,
                content: (
                  <ReconciledTransactions
                    reconciledTransactionsBlocks={reconciledTransactionsBlocks}
                    externalDocumentType={externalDocumentType}
                    ledgerDocumentType={ledgerDocumentType}
                    unreconcileMatch={unreconcileMatch}
                    bankStatementTransactionsPercentage={bankStatementTransactionsPercentage}
                  />
                ),
              },
              {
                label: 'Unreconciled Transactions',
                isReconciliationReport: true,
                content: (
                  <UnreconciledTransactions
                    unreconciledTransactionsBlocks={unreconciledTransactionsBlocks}
                    ledgerEndBalance={ledgerEndBalance}
                    bankClosingBalance={bankClosingBalance}
                    reconciliationDifference={reconciliationDifference}
                    ledgerEndOfPeriodBalanceName={ledgerEndOfPeriodBalanceName}
                    externalClosingBalanceName={externalClosingBalanceName}
                    externalStatementTransactionsPercentage={bankStatementTransactionsPercentage}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}

// unreconciledTransactionsBlocks - reconciledTransactionsBlocks reconciliation report model
// const handleDownloadXLSXButtonClick = () => {
//   // Acquire Data (reference to the HTML table)
//   var tableElt = document.getElementById("reconciliation-report");

//   // Extract Data (create a workbook object from the table)
//   var workbook = utils.table_to_book(tableElt);

//   // Process Data (add a new row)
//   var ws = workbook.Sheets["Reconciliation Report"];
//   utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {
//     origin: -1,
//   });

//   // Package and Release Data (`writeFile` tries to write and save an XLSB file)
//   writeFileXLSX(workbook, "Report.xlsx");
// };
