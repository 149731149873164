import { CONCRETE_TYPES } from './Document';

export enum FILE_TYPE {
  EXCEL = 'EXCEL',
  PDF = 'PDF',
  WORD = 'WORD',
  IMAGE = 'IMAGE',
}

export enum DOCUMENT_TYPE {
  LEDGER = 'LEDGER',
  BANK_STATEMENT = 'BANK_STATEMENT',
}

export type UploadFileParams = {
  file: File;
  fileName: string;
  fileType: FILE_TYPE;
  documentType: DOCUMENT_TYPE;
  concreteType: CONCRETE_TYPES;
  workspaceId: string;
  entityId: string;
  accountId: string;
  isNotInStandardTemplateFormat?: boolean;
};

export type ConvertFileParams = {
  file: File;
  fileType: FILE_TYPE;
};

export type FileData = {
  date: string;
  description: string;
  reference: string;
  financialTransactionId: string;
  amount: {
    amount: number;
    type: string;
  };
  balance: number;
};
