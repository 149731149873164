import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import WorkspacePage from './page';
import { useWorkspacesViewModel } from '../../view-models/WorkspacesViewModel';
import { useEntityViewModel } from '../../view-models/EntityViewModel';


export const WorkspaceController: React.FC = () => {
  const workspacesViewModel = useWorkspacesViewModel();
  const entityViewModel = useEntityViewModel();

  return (
    <WorkspacePage
      countries={workspacesViewModel.workspaceCountries}
      languages={workspacesViewModel.workspaceLanguages}
      users={workspacesViewModel.users}
      createUserWithEmailAndRoleId={workspacesViewModel.createUserWithEmailAndRoleId}
      userAvailableRoles={workspacesViewModel.availableUserRoles}
      updateWorkspaceName={workspacesViewModel.updateWorkspaceName}
      updateCountry={workspacesViewModel.updateWorkspaceCountry}
      updateLanguage={workspacesViewModel.updateWorkspaceLanguage}
      status="success"
      updateParams={workspacesViewModel.updateParams}
      updateWorkspace={workspacesViewModel.updateWorkspace}
      showCreateEntityDialog={entityViewModel.showCreateEntityDialog}
      deleteWorkspace={workspacesViewModel.deleteWorkspace}
      fetchUsersDetails={workspacesViewModel.fetchUsersDetails}
    />
  );
};

const WorkspaceControllerPage = observer(WorkspaceController);

export { WorkspaceControllerPage };
