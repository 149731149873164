import { ErrorBlock, Header, Texts, Text, SmallText } from './Styles';
import { IError } from '../../types';
import SimpleButton from '../Buttons/SimpleButton';

function Error(props: IError): JSX.Element {
  const { message } = props;
  const handleClick = () => {
    window.location.reload();
  };
  return (
    <ErrorBlock>
      <Header>Oops! Error</Header>
      <Texts>
        <Text>{message}</Text>
        <SmallText>Please wait and try again.</SmallText>
      </Texts>
      <SimpleButton content="Reload" onClick={handleClick} />
    </ErrorBlock>
  );
}
export default Error;
