import React, { ReactNode } from 'react';
import { IamViewModelProvider } from '../view-models/IamViewModel';
import { WorkspacesViewModelProvider } from '../view-models/WorkspacesViewModel';
import { EntityViewModelProvider } from '../view-models/EntityViewModel';
import { ReconciliationReportViewModelProvider } from '../view-models/ReconciliationReportViewModel';
import { ReconciliationAccountViewModelProvider } from '../view-models/ReconciliationAccountViewModel';
import { SuggestedMatchesViewModelProvider } from '../view-models/SuggestedMatchesViewModel';
import { DocumentsViewModelProvider } from '../view-models/DocumentsViewModel';
import { ConvertersAndTemplatesViewModelProvider } from '../view-models/ConvertersAndTemplatesViewModel';
import { UIViewModelProvider } from '../view-models/UIViewModel';

const ViewModelProviders: React.FC<{ children: ReactNode }> = ({ children }) => {
  const providers: React.ComponentType<any>[] = [
    IamViewModelProvider,
    WorkspacesViewModelProvider,
    EntityViewModelProvider,
    SuggestedMatchesViewModelProvider,
    ReconciliationReportViewModelProvider,
    ReconciliationAccountViewModelProvider,
    DocumentsViewModelProvider,
    ConvertersAndTemplatesViewModelProvider,
    UIViewModelProvider,
    // Add more View Model Providers here
  ];
  return (
    <>
      {providers.reduceRight(
        (child, Provider) => (
          <Provider>{child}</Provider>
        ),
        children
      )}
    </>
  );
};

export { ViewModelProviders };
