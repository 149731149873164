import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

// export const search: any = styled('form')({
//   width: '100%',
//   'margin-bottom': '50px',
//   padding: '20px',
//   border: 'none',
//   'border-radius': '10px',
//   'font-size': '20px',
// });

export const SearchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start', // Align to the left
  width: '100%', // Ensure the container takes the full width
});

export const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '300px', // Adjust the width as needed
  height: '30px', // Adjust the height as needed
  '& .MuiOutlinedInput-root': {
    height: '30px', // Adjust the height of the input
    '& input': {
      padding: '8px 14px', // Adjust padding to fit the reduced height
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px', // Adjust label font size if necessary
    transform: 'translate(14px, 9px) scale(1)', // Adjust label position
  },
  '& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.Mui-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)', // Adjust label position when focused/shrink
  },
}));
