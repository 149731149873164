import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright: React.FC = (props: any) => (
  <Typography variant="body2" color="text.secondary" align="center" sx={{ pt: 4 }}>
    {'Copyright © '}
    {new Date().getFullYear() === 2023 ? 2023 : `2023 - ${new Date().getFullYear()}`}
    {'. All right reserved by '}
    <Link color="inherit" href="https://reconcilio.com/">
      Reconcilio Ltd
    </Link>
    .
  </Typography>
);

export default Copyright;
