import { observer } from 'mobx-react-lite';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import DateRange from '../../DateRange/component';

const ReconciliationPeriodController = () => {
  const reconciliationReportViewModel = useReconciliationReportViewModel();
  const content = reconciliationReportViewModel ? (
    <DateRange
      startDate={reconciliationReportViewModel.reconciliationPeriodStartDate}
      endDate={reconciliationReportViewModel.reconciliationPeriodEndDate}
      editStartDate={reconciliationReportViewModel.setReconciliationPeriodStartDate}
      editEndDate={reconciliationReportViewModel.setReconciliationPeriodEndDate}
      shouldDisableStartDateFromDatePicker={
        reconciliationReportViewModel.shouldDisableStartDateFromDatePicker
      }
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
  return content;
};

const ReconciliationPeriodPage = observer(ReconciliationPeriodController);

export default ReconciliationPeriodPage;
