/* eslint-disable import/prefer-default-export */
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  Entity,
  EntitySlim,
  ReconciliationAccount,
  ReconciliationAccountSlim,
} from '../../../../models/Resources';
import { ExpandableEntityList } from '../../../ExpandableListItem/ExpandableEntityList';

export function EntityItems(props: {
  entities: EntitySlim[];
  drawerOpen: boolean;
  currentEntity: Entity | null;
  selectEntity: (entityId: string) => void;
  reconciliationAccountsList: ReconciliationAccountSlim[];
  currentReconciliationAccount: ReconciliationAccount | null;
  selectReconciliationAccount: (reconciliationAccountId: string) => void;
  navigateToEntity: (entityId: string) => void;
  navigateToReconciliationAccount: (reconciliationAccountId: string) => void;
}) {
  const {
    entities,
    drawerOpen,
    currentEntity,
    selectEntity,
    reconciliationAccountsList,
    currentReconciliationAccount,
    selectReconciliationAccount,
    navigateToEntity,
    navigateToReconciliationAccount,
  } = props;

  const selectEntityHandler = (entityId: string) => {
    selectEntity(entityId);
    navigateToEntity(entityId);
  };

  const selectReconciliationAccountHandler = (reconciliationAccountId: string) => {
    selectReconciliationAccount(reconciliationAccountId);
    navigateToReconciliationAccount(reconciliationAccountId);
  };

  return (
    <>
      {entities.map((entity) => (
        <ExpandableEntityList
          isSelected={entity.id === currentEntity?.id}
          accounts={reconciliationAccountsList}
          selectedAccount={currentReconciliationAccount}
          selectReconciliationAccountHandler={selectReconciliationAccountHandler}
          drawerOpen={currentEntity?.id === entity.id && drawerOpen}
          handleExpandCollapse={() => {
            selectEntity(entity.id);
          }}
          mainListItem={
            <>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  marginRight: drawerOpen ? 2 : 0,
                }}
              >
                <ApartmentIcon
                  sx={{
                    color: entity.id === currentEntity?.id ? 'white' : undefined,
                  }}
                />
              </ListItemIcon>
              {drawerOpen && (
                <Tooltip title={entity.name} placement="top" enterDelay={500} leaveDelay={200}>
                  <ListItemText
                    key={entity.id}
                    primary={
                      <Typography
                        sx={{
                          whiteSpace: 'normal',
                        }}
                      >
                        {entity.name}
                      </Typography>
                    }
                    onClick={() => {
                      selectEntityHandler(entity.id);
                    }}
                  />
                </Tooltip>
              )}
            </>
          }
        />
      ))}
    </>
  );
}
