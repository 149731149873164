import { Date, Reference, Debit, Credit, Description, Category } from './Styles';

function BCategories(): JSX.Element {
  return (
    <Category
      sx={{
        display: {
          xs: 'flex',
          lg: 'none',
        },
      }}
    >
      <Date>Date</Date>
      <Description>Description</Description>
      <Reference>Reference</Reference>
      <Debit>Debit</Debit>
      <Credit>Credit</Credit>
    </Category>
  );
}

export default BCategories;
