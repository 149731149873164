export enum ErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNEXPECTED = 'UNEXPECTED',
  UNKNOWN = 'UNKNOWN',
  RECONCILIATION_REPORT_NOT_FOUND = 'RECONCILIATION_REPORT_NOT_FOUND',
  INVALID_BALANCES = 'INVALID_BALANCES',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  INVALID_DOCUMENT_LOCATION = 'INVALID_DOCUMENT_LOCATION',
  INVALID_DOCUMENT_TYPE = 'INVALID_DOCUMENT_TYPE',
  INVALID_DOCUMENT_STATUS = 'INVALID_DOCUMENT_STATUS',
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  INVALID_DATE_FORMAT = 'INVALID_DATE_FORMAT',
  MISSING_OPENING_BALANCE = 'MISSING_OPENING_BALANCE',
  DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED = 'DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED',
  TRANSACTIONS_NOT_SUM_TO_ZERO = 'TRANSACTIONS_NOT_SUM_TO_ZERO',
  RECONCILIATION_REPORT_STATUS_LOCKED = 'RECONCILIATION_REPORT_STATUS_LOCKED',
  TRANSACATION_IS_NOT_UNRECONCILED = 'TRANSACATION_IS_NOT_UNRECONCILED',
  NOT_FOUND = 'NOT_FOUND',
  TRANSACTION_WAS_NOT_FOUND = 'TRANSACTION_WAS_NOT_FOUND',
  CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR = 'CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR',
  MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_REPORT = 'MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_REPORT',
  RECONCILIATION_REPORT_ALREADY_LOCKED_ERROR = 'RECONCILIATION_REPORT_ALREADY_LOCKED_ERROR',
  ADJUSTMENTS_REPORT_NOT_FOUND = 'ADJUSTMENTS_REPORT_NOT_FOUND',
  MISMATCH_IN_LEDGER_BALANCE_ERROR = 'MISMATCH_IN_LEDGER_BALANCE_ERROR',
  MIXED_DATES_ERROR = 'MIXED_DATES_ERROR',
  TRANSACTION_DATE_NOT_IN_RECONCILIATION_REPORT_RANGE = 'TRANSACTION_DATE_NOT_IN_RECONCILIATION_REPORT_RANGE',
  MISSING_DOCUMENT_FORMAT_TYPE = 'MISSING_DOCUMENT_FORMAT_TYPE',
  DEBIT_CREDIT_SWAPPED_ERROR = 'DEBIT_CREDIT_SWAPPED_ERROR',
}

export enum ErrorCategory {
  SERVICE = 'SERVICE',
  REPORT_SERVICE = 'REPORT_SERVICE',
  IAM_SERVICE = 'IAM_SERVICE',
}

type ErrorMessage = string;

export class BitloopsError extends Error {
  public code: ErrorCodes;

  public category: ErrorCategory;

  public message: ErrorMessage;

  constructor(code: ErrorCodes, category: ErrorCategory, message: ErrorMessage) {
    super(message);
    this.code = code;
    this.category = category;
    this.message = message;
  }
}

export const ServiceErrors = {
  UNAUTHORIZED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNAUTHORIZED,
      ErrorCategory.SERVICE,
      'Unauthorized error'
    );
    return error;
  },
  UNEXPECTED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNEXPECTED,
      ErrorCategory.SERVICE,
      'Unexpected error'
    );
    return error;
  },
  UNKNOWN_ERROR: () => {
    const error = new BitloopsError(ErrorCodes.UNKNOWN, ErrorCategory.SERVICE, 'Unknown error');
    return error;
  },
  NOT_FOUND_ERROR: (msg?: string) => () => {
    const error = new BitloopsError(
      ErrorCodes.NOT_FOUND,
      ErrorCategory.SERVICE,
      msg || 'Not found'
    );
    return error;
  },
};

export const IAMServiceErrors = {
  INVALID_TOKEN_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNAUTHORIZED,
      ErrorCategory.IAM_SERVICE,
      'Unauthorized error'
    );
    return error;
  },
  UNEXPECTED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNEXPECTED,
      ErrorCategory.IAM_SERVICE,
      'Unexpected error'
    );
    return error;
  },
};

export const ReportServiceErrors = {
  UNAUTHORIZED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNAUTHORIZED,
      ErrorCategory.REPORT_SERVICE,
      'Unauthorized error'
    );
    return error;
  },
  UNEXPECTED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNEXPECTED,
      ErrorCategory.REPORT_SERVICE,
      'Unexpected error'
    );
    return error;
  },
  UNKNOWN_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.UNKNOWN,
      ErrorCategory.REPORT_SERVICE,
      'Unknown error'
    );
    return error;
  },
  RECONCILIATION_REPORT_NOT_FOUND_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.RECONCILIATION_REPORT_NOT_FOUND,
      ErrorCategory.REPORT_SERVICE,
      'Reconciliation report not found'
    );
    return error;
  },
  INVALID_BALANCES_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.INVALID_BALANCES,
      ErrorCategory.REPORT_SERVICE,
      'Unable to read (Bank / Supplier / Ledger) Statement. Please ensure the format of the credit, debit and balance columns are numbers.'
    );
    return error;
  },
  INVALID_FILE_TYPE_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.INVALID_FILE_TYPE,
      ErrorCategory.REPORT_SERVICE,
      'Invalid file type'
    );
    return error;
  },
  INVALID_DOCUMENT_LOCATION_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.INVALID_DOCUMENT_LOCATION,
      ErrorCategory.REPORT_SERVICE,
      'Invalid document location'
    );
    return error;
  },
  INVALID_DOCUMENT_TYPE_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.INVALID_DOCUMENT_TYPE,
      ErrorCategory.REPORT_SERVICE,
      'Invalid document type'
    );
    return error;
  },
  INVALID_DOCUMENT_STATUS_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.INVALID_DOCUMENT_STATUS,
      ErrorCategory.REPORT_SERVICE,
      'Invalid document status'
    );
    return error;
  },
  DOCUMENT_NOT_FOUND_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.DOCUMENT_NOT_FOUND,
      ErrorCategory.REPORT_SERVICE,
      'Document not found'
    );
    return error;
  },
  INVALID_DATE_FORMAT_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.INVALID_DATE_FORMAT,
      ErrorCategory.REPORT_SERVICE,
      'Please verify the format of the dates in your (Bank / Supplier / Ledger) Statement'
    );
    return error;
  },
  MIXED_DATES_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.MIXED_DATES_ERROR,
      ErrorCategory.REPORT_SERVICE,
      'Please verify that there are no mixed dates in your (Bank / Supplier / Ledger) Statement'
    );
    return error;
  },
  TRANSACTION_DATE_NOT_IN_RECONCILIATION_REPORT_RANGE_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.TRANSACTION_DATE_NOT_IN_RECONCILIATION_REPORT_RANGE,
      ErrorCategory.REPORT_SERVICE,
      'Please verify that the dates in your (Bank / Supplier / Ledger) Statement are all inside the reconciliation report range you provided'
    );
    return error;
  },
  MISSING_OPENING_BALANCE_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.MISSING_OPENING_BALANCE,
      ErrorCategory.REPORT_SERVICE,
      'Please add an Opening Balance to the (Bank / Supplier / Ledger) Statement'
    );
    return error;
  },
  DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.DEBIT_CREDIT_MUST_BE_EXCLUSIVELY_FILLED,
      ErrorCategory.REPORT_SERVICE,
      'There seems to be an issue with some transaction(s). There may be a value in both the debit and credit field for one or more transactions, or transactions may be missing values.'
    );
    return error;
  },
  TRANSACTIONS_NOT_SUM_TO_ZERO_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.TRANSACTIONS_NOT_SUM_TO_ZERO,
      ErrorCategory.REPORT_SERVICE,
      'Transactions must sum to zero'
    );
    return error;
  },
  RECONCILIATION_REPORT_STATUS_LOCKED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.RECONCILIATION_REPORT_STATUS_LOCKED,
      ErrorCategory.REPORT_SERVICE,
      'Reconciliation report status locked'
    );
    return error;
  },
  TRANSACATION_IS_NOT_UNRECONCILED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.TRANSACATION_IS_NOT_UNRECONCILED,
      ErrorCategory.REPORT_SERVICE,
      'Transaction is not unreconciled'
    );
    return error;
  },
  TRANSACTION_WAS_NOT_FOUND: () => {
    const error = new BitloopsError(
      ErrorCodes.TRANSACTION_WAS_NOT_FOUND,
      ErrorCategory.REPORT_SERVICE,
      'Transaction WAS NOT FOUND'
    );
    return error;
  },
  CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.CANNOT_MODIFY_RECONCILED_TRANSACTION_ERROR,
      ErrorCategory.REPORT_SERVICE,
      'Cannot modify reconciled transaction'
    );
    return error;
  },
  MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_REPORT: () => {
    const error = new BitloopsError(
      ErrorCodes.MATCH_DOES_NOT_EXIST_IN_RECONCILIATION_REPORT,
      ErrorCategory.REPORT_SERVICE,
      'Match does not exist in reconciliation report'
    );
    return error;
  },
  RECONCILIATION_REPORT_ALREADY_LOCKED: () => {
    const error = new BitloopsError(
      ErrorCodes.RECONCILIATION_REPORT_ALREADY_LOCKED_ERROR,
      ErrorCategory.REPORT_SERVICE,
      'Reconciliation report was already locked'
    );
    return error;
  },
  ADJUSTMENTS_REPORT_NOT_FOUND: () => {
    const error = new BitloopsError(
      ErrorCodes.ADJUSTMENTS_REPORT_NOT_FOUND,
      ErrorCategory.REPORT_SERVICE,
      'Adjustments report was not found'
    );
    return error;
  },
  MISMATCH_IN_LEDGER_BALANCE_ERROR: (message: string) => {
    const error = new BitloopsError(
      ErrorCodes.MISMATCH_IN_LEDGER_BALANCE_ERROR,
      ErrorCategory.REPORT_SERVICE,
      message
    );
    return error;
  },
  MISSING_DOCUMENT_FORMAT_TYPE_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.MISSING_DOCUMENT_FORMAT_TYPE,
      ErrorCategory.REPORT_SERVICE,
      'Please select the format type of the Statemet (Debits Increase / Debits Decrease)'
    );
    return error;
  },
  DEBIT_CREDIT_SWAPPED_ERROR: () => {
    const error = new BitloopsError(
      ErrorCodes.DEBIT_CREDIT_SWAPPED_ERROR,
      ErrorCategory.REPORT_SERVICE,
      'Debits and Credits are swapped.'
    );
    return error;
  },
};
