import React from 'react';
import Categories from './Categories';
import { Header, HeaderName, Bank, Ledger } from './Styles';
import { EXTERNAL_DOCUMENT_TYPES, LEDGER_DOCUMENT_TYPES } from '../../../../../models/Document';

interface SmallScreenHeadersProps {
  externalDocumentType: EXTERNAL_DOCUMENT_TYPES;
  ledgerDocumentType: LEDGER_DOCUMENT_TYPES;
}

function SmallScreenHeaders(props: SmallScreenHeadersProps): JSX.Element {
  const { externalDocumentType, ledgerDocumentType } = props;
  return (
    <Header
      sx={{
        display: {
          xs: 'block',
          lg: 'none',
        },
      }}
    >
      <HeaderName>
        <Bank>{externalDocumentType}</Bank>&nbsp;/&nbsp;<Ledger>{ledgerDocumentType}</Ledger> &nbsp;
        Transactions
      </HeaderName>
      {/* <Action>
        <SmallSimpleButton content="New Ledger Transaction" />
      </Action> */}
      <Categories />
    </Header>
  );
}
export default SmallScreenHeaders;
