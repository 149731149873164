import { styled } from '@mui/material/styles';

export const BlockContent: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  fontSize: '10px',
  alignItems: 'center',
  padding: '15px 10px',
  boxSizing: 'border-box',
  height: 'auto',
});

export const Date: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '80px',
});

export const Description: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
});

export const Reference: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '90px',
});

export const Debit: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '70px',
});

export const Title: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

export const SmallScreen: any = styled('div')({});
export const LargeScreen: any = styled('div')({});
