import { observer } from 'mobx-react-lite';
import { useReconciliationReportViewModel } from '../../../view-models/ReconciliationReportViewModel';
import CreateLedgerTransactionDialog from './component';
import { useDocumentsViewModel } from '../../../view-models/DocumentsViewModel';

function CompensatingTransactionDialogController() {
  const reportViewModel = useReconciliationReportViewModel();

  const documentViewModel = useDocumentsViewModel();

  if (!reportViewModel) {
    return null;
  }
  return (
    <CreateLedgerTransactionDialog
      open={reportViewModel.isCreateLedgerTransactionDialogVisible}
      closeDialog={reportViewModel.hideCreateLedgerTransactionDialog}
      submitAction={() =>
        reportViewModel.createReconciliationReportWithComplementaryLedgerTransaction({
          externalDocumentId: documentViewModel.bankDocumentId,
          ledgerDocumentId: documentViewModel.ledgerDocumentId,
        })
      }
    />
  );
}

const CompensatingTransactionDialog = observer(CompensatingTransactionDialogController);

// eslint-disable-next-line import/prefer-default-export
export { CompensatingTransactionDialog };
