import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import StopListView from './component';
import { useEntityViewModel } from '../../../../view-models/EntityViewModel';

const StopListController = () => {
  const entityViewModel = useEntityViewModel();

  return entityViewModel.entitySettings.data ? (
    <StopListView
      stopList={entityViewModel.entitySettings.data.stopList}
      workspaceStopList={entityViewModel.entitySettings.data.workspaceStopList}
      deleteEntityStopListItem={entityViewModel.deleteStopListItem}
      updateEntityStopListItem={entityViewModel.updateStopListItem}
      showAddStopListItemDialog={entityViewModel.showAddStopListItemDialog}
      setStopListItemName={entityViewModel.setStopListItemName}
      enableWorkspaceStopListItem={entityViewModel.enableWorkspaceStopListItem}
      disableWorkspaceStopListItem={entityViewModel.disableWorkspaceStopListItem}
    />
  ) : (
    <CircularProgress />
  );
};

const StopList = observer(StopListController);

export default StopList;
