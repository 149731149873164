import { styled } from '@mui/material/styles';

export const ReconciledTransaction: any = styled('div')({
  backgroundColor: 'rgba(235, 235, 235, 0.1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '5px 2.5px',
  height: 'auto',
  width: '100%',
});

export const Layout: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: 'auto',
  width: '100%',
  // Margin left is the width of the flag.
  // It will be removed when the unreconcile button is added on the right.
  // marginLeft: '-42px',
});

export const MainContent: any = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});

export const Button: any = styled('div')({
  backgroundColor: 'rgba(146, 0, 0, 0.1)',
  border: '1px solid transparent',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '12px 10px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 10px',
  overflow: 'hidden',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(146, 0, 0, 0.2)',
    border: '1px solid rgba(146, 0, 0, 0.2)',
  },
});

export const Icon: any = styled('img')({
  height: '16px',
  width: '20px',
  margin: '0px',
});

export const FlagShape: any = styled('div')({
  border: '1px solid var(--primary-color)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius) 0px 0px 4px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '10.5px 12px',
  margin: '0px',
  overflow: 'hidden',
});

export const FlagText: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});
