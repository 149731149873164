import Checkbox from '@mui/material/Checkbox';
import { Block, Content, Actions, Action, Category, Items } from '../Styles';
import { Item } from './Styles';
import { CategoryItem } from '../../../../../models/Resources';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface WorkspaceCategoryBlockProps {
  name: string;
  items: readonly CategoryItem[];
  checked: boolean;
  enableWorkspaceCategory: () => Promise<void>;
  disableWorkspaceCategory: () => Promise<void>;
}
function WorkspaceCategoryBlock(props: WorkspaceCategoryBlockProps) {
  const { name, items, checked, enableWorkspaceCategory, disableWorkspaceCategory } = props;

  return (
    <Block>
      <Content>
        <Category>{name}</Category>
        <Items>
          {items.map((item) => (
            <Item key={item.id}>{item.value}</Item>
          ))}
        </Items>
      </Content>

      <Actions>
        <Action>
          <Checkbox
            checked={checked}
            {...label}
            sx={{
              '&:hover': { bgcolor: 'transparent' },
              padding: 0,
            }}
            onClick={() => {
              if (checked) {
                disableWorkspaceCategory();
              } else {
                enableWorkspaceCategory();
              }
            }}
          />
        </Action>
      </Actions>
    </Block>
  );
}

export default WorkspaceCategoryBlock;
