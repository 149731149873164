import { Header, Date, Description, Reference, Amount, Title } from './Styles';
import { InnerBlockHeaderProps } from '../../../../../../types';

function InnerBlockHeader(props: InnerBlockHeaderProps): JSX.Element {
  const { dateHeader, descriptionHeader, referenceHeader, amountHeader } = props;
  return (
    <Header>
      <Date>
        <Title>{dateHeader || 'Date'}</Title>
      </Date>
      <Description
        sx={{
          width: {
            xs: '250px',
            lg: '660px',
          },
        }}
      >
        <Title>{descriptionHeader || 'Description'}</Title>
      </Description>
      <Reference>
        <Title>{referenceHeader || 'Reference'}</Title>
      </Reference>
      <Amount>
        <Title>{amountHeader}</Title>
      </Amount>
    </Header>
  );
}

export default InnerBlockHeader;
