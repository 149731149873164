import VisibleText from '../../../../../VisibleText';
import { SmallScreen, LargeScreen, Description } from './Styles';

interface DescriptionColumnProps {
  content: string;
}

function DescriptionColumn(props: DescriptionColumnProps): JSX.Element {
  const { content } = props;
  return (
    <Description
      sx={{
        width: {
          xs: '250px',
          lg: '660px',
        },
      }}
    >
      <SmallScreen
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
        }}
      >
        <VisibleText text={content} maxCharacters={40} />
      </SmallScreen>
      <LargeScreen
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
        }}
      >
        <VisibleText text={content} maxCharacters={100} />
      </LargeScreen>
    </Description>
  );
}
export default DescriptionColumn;
