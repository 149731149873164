import { Buttons } from './Styles';
import SmallSimpleButton from '../../Buttons/SmallSimpleButton';

interface ReconciliationReportButtons {
  showNewLedgerTransactionDialog: () => void;
  generateCorrespondingTransactions: () => void;
}

function ReconciliationReportButtons(props: ReconciliationReportButtons): JSX.Element {
  const { showNewLedgerTransactionDialog, generateCorrespondingTransactions } = props;
  return (
    <Buttons>
      <SmallSimpleButton
        content="New Ledger Transaction"
        onClick={showNewLedgerTransactionDialog}
      />
      <SmallSimpleButton
        content="Auto Ledger Transactions"
        onClick={generateCorrespondingTransactions}
      />
    </Buttons>
  );
}

export default ReconciliationReportButtons;
