import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ConfirmationDialog from './component';
import { useUIViewModel } from '../../view-models/UIViewModel';

const ConfirmationDialogController = () => {
  const uiViewModel = useUIViewModel();
  const [open, setOpen] = useState(uiViewModel.isConfirmationDialogVisible);

  useEffect(() => {
    setOpen(uiViewModel.isConfirmationDialogVisible);
  }, [uiViewModel.isConfirmationDialogVisible]);

  return (
    (open && (
      <ConfirmationDialog
        confirmationMessage={uiViewModel.confirmationDialogMessage}
        confirmationButtonMessage={uiViewModel.confirmationDialogButtonMessage}
        open={open}
        closeDialog={() => {
          uiViewModel.hideConfirmationDialog();
        }}
        submit={() => {
          uiViewModel.confirmationDialogAction();
          uiViewModel.hideConfirmationDialog();
        }}
      />
    )) ||
    null
  );

};

export default observer(ConfirmationDialogController);
