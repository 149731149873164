import { styled } from '@mui/material/styles';

export const DescriptionSpan: any = styled('span')({
  display: '-webkit-box',
  WebkitLineClamp: 2, // Number of lines you want to display
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  // whiteSpace: 'pre-wrap',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '11px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Description: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '10px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: '290px',
  height: 'auto',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});
