import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import FilledButton from '../Buttons/FilledButton';
import { Form } from './Styles';

export interface EmailPassFormProps {
  email: string;
  password: string;
  message: { type: 'error' | 'success'; message: string } | null;
  view: string;
  submit: () => void;
  updateEmail: (email: string) => void;
  updatePassword: (password: string) => void;
}

function EmailPassForm(props: EmailPassFormProps): JSX.Element {
  const {
    email,
    password,
    message, // You have successfully signed up!
    submit,
    updateEmail,
    updatePassword,
    view,
  } = props;

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatePassword(event.target.value);
  };

  return (
    <Form>
      <TextField label="Email" type="email" value={email} onChange={handleEmailChange} />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && email && password) {
            submit();
          }
        }}
      />
      <FilledButton disabled={!email || !password} content={view} onClick={submit} />
      {message && <Alert severity={message.type}>{message.message}</Alert>}
    </Form>
  );
}
export { EmailPassForm };
