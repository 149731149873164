import { ReconciledTransactionsBlock } from '../../../models/ReconciliationReport';

export function ReconciledTransactionsBlockRows({
  bankTransactions,
  ledgerTransactions,
}: {
  bankTransactions: ReconciledTransactionsBlock['bankStatementTransactions'];
  ledgerTransactions: ReconciledTransactionsBlock['ledgerTransactions'];
}) {
  return (
    <>
      {bankTransactions.map((transaction, index) => (
        <tr key={index}>
          <td>{transaction.date}</td>
          <td>{transaction.description}</td>
          <td>{transaction.reference}</td>
          <td>{transaction.debit}</td>
          <td>{transaction.credit}</td>
        </tr>
      ))}
      {ledgerTransactions.map((transaction, index) => (
        <tr key={index}>
          <td>{transaction.date}</td>
          <td>{transaction.description}</td>
          <td>{transaction.reference}</td>
          <td>{transaction.debit}</td>
          <td>{transaction.credit}</td>
        </tr>
      ))}
    </>
  );
}
