import dayjs from 'dayjs';

const DATES_FORMAT = 'DD/MM/YYYY';
const INVALID_DATE = 'Invalid Date';

type DayMonthYear = {
  day: number;
  month: number;
  year: number;
};

export default class DateUtils {
  static GetDayMonthYearFromDateString(dateString: string): DayMonthYear {
    const day = dayjs(dateString).day();
    const month = dayjs(dateString).month() + 1;
    const year = dayjs(dateString).year();
    return { day, month, year };
  }

  static GetDateStringFromDayMonthYear(day: number, month: number, year: number): string {
    return dayjs()
      .set('year', year)
      .set('month', month - 1)
      .set('day', day)
      .format(DATES_FORMAT);
  }

  static GetNextDayDateString(date: string): string {
    return dayjs(date, DATES_FORMAT).add(1, 'day').format(DATES_FORMAT);
  }

  static FormatYearToYY = (date: string): string => {
    if (date === INVALID_DATE) {
      return date;
    }
    const [day, month, year] = date.split('/');
    return `${day}/${month}/${year.slice(2)}`;
  };

  static FormatYearToYYYY = (date: string): string => {
    const [day, month, year] = date.split('/');
    return `${day}/${month}/20${year}`;
  };

  static ParseCustomDate = (dateStr: string): Date => {
    const [day, month, year] = dateStr.split('/').map((num) => parseInt(num, 10));
    return new Date(year, month - 1, day); // Note: month is 0-indexed
  };
}
