import { styled } from '@mui/material/styles';

export const SuggestedMatch: any = styled('div')({
  backgroundColor: 'rgba(235, 235, 235, 0.1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '5px 2.5px',
  height: 'auto',
  width: '100%',
});

export const Buttons: any = styled('div')({
  display: 'flex',
  position: 'relative',
  gap: '20px',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '20px 0px 0px 0px',
});
