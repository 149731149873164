import { observer } from 'mobx-react-lite';
import CategoryItemControlsDialog from './component';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';

const EditCategoryItemController = () => {
  const workspaceViewModel = useWorkspacesViewModel();

  return (
    <CategoryItemControlsDialog
      open={workspaceViewModel.isEditCategoryItemDialogVisible}
      closeDialog={workspaceViewModel.hideEditCategoryItemDialog}
      submit={() => workspaceViewModel.updateCategoryItem()}
      categoryItemValue={workspaceViewModel.categoryItemName}
      setCategoryItemName={workspaceViewModel.setCategoryItemName}
      areRequiredFieldsSet={workspaceViewModel.categoryItemName.length > 0}
      deleteCategoryItem={() => workspaceViewModel.deleteCategoryItem()}
    />
  );
};

const EditCategoryItem = observer(EditCategoryItemController);

export default EditCategoryItem;
