import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { action, autorun, computed, makeAutoObservable } from 'mobx';
import { GetUserError, IIamRepository } from '../infra/repositories/iam';
import { EditUserParams, User } from '../models/User';
import { useIamRepository } from '../context/DI';
// import User from '../pages/User';
import { AsyncStatus, State, fetchedState, initState } from '../types';
import { EventBus, Events } from '../Events';

interface IIamViewModel {
  loginEmail: string;
  loginPassword: string;
  registerEmail: string;
  registerPassword: string;
  isAuthenticated: boolean | null;
  user: User | null;
  authMessage: { type: 'error' | 'success'; message: string } | null;
  loginWithGoogle: () => Promise<void | Error>;
  logout: () => void;
  loginWithEmailPassword: () => Promise<void>;
  updateLoginEmail: (email: string) => void;
  updateLoginPassword: (password: string) => void;
  updateRegisterEmail: (email: string) => void;
  updateRegisterPassword: (password: string) => void;
}

class IamViewModel implements IIamViewModel {
  _loginEmail = '';

  _loginPassword = '';

  _registerEmail = '';

  _registerPassword = '';

  _isAuthenticated: boolean | null = null;

  _user: State<User | null, GetUserError> = initState<User | null, GetUserError>(null);

  _authMessage: { type: 'error' | 'success'; message: string } | null = null;

  private _updateParams: EditUserParams = {
    name: '',
    companyName: '',
    country: '',
    email: '',
    phone: '',
    industry: '',
  };

  constructor(private iamRepository: IIamRepository) {
    makeAutoObservable(this, {
      isAuthenticated: computed,
      user: computed,
      authMessage: computed,
      initializeUpdateParams: action,
    });
    autorun(() => {
      if (this._user) {
        this.initializeUpdateParams();
      }
    });
    console.log('IamViewModel constructor', iamRepository);
    EventBus.subscribe(Events.USER_FETCH, this.userFetchedHandler);
    EventBus.subscribe(Events.USER_UPDATE, this.userUpdatedHandler);
    EventBus.emit(Events.RENEW_ACCESS_TOKEN, fetchedState(null));
  }

  @action
  asyncCallback = (data: any) => {
    console.log('asyncCallback', data);
    const { event, session } = data;
    const jwt = session?.access_token;
    const user = session?.user;
    const uid = user?.id;
    const name = user?.user_metadata?.full_name;
    if (!(jwt && uid && name)) {
      this._isAuthenticated = false;
      this._user = initState<User | null, GetUserError>(null);
    }

    switch (event) {
      case 'INITIAL_SESSION':
        console.log('switch INITIAL_SESSION', session);
        if (jwt && uid && name) {
          this._isAuthenticated = true;
          this._user = fetchedState({ id: uid, name, jwt });
        } else {
          this._isAuthenticated = false;
          this._user = initState<User | null, GetUserError>(null);
        }
        break;
      case 'SIGNED_IN':
        console.log('switch SIGNED_IN', session);
        if (jwt && uid && name) {
          this._isAuthenticated = true;
          this._user = fetchedState({ id: uid, name, jwt });
        }
        break;
      case 'SIGNED_OUT':
        console.log('switch SIGNED_OUT', session);
        this._isAuthenticated = false;
        this._user = fetchedState(null);
        break;
      default:
        console.log('switch DEFAULT', event, session);
        break;
    }
  };

  initializeUpdateParams() {
    this._updateParams = {
      name: this._user.data?.name || '',
      companyName: this._user.data?.companyName || '',
      country: this._user.data?.country || '',
      email: this._user.data?.email || '',
      phone: this._user.data?.phone || '',
      industry: this._user.data?.industry || '',
    };
  }

  @action
  private setAuthMessage = (message: { type: 'error' | 'success'; message: string } | null) => {
    this._authMessage = message;
  };

  @action
  private setIsAuthenticated = (isAuthenticated: boolean | null) => {
    this._isAuthenticated = isAuthenticated;
  };

  @action
  private setUser = (user: User | null) => {
    this._user = fetchedState(user);
  };

  @action
  private clearAuthMessage = () => {
    this._authMessage = null;
  };

  @action
  registerWithEmailPassword = async (): Promise<void> => {
    try {
      await this.iamRepository.registerWithEmailPassword(
        this._registerEmail,
        this._registerPassword
      );
      this.setAuthMessage({ type: 'success', message: 'Registered successfully!' });
    } catch (error) {
      this.setAuthMessage({ type: 'error', message: (error as Error).message });
    }
  };

  @action
  loginWithEmailPassword = async (email?: string, password?: string): Promise<void> => {
    const loginEmail = email || this._loginEmail;
    const loginPassword = password || this._loginPassword;
    try {
      const user = await this.iamRepository.loginWithEmailPassword(loginEmail, loginPassword);
      this.setIsAuthenticated(true);
      this.setUser(user);
      EventBus.emit(Events.RENEW_ACCESS_TOKEN, fetchedState(user));
      this.setAuthMessage({ type: 'success', message: 'Login successful!' });
      this.clearLoginEmail();
      this.clearLoginPassword();
    } catch (error) {
      // console.log(error);
      this.setAuthMessage({ type: 'error', message: (error as Error).message });
    }
  };

  fetchUser = (): void => {
    try {
      const repoResponse = this.iamRepository.getUserDetails();
      this._user.status = repoResponse.status;
    } catch (error) {
      console.error(error);
    }
  };

  @action
  loginWithGoogle = async (): Promise<void> => {
    throw new Error('Not implemented');
  };

  @action
  logout = () => {
    this.iamRepository.logout();
    this._isAuthenticated = false;
    this.setUser(null);
  };

  @action
  updateLoginEmail = (email: string) => {
    this._loginEmail = email;
  };

  @action
  updateLoginPassword = (password: string) => {
    this._loginPassword = password;
  };

  @action
  updateRegisterEmail = (email: string) => {
    this._registerEmail = email;
  };

  @action
  updateRegisterPassword = (password: string) => {
    this._registerPassword = password;
  };

  @action
  clearLoginEmail = () => {
    this._loginEmail = '';
  };

  @action
  clearLoginPassword = () => {
    this._loginPassword = '';
  };

  get isAuthenticated() {
    if (this._isAuthenticated === null) {
      this._isAuthenticated = this.iamRepository.isAuthenticated();
      if (this._isAuthenticated) {
        this._user = fetchedState(this.iamRepository.getUser());
      }
    }
    return this._isAuthenticated;
  }

  get user() {
    if (this._isAuthenticated === null) {
      this._isAuthenticated = this.iamRepository.isAuthenticated();
      if (this._isAuthenticated) {
        this._user = fetchedState(this.iamRepository.getUser());
      }
    }
    return this._user.data;
  }

  get loginEmail() {
    return this._loginEmail;
  }

  get loginPassword() {
    return this._loginPassword;
  }

  get registerEmail() {
    return this._registerEmail;
  }

  get registerPassword() {
    return this._registerPassword;
  }

  get authMessage() {
    if (this._authMessage) {
      setTimeout(() => {
        this.clearAuthMessage();
      }, 5000);
    }
    return this._authMessage;
  }

  get updateParams(): EditUserParams {
    return this._updateParams;
  }

  get userCountries() {
    return ['Cyprus', 'Greece', 'USA', 'UK'];
  }

  userFetchedHandler = (user: State<User, GetUserError>) => {
    if (user.status === AsyncStatus.ERROR) {
      console.error(user.error);
      return;
    }

    if (user.data !== null && user.status === AsyncStatus.SUCCESS) {
      this._user = fetchedState(user.data);
    }
  };

  @action
  updateUserName = (name: string) => {
    this._updateParams = {
      ...this._updateParams,
      name,
    };
  };

  @action
  updateUserCountry = (country: string) => {
    this._updateParams = {
      ...this._updateParams,
      country,
    };
  };

  @action
  updateUserCompanyName = (companyName: string) => {
    this._updateParams = {
      ...this._updateParams,
      companyName,
    };
  };

  @action
  updateUserIndustry = (industry: string) => {
    this._updateParams = {
      ...this._updateParams,
      industry,
    };
  };

  @action
  updateUserPhone = (phone: string) => {
    this._updateParams = {
      ...this._updateParams,
      phone,
    };
  };

  @action
  updateUserEmail = (email: string) => {
    this._updateParams = {
      ...this._updateParams,
      email,
    };
  };

  updateUser = async (userParams: Partial<EditUserParams>): Promise<void> => {
    try {
      await this.iamRepository.updateUserDetails({
        ...userParams,
      });
      this._updateParams = { ...this._updateParams, ...userParams };
    } catch (error: unknown) {
      console.error(error);
    }
  };

  userUpdatedHandler = (user: State<null, GetUserError>) => {
    if (user.status === AsyncStatus.ERROR) {
      console.error(user.error);
      // return;
    }

    if (this._updateParams.country && this._user.data)
      this._user.data.country = this._updateParams.country;

    if (this._updateParams.name && this._user.data) this._user.data.name = this._updateParams.name;

    if (this._updateParams.companyName && this._user.data)
      this._user.data.companyName = this._updateParams.companyName;

    if (this._updateParams.industry && this._user.data)
      this._user.data.industry = this._updateParams.industry;

    if (this._updateParams.phone && this._user.data)
      this._user.data.phone = this._updateParams.phone;

    if (this._updateParams.email && this._user.data)
      this._user.data.email = this._updateParams.email;
  };
}

const IamViewModelContext = createContext<IamViewModel | null>(null);

interface IamViewModelProviderProps {
  children: React.ReactNode;
}

const IamViewModelProvider: React.FC<IamViewModelProviderProps> = (
  props: IamViewModelProviderProps
) => {
  const { children } = props;
  const iamRepository = useIamRepository();
  // const loginViewModel = new IamViewModel(iamRepository);
  const loginViewModel = useMemo(() => new IamViewModel(iamRepository), [iamRepository]);

  useEffect(() => {
    iamRepository.onAuthStateChanged((data: any) => {
      loginViewModel.asyncCallback(data);
    });
  }, []);

  return (
    <IamViewModelContext.Provider value={loginViewModel}>{children}</IamViewModelContext.Provider>
  );
};

const useIamViewModel = () => {
  const viewModel = useContext(IamViewModelContext);
  if (!viewModel) throw new Error('No IamViewModel provided');
  return viewModel;
};

export { IamViewModel, IamViewModelProvider, IamViewModelContext, useIamViewModel };
