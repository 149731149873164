import { Content, Block } from './Styles';
import FilledButton from '../../../components/Buttons/FilledButton';
import { DownloadReport } from './download-report';
import saveIcon from '../../../assets/save-white.svg';
import lockIcon from '../../../assets/locked-white.svg';
import {
  ReconciledTransactionsBlock,
  UnreconciledTransactionsBlock,
} from '../../../models/ReconciliationReport';
import LockReconciliationReportDialog from '../../../components/ReconciliationReport/LockReconciliationReportDialog';

interface ReconciliationReportButtons {
  unreconciledTransactionsBlocks: UnreconciledTransactionsBlock[] | null;
  reconciledTransactionsBlocks: ReconciledTransactionsBlock[] | null;
  ledgerEndBalance: string;
  bankClosingBalance: string;
  reconciliationDifference: string;
  isReconciliationReportLocked: boolean;
  handleDownloadXLSXButtonClick: () => void | Promise<void>;
  showLockReconciliationReportDialog: () => void;
  navigateToManualReconciliation: () => void;
}

function ReconciliationReportButtons(props: ReconciliationReportButtons): JSX.Element {
  const {
    reconciledTransactionsBlocks,
    ledgerEndBalance,
    bankClosingBalance,
    unreconciledTransactionsBlocks,
    reconciliationDifference,
    isReconciliationReportLocked,
    handleDownloadXLSXButtonClick,
    showLockReconciliationReportDialog,
    navigateToManualReconciliation,
  } = props;
  return (
    <Block>
      <Content>
        <DownloadReport
          unreconciledTransactionsBlocks={unreconciledTransactionsBlocks}
          reconciledTransactionsBlocks={reconciledTransactionsBlocks}
          ledgerEndBalance={ledgerEndBalance}
          bankClosingBalance={bankClosingBalance}
          reconciliationDifference={reconciliationDifference}
          handleDownloadXLSXButtonClick={handleDownloadXLSXButtonClick}
        />
        <FilledButton
          content="Save Report"
          icon={saveIcon}
          disabled={isReconciliationReportLocked}
        />
        <FilledButton
          content="Save and Lock"
          onClick={showLockReconciliationReportDialog}
          icon={lockIcon}
          disabled={isReconciliationReportLocked}
        />
        <FilledButton
          content="Manual Reconciliation"
          onClick={navigateToManualReconciliation}
          disabled={isReconciliationReportLocked}
        />
        <LockReconciliationReportDialog />
      </Content>
    </Block>
  );
}

export default ReconciliationReportButtons;
