import Button from '@mui/material/Button';
import reconcileIcon from '../../../../assets/reconcile.svg';

interface ReconcileButtonProps {
  onClick: (reconciliationReportId: string) => void;
  disabled: boolean;
  reconciliationReportId: string;
}

function ReconcileButton(props: ReconcileButtonProps): JSX.Element {
  const { onClick, disabled, reconciliationReportId } = props;

  const handleClick = () => {
    onClick(reconciliationReportId);
  };

  return (
    <Button
      variant="contained"
      color="success"
      style={{ padding: '15px 20px', fontWeight: '500' }}
      startIcon={<img src={reconcileIcon} alt="Reconcile Icon" />}
      disabled={disabled}
      onClick={handleClick}
    >
      Reconcile
    </Button>
  );
}

export default ReconcileButton;
