import { styled } from '@mui/material/styles';

export const Block: any = styled('div')({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(235, 235, 235, 1)',
  boxSizing: 'border-box',
  borderRadius: 'var(--border-radius)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  overflow: 'hidden',
  height: 'auto',
});
