import React, { JSX, useState } from 'react';
import Button from '@mui/material/Button';
import { Block, Content, Actions, Action, Category, Input, Items, Item, Row, Rows } from './Styles';
import editIcon from '../../../../assets/edit.svg';
import undoIcon from '../../../../assets/undo.svg';
import deleteIcon from '../../../../assets/delete.svg';
import saveIcon from '../../../../assets/save.svg';
import { CategoryItem } from '../../../../models/Resources';

function chunk(array: CategoryItem[], size: number): CategoryItem[][] {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

interface EntityCategoryBlockProps {
  name: string;
  items: CategoryItem[];
  nameInput: JSX.Element;
  updateField: () => void;
  deleteField: () => void;
  showAddCategoryItemDialog: () => void;
  showEditCategoryItemDialog: (itemId: string) => void;
}
function EntityCategoryBlock(props: EntityCategoryBlockProps) {
  const {
    name,
    items,
    nameInput,
    updateField,
    deleteField,
    showAddCategoryItemDialog,
    showEditCategoryItemDialog,
  } = props;
  const [edit, setEdit] = useState(false);
  const handleClick = () => {
    setEdit(!edit);
  };

  // Mapping the array in chunks of 4 digits
  const chunkedItems = chunk(items, 4);

  return (
    <Block>
      <Content>
        <Category>{edit ? <Input>{nameInput}</Input> : name}</Category>
        <Items>
          {chunkedItems.map((chunkItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Rows key={index}>
              {chunkItem.map((item) => (
                <Row key={item.id}>
                  <Item onClick={() => showEditCategoryItemDialog(item.id)}>{item.value}</Item>
                </Row>
              ))}
            </Rows>
          ))}
        </Items>
      </Content>

      <Actions>
        {edit && (
          <Action>
            <button
              type="button" // Add the type attribute with the value "button"
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              aria-label="Save"
              onClick={() => {
                updateField();
                setEdit(false);
              }}
            >
              <img src={saveIcon} alt="save" />
            </button>
          </Action>
        )}
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={handleClick}
          >
            <img src={edit ? undoIcon : editIcon} alt="edit" />
          </button>
        </Action>
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={deleteField}
          >
            <img src={deleteIcon} alt="delete" />
          </button>
        </Action>

        <Button variant="text" onClick={showAddCategoryItemDialog}>
          Add Item
        </Button>
      </Actions>
    </Block>
  );
}

export default EntityCategoryBlock;
