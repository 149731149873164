import TextField from '@mui/material/TextField';
import InputBlock from '../Input';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';

interface MissingOpeningBalanceDialogProps {
  open: boolean;
  openingBalance: number | null;
  areRequiredFieldsSet: boolean;
  submitAction: () => void;
  closeDialog: () => void;
  editOpeningBalance: (ob: string) => void;
}
function MissingOpeningBalanceDialog(props: MissingOpeningBalanceDialogProps) {
  const {
    open,
    openingBalance,
    editOpeningBalance,
    closeDialog,
    submitAction,
    areRequiredFieldsSet,
  } = props;
  return (
    <CreateDialog
      title="Unable to proceed - Opening Balance required"
      open={open}
      closeDialog={closeDialog}
      content={
        <InputBlock
          name="Insert the Opening Balance"
          input={
            <TextField
              id="outlined-basic"
              type="number"
              variant="outlined"
              defaultValue={openingBalance}
              onChange={(e) => editOpeningBalance(e.target.value)}
            />
          }
          required
        />
      }
      action={
        <FilledButton disabled={!areRequiredFieldsSet} content="Confirm" onClick={submitAction} />
      }
    />
  );
}

export default MissingOpeningBalanceDialog;
