import TextField from '@mui/material/TextField';
import FilledButton from '../../Buttons/FilledButton';
import CreateDialog from '../CreateDialog';
import InputBlock from '../Input';
import SimpleButton from '../../Buttons/SimpleButton';
import deleteIcon from '../../../assets/delete.svg';

interface NewCategoryItemDialogProps {
  open: boolean;
  categoryItemValue: string;
  setCategoryItemName: (value: string) => void;
  deleteCategoryItem: () => void;
  areRequiredFieldsSet: boolean;
  closeDialog: () => void;
  submit: () => void;
}
function CategoryItemControlsDialog(props: NewCategoryItemDialogProps) {
  const {
    open,
    categoryItemValue,
    setCategoryItemName,
    areRequiredFieldsSet,
    deleteCategoryItem,
    submit,
    closeDialog,
  } = props;

  return (
    <CreateDialog
      title="Edit Category Item"
      open={open}
      closeDialog={closeDialog}
      content={
        <InputBlock
          name="Category Item Name"
          input={
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={categoryItemValue}
              onChange={(event) => setCategoryItemName(event.target.value)}
            />
          }
          required
        />
      }
      action={
        <>
          <SimpleButton
            icon={deleteIcon}
            onClick={deleteCategoryItem}
            content="Delete Category Item"
          />

          <div
            style={{
              marginLeft: 'auto',
            }}
          />
          <FilledButton disabled={!areRequiredFieldsSet} content="Update" onClick={submit} />
        </>
      }
    />
  );
}

export default CategoryItemControlsDialog;
