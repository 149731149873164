import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RegisterPage from './page';
import { useIamViewModel } from '../../view-models/IamViewModel';
// import ErrorContext from '../../context/Error';
// import { useSuggestedMatchesViewModel } from '../../viewModels/SuggestedMatchViewModel';

const LoginController: React.FC = () => {
  const iamViewModel = useIamViewModel();
  const navigate = useNavigate();

  useEffect(() => {
    if (iamViewModel.isAuthenticated) {
      navigate('/');
    }
  }, [iamViewModel.isAuthenticated]);

  useEffect(() => {
    if (iamViewModel.authMessage?.type === 'success') {
      iamViewModel.loginWithEmailPassword(
        iamViewModel.registerEmail,
        iamViewModel.registerPassword
      );
    }
  }, [iamViewModel.authMessage]);

  const visitLoginPage = () => {
    navigate('/login');
  };

  const handleSubmit = async (): Promise<void> => {
    iamViewModel.registerWithEmailPassword();
  };

  return (
    <RegisterPage
      email={iamViewModel.registerEmail}
      password={iamViewModel.registerPassword}
      submit={handleSubmit}
      visitLoginPage={visitLoginPage}
      message={iamViewModel.authMessage}
      updateEmail={iamViewModel.updateRegisterEmail}
      updatePassword={iamViewModel.updateRegisterPassword}
      name=""
      telephone=""
      companyName=""
      industry=""
      countries={[]}
      country=""
      updateName={() => {}}
      updateTelephone={() => {}}
      updateCompanyName={() => {}}
      updateIndustry={() => {}}
      setCountryInputValue={() => {}}
    />
  );
};

const Register = observer(LoginController);

export default Register;
