import { styled } from '@mui/material/styles';

export const ErrorBlock: any = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '40px',
  width: '100%',
  height: '60vh',
  fontFamily: 'var(--font-family)',
});

export const Header: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(0, 0, 0, 1)',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '32px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

export const Texts: any = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
});

export const Text: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});

export const SmallText: any = styled('div')({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '20px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
});
