import { observer } from 'mobx-react-lite';
import NewCategoryDialog from './component';
import { useWorkspacesViewModel } from '../../../view-models/WorkspacesViewModel';

const NewWorkspaceCategoryController = () => {
  const workspaceViewModel = useWorkspacesViewModel();

  return (
    <NewCategoryDialog
      open={workspaceViewModel.isAddCategoryDialogVisible}
      closeDialog={workspaceViewModel.hideAddCategoryDialog}
      submit={workspaceViewModel.addNewCategory}
      categoryName={workspaceViewModel.categoryName}
      setCategoryName={workspaceViewModel.setCategoryName}
      areRequiredFieldsSet={workspaceViewModel.categoryName.length > 0}
    />
  );
};

const NewWorkspaceCategory = observer(NewWorkspaceCategoryController);

export default NewWorkspaceCategory;
