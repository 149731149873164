import React, { useState, JSX } from 'react';
import { Block, Field, Actions, Action, Content } from './Styles';
import editIcon from '../../../../assets/edit.svg';
import undoIcon from '../../../../assets/undo.svg';
import saveIcon from '../../../../assets/save.svg';
import deleteIcon from '../../../../assets/delete.svg';

interface ItemBlockProps {
  name: string;
  nameInput: JSX.Element;
  updateField: () => void;
  deleteField: () => void;
}
function ItemBlock(props: ItemBlockProps) {
  const { name, nameInput, updateField, deleteField } = props;
  const [edit, setEdit] = useState(false);
  const handleClick = () => {
    setEdit(!edit);
  };
  return (
    <Block>
      <Content>{edit ? <Field>{nameInput}</Field> : <Field>{name}</Field>}</Content>
      <Actions>
        {edit && (
          <Action>
            <button
              type="button" // Add the type attribute with the value "button"
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
              }}
              aria-label="Save"
              onClick={() => {
                updateField();
                setEdit(false);
              }}
            >
              <img src={saveIcon} alt="save" />
            </button>
          </Action>
        )}
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={handleClick}
          >
            <img src={edit ? undoIcon : editIcon} alt="edit" />
          </button>
        </Action>
        <Action>
          <button
            type="button" // Add the type attribute with the value "button"
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
            }}
            aria-label="Edit"
            onClick={deleteField}
          >
            <img src={deleteIcon} alt="delete" />
          </button>
        </Action>
      </Actions>
    </Block>
  );
}

export default ItemBlock;
