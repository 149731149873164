import { ServiceErrors } from '../../Errors';
import { ReconcilioServiceClient } from '../../bitloops/proto/ReconcilioServiceClientPb';
import {
  GetEntityByIdRequest,
  GetEntityByIdResponse,
  GetWorkspaceByIdRequest,
  GetWorkspaceByIdResponse,
  GetWorkspacesRequest,
  GetWorkspacesResponse,
  Workspace as ProtoWorkspace,
  Entity as ProtoEntity,
  ReconciliationAccount as ProtoReconciliationAccount,
  GetReconciliationAccountByIdRequest,
  GetReconciliationAccountByIdResponse,
  CreateWorkspaceRequest,
  CreateWorkspaceResponse,
  CreateEntityRequest,
  CreateEntityResponse,
  CreateReconciliationAccountRequest,
  CreateReconciliationAccountResponse,
  EditWorkspaceProfileRequest,
  EditWorkspaceProfileResponse,
  DeleteWorkspaceResponse,
  EditEntityProfileResponse,
  DeleteEntityRequest,
  DeleteEntityErrorResponse,
  EditEntityProfileRequest,
  EditReconciliationAccountProfileResponse,
  EditReconciliationAccountProfileRequest,
  EditReconciliationAccountProfileErrorResponse,
  DeleteWorkspaceRequest,
  DeleteReconciliationAccountRequest,
  DeleteReconciliationAccountErrorResponse,
  CreateReconciliationAccountErrorResponse,
  CreateEntityErrorResponse,
  DeleteWorkspaceErrorResponse,
  CreateWorkspaceErrorResponse,
  EditWorkspaceProfileErrorResponse,
  EditEntityProfileErrorResponse,
  GetWorkspacesErrorResponse,
  GetWorkspaceByIdErrorResponse,
  GetEntityByIdErrorResponse,
  GetReconciliationAccountByIdErrorResponse,
  AddWorkspaceStopListItemRequest,
  AddWorkspaceStopListItemResponse,
  AddWorkspaceStopListItemErrorResponse,
  DeleteWorkspaceStopListItemRequest,
  DeleteWorkspaceStopListItemErrorResponse,
  DeleteWorkspaceStopListItemResponse,
  EditWorkspaceStopListItemErrorResponse,
  EditWorkspaceStopListItemRequest,
  EditWorkspaceStopListItemResponse,
  AddWorkspaceCategoryErrorResponse,
  AddWorkspaceCategoryRequest,
  AddWorkspaceCategoryResponse,
  DeleteWorkspaceCategoryRequest,
  DeleteWorkspaceCategoryErrorResponse,
  DeleteWorkspaceCategoryResponse,
  AddEntityCategoryErrorResponse,
  AddEntityCategoryItemErrorResponse,
  AddEntityCategoryItemRequest,
  AddEntityCategoryItemResponse,
  AddEntityCategoryRequest,
  AddEntityCategoryResponse,
  AddEntityStopListItemErrorResponse,
  AddEntityStopListItemRequest,
  AddEntityStopListItemResponse,
  AddWorkspaceCategoryItemErrorResponse,
  AddWorkspaceCategoryItemRequest,
  AddWorkspaceCategoryItemResponse,
  DeleteEntityCategoryErrorResponse,
  DeleteEntityCategoryItemErrorResponse,
  DeleteEntityCategoryItemRequest,
  DeleteEntityCategoryItemResponse,
  DeleteEntityCategoryRequest,
  DeleteEntityCategoryResponse,
  DeleteEntityStopListItemErrorResponse,
  DeleteEntityStopListItemRequest,
  DeleteEntityStopListItemResponse,
  DeleteWorkspaceCategoryItemErrorResponse,
  DeleteWorkspaceCategoryItemRequest,
  DeleteWorkspaceCategoryItemResponse,
  DisableWorkspaceCategoryOfEntityErrorResponse,
  DisableWorkspaceCategoryOfEntityRequest,
  DisableWorkspaceCategoryOfEntityResponse,
  DisableWorkspaceStopListItemOfEntityErrorResponse,
  DisableWorkspaceStopListItemOfEntityRequest,
  DisableWorkspaceStopListItemOfEntityResponse,
  EditEntityCategoryItemErrorResponse,
  EditEntityCategoryItemRequest,
  EditEntityCategoryItemResponse,
  EditEntityCategoryNameErrorResponse,
  EditEntityCategoryNameRequest,
  EditEntityCategoryNameResponse,
  EditEntityStopListItemErrorResponse,
  EditEntityStopListItemRequest,
  EditEntityStopListItemResponse,
  EditWorkspaceCategoryItemErrorResponse,
  EditWorkspaceCategoryItemRequest,
  EditWorkspaceCategoryItemResponse,
  EditWorkspaceCategoryNameErrorResponse,
  EditWorkspaceCategoryNameRequest,
  EditWorkspaceCategoryNameResponse,
  EnableWorkspaceCategoryOfEntityErrorResponse,
  EnableWorkspaceCategoryOfEntityRequest,
  EnableWorkspaceCategoryOfEntityResponse,
  EnableWorkspaceStopListItemOfEntityErrorResponse,
  EnableWorkspaceStopListItemOfEntityRequest,
  EnableWorkspaceStopListItemOfEntityResponse,
  GetWorkspaceSettingsByIdRequest,
  GetWorkspaceSettingsByIdErrorResponse,
  GetWorkspaceSettingsByIdResponse,
  GetEntitySettingsByIdRequest,
  GetEntitySettingsByIdResponse,
  GetEntitySettingsByIdErrorResponse,
} from '../../bitloops/proto/reconcilio_pb';
import { PROXY_URL } from '../../config';
import {
  AuthOptions,
  // CurrentEntity,
  // CurrentReconciliationAccount,
  IResourcesService,
  Workspace,
  Entity,
  ReconciliationAccount,
  CreateEntityParams,
  CreateReconciliationAccountParams,
  CreateWorkspaceParams,
  UpdateWorkspaceParams,
  UpdateEntityParams,
  DeleteEntityParams,
  DeleteReconciliationAccountParams,
  UpdateReconciliationAccountParams,
  EntitySettings,
  WorkspaceSettings,
} from '../interfaces/IResourcesService';

export default class GRPCResourcesService implements IResourcesService {
  private reconciliationService: ReconcilioServiceClient;

  constructor() {
    this.reconciliationService = new ReconcilioServiceClient(PROXY_URL);
  }

  async updateEntity(params: UpdateEntityParams, opts: AuthOptions): Promise<void> {
    const request = new EditEntityProfileRequest();
    request.setWorkspaceId(params.workspaceId);
    request.setEntityId(params.entityId);
    if (params.name) request.setName(params.name);
    if (params.country) request.setCountry(params.country);
    if (params.language) request.setLanguage(params.language);

    const response: EditEntityProfileResponse = await this.reconciliationService.editEntityProfile(
      request,
      {
        Authorization: `Bearer ${opts.idToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditEntityProfileErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditEntityProfileErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditEntityProfileErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async updateWorkspace(params: UpdateWorkspaceParams, opts: AuthOptions): Promise<void> {
    const request = new EditWorkspaceProfileRequest();
    request.setWorkspaceId(params.workspaceId);
    if (params.name) request.setName(params.name);
    if (params.country) request.setCountry(params.country);
    if (params.language) request.setLanguage(params.language);

    const response: EditWorkspaceProfileResponse =
      await this.reconciliationService.editWorkspaceProfile(request, {
        Authorization: `Bearer ${opts.idToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditWorkspaceProfileErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditWorkspaceProfileErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditWorkspaceProfileErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async createWorkspace(params: CreateWorkspaceParams, opts: AuthOptions): Promise<string> {
    const request = new CreateWorkspaceRequest();
    request.setCountry(params.country);
    request.setLanguage(params.language);
    request.setName(params.name);

    const response: CreateWorkspaceResponse = await this.reconciliationService.createWorkspace(
      request,
      {
        Authorization: `Bearer ${opts.idToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: CreateWorkspaceErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case CreateWorkspaceErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case CreateWorkspaceErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    } else {
      const id = response.getOk()?.getId();
      if (!id) throw new Error('No id returned');
      return id;
    }
  }

  async deleteWorkspace(id: string, opts: AuthOptions): Promise<void> {
    const request = new DeleteWorkspaceRequest();
    request.setWorkspaceId(id);
    const response: DeleteWorkspaceResponse = await this.reconciliationService.deleteWorkspace(
      request,
      {
        Authorization: `Bearer ${opts.idToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteWorkspaceErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteWorkspaceErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteWorkspaceErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async createEntity(params: CreateEntityParams, opts: AuthOptions): Promise<string> {
    const request = new CreateEntityRequest();
    request.setWorkspaceId(params.workspaceId);
    request.setName(params.name);
    request.setCountry(params.country);
    request.setLanguage(params.language);
    request.setUniqueIdentifier(params.uniqueIdentifier);
    request.setResponsibleUserId(params.owner);

    const response: CreateEntityResponse = await this.reconciliationService.createEntity(request, {
      Authorization: `Bearer ${opts.idToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: CreateEntityErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case CreateEntityErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case CreateEntityErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const id = response.getOk()?.getId();
    if (!id) throw new Error('No id returned');
    return id;
  }

  async deleteEntity(params: DeleteEntityParams, opts: AuthOptions): Promise<void> {
    const request = new DeleteEntityRequest();
    request.setEntityId(params.entityId);
    request.setWorkspaceId(params.workspaceId);
    const response = await this.reconciliationService.deleteEntity(request, {
      Authorization: `Bearer ${opts.idToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteEntityErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteEntityErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteEntityErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async createReconciliationAccount(
    params: CreateReconciliationAccountParams,
    opts: AuthOptions
  ): Promise<string> {
    const request = new CreateReconciliationAccountRequest();
    request.setName(params.name);
    request.setReconciliationType(params.reconciliationType);
    request.setUniqueIdentifier(params.uniqueIdentifier);
    if (params.counterpartyContactName)
      request.setCounterpartyContactName(params.counterpartyContactName);
    if (params.counterpartyContactEmail)
      request.setCounterpartyContactEmail(params.counterpartyContactEmail);
    request.setResponsibleUserId(params.owner);
    request.setEntityId(params.entityId);
    request.setWorkspaceId(params.workspaceId);

    const response: CreateReconciliationAccountResponse =
      await this.reconciliationService.createReconciliationAccount(request, {
        Authorization: `Bearer ${opts.idToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: CreateReconciliationAccountErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case CreateReconciliationAccountErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case CreateReconciliationAccountErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const id = response.getOk()?.getId();
    if (!id) throw new Error('No id returned');
    return id;
  }

  async updateReconciliationAccount(
    params: UpdateReconciliationAccountParams,
    opts: AuthOptions
  ): Promise<void> {
    const request = new EditReconciliationAccountProfileRequest();
    request.setWorkspaceId(params.workspaceId);
    request.setEntityId(params.entityId);
    request.setReconciliationAccountId(params.reconciliationAccountId);
    if (params.name) request.setName(params.name);
    if (params.uniqueIdentifier) request.setUniqueIdentifier(params.uniqueIdentifier);
    if (params.counterpartyContactName)
      request.setCounterpartyContactName(params.counterpartyContactName);
    if (params.counterpartyContactEmail)
      request.setCounterpartyContactEmail(params.counterpartyContactEmail);
    if (params.responsibleUserId) request.setResponsibleUserId(params.responsibleUserId);

    const response: EditReconciliationAccountProfileResponse =
      await this.reconciliationService.editReconciliationAccountProfile(request, {
        Authorization: `Bearer ${opts.idToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditReconciliationAccountProfileErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditReconciliationAccountProfileErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditReconciliationAccountProfileErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async deleteReconciliationAccount(
    params: DeleteReconciliationAccountParams,
    opts: AuthOptions
  ): Promise<void> {
    const request = new DeleteReconciliationAccountRequest();
    request.setWorkspaceId(params.workspaceId);
    request.setEntityId(params.entityId);
    request.setReconciliationAccountId(params.reconciliationAccountId);
    const response = await this.reconciliationService.deleteReconciliationAccount(request, {
      Authorization: `Bearer ${opts.idToken}`,
    });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteReconciliationAccountErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteReconciliationAccountErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteReconciliationAccountErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async getWorkspaces({ idToken }: AuthOptions): Promise<Workspace[]> {
    const request = new GetWorkspacesRequest();
    const response: GetWorkspacesResponse = await this.reconciliationService.getWorkspaces(
      request,
      {
        Authorization: `Bearer ${idToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetWorkspacesErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetWorkspacesErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case GetWorkspacesErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const workspaces = response.getOk()?.getWorkspacesList();
    if (!workspaces) {
      return [];
    }

    return workspaces.map((workspace) => this.mapWorkspace(workspace));
  }

  async getWorkspace(workspaceId: string, { idToken }: AuthOptions): Promise<Workspace> {
    const request = new GetWorkspaceByIdRequest();
    request.setWorkspaceId(workspaceId);
    const response: GetWorkspaceByIdResponse = await this.reconciliationService.getWorkspaceById(
      request,
      {
        Authorization: `Bearer ${idToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetWorkspaceByIdErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetWorkspaceByIdErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case GetWorkspaceByIdErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const workspace = response.getOk()?.getWorkspace();
    if (!workspace) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }

    return this.mapWorkspace(workspace);
  }

  async getEntity(workspaceId: string, entityId: string, options: AuthOptions): Promise<Entity> {
    const request = new GetEntityByIdRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    const response: GetEntityByIdResponse = await this.reconciliationService.getEntityById(
      request,
      {
        Authorization: `Bearer ${options.idToken}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetEntityByIdErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetEntityByIdErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case GetEntityByIdErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const entity = response.getOk()?.getEntity();
    if (!entity) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return this.mapEntity(entity);
  }

  async getReconciliationAccount(
    workspaceId: string,
    entityId: string,
    reconciliationAccountId: string,
    { idToken }: AuthOptions
  ): Promise<ReconciliationAccount> {
    const request = new GetReconciliationAccountByIdRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setReconciliationAccountId(reconciliationAccountId);
    const response: GetReconciliationAccountByIdResponse =
      await this.reconciliationService.getReconciliationAccountById(request, {
        Authorization: `Bearer ${idToken}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetReconciliationAccountByIdErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetReconciliationAccountByIdErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case GetReconciliationAccountByIdErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const okResponse = response.getOk();
    if (!okResponse) {
      throw new Error('No OK response received');
    }
    const reconciliationAccount = okResponse.getReconciliationAccount();
    if (!reconciliationAccount) {
      throw new Error('No reconciliation account received');
    }
    return this.mapReconciliationAccount(reconciliationAccount);
  }

  getEntities() // workspaceId: string
  : Promise<Entity[]> {
    throw new Error('Method not implemented.');
  }

  getReconciliationAccounts() // workspaceId: string,
  // entityId: string
  : Promise<ReconciliationAccount[]> {
    throw new Error('Method not implemented.');
  }

  getCountries(): Promise<string[]> {
    const countries = ['Greece', 'USA', 'UK'];
    return Promise.resolve(countries);
  }

  getLanguages(): Promise<string[]> {
    const languages = ['US/ENG', 'GR/EN', 'UK/EN'];
    return Promise.resolve(languages);
  }

  async addWorkspaceStopListItem(
    workspaceId: string,
    value: string,
    token: string
  ): Promise<string> {
    const request = new AddWorkspaceStopListItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setValue(value);
    const response: AddWorkspaceStopListItemResponse =
      await this.reconciliationService.addWorkspaceStopListItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AddWorkspaceStopListItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AddWorkspaceStopListItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case AddWorkspaceStopListItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case AddWorkspaceStopListItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const stopListItemId = response.getOk()?.getId();
    if (!stopListItemId) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return stopListItemId;
  }

  async deleteWorkspaceStopListItem(
    workspaceId: string,
    stopListItemId: string,
    token: string
  ): Promise<void> {
    const request = new DeleteWorkspaceStopListItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setId(stopListItemId);
    const response: DeleteWorkspaceStopListItemResponse =
      await this.reconciliationService.deleteWorkspaceStopListItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteWorkspaceStopListItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteWorkspaceStopListItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DeleteWorkspaceStopListItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteWorkspaceStopListItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async editWorkspaceStopListItem(
    workspaceId: string,
    stopListItemId: string,
    newValue: string,
    token: string
  ): Promise<void> {
    const request = new EditWorkspaceStopListItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setId(stopListItemId);
    request.setValue(newValue);
    const response: EditWorkspaceStopListItemResponse =
      await this.reconciliationService.editWorkspaceStopListItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditWorkspaceStopListItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditWorkspaceStopListItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EditWorkspaceStopListItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditWorkspaceStopListItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async addWorkspaceCategory(workspaceId: string, name: string, token: string): Promise<string> {
    const request = new AddWorkspaceCategoryRequest();
    request.setWorkspaceId(workspaceId);
    request.setName(name);
    const response: AddWorkspaceCategoryResponse =
      await this.reconciliationService.addWorkspaceCategory(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AddWorkspaceCategoryErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AddWorkspaceCategoryErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case AddWorkspaceCategoryErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case AddWorkspaceCategoryErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const categoryId = response.getOk()?.getId();
    if (!categoryId) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return categoryId;
  }

  async deleteWorkspaceCategory(
    workspaceId: string,
    categoryId: string,
    token: string
  ): Promise<void> {
    const request = new DeleteWorkspaceCategoryRequest();
    request.setWorkspaceId(workspaceId);
    request.setId(categoryId);
    const response: DeleteWorkspaceCategoryResponse =
      await this.reconciliationService.deleteWorkspaceCategory(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteWorkspaceCategoryErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteWorkspaceCategoryErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DeleteWorkspaceCategoryErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteWorkspaceCategoryErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async editWorkspaceCategoryName(
    workspaceId: string,
    categoryId: string,
    newName: string,
    token: string
  ): Promise<void> {
    const request = new EditWorkspaceCategoryNameRequest();
    request.setWorkspaceId(workspaceId);
    request.setCategoryId(categoryId);
    request.setName(newName);
    const response: EditWorkspaceCategoryNameResponse =
      await this.reconciliationService.editWorkspaceCategoryName(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditWorkspaceCategoryNameErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditWorkspaceCategoryNameErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EditWorkspaceCategoryNameErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditWorkspaceCategoryNameErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async addWorkspaceCategoryItem(
    workspaceId: string,
    categoryId: string,
    value: string,
    token: string
  ): Promise<string> {
    const request = new AddWorkspaceCategoryItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setCategoryId(categoryId);
    request.setValue(value);
    const response: AddWorkspaceCategoryItemResponse =
      await this.reconciliationService.addWorkspaceCategoryItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AddWorkspaceCategoryItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AddWorkspaceCategoryItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case AddWorkspaceCategoryItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case AddWorkspaceCategoryItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }

    const id = response.getOk()?.getId();
    if (!id) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return id;
  }

  async deleteWorkspaceCategoryItem(
    workspaceId: string,
    categoryId: string,
    itemId: string,
    token: string
  ): Promise<void> {
    const request = new DeleteWorkspaceCategoryItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setCategoryId(categoryId);
    request.setId(itemId);
    const response: DeleteWorkspaceCategoryItemResponse =
      await this.reconciliationService.deleteWorkspaceCategoryItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteWorkspaceCategoryItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteWorkspaceCategoryItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DeleteWorkspaceCategoryItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteWorkspaceCategoryItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async editWorkspaceCategoryItem(
    workspaceId: string,
    categoryId: string,
    itemId: string,
    newValue: string,
    token: string
  ): Promise<void> {
    const request = new EditWorkspaceCategoryItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setCategoryId(categoryId);
    request.setId(itemId);
    request.setValue(newValue);
    const response: EditWorkspaceCategoryItemResponse =
      await this.reconciliationService.editWorkspaceCategoryItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditWorkspaceCategoryItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditWorkspaceCategoryItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EditWorkspaceCategoryItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditWorkspaceCategoryItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async enableWorkspaceStopListItemOfEntity(
    workspaceId: string,
    entityId: string,
    stopListItemId: string,
    token: string
  ): Promise<void> {
    const request = new EnableWorkspaceStopListItemOfEntityRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setId(stopListItemId);
    const response: EnableWorkspaceStopListItemOfEntityResponse =
      await this.reconciliationService.enableWorkspaceStopListItemOfEntity(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EnableWorkspaceStopListItemOfEntityErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EnableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async disableWorkspaceStopListItemOfEntity(
    workspaceId: string,
    entityId: string,
    stopListItemId: string,
    token: string
  ): Promise<void> {
    const request = new DisableWorkspaceStopListItemOfEntityRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setId(stopListItemId);
    const response: DisableWorkspaceStopListItemOfEntityResponse =
      await this.reconciliationService.disableWorkspaceStopListItemOfEntity(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DisableWorkspaceStopListItemOfEntityErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DisableWorkspaceStopListItemOfEntityErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async enableWorkspaceCategoryOfEntity(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    token: string
  ): Promise<void> {
    const request = new EnableWorkspaceCategoryOfEntityRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    const response: EnableWorkspaceCategoryOfEntityResponse =
      await this.reconciliationService.enableWorkspaceCategoryOfEntity(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EnableWorkspaceCategoryOfEntityErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EnableWorkspaceCategoryOfEntityErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async disableWorkspaceCategoryOfEntity(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    token: string
  ): Promise<void> {
    const request = new DisableWorkspaceCategoryOfEntityRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    const response: DisableWorkspaceCategoryOfEntityResponse =
      await this.reconciliationService.disableWorkspaceCategoryOfEntity(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DisableWorkspaceCategoryOfEntityErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DisableWorkspaceCategoryOfEntityErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async addEntityStopListItem(
    workspaceId: string,
    entityId: string,
    value: string,
    token: string
  ): Promise<string> {
    const request = new AddEntityStopListItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setValue(value);
    const response: AddEntityStopListItemResponse =
      await this.reconciliationService.addEntityStopListItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AddEntityStopListItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AddEntityStopListItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case AddEntityStopListItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case AddEntityStopListItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const id = response.getOk()?.getId();
    if (!id) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return id;
  }

  async deleteEntityStopListItem(
    workspaceId: string,
    entityId: string,
    stopListItemId: string,
    token: string
  ): Promise<void> {
    const request = new DeleteEntityStopListItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setStopListItemId(stopListItemId);
    const response: DeleteEntityStopListItemResponse =
      await this.reconciliationService.deleteEntityStopListItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteEntityStopListItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteEntityStopListItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DeleteEntityStopListItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteEntityStopListItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async editEntityStopListItem(
    workspaceId: string,
    entityId: string,
    stopListItemId: string,
    newValue: string,
    token: string
  ): Promise<void> {
    const request = new EditEntityStopListItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setStopListItemId(stopListItemId);
    request.setValue(newValue);
    const response: EditEntityStopListItemResponse =
      await this.reconciliationService.editEntityStopListItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditEntityStopListItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditEntityStopListItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EditEntityStopListItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditEntityStopListItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async addEntityCategory(
    workspaceId: string,
    entityId: string,
    name: string,
    token: string
  ): Promise<string> {
    const request = new AddEntityCategoryRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setName(name);
    const response: AddEntityCategoryResponse = await this.reconciliationService.addEntityCategory(
      request,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AddEntityCategoryErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AddEntityCategoryErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case AddEntityCategoryErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case AddEntityCategoryErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const id = response.getOk()?.getId();
    if (!id) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return id;
  }

  async deleteEntityCategory(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    token: string
  ): Promise<void> {
    const request = new DeleteEntityCategoryRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    const response: DeleteEntityCategoryResponse =
      await this.reconciliationService.deleteEntityCategory(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteEntityCategoryErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteEntityCategoryErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DeleteEntityCategoryErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteEntityCategoryErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async editEntityCategoryName(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    newName: string,
    token: string
  ): Promise<void> {
    const request = new EditEntityCategoryNameRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    request.setName(newName);
    const response: EditEntityCategoryNameResponse =
      await this.reconciliationService.editEntityCategoryName(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditEntityCategoryNameErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditEntityCategoryNameErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EditEntityCategoryNameErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditEntityCategoryNameErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async addEntityCategoryItem(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    value: string,
    token: string
  ): Promise<string> {
    const request = new AddEntityCategoryItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    request.setValue(value);
    const response: AddEntityCategoryItemResponse =
      await this.reconciliationService.addEntityCategoryItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: AddEntityCategoryItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case AddEntityCategoryItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case AddEntityCategoryItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case AddEntityCategoryItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const id = response.getOk()?.getId();
    if (!id) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    return id;
  }

  async deleteEntityCategoryItem(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    itemId: string,
    token: string
  ): Promise<void> {
    const request = new DeleteEntityCategoryItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    request.setCategoryItemId(itemId);
    const response: DeleteEntityCategoryItemResponse =
      await this.reconciliationService.deleteEntityCategoryItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: DeleteEntityCategoryItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case DeleteEntityCategoryItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case DeleteEntityCategoryItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case DeleteEntityCategoryItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async editEntityCategoryItem(
    workspaceId: string,
    entityId: string,
    categoryId: string,
    itemId: string,
    newValue: string,
    token: string
  ): Promise<void> {
    const request = new EditEntityCategoryItemRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);
    request.setCategoryId(categoryId);
    request.setCategoryItemId(itemId);
    request.setValue(newValue);
    const response: EditEntityCategoryItemResponse =
      await this.reconciliationService.editEntityCategoryItem(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: EditEntityCategoryItemErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case EditEntityCategoryItemErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case EditEntityCategoryItemErrorResponse.ErrorCase.UNAUTHORIZED_ERROR:
          throw ServiceErrors.UNAUTHORIZED_ERROR;
        case EditEntityCategoryItemErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
  }

  async getWorkspaceSettings(workspaceId: string, token: string): Promise<WorkspaceSettings> {
    const requet = new GetWorkspaceSettingsByIdRequest();
    requet.setWorkspaceId(workspaceId);
    const response: GetWorkspaceSettingsByIdResponse =
      await this.reconciliationService.getWorkspaceSettingsById(requet, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetWorkspaceSettingsByIdErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetWorkspaceSettingsByIdErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case GetWorkspaceSettingsByIdErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const settings = response.getOk()?.getSettings();
    if (!settings) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }
    const asObject = settings.toObject();
    return {
      ...asObject,
      stopList: asObject.stopListList,
      categories: asObject.categoriesList.map((category) => ({
        ...category,
        items: category.itemsList,
      })),
    };
  }

  async getEntitySettings(
    workspaceId: string,
    entityId: string,
    token: string
  ): Promise<EntitySettings> {
    const request = new GetEntitySettingsByIdRequest();
    request.setWorkspaceId(workspaceId);
    request.setEntityId(entityId);

    const response: GetEntitySettingsByIdResponse =
      await this.reconciliationService.getEntitySettingsById(request, {
        Authorization: `Bearer ${token}`,
      });
    const errorResponse = response.getError();
    if (response.hasError() && errorResponse) {
      const error: GetEntitySettingsByIdErrorResponse = errorResponse;
      switch (error.getErrorCase()) {
        case GetEntitySettingsByIdErrorResponse.ErrorCase.WORKSPACE_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(
            errorResponse.getWorkspaceNotFoundError()!.getMessage()
          );
        case GetEntitySettingsByIdErrorResponse.ErrorCase.ENTITY_NOT_FOUND_ERROR:
          throw ServiceErrors.NOT_FOUND_ERROR(errorResponse.getEntityNotFoundError()!.getMessage());
        case GetEntitySettingsByIdErrorResponse.ErrorCase.UNEXPECTED_ERROR:
          throw ServiceErrors.UNEXPECTED_ERROR;
        default:
          throw ServiceErrors.UNKNOWN_ERROR;
      }
    }
    const settings = response.getOk()?.getSettings();
    if (!settings) {
      throw ServiceErrors.UNKNOWN_ERROR;
    }

    const asObject = settings.toObject();
    return {
      entitySettings: {
        ...asObject.entitySettings,
        stopList: asObject.entitySettings!.stopListList,
        categories: asObject.entitySettings!.categoriesList.map((category) => ({
          ...category,
          items: category.itemsList,
        })),
        workspaceCategories: asObject.entitySettings!.workspaceCategoriesList,
        workspaceStopList: asObject.entitySettings!.workspaceStopListList,
      },
      workspaceStopList: asObject.workspaceStopListList,
      workspaceCategories: asObject.workspaceCategoriesList.map((category) => ({
        ...category,
        items: category.itemsList,
      })),
    };
  }

  private mapWorkspace(workspace: ProtoWorkspace): Workspace {
    return {
      id: workspace.getId(),
      name: workspace.getName(),
      country: workspace.getCountry(),
      language: workspace.getLanguage(),
      createdAt: workspace.getCreatedAt(),
      updatedAt: workspace.getUpdatedAt(),
      entities: workspace.getEntitiesList().map((entity) => entity.toObject()),
    };
  }

  private mapEntity(entity: ProtoEntity): Entity {
    return {
      ...entity.toObject(),
      reconciliationAccountsList: entity
        .getReconciliationAccountsList()
        .map((reconciliationAccount) => reconciliationAccount.toObject()),
    };
  }

  private mapReconciliationAccount(
    reconciliationAccount: ProtoReconciliationAccount
  ): ReconciliationAccount {
    return {
      ...reconciliationAccount.toObject(),
    };
  }
}
