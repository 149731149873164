import { observer } from 'mobx-react-lite';
import LedgerDocumentFormatTypeMissingDialog from '../component';
import { useDocumentsViewModel } from '../../../../view-models/DocumentsViewModel';
import { useReconciliationReportViewModel } from '../../../../view-models/ReconciliationReportViewModel';

function LedgerDocumentFormatTypeMissingController() {
  const documentsViewModel = useDocumentsViewModel()!;
  const reconciliationReportViewModel = useReconciliationReportViewModel()!;

  return (
    <LedgerDocumentFormatTypeMissingDialog
      open={documentsViewModel.isLedgerDocumentFormatTypeVisible}
      submitDebitsIncrease={reconciliationReportViewModel.applyLedgerDebitIncrease}
      submitDebitsDecrease={reconciliationReportViewModel.applyLedgerDebitDecrease}
      closeDialog={documentsViewModel.hideLedgerDocumentFormatTypeMissingDialog}
    />
  );
}

const formatTypeMissingOB = observer(LedgerDocumentFormatTypeMissingController);

export default formatTypeMissingOB;
