import { TextField, Autocomplete } from '@mui/material';
import FieldBlock from './Field';
import { EditWorkspaceParams } from '../../../models/Resources';
import { Blocks, TabActions } from './Styles';
import deleteIcon from '../../../assets/delete.svg';
import SimpleButton from '../../Buttons/SimpleButton';

interface ProfileTabContentProps {
  countries: string[];
  languages: string[];
  updateParams: EditWorkspaceParams;
  updateWorkspaceName: (name: string) => void;
  updateCountry: (country: string) => void;
  updateLanguage: (language: string) => void;
  updateWorkspace: (updateParams: Partial<EditWorkspaceParams>) => void;
  deleteWorkspace: () => Promise<void>;
}

function ProfileTabContent(props: ProfileTabContentProps): JSX.Element {
  const {
    updateParams,
    countries,
    languages,
    updateCountry,
    updateLanguage,
    updateWorkspaceName,
    updateWorkspace,
    deleteWorkspace,
  } = props;
  return (
    <Blocks>
      <FieldBlock
        name="Name"
        field={updateParams.name}
        fieldInput={
          <TextField
            id="outlined-basic"
            variant="outlined"
            onChange={(e) => updateWorkspaceName(e.target.value)}
            defaultValue={updateParams.name}
          />
        }
        updateParam={{ name: updateParams.name }}
        updateField={updateWorkspace}
      />
      <FieldBlock
        name="Country"
        field={updateParams.country}
        fieldInput={
          <Autocomplete
            options={countries}
            sx={{ width: 400 }}
            value={updateParams.country || null}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e, value) => {
              if (value) updateCountry(value);
            }}
          />
        }
        updateParam={{ country: updateParams.country }}
        updateField={updateWorkspace}
      />
      <FieldBlock
        name="Language"
        field={updateParams.language}
        fieldInput={
          <Autocomplete
            options={languages}
            sx={{ width: 300 }}
            value={updateParams.language || null}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e, value) => {
              if (value) updateLanguage(value);
            }}
          />
        }
        updateParam={{ language: updateParams.language }}
        updateField={updateWorkspace}
      />
      <TabActions>
        <SimpleButton icon={deleteIcon} onClick={deleteWorkspace} content="Delete Workspace" />
      </TabActions>
    </Blocks>
  );
}
export default ProfileTabContent;
