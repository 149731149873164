import { HTTP_PROXY_URL } from '../../config';
import {
  ConvertFileData,
  FileRowData,
  IFileStorageService,
  UploadFileData,
} from '../interfaces/IFileStorageService';

export default class FileStorageService implements IFileStorageService {
  readonly apiUrl = HTTP_PROXY_URL;

  async uploadFile(data: UploadFileData): Promise<string> {
    const { file, workspaceId, entityId, accountId, accessToken } = data;
    // Construct a new FormData instance
    const formData = new FormData();

    // const newFileName = file.name;
    formData.append('workspaceId', workspaceId);
    formData.append('entityId', entityId);
    formData.append('accountId', accountId);
    formData.append('incoming', file); // newFileName);

    // Specify the URL of your API endpoint
    const apiUrl = `${this.apiUrl}/uploadFileToStorage`;
    console.log('apiUrl', apiUrl);

    console.log({
      formData: Object.fromEntries(formData.entries()),
    });
    try {
      // Send the file to your API
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
      });

      // Check if the upload was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('response', response);

      // Process the response here (if needed)
      const responseData = await response.json();
      return responseData.fileLocation;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      throw error;
    }
  }

  async convertFile(data: ConvertFileData): Promise<FileRowData[]> {
    const { file, fileType, workspaceId, accessToken } = data;
    // Construct a new FormData instance
    const formData = new FormData();

    // const newFileName = file.name;
    formData.append('workspaceId', workspaceId);
    formData.append('fileType', fileType);
    formData.append('incoming', file); // newFileName);

    // Specify the URL of your API endpoint
    const apiUrl = `${this.apiUrl}/convertFile`;
    console.log('apiUrl', apiUrl);

    console.log({
      formData: Object.fromEntries(formData.entries()),
    });
    try {
      // Send the file to your API
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
      });
      // Check if the upload was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('response', response);

      // Process the response here (if needed)
      const responseData = await response.json();
      return responseData.fileResult;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      throw error;
    }
  }
}

// const main = async () => {
//   // Usage
//   const fileUploadService = new FileStorageService();

//   // Assuming you have a File object (perhaps from an <input type="file"> element)
//   // const file = 'a' /* ... */;
//   const file = new File(['Content of the Excel file (not actual Excel data)'], 'filename.xlsx', {
//     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   });

//   // Call the uploadFile method with the File object
//   fileUploadService
//     .uploadFile(file)
//     .then((data) => {
//       console.log('File uploaded successfully:', data);
//     })
//     .catch((error) => {
//       console.error('File upload failed:', error);
//     });
// };

// console.log('Running main()...');
// main();
